import { report } from "process";

export const AdminAssignmentPageLocale = {
	en: {
		general: {
			addCommentOrClarification: "Add a comment or clarification to this summary",
			typeYourCommentHere: "Type your comment here...",
			save: "Save",
			cancel: "Cancel"
		},
		topSection: {
			aggregateSummaryTitle: "Aggregate Summary",
			assignmentDetailsDropdown: {
				hideAssignmentDetails: "Hide Assignment Details",
				showAssignmentDetails: "Show Assignment Details",
				editAssignmentButton: "Edit Assignment",
				shareAssignmentButton: "Share Assignment",
				shareAssignmentButtonDisabled: "This assignment is currently in draft status and cannot be shared.",
				assignmentLink: "Assignment Link",
				assignmentLinkDescription: "Copy assignment link to clipboard",
				expanded: {
					course: "Course",
					experienceType: "Experience Type",
					assignmentCode: "Code",
					assignmentName: "Name",
					assignmentDescription: "Description",
					totalUsage: "Total Usage",
					competencies: "Competencies"
				}
			}
		},
		schedule: {
			title: "Schedule"
		},
		report: {
			generating: "Generating",
			generateAggregateData: "Generate Aggregate Data",
			averageGrade: "Average Grade",
			sessionCompletionRate: "Session Completion Rate",
			totalUsage: "Total Usage",
			downloading: "Downloading",
			downloadReport: "Download Report",
			download: "Download",
			aggregateReportWillAutoGenerateOn: "The aggregate report will auto generate on"
		},
		assignedSessionsTable: {
			actions: "Actions",
			emailAlreadyExists: "This email is already in the list",
			student: "Student",
			email: "Email",
			report: "Report",
			viewReport: "View",
			grade: "Grade",
			pass: "Pass",
			fail: "Fail",
			status: "Status",
			extension: "Extension",
			dueDate: "Due Date",
			submittedAt: "Submitted At",
			notSubmitted: "Not Submitted",
			add1DayExtension: "Add 1 Day Extension",
			add3DayExtension: "Add 3 Day Extension",
			add7DayExtension: "Add 7 Day Extension",
			removeUser: "Remove User",
			search: "Search",
			submitted: "Submitted",
			pastDue: "Past Due",
			pending: "Pending",
			available: "Available",
			inProgress: "In Progress",
			notOnboarded: "Not Onboarded",
			bounced: "Bounced",
			userReports: "User Reports",
			assignedSessions: "Assigned Sessions",
			downloadCSV: "Download CSV",
			addStudent: "Add Student",
			addUser: "Add User",
			enterEmail: "Enter email",
			adding: "Adding...",
			selectDateRange: "Select date range",
			rowsPerPage: "Rows per page",
			page: "Page",
			of: "of",
			viewOptions: "View Options",
			toggleColumns: "Toggle Columns",
			fullName: "Full Name",
			daysToAdd: "Days to Add",
			date: "Date",
			commonCareerPaths: "Common Career Paths",
			careerReadiness: "Career Readiness",
			goalProgress: "Goal Progress",
			careerSupport: "Career Support",
			careerHappiness: "Career Happiness",
			careerPreparedness: "Career Preparedness",
			competencyProgress: "Competency Progress",
			assignmentTitle: "Assignment Title",
			experienceType: "Experience Type",
			ownerEmail: "Owner Email",
			startDate: "Start Date",
			endDate: "End Date",
			enrolledUserCount: "Enrolled User Count",
			sessionsAssigned: "Sessions Assigned",
			totalExpectedSessions: "Total Expected Sessions",
			submissionCount: "Submission Count",
			participationRate: "Participation Rate",
			serviceHoursUsed: "Service Hours Used",
			sessionId: "Session ID",
			clientName: "Client",
			userId: "User ID",
			assignmentId: "Assignment ID",
			startTime: "Start Time",
			endTime: "End Time",
			scheduleDescription: "Schedule Description",
			studentName: "Student Name",
			duration: "Duration",
			createdAt: "Created At",
			title: "Title",
			jobTitle: "Job Title",
			matchScore: "Match Score",
			firstName: "Student Name",
			scheduleDate: "Schedule Date",
			minutesUsed: "Minutes Used",
			updatedAt: "Updated At",
			studentSatisfaction: "Student Satisfaction",

			statuses: {
				upcoming: "Upcoming",
				not_confirmed: "Not Confirmed",
				bounced: "Bounced", // bad email
				pending: "Pending", // due date in future
				available: "Available", // ready to start
				in_progress: "In Progress", // user is on call
				incomplete: "Incomplete", // user did not complete the call
				unsubmitted: "Unsubmitted", // user did not submit the call
				submitted: "Submitted", // user submitted the call
				submitted_late: "Submitted Late", // user submitted the call after the due date
				not_attempted: "Not Attempted", // user did not attempt the call
				pastdue: "Past Due", // user did not attempt the call
				draft: "Draft", // assingment is in draft mode
				not_available: "Not Available", // removed
				deleted: "Deleted", // removed
				completed: "Completed" // removed
			},
			goalStatuses: {
				Yes: "Yes",
				No: "No",
				Partially: "Partially",
				NotAttempted: "Not Attempted",
				NotApplicable: "Not Applicable"
			}
		},
		horizontalTabs: {
			interview: {
				screening: "Screening",
				middle: "Middle",
				final: "Final",
				resume: "Resume"
			},
			careerPath: {
				discovery: "Discovery",
				progression: "Progression"
			},
			frontline: {
				pre: "Pre",
				middle: "Middle",
				post: "Post",
				call: "Call"
			}
		},
		cards: {
			goalCompletionRate: "Goal Completion Rate",
			whatWentWell: "What Went Well",
			areasToImprove: "Areas to Improve",
			hideSources: "Hide Sources",
			showSources: "Show Sources",
			strengthsAndImprovements: "Strengths and Improvements",
			newGoals: "New Goals",
			noNewGoals: "No New Goals",
			hasChosen: "Has Chosen",
			finalizedCareerPath: "Finalized Career Path",
			noChosenCareerPath: "No Chosen Career Path",
			overview: "Overview",
			noStrengthsProvided: "No Strengths Provided",
			noImprovementsProvided: "No Improvements Provided",
			success: "Success",
			yes: "Yes",
			no: "No",
			example: "Example",
			viewExample: "View Example",
			noExampleProvided: "No Example Provided",
			progress: "Progress",
			outlook: "Outlook",
			score: "Score",
			preferredMethod: "Preferred Method",
			feedback: "Feedback",
			summary: "Summary",
			noReasonProvided: "No Reason Provided",
			noExpectationsProvided: "No Expectations Provided",
			noSummaryProvided: "No Summary Provided",
			unknown: "Unknown",
			view: "View",
			report: "Report",
			high: "High",
			medium: "Medium",
			low: "Low",
			positive: "Positive",
			mixed: "Mixed",
			negative: "Negative",
			new: "New",
			partial: "Partial",
			frontlineEvaluation: {
				communicationPointsChecklist: "Communication Points Checklist",
				informationCollectionChecklist: "Information Collection Checklist",
				successRate: "Success Rate",
				loadingStudentDetails: "Loading student details...",
				showSources: "Show Sources",
				hideSources: "Hide Sources"
			},
			expectations: {
				title: "Expectations",
				outlook: "Outlook",
				positive: "Positive",
				negative: "Negative",
				mixed: "Mixed"
			},
			projectGoals: {
				title: "Goals"
			},
			purpose: {
				title: "Purpose"
			}
		},
		renderCards: {
			flaggedComments: "Flagged Comments",
			goalCompletionRate: "Goal Completion Rate",
			tooltips: {
				goalCompletionRate: "This is the percentage of goals that have been completed by students.",
				averageGrade: "Average grade across all submitted sessions.",
				sessionCompletionRate: "This is the percentage of students who have completed this reflection call.",
				totalUsage: "This is the total duration of all sessions for this schedule."
			},
			metrics: {
				averageGrade: "Average Grade",
				sessionCompletionRate: "Session Completion Rate",
				totalUsage: "Total Usage"
			},
			questionList: {
				title: "Question List Summary",
				averageScore: "Average Score",
				distribution: "Distribution",
				high: "High",
				medium: "Medium",
				low: "Low",
				showSources: "Show Sources",
				hideSources: "Hide Sources",
				ariaLabel: {
					showHideSources: "{{action}} sources for question {{id}}"
				},
				questionTypes: {
					Technical: "Technical",
					Behavioral: "Behavioral",
					Situational: "Situational",
					"Cultural Fit": "Cultural Fit",
					Background: "Background",
					Motivation: "Motivation",
					General: "General",
					Recall: "Recall",
					Resume: "Resume",
					Opening: "Opening",
					Closing: "Closing"
				}
			}
		},
		priority: {
			high: "High Priority",
			medium: "Medium Priority",
			low: "Low Priority"
		},
		reportCard: {
			average: "Average"
		},
		userSessionCard: {
			availableNow: "Available Now",
			selfPractice: "Self Practice",
			pastDue: "Past Due",
			dueIn: "Due in",
			days: "days",
			weeks: "weeks",
			months: "months",
			ago: "ago",
			dueToday: "Due Today",
			dueTomorrow: "Due Tomorrow",
			deny: "Deny",
			comply: "Comply",
			gather: "Gather",
			review: "Review",
			startSession: "Start Session",
			starting: "Starting...",
			preExperience: "Pre-Experience",
			midExperience: "Mid-Experience",
			lastExperience: "Last-Experience",
			completedOn: "Completed On",
			viewSummary: "View Summary",
			finalStage: "Final-Experience",
			first: "First",
			middle: "Middle",
			screening: "Screening",
			last: "Last",
			final: "Final",
			stage: "Stage"
		}
	},
	fr: {
		general: {
			addCommentOrClarification: "Ajouter un commentaire ou une clarification à ce résumé",
			typeYourCommentHere: "Type votre commentaire ici...",
			save: "Enregistrer",
			cancel: "Annuler"
		},
		topSection: {
			aggregateSummaryTitle: "Résumé agrégé",
			assignmentDetailsDropdown: {
				hideAssignmentDetails: "Masquer les détails de l'affectation",
				showAssignmentDetails: "Afficher les détails de l'affectation",
				editAssignmentButton: "Modifier l'affectation",
				shareAssignmentButton: "Partager l'affectation",
				shareAssignmentButtonDisabled: "Cette affectation est actuellement dans l'état de brouillon et ne peut pas être partagée.",
				assignmentLink: "Lien de l'affectation",
				assignmentLinkDescription: "Copier le lien de l'affectation dans le presse-papiers",
				expanded: {
					course: "Cours",
					experienceType: "Type d'expérience",
					assignmentCode: "Code",
					assignmentName: "Nom",
					assignmentDescription: "Description",
					totalUsage: "Utilisation Totale",
					competencies: "Compétences"
				}
			}
		},
		schedule: {
			title: "Schedule"
		},
		report: {
			generating: "Génération",
			generateAggregateData: "Générer les données agrégées",
			averageGrade: "Note moyenne",
			sessionCompletionRate: "Taux de complétion des sessions",
			totalUsage: "Utilisation Totale",
			downloading: "Téléchargement",
			downloadReport: "Télécharger le rapport",
			download: "Télécharger",
			aggregateReportWillAutoGenerateOn: "Le rapport agrégé sera généré automatiquement le"
		},
		assignedSessionsTable: {
			actions: "Actions",
			emailAlreadyExists: "Cette adresse email est déjà dans la liste",
			student: "Étudiant",
			email: "Email",
			report: "Rapport",
			viewReport: "Voir",
			grade: "Note",
			pass: "Pass",
			fail: "Échoué",
			status: "Statut",
			extension: "Extension",
			dueDate: "Date de rendu",
			submittedAt: "Date de soumission",
			notSubmitted: "Non soumis",
			add1DayExtension: "Ajouter 1 jour d'extension",
			add3DayExtension: "Ajouter 3 jours d'extension",
			add7DayExtension: "Ajouter 7 jours d'extension",
			removeUser: "Supprimer l'utilisateur",
			search: "Rechercher",
			submitted: "Soumis",
			pastDue: "Passé",
			pending: "En attente",
			available: "Disponible",
			inProgress: "En cours",
			notOnboarded: "Non onboardé",
			bounced: "Bloqué",
			userReports: "Rapports d'utilisateurs",
			assignedSessions: "Sessions affectées",
			downloadCSV: "Télécharger CSV",
			addStudent: "Ajouter un étudiant",
			addUser: "Ajouter un utilisateur",
			enterEmail: "Entrer l'email",
			adding: "Ajout en cours...",
			selectDateRange: "Sélectionner la plage de dates",
			rowsPerPage: "Lignes par page",
			page: "Page",
			of: "de",
			viewOptions: "Options de vue",
			toggleColumns: "Basculer les colonnes",
			fullName: "Nom complet",
			daysToAdd: "Jours d'extension",
			date: "Date",
			commonCareerPaths: "Chemins de carrière communs",
			careerReadiness: "Prêt à la carrière",
			goalProgress: "Progression des objectifs",
			careerSupport: "Support de carrière",
			careerHappiness: "Satisfaction de carrière",
			careerPreparedness: "Préparation à la carrière",
			competencyProgress: "Progression des compétences",

			assignmentTitle: "Titre de l'affectation",
			experienceType: "Type d'expérience",
			ownerEmail: "Email du propriétaire",
			startDate: "Date de début",
			endDate: "Date de fin",
			enrolledUserCount: "Nombre d'utilisateurs inscrits",
			sessionsAssigned: "Sessions affectées",
			totalExpectedSessions: "Sessions attendues",
			submissionCount: "Nombre de soumissions",
			participationRate: "Taux de participation",
			serviceHoursUsed: "Heures de service utilisées",
			sessionId: "ID de session",
			clientName: "Client",
			userId: "ID de l'utilisateur",
			assignmentId: "ID de l'assignation",
			startTime: "Heure de début",
			endTime: "Heure de fin",
			scheduleDescription: "Description du planning",
			studentName: "Nom de l'étudiant",
			duration: "Durée",
			firstName: "Prénom",
			scheduleDate: "Date du planning",
			minutesUsed: "Minutes utilisées",
			createdAt: "Créé le",
			title: "Titre",
			jobTitle: "Titre du travail",
			matchScore: "Score de correspondance",
			updatedAt: "Mis à jour le",
			studentSatisfaction: "Satisfaction des étudiants",
			statuses: {
				upcoming: "À venir",
				not_confirmed: "non confirmé",
				bounced: "bloqué",
				pending: "en attente",
				available: "disponible",
				in_progress: "en cours",
				incomplete: "incomplet",
				unsubmitted: "non soumis",
				submitted: "soumis",
				submitted_late: "soumis en retard",
				not_attempted: "non tenté",
				pastdue: "passé",
				draft: "brouillon",
				not_available: "non disponible",
				deleted: "supprimé",
				completed: "terminé"
			},
			goalStatuses: {
				Yes: "Oui",
				No: "Non",
				Partially: "Partiellement",
				NotAttempted: "Non tenté",
				NotApplicable: "Non applicable"
			}
		},
		horizontalTabs: {
			interview: {
				screening: "Sélection",
				middle: "Moyen",
				final: "Final",
				resume: "CV"
			},
			careerPath: {
				discovery: "Découverte",
				progression: "Progression"
			},
			frontline: {
				pre: "Pré",
				middle: "Moyen",
				post: "Post",
				call: "Appel"
			}
		},
		cards: {
			goalCompletionRate: "Taux de complétion des objectifs",
			whatWentWell: "Ce qui s'est bien passé",
			areasToImprove: "Zones à améliorer",
			hideSources: "Masquer les sources",
			showSources: "Afficher les sources",
			strengthsAndImprovements: "Points forts et améliorations",
			newGoals: "Nouveaux objectifs",
			noNewGoals: "Aucun nouvel objectif",
			hasChosen: "A choisi",
			finalizedCareerPath: "Carrière finalisée",
			noChosenCareerPath: "Aucune carrière finalisée",
			overview: "Aperçu",
			noStrengthsProvided: "Aucun point fort fourni",
			noImprovementsProvided: "Aucune amélioration fournie",
			success: "Succès",
			yes: "Oui",
			no: "Non",
			example: "Exemple",
			viewExample: "Voir l'exemple",
			noExampleProvided: "Aucun exemple fourni",
			progress: "Progression",
			outlook: "Perspective",
			score: "Note",
			preferredMethod: "Méthode préférée",
			feedback: "Retour",
			summary: "Résumé",
			noReasonProvided: "Aucune raison fournie",
			noExpectationsProvided: "Aucune attente fournie",
			noSummaryProvided: "Aucun résumé fourni",
			unknown: "Inconnu",
			view: "Voir",
			report: "Rapport",
			high: "Haut",
			medium: "Moyen",
			low: "Faible",
			positive: "Positif",
			mixed: "Mixte",
			negative: "Négatif",
			frontlineEvaluation: {
				communicationPointsChecklist: "Liste de contrôle des points de communication",
				informationCollectionChecklist: "Liste de contrôle de la collecte d'informations",
				successRate: "Taux de réussite",
				loadingStudentDetails: "Chargement des détails des étudiants...",
				showSources: "Afficher les sources",
				hideSources: "Masquer les sources"
			},
			expectations: {
				title: "Attentes",
				outlook: "Perspective",
				positive: "Positif",
				negative: "Négatif",
				mixed: "Mixte"
			},
			projectGoals: {
				title: "Objectifs"
			},
			purpose: {
				title: "Objectif"
			}
		},
		renderCards: {
			flaggedComments: "Commentaires Flaggés",
			goalCompletionRate: "Taux de complétion des objectifs",
			tooltips: {
				goalCompletionRate: "C'est le pourcentage d'objectifs qui ont été complétés par les étudiants.",
				averageGrade: "Note moyenne pour toutes les sessions soumises.",
				sessionCompletionRate: "C'est le pourcentage d'étudiants qui ont terminé cet appel de réflexion.",
				totalUsage: "C'est la durée totale de toutes les sessions pour ce programme."
			},
			metrics: {
				averageGrade: "Note Moyenne",
				sessionCompletionRate: "Taux de Complétion des Sessions",
				totalUsage: "Utilisation Totale"
			},
			questionList: {
				title: "Résumé de la Liste des Questions",
				averageScore: "Score Moyen",
				distribution: "Distribution",
				high: "Élevé",
				medium: "Moyen",
				low: "Faible",
				showSources: "Afficher les Sources",
				hideSources: "Masquer les Sources",
				ariaLabel: {
					showHideSources: "{{action}} les sources pour la question {{id}}"
				},
				questionTypes: {
					Technical: "Technique",
					Behavioral: "Comportemental",
					Situational: "Situationnel",
					"Cultural Fit": "Adéquation Culturelle",
					Background: "Contexte",
					Motivation: "Motivation",
					General: "Général",
					Recall: "Rappel",
					Resume: "CV",
					Opening: "Ouverture",
					Closing: "Clôture"
				}
			}
		},
		priority: {
			high: "Haut Priorité",
			medium: "Moyen Priorité",
			low: "Faible Priorité"
		},
		reportCard: {
			average: "Moyenne"
		},
		userSessionCard: {
			availableNow: "Disponible maintenant",
			selfPractice: "Pratique personnelle",
			pastDue: "Passé",
			dueIn: "Dû dans",
			days: "jours",
			weeks: "semaines",
			months: "mois",
			ago: "il y a",
			dueToday: "Aujourd'hui",
			dueTomorrow: "Demain",
			deny: "Refuser",
			comply: "Compléter",
			gather: "Collecter",
			review: "Revoir",
			startSession: "Commencer la session",
			starting: "En cours...",
			preExperience: "Pré-Expérience",
			midExperience: "Moyen-Expérience",
			finalStage: "Dernière-Expérience",
			lastExperience: "Dernière-Expérience",
			completedOn: "Terminé le",
			viewSummary: "Voir le résumé",
			first: "Premier",
			middle: "Moyen",
			screening: "Sélection",
			last: "Dernier",
			final: "Final",
			stage: "Étape"
		}
	}
};
