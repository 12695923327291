import React from "react";

import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../shadcn-ui/Select";
import { PhoneIcon, SparklesIcon } from "@heroicons/react/24/solid";
import { Button } from "../../shadcn-ui/Button";
import { Input } from "../../shadcn-ui/Input";
import { Link } from "react-router-dom";
import { Checkbox } from "../../shadcn-ui/Checkbox";
import { Label } from "../../shadcn-ui/Label";
import { useTranslation } from "../../Utility/UseTranslation";
const VerificationHeader: React.FC = () => (
	<div className="w-full h-24 bg-gradient-to-r from-[#DB4F86] to-[#00A9AF] rounded-lg flex items-center justify-center mb-4">
		<div className="flex items-center">
			<div className="w-12 h-12 rounded-full bg-white flex items-center justify-center">
				<PhoneIcon className="text-[#DB4F86] w-6 h-6" />
			</div>
			<div className="w-16 border-t-2 border-dashed border-white"></div>
			<div className="w-12 h-12 rounded-full bg-white flex items-center justify-center">
				<SparklesIcon className="text-[#00A9AF] w-6 h-6" />
			</div>
		</div>
	</div>
);

interface CountryCodeSelectorProps {
	value: string;
	onChange: (value: string) => void;
}

const countryOptions = [
	{ value: "+1-CA", label: "CA", flag: "🇨🇦" },
	{ value: "+1-US", label: "US", flag: "🇺🇸" }
];

const CountryCodeSelector: React.FC<CountryCodeSelectorProps> = ({ value, onChange }) => {
	return (
		<Select value={value} onValueChange={onChange}>
			<SelectTrigger className="w-[80px]">
				<SelectValue>{countryOptions.find((option) => option.value === value)?.flag}</SelectValue>
			</SelectTrigger>
			<SelectContent>
				{countryOptions.map((option) => (
					<SelectItem key={option.value} value={option.value}>
						<span className="flex items-center">
							<span className="mr-2">{option.flag}</span>
							{option.label}
						</span>
					</SelectItem>
				))}
			</SelectContent>
		</Select>
	);
};

interface PhoneInputFormProps {
	onSubmit: (e: React.FormEvent) => void;
	numberValid: boolean;
	error: string;
	countryCode: string;
	phoneNumber: string;
	isConsentChecked: boolean;
	onCountryCodeChange: (value: string) => void;
	onPhoneChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onCancel: () => void;
	children: React.ReactNode;
}

const PhoneInputForm: React.FC<PhoneInputFormProps> = ({
	onSubmit,
	numberValid,
	error,
	countryCode,
	phoneNumber,
	isConsentChecked,
	onCountryCodeChange,
	onPhoneChange,
	onCancel,
	children
}) => {
	const { t } = useTranslation("extraUtility");
	return (
		<form onSubmit={onSubmit} className="space-y-4">
			<div className="space-y-2">
				{!numberValid && (
					<div className="flex space-x-2">
						<CountryCodeSelector value={countryCode} onChange={onCountryCodeChange} />
						<Input
							id="phone"
							type="tel"
							placeholder="(555) 000-0000"
							value={phoneNumber}
							onChange={onPhoneChange}
							className="flex-1 text-gray-800 placeholder-gray-400"
							required
						/>
					</div>
				)}

				{error && <p className="text-sm text-red-500 mt-1">{error}</p>}

				{children}
			</div>

			{!numberValid && (
				<div className="flex space-x-4">
					<Button type="button" variant="outline" className="w-1/2" onClick={onCancel}>
						{t("phoneVerification.cancel")}
					</Button>
					<Button type="submit" className="w-1/2 bg-[#00A9AF] hover:bg-[#008C8F] text-white" disabled={!isConsentChecked}>
						{t("phoneVerification.verifyNumber")}
					</Button>
				</div>
			)}
		</form>
	);
};

const TextMessageDisclaimer: React.FC<{
	isChecked: boolean;
	onCheckChange: (checked: boolean) => void;
}> = ({ isChecked, onCheckChange }) => {
	const { t } = useTranslation("extraUtility");
	return (
		<div className="text-sm text-gray-500 mt-4 p-4 bg-gray-50 rounded-lg border border-gray-200">
			<div className="flex items-center gap-2 mb-2">
				<Checkbox id="otp-consent" checked={isChecked} onCheckedChange={(checked) => onCheckChange(!!checked)} />
				<Label htmlFor="otp-consent" className="text-sm">
					{t("phoneVerification.disclaimer.text")}
				</Label>
			</div>
			<p className="text-xs text-gray-400 mt-2">{t("phoneVerification.disclaimer.standardMessage")}</p>
			<div className="flex gap-4">
				<Link to="https://www.instage.io/terms" target="_blank" rel="noopener noreferrer" className="text-xs text-gray-400 hover:underline">
					{t("phoneVerification.disclaimer.terms")}
				</Link>
				<Link to="https://www.instage.io/privacy" target="_blank" rel="noopener noreferrer" className="text-xs text-gray-400 hover:underline">
					{t("phoneVerification.disclaimer.privacy")}
				</Link>
			</div>
		</div>
	);
};

type VerificationCodeInputsProps = {
	verificationCode: string[];
	onChange: (e: React.ChangeEvent<HTMLInputElement>, index: number) => void;
	onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>, index: number) => void;
	onPaste: (e: React.ClipboardEvent<HTMLInputElement>) => void;
};

const VerificationCodeInputs: React.FC<VerificationCodeInputsProps> = ({ verificationCode, onChange, onKeyDown, onPaste }) => (
	<div className="space-y-2">
		<label htmlFor="verificationCode" className="text-sm font-medium text-gray-700">
			Verification Code
		</label>
		<div className="flex space-x-2">
			{[0, 1, 2, 3].map((index) => (
				<input
					key={index}
					id={`verificationCode${index + 1}`}
					type="text"
					inputMode="numeric"
					pattern="[0-9]*"
					maxLength={1}
					value={verificationCode[index] || ""}
					onChange={(e) => onChange(e, index)}
					onKeyDown={(e) => onKeyDown(e, index)}
					onPaste={onPaste}
					className="w-12 h-12 text-center border rounded-md focus:ring-2 focus:ring-[#00A9AF] focus:border-[#00A9AF]"
				/>
			))}
		</div>
	</div>
);
export { CountryCodeSelector, PhoneInputForm, TextMessageDisclaimer, VerificationCodeInputs, VerificationHeader };
