export const AdminSettingsLocale = {
	en: {
		title: "Admin Settings",
		emailReportSettings: {
			title: "Email Report Settings",
			description:
				"These are reports sent to the assignment creator containing an aggregated summary of student activity for each assigned call.",
			allowEmailReports: "Allow email reports",
			doNotAllowEmailReports: "Do not allow email reports"
		},
		studentSignupOptions: {
			title: "Student Signup Options",
			description: "Configure how students can sign up for your account",
			allowGenericSignup: "Allow generic signup form",
			signupUrl: "Signup URL",
			copyAssignmentLink: "Copy signup link to clipboard"
		},
		summaryContentPermissions: {
			title: "Summary Content Permissions",
			description: "Configure which student data assignment creators are allowed to enable/disable.",
			allowUserIdentification: {
				label: "Allow inclusion of student Name and Email",
				ariaLabel: "Allow inclusion of student name and email in reports"
			},
			allowTranscript: {
				label: "Allow inclusion of student transcript",
				ariaLabel: "Allow inclusion of student transcript in reports"
			},
			allowAudio: {
				label: "Allow inclusion of student audio recording",
				ariaLabel: "Allow inclusion of student audio recording in reports"
			},
			note: {
				prefix: "Note:",
				message: "Unchecking any option here will disable it completely when creating assignments."
			}
		},
		studentNotificationSettings: {
			title: "Student Notification Settings",
			description: {
				prefix: "Configure which",
				suffix: "assignment creators are allowed to enable/disable.",
				bold: "email notifications"
			},
			allowNotifyAvailabilityWindow: {
				label: "Allow call availability window open email",
				ariaLabel: "Allow call availability window open email notification",
				tooltip: "This email is sent to students when the availability window for the call opens so that students know when they can start."
			},
			allowNotify24HoursBefore: {
				label: "Allow 24-hour reminder email",
				ariaLabel: "Allow 24-hour reminder email notification",
				tooltip:
					"This email is sent to students 24 hours before the due date of the assignment to remind them that the assignment is due soon."
			},
			allowNotifyDueDate: {
				label: "Allow due date reminder email",
				ariaLabel: "Allow due date reminder email notification",
				tooltip: "This email is sent to students the day the assignment is due to remind them that the assignment is due soon."
			},
			note: {
				prefix: "Note:",
				message: "Unchecking any option here will disable it completely when creating assignments."
			}
		},
		dataStorageLimits: {
			title: "Data Storage Limits",
			audioStorage: {
				label: "Audio File Storage Length (days)",
				ariaLabel: "Audio file storage length in days"
			}
		},
		callTimeLimits: {
			title: "Call Time Limits",
			assignmentCall: {
				label: "Call Time Limit (minutes)",
				ariaLabel: "Assignment call time limit in minutes"
			},
			selfServeCall: {
				label: "Per Student Call Time Limit for Self-Serve Mode (minutes/year)",
				ariaLabel: "Self-serve mode call time limit in minutes per year"
			}
		},
		priorityComments: {
			title: "Priority Comments Settings",
			description:
				"These definitions will be used to improve the detection of priority comments by students during their calls with the AI assistants. Priority comments will be highlighted in the assignment summary reports.",
			highPriority: {
				definition: {
					label: "High Priority Definition",
					placeholder: "Define what constitutes a high priority comment...",
					ariaLabel: "High priority comment definition"
				},
				contactInstructions: {
					label: "High Priority Contact Instructions",
					placeholder: "Provide contact instructions for high priority comments...",
					ariaLabel: "High priority contact instructions"
				}
			},
			mediumPriority: {
				label: "Medium Priority Definition",
				placeholder: "Define what constitutes a medium priority comment...",
				ariaLabel: "Medium priority comment definition"
			},
			lowPriority: {
				label: "Low Priority Definition",
				placeholder: "Define what constitutes a low priority comment...",
				ariaLabel: "Low priority comment definition"
			}
		},
		jobSearchResources: {
			title: "Job Search Resources",
			description: {
				part1: "Provide information about available resources to help students with their co-op/internship search (e.g., career services, job boards, resume workshops).",
				part2: "This information will be used to guide the AI assistant during job search calls with students."
			},
			textarea: {
				placeholder: "List available job search resources and support services...",
				ariaLabel: "Job search resources and support services"
			}
		},
		saveButton: {
			label: "Save Settings",
			ariaLabel: "Save settings",
			toast: {
				success: {
					title: "Settings saved",
					description: "Your changes have been successfully saved."
				},
				error: {
					title: "Error",
					description: "Failed to save settings. Please try again."
				}
			}
		}
	},
	fr: {
		title: "Paramètres de l'administrateur",
		emailReportSettings: {
			title: "Paramètres des rapports par email",
			description:
				"Ces rapports sont envoyés au créateur de l'affectation contenant un résumé agrégé des activités des étudiants pour chaque appel assigné.",
			allowEmailReports: "Autoriser les rapports par email",
			doNotAllowEmailReports: "Ne pas autoriser les rapports par email"
		},
		studentSignupOptions: {
			title: "Options d'inscription des étudiants",
			description: "Configurez comment les étudiants peuvent s'inscrire à votre compte",
			allowGenericSignup: "Autoriser le formulaire d'inscription générique",
			signupUrl: "URL d'inscription",
			copyAssignmentLink: "Copier le lien d'inscription dans le presse-papiers"
		},
		summaryContentPermissions: {
			title: "Autorisations de contenu du résumé",
			description: "Configurez quelles données d'étudiant les créateurs de tâches sont autorisés à activer/désactiver.",
			allowUserIdentification: {
				label: "Autoriser l'inclusion du nom et de l'email de l'étudiant",
				ariaLabel: "Autoriser l'inclusion du nom et de l'email de l'étudiant dans les rapports"
			},
			allowTranscript: {
				label: "Autoriser l'inclusion de la transcription de l'étudiant",
				ariaLabel: "Autoriser l'inclusion de la transcription de l'étudiant dans les rapports"
			},
			allowAudio: {
				label: "Autoriser l'inclusion de l'enregistrement audio de l'étudiant",
				ariaLabel: "Autoriser l'inclusion de l'enregistrement audio de l'étudiant dans les rapports"
			},
			note: {
				prefix: "Remarque :",
				message: "Décocher une option ici la désactivera complètement lors de la création des tâches."
			}
		},
		studentNotificationSettings: {
			title: "Paramètres de notification des étudiants",
			description: {
				prefix: "Configurez quelles",
				suffix: "les créateurs de tâches sont autorisés à activer/désactiver.",
				bold: "notifications par email"
			},
			allowNotifyAvailabilityWindow: {
				label: "Autoriser l'email d'ouverture de la fenêtre de disponibilité",
				ariaLabel: "Autoriser la notification par email d'ouverture de la fenêtre de disponibilité",
				tooltip:
					"Cet email est envoyé aux étudiants lorsque la fenêtre de disponibilité pour l'appel s'ouvre afin qu'ils sachent quand ils peuvent commencer."
			},
			allowNotify24HoursBefore: {
				label: "Autoriser l'email de rappel 24 heures avant",
				ariaLabel: "Autoriser la notification par email de rappel 24 heures avant",
				tooltip:
					"Cet email est envoyé aux étudiants 24 heures avant la date limite de la tâche pour leur rappeler que la tâche est bientôt due."
			},
			allowNotifyDueDate: {
				label: "Autoriser l'email de rappel de la date limite",
				ariaLabel: "Autoriser la notification par email de rappel de la date limite",
				tooltip: "Cet email est envoyé aux étudiants le jour où la tâche est due pour leur rappeler que la tâche est bientôt due."
			},
			note: {
				prefix: "Remarque :",
				message: "Décocher une option ici la désactivera complètement lors de la création des tâches."
			}
		},
		dataStorageLimits: {
			title: "Limites de stockage des données",
			audioStorage: {
				label: "Durée de stockage des fichiers audio (jours)",
				ariaLabel: "Durée de stockage des fichiers audio en jours"
			}
		},
		callTimeLimits: {
			title: "Limites de durée d'appel",
			assignmentCall: {
				label: "Limite de durée d'appel (minutes)",
				ariaLabel: "Limite de durée d'appel pour les tâches en minutes"
			},
			selfServeCall: {
				label: "Limite de durée d'appel par étudiant pour le mode libre-service (minutes/an)",
				ariaLabel: "Limite de durée d'appel pour le mode libre-service en minutes par an"
			}
		},
		priorityComments: {
			title: "Paramètres des commentaires prioritaires",
			description:
				"Ces définitions seront utilisées pour améliorer la détection des commentaires prioritaires par les étudiants lors de leurs appels avec les assistants IA. Les commentaires prioritaires seront mis en évidence dans les rapports de synthèse des tâches.",
			highPriority: {
				definition: {
					label: "Définition de la haute priorité",
					placeholder: "Définissez ce qui constitue un commentaire de haute priorité...",
					ariaLabel: "Définition des commentaires de haute priorité"
				},
				contactInstructions: {
					label: "Instructions de contact pour la haute priorité",
					placeholder: "Fournissez les instructions de contact pour les commentaires de haute priorité...",
					ariaLabel: "Instructions de contact pour les commentaires de haute priorité"
				}
			},
			mediumPriority: {
				label: "Définition de la priorité moyenne",
				placeholder: "Définissez ce qui constitue un commentaire de priorité moyenne...",
				ariaLabel: "Définition des commentaires de priorité moyenne"
			},
			lowPriority: {
				label: "Définition de la basse priorité",
				placeholder: "Définissez ce qui constitue un commentaire de basse priorité...",
				ariaLabel: "Définition des commentaires de basse priorité"
			}
		},
		jobSearchResources: {
			title: "Ressources de recherche d'emploi",
			description: {
				part1: "Fournissez des informations sur les ressources disponibles pour aider les étudiants dans leur recherche de stage/emploi (par exemple, services de carrière, sites d'emploi, ateliers de CV).",
				part2: "Ces informations seront utilisées pour guider l'assistant IA lors des appels de recherche d'emploi avec les étudiants."
			},
			textarea: {
				placeholder: "Listez les ressources et services de soutien disponibles pour la recherche d'emploi...",
				ariaLabel: "Ressources et services de soutien pour la recherche d'emploi"
			}
		},
		saveButton: {
			label: "Enregistrer les paramètres",
			ariaLabel: "Enregistrer les paramètres",
			toast: {
				success: {
					title: "Paramètres enregistrés",
					description: "Vos modifications ont été enregistrées avec succès."
				},
				error: {
					title: "Erreur",
					description: "Échec de l'enregistrement des paramètres. Veuillez réessayer."
				}
			}
		}
	}
};
