import React from "react";
import { DateRange } from "react-day-picker";
import { DatePickerWithRange } from "../../shadcn-ui/DatePickerWithRange";
import { subMonths } from "date-fns";

interface DateRangeFilterProps {
	dateRange: DateRange;
	onDateRangeChange: (dateRange: DateRange) => void;
}

const DateRangeFilter: React.FC<DateRangeFilterProps> = ({ dateRange, onDateRangeChange }) => {
	// Set default date range to last month if not provided
	const [initialLoad, setInitialLoad] = React.useState(true);
	React.useEffect(() => {
		if (!dateRange.from && !dateRange.to && initialLoad) {
			const today = new Date();
			const lastMonth = subMonths(today, 1);
			onDateRangeChange({ from: lastMonth, to: today });
			setInitialLoad(false);
		}
	}, [dateRange, onDateRangeChange, initialLoad]);

	// Convert the date range tuple to the format expected by DatePickerWithRange
	const dateRangeForPicker = dateRange.from && dateRange.to ? ({ from: dateRange.from, to: dateRange.to } as DateRange) : undefined;

	// Convert the DatePickerWithRange format back to our tuple format
	const handleDateRangeChange = (range: DateRange | undefined) => {
		if (range) {
			if (!range.to) {
				onDateRangeChange({ from: range.from, to: range.from });
			} else {
				onDateRangeChange({ from: range.from, to: range.to });
			}
			// if (range.from) {
			// 	onDateRangeChange({ ...dateRange, from: range.from });
			// }
			// if (range.to) {
			// 	onDateRangeChange({ ...dateRange, to: range.to });
			// }
			console.log("range", range);
			// if (range) {
			// 	onDateRangeChange([range.from, range.to]);
			// } else {
			// 	onDateRangeChange([undefined, undefined]);
			// }
		}
	};

	return (
		<div className="flex justify-between items-center mb-4">
			<DatePickerWithRange dateRange={dateRangeForPicker} onDateRangeChange={handleDateRangeChange} />
		</div>
	);
};

export default DateRangeFilter;
