export const InterviewSetupLocale = {
	en: {
		previous: "Previous",
		next: "Next",
		customizeYourInterviewAssignment: {
			title: "Customize Your Interview",
			description:
				"Configure the details of each selected interview stage. You can adjust question lists and set the interviewer's attitude. After this, you'll proceed to define the assignment details, send invitations, and set up notifications."
		},
		customizeYourInterviewPractice: {
			description:
				"Design your practice interview experience. You can customize the questions for each stage and set the interviewer's style. This will help you prepare for real interviews in your desired role."
		},
		questionPanel: {
			selectPreset:
				"Select a preset question order, or customize the sequence and type of questions. You can add, remove, or reorder questions to match your interview goals.",
			customizeQuestions:
				"Customize the questions for each stage and set the interviewer's style. You can add, remove, or reorder questions to match your interview goals.",
			interviewPlan: "Interview Plan",
			stages: {
				"Screening Interview": {
					title: "Screening Interview",
					description: "Initial screening stage for candidate evaluation"
				},
				"Middle Interview": {
					title: "Middle Interview",
					description: "Additional interview stage for candidate evaluation"
				},
				"Final Interview": {
					title: "Final Interview",
					description: "Concluding interview stage for candidate evaluation"
				}
			}
		},
		interviewPlan: {
			totalQuestions: "Total Questions",
			estimatedDuration: "Estimated Duration",
			minutes: "minutes",
			questionList: "Question List",
			SelectPreset: "Select a preset question order",
			orCustomize: "Or customize the sequence and type of questions",
			autoGeneratedQuestion: "Auto-generated question",
			addNewQuestion: "Add New Question",
			maximumQuestionLimitReached: "Maximum question limit reached (15 questions)",
			configureNewQuestion: "Configure a new question for this interview stage.",
			questionType: "Question Type",
			selectQuestionType: "Select Question Type",
			questionText: "Question Text",
			AutoGeneratedQuestion: "Auto-generated question",
			modelResponse: "Model Response",
			optional: "Optional",
			custom: "Custom",
			selectTextType: "Select Text Type",
			customQuestionText: "Enter your custom question...",
			modelResponseText: "Enter a model response to the question...",
			addQuestion: "Add Question",
			cancel: "Cancel",
			interviewerAttitude: "Interviewer Attitude",
			selectInterviewerAttitude: "Select Interviewer Attitude",
			Neutral: "Neutral",
			Friendly: "Friendly",
			Direct: "Direct",
			neutral: "neutral",
			friendly: "friendly",
			direct: "direct"
		},
		questionTypes: {
			Opening: "Opening",
			Standard: "Standard",
			Behavioral: "Behavioral",
			Technical: "Technical",
			Screening: "Screening",
			Final: "Final"
		},
		types: {
			Behavioral: "Behavioral",
			Technical: "Technical",
			Situational: "Situational",
			"Cultural Fit": "Cultural Fit",
			Background: "Background",
			Motivation: "Motivation",
			Recall: "Recall",
			Opening: "Opening",
			Closing: "Closing",
			General: "General",
			Resume: "Resume"
		},
		questionPresets: {
			Standard: {
				name: "Standard",
				description: "Balanced mix of different question types"
			},
			Screening: {
				name: "Screening",
				description: "Screening questions"
			},
			Technical: {
				name: "Technical",
				description: "Heavy emphasis on technical skills assessment"
			},
			Behavioral: {
				name: "Behavioral",
				description: "Focus on behavioral and cultural assessment"
			},
			Final: {
				name: "Final",
				description: "Cultural fit and motivation assessment"
			}
		}
	},
	fr: {
		previous: "Précédent",
		next: "Suivant",
		customizeYourInterviewAssignment: {
			title: "Personnaliser votre entretien",
			description:
				"Configurez les détails de chaque stage d'entretien sélectionné. Vous pouvez ajuster les listes de questions et définir l'attitude de l'interviewer. Après cela, vous pourrez passer à la définition des détails de l'affectation, envoyer des invitations et configurer les notifications."
		},
		customizeYourInterviewPractice: {
			description:
				"Personnalisez votre expérience d'entretien pratique. Vous pouvez personnaliser les questions pour chaque stage et définir l'attitude de l'interviewer. Cela vous aidera à vous préparer aux entretiens réels dans le rôle de votre choix."
		},
		questionPanel: {
			selectPreset:
				"Sélectionnez un ordre de questions prédéfini, ou personnalisez la séquence et le type de questions. Vous pouvez ajouter, supprimer ou réorganiser les questions pour correspondre à vos objectifs d'entretien.",
			customizeQuestions:
				"Personnalisez les questions pour chaque stage et définissez l'attitude de l'interviewer. Vous pouvez ajouter, supprimer ou réorganiser les questions pour correspondre à vos objectifs d'entretien.",
			interviewPlan: "Plan d'entretien",
			stages: {
				"Screening Interview": {
					title: "Entretien de sélection",
					description: "Initial screening stage for candidate evaluation"
				},
				"Middle Interview": {
					title: "Entretien intermédiaire",
					description: "Stage d'entretien supplémentaire pour l'évaluation du candidat"
				},
				"Final Interview": {
					title: "Entretien final",
					description: "Stage d'entretien final pour l'évaluation du candidat"
				}
			}
		},
		interviewPlan: {
			totalQuestions: "Questions totales",
			estimatedDuration: "Durée estimée",
			minutes: "minutes",
			questionList: "Liste des questions",
			SelectPreset: "Sélectionnez un ordre de questions prédéfini",
			orCustomize: "Ou personnalisez la séquence et le type de questions",
			autoGeneratedQuestion: "Question auto-générée",
			addNewQuestion: "Ajouter une nouvelle question",
			maximumQuestionLimitReached: "Limite de questions atteinte (15 questions)",
			configureNewQuestion: "Configurer une nouvelle question pour ce stade d'entretien.",
			questionType: "Type de question",
			selectQuestionType: "Sélectionnez le type de question",
			questionText: "Texte de la question",
			AutoGeneratedQuestion: "Question auto-générée",
			modelResponse: "Réponse modèle",
			optional: "Optionnel",
			custom: "Personnalisé",
			selectTextType: "Sélectionnez le type de texte",
			customQuestionText: "Entrez votre question personnalisée...",
			modelResponseText: "Entrez une réponse modèle à la question...",
			cancel: "Annuler",
			addQuestion: "Ajouter une question",
			interviewerAttitude: "Attitude de l'interviewer",
			selectInterviewerAttitude: "Sélectionnez l'attitude de l'interviewer",
			Neutral: "Neutre",
			Friendly: "Amical",
			Direct: "Direct",
			neutral: "neutre",
			friendly: "amical",
			direct: "direct"
		},
		questionTypes: {
			Opening: "Ouverture",
			Standard: "Standard",
			Behavioral: "Comportemental",
			Technical: "Technique",
			Screening: "Sélection",
			Final: "Final"
		},
		types: {
			Opening: "Ouverture",
			Behavioral: "Comportemental",
			Technical: "Technique",
			Situational: "Situationnel",
			"Cultural Fit": "Culturel",
			Background: "Arrière-plan",
			Motivation: "Motivation",
			Recall: "Rappel",
			General: "Général",
			Closing: "Fermeture",
			Resume: "CV"
		},
		questionPresets: {
			Standard: {
				name: "Standard",
				description: "Mélange équilibré de différents types de questions"
			},
			Screening: {
				name: "Sélection",
				description: "Questions de sélection"
			},
			Technical: {
				name: "Technique",
				description: "Forte emphase sur l'évaluation des compétences techniques"
			},
			Behavioral: {
				name: "Comportemental",
				description: "Forte emphase sur l'évaluation du comportement et de la culture"
			},
			Final: {
				name: "Final",
				description: "Évaluation de l'ajustement culturel et de la motivation"
			}
		}
	}
};
