import React, { useState } from "react";
import { useTranslation } from "../../Utility/UseTranslation";

import { Button } from "../../shadcn-ui/Button";

import { UserAssignmentView } from "../../../types/UserAssignmentView";

import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter, DialogDescription } from "../../shadcn-ui/Dialog";
import { TrashIcon } from "@heroicons/react/20/solid";

import { Tooltip, TooltipContent, TooltipTrigger, TooltipProvider } from "../../shadcn-ui/Tooltip";

const DeleteConfirmationModal = ({
	showDeleteConfirmation,
	setShowDeleteConfirmation,
	handleDeleteDelete,
	sessionId
}: {
	showDeleteConfirmation: boolean;
	setShowDeleteConfirmation: (show: boolean) => void;
	handleDeleteDelete: (sessionId: string) => void;
	sessionId: string;
}) => {
	const [deleteError, setDeleteError] = useState<string | null>(null);
	const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
	return (
		<Dialog open={showDeleteConfirmation} onOpenChange={setShowDeleteConfirmation}>
			<DialogContent>
				<DialogHeader>
					<DialogTitle>Delete Session</DialogTitle>
					<DialogDescription>Are you sure you want to delete this session? This action cannot be undone.</DialogDescription>
				</DialogHeader>
				{deleteError && (
					<div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded relative" role="alert">
						<span className="block sm:inline">{deleteError}</span>
					</div>
				)}
				<div className="flex justify-end space-x-2">
					<Button
						variant="outline"
						onClick={() => {
							setShowDeleteConfirmation(false);
							setDeleteError(null);
						}}
						disabled={deleteLoading}
						aria-label="Cancel delete assignment"
					>
						Cancel
					</Button>
					<Button
						variant="destructive"
						onClick={async () => {
							if (sessionId) {
								setDeleteLoading(true);
								await handleDeleteDelete(sessionId);
								setDeleteLoading(false);
							}
						}}
						disabled={deleteLoading}
						aria-label={deleteLoading ? "Deleting session..." : "Confirm delete session"}
					>
						{deleteLoading ? (
							<>
								<svg
									className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									aria-hidden="true"
								>
									<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
									<path
										className="opacity-75"
										fill="currentColor"
										d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
									/>
								</svg>
								Deleting...
							</>
						) : (
							"Delete"
						)}
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export const DeleteSessionButtonSection = ({
	sessionId,
	assignment,
	handleDeleteSession,
	allowedToDelete
}: {
	sessionId: string;
	assignment: UserAssignmentView | null;
	handleDeleteSession: (sessionId: string) => void;
	allowedToDelete: boolean;
}) => {
	const [showDeleteDialog, setShowDeleteDialog] = useState(false);
	const { t } = useTranslation("userSessionPage");

	if (!allowedToDelete) return null;

	return (
		<>
			<Button
				variant="ghost"
				size="sm"
				className="ml-4 text-red-500 hover:text-red-700 hover:bg-red-50 ml-auto"
				onClick={() => setShowDeleteDialog(true)}
			>
				{t("general.deleteSession")}
			</Button>

			<Dialog open={showDeleteDialog} onOpenChange={setShowDeleteDialog}>
				<DialogContent>
					<DialogHeader>
						<DialogTitle>{t("general.deleteSessionTitle")}</DialogTitle>
						<DialogDescription>{t("general.deleteSessionDescription")}</DialogDescription>
					</DialogHeader>
					<DialogFooter>
						<Button variant="outline" onClick={() => setShowDeleteDialog(false)}>
							{t("general.noCancel")}
						</Button>
						<Button
							variant="destructive"
							onClick={() => {
								handleDeleteSession(sessionId);
								setShowDeleteDialog(false);
							}}
						>
							{t("general.yesDelete")}
						</Button>
					</DialogFooter>
				</DialogContent>
			</Dialog>
		</>
	);
};
