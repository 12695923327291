export const ResumeSelectorLocale = {
	en: {
		title: "Resume Composer",
		ResumeForInterview: "Resume for Interview",
		columnHeaders: {
			title: "Title",
			jobTitle: "Job Title",
			matchScore: "Match Score",
			createdAt: "Created At",
			updatedAt: "Updated At",
			actions: "Actions",
			edit: "Edit",
			rename: "Rename",
			delete: "Delete"
		},
		filters: {
			searchTitles: "Search titles...",
			searchJobTitles: "Search job titles..."
		},
		Dialog: {
			MobileDeviceDetected: "Mobile Device Detected",
			MobileDeviceDetectedDescription:
				"This tool is not recommended for mobile use. For the best experience, please use a desktop or laptop computer.",
			Understand: "I Understand"
		},
		Step1: {
			title: "Step 1: Your Profile",
			description:
				"Create your profile to provide details about your work experience, education, and skills. This information will be used to generate your resume."
		},
		Step2: {
			title: "Step 2: Your Resumes",
			description: "Resumes are generated based on the information in your profile.",
			createResume: "Create Resume"
		}
	},
	fr: {
		title: "Composer un CV",
		ResumeForInterview: "CV pour l'entretien",
		columnHeaders: {
			title: "Titre",
			jobTitle: "Titre du travail",
			matchScore: "Score de correspondance",
			createdAt: "Créé le",
			updatedAt: "Mis à jour le",
			actions: "Actions",
			edit: "Modifier",
			rename: "Renommer",
			delete: "Supprimer"
		},
		filters: {
			searchTitles: "Rechercher par titre...",
			searchJobTitles: "Rechercher par titre du travail..."
		},
		Dialog: {
			MobileDeviceDetected: "Appareil mobile détecté",
			MobileDeviceDetectedDescription:
				"Cet outil n'est pas recommandé pour les appareils mobiles. Pour la meilleure expérience, veuillez utiliser un ordinateur de bureau ou un ordinateur portable.",
			Understand: "Je comprends"
		},
		Step1: {
			title: "Étape 1: Votre profil",
			description:
				"Créez votre profil pour fournir des détails sur votre expérience de travail, votre formation et vos compétences. Cette information sera utilisée pour générer votre CV."
		},
		Step2: {
			title: "Étape 2: Vos CV",
			description: "Les CV sont générés sur la base de l'information dans votre profil.",
			createResume: "Créer un CV"
		}
	}
};
