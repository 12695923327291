import React from "react";
import { Button } from "../shadcn-ui/Button";
import { Input } from "../shadcn-ui/Input";
import { ClipboardIcon } from "@heroicons/react/20/solid";

interface CopyUrlProps {
	url: string;
	label?: string;
}

const CopyUrl: React.FC<CopyUrlProps> = ({ url, label = "Copy URL to clipboard" }) => {
	const copyToClipboard = () => {
		navigator.clipboard.writeText(url);
	};

	return (
		<div className="flex items-center space-x-2">
			<Input type="text" id="link" value={url} readOnly />
			<Button variant="outline" size="icon" onClick={copyToClipboard} aria-label={label}>
				<ClipboardIcon className="h-4 w-4" aria-hidden="true" />
				<span className="sr-only">{label}</span>
			</Button>
		</div>
	);
};

export default CopyUrl;
