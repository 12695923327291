import React, { useState, useEffect } from "react";
import axios from "axios";
import Spinner from "../Utility/Spinner";
import { useTranslation } from "../Utility/UseTranslation";

interface ResumeUploadProps {
	profileId: string;
	setProfile: (profile: any) => void;
	profile: any;
	onClose: () => void;
}

export const ResumeUpload: React.FC<ResumeUploadProps> = ({ profileId, setProfile, profile, onClose }) => {
	const backendUrl = `${process.env.REACT_APP_BACKEND_URL}/api/profile`;
	const [uploading, setUploading] = useState(false);
	const [isResumeUploaded, setIsResumeUploaded] = useState(false);
	const { t } = useTranslation("profile");

	useEffect(() => {
		console.log("Profile:", profile);
	}, [profile]);

	const onUploadSuccess = (profile: any) => {
		console.log("Upload success:", profile);
		setIsResumeUploaded(true);
		onClose();
	};

	const onUploadError = (error: string) => {
		console.error("Upload error:", error);
	};

	const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];
		if (!file) return;

		const formData = new FormData();
		formData.append("resume", file);
		formData.append("profileId", profileId);

		setUploading(true);
		try {
			const response = await axios.post(`${backendUrl}/upload-resume`, formData, {
				headers: {
					"Content-Type": "multipart/form-data"
				}
			});
			setProfile(response.data.profile);
			console.log("Response:", response.data);
			onUploadSuccess?.(response.data.profile);
		} catch (error) {
			console.error("Error uploading file:", error);
			onUploadError?.(error instanceof Error ? error.message : "Failed to upload file");
		} finally {
			setUploading(false);
		}
	};

	return (
		<div className="pb-4">
			<>
				<input type="file" accept=".pdf,.doc,.docx" onChange={handleFileChange} disabled={uploading} className="hidden" id="resume-upload" />
				<label
					htmlFor="resume-upload"
					className="flex flex-col items-center p-4 border-2 border-dashed border-[#00a9af] rounded-lg cursor-pointer hover:bg-[#00a9af]/5"
				>
					{uploading ? (
						<>
							<Spinner className="mb-2" />
							<span className="text-sm text-gray-600">{t("buttons.resumeUpload.uploading")}</span>
						</>
					) : (
						<>
							<span className="text-sm text-gray-600 mb-2">{t("buttons.resumeUpload.button")}</span>
							<span className="text-xs text-gray-500">{t("buttons.resumeUpload.fileTypes")}</span>
							<p className="text-xs text-gray-500 whitespace-pre-wrap break-words text-center mt-2">
								{t("buttons.resumeUpload.description")}
							</p>
						</>
					)}
				</label>
			</>

			{/* {isResumeUploaded && (
				<>
					<input
						type="file"
						accept=".pdf,.doc,.docx"
						onChange={handleFileChange}
						disabled={uploading}
						className="hidden"
						id="resume-upload"
					/>
					<label
						htmlFor="resume-upload"
						className="flex flex-col items-center p-4 border-2 border-dashed border-gray-300 rounded-lg cursor-pointer hover:bg-gray-50"
					>
						<span className="text-sm text-gray-600 mb-2">
							{uploading ? t("buttons.resumeUpload.uploading") : t("buttons.resumeUpload.reupload")}
						</span>
						<span className="text-xs text-gray-500">{t("buttons.resumeUpload.fileTypes")}</span>
					</label>
				</>
			)} */}
		</div>
	);
};
