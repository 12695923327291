import React, { useState, useEffect } from "react";
import { isValidPhoneNumber } from "libphonenumber-js";
import { Button } from "../shadcn-ui/Button";
import { Input } from "../shadcn-ui/Input";
import { Label } from "../shadcn-ui/Label";
import { Card, CardTitle, CardContent } from "../shadcn-ui/Card";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../shadcn-ui/Select";
import { UserService } from "../../services/UserService";
import posthog from "posthog-js";
import { useAuth } from "../../contexts/AuthContext";
import { TextMessageDisclaimer, VerificationHeader } from "./phoneVerification/phoneVerificationComponents";
import { useTranslation } from "../Utility/UseTranslation";

interface PhoneVerificationProps {
	onVerificationComplete: (phoneNumber: string) => void;
	onCancel: () => void;
}

const countryOptions = [
	{ value: "+1-CA", label: "CA", flag: "🇨🇦" },
	{ value: "+1-US", label: "US", flag: "🇺🇸" }
];

const PhoneVerification: React.FC<PhoneVerificationProps> = ({ onVerificationComplete, onCancel }) => {
	const [phoneNumber, setPhoneNumber] = useState("");
	const [unformattedPhoneNumber, setUnformattedPhoneNumber] = useState("");
	const [countryCode, setCountryCode] = useState(countryOptions[0].value);
	const [error, setError] = useState("");
	const { instageUser } = useAuth();

	const [numberValid, setNumberValid] = useState(false);
	const [verificationCode, setVerificationCode] = useState<string[]>(["", "", "", ""]);
	const [isConsentChecked, setIsConsentChecked] = useState(false);
	const { t } = useTranslation("extraUtility");

	useEffect(() => {
		const fullCode = Object.values(verificationCode).join("");
		if (fullCode.length === 4) {
			console.log("verifying code");
			UserService.verifyPhoneNumber(fullCode).then((verified) => {
				if (verified) {
					console.log("VERIFIED");
					onVerificationComplete(unformattedPhoneNumber);
				} else {
					setError("Invalid verification code");
				}
			});
		}
	}, [verificationCode, onVerificationComplete, unformattedPhoneNumber]);

	const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value.replace(/\D/g, "");
		setError("");
		if (value.length <= 10) {
			setUnformattedPhoneNumber(value);
			const formatted = value.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
			setPhoneNumber(formatted);
		}
		if (value.length <= 1 && instageUser) {
			// console.log("started phone verification");
			try {
				posthog.capture("MINOR: Started Phone Verification", { userId: instageUser.fronteggId, timestamp: new Date().toISOString() });
			} catch (err) {
				console.error("Error capturing event 'MINOR: Started Phone Verification':", err);
			}
		}
	};

	const handleVerificationCodeChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
		const { value } = e.target;

		if (value.length <= 1) {
			setVerificationCode((prev) => {
				const newV = [...prev];
				newV[index] = value;
				return newV;
			});

			// Move focus to the next input if a digit is entered
			if (value.length === 1 && index < 3) {
				const nextInput = document.getElementById(`verificationCode${index + 2}`);
				if (nextInput) {
					(nextInput as HTMLInputElement).focus();
				}
			}
			// Move focus to the previous input if a digit is erased
			else if (value.length === 0 && index > 0) {
				const prevInput = document.getElementById(`verificationCode${index}`);
				if (prevInput) {
					(prevInput as HTMLInputElement).focus();
				}
			}
		} else if (value.length > 1) {
			// Handle pasting multiple digits
			const digits = value.replace(/\D/g, "").slice(0, 4).split("");
			const newVerificationCode = [...verificationCode];

			digits.forEach((digit, i) => {
				if (index + i < 4) {
					newVerificationCode[index + i] = digit;
				}
			});

			setVerificationCode(newVerificationCode);

			// Focus on the next empty input or the last input
			const nextEmptyIndex = newVerificationCode.findIndex((v) => v === "");
			const nextInputId = nextEmptyIndex !== -1 ? `verificationCode${nextEmptyIndex + 1}` : "verificationCode4";
			const nextInput = document.getElementById(nextInputId);
			if (nextInput) {
				(nextInput as HTMLInputElement).focus();
			}
		}
	};

	const handleVerificationCodeKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
		if (e.key === "Backspace" && index > 0 && verificationCode[index] === "") {
			e.preventDefault();
			const prevInput = document.getElementById(`verificationCode${index}`);
			if (prevInput) {
				(prevInput as HTMLInputElement).focus();
			}
		}
	};

	const handleVerificationCodeFocus = (e: React.FocusEvent<HTMLInputElement>) => {
		if (e.target.value) {
			e.target.select();
		}
	};

	const handleVerificationCodePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
		e.preventDefault();
		const pastedData = e.clipboardData.getData("text").replace(/\D/g, "").slice(0, 4);
		const newVerificationCode = [...verificationCode];

		pastedData.split("").forEach((digit, index) => {
			if (index < 4) {
				newVerificationCode[index] = digit;
			}
		});

		setVerificationCode(newVerificationCode);

		// Focus on the next empty input or the last input
		const nextEmptyIndex = newVerificationCode.findIndex((v) => v === "");
		const nextInputId = nextEmptyIndex !== -1 ? `verificationCode${nextEmptyIndex + 1}` : "verificationCode4";
		const nextInput = document.getElementById(nextInputId);
		if (nextInput) {
			(nextInput as HTMLInputElement).focus();
		}
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		const countryCodeOnly = countryCode.split("-")[0];
		const fullNumber = `${countryCodeOnly}${unformattedPhoneNumber}`;
		if (isValidPhoneNumber(fullNumber)) {
			try {
				await UserService.addPhoneNumber(fullNumber);

				onVerificationComplete(unformattedPhoneNumber);

				// await UserService.sendVerificationSMS();
				setNumberValid(true);
			} catch (error) {
				console.error("Error adding phone number:", error);
			}
		} else {
			setError("Please enter a valid phone number");
		}
	};

	return (
		<Card className="max-w-md mx-auto p-4">
			<CardContent className="space-y-4 p-0">
				<VerificationHeader />
				<div className="p-0 space-y-4">
					<div>
						<CardTitle className="mb-2 text-[#101828] text-xl font-bold">{t("phoneVerification.title")}</CardTitle>
						<p className="text-gray-600">{t("phoneVerification.description")}</p>
					</div>
					<form onSubmit={handleSubmit} className="space-y-4">
						<div className="space-y-2">
							{!numberValid && (
								<div className="flex space-x-2">
									<Select value={countryCode} onValueChange={setCountryCode}>
										<SelectTrigger className="w-[80px]">
											<SelectValue>{countryOptions.find((option) => option.value === countryCode)?.flag}</SelectValue>
										</SelectTrigger>
										<SelectContent>
											{countryOptions.map((option) => (
												<SelectItem key={option.value} value={option.value}>
													<span className="flex items-center">
														<span className="mr-2">{option.flag}</span>
														{option.label}
													</span>
												</SelectItem>
											))}
										</SelectContent>
									</Select>
									<Input
										id="phone"
										type="tel"
										placeholder="(555) 000-0000"
										value={phoneNumber}
										onChange={handlePhoneChange}
										className="flex-1 text-gray-800 placeholder-gray-400"
										required
									/>
								</div>
							)}

							{numberValid && (
								<div className="space-y-2">
									<Label htmlFor="verificationCode" className="text-sm font-medium text-gray-700">
										{t("phoneVerification.verificationCode")}
									</Label>
									<div className="flex space-x-2">
										{[0, 1, 2, 3].map((index) => (
											<Input
												key={index}
												id={`verificationCode${index + 1}`}
												type="text"
												inputMode="numeric"
												pattern="[0-9]*"
												maxLength={2}
												value={verificationCode[index] || ""}
												onChange={(e) => handleVerificationCodeChange(e, index)}
												onKeyDown={(e) => handleVerificationCodeKeyDown(e, index)}
												onFocus={handleVerificationCodeFocus}
												onPaste={handleVerificationCodePaste}
												className="w-12 text-gray-800 placeholder-gray-400 text-center"
												required
											/>
										))}
									</div>
								</div>
							)}

							{error && <p className="text-sm text-red-500 mt-1">{error}</p>}
						</div>
						<TextMessageDisclaimer isChecked={isConsentChecked} onCheckChange={setIsConsentChecked} />
						{!numberValid && (
							<div className="flex space-x-4">
								<Button type="button" variant="outline" className="w-1/2" onClick={onCancel}>
									{t("phoneVerification.disclaimer.cancel")}
								</Button>
								<Button type="submit" className="w-1/2 bg-[#00A9AF] hover:bg-[#008C8F] text-white" disabled={!isConsentChecked}>
									{t("phoneVerification.disclaimer.verifyNumber")}
								</Button>
							</div>
						)}
					</form>
				</div>
			</CardContent>
		</Card>
	);
};

export default PhoneVerification;
