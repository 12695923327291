import React, { useEffect, useMemo, useRef } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "../../Utility/UseTranslation";

import { SessionView } from "../../../types/SessionView";
import { SessionService } from "../../../services/SessionService";

import { AssignedSessionView } from "../../../types/AssignedSessionView";
import { XCircleIcon } from "@heroicons/react/24/solid";

import BackButton from "../../Utility/BackButton";
import HorizontalTabs from "../../Utility/HorizontalTabs";

import { Button } from "../../shadcn-ui/Button";

import { UserAssignmentView } from "../../../types/UserAssignmentView";

import { normalizeCompetencyName } from "../../Utility/reportUtility";

import { assistants } from "../../../constants/Assistants";

import { AssignedSessionStatus } from "../../../types/AssignedSessionStatus";

import { Report } from "../../Utility/Report";

import { UserCircle } from "lucide-react";

import Spinner from "../../Utility/Spinner";
import UserInterviewPlan from "../UserInterviewPlan";
import { TabData } from "../../Utility/HorizontalTabs";

import { ExperienceType } from "../../../schemas/ExperienceType";
import TokenManager from "../../../services/TokenManager";

import { DeleteSessionButtonSection } from "./DeleteSessionButtonSection";
import { ResumeSelectSection } from "./ResumeSelectSection";

import { useUserSessionPage } from "./useUserSessionPage";
import { JobDetailsSection } from "./JobDetailsSection";
import { StartOptionsSection, RestartOptionsSection } from "./StartOptions";

import { ConfidentialityDialog } from "./ConfidentialityDialog";

const InfoSection = ({
	assignment,
	selectedScheduleId,
	assignmentId,
	userId,
	debugMode,
	selectedAssignedSession,
	selectedUserSession,
	t
}: {
	assignment: UserAssignmentView;
	selectedScheduleId: string;
	assignmentId: string;
	userId: string;
	debugMode: boolean;
	selectedAssignedSession: AssignedSessionView | null;
	selectedUserSession: SessionView | null;
	t: (key: string) => string;
}) => {
	return (
		<div className="text-gray-600 mb-8">
			<p className="mb-2 text-sm font-semibold">
				<span className="font-semibold">{t("general.course")}: </span>
				<span className="font-normal">{assignment.courseName}</span>
			</p>
			<p className="text-sm">
				<span className="font-semibold">{t("general.description")}: </span>
				{assignment.assignmentDescription}
			</p>
			{debugMode && (
				<>
					<p className="text-sm mt-2">scheduleId: {selectedScheduleId}</p>
					<p className="text-sm">assignmentId: {assignmentId}</p>
					<p className="text-sm">userId: {userId}</p>
					{debugMode && selectedAssignedSession && selectedAssignedSession?.status && <div>status: {selectedAssignedSession?.status}</div>}
					{selectedUserSession && (
						<p className="text-sm">
							plGroupIdUrl:{" "}
							<a className="underline text-blue-600" target="_blank" rel="noreferrer" href={selectedUserSession.plGroupIdUrl}>
								{selectedUserSession.plGroupIdUrl}
							</a>
						</p>
					)}
				</>
			)}
		</div>
	);
};

const UserIdentificationSection = ({
	includeUserIdentification,
	email,
	t
}: {
	includeUserIdentification: boolean;
	email: string;
	t: (key: string) => string;
}) => {
	return (
		<p>
			{includeUserIdentification && (
				<>
					<span className="font-semibold">{t("general.for")}: </span>
					<span className="font-normal">{email}</span>
				</>
			)}
		</p>
	);
};

const SoloModeTag = ({ soloMode, t }: { soloMode: boolean; t: (key: string) => string }) => {
	return (
		<>
			{soloMode && (
				<span className="ml-3 inline-flex items-center px-2 py-1 text-sm font-medium rounded bg-purple-100 text-purple-800 border border-purple-200 align-middle">
					<UserCircle className="w-4 h-4 mr-1" />
					{t("general.selfPractice")}
				</span>
			)}
		</>
	);
};

const UserSessionPage: React.FC = () => {
	const { assignmentId, userId, scheduleId } = useParams<{ assignmentId: string; userId: string; scheduleId: string }>();
	const { t } = useTranslation("userSessionPage");

	const {
		debugMode,
		checkIfAvailable,
		activeAssistant,
		setActiveAssistant,
		loggedInUserId,
		instageUser,
		resumeAssistFeatureFlag,
		resumeAssistTenantFeatureFlag,
		sessions,
		isLoading,
		isStartingSession,
		assignedSessions,
		selectedScheduleId,
		assignment,
		isDetailsVisible,
		showResumeOptions,
		startNewSession,
		isRegenerating,
		showConfidentialityDialog,
		pendingAction,
		isPlaying,
		setIsPlaying,
		showJobPost,
		jobTitle,
		jobDescription,
		isGeneratingJob,
		jobPostings,
		isLoadingJobPosting,
		resumes,
		selectedResumeId,
		selectedResume,
		selectedTab,
		selectedAssignedSession,
		selectedUserSession,
		isJobGenerated,

		handleTabSelect,
		handleResumeSelection,
		handleGenerateJobDetails,
		handleJobTitleChange,
		handleConfidentialityDialogOnOpenChange,
		handleRestartClick,
		handleCancelResumeSession,
		handleInitiateSession,
		handleJobDescriptionChange,
		handleToggleJobPost,
		handleJobSelection,
		startSession,
		regenerateSummary
	} = useUserSessionPage({ assignmentId: assignmentId || null, userId: userId || null, scheduleId: scheduleId || null });

	const audioRef = useRef<HTMLAudioElement | null>(null);

	const isAdmin = TokenManager.hasAdminClearance();
	const isOwner = loggedInUserId === userId;
	const isInStageAdmin = TokenManager.hasInstageAdminClearance();
	const isSubmitted = selectedAssignedSession?.status !== AssignedSessionStatus.SUBMITTED;
	const allowedToDelete = isInStageAdmin || (isOwner! && isSubmitted) || (isAdmin && isSubmitted);

	const tabs: TabData[] = assignedSessions
		?.sort((a, b) => a.scheduleNumber - b.scheduleNumber)
		.map((session, index) => {
			const overwriteStatus = getOverwrittenStatus(session, index);

			return {
				label: session.scheduleDescription,
				status: overwriteStatus,
				date: session.status === AssignedSessionStatus.SUBMITTED ? session.submittedAt : session.date,
				scheduleId: session.scheduleId,
				activityType: session.activityType,
				interviewStageOrderNumber: session.interviewStageOrderNumber ?? undefined,
				interviewStageType: session.interviewStageType ?? undefined,
				careerPathStageOrderNumber: session.careerPathStageOrderNumber ?? undefined,
				careerPathStageType: session.careerPathStageType ?? undefined,
				frontlineStageOrderNumber: session.frontlineStageOrderNumber ?? undefined,
				frontlineStageType: session.frontlineStageType ?? undefined
			};
		});

	useEffect(() => {
		const audioElement = audioRef.current;
		const handleEnded = () => setIsPlaying(false);

		if (audioElement) {
			audioElement.addEventListener("ended", handleEnded);
		}

		return () => {
			if (audioElement) {
				audioElement.removeEventListener("ended", handleEnded);
			}
		};
	}, []);

	function getUserName(assignedSessions: AssignedSessionView) {
		if (assignedSessions.firstName && assignedSessions.firstName !== "") {
			return assignedSessions.lastName ? `${assignedSessions.firstName} ${assignedSessions.lastName}` : assignedSessions.firstName;
		}
		return assignedSessions.email?.split("@")[0] || "student"; // TODO FIND WHY IT CAN BE NULL
	}

	const generateReportScoreData = (sessions: SessionView[], currentScheduleId: string) => {
		// TODO move to backend
		// Sort sessions by submittedAt ascending (oldest first)
		const sortedSessions = [...sessions].sort((a, b) => new Date(a.submittedAt).getTime() - new Date(b.submittedAt).getTime());

		// Find the index of the current session
		const currentSessionIndex = sortedSessions.findIndex((session) => session.scheduleId === currentScheduleId);

		if (currentSessionIndex === -1) return { satisfactionData: [], competencyData: {} };

		// Include all sessions up to and including the current one
		const relevantSessions = sortedSessions.slice(0, currentSessionIndex + 1);

		// Extract satisfaction scores
		const satisfactionData = relevantSessions
			.filter((session) => session.satisfaction && typeof session.satisfaction.score === "number")
			.map((session) => session.satisfaction!.score);

		// Extract all unique competency names (case-insensitive and ignore hyphens)
		const competencyNames = Array.from(
			new Set(
				relevantSessions.flatMap((session) =>
					session.competencyProgress && Array.isArray(session.competencyProgress)
						? session.competencyProgress.map((competency) => normalizeCompetencyName(competency.name))
						: []
				)
			)
		);

		// For each competency, extract assessment scores
		const competencyData: { [key: string]: number[] } = {};
		competencyNames.forEach((name) => {
			competencyData[name] = relevantSessions
				.map((session) => {
					const competency = session.competencyProgress?.find((c) => normalizeCompetencyName(c.name) === name);
					return competency && typeof competency.assessment === "number" ? Number(competency.assessment) : null;
				})
				.filter((score): score is number => score !== null);
		});

		return { satisfactionData, competencyData };
	};
	const { satisfactionData, competencyData } = useMemo(() => generateReportScoreData(sessions, selectedScheduleId), [sessions, selectedScheduleId]);

	function getOverwrittenStatus(session: AssignedSessionView, index: number): AssignedSessionStatus {
		const prevSubmitted = index > 0 ? assignedSessions[index - 1].status === AssignedSessionStatus.SUBMITTED : true;
		let overwriteStatus = session.status;
		if (session.status === AssignedSessionStatus.AVAILABLE && !prevSubmitted) {
			// 	overwriteStatus = AssignedSessionStatus.NOT_AVAILABLE;
		}
		return overwriteStatus;
	}

	const htmlToPlainText = (html: string) => {
		const tempElement = document.createElement("div");
		tempElement.innerHTML = html;

		const text = tempElement.innerHTML
			.replace(/&nbsp;/g, " ")
			.replace(/<\/p>/g, "\n\n")
			.replace(/<\/li>/g, "\n")
			.replace(/<li>/g, "- ")
			.replace(/<br>/g, "\n")
			.replace(/<strong>/g, "*")
			.replace(/<\/strong>/g, "*")
			.replace(/\n\s*\n/g, "\n\n")
			.replace(/&amp;/g, "&")
			.replace(/<\/?[^>]+(>|$)/g, "");
		return text.trim();
	};

	const handleDeleteSession = async (sessionId: string) => {
		if (!sessionId) return;
		console.log("handleDeleteSession", sessionId);
		await SessionService.deleteSession(sessionId);
		window.location.reload();
	};

	const handleAssistantChange = (value: string) => {
		const assistant = assistants.find((a) => a.id === value);
		if (assistant) {
			setActiveAssistant(assistant);
			localStorage.setItem("selectedAssistantId", assistant.id);

			// Play the assistant's audio
			if (audioRef.current) {
				// 	audioRef.current.src = assistant.audio;
				// 	audioRef.current.play().catch((error) => console.error("Error playing audio:", error));
			}
		}
	};

	function renderSessionMessage(status: AssignedSessionStatus) {
		if (debugMode && (status === AssignedSessionStatus.PAST_DUE || status === AssignedSessionStatus.PENDING)) {
			return "";
		}
		switch (status) {
			case AssignedSessionStatus.PAST_DUE:
				return t("assignment.status.pastDue");
			case AssignedSessionStatus.AVAILABLE:
				return "";
			case AssignedSessionStatus.NOT_ATTEMPTED:
				return t("assignment.status.lateSubmission");
			default:
				return t("assignment.status.noData");
		}
	}

	const hasResumeQuestion = (session: AssignedSessionView | null): boolean => {
		if (!session?.interviewQuestionList) return false;
		return session.interviewQuestionList.some((question) => question.type === "Resume");
	};

	const isSessionStartable = (session: AssignedSessionView | null): boolean => {
		if (!session) return false;

		// If there's a resume question but no resume selected, block starting
		if (hasResumeQuestion(session) && !selectedResumeId) {
			return false;
		}

		return true;
	};

	const shouldShowRestartButton = (assignedSession: AssignedSessionView, index: number) => {
		const overwriteStatus = getOverwrittenStatus(assignedSession, index);
		return (
			overwriteStatus === AssignedSessionStatus.IN_PROGRESS ||
			overwriteStatus === AssignedSessionStatus.UNSUBMITTED ||
			overwriteStatus === AssignedSessionStatus.INCOMPLETE
		);
	};

	const playAssistantAudio = () => {
		if (audioRef.current) {
			if (isPlaying) {
				audioRef.current.pause();
				setIsPlaying(false);
			} else {
				audioRef.current.src = activeAssistant.audio;
				audioRef.current
					.play()
					.then(() => {
						setIsPlaying(true);
					})
					.catch((error) => console.error("Error playing audio:", error));
			}
		}
	};

	if (isLoading || assignedSessions.length === 0) {
		return <div>{t("general.loading")}</div>;
	}

	return (
		<div className="h-full pb-12">
			<BackButton />
			<div className="flex flex-col gap-4 mb-4">
				<h2 className="text-2xl md:text-3xl font-bold text-[#16013e] flex items-center">
					"{assignment?.assignmentTitle}"{" "}
					{selectedAssignedSession?.interviewStageType ? t("assignment.status.stages") : t("assignment.status.calls")}
					<SoloModeTag soloMode={selectedAssignedSession?.soloMode || false} t={t} />
					<DeleteSessionButtonSection
						assignment={assignment}
						sessionId={selectedAssignedSession?.sessionId || ""}
						handleDeleteSession={handleDeleteSession}
						allowedToDelete={allowedToDelete}
					/>
				</h2>

				{/* If the assignment type is interview, show the resume select dropdown */}
				{assignment?.experienceType === ExperienceType.INTERVIEW && (resumeAssistFeatureFlag || resumeAssistTenantFeatureFlag) && (
					<ResumeSelectSection selectedResumeId={selectedResumeId} handleResumeSelection={handleResumeSelection} resumes={resumes} />
				)}
				<UserIdentificationSection
					includeUserIdentification={assignment?.includeUserIdentification || false}
					email={assignedSessions[0].email}
					t={t}
				/>
			</div>

			{/* {!selectedAssignedSession?.soloMode && (
					<Button variant="ghost" size="sm" className="p-0 mb-4" onClick={() => setIsDetailsVisible(!isDetailsVisible)}>
						{isDetailsVisible ? "Hide Assignment Details" : "Show Assignment Details"}
						{isDetailsVisible ? <ChevronUpIcon className="h-4 w-4 ml-1" /> : <ChevronDownIcon className="h-4 w-4 ml-1" />}
					</Button>
				)} */}

			{assignment && (
				<InfoSection
					assignment={assignment}
					selectedScheduleId={selectedScheduleId}
					assignmentId={assignmentId || ""}
					userId={userId || ""}
					debugMode={debugMode}
					selectedAssignedSession={selectedAssignedSession || null}
					selectedUserSession={selectedUserSession || null}
					t={t}
				/>
			)}
			<div>
				{assignmentId && userId && (
					<HorizontalTabs
						tabs={tabs}
						type="user"
						selectedTab={selectedTab}
						assignmentId={assignmentId}
						userId={userId}
						onTabSelect={handleTabSelect}
					/>
				)}

				<ConfidentialityDialog
					assignment={assignment}
					showConfidentialityDialog={showConfidentialityDialog}
					onOpenChange={handleConfidentialityDialogOnOpenChange}
					onStartSession={startSession}
				/>

				{selectedAssignedSession && selectedAssignedSession.sessionId && showResumeOptions && (
					<RestartOptionsSection
						selectedAssignedSession={selectedAssignedSession}
						instageUser={instageUser}
						hasResumeQuestion={hasResumeQuestion}
						selectedResumeId={selectedResumeId}
						activeAssistant={activeAssistant}
						setActiveAssistant={setActiveAssistant}
						handleAssistantChange={handleAssistantChange}
						playAssistantAudio={playAssistantAudio}
						isPlaying={isPlaying}
						isStartingSession={isStartingSession}
						debugMode={debugMode}
						handleInitiateSession={handleInitiateSession}
						renderSessionMessage={renderSessionMessage}
						isSessionStartable={isSessionStartable}
						handleCancelResumeSession={handleCancelResumeSession}
					/>
				)}
				{!selectedUserSession && loggedInUserId === userId && selectedAssignedSession && (
					<StartOptionsSection
						selectedAssignedSession={selectedAssignedSession}
						isJobGenerated={isJobGenerated}
						hasResumeQuestion={hasResumeQuestion}
						selectedResumeId={selectedResumeId}
						activeAssistant={activeAssistant}
						setActiveAssistant={setActiveAssistant}
						handleAssistantChange={handleAssistantChange}
						playAssistantAudio={playAssistantAudio}
						isPlaying={isPlaying}
						isStartingSession={isStartingSession}
						debugMode={debugMode}
						handleInitiateSession={handleInitiateSession}
						renderSessionMessage={renderSessionMessage}
						isSessionStartable={isSessionStartable}
						instageUser={instageUser}
					/>
				)}

				{!selectedUserSession &&
					loggedInUserId === userId &&
					selectedAssignedSession &&
					selectedAssignedSession.status === AssignedSessionStatus.DELETED && (
						<div className="flex justify-between items-center mt-12 mb-4">
							<h2 className="text-xl md:text-2xl font-semibold">{t("assignment.session.deleted")}</h2>
						</div>
					)}
				{selectedUserSession && !showResumeOptions && (
					<div key={selectedUserSession.sessionId}>
						<div className="flex justify-between items-center mt-12 mb-4">
							<h2 className="text-xl md:text-2xl font-semibold">
								{/* {selectedUserSession.scheduleDescription || selectedUserSession.experienceType}:  */}
								{t("assignment.session.summary")}
							</h2>
							{debugMode && (
								<Button size="sm" onClick={regenerateSummary} disabled={isRegenerating}>
									{isRegenerating ? (
										<>
											<Spinner className="w-4 h-4 mr-2" />
											{t("assignment.session.regenerating")}
										</>
									) : (
										t("assignment.session.regenerateSummary")
									)}
								</Button>
							)}
						</div>

						<div className="email-report-post-session">
							<div className="mb-6">
								<Report
									selectedUserSession={selectedUserSession}
									userName={getUserName(assignedSessions[0])}
									satisfactionData={satisfactionData}
									competencyData={competencyData}
									showRestartButton={
										selectedAssignedSession ? shouldShowRestartButton(selectedAssignedSession, selectedTab) : false
									}
									onRestartClick={handleRestartClick}
								/>
							</div>
						</div>
					</div>
				)}
				{selectedAssignedSession && (
					<JobDetailsSection
						selectedAssignedSession={selectedAssignedSession}
						isJobGenerated={isJobGenerated}
						jobTitle={jobTitle}
						jobDescription={jobDescription}
						handleJobTitleChange={handleJobTitleChange}
						handleJobDescriptionChange={handleJobDescriptionChange}
						handleGenerateJobDetails={handleGenerateJobDetails}
						handleJobSelection={handleJobSelection}
						jobPostings={jobPostings}
						showJobPost={showJobPost}
						handleToggleJobPost={handleToggleJobPost}
						isLoadingJobPosting={isLoadingJobPosting}
						isGeneratingJob={isGeneratingJob}
					/>
				)}
				{!selectedUserSession && isJobGenerated && (
					<>{selectedAssignedSession?.interviewStageType && <UserInterviewPlan selectedAssignedSession={selectedAssignedSession} />}</>
				)}
			</div>
			<audio ref={audioRef} style={{ display: "none" }} />
		</div>
	);
};

// const scrollToTranscript = (index: number) => {
// 	window.scrollTo(0, document.body.scrollHeight);
// };
export default UserSessionPage;
