import React, { Dispatch, SetStateAction, useState, useEffect } from "react";
import { UploadJobFormProps } from "../UserPages/ResumeAssist";
import Spinner from "../Utility/Spinner";
import { useTranslation } from "../Utility/UseTranslation";

const UploadJobForm: React.FC<UploadJobFormProps> = ({
	profile,
	setJobTitle,
	setJobDescription,
	jobTitle,
	jobDescription,
	generateResume,
	generateCoverLetter,
	resumeId
}) => {
	const [formData, setFormData] = useState({
		title: jobTitle,
		description: jobDescription
	});
	const [isLoading, setIsLoading] = useState(false);
	const { t } = useTranslation("resumeAssist");
	const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const { name, value } = e.target;
		setFormData((prev) => ({
			...prev,
			[name]: value
		}));

		// Update parent state immediately
		if (name === "title") {
			setJobTitle(value);
		} else if (name === "description") {
			setJobDescription(value);
		}
	};

	// Add this useEffect to sync props with local state
	useEffect(() => {
		setFormData({
			title: jobTitle,
			description: jobDescription
		});
	}, [jobTitle, jobDescription]);

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		// Handle form submission here
		console.log(formData);
	};

	const handleGenerateResume = async () => {
		if (formData.title && formData.description) {
			setIsLoading(true);
			setJobTitle(formData.title);
			setJobDescription(formData.description);
			try {
				await generateResume();
			} finally {
				setIsLoading(false);
			}
		}
	};

	return (
		<div className="w-full mx-auto p-6">
			<h1 className="text-2xl font-bold mb-4">{t("uploadJobForm.title")}</h1>
			<p className="text-sm text-gray-500 mb-4">{t("uploadJobForm.description")}</p>
			<form onSubmit={handleSubmit} className="space-y-4">
				<div>
					<label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
						{t("uploadJobForm.jobTitle.label")}
					</label>
					<input
						type="text"
						id="title"
						name="title"
						value={formData.title}
						onChange={handleChange}
						required
						className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#00a9af]"
						placeholder={t("uploadJobForm.jobTitle.placeholder")}
					/>
				</div>

				<div>
					<label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-1">
						{t("uploadJobForm.jobDescription.label")}
					</label>
					<textarea
						id="description"
						name="description"
						value={formData.description}
						onChange={handleChange}
						required
						className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#00a9af] h-32 max-h-[400px]"
						placeholder={t("uploadJobForm.jobDescription.placeholder")}
					/>
				</div>

				<button
					type="submit"
					className="w-full bg-[#00a9af] text-white py-2 px-4 rounded-md hover:bg-[#008f94] focus:outline-none focus:ring-2 focus:ring-[#00a9af] disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center"
					onClick={handleGenerateResume}
					disabled={isLoading}
				>
					{isLoading ? (
						<>
							<Spinner className="w-5 h-5 mr-2" /> {t("uploadJobForm.generateButton.loading")}
						</>
					) : (
						t("uploadJobForm.generateButton.default")
					)}
				</button>
			</form>
		</div>
	);
};

export default UploadJobForm;
