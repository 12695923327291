import { AssignedSessionStatus } from "../types/AssignedSessionStatus";
import {
	CheckCircleIcon,
	ClockIcon as SolidClockIcon,
	CheckCircleIcon as SolidCheckCircleIcon,
	XCircleIcon,
	PhoneIcon,
	NoSymbolIcon,
	ExclamationCircleIcon,
	PencilIcon,
	CalendarIcon,
	TrashIcon
} from "@heroicons/react/24/solid";
import { CheckCircleIcon as CheckCircleOutlineIcon, ClockIcon as ClockOutlineIcon } from "@heroicons/react/24/outline";
import React from "react";

interface StatusStyle {
	background: string;
	text: string;
	border?: string;
}

export const getStatusColors = (status: AssignedSessionStatus): StatusStyle => {
	switch (status) {
		case AssignedSessionStatus.COMPLETED:
		case AssignedSessionStatus.SUBMITTED:
		case AssignedSessionStatus.SUBMITTED_LATE:
			return {
				background: "bg-[#00a9af]",
				text: "text-[#fff]"
			};

		case AssignedSessionStatus.NOT_ATTEMPTED:
		case AssignedSessionStatus.PAST_DUE:
			return {
				background: "bg-[#FEF3F2]",
				text: "text-[#B42318]",
				border: "border-[#FEE4E2]"
			};
		case AssignedSessionStatus.AVAILABLE:
			return {
				background: "bg-[#e6f7f7]",
				text: "text-[#00a9af]",
				border: "border-[#b3e6e8]"
			};
		case AssignedSessionStatus.PENDING:
		case AssignedSessionStatus.NOT_CONFIRMED:
		case AssignedSessionStatus.DRAFT:
		case AssignedSessionStatus.NOT_AVAILABLE:
			return {
				background: "bg-gray-100",
				text: "text-gray-800",
				border: "border-[#D0D5DD]"
			};

		case AssignedSessionStatus.IN_PROGRESS:
		case AssignedSessionStatus.INCOMPLETE:
		case AssignedSessionStatus.UNSUBMITTED:
			return {
				background: "bg-yellow-100",
				text: "text-yellow-600",
				border: "border-yellow-300"
			};
		case AssignedSessionStatus.DELETED:
			return {
				background: "bg-[#FEF3F2]",
				text: "text-[#B42318]",
				border: "border-[#FEE4E2]"
			};
		default:
			return {
				background: "bg-gray-100",
				text: "text-gray-800"
			};
	}
};

export const getStatusIcon = (status: AssignedSessionStatus): JSX.Element | null => {
	switch (status) {
		case AssignedSessionStatus.COMPLETED:
			return React.createElement(CheckCircleIcon, { className: "h-4 w-4 text-white" });
		case AssignedSessionStatus.SUBMITTED:
			return React.createElement(SolidCheckCircleIcon, { className: "h-4 w-4 text-white" });
		case AssignedSessionStatus.SUBMITTED_LATE:
			return React.createElement(SolidCheckCircleIcon, { className: "h-4 w-4 text-white" });
		case AssignedSessionStatus.NOT_ATTEMPTED:
			return React.createElement(SolidClockIcon, { className: "h-4 w-4 text-[#B42318]" });
		case AssignedSessionStatus.PENDING:
			return React.createElement(CalendarIcon, { className: "h-4 w-4 text-[#344054]" });
		case AssignedSessionStatus.AVAILABLE:
			return React.createElement(ClockOutlineIcon, { className: "h-4 w-4 text-[#00A9AF]" });
		case AssignedSessionStatus.NOT_CONFIRMED:
			return React.createElement(ExclamationCircleIcon, { className: "h-4 w-4 text-[#344054]" });
		case AssignedSessionStatus.DRAFT:
			return React.createElement(PencilIcon, { className: "h-4 w-4 text-[#344054]" });
		case AssignedSessionStatus.IN_PROGRESS:
			return React.createElement(PhoneIcon, { className: "h-4 w-4 text-[#B54708]" });
		case AssignedSessionStatus.INCOMPLETE:
			return React.createElement(XCircleIcon, { className: "h-4 w-4 text-[#B54708]" });
		case AssignedSessionStatus.UNSUBMITTED:
			return React.createElement(CheckCircleOutlineIcon, { className: "h-4 w-4 text-[#B54708]" });
		case AssignedSessionStatus.NOT_AVAILABLE:
			return React.createElement(NoSymbolIcon, { className: "h-4 w-4 text-[#344054]" });
		case AssignedSessionStatus.DELETED:
			return React.createElement(TrashIcon, { className: "h-4 w-4 text-[#344054]" });
		default:
			return null;
	}
};

// Helper function for card-specific styling
export const getStatusCardStyles = (status: AssignedSessionStatus): string => {
	const styles = getStatusColors(status);
	return `${styles.background} ${styles.text} ${styles.border ? `border ${styles.border}` : ""} rounded-full`;
};
