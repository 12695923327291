export const AssignmentListLocale = {
	en: {
		pageTitle: "Assignments",
		searchAssignments: "Search Assignments",
		createAssignment: {
			title: "Create Assignment",
			description: "Create a new assignment to help your students learn and grow.",
			reflection: "Reflection",
			interview: "Interview",
			careerPath: "Career Path",
			checkIn: "Check-in",
			jobSearch: "Job Search",
			frontline: "Frontline",
			beta: "Beta"
		},
		buttonLabels: {
			settings: "Settings",
			download: "Download",
			loading: "Loading..."
		},
		statuses: {
			complete: "Complete",
			ongoing: "Ongoing",
			drafts: "Drafts"
		},
		metrics: {
			usage: "Usage",
			callsAssigned: "Calls Assigned",
			callsSubmitted: "Calls Submitted",
			avgParticipation: "Avg Participation"
		},
		tableHeaders: {
			assignmentTitle: "Assignment",
			experienceType: "Type",
			ownerEmail: "Created By",
			startDate: "Start Date",
			endDate: "End Date",
			enrolledUserCount: "Enrolled Users",
			sessionsAssigned: "Sessions Assigned",
			totalExpectedSessions: "Total Expected Sessions",
			submissionCount: "Submission Count",
			participationRate: "Participation Rate",
			serviceHoursUsed: "Service Hours Used",
			status: "Status"
		},

		actions: {
			edit: "Edit",
			delete: "Delete"
		}
	},
	fr: {
		pageTitle: "Assignations",
		searchAssignments: "Rechercher des assignations",
		createAssignment: {
			title: "Créer une assignation",
			description: "Créer une nouvelle assignation pour aider vos étudiants à apprendre et à grandir.",
			reflection: "Réflexion",
			interview: "Entretien",
			careerPath: "Carrière",
			checkIn: "Contrôle",
			jobSearch: "Recherch",
			frontline: "Frontline",
			beta: "Bêta"
		},
		buttonLabels: {
			settings: "Paramètres",
			download: "Télécharger",
			loading: "Chargement..."
		},
		statuses: {
			complete: "Terminé",
			ongoing: "En cours",
			drafts: "Brouillons"
		},
		metrics: {
			usage: "Usage",
			callsAssigned: "Appels affectés",
			callsSubmitted: "Appels soumis",
			avgParticipation: "Moyenne de participation"
		},
		tableHeaders: {
			assignmentTitle: "Titre de l'assignation",
			experienceType: "Type d'expérience",
			ownerEmail: "Email du propriétaire",
			startDate: "Date de début",
			endDate: "Date de fin",
			enrolledUserCount: "Nombre d'utilisateurs inscrits",
			sessionsAssigned: "Sessions affectées",
			totalExpectedSessions: "Sessions attendues",
			submissionCount: "Nombre de soumissions",
			participationRate: "Taux de participation",
			serviceHoursUsed: "Heures de service utilisées",
			status: "Statut"
		},
		actions: {
			edit: "Modifier",
			delete: "Supprimer"
		}
	}
};
