import React, { useEffect, useMemo, useRef } from "react";

import { AssignedSessionView } from "../../../types/AssignedSessionView";
import { VideoCameraIcon, PhoneIcon, CalendarIcon, PlayIcon, PauseIcon } from "@heroicons/react/24/solid";

import { Assistant } from "../../../contexts/AppContext";

import { Button } from "../../shadcn-ui/Button";
import { NavigateService } from "../../../services/NavigateService";

import { Loader2 } from "lucide-react";
import { AssignedSessionStatus } from "../../../types/AssignedSessionStatus";

import { format } from "date-fns";

import posthog from "posthog-js";

import { UserView } from "../../../types/UserView";

import { AssistantDropdown } from "./AssistantDropdown";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../shadcn-ui/Select";
import { useTranslation } from "../../Utility/UseTranslation";
import Spinner from "../../Utility/Spinner";

enum MinutesAvailabilityStatus {
	AVAILABLE = "AVAILABLE",
	SELF_PRACTICE_LIMIT_REACHED = "SELF_PRACTICE_LIMIT_REACHED",
	ASSIGNMENT_LIMIT_REACHED = "ASSIGNMENT_LIMIT_REACHED"
}

function checkMinutesAvailabilityStatus(instageUser: UserView | null, soloMode: boolean): MinutesAvailabilityStatus {
	if (instageUser && soloMode && instageUser.selfServeMinutesUsed >= instageUser.selfServeCallTimeLimit * 3) {
		return MinutesAvailabilityStatus.SELF_PRACTICE_LIMIT_REACHED;
	}
	if (instageUser && instageUser.assignmentMinutesUsed >= instageUser.assignmentCallTimeLimit * 3) {
		return MinutesAvailabilityStatus.ASSIGNMENT_LIMIT_REACHED;
	}
	return MinutesAvailabilityStatus.AVAILABLE;
}

const LimitReachedMessage = ({ status }: { status: MinutesAvailabilityStatus }) => {
	switch (status) {
		case MinutesAvailabilityStatus.SELF_PRACTICE_LIMIT_REACHED:
			return (
				<div className="flex flex-col items-center justify-center py-12 rounded-lg border-gray-300 border bg-gray-50">
					<div className="max-w-md text-center space-y-3">
						<h3 className="text-xl font-semibold text-warning">Self-Practice Time Limit Reached</h3>
						<p className="text-gray-600">You have used all your available self-practice call minutes.</p>
						<p className="text-gray-600">Please contact your instructor or administrator to request additional time.</p>
					</div>
				</div>
			);
		case MinutesAvailabilityStatus.ASSIGNMENT_LIMIT_REACHED:
			return (
				<div className="flex flex-col items-center justify-center py-12 rounded-lg border-gray-300 border bg-gray-50">
					<div className="max-w-md text-center space-y-3">
						<h3 className="text-xl font-semibold text-warning">Assignment Time Limit Reached</h3>
						<p className="text-gray-600">You have used all your available assignment call minutes.</p>
						<p className="text-gray-600">Please contact your instructor or administrator to request additional time.</p>
					</div>
				</div>
			);
		default:
			return null;
	}
};

export const StartOptionsSection = ({
	selectedAssignedSession,
	isJobGenerated,
	hasResumeQuestion,
	selectedResumeId,
	activeAssistant,
	setActiveAssistant,
	handleAssistantChange,
	playAssistantAudio,
	isPlaying,
	isStartingSession,
	debugMode,
	handleInitiateSession,
	renderSessionMessage,
	isSessionStartable,
	instageUser
}: {
	selectedAssignedSession: AssignedSessionView;
	isJobGenerated: boolean;
	hasResumeQuestion: (session: AssignedSessionView) => boolean;
	selectedResumeId: string;
	activeAssistant: Assistant;
	setActiveAssistant: (assistant: Assistant) => void;
	handleAssistantChange: (assistantId: string) => void;
	playAssistantAudio: () => void;
	isPlaying: boolean;
	isStartingSession: boolean;
	debugMode: boolean;
	handleInitiateSession: (type: "web" | "phone" | "schedule") => void;
	renderSessionMessage: (status: AssignedSessionStatus) => string;
	isSessionStartable: (session: AssignedSessionView) => boolean;
	instageUser: UserView | null;
}) => {
	const { t } = useTranslation("userSessionPage");

	// For interviews, if job details aren't generated yet, don't show the start button
	if (selectedAssignedSession.interviewStageType !== null && !isJobGenerated) {
		return null;
	}

	const status = checkMinutesAvailabilityStatus(instageUser, selectedAssignedSession.soloMode);

	if (status !== MinutesAvailabilityStatus.AVAILABLE) return <LimitReachedMessage status={status} />;

	// Check if resume is required but not selected
	const needsResume = hasResumeQuestion(selectedAssignedSession) && !selectedResumeId;

	return (
		<div className="flex flex-col items-center justify-center py-12 rounded-lg border-gray-300 border">
			{(selectedAssignedSession?.status === AssignedSessionStatus.AVAILABLE ||
				selectedAssignedSession?.status === AssignedSessionStatus.NOT_ATTEMPTED ||
				debugMode) && (
				<>
					<div className="text-center mb-2 text-[#16013E] text-xl md:text-2xl font-bold inline-flex items-center relative">
						<span>{t("CallOptions.StartCallWith")}</span>
						<AssistantDropdown
							activeAssistant={activeAssistant}
							setActiveAssistant={setActiveAssistant}
							handleAssistantChange={handleAssistantChange}
						/>
						<button
							onClick={playAssistantAudio}
							className="h-6 w-6 rounded-full ml-2 flex items-center justify-center"
							style={{ background: "linear-gradient(90deg, #DB4F86 -19.25%, #00A9AF 152.17%)" }}
							aria-label={isPlaying ? "Pause assistant audio" : "Play assistant audio"}
						>
							{isPlaying ? <PauseIcon className="h-3 w-3 text-white" /> : <PlayIcon className="h-3 w-3 text-white" />}
						</button>
					</div>
					{needsResume && (
						<div className="mt-4 mb-4 p-4 bg-yellow-50 border border-yellow-200 rounded-lg text-center">
							<p className="text-yellow-800 font-medium mb-2">{t("CallOptions.ResumeRequiredShort")}</p>
							<p className="text-sm text-yellow-700">{t("CallOptions.ResumeRequiredLong")}</p>
						</div>
					)}
				</>
			)}
			{selectedAssignedSession.frontlineStudentRole && selectedAssignedSession.frontlineStudentRole !== "" && (
				<p className="text-lg text-gray-600 mb-4 text-center">
					{t("CallOptions.PlayingRoleOf")} {selectedAssignedSession.frontlineStudentRole} {t("CallOptions.and")} {activeAssistant.name}{" "}
					{t("CallOptions.isA")} {selectedAssignedSession.frontlineAssistantRole}.
				</p>
			)}
			{selectedAssignedSession?.status !== AssignedSessionStatus.PENDING && (
				<p className="text-lg text-gray-600 mb-4 text-center w-11/12 sm:w-1/2 max-w-sm">
					{renderSessionMessage(selectedAssignedSession.status)}
				</p>
			)}
			{(selectedAssignedSession?.status === AssignedSessionStatus.AVAILABLE ||
				selectedAssignedSession?.status === AssignedSessionStatus.NOT_ATTEMPTED ||
				debugMode) && (
				<div className="flex flex-col gap-4 w-11/12 sm:w-1/2 max-w-sm">
					<Button
						className="h-12"
						variant="default"
						size="lg"
						onClick={() => handleInitiateSession("web")}
						disabled={isStartingSession || !isSessionStartable(selectedAssignedSession)}
					>
						{isStartingSession ? <Spinner /> : <VideoCameraIcon className="mr-2 h-5 w-5" />}
						{isStartingSession ? t("CallOptions.Starting") : t("CallOptions.StartWebCallNow")}
					</Button>
					<Button
						className="h-12"
						variant="outline"
						size="lg"
						onClick={() => handleInitiateSession("phone")}
						disabled={!isSessionStartable(selectedAssignedSession)}
					>
						<PhoneIcon className="mr-2 h-5 w-5" /> {t("CallOptions.CallMeNow")}
					</Button>
				</div>
			)}
			{(selectedAssignedSession?.status === AssignedSessionStatus.AVAILABLE ||
				selectedAssignedSession?.status === AssignedSessionStatus.PENDING ||
				selectedAssignedSession?.status === AssignedSessionStatus.NOT_ATTEMPTED ||
				debugMode) && (
				<>
					{selectedAssignedSession.scheduledCallBookingId && selectedAssignedSession.scheduledCallTime ? (
						<div className="flex flex-col gap-4 w-11/12 sm:w-1/2 max-w-sm">
							<p className="text-center text-[#16013E] text-xl md:text-2xl font-bold mt-12">{t("CallOptions.ScheduledCall")}</p>
							<div className="space-y-4">
								<div className="h-12 flex items-center justify-center bg-gray-100 rounded-md px-4">
									<CalendarIcon className="mr-2 h-5 w-5 text-gray-600" />
									<span className="text-lg text-gray-800">
										{format(new Date(selectedAssignedSession.scheduledCallTime), "MMM d 'at' h:mm a")}
									</span>
								</div>
								{selectedAssignedSession.attendeeSeatId && (
									<Button
										className="w-full h-12"
										variant="outline"
										size="lg"
										onClick={() => {
											NavigateService.navToManageBooking(
												selectedAssignedSession.scheduledCallBookingId!,
												selectedAssignedSession.attendeeSeatId!
											);
											const printObject = {
												fronteggId: instageUser?.fronteggId || "",
												userId: instageUser?.id || "",
												tenantId: instageUser?.tenantId || "",
												clientId: instageUser?.clientId || "",
												timestamp: new Date().toISOString(),
												sessionId: selectedAssignedSession?.sessionId || "",
												assignmentId: selectedAssignedSession?.assignmentId || "",
												activityType: selectedAssignedSession?.activityType || "",
												scheduleId: selectedAssignedSession?.scheduleId || ""
											};
											posthog.capture("MAJOR_EVENT: Manage Booking", printObject);
										}}
									>
										<span>{t("CallOptions.ManageBooking")}</span>
									</Button>
								)}
							</div>
						</div>
					) : (
						<Button
							className="w-11/12 sm:w-1/2 max-w-sm h-12 mt-4"
							variant={selectedAssignedSession?.status === "available" ? "outline" : "default"}
							size="lg"
							onClick={() => handleInitiateSession("schedule")}
							disabled={!isSessionStartable(selectedAssignedSession)}
						>
							<CalendarIcon className="mr-2 h-5 w-5" /> {t("CallOptions.ScheduleACall")}
						</Button>
					)}
				</>
			)}
		</div>
	);
};

export const RestartOptionsSection = ({
	selectedAssignedSession,
	instageUser,
	hasResumeQuestion,
	selectedResumeId,
	activeAssistant,
	setActiveAssistant,
	handleAssistantChange,
	playAssistantAudio,
	isPlaying,
	isStartingSession,
	debugMode,
	handleInitiateSession,
	renderSessionMessage,
	isSessionStartable,
	handleCancelResumeSession
}: {
	selectedAssignedSession: AssignedSessionView;
	instageUser: UserView | null;
	hasResumeQuestion: (session: AssignedSessionView) => boolean;
	selectedResumeId: string;
	activeAssistant: Assistant;
	setActiveAssistant: (assistant: Assistant) => void;
	handleAssistantChange: (assistantId: string) => void;
	playAssistantAudio: () => void;
	isPlaying: boolean;
	isStartingSession: boolean;
	debugMode: boolean;
	handleInitiateSession: (type: "web" | "phone" | "schedule") => void;
	renderSessionMessage: (status: AssignedSessionStatus) => string;
	isSessionStartable: (session: AssignedSessionView) => boolean;
	handleCancelResumeSession: () => void;
}) => {
	const { t } = useTranslation("userSessionPage");

	// Check minutes availability first

	const status = checkMinutesAvailabilityStatus(instageUser, selectedAssignedSession.soloMode);

	if (status !== MinutesAvailabilityStatus.AVAILABLE) return <LimitReachedMessage status={status} />;

	// Check if resume is required but not selected
	const needsResume = hasResumeQuestion(selectedAssignedSession) && !selectedResumeId;

	return (
		<div className="flex flex-col items-center justify-center py-12 rounded-lg gap-6 border-gray-300 border">
			<div className="text-center mb-2 text-[#16013E] text-xl md:text-2xl font-bold inline-flex items-center relative">
				<span>{t("general.restartCallWith")}</span>
				<AssistantDropdown
					activeAssistant={activeAssistant}
					setActiveAssistant={setActiveAssistant}
					handleAssistantChange={handleAssistantChange}
				/>
				<button
					onClick={playAssistantAudio}
					className="h-6 w-6 rounded-full ml-2 flex items-center justify-center"
					style={{ background: "linear-gradient(90deg, #DB4F86 -19.25%, #00A9AF 152.17%)" }}
					aria-label={isPlaying ? "Pause assistant audio" : "Play assistant audio"}
				>
					{isPlaying ? <PauseIcon className="h-3 w-3 text-white" /> : <PlayIcon className="h-3 w-3 text-white" />}
				</button>
			</div>
			{needsResume && (
				<div className="mt-4 mb-4 p-4 bg-yellow-50 border border-yellow-200 rounded-lg text-center">
					<p className="text-yellow-800 font-medium mb-2">{t("CallOptions.ResumeRequiredShort")}</p>
					<p className="text-sm text-yellow-700">{t("CallOptions.ResumeRequiredLong")}</p>
				</div>
			)}
			{selectedAssignedSession.frontlineStudentRole && selectedAssignedSession.frontlineStudentRole !== "" && (
				<p className="text-lg text-gray-600 mb-4 text-center">
					{t("PlayingRoleOf")} {selectedAssignedSession.frontlineStudentRole} {t("and")} {activeAssistant.name} {t("isA")}{" "}
					{selectedAssignedSession.frontlineAssistantRole}.
				</p>
			)}
			<p className="text-sm text-[#DB4F86] mb-8 text-center">{t("CallOptions.Note")}</p>
			<Button
				className="w-11/12 sm:w-1/2 max-w-sm h-12"
				variant="default"
				name="CallOptions.StartWebCall"
				aria-label="CallOptions.StartWebCall"
				size="lg"
				onClick={() => handleInitiateSession("web")}
				disabled={isStartingSession || !isSessionStartable(selectedAssignedSession)}
			>
				{isStartingSession ? <Spinner /> : <VideoCameraIcon className="mr-2 h-5 w-5" />}
				{isStartingSession ? t("CallOptions.Starting") : t("CallOptions.RestartWebCallNow")}
			</Button>
			<Button
				className="w-11/12 sm:w-1/2 max-w-sm h-12"
				variant="outline"
				size="lg"
				onClick={() => handleInitiateSession("phone")}
				disabled={!isSessionStartable(selectedAssignedSession)}
			>
				<PhoneIcon className="mr-2 h-5 w-5" /> {t("CallOptions.RestartPhoneCall")}
			</Button>
			{selectedAssignedSession.scheduledCallBookingId && selectedAssignedSession.scheduledCallTime ? (
				<div className="w-11/12 sm:w-1/2 max-w-sm h-12 flex items-center justify-center bg-gray-100 rounded-md">
					<CalendarIcon className="mr-2 h-5 w-5 text-gray-600" />
					<span className="text-gray-800 font-medium">
						{t("CallOptions.Scheduled")}: {format(new Date(selectedAssignedSession.scheduledCallTime), "MMM d, yyyy 'at' h:mm a")}
					</span>
				</div>
			) : (
				<Button
					className="w-11/12 sm:w-1/2 max-w-sm h-12"
					variant="outline"
					size="lg"
					onClick={() => handleInitiateSession("schedule")}
					disabled={!isSessionStartable(selectedAssignedSession)}
				>
					<CalendarIcon className="mr-2 h-5 w-5" /> {t("CallOptions.RestartBySchedulingACall")}
				</Button>
			)}
			<Button className="w-11/12 sm:w-1/2 max-w-sm h-12" variant="outlineWarning" size="lg" onClick={handleCancelResumeSession}>
				{t("CallOptions.Cancel")}
			</Button>
		</div>
	);
};
