import React from "react";

// Define the structure of a step
export interface Step {
	label: string;
	component: React.ComponentType<any>;
	icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
}

// Define props for the StepNavigation component
export interface StepNavigationProps {
	steps: Step[];
	currentStep: number;
}

// StepNavigation component to display the progress of form steps
const StepNavigation: React.FC<StepNavigationProps> = ({ steps, currentStep }) => {
	return (
		<nav aria-label="Assignment creation steps" className="mb-8">
			<ol className="space-y-4" role="list">
				{steps.map((step, index) => {
					const isCompleted = index < currentStep;
					const isCurrent = index === currentStep;
					const Icon = step.icon;
					const stepStatus = isCompleted ? "completed" : isCurrent ? "current" : "pending";

					return (
						<li key={index} className="flex items-center">
							{/* Step icon */}
							<span
								className={`flex-shrink-0 w-8 h-8 flex items-center justify-center rounded-full border-2 ${
									isCompleted
										? "bg-[#00a9af] text-white border-[#00a9af]"
										: isCurrent
										? "border-[#00a9af] text-[#00a9af]"
										: "border-gray-300 text-gray-500"
								}`}
								role="img"
								aria-label={`Step ${index + 1} ${step.label}: ${stepStatus}`}
							>
								<Icon className="w-4 h-4" aria-hidden="true" />
							</span>
							{/* Step label */}
							<span
								className={`ml-4 text-sm font-medium ${
									isCurrent ? "text-[#00a9af]" : isCompleted ? "text-gray-900" : "text-gray-500"
								}`}
								aria-current={isCurrent ? "step" : undefined}
							>
								{step.label}
							</span>
						</li>
					);
				})}
			</ol>
		</nav>
	);
};

export default StepNavigation;
