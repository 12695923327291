import React from "react";
import { useNavigate } from "react-router-dom";
import { ActivityType } from "../../schemas/ActivityType";
import { AssignedSessionStatus } from "../../types/AssignedSessionStatus";
import { getStatusColors, getStatusIcon } from "../../utils/statusStyles";
import { InterviewStageType } from "@/src/types/InterviewPayload";
import { FrontlineStageType } from "@/src/types/FrontlinePayload";
import { CareerPathStageType } from "@/src/types/CareerPathPayload";
import { useTranslation } from "./UseTranslation";

export interface TabData {
	label: string;
	status: AssignedSessionStatus;
	date: Date | null;
	scheduleId: string;
	activityType: ActivityType;
	interviewStageOrderNumber?: number;
	interviewStageType?: InterviewStageType;
	frontlineStageOrderNumber?: number;
	frontlineStageType?: FrontlineStageType;
	careerPathStageType?: CareerPathStageType;
}

interface HorizontalTabsProps {
	tabs: TabData[];
	type: "user" | "admin";
	selectedTab: number;
	assignmentId: string;
	userId: string | null;
	onTabSelect: (scheduleId: string) => void;
}

export const formatStatus = (status: string, t: Function) => {
	return t(`assignedSessionsTable.statuses.${status}`);
	// Capitalize the first letter of each word and replace underscores with spaces
	// return status.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase());
};

const HorizontalTabs: React.FC<HorizontalTabsProps> = ({ tabs, type, selectedTab, assignmentId, userId, onTabSelect }) => {
	const navigate = useNavigate();
	const { t } = useTranslation("adminAssignmentPage");

	const renderStatus = (status: AssignedSessionStatus) => {
		const styles = getStatusColors(status);
		const className = `text-xs whitespace-nowrap ${styles.background} ${styles.text} ${
			styles.border ? `border ${styles.border}` : ""
		} px-2 py-1 rounded-full flex items-center gap-1`;

		return (
			<span className={className}>
				{getStatusIcon(status)}
				{formatStatus(status, t)}
			</span>
		);
	};

	return (
		<div className="mb-4">
			<div className="border-b border-gray-200 overflow-x-auto">
				<nav className="flex">
					{tabs.map((tab, index) => {
						let label;

						if (tab.interviewStageType) {
							if (tab.interviewStageType === "screening") {
								label = t("horizontalTabs.interview.screening");
							} else if (tab.interviewStageType === "middle") {
								label = t("horizontalTabs.interview.middle");
							} else if (tab.interviewStageType === "final") {
								label = t("horizontalTabs.interview.final");
							} else if (tab.interviewStageType === "resume") {
								label = t("horizontalTabs.interview.resume");
							}
						} else if (tab.careerPathStageType) {
							console.log("Career path stage type:", tab.careerPathStageType);
							if (tab.careerPathStageType === "discovery") {
								label = t("horizontalTabs.careerPath.discovery");
							} else if (tab.careerPathStageType === "progression") {
								label = t("horizontalTabs.careerPath.progression");
							}
						} else if (tab.interviewStageType) {
							if (tab.interviewStageType === "screening") {
								label = t("horizontalTabs.interview.screening");
							} else if (tab.interviewStageType === "middle") {
								label = t("horizontalTabs.interview.middle");
							} else if (tab.interviewStageType === "final") {
								label = t("horizontalTabs.interview.final");
							} else if (tab.interviewStageType === "resume") {
								label = t("horizontalTabs.interview.resume");
							}
						} else if (tab.frontlineStageType) {
							if (["deny", "comply", "gather"].includes(tab.frontlineStageType)) {
								label = `${t("horizontalTabs.frontline.call")} ${tab.frontlineStageOrderNumber}`;
							}
						} else if (tab.activityType === "first") {
							label = t("horizontalTabs.frontline.pre");
						} else if (tab.activityType === "middle") {
							label = `${t("horizontalTabs.frontline.middle")} ${index}`;
						} else if (tab.activityType === "last") {
							label = t("horizontalTabs.frontline.post");
						} else {
							label = tab.label;
						}

						return (
							<button
								key={index}
								className={`flex flex-col items-center px-4 md:px-6 py-2 text-sm font-medium snap-start min-w-[150px] ${
									index === selectedTab
										? "border-b-2 border-[#00a9af] bg-[#00A9AF13]"
										: "border-b-2 border-transparent text-gray-500 hover:text-gray-700 hover:bg-gray-100"
								} transition-all duration-200 ease-in-out`}
								onClick={() => {
									onTabSelect(tab.scheduleId);
									if (type === "user") {
										navigate(`/user/assignment/${assignmentId}/user/${userId}/schedule/${tab.scheduleId}`, {
											replace: true
										});
									} else {
										navigate(`/admin/assignment/${assignmentId}/schedule/${tab.scheduleId}`, {
											replace: true
										});
									}
								}}
							>
								<div className="flex flex-col gap-2 items-center w-full">
									<div className="flex items-center">
										<span className={`${index === selectedTab ? "font-bold" : ""}`}>{label}</span>
									</div>
									<span className="text-xs text-gray-500">{tab.date ? tab.date.toLocaleDateString() : "TBD"}</span>
									{renderStatus(tab.status)}
								</div>
							</button>
						);
					})}
				</nav>
			</div>
		</div>
	);
};

export default HorizontalTabs;
