export const AssignmentDetailsLocale = {
	en: {
		next: "Next",
		previous: "Previous",
		assignmentDetails: {
			title: "Assignment Details",
			description:
				"Give your assignment a clear name and description. Then, set how you want to distribute this assignment to students, configure notifications, and choose how aggregated reports are generated."
		},
		practiceDetials: {
			description:
				"Give your assignment a clear name and description. Then, set how you want to distribute this assignment to students, configure notifications, and choose how aggregated reports are generated."
		},
		information: {
			assignmentName: "Assignment Name",
			interviewName: "Interview Name",
			frontlineName: "Frontline Name",
			careerPathName: "Career Path Discovery Simulation Name",
			reflectionName: "Reflection Name",
			assignmentNameTooltip:
				"Give your assignment a clear name and description. Then, set how you want to distribute this assignment to students, configure notifications, and choose how aggregated reports are generated.",
			interviewNamePlaceholder: "Mock Interview",
			jobSearchNamePlaceholder: "Job Search for Co-op",
			checkInSimNamePlaceholder: "Check-In on Course Content",
			careerPathNamePlaceholder: "Career Path Discovery",
			frontlineNamePlaceholder: "Frontline Training Series",
			reflectionNamePlaceholder: "Reflection for AI Ethics Program",
			description: "Description",
			descriptionTooltip:
				"Explain the purpose and scope of this assignment. This context helps the AI assistant guide meaningful conversations during the reflection calls.",
			descriptionHeading: "This description will appear in the student's initial enrolment email and on their start call page.",
			reflectionDescriptionPlaceholder:
				"This reflection is part of our AI Ethics Program. Students will engage in 6 sessions throughout the course, each focusing on a different aspect of AI ethics. These reflections will encourage critical thinking about the ethical implications of AI in various contexts, including healthcare, finance, and social media.",
			reflectionDescriptionStudentPlaceholder:
				"This reflection series will help me explore ethical considerations in AI development. Through multiple reflection sessions, I'll examine different aspects like responsible AI development, algorithmic bias, and privacy concerns. These reflections will help develop my critical thinking about AI's impact across healthcare, finance, and social media.",
			interviewDescriptionPlaceholder:
				"This mock interview series simulates a full interview process. Students will progress through multiple interview stages. This structured approach helps students develop their interviewing skills and confidence.",
			interviewDescriptionStudentPlaceholder:
				"This mock interview series will help prepare me for real-world interviews. I will progress through multiple interview stages. These sessions will help develop my interviewing skills and confidence.",
			jobSearchDescriptionPlaceholder:
				"This job search assignment helps track and support students throughout their job search process. Through regular check-ins, students will discuss their progress, challenges, and next steps. These sessions ensure students stay on track with their goals and get guidance when needed.",
			jobSearchDescriptionStudentPlaceholder:
				"This job search will help me stay accountable during my job search. Through regular check-ins, I'll discuss my progress, challenges, and next steps to ensure I'm on track with my goals.",
			checkInSimDescriptionPlaceholder:
				"This check-in assignment helps students keep track of their ongoing learning experiences and progress since their last call. Through regular check-ins, students will discuss their progress, challenges, and next steps. These sessions ensure students stay on track with their goals and get guidance when needed.",
			checkInSimDescriptionStudentPlaceholder:
				"This check-in will help me stay accountable during my learning process. Through regular check-ins, I'll discuss my progress, challenges, and next steps to ensure I'm on track with my goals.",
			careerPathDescriptionPlaceholder:
				"This career path assignment helps students explore and plan their career paths. Through regular check-ins, students will disover possible career paths, progress through a roadmap, and get guidance when needed.",
			careerPathDescriptionStudentPlaceholder:
				"This career path assignment will help me stay accountable during my career path exploration. Through regular check-ins, I'll discuss my progress, challenges, and next steps to ensure I'm on track with my goals.",
			frontlineDescriptionPlaceholder:
				"This frontline training series helps students develop customer service skills. Students will practice handling different scenarios like denying requests professionally, gathering information effectively, and providing solutions. Each stage focuses on a specific framework to build well-rounded service skills.",
			frontlineDescriptionStudentPlaceholder:
				"This frontline training series will help me stay accountable during my customer service training. Through regular check-ins, I'll discuss my progress, challenges, and next steps to ensure I'm on track with my goals.",
			courseName: "Course/Project Name",
			courseNameTooltip: "This name is used to organize course assignments at scale.",
			reflectionCourseNamePlaceholder: "Reflection for AI Ethics Program",
			interviewCourseNamePlaceholder: "Mock Interview",
			careerPathCourseNamePlaceholder: "Career Path Discovery",
			frontlineCourseNamePlaceholder: "Frontline Training Series",
			courseCode: "Course/Project Code",
			courseCodeTooltip: "This code is used to organize course assignments at scale.",
			optional: "Optional",
			courseSection: "Course/Project Section",
			courseSectionTooltip:
				"The specific section of the course/project. Useful for courses with multiple sections to differentiate assignments and student groups."
		},
		grading: {
			title: "Grading",
			description: "Select how you want this assignment to be graded.",
			noGrading: "No grading",
			passOrFail: "Pass or Fail",
			passOrFailTooltip: "Students pass if they make a genuine attempt to complete the full call.",
			recommended: "Recommended",
			numeric: "Numeric",
			numericTooltip: "Assigns a score out of 100 based on the weighted components you define below.",
			gradingWeights: "Grading Weights",
			percentage: "%",
			total: "Total",
			mustEqual100: "Must equal 100%",
			gradeVisibility: "Grade Visibility:",
			hideGrades: "Hide grades from students",
			hideGradesTooltip: "Students will not be able to see their grades for any calls in this assignment.",
			showGrades: "Show grades to students",
			showGradesTooltip: "Students will be able to see their grades for each call after submission."
		},
		distribution: {
			title: "Distribution",
			emailDistribution: {
				title: "Option 1: Email Distribution",
				titleTooltip: "Enter student emails or upload a CSV list to invite them directly.",
				description: "Send an email invitation to students with assignment details.",
				recipientList: "Recipient List",
				enterEmailPlaceholder: "Enter email addresses",
				removeEmailAria: "Remove {email}",
				moreEmails: "... {count} more",
				csvUploadLabel: "Or upload a CSV file with email addresses",
				csvUploadButton: "Upload CSV with Emails",
				csvUploadAria: "Upload CSV file with email addresses"
			},
			shareLink: {
				title: "Option 2: Share Assignment Link",
				titleTooltip: "Copy this link to distribute via LMS, Email, etc.",
				description: "Share this link with students (Via LMS, Email)",
				assignmentLink: "Assignment Link",
				assignmentLinkAria: "Assignment link",
				copyButton: "Copy",
				copyButtonAria: "Copy assignment link to clipboard",
				copied: "Copied"
			}
		},
		notifications: {
			title: "Student Notifications",
			description: "Choose which automated and optional notifications students receive. Notifications help keep students on track.",
			automatic: {
				title: "Automatic Notifications:",
				enrollment: {
					title: "Enrollment notification email",
					tooltip:
						"This email is sent to students when they are enrolled in an assignment. It will give them a link to the assignment dashboard and include all relevant reflection call dates."
				},
				sessionReport: {
					title: "Session report email",
					tooltip:
						"This email is sent to students after each session providing them with a pdf copy of the session report as well as a link to confirm, add comments, and submit."
				}
			},
			optional: {
				title: "Optional Notifications:",
				availabilityWindow: {
					title: "Call availability window open notification",
					tooltip: "Notify students when a new stage becomes available.",
					ariaLabel: "Toggle call availability window open notification"
				},
				reminder24Hours: {
					title: "24-hour reminder email",
					tooltip: "Send a reminder 24 hours before the due date.",
					ariaLabel: "Toggle 24-hour reminder email"
				},
				dueDate: {
					title: "Due date reminder email",
					tooltip: "Remind students on the due date to complete the stage if they haven't already.",
					ariaLabel: "Toggle due date reminder email"
				}
			}
		},
		assignmentSettings: {
			title: "Aggregated Summary Options",
			description:
				"Choose what information you'd like to include in the aggregated reports sent to you 24 hours after each due date. This can help you track participation and performance at a glance.",
			summaryContent: {
				title: "Summary Content",
				includeUserIdentification: {
					title: "Include student Name and Email",
					tooltip: "Identify which students completed the assignment."
				},
				includeTranscript: {
					title: "Include student transcript",
					tooltip: "Attach a text transcript of the student's responses."
				},
				includeAudio: {
					title: "Include student audio recording",
					tooltip: "Include an audio recording of each student's session, if available."
				}
			},
			emailReports: {
				title: "Email Report Frequency",
				tooltip: "Decide when to receive summary reports—after each due date, or only after the final due date.",
				afterEachDueDate: {
					title: "Send a report 24 hours after each due date has passed",
					emphasis: "24 hours after each",
					tooltip: "This is an aggregated summary of student activity for each assigned call generated 24 hours after its due date."
				},
				afterFinalDueDate: {
					title: "Send a report only after the final due date has passed",
					emphasis: "only after the final",
					tooltip: "This is an aggregated summary of student activity for each assigned call generated 24 hours after its due date."
				},
				noReports: "Do not send email reports"
			},
			emailContent: {
				title: "What do you want included in the email?",
				adminSummaryLink: "Link to admin summary",
				includeCSV: {
					title: "Include a CSV file of the student activity in the email",
					tooltip: "This includes a list of the students and their activity for each assigned call."
				},
				includePDF: {
					title: "Include a PDF summary in the email",
					tooltip: "This includes a PDF version of the admin summary."
				}
			},
			additionalFaculty: {
				title: "Additional Faculty Members",
				tooltip:
					"Add colleagues who should be able to view the assignment and receive the aggregated summary reports. They will have an overview of student activity without needing separate logins.",
				description:
					"Do you want any other colleagues to join the assignment as admins to view the assignment and receive copies of the activity reports?",
				placeholder: "Enter email addresses",
				removeEmailAria: "Remove {email} from recipients",
				addEmailAria: "Add additional faculty member email"
			},
			note: "Note: You can always log in to the dashboard to view assignment activity at any time."
		}
	},
	fr: {
		next: "Suivant",
		previous: "Précédent",
		assignmentDetails: {
			title: "Détails de l'affectation",
			description:
				"Donnez un nom et une description clairs à votre affectation. Ensuite, définissez comment vous souhaitez distribuer cette affectation aux étudiants, configurer les notifications et choisir comment les rapports agrégés sont générés."
		},
		practiceDetails: {
			description: "Donnez un nom et une description clairs à votre affectation pratique pour vous aider à suivre votre progression."
		},
		information: {
			assignmentName: "Nom de l'affectation",
			interviewName: "Nom de l'entretien",
			frontlineName: "Nom de la ligne de front",
			careerPathName: "Nom de la simulation de découverte de carrière",
			reflectionName: "Nom de la réflexion",
			assignmentNameTooltip:
				"Donnez un nom et une description clairs à votre affectation. Ensuite, définissez comment vous souhaitez distribuer cette affectation aux étudiants, configurer les notifications et choisir comment les rapports agrégés sont générés.",
			interviewNamePlaceholder: "Entretien simulé",
			jobSearchNamePlaceholder: "Recherche d'emploi pour coopératif",
			checkInSimNamePlaceholder: "Vérification sur le contenu du cours",
			careerPathNamePlaceholder: "Découverte de carrière",
			frontlineNamePlaceholder: "Formation de la ligne de front",
			reflectionNamePlaceholder: "Réflexion pour le programme d'éthique AI",
			description: "Description",
			descriptionTooltip:
				"Expliquez le but et le champ d'application de cette affectation. Cette contexte aide l'assistant IA à guider des conversations significatives lors des appels de réflexion.",
			descriptionHeading: "Cette description apparaîtra dans l'email initial d'inscription de l'étudiant et sur leur page de début d'appel.",
			reflectionDescriptionPlaceholder:
				"Cette réflexion fait partie de notre programme d'éthique AI. Les étudiants participeront à 6 sessions au cours du cours, chacune axée sur un aspect différent de l'éthique AI. Ces réflexions encourageront le raisonnement critique sur les implications éthiques de l'IA dans divers contextes, y compris la santé, le financement et les médias sociaux.",
			reflectionDescriptionStudentPlaceholder:
				"Cette série de réflexions m'aidera à explorer les considérations éthiques dans le développement de l'IA. Au travers de plusieurs sessions de réflexion, je m'examinerai différents aspects comme le développement responsable de l'IA, le biais algorithmique et les préoccupations concernant la vie privée. Ces réflexions m'aideront à développer mon raisonnement critique sur l'impact de l'IA dans la santé, le financement et les médias sociaux.",
			interviewDescriptionPlaceholder:
				"Cette série d'entretiens simulés simule un processus d'entretien complet. Les étudiants progresseront par étapes d'entretien multiples. Cette approche structurée aide les étudiants à développer leurs compétences en entretien et à gagner en confiance.",
			interviewDescriptionStudentPlaceholder:
				"Cette série d'entretiens simulés m'aidera à me préparer pour les entretiens réels. Je progresserai par étapes d'entretien multiples. Ces sessions m'aideront à développer mes compétences en entretien et à gagner en confiance.",
			jobSearchDescriptionPlaceholder:
				"Cette affectation de recherche d'emploi aide à suivre et à soutenir les étudiants tout au long de leur processus de recherche d'emploi. Par le biais de vérifications régulières, les étudiants discuteront de leur progression, de leurs défis et de leurs étapes suivantes. Ces sessions garantissent que les étudiants restent sur la bonne voie et obtiennent de l'aide lorsqu'ils en ont besoin.",
			jobSearchDescriptionStudentPlaceholder:
				"Cette affectation de recherche d'emploi m'aidera à rester responsable de ma recherche d'emploi. Par le biais de vérifications régulières, je discuterai de ma progression, de mes défis et de mes étapes suivantes pour m'assurer de rester sur la bonne voie de mes objectifs.",
			checkInSimDescriptionPlaceholder:
				"Cette affectation de vérification aide les étudiants à suivre leurs expériences et leur progression depuis leur dernier appel. Par le biais de vérifications régulières, les étudiants discuteront de leur progression, de leurs défis et de leurs étapes suivantes. Ces sessions garantissent que les étudiants restent sur la bonne voie et obtiennent de l'aide lorsqu'ils en ont besoin.",
			checkInSimDescriptionStudentPlaceholder:
				"Cette vérification m'aidera à rester responsable de mon processus d'apprentissage. Par le biais de vérifications régulières, je discuterai de ma progression, de mes défis et de mes étapes suivantes pour m'assurer de rester sur la bonne voie de mes objectifs.",
			careerPathDescriptionPlaceholder:
				"Cette affectation de découverte de carrière aide les étudiants à explorer et à planifier leurs carrières. Par le biais de vérifications régulières, les étudiants découvriront des carrières possibles, progresseront par un cheminement, et obtiendront de l'aide lorsqu'ils en ont besoin.",
			careerPathDescriptionStudentPlaceholder:
				"Cette affectation de découverte de carrière m'aidera à rester responsable de ma découverte de carrière. Par le biais de vérifications régulières, je discuterai de ma progression, de mes défis et de mes étapes suivantes pour m'assurer de rester sur la bonne voie de mes objectifs.",
			frontlineDescriptionPlaceholder:
				"Cette série de formation de ligne de front aide les étudiants à développer des compétences de service client. Les étudiants pratiqueront à gérer différentes situations comme refuser des demandes professionnellement, collecter des informations efficacement et fournir des solutions. Chaque étape se concentre sur un cadre spécifique pour construire des compétences de service client équilibrées.",
			frontlineDescriptionStudentPlaceholder:
				"Cette série de formation de ligne de front m'aidera à rester responsable de ma formation de service client. Par le biais de vérifications régulières, je discuterai de ma progression, de mes défis et de mes étapes suivantes pour m'assurer de rester sur la bonne voie de mes objectifs.",
			courseName: "Nom du cours/projet",
			courseNameTooltip: "Ce nom est utilisé pour organiser les affectations de cours à l'échelle.",
			reflectionCourseNamePlaceholder: "Réflexion pour le programme d'éthique AI",
			interviewCourseNamePlaceholder: "Entretien simulé",
			careerPathCourseNamePlaceholder: "Découverte de carrière",
			frontlineCourseNamePlaceholder: "Formation de la ligne de front",
			courseCode: "Code du cours/projet",
			courseCodeTooltip: "Ce code est utilisé pour organiser les affectations de cours à l'échelle.",
			optional: "Optionnel",
			courseSection: "Section du cours/projet",
			courseSectionTooltip:
				"La section spécifique du cours/projet. Utile pour les cours avec plusieurs sections pour différencier les affectations et les groupes d'étudiants."
		},
		grading: {
			title: "Notation",
			description: "Sélectionnez comment vous souhaitez noter cette affectation.",
			noGrading: "Pas de notation",
			passOrFail: "Réussite ou échec",
			passOrFailTooltip: "Les étudiants réussissent s'ils font une tentative sincère de compléter l'appel en entier.",
			recommended: "Recommandé",
			numeric: "Numérique",
			numericTooltip: "Attribue une note sur 100 basée sur les composantes pondérées que vous définissez ci-dessous.",
			gradingWeights: "Pondérations de notation",
			percentage: "%",
			total: "Total",
			mustEqual100: "Doit égaler 100%",
			gradeVisibility: "Visibilité des notes:",
			hideGrades: "Masquer les notes aux étudiants",
			hideGradesTooltip: "Les étudiants ne pourront pas voir leurs notes pour aucun appel dans cette affectation.",
			showGrades: "Montrer les notes aux étudiants",
			showGradesTooltip: "Les étudiants pourront voir leurs notes pour chaque appel après la soumission."
		},
		distribution: {
			title: "Distribution",
			emailDistribution: {
				title: "Option 1: Distribution par courriel",
				titleTooltip: "Entrez les courriels des étudiants ou téléversez une liste CSV pour les inviter directement.",
				description: "Envoyez une invitation par courriel aux étudiants avec les détails de l'affectation.",
				recipientList: "Liste des destinataires",
				enterEmailPlaceholder: "Entrez les adresses courriel",
				removeEmailAria: "Supprimer {email}",
				moreEmails: "... {count} de plus",
				csvUploadLabel: "Ou téléversez un fichier CSV avec les adresses courriel",
				csvUploadButton: "Téléverser CSV avec courriels",
				csvUploadAria: "Téléverser un fichier CSV avec les adresses courriel"
			},
			shareLink: {
				title: "Option 2: Partager le lien de l'affectation",
				titleTooltip: "Copiez ce lien pour le distribuer via LMS, courriel, etc.",
				description: "Partagez ce lien avec les étudiants (Via LMS, courriel)",
				assignmentLink: "Lien de l'affectation",
				assignmentLinkAria: "Lien de l'affectation",
				copyButton: "Copier",
				copyButtonAria: "Copier le lien de l'affectation dans le presse-papiers",
				copied: "Copié"
			}
		},
		notifications: {
			title: "Notifications aux étudiants",
			description:
				"Choisissez quelles notifications automatiques et optionnelles les étudiants reçoivent. Les notifications aident les étudiants à rester sur la bonne voie.",
			automatic: {
				title: "Notifications automatiques :",
				enrollment: {
					title: "Courriel de notification d'inscription",
					tooltip:
						"Ce courriel est envoyé aux étudiants lorsqu'ils sont inscrits à une affectation. Il leur donnera un lien vers le tableau de bord de l'affectation et inclura toutes les dates d'appel de réflexion pertinentes."
				},
				sessionReport: {
					title: "Courriel de rapport de session",
					tooltip:
						"Ce courriel est envoyé aux étudiants après chaque session, leur fournissant une copie PDF du rapport de session ainsi qu'un lien pour confirmer, ajouter des commentaires et soumettre."
				}
			},
			optional: {
				title: "Notifications optionnelles :",
				availabilityWindow: {
					title: "Notification d'ouverture de la fenêtre de disponibilité d'appel",
					tooltip: "Notifier les étudiants lorsqu'une nouvelle étape devient disponible.",
					ariaLabel: "Basculer la notification d'ouverture de la fenêtre de disponibilité d'appel"
				},
				reminder24Hours: {
					title: "Courriel de rappel 24 heures avant",
					tooltip: "Envoyer un rappel 24 heures avant la date d'échéance.",
					ariaLabel: "Basculer le courriel de rappel 24 heures avant"
				},
				dueDate: {
					title: "Courriel de rappel de la date d'échéance",
					tooltip: "Rappeler aux étudiants à la date d'échéance de compléter l'étape s'ils ne l'ont pas déjà fait.",
					ariaLabel: "Basculer le courriel de rappel de la date d'échéance"
				}
			}
		},
		assignmentSettings: {
			title: "Options de résumé agrégé",
			description:
				"Choisissez les informations que vous souhaitez inclure dans les rapports agrégés qui vous sont envoyés 24 heures après chaque date d'échéance. Cela peut vous aider à suivre la participation et la performance en un coup d'œil.",
			summaryContent: {
				title: "Contenu du résumé",
				includeUserIdentification: {
					title: "Inclure le nom et l'email de l'étudiant",
					tooltip: "Identifier quels étudiants ont complété l'affectation."
				},
				includeTranscript: {
					title: "Inclure la transcription de l'étudiant",
					tooltip: "Joindre une transcription textuelle des réponses de l'étudiant."
				},
				includeAudio: {
					title: "Inclure l'enregistrement audio de l'étudiant",
					tooltip: "Inclure un enregistrement audio de chaque session de l'étudiant, si disponible."
				}
			},
			emailReports: {
				title: "Fréquence des rapports par courriel",
				tooltip: "Décidez quand recevoir les rapports sommaires—après chaque date d'échéance, ou seulement après la date d'échéance finale.",
				afterEachDueDate: {
					title: "Envoyer un rapport 24 heures après chaque date d'échéance passée",
					emphasis: "24 heures après chaque",
					tooltip:
						"Ceci est un résumé agrégé de l'activité des étudiants pour chaque appel assigné, généré 24 heures après sa date d'échéance."
				},
				afterFinalDueDate: {
					title: "Envoyer un rapport seulement après la date d'échéance finale",
					emphasis: "seulement après la finale",
					tooltip:
						"Ceci est un résumé agrégé de l'activité des étudiants pour chaque appel assigné, généré 24 heures après sa date d'échéance."
				},
				noReports: "Ne pas envoyer de rapports par courriel"
			},
			emailContent: {
				title: "Que voulez-vous inclure dans le courriel?",
				adminSummaryLink: "Lien vers le résumé administrateur",
				includeCSV: {
					title: "Inclure un fichier CSV de l'activité des étudiants dans le courriel",
					tooltip: "Ceci inclut une liste des étudiants et leur activité pour chaque appel assigné."
				},
				includePDF: {
					title: "Inclure un résumé PDF dans le courriel",
					tooltip: "Ceci inclut une version PDF du résumé administrateur."
				}
			},
			additionalFaculty: {
				title: "Membres additionnels du corps enseignant",
				tooltip:
					"Ajoutez des collègues qui devraient pouvoir voir l'affectation et recevoir les rapports sommaires agrégés. Ils auront un aperçu de l'activité des étudiants sans avoir besoin de connexions séparées.",
				description:
					"Voulez-vous que d'autres collègues rejoignent l'affectation en tant qu'administrateurs pour voir l'affectation et recevoir des copies des rapports d'activité?",
				placeholder: "Entrez les adresses courriel",
				removeEmailAria: "Retirer {email} des destinataires",
				addEmailAria: "Ajouter l'email d'un membre additionnel du corps enseignant"
			},
			note: "Remarque : Vous pouvez toujours vous connecter au tableau de bord pour voir l'activité de l'affectation à tout moment."
		}
	}
};
