import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Field, ErrorMessage, FieldArray } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button } from "../../../shadcn-ui/Button";
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "../../../shadcn-ui/Select";
import { CheckCircleIcon, CalendarIcon, PlusIcon } from "@heroicons/react/20/solid";
import { CheckCircleIcon as CheckCircleOutlineIcon } from "@heroicons/react/24/outline";
import CustomTooltip from "../../../Utility/CustomTooltip";
import Spinner from "../../../Utility/Spinner";
import { Switch } from "../../../shadcn-ui/Switch";
import JobTitleSelect from "./JobTitleSelect";
import JobPostings from "./JobPostings";
import { AssignmentService, JobPosting } from "../../../../services/AssignmentService";
import { InterviewPayload, InterviewStage, InterviewStageType } from "../../../../types/InterviewPayload";
import { useTranslation } from "../../../Utility/UseTranslation";
import { fr, enCA } from "date-fns/locale";
import { useAppContext } from "../../../../contexts/AppContext";

// Define the structure of a session
interface Session {
	title: string;
	date: Date | null;
	startDate: Date | null;
	interview?: {
		interviewStageOrderNumber: number;
		interviewStageType: InterviewStageType;
		interviewerAttitude: string;
		interviewQuestionList: any[];
		jobTitle?: string;
		jobDescription?: string;
		jobSummary?: string;
		jobFunction?: string;
		jobExperienceLevel?: string;
		jobResponsibilities?: string[];
		jobSkillsRequired?: string[];
	};
}

// Define the structure of form values
interface Values {
	sessions: Session[];
	stages: InterviewStage[];
	useSharedDates: boolean;
	sharedStartDate: Date | null;
	sharedDueDate: Date | null;
	[key: string]: any;
}

// Define props for the InterviewDates component
export interface InterviewDatesProps {
	values: Values;
	errors: any;
	touched: any;
	setFieldValue: (field: string, value: any) => void;
	onNext: () => void;
	onPrevious: () => void;
	isValid: boolean;
	dirty: boolean;
	isEditMode: boolean;
}

// Define a helper component for DateRangePicker
interface DateRangePickerProps {
	startDate: Date | null;
	endDate: Date | null;
	onStartDateChange: (date: Date | null) => void;
	onEndDateChange: (date: Date | null) => void;
	disabled?: boolean;
	minDate?: Date;
	maxDate?: Date;
	minEndDate?: Date;
}

// Update the DateRangePicker component
const DateRangePicker: React.FC<DateRangePickerProps> = ({
	startDate,
	endDate,
	onStartDateChange,
	onEndDateChange,
	disabled,
	minDate,
	maxDate,
	minEndDate
}) => {
	const { t } = useTranslation("interviewCreatorDates");
	// Convert null to undefined for react-datepicker
	const convertNullToUndefined = (date: Date | null): Date | undefined => {
		return date || undefined;
	};
	const { language } = useAppContext();
	const locale = language === "fr" ? fr : enCA;

	// Helper function to set default start date
	const handleEndDateChange = (date: Date | null) => {
		if (date && minEndDate && date < minEndDate) {
			return;
		}
		onEndDateChange(date);
		if (date) {
			if (!startDate || startDate > date) {
				const defaultStartDate = new Date(date);
				defaultStartDate.setDate(defaultStartDate.getDate() - 5);
				// Ensure default start date isn't before minDate
				if (minDate && defaultStartDate < minDate) {
					defaultStartDate.setTime(minDate.getTime());
				}
				onStartDateChange(defaultStartDate);
			}
		}
	};

	// Validate start date is not after end date
	const handleStartDateChange = (date: Date | null) => {
		if (date && endDate && date > endDate) {
			// If selected start date is after end date, don't allow it
			return;
		}
		onStartDateChange(date);
	};

	return (
		<div className="flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0">
			<div className="flex-1">
				<div className="flex items-center mb-1">
					<label className="block text-sm font-medium text-gray-700">{t("dateRangePicker.dueDate")}*</label>
					<CustomTooltip content={t("dateRangePicker.dueDateTooltip")} />
				</div>
				<div className="relative">
					<DatePicker
						locale={locale}
						selected={endDate}
						onChange={handleEndDateChange}
						selectsEnd
						startDate={convertNullToUndefined(startDate)}
						endDate={convertNullToUndefined(endDate)}
						minDate={minEndDate || minDate}
						maxDate={maxDate}
						disabled={disabled}
						className="mt-1 block w-full rounded-md border border-[#00a9af] pl-10 pr-3 py-2 text-gray-900"
						placeholderText={t("dateRangePicker.selectDueDate")}
						excludeDates={[]} // You can add specific dates to exclude if needed
						filterDate={(date) => {
							if (minEndDate && date < minEndDate) return false;
							if (maxDate && date > maxDate) return false;
							return true;
						}}
					/>
					<CalendarIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
				</div>
			</div>
			<div className="flex-1">
				<div className="flex items-center mb-1">
					<label className="block text-sm font-medium text-gray-700">{t("dateRangePicker.startOfAvailability")}*</label>
					<CustomTooltip content={t("dateRangePicker.startOfAvailabilityTooltip")} />
				</div>
				<div className="relative">
					<DatePicker
						locale={locale}
						selected={startDate}
						onChange={handleStartDateChange}
						selectsStart
						startDate={convertNullToUndefined(startDate)}
						endDate={convertNullToUndefined(endDate)}
						maxDate={endDate || undefined}
						minDate={minDate}
						disabled={disabled}
						className="mt-1 block w-full rounded-md border border-[#00a9af] pl-10 pr-3 py-2 text-gray-900"
						placeholderText={t("dateRangePicker.selectStartDate")}
					/>
					<CalendarIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
				</div>
			</div>
		</div>
	);
};

const SharedDatePicker: React.FC<{
	isEditMode: boolean;
	startDate: Date | null;
	dueDate: Date | null;
	onStartDateChange: (date: Date | null) => void;
	onDueDateChange: (date: Date | null) => void;
	disabled?: boolean;
	useSharedDates: boolean;
	onToggleSharedDates: (checked: boolean, setFieldValue: (field: string, value: any) => void) => void;
	setFieldValue: (field: string, value: any) => void;
}> = ({ isEditMode, startDate, dueDate, onStartDateChange, onDueDateChange, disabled, useSharedDates, onToggleSharedDates, setFieldValue }) => {
	return (
		<>
			<div className={`${!useSharedDates ? "opacity-50" : ""} bg-white border border-[#eaecf0] rounded-lg p-6 mb-6`}>
				<div className="flex items-center">
					{!isEditMode ? (
						<>
							<Switch
								checked={useSharedDates}
								onCheckedChange={(checked) => onToggleSharedDates(checked, setFieldValue)}
								aria-label="Toggle shared schedule for all interview stages"
							/>
							<h3 className="text-lg font-medium text-gray-900 ml-3">Shared Schedule</h3>
							<CustomTooltip content="If enabled, all stages share the same start and due dates. If disabled, you can assign different timelines to each stage." />
						</>
					) : (
						<p className="text-sm text-gray-500">Schedule settings are locked in edit mode</p>
					)}
				</div>
				{!isEditMode && useSharedDates && (
					<>
						<p className="text-sm text-gray-600 my-4">
							Use one schedule for all interview stages. Toggle this off if you need to set different dates for each stage.
						</p>
						<DateRangePicker
							startDate={startDate}
							endDate={dueDate}
							onStartDateChange={onStartDateChange}
							onEndDateChange={onDueDateChange}
							disabled={disabled}
							minDate={new Date()}
						/>
					</>
				)}
			</div>
			{!useSharedDates && (
				<p className="text-sm text-gray-600 italic">
					Individual date selection is now enabled. Please set specific dates for each active interview stage above.
				</p>
			)}
		</>
	);
};

// Add this helper function before the InterviewDates component
const getSessionIndexForStage = (stages: InterviewStage[], stageIndex: number): number => {
	return stages.slice(0, stageIndex).filter((stage) => stage.enabled).length;
};

// Helper function to determine if interview setup is valid
const isInterviewSetupValid = (values: any) => {
	if (values.jobTitleChoice === "Dynamic") return true;

	// Check if both fields exist and have non-empty trimmed values
	const hasTitle = Boolean(values.staticJobTitle?.trim());
	const hasDescription = Boolean(values.jobDescription?.trim());

	return hasTitle && hasDescription;
};

// InterviewDates component for configuring reflection sessions
const InterviewDates: React.FC<InterviewDatesProps> = ({
	values,
	errors,
	touched,
	setFieldValue,
	onNext,
	onPrevious,
	isValid,
	dirty,
	isEditMode
}) => {
	const location = useLocation();
	const isAdminPath = location.pathname.startsWith("/admin");

	const [jobPostings, setJobPostings] = useState<JobPosting[]>([]);
	const [isLoadingJobPosting, setIsLoadingJobPosting] = useState(false);
	const { language } = useAppContext();
	const { t } = useTranslation("interviewCreatorDates");

	// Initialize jobTitleChoice based on faculty clearance
	useEffect(() => {
		if (!values.jobTitleChoice) {
			// If not faculty, always set to Static
			setFieldValue("jobTitleChoice", isAdminPath ? "Dynamic" : "Static");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Initialize stages if not already set
	useEffect(() => {
		if (!values.stages) {
			let initialStages: InterviewStage[] = [];

			if (isEditMode && values.sessions?.length > 0) {
				// Process existing sessions to create stages
				values.sessions.forEach((session, index) => {
					let stageType: InterviewStageType;
					let orderNumber: number;

					if (session.interview) {
						// If we have interview data, use it directly
						stageType = session.interview.interviewStageType;
						orderNumber = session.interview.interviewStageOrderNumber;
					} else {
						// Fallback to title-based logic
						if (session.title.toLowerCase().includes("screening")) {
							stageType = "screening";
							orderNumber = 1;
						} else if (session.title.toLowerCase().includes("final")) {
							stageType = "final";
							orderNumber = values.sessions.length;
						} else {
							stageType = "middle";
							orderNumber = index + 1;
						}
					}

					initialStages.push({
						interviewStageOrderNumber: orderNumber,
						interviewStageType: stageType,
						enabled: true,
						title: session.title,
						description: `${session.title} stage for candidate evaluation`,
						date: session.date,
						startDate: session.startDate
					});
				});

				// Sort stages by order number (null values - resume review - should come first)
				initialStages.sort((a, b) => {
					if (a.interviewStageOrderNumber === null) return -1;
					if (b.interviewStageOrderNumber === null) return 1;
					return a.interviewStageOrderNumber - b.interviewStageOrderNumber;
				});
			} else {
				// Default stages for create mode
				initialStages = [
					{
						interviewStageOrderNumber: 1,
						interviewStageType: "screening",
						enabled: true,
						title: t("interviewSetup.step2.stages.Screening Interview.title"),
						description: t("interviewSetup.step2.stages.Screening Interview.description"),
						date: null,
						startDate: null
					},
					{
						interviewStageOrderNumber: 2,
						interviewStageType: "middle",
						enabled: true,
						title: t("interviewSetup.step2.stages.Middle Interview.title"),
						description: t("interviewSetup.step2.stages.Middle Interview.description"),
						date: null,
						startDate: null
					},
					{
						interviewStageOrderNumber: 3,
						interviewStageType: "final",
						enabled: true,
						title: t("interviewSetup.step2.stages.Final Interview.title"),
						description: t("interviewSetup.step2.stages.Final Interview.description"),
						date: null,
						startDate: null
					}
				];
			}
			setFieldValue("stages", initialStages);

			// Initialize sessions to match only enabled stages
			setFieldValue(
				"sessions",
				initialStages.filter((stage) => stage.enabled) // Only include enabled stages
			);

			// Initialize shared dates - now defaulting to true
			if (values.sharedStartDate === undefined) {
				setFieldValue("sharedStartDate", null);
			}
			if (values.sharedDueDate === undefined) {
				setFieldValue("sharedDueDate", null);
			}
			if (values.useSharedDates === undefined) {
				setFieldValue("useSharedDates", true);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleStageToggle = (stageType: InterviewStageType, orderNumber: number, enabled: boolean) => {
		// If disabling a middle stage
		if (stageType === "middle" && !enabled) {
			// Find if this is an additional middle stage (not the first middle stage)
			const middleStages = values.stages.filter((s) => s.interviewStageType === "middle");
			const isFirstMiddle = middleStages.indexOf(middleStages.find((s) => s.interviewStageOrderNumber === orderNumber)!) === 0;

			if (!isFirstMiddle) {
				// Remove this stage and all subsequent additional middle stages from both arrays
				const updatedStages = values.stages.filter((stage) => {
					if (stage.interviewStageType === "middle" && stage.interviewStageOrderNumber >= orderNumber) {
						return false; // Remove this stage
					}
					return true;
				});

				// Reorder the remaining stages
				const finalStages = updatedStages.map((stage, index) => ({
					...stage,
					interviewStageOrderNumber: index + 1
				}));

				// Create new sessions array only including remaining stages
				const newSessions = finalStages
					.filter((stage) => stage.enabled)
					.map((stage, index) => {
						const existingSession = values.sessions.find(
							(s) => s.interview?.interviewStageOrderNumber === stage.interviewStageOrderNumber
						);
						return {
							...existingSession,
							title: stage.title,
							date: existingSession?.date || null,
							startDate: existingSession?.startDate || null,
							interview: {
								...existingSession?.interview,
								interviewStageOrderNumber: index + 1,
								interviewStageType: stage.interviewStageType
							}
						};
					});

				setFieldValue("stages", finalStages);
				setFieldValue("sessions", newSessions);
				return;
			} else {
				// For first middle stage, just disable it
				const updatedStages = values.stages.map((stage) => {
					if (stage.interviewStageType === stageType && stage.interviewStageOrderNumber === orderNumber) {
						return { ...stage, enabled: false };
					}
					return stage;
				});

				const newSessions = updatedStages
					.filter((stage) => stage.enabled)
					.map((stage, index) => {
						const existingSession = values.sessions.find(
							(s) => s.interview?.interviewStageOrderNumber === stage.interviewStageOrderNumber
						);
						return {
							...existingSession,
							title: stage.title,
							date: existingSession?.date || null,
							startDate: existingSession?.startDate || null,
							interview: {
								...existingSession?.interview,
								interviewStageOrderNumber: stage.interviewStageOrderNumber,
								interviewStageType: stage.interviewStageType
							}
						};
					});

				setFieldValue("stages", updatedStages);
				setFieldValue("sessions", newSessions);
				return;
			}
		}

		// For other stage types or when enabling a stage
		const updatedStages = values.stages.map((stage) => {
			if (stage.interviewStageType === stageType && stage.interviewStageOrderNumber === orderNumber) {
				return { ...stage, enabled };
			}
			return stage;
		});

		const newSessions = updatedStages
			.filter((stage) => stage.enabled)
			.map((stage, index) => {
				const existingSession = values.sessions.find((s) => s.interview?.interviewStageOrderNumber === stage.interviewStageOrderNumber);
				return {
					...existingSession,
					title: stage.title,
					date: existingSession?.date || null,
					startDate: existingSession?.startDate || null,
					interview: {
						...existingSession?.interview,
						interviewStageOrderNumber: stage.interviewStageOrderNumber,
						interviewStageType: stage.interviewStageType
					}
				};
			});

		setFieldValue("stages", updatedStages);
		setFieldValue("sessions", newSessions);
	};

	const addMiddleStage = () => {
		const middleStages = values.stages.filter((s) => s.interviewStageType === "middle");
		if (middleStages.length >= 3) return;

		const finalIndex = values.stages.findIndex((s) => s.interviewStageType === "final");
		const newOrderNumber = finalIndex + 1;

		const newStage: InterviewStage = {
			interviewStageOrderNumber: newOrderNumber,
			interviewStageType: "middle",
			enabled: true,
			title: `Middle Interview ${middleStages.length > 0 ? (middleStages.length === 1 ? "Two" : "Three") : ""}`,
			description: "Additional interview stage for candidate evaluation",
			date: null,
			startDate: null
		};

		// Create new stages array with updated order numbers
		const newStages = [
			...values.stages.slice(0, finalIndex),
			newStage,
			{
				...values.stages[finalIndex],
				interviewStageOrderNumber: newOrderNumber + 1
			}
		];

		// Create new session for the middle stage
		const newSession = {
			title: newStage.title,
			date: null,
			startDate: null,
			interview: {
				interviewStageOrderNumber: newOrderNumber,
				interviewStageType: "middle"
			}
		};

		// Update sessions array
		const updatedSessions = [
			...values.sessions.slice(0, finalIndex),
			newSession,
			{
				...values.sessions[finalIndex],
				interview: {
					...values.sessions[finalIndex].interview,
					interviewStageOrderNumber: newOrderNumber + 1
				}
			}
		];

		setFieldValue("stages", newStages);
		setFieldValue("sessions", updatedSessions);
	};

	const getPreviousStageEndDate = (stages: InterviewStage[], sessions: Session[], currentIndex: number): Date | undefined => {
		// Find the previous enabled stage
		for (let i = currentIndex - 1; i >= 0; i--) {
			if (stages[i].enabled && sessions[i]?.date) {
				return sessions[i].date || undefined;
			}
		}
		return undefined;
	};

	// Add this function to handle shared date changes
	const handleSharedDateChange = (type: "start" | "due", date: Date | null) => {
		if (type === "start") {
			setFieldValue("sharedStartDate", date);
		} else {
			setFieldValue("sharedDueDate", date);
		}

		// Update all enabled stages with the shared dates
		const updatedSessions = values.sessions.map((session: any, index: number) => {
			// Only update if the corresponding stage is enabled
			if (values.stages[index]?.enabled) {
				return {
					...session,
					title: values.stages[index].title,
					startDate: type === "start" ? date : values.sharedStartDate,
					date: type === "due" ? date : values.sharedDueDate
				};
			}
			return session;
		});

		setFieldValue("sessions", updatedSessions);
	};

	// Add this helper function to get the active stage number
	const getActiveStageNumber = (currentIndex: number): number => {
		return values.stages.slice(0, currentIndex + 1).filter((stage) => stage.enabled).length;
	};

	const handleToggleSharedDates = (checked: boolean, setFieldValue: (field: string, value: any) => void) => {
		setFieldValue("useSharedDates", checked);

		if (checked) {
			// When enabling shared dates, apply the shared dates to all enabled stages
			const updatedSessions = values.sessions.map((session: any, index: number) => {
				if (values.stages[index]?.enabled) {
					return {
						...session,
						title: values.stages[index].title,
						startDate: values.sharedStartDate,
						date: values.sharedDueDate
					};
				}
				return session;
			});
			setFieldValue("sessions", updatedSessions);
		} else {
			// When disabling shared dates, reset all dates
			const resetSessions = values.sessions.map((session: any, index: number) => ({
				...session,
				title: values.stages[index]?.title || session.title,
				startDate: null,
				date: null
			}));
			setFieldValue("sessions", resetSessions);
			setFieldValue("sharedStartDate", null);
			setFieldValue("sharedDueDate", null);
		}
	};

	// Update the useEffect that handles stage changes
	useEffect(() => {
		if (values.stages) {
			// Create or update sessions to match stages
			const updatedSessions = values.stages
				.filter((stage) => stage.enabled) // Only include enabled stages
				.map((stage: any, index: number) => {
					const existingSession = values.sessions?.[index] || {};
					return {
						...existingSession,
						title: stage.title,
						startDate: values.useSharedDates ? values.sharedStartDate : existingSession.startDate,
						date: values.useSharedDates ? values.sharedDueDate : existingSession.date
					};
				});
			setFieldValue("sessions", updatedSessions);
		}
	}, [values.stages, values.useSharedDates, values.sharedStartDate, values.sharedDueDate]);

	const validateStagesSection = (stages: InterviewStage[], sessions: Session[], useSharedDates: boolean): "valid" | "invalid" => {
		// First check if any stages are enabled
		const hasEnabledStages = stages?.some((stage) => stage.enabled);
		if (!hasEnabledStages) return "invalid";

		// If using shared dates, we only care that at least one stage is enabled
		if (useSharedDates) return "valid";

		// When not using shared dates, check if all enabled stages have their dates set
		const allEnabledStagesHaveDates = stages.every((stage, index) => {
			if (!stage.enabled) return true; // Skip disabled stages
			const session = sessions[index];
			return session?.startDate !== null && session?.date !== null;
		});

		return allEnabledStagesHaveDates ? "valid" : "invalid";
	};

	const validateScheduleSection = (
		useSharedDates: boolean,
		sharedStartDate: Date | null,
		sharedDueDate: Date | null
	): "valid" | "invalid" | "none" => {
		if (!useSharedDates) return "none";
		return sharedStartDate && sharedDueDate ? "valid" : "invalid";
	};

	const searchJobs = async (title: string) => {
		setJobPostings([]);
		try {
			const jobPostingList = await AssignmentService.searchJobPostings(title, language);
			setJobPostings(jobPostingList);
		} catch (error) {
			console.error("Error fetching job postings:", error);
			setJobPostings([]);
		}
	};

	const handleJobSelection = async (option: JobPosting) => {
		setFieldValue("staticJobTitle", option.title);

		// Create interview payload
		const interviewData: Partial<InterviewPayload> = {
			jobTitle: option.title,
			jobSummary: option.summary || "",
			jobFunction: "",
			jobExperienceLevel: "",
			jobResponsibilities: [],
			jobSkillsRequired: []
		};

		if (option.postingId === "custom") {
			setFieldValue("jobDescription", option.summary || "");
			interviewData.jobDescription = option.summary || "";
		} else {
			try {
				setIsLoadingJobPosting(true);
				const fullJobDetails = await AssignmentService.getJobPosting(option.postingId);
				const description = htmlToPlainText(fullJobDetails.jobBody || "") || option.summary || "";
				setFieldValue("jobDescription", description);
				interviewData.jobDescription = description;
			} catch (error) {
				console.error("Error fetching full job details:", error);
				setFieldValue("jobDescription", option.summary || "");
				interviewData.jobDescription = option.summary || "";
			} finally {
				setIsLoadingJobPosting(false);
			}
		}

		setFieldValue("interview", new InterviewPayload(interviewData));
	};

	const htmlToPlainText = (html: string) => {
		const tempElement = document.createElement("div");
		tempElement.innerHTML = html;

		const text = tempElement.innerHTML
			.replace(/&nbsp;/g, " ")
			.replace(/<\/p>/g, "\n\n")
			.replace(/<\/li>/g, "\n")
			.replace(/<li>/g, "- ")
			.replace(/<br>/g, "\n")
			.replace(/<strong>/g, "*")
			.replace(/<\/strong>/g, "*")
			.replace(/\n\s*\n/g, "\n\n")
			.replace(/&amp;/g, "&")
			.replace(/<\/?[^>]+(>|$)/g, "");
		return text.trim();
	};

	const stripTrailingNumber = (title: string): string => {
		return title.replace(
			/\s(?:\d+|Two|Three|Four|Five|Six|Seven|Eight|Nine|Ten|Eleven|Twelve|Thirteen|Fourteen|Fifteen|Sixteen|Seventeen|Eighteen|Nineteen|Twenty)$/,
			""
		);
	};

	// Update the isFormValid function
	const isFormValid = () => {
		if (isEditMode) return true;

		// Check staticJobTitle only when jobTitleChoice is Static
		const hasValidJobTitle = values.jobTitleChoice === "Static" ? values.staticJobTitle && values.staticJobTitle.trim() !== "" : true;

		// Check staticJobDescription only when jobTitleChoice is Static
		const hasValidJobDescription = values.jobTitleChoice === "Static" ? values.jobDescription && values.jobDescription.trim() !== "" : true;
		if (!hasValidJobTitle || !hasValidJobDescription) return false;

		// Then check if any stages are enabled
		const hasEnabledStages = values.stages?.some((stage: InterviewStage) => stage.enabled);
		if (!hasEnabledStages) return false;

		// Finally check dates
		if (values.useSharedDates) {
			return values.sharedStartDate !== null && values.sharedDueDate !== null;
		}

		// If not using shared dates, check each enabled stage has both dates set
		return values.stages?.every((stage: InterviewStage, index: number) => {
			if (!stage.enabled) return true; // Skip disabled stages
			const session = values.sessions[index];
			return session?.startDate !== null && session?.date !== null;
		});
	};

	return (
		<div className="space-y-8">
			{/* Welcome Message */}
			<Panel title={t("configureYourInterviewAssignmentPannel.title")}>
				{isAdminPath ? (
					<>
						<p className="text-base text-gray-600 mb-4">{t("configureYourInterviewAssignmentPannel.description")}</p>
						<p className="text-base text-gray-600">{t("configureYourInterviewAssignmentPannel.nextSteps")}</p>
					</>
				) : (
					<>
						<p className="text-base text-gray-600 mb-4">{t("configureYourInterviewPannel.description")}</p>
						<p className="text-base text-gray-600">{t("configureYourInterviewPannel.nextSteps")}</p>
					</>
				)}
			</Panel>

			{/* Step 1: Interview Setup */}
			<Panel title={t("interviewSetup.step1.title")} validationState={isInterviewSetupValid(values) ? "valid" : "invalid"}>
				{/* Students Choose Job Title Field - only shown for faculty */}
				{isAdminPath && (
					<div className="mt-4">
						<label htmlFor="jobTitleChoice" className="text-sm font-medium text-gray-700 mb-1 flex items-center">
							{t("interviewSetup.step1.jobTitleChoice.label")}
							<CustomTooltip content={t("interviewSetup.step1.jobTitleChoice.tooltip")} />
						</label>
						<div className="relative">
							<Select
								defaultValue="Dynamic"
								value={values.jobTitleChoice}
								onValueChange={(value) => {
									setFieldValue("jobTitleChoice", value);
									if (value === "Dynamic") {
										setFieldValue("staticJobTitle", "");
										setFieldValue("jobDescription", "");
									}
								}}
								disabled={isEditMode}
							>
								<SelectTrigger className="w-full px-4 py-2 text-left bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
									<SelectValue placeholder={t("interviewSetup.step1.jobTitleChoice.placeholder")} />
								</SelectTrigger>
								<SelectContent className="w-full bg-white border border-gray-300 rounded-md shadow-lg">
									<SelectItem value="Dynamic" className="py-2 px-4 hover:bg-gray-100">
										{t("interviewSetup.step1.jobTitleChoice.options.yes")}
									</SelectItem>
									<SelectItem value="Static" className="py-2 px-4 hover:bg-gray-100">
										{t("interviewSetup.step1.jobTitleChoice.options.no")}
									</SelectItem>
								</SelectContent>
							</Select>
						</div>
					</div>
				)}

				{/* Static Job Title Field - shown when jobTitleChoice is Static or user is not faculty */}
				{(values.jobTitleChoice === "Static" || !isAdminPath) && (
					<div className="mt-4 space-y-4">
						<div>
							<label htmlFor="staticJobTitle" className="block text-sm font-medium text-gray-700 mb-1">
								{t("interviewSetup.step1.jobTitle.label")}
							</label>
							<JobTitleSelect
								value={values.staticJobTitle || ""}
								onChange={(value) => setFieldValue("staticJobTitle", value)}
								onJobSearch={searchJobs}
								disabled={isEditMode}
							/>
							<ErrorMessage name="staticJobTitle" component="div" className="mt-1 text-sm text-red-600" />
							<JobPostings jobTitle={values.staticJobTitle} options={jobPostings} onSelect={handleJobSelection} />
						</div>

						<div>
							<label htmlFor="jobDescription" className="block text-sm font-medium text-gray-700 mb-1">
								{t("interviewSetup.step1.jobDescription.label")}
							</label>
							{isLoadingJobPosting ? (
								<Spinner />
							) : (
								<Field
									as="textarea"
									name="jobDescription"
									id="jobDescription"
									rows={8}
									className={`mt-1 block w-full rounded-md border-gray-300 border focus:border-indigo-500 focus:ring-indigo-500 text-base p-2 ${
										isEditMode ? "text-gray-500" : "text-gray-900"
									}`}
									placeholder={t("interviewSetup.step1.jobDescription.placeholder")}
									disabled={isEditMode}
								/>
							)}
							<ErrorMessage name="jobDescription" component="div" className="mt-1 text-sm text-red-600" />
						</div>
					</div>
				)}
			</Panel>

			{/* Step 2: Interview Stages Section */}
			<Panel
				title={t("interviewSetup.step2.title")}
				validationState={validateStagesSection(values.stages, values.sessions, values.useSharedDates)}
				tooltipContent={t("interviewSetup.step2.tooltip")}
			>
				<p className="text-base text-gray-600 mb-6">
					{isAdminPath ? t("interviewSetup.step2.description.admin") : t("interviewSetup.step2.description.student")}
				</p>
				<FieldArray name="sessions">
					{({ push, remove }) => (
						<div className="space-y-4">
							{values.stages?.map((stage, index) => {
								// Skip rendering disabled middle stages based on previous middle stages
								if (stage.interviewStageType === "middle") {
									const middleStages = values.stages.filter((s) => s.interviewStageType === "middle");
									const middleIndex = middleStages.findIndex(
										(s) => s.interviewStageOrderNumber === stage.interviewStageOrderNumber
									);

									// Skip middle-2 if middle-1 is disabled
									if (middleIndex === 1 && !middleStages[0].enabled) {
										return null;
									}
									// Skip middle-3 if either middle-1 or middle-2 is disabled
									if (middleIndex === 2 && (!middleStages[0].enabled || !middleStages[1].enabled)) {
										return null;
									}
								}

								// Render the Add Interview button before the final stage
								const isFinalStage = stage.interviewStageType === "final";
								const middleStages = values.stages.filter((s) => s.interviewStageType === "middle");
								const enabledMiddleStages = middleStages.filter((s) => s.enabled);

								// Show Add Interview button if:
								// - We're at the final stage
								// - At least one middle stage is enabled
								// - We have less than 3 middle stages OR
								// - The last middle stage is disabled (meaning we can replace it)
								const showAddMiddleButton =
									!isEditMode &&
									isFinalStage &&
									enabledMiddleStages.length > 0 &&
									(middleStages.length < 3 || !middleStages[middleStages.length - 1].enabled);

								return (
									<React.Fragment key={stage.interviewStageOrderNumber}>
										{showAddMiddleButton && (
											<Button
												variant="outline"
												onClick={addMiddleStage}
												className="w-full text-primary-900 border-primary-900 bg-white"
												aria-label={t("interviewSetup.step2.addButton.ariaLabel")}
											>
												<PlusIcon className="h-5 w-5 mr-2 text-primary-900" />
												<span className="text-primary-900">{t("interviewSetup.step2.addButton.label")}</span>
											</Button>
										)}
										<div className={`bg-white border border-[#eaecf0] rounded-lg p-6 ${!stage.enabled ? "opacity-50" : ""}`}>
											<div className="flex items-center">
												<Switch
													checked={stage.enabled}
													onCheckedChange={(checked) =>
														handleStageToggle(stage.interviewStageType, stage.interviewStageOrderNumber, checked)
													}
													disabled={isEditMode}
													aria-label={`Toggle ${stage.title} stage`}
												/>
												<h3 className="text-lg font-medium text-gray-900 ml-3">
													{stage.enabled && `${getActiveStageNumber(index)}. `}
													{t(`interviewSetup.step2.stages.${stripTrailingNumber(stage.title)}.title`)}{" "}
													{/* Show the number of the middle interview. The number is in the name so we have to cut that to get the translation and add it back after */}
													{stage.title.includes("Middle Interview") ? getActiveStageNumber(index - 1) : ""}
													{stage.title.match(/\d+$/) ? ` ${stage.title.match(/\d+$/)?.[0]}` : ""}
												</h3>
												<CustomTooltip content={t(`interviewSetup.step2.stageTooltips.${stage.interviewStageType}`)} />
											</div>

											{stage.enabled && !values.useSharedDates && (
												<div className="mt-4">
													<DateRangePicker
														startDate={values.sessions[getSessionIndexForStage(values.stages, index)]?.startDate || null}
														endDate={values.sessions[getSessionIndexForStage(values.stages, index)]?.date || null}
														onStartDateChange={(date) =>
															setFieldValue(
																`sessions[${getSessionIndexForStage(values.stages, index)}].startDate`,
																date
															)
														}
														onEndDateChange={(date) =>
															setFieldValue(`sessions[${getSessionIndexForStage(values.stages, index)}].date`, date)
														}
														disabled={values.isPublished}
														minDate={new Date()}
														maxDate={
															values.sessions[getSessionIndexForStage(values.stages, index) + 1]?.date || undefined
														}
														minEndDate={getPreviousStageEndDate(values.stages, values.sessions, index)}
													/>
												</div>
											)}
										</div>
									</React.Fragment>
								);
							})}
						</div>
					)}
				</FieldArray>
			</Panel>

			{/* Step 3: Schedule Section */}
			<Panel
				title={t("interviewSetup.step3.title")}
				validationState={validateScheduleSection(values.useSharedDates, values.sharedStartDate, values.sharedDueDate)}
			>
				<p className="text-base text-gray-600 mb-6">
					{isAdminPath ? t("interviewSetup.step3.description.admin") : t("interviewSetup.step3.description.student")}
				</p>
				<div className={`${!values.useSharedDates ? "opacity-50" : ""} bg-white border border-[#eaecf0] rounded-lg p-6 mb-6`}>
					<div className="flex items-center">
						{!isEditMode ? (
							<>
								<Switch
									checked={values.useSharedDates}
									onCheckedChange={(checked) => handleToggleSharedDates(checked, setFieldValue)}
									aria-label="Toggle shared schedule for all interview stages"
								/>
								<h3 className="text-lg font-medium text-gray-900 ml-3">{t("interviewSetup.step3.sharedSchedule.title")}</h3>
								<CustomTooltip content={t("interviewSetup.step3.sharedSchedule.tooltip")} />
							</>
						) : (
							<p className="text-sm text-gray-500">{t("interviewSetup.step3.sharedSchedule.editModeMessage")}</p>
						)}
					</div>
					{!isEditMode && values.useSharedDates && (
						<>
							<p className="text-sm text-gray-600 my-4">{t("interviewSetup.step3.sharedSchedule.description")}</p>
							<DateRangePicker
								startDate={values.sharedStartDate}
								endDate={values.sharedDueDate}
								onStartDateChange={(date) => handleSharedDateChange("start", date)}
								onEndDateChange={(date) => handleSharedDateChange("due", date)}
								disabled={values.isPublished}
								minDate={new Date()}
							/>
						</>
					)}
				</div>
				{!values.useSharedDates && <p className="text-sm text-gray-600 italic">{t("interviewSetup.step3.individualDatesMessage")}</p>}
			</Panel>

			{/* Navigation buttons */}
			<div className="block md:flex justify-end mt-8 gap-2">
				<Button
					name="next"
					aria-label={t("interviewSetup.navigation.next.ariaLabel")}
					value={t("interviewSetup.navigation.next.label")}
					variant="default"
					onClick={onNext}
					disabled={!isFormValid()}
				>
					{t("interviewSetup.navigation.next.label")}
				</Button>
			</div>
		</div>
	);
};

// Panel component for grouping related form fields
const Panel: React.FC<{
	title: string;
	children: React.ReactNode;
	validationState?: "valid" | "invalid" | "none";
	tooltipContent?: string;
}> = ({ title, children, validationState = "none", tooltipContent }) => (
	<div
		className={`bg-white border rounded-lg p-6 ${
			validationState === "valid" ? "border-[#00a9af]" : validationState === "invalid" ? "border-incomplete" : "border-[#eaecf0]"
		}`}
	>
		<div className="flex justify-between items-center mb-4">
			<div className="flex items-center gap-2">
				<h3 className="text-lg font-semibold text-gray-800">{title}</h3>
				{tooltipContent && <CustomTooltip content={tooltipContent} />}
			</div>
			{validationState === "valid" && <CheckCircleIcon className="w-6 h-6 text-[#00a9af]" />}
			{validationState === "invalid" && <CheckCircleOutlineIcon className="w-6 h-6 text-incomplete" />}
		</div>
		<div className="space-y-4">{children}</div>
	</div>
);

export default InterviewDates;
