import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useAppContext } from "../../contexts/AppContext";
import { useParams } from "react-router-dom";
import {
	AcademicCapIcon,
	BookOpenIcon,
	CheckBadgeIcon,
	ClipboardDocumentCheckIcon,
	ClipboardDocumentListIcon,
	ExclamationTriangleIcon,
	StarIcon,
	PencilIcon,
	LightBulbIcon,
	FlagIcon,
	BoltIcon,
	ClockIcon,
	UsersIcon,
	RocketLaunchIcon,
	ShareIcon,
	ChatBubbleBottomCenterTextIcon
} from "@heroicons/react/24/solid";
import { ClipboardIcon } from "@heroicons/react/20/solid";
import { CheckCircleIcon, ChevronDownIcon, ChevronUpIcon, ListBulletIcon, SparklesIcon } from "@heroicons/react/24/outline";
import { ScheduleService } from "../../services/ScheduleService";
import { ScheduleView, ActivityType } from "../../types/ScheduleView";
import BackButton from "../Utility/BackButton";
import ReportCard from "../Utility/ReportCard";
import HorizontalTabs from "../Utility/HorizontalTabs";
import { AssignedSessionView } from "../../types/AssignedSessionView";
import { AssignedSessionService } from "../../services/AssignedSessionService";
import AssignedSessionsTable from "./AssignedSessionsTable";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import { Button } from "../../components/shadcn-ui/Button";
import { Input } from "../../components/shadcn-ui/Input";
import { Label } from "../../components/shadcn-ui/Label";
import { Popover, PopoverTrigger, PopoverContent } from "../../components/shadcn-ui/Popover";
import { AssignmentService } from "../../services/AssignmentService";
import { AdminAssignmentView } from "@/src/types/AdminAssignmentView";
import { Badge } from "../shadcn-ui/Badge";
import { Card, CardContent, CardHeader, CardTitle } from "../shadcn-ui/Card";
// import { ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent } from "../shadcn-ui/Chart";
// import { Label as RechartsLabel, Pie, PieChart } from "recharts";
import TokenManager from "../../services/TokenManager";
import { NavigateService } from "../../services/NavigateService";
import { AssignedSessionStatus } from "../../types/AssignedSessionStatus";
import { ReportScoreDataPoint } from "../Utility/ReportCard";
import { SessionView } from "../../types/SessionView";
import { SessionStats, SessionService } from "../../services/SessionService";
import StudentDetailsTable from "./StudentDetailsTable";
import CustomTooltip from "../Utility/CustomTooltip";
import Spinner from "../Utility/Spinner";
import ScoreTimeline, { TimelineSegment } from "./ScoreTimeline";
import { ExperienceType } from "../../schemas/ExperienceType";
import { TabData } from "../Utility/HorizontalTabs";
import { SessionType } from "../../schemas/SessionType";
import { getSessionType } from "../../utils/getSessionType";
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from "../../components/shadcn-ui/Accordion";
import { getQuestionScoreColor } from "../../utils/getQuestionScoreColor";
//import { renderExpectations, renderCompetencyProgress, renderNoteworthyComments } from "../Utility/reportUtility";
import { RenderNoteworthyComments } from "../Utility/reportUtility";
import CareerPathSummary from "../CareerPath/CareerPathSummary";
import { Checkbox } from "../shadcn-ui/Checkbox";
import { useTranslation } from "../Utility/UseTranslation";

const normalizeCompetencyName = (name: string): string => {
	return name.toLowerCase().replace(/[-\s]/g, "");
};

const navigateToUserReport = (assignmentId: string, userId: string, scheduleId: string) => {
	NavigateService.navToSessionSummary(assignmentId, userId, scheduleId);
};

const RenderFlaggedComments = ({
	assignmentId,
	schedule,
	isEditingCheckbox,
	handleCancelEdit,
	handleSaveChanges
}: {
	assignmentId: string;
	schedule: ScheduleView;
	isEditingCheckbox: boolean;
	handleCancelEdit: () => void;
	handleSaveChanges: () => void;
}) => {
	const { t } = useTranslation("adminAssignmentPage");
	return (
		<>
			{schedule.flaggedComments?.negative && schedule.flaggedComments?.negative.length > 0 && (
				<ReportCard title={t("renderCards.flaggedComments")} icon={<ExclamationTriangleIcon className="h-5 w-5 text-[#16013e]" />}>
					<div className="space-y-4">
						{["high", "medium"].map((priority) => {
							const priorityComments = schedule.flaggedComments?.negative?.filter((comment) => comment.priority === priority);
							if (priorityComments && priorityComments.length > 0) {
								return (
									<div key={priority} className={`${priority === "high" ? "bg-red-50" : "bg-yellow-50"} p-3 rounded-md`}>
										<h3 className={`font-semibold mb-2 ${priority === "high" ? "text-red-700" : "text-yellow-700"}`}>
											{priority === "medium" ? t("priority.low") : t(`priority.${priority}`)}
										</h3>
										{priorityComments.map((comment, index) => (
											<p key={index} className="text-gray-700 mb-2 last:mb-0 flex items-center">
												<span className="text-sm text-gray-600 mr-2">{"•"}</span>
												{comment.comment}
												{comment.firstName && (
													<span className="text-sm text-gray-600">
														(
														<button
															className="text-[#00a9af] hover:underline"
															onClick={() => navigateToUserReport(assignmentId!, comment.userId, schedule.scheduleId)}
															aria-label={`View report for ${comment.firstName} ${comment.lastName || ""}`}
														>
															{comment.firstName} {comment.lastName || ""}
														</button>
														)
													</span>
												)}
											</p>
										))}
									</div>
								);
							}
							return null;
						})}
						{isEditingCheckbox && (
							<div className="mt-4 flex justify-end items-end gap-2">
								<p className="text-sm text-gray-400">Indicate which comments have been reviewed / resolved.</p>
								<Button variant="outline" onClick={handleCancelEdit}>
									Cancel
								</Button>
								<Button onClick={handleSaveChanges}>Save Changes</Button>
							</div>
						)}
					</div>
				</ReportCard>
			)}
		</>
	);
};

const RenderGoalStatusChart = ({ schedule }: { schedule: ScheduleView }) => {
	const { t } = useTranslation("adminAssignmentPage");
	if (!schedule || !schedule.goalProgress || !schedule.goalProgress.goals) return null;

	const { goals } = schedule.goalProgress;
	const totalGoals = goals.total_count;
	const completedPercentage = totalGoals > 0 ? Math.round((goals.yes_count / totalGoals) * 100) : 0;

	return (
		<Card>
			<CardHeader className="flex flex-row items-center space-y-0 space-x-2 pb-2">
				<FlagIcon className="h-5 w-5 text-[#16013e]" />
				<CardTitle className="text-sm font-medium text-gray-500 flex items-center">
					{t("renderCards.goalCompletionRate")}
					<CustomTooltip content={t("renderCards.tooltips.goalCompletionRate")} />
				</CardTitle>
			</CardHeader>
			<CardContent>
				<div className="text-2xl font-bold text-[#16013e]">{completedPercentage.toFixed(2)}%</div>
			</CardContent>
		</Card>
	);
};

const RenderMetrics = ({
	schedule,
	assignment,
	assignedSessions,
	scheduleSessionStats
}: {
	schedule: ScheduleView;
	assignment: AdminAssignmentView;
	assignedSessions: AssignedSessionView[];
	scheduleSessionStats: SessionStats;
}) => {
	const { t } = useTranslation("adminAssignmentPage");
	if (!schedule || !assignedSessions) return null;

	const enrolledStudents = assignedSessions.length;
	const submittedSessions = assignedSessions.filter(
		(session) => session.status === AssignedSessionStatus.SUBMITTED || session.status === AssignedSessionStatus.SUBMITTED_LATE
	).length;
	const completionRate = enrolledStudents > 0 ? (submittedSessions / enrolledStudents) * 100 : 0;

	// Calculate average grade for Frontline numeric assignments
	let averageGrade = null;
	if (assignment?.gradingType === "numeric" && assignment?.experienceType === ExperienceType.FRONTLINE) {
		const submittedSessionsWithGrades = assignedSessions.filter(
			(session) =>
				(session.status === AssignedSessionStatus.SUBMITTED || session.status === AssignedSessionStatus.SUBMITTED_LATE) &&
				session.frontlineAssessment &&
				session.gradingWeights
		);

		if (submittedSessionsWithGrades.length > 0) {
			const totalGrade = submittedSessionsWithGrades.reduce((sum, session) => {
				const scores = session.frontlineAssessment!.score;
				const weights = session.gradingWeights!;
				const sessionGrade = scores.professionalism * (weights.professionalism / 100) + scores.checklist * (weights.checklist / 100);
				return sum + sessionGrade;
			}, 0);
			averageGrade = totalGrade / submittedSessionsWithGrades.length;
		}
	}

	return (
		<div className="space-y-6 my-8">
			<div className="grid grid-cols-1 md:grid-cols-3 gap-6">
				{averageGrade !== null && (
					<Card>
						<CardHeader className="flex flex-row items-center space-y-0 space-x-2 pb-2">
							<AcademicCapIcon className="h-5 w-5 text-[#16013e]" />
							<CardTitle className="text-sm font-medium text-gray-500 flex items-center">
								{t("renderCards.metrics.averageGrade")}
								<CustomTooltip content={t("renderCards.tooltips.averageGrade")} />
							</CardTitle>
						</CardHeader>
						<CardContent>
							<div className="text-2xl font-bold text-[#16013e]">{averageGrade.toFixed(1)}%</div>
						</CardContent>
					</Card>
				)}
				<Card>
					<CardHeader className="flex flex-row items-center space-y-0 space-x-2 pb-2">
						<CheckBadgeIcon className="h-5 w-5 text-[#16013e]" />
						<CardTitle className="text-sm font-medium text-gray-500 flex items-center">
							{t("renderCards.metrics.sessionCompletionRate")}
							<CustomTooltip content={t("renderCards.tooltips.sessionCompletionRate")} />
						</CardTitle>
					</CardHeader>
					<CardContent>
						<div className="text-2xl font-bold text-[#16013e]">{completionRate.toFixed(2)}%</div>
					</CardContent>
				</Card>
				<Card>
					<CardHeader className="flex flex-row items-center space-y-0 space-x-2 pb-2">
						<ClockIcon className="h-5 w-5 text-[#16013e]" />
						<CardTitle className="text-sm font-medium text-gray-500 flex items-center">
							{t("renderCards.metrics.totalUsage")}
							<CustomTooltip content={t("renderCards.tooltips.totalUsage")} />
						</CardTitle>
					</CardHeader>
					<CardContent>
						<div className="text-2xl font-bold text-[#16013e]">{scheduleSessionStats.totalDuration}</div>
					</CardContent>
				</Card>
				<RenderGoalStatusChart schedule={schedule} />
			</div>
		</div>
	);
};

const RenderQuestionListSummary = ({
	schedule,
	expandedTables,
	handleToggleExpandedTables,
	userSessions,
	generateTimelineData
}: {
	schedule: ScheduleView;
	expandedTables: Record<string, boolean>;
	handleToggleExpandedTables: (key: string) => void;
	userSessions: Record<string, SessionView>;
	generateTimelineData: (type: string, competencyName?: string, questionId?: string) => TimelineSegment[];
}) => {
	const { t } = useTranslation("adminAssignmentPage");
	if (!schedule || !schedule.interviewAssessment?.questionListSummary) return null;

	// Filter out questions with zero score and empty feedback
	const validQuestions = schedule.interviewAssessment.questionListSummary.filter(
		(question) => !(question.averageScore === 0 && (!question.feedback || question.feedback.trim() === ""))
	);

	if (validQuestions.length === 0) return null;

	return (
		<ReportCard title="Question List Summary" icon={<ChatBubbleBottomCenterTextIcon className="h-5 w-5 text-[#16013e]" />}>
			<Accordion type="single" collapsible className="w-full space-y-6">
				{validQuestions.map((question, index) => {
					const colors = getQuestionScoreColor(question.averageScore || 0);
					const isExpanded = expandedTables[`question-${question.id}`] || false;

					// Calculate distribution for this question
					const segments = generateTimelineData("question", undefined, question.id);
					const totalCount = segments.length;
					const highCount = segments.filter((s) => s.variant === "high").length;
					const mediumCount = segments.filter((s) => s.variant === "medium").length;
					const lowCount = segments.filter((s) => s.variant === "low").length;

					return (
						<AccordionItem
							key={index}
							value={`question-${index}`}
							className="group border border-[#eaecf0] rounded-lg overflow-hidden hover:bg-[#F6F3ED] hover:border-[#00a9af] [&_[data-state=open]]:bg-[#F6F3ED]"
						>
							<AccordionTrigger className="flex w-full items-center justify-between px-4 py-3 [&>*]:no-underline hover:[&>*]:no-underline">
								<div className="flex-1 min-w-0">
									<div className="flex items-center justify-between pr-4">
										<div className="flex-1 min-w-0 pr-8">
											<span className="font-bold text-gray-900 text-left block">
												{t(`renderCards.questionList.questionTypes.${question.type}`)}
											</span>
											<p className="text-sm text-gray-600 mt-1 truncate text-left group-[[data-state=open]]:hidden">
												{question.feedback}
											</p>
										</div>
										<div className="flex-shrink-0 w-[160px]">
											{question.type !== "Opening" && question.type !== "Closing" && question.averageScore && (
												<div className="flex flex-col items-end">
													<span className="text-sm font-medium mb-2" style={{ color: colors.text }}>
														{t("renderCards.questionList.averageScore")}: {question.averageScore}
													</span>
													<div className="w-full h-2 bg-[#F6F3EC] rounded-full overflow-hidden">
														<div
															className="h-full transition-all duration-300 ease-in-out rounded-full"
															style={{
																width: `${question.averageScore}%`,
																backgroundColor: colors.text,
																opacity: 0.9
															}}
														/>
													</div>
												</div>
											)}
										</div>
									</div>
								</div>
							</AccordionTrigger>
							<AccordionContent className="p-4 bg-white">
								<p className="text-gray-600 text-base mb-6">{question.feedback}</p>

								{question.type !== "Opening" && question.type !== "Closing" && (
									<>
										<div className="mt-4 flex flex-col sm:flex-row sm:items-center sm:space-x-2">
											<span className="font-semibold text-gray-800 text-base">
												{t("renderCards.questionList.distribution")}:{" "}
											</span>
											<div className="sm:mt-0 space-y-2 sm:space-y-0 space-x-2">
												<div className="inline-block px-2 py-1 rounded-md border text-sm font-medium bg-[#e6f7f7] text-[#00a9af] border-[#b3e6e8]">
													{t("renderCards.questionList.high")}: {((highCount / totalCount) * 100).toFixed(1)}%
												</div>
												<div className="inline-block px-2 py-1 rounded-md border text-sm font-medium bg-yellow-100 text-yellow-600 border-yellow-300">
													{t("renderCards.questionList.medium")}: {((mediumCount / totalCount) * 100).toFixed(1)}%
												</div>
												<div className="inline-block px-2 py-1 rounded-md border text-sm font-medium bg-[#FEF3F2] text-[#B42318] border-[#FEE4E2]">
													{t("renderCards.questionList.low")}: {((lowCount / totalCount) * 100).toFixed(1)}%
												</div>
											</div>
										</div>

										<div className="mb-4 mt-4">
											<ScoreTimeline segments={generateTimelineData("question", undefined, question.id)} />
										</div>

										<Button
											variant="outline"
											size="sm"
											onClick={() => handleToggleExpandedTables(`question-${question.id}`)}
											className={`flex items-center space-x-2 mt-8 ${isExpanded ? "mb-4" : ""}`}
											aria-expanded={isExpanded}
											aria-label={`${isExpanded ? "Hide" : "Show"} sources for question ${question.id}`}
										>
											<UsersIcon className="h-4 w-4" />
											<span>
												{isExpanded ? t("renderCards.questionList.hideSources") : t("renderCards.questionList.showSources")}
											</span>
											{isExpanded ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />}
										</Button>

										{isExpanded && (
											<StudentDetailsTable
												sessions={Object.values(userSessions).filter((session) => {
													const questionSummary = session.questionListSummary?.find((q) => q.id === question.id);
													const score = questionSummary
														? (questionSummary.assessment.score.grade_1 + questionSummary.assessment.score.grade_2) / 2
														: 0;
													return (
														score !== 0 ||
														(questionSummary?.assessment?.feedback && questionSummary.assessment.feedback.trim() !== "")
													);
												})}
												type="question"
												questionId={question.id}
												onNavigateToReport={navigateToUserReport}
											/>
										)}
									</>
								)}
							</AccordionContent>
						</AccordionItem>
					);
				})}
			</Accordion>
		</ReportCard>
	);
};

const RenderInterviewAssessment = ({
	schedule,
	userSessions,
	handleToggleExpandedTables,
	expandedTables
}: {
	schedule: ScheduleView;
	userSessions: Record<string, SessionView>;
	expandedTables: Record<string, boolean>;
	handleToggleExpandedTables: (key: string) => void;
}) => {
	const { t } = useTranslation("adminAssignmentPage");

	if (!schedule || !schedule.interviewAssessment) return null;

	const isExpanded = expandedTables["interviewAssessment"];

	return (
		<ReportCard
			title={t("cards.strengthsAndImprovements")}
			aria-label="Strengths and Improvements"
			icon={<RocketLaunchIcon className="h-5 w-5 text-[#16013e]" />}
		>
			<h3 className="font-semibold text-gray-800 mb-2">{t("cards.whatWentWell")}</h3>
			<p className="text-gray-600 mb-4">{schedule.interviewAssessment.strengths}</p>
			<h3 className="font-semibold text-gray-800 mb-2">{t("cards.areasToImprove")}</h3>
			<p className="text-gray-600 mb-4">{schedule.interviewAssessment.improvements}</p>
			<Button
				variant="outline"
				size="sm"
				onClick={() => handleToggleExpandedTables("interviewAssessment")}
				className={`flex items-center space-x-2 mt-8 ${isExpanded ? "mb-4" : ""}`}
				aria-expanded={isExpanded}
				aria-label={`${isExpanded ? "Hide" : "Show"} interview assessment details`}
			>
				<UsersIcon className="h-4 w-4" />
				<span>{isExpanded ? t("cards.hideSources") : t("cards.showSources")}</span>
				{isExpanded ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />}
			</Button>
			{isExpanded && (
				<StudentDetailsTable sessions={Object.values(userSessions)} type="interviewAssessment" onNavigateToReport={navigateToUserReport} />
			)}
		</ReportCard>
	);
};

const RenderFrontlineEvaluation = ({
	schedule,
	userSessions,
	isLoadingUserSessions,
	handleToggleExpandedTables,
	expandedTables,
	type
}: {
	schedule: ScheduleView;
	userSessions: Record<string, SessionView>;
	isLoadingUserSessions: boolean;
	expandedTables: Record<string, boolean>;
	type: "keyPoints" | "infoCollection";
	handleToggleExpandedTables: (key: string) => void;
}) => {
	const { t } = useTranslation("adminAssignmentPage");
	if (!schedule || !schedule.frontlineAssessment) return null;

	const evaluationData =
		type === "keyPoints" ? schedule.frontlineAssessment.keyPointsEvaluation : schedule.frontlineAssessment.infoCollectionEvaluation;

	if (!evaluationData) return null;

	const validItems = evaluationData.filter((item) => item.aggregatedSummary && item.aggregatedSummary.trim() !== "");

	if (validItems.length === 0) return null;

	const config = {
		keyPoints: {
			title: t("cards.frontlineEvaluation.communicationPointsChecklist"),
			icon: <ListBulletIcon className="h-5 w-5 text-[#16013e]" />,
			itemKey: "point"
		},
		infoCollection: {
			title: t("cards.frontlineEvaluation.informationCollectionChecklist"),
			icon: <ClipboardDocumentListIcon className="h-5 w-5 text-[#16013e]" />,
			itemKey: "info"
		}
	}[type];

	return (
		<ReportCard title={config.title} icon={config.icon}>
			<Accordion type="single" collapsible className="w-full space-y-6">
				{validItems.map((item, index) => {
					const successPercentage = item.successPercentage || 0;
					const colors = getQuestionScoreColor(successPercentage);
					const content = item[config.itemKey as keyof typeof item] as string;
					const tableKey = `${type}-${index}`;
					const isTableExpanded = expandedTables[tableKey] || false;

					return (
						<AccordionItem
							key={index}
							value={`${type}-${index}`}
							className="group border border-[#eaecf0] rounded-lg overflow-hidden hover:bg-[#F6F3ED] hover:border-[#00a9af] [&_[data-state=open]]:bg-[#F6F3ED]"
						>
							<AccordionTrigger className="flex w-full items-center justify-between px-4 py-3 [&>*]:no-underline hover:[&>*]:no-underline">
								<div className="flex-1 min-w-0">
									<div className="flex items-center justify-between pr-4">
										<div className="flex-1 min-w-0 pr-8">
											<span className="font-bold text-gray-900 text-left block">{content}</span>
											<p className="text-sm text-gray-600 mt-1 truncate text-left group-[[data-state=open]]:hidden">
												{item.aggregatedSummary}
											</p>
										</div>
										<div className="flex-shrink-0 w-[160px]">
											{typeof item.successPercentage === "number" && (
												<div className="flex flex-col items-end">
													<span className="text-sm font-medium mb-2" style={{ color: colors.text }}>
														{t("cards.frontlineEvaluation.successRate")}: {successPercentage.toFixed(1)}%
													</span>
													<div className="w-full h-2 bg-[#F6F3EC] rounded-full overflow-hidden">
														<div
															className="h-full transition-all duration-300 ease-in-out rounded-full"
															style={{
																width: `${successPercentage}%`,
																backgroundColor: colors.text,
																opacity: 0.9
															}}
														/>
													</div>
												</div>
											)}
										</div>
									</div>
								</div>
							</AccordionTrigger>
							<AccordionContent className="p-4 bg-white">
								<p className="text-gray-600 text-base mb-6">{item.aggregatedSummary}</p>

								{userSessions && Object.keys(userSessions).length > 0 && (
									<div className="mt-6">
										{isLoadingUserSessions || Object.keys(userSessions).length === 0 ? (
											<div className="flex items-center space-x-2">
												<Spinner className="h-4 w-4" />
												<span className="text-sm text-gray-500">{t("cards.frontlineEvaluation.loadingStudentDetails")}</span>
											</div>
										) : (
											<>
												<Button
													variant="outline"
													size="sm"
													onClick={() => handleToggleExpandedTables(tableKey)}
													className={`flex items-center space-x-2 mt-8 ${isTableExpanded ? "mb-4" : ""}`}
													aria-expanded={isTableExpanded}
													aria-label={`${isTableExpanded ? "Hide" : "Show"} sources for ${content}`}
												>
													<UsersIcon className="h-4 w-4" />
													<span>
														{isTableExpanded
															? t("cards.frontlineEvaluation.hideSources")
															: t("cards.frontlineEvaluation.showSources")}
													</span>
													{isTableExpanded ? (
														<ChevronUpIcon className="h-4 w-4" />
													) : (
														<ChevronDownIcon className="h-4 w-4" />
													)}
												</Button>
												{isTableExpanded && (
													<StudentDetailsTable
														sessions={Object.values(userSessions)
															// First, filter to only include sessions that have this specific point
															.filter((session) => {
																if (type === "keyPoints") {
																	const evaluation = session.frontlineAssessment?.key_points_evaluation;
																	if (!evaluation) return false;
																	// Check if this session has an evaluation matching our point
																	return evaluation.some((p) => p.point === content);
																} else {
																	const evaluation = session.frontlineAssessment?.info_collection_evaluation;
																	if (!evaluation) return false;
																	// Check if this session has an evaluation matching our point
																	return evaluation.some((p) => p.point === content);
																}
															})
															// Then, for each matching session, create a new session with only this point
															.map((session) => {
																// Create a proper clone of the session that maintains the SessionView prototype
																// This ensures methods like getPlGroupIdUrl are preserved
																const newSession = Object.create(
																	Object.getPrototypeOf(session),
																	Object.getOwnPropertyDescriptors(session)
																);

																if (type === "keyPoints") {
																	// Find the specific point we want and create a new frontlineAssessment
																	// with only this point in the evaluation array
																	const point = session.frontlineAssessment!.key_points_evaluation!.find(
																		(p) => p.point === content
																	);
																	newSession.frontlineAssessment = {
																		...session.frontlineAssessment!,
																		key_points_evaluation: [point!]
																	};
																} else {
																	// Same logic for info collection points
																	const point = session.frontlineAssessment!.info_collection_evaluation!.find(
																		(p) => p.point === content
																	);
																	newSession.frontlineAssessment = {
																		...session.frontlineAssessment!,
																		info_collection_evaluation: [point!]
																	};
																}
																return newSession;
															})}
														type={type === "keyPoints" ? "keyPoints" : "infoCollection"}
														onNavigateToReport={navigateToUserReport}
													/>
												)}
											</>
										)}
									</div>
								)}
							</AccordionContent>
						</AccordionItem>
					);
				})}
			</Accordion>
		</ReportCard>
	);
};

const RenderFrontlineAssessment = ({
	schedule,
	userSessions,
	handleToggleExpandedTables,
	expandedTables,
	isLoadingUserSessions
}: {
	schedule: ScheduleView;
	userSessions: Record<string, SessionView>;
	isLoadingUserSessions: boolean;
	expandedTables: Record<string, boolean>;
	handleToggleExpandedTables: (key: string) => void;
}) => {
	const { t } = useTranslation("adminAssignmentPage");
	if (!schedule || !schedule.frontlineAssessment) return null;

	const isExpanded = expandedTables["frontlineAssessment"] || false;

	return (
		<>
			<ReportCard title={t("cards.strengthsAndImprovements")} icon={<RocketLaunchIcon className="h-5 w-5 text-[#16013e]" />}>
				<h3 className="font-semibold text-gray-800 mb-2">{t("cards.whatWentWell")}</h3>
				<p className="text-gray-600 mb-4">{schedule.frontlineAssessment.strengths}</p>
				<h3 className="font-semibold text-gray-800 mb-2">{t("cards.areasToImprove")}</h3>
				<p className="text-gray-600 mb-4">{schedule.frontlineAssessment.improvements}</p>
				<Button
					variant="outline"
					size="sm"
					onClick={() => handleToggleExpandedTables("frontlineAssessment")}
					className={`flex items-center space-x-2 mt-8 ${isExpanded ? "mb-4" : ""}`}
					aria-expanded={isExpanded}
					aria-label={`${isExpanded ? t("cards.hideSources") : t("cards.showSources")} frontline assessment details`}
				>
					<UsersIcon className="h-4 w-4" />
					<span>{isExpanded ? t("cards.hideSources") : t("cards.showSources")}</span>
					{isExpanded ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />}
				</Button>
				{isExpanded && (
					<StudentDetailsTable
						sessions={Object.values(userSessions)}
						type="frontlineAssessment"
						onNavigateToReport={navigateToUserReport}
					/>
				)}
			</ReportCard>
			<RenderFrontlineEvaluation
				schedule={schedule}
				userSessions={userSessions}
				isLoadingUserSessions={isLoadingUserSessions}
				handleToggleExpandedTables={handleToggleExpandedTables}
				expandedTables={expandedTables}
				type="keyPoints"
			/>
			<RenderFrontlineEvaluation
				schedule={schedule}
				userSessions={userSessions}
				isLoadingUserSessions={isLoadingUserSessions}
				handleToggleExpandedTables={handleToggleExpandedTables}
				expandedTables={expandedTables}
				type="infoCollection"
			/>
		</>
	);
};

const RenderOverview = ({
	schedule,
	userSessions,
	handleToggleExpandedTables,
	expandedTables
}: {
	schedule: ScheduleView;
	userSessions: Record<string, SessionView>;
	handleToggleExpandedTables: (key: string) => void;
	expandedTables: Record<string, boolean>;
}) => {
	const { t } = useTranslation("adminAssignmentPage");
	if (!schedule || !schedule.overview || schedule.overview === "") return null;

	const isExpanded = expandedTables["overview"] || false;

	return (
		<ReportCard title={t("cards.overview")} icon={<BookOpenIcon className="h-5 w-5 text-[#16013e]" />}>
			<p>{schedule.overview}</p>
			<Button
				variant="outline"
				size="sm"
				onClick={() => handleToggleExpandedTables("overview")}
				className={`flex items-center space-x-2 mt-8 ${isExpanded ? "mb-4" : ""}`}
				aria-expanded={isExpanded}
				aria-label={`${isExpanded ? t("cards.hideSources") : t("cards.showSources")} overview details`}
			>
				<UsersIcon className="h-4 w-4" />
				<span>{isExpanded ? t("cards.hideSources") : t("cards.showSources")}</span>
				{isExpanded ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />}
			</Button>
			{isExpanded && <StudentDetailsTable sessions={Object.values(userSessions)} type="overview" onNavigateToReport={navigateToUserReport} />}
		</ReportCard>
	);
};

const RenderGoalProgress = ({
	schedule,
	userSessions,
	handleToggleExpandedTables,
	expandedTables,
	generateTimelineData
}: {
	schedule: ScheduleView;
	userSessions: Record<string, SessionView>;
	handleToggleExpandedTables: (key: string) => void;
	expandedTables: Record<string, boolean>;
	generateTimelineData: (type: string, competencyName?: string, questionId?: string) => TimelineSegment[];
}) => {
	const { t } = useTranslation("adminAssignmentPage");
	if (!schedule || !schedule.goalProgress || !schedule.goalProgress.goals) return null;

	const isExpanded = expandedTables["goalProgress"] || false;

	// Aggregate goal data
	const totalGoals = schedule.goalProgress.goals.total_count;
	const yesCount = schedule.goalProgress.goals.yes_count;
	const partialCount = schedule.goalProgress.goals.partial_count;
	const noCount = schedule.goalProgress.goals.no_count;

	return (
		<ReportCard title={t("assignedSessionsTable.goalProgress")} icon={<LightBulbIcon className="h-5 w-5 text-[#16013e]" />}>
			<p className="text-gray-600 mb-4">{schedule.goalProgress.summary}</p>
			<div className="mt-4 flex flex-col sm:flex-row sm:items-center sm:space-x-2">
				<span className="font-semibold text-gray-800">{t("cards.progress")}: </span>
				<div className="sm:mt-0 space-y-2 sm:space-y-0 space-x-2">
					<div className="inline-block px-2 py-1 rounded-md border text-sm font-medium bg-[#e6f7f7] text-[#00a9af] border-[#b3e6e8]">
						{t("assignedSessionsTable.goalStatuses.Yes")}: {((yesCount / totalGoals) * 100).toFixed(1)}%
					</div>
					<div className="inline-block px-2 py-1 rounded-md border text-sm font-medium bg-yellow-100 text-yellow-600 border-yellow-300">
						{t("assignedSessionsTable.goalStatuses.Partially")}: {((partialCount / totalGoals) * 100).toFixed(1)}%
					</div>
					<div className="inline-block px-2 py-1 rounded-md border text-sm font-medium bg-[#FEF3F2] text-[#B42318] border-[#FEE4E2]">
						{t("assignedSessionsTable.goalStatuses.No")}: {((noCount / totalGoals) * 100).toFixed(1)}%
					</div>
				</div>
			</div>
			<div className="mb-4 mt-4">
				<ScoreTimeline segments={generateTimelineData("goalProgress")} />
			</div>
			<Button
				variant="outline"
				size="sm"
				onClick={() => handleToggleExpandedTables("goalProgress")}
				className={`flex items-center space-x-2 mt-8 ${isExpanded ? "mb-4" : ""}`}
				aria-expanded={isExpanded}
				aria-label={`${isExpanded ? t("cards.hideSources") : t("cards.showSources")} goal progress details`}
			>
				<UsersIcon className="h-4 w-4" />
				<span>{isExpanded ? t("cards.hideSources") : t("cards.showSources")}</span>
				{isExpanded ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />}
			</Button>
			{isExpanded && (
				<StudentDetailsTable sessions={Object.values(userSessions)} type="goalProgress" onNavigateToReport={navigateToUserReport} />
			)}
		</ReportCard>
	);
};

const getAverageScore = (field: "careerPreparedness" | "careerHappiness" | "careerSupport", userSessions: Record<string, SessionView>) => {
	if (!userSessions || Object.keys(userSessions).length === 0) {
		return 0;
	}

	let totalScore = 0;
	let count = 0;

	Object.values(userSessions).forEach((session) => {
		const score = session.careerPathAssessment?.[field]?.score;
		if (typeof score === "number") {
			totalScore += score;
			count += 1;
		}
	});

	return count > 0 ? totalScore / count : 0;
};

const RenderCareerPreparedness = ({
	schedule,
	userSessions,
	handleToggleExpandedTables,
	expandedTables,
	generateTimelineData
}: {
	schedule: ScheduleView;
	userSessions: Record<string, SessionView>;
	handleToggleExpandedTables: (key: string) => void;
	expandedTables: Record<string, boolean>;
	generateTimelineData: (type: string, competencyName?: string, questionId?: string) => TimelineSegment[];
}) => {
	const { t } = useTranslation("adminAssignmentPage");
	if (!schedule || !schedule.careerPathAssessment?.careerPreparedness) return null;

	const isExpanded = expandedTables["careerPreparedness"] || false;

	return (
		<ReportCard
			averageScore={getAverageScore("careerPreparedness", userSessions)}
			title={t("assignedSessionsTable.careerReadiness")}
			icon={<AcademicCapIcon className="h-5 w-5 text-[#16013e]" />}
		>
			<p className="text-gray-600">{schedule.careerPathAssessment.careerPreparedness.summary}</p>
			<Button
				variant="outline"
				size="sm"
				onClick={() => handleToggleExpandedTables("careerPreparedness")}
				className={`flex items-center space-x-2 mt-8 ${isExpanded ? "mb-4" : ""}`}
				aria-expanded={isExpanded}
				aria-label={`${isExpanded ? t("cards.hideSources") : t("cards.showSources")} career preparedness details`}
			>
				<UsersIcon className="h-4 w-4" />
				<span>{isExpanded ? t("cards.hideSources") : t("cards.showSources")}</span>
				{isExpanded ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />}
			</Button>
			{isExpanded && (
				<>
					<StudentDetailsTable sessions={Object.values(userSessions)} type="careerPreparedness" onNavigateToReport={navigateToUserReport} />
				</>
			)}
		</ReportCard>
	);
};

const RenderCareerSupport = ({
	schedule,
	userSessions,
	handleToggleExpandedTables,
	expandedTables,
	generateTimelineData
}: {
	schedule: ScheduleView;
	userSessions: Record<string, SessionView>;
	handleToggleExpandedTables: (key: string) => void;
	expandedTables: Record<string, boolean>;
	generateTimelineData: (type: string, competencyName?: string, questionId?: string) => TimelineSegment[];
}) => {
	const { t } = useTranslation("adminAssignmentPage");
	if (!schedule || !schedule.careerPathAssessment?.careerSupport) return null;

	const isExpanded = expandedTables["careerSupport"] || false;

	return (
		<ReportCard
			averageScore={getAverageScore("careerSupport", userSessions)}
			title={t("assignedSessionsTable.careerSupport")}
			icon={<AcademicCapIcon className="h-5 w-5 text-[#16013e]" />}
		>
			<p className="text-gray-600">{schedule.careerPathAssessment.careerSupport.summary}</p>
			<Button
				variant="outline"
				size="sm"
				onClick={() => handleToggleExpandedTables("careerSupport")}
				className={`flex items-center space-x-2 mt-8 ${isExpanded ? "mb-4" : ""}`}
				aria-expanded={isExpanded}
				aria-label={`${isExpanded ? t("cards.hideSources") : t("cards.showSources")} career support details`}
			>
				<UsersIcon className="h-4 w-4" />
				<span>{isExpanded ? t("cards.hideSources") : t("cards.showSources")}</span>
				{isExpanded ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />}
			</Button>
			{isExpanded && (
				<StudentDetailsTable sessions={Object.values(userSessions)} type="careerSupport" onNavigateToReport={navigateToUserReport} />
			)}
		</ReportCard>
	);
};

const RenderCareerHappiness = ({
	schedule,
	userSessions,
	handleToggleExpandedTables,
	expandedTables,
	generateTimelineData
}: {
	schedule: ScheduleView;
	userSessions: Record<string, SessionView>;
	handleToggleExpandedTables: (key: string) => void;
	expandedTables: Record<string, boolean>;
	generateTimelineData: (type: string, competencyName?: string, questionId?: string) => TimelineSegment[];
}) => {
	const { t } = useTranslation("adminAssignmentPage");
	if (!schedule || !schedule.careerPathAssessment?.careerHappiness) return null;

	const isExpanded = expandedTables["careerHappiness"] || false;

	return (
		<ReportCard
			averageScore={getAverageScore("careerHappiness", userSessions)}
			title={t("assignedSessionsTable.careerHappiness")}
			icon={<AcademicCapIcon className="h-5 w-5 text-[#16013e]" />}
		>
			<p className="text-gray-600">{schedule.careerPathAssessment.careerHappiness.summary}</p>
			<Button
				variant="outline"
				size="sm"
				onClick={() => handleToggleExpandedTables("careerHappiness")}
				className={`flex items-center space-x-2 mt-8 ${isExpanded ? "mb-4" : ""}`}
				aria-expanded={isExpanded}
				aria-label={`${isExpanded ? t("cards.hideSources") : t("cards.showSources")} career happiness details`}
			>
				<UsersIcon className="h-4 w-4" />
				<span>{isExpanded ? t("cards.hideSources") : t("cards.showSources")}</span>
				{isExpanded ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />}
			</Button>
			{isExpanded && (
				<StudentDetailsTable sessions={Object.values(userSessions)} type="careerHappiness" onNavigateToReport={navigateToUserReport} />
			)}
		</ReportCard>
	);
};

const RenderCareerPathSuccessfulChoice = ({ schedule, userSessions }: { schedule: ScheduleView; userSessions: Record<string, SessionView> }) => {
	if (!schedule || !schedule.careerPathSuccessfulChoice) return null;

	return (
		<CareerPathSummary
			overview={schedule.careerPathSuccessfulChoice.overview}
			successfulChoice={schedule.careerPathSuccessfulChoice.successfulChoice}
			userSessions={userSessions}
			onNavigateToReport={navigateToUserReport}
		/>
	);
};

const RenderStudentSatisfaction = ({
	schedule,
	userSessions,
	handleToggleExpandedTables,
	expandedTables,
	generateTimelineData,
	satisfactionData,
	satisfactionDetails,
	isLoadingUserSessions
}: {
	schedule: ScheduleView;
	userSessions: Record<string, SessionView>;
	handleToggleExpandedTables: (key: string) => void;
	expandedTables: Record<string, boolean>;
	generateTimelineData: (type: string, competencyName?: string, questionId?: string) => TimelineSegment[];
	satisfactionData: number[];
	satisfactionDetails: ReportScoreDataPoint[];
	isLoadingUserSessions: boolean;
}) => {
	const { t } = useTranslation("adminAssignmentPage");
	if (!schedule || !schedule.studentSatisfaction || !schedule.studentSatisfaction.average_score) return null;

	const isExpanded = expandedTables["satisfaction"] || false;

	// Calculate percentages
	const satisfactionSegments = generateTimelineData("satisfaction");
	const totalCount = satisfactionSegments.length;
	const highCount = satisfactionSegments.filter((s) => s.variant === "high").length;
	const mediumCount = satisfactionSegments.filter((s) => s.variant === "medium").length;
	const lowCount = satisfactionSegments.filter((s) => s.variant === "low").length;

	return (
		<ReportCard
			title={t("assignedSessionsTable.studentSatisfaction")}
			icon={<StarIcon className="h-5 w-5 text-[#16013e]" />}
			reportScoreData={satisfactionData}
			reportScoreDetails={satisfactionDetails}
			lineColor="#d4d4d8"
			currentScore={schedule.studentSatisfaction.average_score}
			isAdminView={true}
			showDataTable={true}
		>
			<p className="text-gray-600">{schedule.studentSatisfaction.aggregated_summary}</p>
			<div className="mt-6">
				{isLoadingUserSessions || Object.keys(userSessions).length === 0 ? (
					<div className="flex items-center space-x-2">
						<Spinner className="h-4 w-4" />
						<span className="text-sm text-gray-500">{t("cards.frontlineEvaluation.loadingStudentDetails")}</span>
					</div>
				) : (
					<>
						<div className="mt-4 flex flex-col sm:flex-row sm:items-center sm:space-x-2">
							<span className="font-semibold text-gray-800">{t("renderCards.questionList.distribution")}: </span>
							<div className="sm:mt-0 space-y-2 sm:space-y-0 space-x-2">
								<div className="inline-block px-2 py-1 rounded-md border text-sm font-medium bg-[#e6f7f7] text-[#00a9af] border-[#b3e6e8]">
									{t("renderCards.questionList.high")}: {((highCount / totalCount) * 100).toFixed(1)}%
								</div>
								<div className="inline-block px-2 py-1 rounded-md border text-sm font-medium bg-yellow-100 text-yellow-600 border-yellow-300">
									{t("renderCards.questionList.medium")}: {((mediumCount / totalCount) * 100).toFixed(1)}%
								</div>
								<div className="inline-block px-2 py-1 rounded-md border text-sm font-medium bg-[#FEF3F2] text-[#B42318] border-[#FEE4E2]">
									{t("renderCards.questionList.low")}: {((lowCount / totalCount) * 100).toFixed(1)}%
								</div>
							</div>
						</div>
						<div className="mb-4 mt-4">
							<ScoreTimeline segments={satisfactionSegments} />
						</div>
						<Button
							variant="outline"
							size="sm"
							onClick={() => handleToggleExpandedTables("satisfaction")}
							className={`flex items-center space-x-2 mt-8 ${isExpanded ? "mb-4" : ""}`}
							aria-expanded={isExpanded}
							aria-label={`${isExpanded ? t("cards.hideSources") : t("cards.showSources")} student satisfaction details`}
						>
							<UsersIcon className="h-4 w-4" />
							<span>{isExpanded ? t("cards.hideSources") : t("cards.showSources")}</span>
							{isExpanded ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />}
						</Button>
						{isExpanded && (
							<StudentDetailsTable
								sessions={Object.values(userSessions)}
								type="satisfaction"
								onNavigateToReport={navigateToUserReport}
							/>
						)}
					</>
				)}
			</div>
		</ReportCard>
	);
};

const RenderCompetencyProgress = ({
	schedule,
	userSessions,
	handleToggleExpandedTables,
	expandedTables,
	generateTimelineData,
	competencyData,
	competencyDetails,
	isLoadingUserSessions
}: {
	schedule: ScheduleView;
	userSessions: Record<string, SessionView>;
	handleToggleExpandedTables: (key: string) => void;
	expandedTables: Record<string, boolean>;
	generateTimelineData: (type: string, competencyName?: string, questionId?: string) => TimelineSegment[];
	competencyData: { [key: string]: number[] };
	competencyDetails: { [key: string]: ReportScoreDataPoint[] };
	isLoadingUserSessions: boolean;
}) => {
	const { t } = useTranslation("adminAssignmentPage");
	if (!schedule || !schedule.competencyProgress || schedule.competencyProgress.length === 0) return null;

	const toggleTable = (competencyName: string) => {
		handleToggleExpandedTables(competencyName);
	};

	return (
		<div className="my-12">
			{schedule.competencyProgress.map((competency, index) => {
				const normalizedName = normalizeCompetencyName(competency.name);
				const assessments = competencyData[normalizedName] || [];
				const details = competencyDetails[normalizedName] || [];
				const isExpanded = expandedTables[competency.name] || false;

				return (
					<ReportCard
						key={`competency-progress-${index}`}
						title={`${t("assignedSessionsTable.competencyProgress")}: ${competency.name
							.split(" ")
							.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
							.join(" ")}`}
						icon={<BoltIcon className="h-5 w-5 text-[#16013e]" />}
						reportScoreData={assessments}
						reportScoreDetails={details}
						lineColor="#d4d4d8"
						currentScore={competency.average_assessment}
						isAdminView={true}
						showDataTable={true}
					>
						<p className="text-gray-600">{competency.aggregated_summary}</p>
						<div className="mt-6">
							{isLoadingUserSessions || Object.keys(userSessions).length === 0 ? (
								<div className="flex items-center space-x-2">
									<Spinner className="h-4 w-4" />
									<span className="text-sm text-gray-500">{t("cards.frontlineEvaluation.loadingStudentDetails")}</span>
								</div>
							) : (
								<>
									{/* Add this new section for competency distribution */}
									{(() => {
										const segments = generateTimelineData("competency", competency.name);
										const totalCount = segments.length;
										const highCount = segments.filter((s) => s.variant === "high").length;
										const mediumCount = segments.filter((s) => s.variant === "medium").length;
										const lowCount = segments.filter((s) => s.variant === "low").length;

										return (
											<div className="mt-4 flex flex-col sm:flex-row sm:items-center sm:space-x-2">
												<span className="font-semibold text-gray-800">{t("renderCards.questionList.distribution")}: </span>
												<div className="sm:mt-0 space-y-2 sm:space-y-0 space-x-2">
													<div className="inline-block px-2 py-1 rounded-md border text-sm font-medium bg-[#e6f7f7] text-[#00a9af] border-[#b3e6e8]">
														{t("renderCards.questionList.high")}: {((highCount / totalCount) * 100).toFixed(1)}%
													</div>
													<div className="inline-block px-2 py-1 rounded-md border text-sm font-medium bg-yellow-100 text-yellow-600 border-yellow-300">
														{t("renderCards.questionList.medium")}: {((mediumCount / totalCount) * 100).toFixed(1)}%
													</div>
													<div className="inline-block px-2 py-1 rounded-md border text-sm font-medium bg-[#FEF3F2] text-[#B42318] border-[#FEE4E2]">
														{t("renderCards.questionList.low")}: {((lowCount / totalCount) * 100).toFixed(1)}%
													</div>
												</div>
											</div>
										);
									})()}
									<div className="mb-4 mt-4">
										<ScoreTimeline segments={generateTimelineData("competency", competency.name)} />
									</div>
									<Button
										variant="outline"
										size="sm"
										onClick={() => toggleTable(competency.name)}
										className={`flex items-center space-x-2 mt-8 ${isExpanded ? "mb-4" : ""}`}
										aria-expanded={isExpanded}
										aria-label={`${isExpanded ? t("cards.hideSources") : t("cards.showSources")} competency details for ${
											competency.name
										}`}
									>
										<UsersIcon className="h-4 w-4" />
										<span>{isExpanded ? t("cards.hideSources") : t("cards.showSources")}</span>
										{isExpanded ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />}
									</Button>
									{isExpanded && (
										<StudentDetailsTable
											sessions={Object.values(userSessions)}
											type="competency"
											competencyName={competency.name}
											onNavigateToReport={navigateToUserReport}
										/>
									)}
								</>
							)}
						</div>
					</ReportCard>
				);
			})}
		</div>
	);
};

const RenderExpectations = ({
	schedule,
	userSessions,
	handleToggleExpandedTables,
	expandedTables,
	generateTimelineData,
	isLoadingUserSessions
}: {
	schedule: ScheduleView;
	userSessions: Record<string, SessionView>;
	handleToggleExpandedTables: (key: string) => void;
	expandedTables: Record<string, boolean>;
	generateTimelineData: (type: string, competencyName?: string, questionId?: string) => TimelineSegment[];
	isLoadingUserSessions: boolean;
}) => {
	const { t } = useTranslation("adminAssignmentPage");
	if (!schedule || !schedule.expectations || !schedule.expectations.content) return null;

	const isExpanded = expandedTables["expectations"] || false;

	return (
		<ReportCard title={t("cards.expectations.title")} icon={<ClipboardDocumentCheckIcon className="h-5 w-5 text-[#16013e]" />}>
			<p>{schedule.expectations.content}</p>
			{schedule.expectations.outlook && schedule.expectations.outlook.total_count > 0 && (
				<div className="mt-4 flex items-center space-x-2">
					<span className="font-semibold text-gray-800">{t("cards.expectations.outlook")}: </span>
					<div className="space-x-2">
						<div className="inline-block px-2 py-1 rounded-md border text-sm font-medium bg-[#e6f7f7] text-[#00a9af] border-[#b3e6e8]">
							{t("cards.expectations.positive")}:{" "}
							{((schedule.expectations.outlook.positive_count / schedule.expectations.outlook.total_count) * 100).toFixed(1)}%
						</div>
						<div className="inline-block px-2 py-1 rounded-md border text-sm font-medium bg-[#FEF3F2] text-[#B42318] border-[#FEE4E2]">
							{t("cards.expectations.negative")}:{" "}
							{((schedule.expectations.outlook.negative_count / schedule.expectations.outlook.total_count) * 100).toFixed(1)}%
						</div>
						<div className="inline-block px-2 py-1 rounded-md border text-sm font-medium bg-yellow-100 text-yellow-600 border-yellow-300">
							{t("cards.expectations.mixed")}:{" "}
							{((schedule.expectations.outlook.mixed_count / schedule.expectations.outlook.total_count) * 100).toFixed(1)}%
						</div>
					</div>
				</div>
			)}
			{userSessions && Object.keys(userSessions).length > 0 && (
				<div className="mt-6">
					<div className="mb-4">
						<ScoreTimeline segments={generateTimelineData("expectations")} />
					</div>
					<Button
						variant="outline"
						size="sm"
						onClick={() => handleToggleExpandedTables("expectations")}
						className={`flex items-center space-x-2 mt-8 ${isExpanded ? "mb-4" : ""}`}
						aria-expanded={isExpanded}
						aria-label={`${isExpanded ? t("cards.hideSources") : t("cards.showSources")} expectations details`}
					>
						<UsersIcon className="h-4 w-4" />
						<span>{isExpanded ? t("cards.hideSources") : t("cards.showSources")}</span>
						{isExpanded ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />}
					</Button>
					{isExpanded &&
						(isLoadingUserSessions || Object.keys(userSessions).length === 0 ? (
							<div className="flex justify-center p-4">
								<Spinner />
							</div>
						) : (
							<StudentDetailsTable
								sessions={Object.values(userSessions)}
								type="expectations"
								onNavigateToReport={navigateToUserReport}
							/>
						))}
				</div>
			)}
		</ReportCard>
	);
};

const RenderProjectGoals = ({
	schedule,
	userSessions,
	handleToggleExpandedTables,
	expandedTables
}: {
	schedule: ScheduleView;
	userSessions: Record<string, SessionView>;
	handleToggleExpandedTables: (key: string) => void;
	expandedTables: Record<string, boolean>;
}) => {
	const { t } = useTranslation("adminAssignmentPage");
	if (!schedule || !schedule.projectGoals || schedule.projectGoals === "") return null;

	const isExpanded = expandedTables["goalProgress"] || false;

	return (
		<ReportCard title={t("cards.projectGoals.title")} icon={<FlagIcon className="h-5 w-5 text-[#16013e]" />}>
			<p>{schedule.projectGoals}</p>
			<Button
				variant="outline"
				size="sm"
				onClick={() => handleToggleExpandedTables("goalProgress")}
				className={`flex items-center space-x-2 mt-8 ${isExpanded ? "mb-4" : ""}`}
				aria-expanded={isExpanded}
				aria-label={`${isExpanded ? t("cards.hideSources") : t("cards.showSources")} goal progress details`}
			>
				<UsersIcon className="h-4 w-4" />
				<span>{isExpanded ? t("cards.hideSources") : t("cards.showSources")}</span>
				{isExpanded ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />}
			</Button>
			{isExpanded && (
				<StudentDetailsTable sessions={Object.values(userSessions)} type="goalProgress" onNavigateToReport={navigateToUserReport} />
			)}
		</ReportCard>
	);
};

const RenderPurpose = ({
	schedule,
	userSessions,
	handleToggleExpandedTables,
	expandedTables,
	generateTimelineData,
	isLoadingUserSessions
}: {
	schedule: ScheduleView;
	userSessions: Record<string, SessionView>;
	handleToggleExpandedTables: (key: string) => void;
	expandedTables: Record<string, boolean>;
	generateTimelineData: (type: string, competencyName?: string, questionId?: string) => TimelineSegment[];
	isLoadingUserSessions: boolean;
}) => {
	const { t } = useTranslation("adminAssignmentPage");
	if (!schedule || !schedule.purpose || !schedule.purpose.progress) return null;
	const isExpanded = expandedTables["purpose"] || false;

	return (
		<ReportCard title={t("cards.purpose.title")} icon={<LightBulbIcon className="h-5 w-5 text-[#16013e]" />}>
			<p className="text-gray-600 mb-4">{schedule.purpose.summary}</p>
			{schedule.purpose.progress.total_count > 0 && (
				<div className="mt-4 flex flex-col sm:flex-row sm:items-center sm:space-x-2">
					<span className="font-semibold text-gray-800">{t("cards.progress")}: </span>
					<div className="sm:mt-0 space-y-2 sm:space-y-0 space-x-2">
						<div className="inline-block px-2 py-1 rounded-md border text-sm font-medium bg-[#e6f7f7] text-[#00a9af] border-[#b3e6e8]">
							{t("cards.yes")}: {((schedule.purpose.progress.yes_count / schedule.purpose.progress.total_count) * 100).toFixed(1)}%
						</div>
						<div className="inline-block px-2 py-1 rounded-md border text-sm font-medium bg-yellow-100 text-yellow-600 border-yellow-300">
							{t("cards.partial")}:{" "}
							{((schedule.purpose.progress.partial_count / schedule.purpose.progress.total_count) * 100).toFixed(1)}%
						</div>
						<div className="inline-block px-2 py-1 rounded-md border text-sm font-medium bg-[#FEF3F2] text-[#B42318] border-[#FEE4E2]">
							{t("cards.no")}: {((schedule.purpose.progress.no_count / schedule.purpose.progress.total_count) * 100).toFixed(1)}%
						</div>
						{schedule.purpose.progress.new_count > 0 && (
							<div className="inline-block px-2 py-1 rounded-md border text-sm font-medium bg-blue-100 text-blue-800 border-blue-300">
								{t("cards.new")}: {((schedule.purpose.progress.new_count / schedule.purpose.progress.total_count) * 100).toFixed(1)}%
							</div>
						)}
					</div>
				</div>
			)}
			{userSessions && Object.keys(userSessions).length > 0 && (
				<div className="mt-6">
					{isLoadingUserSessions || Object.keys(userSessions).length === 0 ? (
						<div className="flex items-center space-x-2">
							<Spinner className="h-4 w-4" />
							<span className="text-sm text-gray-500">{t("cards.frontlineEvaluation.loadingStudentDetails")}</span>
						</div>
					) : (
						<>
							<div className="mb-4">
								<ScoreTimeline segments={generateTimelineData("purpose")} />
							</div>
							<Button
								variant="outline"
								size="sm"
								onClick={() => handleToggleExpandedTables("purpose")}
								className={`flex items-center space-x-2 mt-8 ${isExpanded ? "mb-4" : ""}`}
								aria-expanded={isExpanded}
								aria-label={`${isExpanded ? t("cards.hideSources") : t("cards.showSources")} sources for purpose`}
							>
								<UsersIcon className="h-4 w-4" />
								<span>{isExpanded ? t("cards.hideSources") : t("cards.showSources")}</span>
								{isExpanded ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />}
							</Button>
							{isExpanded && (
								<StudentDetailsTable
									sessions={Object.values(userSessions)}
									type="purpose"
									onNavigateToReport={navigateToUserReport}
								/>
							)}
						</>
					)}
				</div>
			)}
		</ReportCard>
	);
};

const RenderAssignmentSections = ({
	schedule,
	assignment,
	assignedSessions,
	scheduleSessionStats,
	isEditingCheckbox,
	handleCancelEdit,
	handleSaveChanges,
	expandedTables,
	handleToggleExpandedTables,
	userSessions,
	isLoadingUserSessions,
	generateTimelineData,
	satisfactionData,
	satisfactionDetails,
	competencyData,
	competencyDetails
}: {
	schedule: ScheduleView;
	assignment: AdminAssignmentView;
	assignedSessions: AssignedSessionView[];
	scheduleSessionStats: SessionStats;
	isEditingCheckbox: boolean;
	handleCancelEdit: () => void;
	handleSaveChanges: () => void;
	expandedTables: Record<string, boolean>;
	handleToggleExpandedTables: (key: string) => void;
	userSessions: Record<string, SessionView>;
	isLoadingUserSessions: boolean;
	generateTimelineData: (type: string, competencyName?: string, questionId?: string) => TimelineSegment[];
	satisfactionData: number[];
	satisfactionDetails: ReportScoreDataPoint[];
	competencyData: { [key: string]: number[] };
	competencyDetails: { [key: string]: ReportScoreDataPoint[] };
}) => {
	if (!schedule || !assignment) return null;

	// const sections: React.ReactNode[] = [];
	const sessionType = getSessionType(assignment.experienceType);

	if (sessionType === SessionType.INTERVIEW) {
		// Interview-specific sections

		return (
			<div className="email-report-post-session">
				<RenderMetrics
					schedule={schedule}
					assignment={assignment}
					assignedSessions={assignedSessions}
					scheduleSessionStats={scheduleSessionStats}
				/>

				<RenderFlaggedComments
					assignmentId={assignment.assignmentId}
					schedule={schedule}
					isEditingCheckbox={isEditingCheckbox}
					handleCancelEdit={handleCancelEdit}
					handleSaveChanges={handleSaveChanges}
				/>

				<RenderInterviewAssessment
					schedule={schedule}
					userSessions={userSessions}
					expandedTables={expandedTables}
					handleToggleExpandedTables={handleToggleExpandedTables}
				/>

				<RenderQuestionListSummary
					schedule={schedule}
					expandedTables={expandedTables}
					handleToggleExpandedTables={handleToggleExpandedTables}
					userSessions={userSessions}
					generateTimelineData={generateTimelineData}
				/>
			</div>
		);
	} else if (sessionType === SessionType.FRONTLINE) {
		// Frontline-specific sections
		return (
			<div className="email-report-post-session">
				<RenderMetrics
					schedule={schedule}
					assignment={assignment}
					assignedSessions={assignedSessions}
					scheduleSessionStats={scheduleSessionStats}
				/>

				<RenderFlaggedComments
					assignmentId={assignment.assignmentId}
					schedule={schedule}
					isEditingCheckbox={isEditingCheckbox}
					handleCancelEdit={handleCancelEdit}
					handleSaveChanges={handleSaveChanges}
				/>

				<RenderFrontlineAssessment
					schedule={schedule}
					userSessions={userSessions}
					handleToggleExpandedTables={handleToggleExpandedTables}
					expandedTables={expandedTables}
					isLoadingUserSessions={isLoadingUserSessions}
				/>
			</div>
		);
	} else if (sessionType === SessionType.JOB_SEARCH) {
		// Job Search-specific sections

		return (
			<div className="email-report-post-session">
				<RenderMetrics
					schedule={schedule}
					assignment={assignment}
					assignedSessions={assignedSessions}
					scheduleSessionStats={scheduleSessionStats}
				/>

				<RenderOverview
					schedule={schedule}
					userSessions={userSessions}
					handleToggleExpandedTables={handleToggleExpandedTables}
					expandedTables={expandedTables}
				/>
				{schedule.activityType === ActivityType.MIDDLE && (
					<RenderGoalProgress
						schedule={schedule}
						userSessions={userSessions}
						handleToggleExpandedTables={handleToggleExpandedTables}
						expandedTables={expandedTables}
						generateTimelineData={generateTimelineData}
					/>
				)}
				<RenderFlaggedComments
					assignmentId={assignment.assignmentId}
					schedule={schedule}
					isEditingCheckbox={isEditingCheckbox}
					handleCancelEdit={handleCancelEdit}
					handleSaveChanges={handleSaveChanges}
				/>
			</div>
		);
	} else if (sessionType === SessionType.CAREER_PATH) {
		if (schedule.activityType === ActivityType.FIRST) {
			return (
				<div className="email-report-post-session">
					<RenderMetrics
						schedule={schedule}
						assignment={assignment}
						assignedSessions={assignedSessions}
						scheduleSessionStats={scheduleSessionStats}
					/>

					<RenderFlaggedComments
						assignmentId={assignment.assignmentId}
						schedule={schedule}
						isEditingCheckbox={isEditingCheckbox}
						handleCancelEdit={handleCancelEdit}
						handleSaveChanges={handleSaveChanges}
					/>

					<RenderOverview
						schedule={schedule}
						userSessions={userSessions}
						handleToggleExpandedTables={handleToggleExpandedTables}
						expandedTables={expandedTables}
					/>

					<RenderCareerPreparedness
						schedule={schedule}
						userSessions={userSessions}
						handleToggleExpandedTables={handleToggleExpandedTables}
						expandedTables={expandedTables}
						generateTimelineData={generateTimelineData}
					/>
					<RenderCareerSupport
						schedule={schedule}
						userSessions={userSessions}
						handleToggleExpandedTables={handleToggleExpandedTables}
						expandedTables={expandedTables}
						generateTimelineData={generateTimelineData}
					/>

					<RenderCareerHappiness
						schedule={schedule}
						userSessions={userSessions}
						handleToggleExpandedTables={handleToggleExpandedTables}
						expandedTables={expandedTables}
						generateTimelineData={generateTimelineData}
					/>
				</div>
			);
		} else {
			return (
				<div className="email-report-post-session">
					<RenderMetrics
						schedule={schedule}
						assignment={assignment}
						assignedSessions={assignedSessions}
						scheduleSessionStats={scheduleSessionStats}
					/>

					<RenderFlaggedComments
						assignmentId={assignment.assignmentId}
						schedule={schedule}
						isEditingCheckbox={isEditingCheckbox}
						handleCancelEdit={handleCancelEdit}
						handleSaveChanges={handleSaveChanges}
					/>

					<RenderOverview
						schedule={schedule}
						userSessions={userSessions}
						handleToggleExpandedTables={handleToggleExpandedTables}
						expandedTables={expandedTables}
					/>

					<RenderCareerPathSuccessfulChoice schedule={schedule} userSessions={userSessions} />
				</div>
			);
		}
	} else {
		// Reflection-type sections
		const isCourse = sessionType === SessionType.REFLECTION_COURSE;

		switch (schedule.activityType) {
			case ActivityType.FIRST:
				return (
					<div className="email-report-post-session">
						<RenderMetrics
							schedule={schedule}
							assignment={assignment}
							assignedSessions={assignedSessions}
							scheduleSessionStats={scheduleSessionStats}
						/>

						<RenderFlaggedComments
							assignmentId={assignment.assignmentId}
							schedule={schedule}
							isEditingCheckbox={isEditingCheckbox}
							handleCancelEdit={handleCancelEdit}
							handleSaveChanges={handleSaveChanges}
						/>

						<RenderStudentSatisfaction
							schedule={schedule}
							userSessions={userSessions}
							handleToggleExpandedTables={handleToggleExpandedTables}
							expandedTables={expandedTables}
							generateTimelineData={generateTimelineData}
							satisfactionData={satisfactionData}
							satisfactionDetails={satisfactionDetails}
							isLoadingUserSessions={isLoadingUserSessions}
						/>

						<RenderCompetencyProgress
							schedule={schedule}
							userSessions={userSessions}
							handleToggleExpandedTables={handleToggleExpandedTables}
							expandedTables={expandedTables}
							generateTimelineData={generateTimelineData}
							competencyData={competencyData}
							competencyDetails={competencyDetails}
							isLoadingUserSessions={isLoadingUserSessions}
						/>

						<RenderExpectations
							schedule={schedule}
							userSessions={userSessions}
							handleToggleExpandedTables={handleToggleExpandedTables}
							expandedTables={expandedTables}
							generateTimelineData={generateTimelineData}
							isLoadingUserSessions={isLoadingUserSessions}
						/>
						{!isCourse && (
							<RenderProjectGoals
								schedule={schedule}
								userSessions={userSessions}
								handleToggleExpandedTables={handleToggleExpandedTables}
								expandedTables={expandedTables}
							/>
						)}
						{!isCourse && (
							<RenderPurpose
								schedule={schedule}
								userSessions={userSessions}
								handleToggleExpandedTables={handleToggleExpandedTables}
								expandedTables={expandedTables}
								generateTimelineData={generateTimelineData}
								isLoadingUserSessions={isLoadingUserSessions}
							/>
						)}
					</div>
				);

			case ActivityType.MIDDLE:
				return (
					<div className="email-report-post-session">
						<RenderMetrics
							schedule={schedule}
							assignment={assignment}
							assignedSessions={assignedSessions}
							scheduleSessionStats={scheduleSessionStats}
						/>

						<RenderFlaggedComments
							assignmentId={assignment.assignmentId}
							schedule={schedule}
							isEditingCheckbox={isEditingCheckbox}
							handleCancelEdit={handleCancelEdit}
							handleSaveChanges={handleSaveChanges}
						/>

						<RenderStudentSatisfaction
							schedule={schedule}
							userSessions={userSessions}
							handleToggleExpandedTables={handleToggleExpandedTables}
							expandedTables={expandedTables}
							generateTimelineData={generateTimelineData}
							satisfactionData={satisfactionData}
							satisfactionDetails={satisfactionDetails}
							isLoadingUserSessions={isLoadingUserSessions}
						/>

						<RenderCompetencyProgress
							schedule={schedule}
							userSessions={userSessions}
							handleToggleExpandedTables={handleToggleExpandedTables}
							expandedTables={expandedTables}
							generateTimelineData={generateTimelineData}
							competencyData={competencyData}
							competencyDetails={competencyDetails}
							isLoadingUserSessions={isLoadingUserSessions}
						/>
						{!isCourse && (
							<RenderGoalProgress
								schedule={schedule}
								userSessions={userSessions}
								handleToggleExpandedTables={handleToggleExpandedTables}
								expandedTables={expandedTables}
								generateTimelineData={generateTimelineData}
							/>
						)}
						{!isCourse && (
							<RenderPurpose
								schedule={schedule}
								userSessions={userSessions}
								handleToggleExpandedTables={handleToggleExpandedTables}
								expandedTables={expandedTables}
								generateTimelineData={generateTimelineData}
								isLoadingUserSessions={isLoadingUserSessions}
							/>
						)}
					</div>
				);

			case ActivityType.LAST:
				return (
					<div className="email-report-post-session">
						<RenderMetrics
							schedule={schedule}
							assignment={assignment}
							assignedSessions={assignedSessions}
							scheduleSessionStats={scheduleSessionStats}
						/>
						<RenderFlaggedComments
							assignmentId={assignment.assignmentId}
							schedule={schedule}
							isEditingCheckbox={isEditingCheckbox}
							handleCancelEdit={handleCancelEdit}
							handleSaveChanges={handleSaveChanges}
						/>
						<RenderStudentSatisfaction
							schedule={schedule}
							userSessions={userSessions}
							handleToggleExpandedTables={handleToggleExpandedTables}
							expandedTables={expandedTables}
							generateTimelineData={generateTimelineData}
							satisfactionData={satisfactionData}
							satisfactionDetails={satisfactionDetails}
							isLoadingUserSessions={isLoadingUserSessions}
						/>
						<RenderCompetencyProgress
							schedule={schedule}
							userSessions={userSessions}
							handleToggleExpandedTables={handleToggleExpandedTables}
							expandedTables={expandedTables}
							generateTimelineData={generateTimelineData}
							competencyData={competencyData}
							competencyDetails={competencyDetails}
							isLoadingUserSessions={isLoadingUserSessions}
						/>
						{!isCourse && (
							<RenderGoalProgress
								schedule={schedule}
								userSessions={userSessions}
								handleToggleExpandedTables={handleToggleExpandedTables}
								expandedTables={expandedTables}
								generateTimelineData={generateTimelineData}
							/>
						)}
						{!isCourse && (
							<RenderPurpose
								schedule={schedule}
								userSessions={userSessions}
								handleToggleExpandedTables={handleToggleExpandedTables}
								expandedTables={expandedTables}
								generateTimelineData={generateTimelineData}
								isLoadingUserSessions={isLoadingUserSessions}
							/>
						)}
						{schedule.finalReview && (schedule.finalReview.takeaways || schedule.finalReview.growth) && (
							<ReportCard title="Student Growth" icon={<RocketLaunchIcon className="h-5 w-5 text-[#16013e]" />}>
								{schedule.finalReview.takeaways && (
									<div className="mt-4">
										<h3 className="font-semibold text-gray-800 mb-2">Key Takeaways</h3>
										<p className="text-gray-600">{schedule.finalReview.takeaways}</p>
									</div>
								)}
								{schedule.finalReview.growth && (
									<div className="mt-4">
										<h3 className="font-semibold text-gray-800 mb-2">Growth</h3>
										<p className="text-gray-600">{schedule.finalReview.growth}</p>
									</div>
								)}
							</ReportCard>
						)}

						{schedule.finalReview && schedule.finalReview.feedback && (
							<ReportCard title="Reflection Feedback" icon={<ClipboardDocumentListIcon className="h-5 w-5 text-[#16013e]" />}>
								<div className="mt-4">
									<div className="space-y-4">
										<div>
											<h3 className="font-semibold text-gray-800 mb-2">Valuable Aspects</h3>
											<span className="text-gray-600">{schedule.finalReview.feedback.valuableAspects}</span>
										</div>
										<div>
											<h3 className="font-semibold text-gray-800 mb-2">Experience Contribution</h3>
											<span className="text-gray-600">{schedule.finalReview.feedback.contribution}</span>
										</div>
										<div>
											<h3 className="font-semibold text-gray-800 mb-2">Suggestions</h3>
											<span className="text-gray-600">{schedule.finalReview.feedback.suggestions}</span>
										</div>
										<div className="flex items-center space-x-2">
											<h3 className="font-semibold text-gray-800 mb-2">Avg Rating for Guided Reflection</h3>
											<Badge
												variant={
													schedule.finalReview.feedback.rating >= 8
														? "high"
														: schedule.finalReview.feedback.rating >= 6
														? "medium"
														: "low"
												}
												className="text-sm font-medium"
											>
												{schedule.finalReview.feedback.rating}
											</Badge>
										</div>
										<div>
											<h3 className="font-semibold text-gray-800 mb-2">Preferred Method</h3>
											<div className="space-x-2">
												<div className="inline-block px-2 py-1 rounded-md border text-sm font-medium bg-[#e6f7f7] text-[#00a9af] border-[#b3e6e8]">
													Guided:{" "}
													{(
														(schedule.finalReview.feedback.preferredMethod.guided /
															schedule.finalReview.feedback.preferredMethod.total) *
														100
													).toFixed(1)}
													%
												</div>
												<div className="inline-block px-2 py-1 rounded-md border text-sm font-medium bg-[#FEF3F2] text-[#B42318] border-[#FEE4E2]">
													Traditional:{" "}
													{(
														(schedule.finalReview.feedback.preferredMethod.traditional /
															schedule.finalReview.feedback.preferredMethod.total) *
														100
													).toFixed(1)}
													%
												</div>
												<div className="inline-block px-2 py-1 rounded-md border text-sm font-medium bg-yellow-100 text-yellow-600 border-yellow-300">
													Unknown:{" "}
													{(
														(schedule.finalReview.feedback.preferredMethod.unknown /
															schedule.finalReview.feedback.preferredMethod.total) *
														100
													).toFixed(1)}
													%
												</div>
											</div>
										</div>
									</div>
								</div>
								{userSessions && Object.keys(userSessions).length > 0 && (
									<div className="mt-6">
										{isLoadingUserSessions || Object.keys(userSessions).length === 0 ? (
											<div className="flex items-center space-x-2">
												<Spinner className="h-4 w-4" />
												<span className="text-sm text-gray-500">Loading student details...</span>
											</div>
										) : (
											<>
												<div className="mb-4">
													<ScoreTimeline segments={generateTimelineData("finalReview")} />
												</div>
												<Button
													variant="outline"
													size="sm"
													onClick={() => handleToggleExpandedTables("finalReview")}
													className={`flex items-center space-x-2 mt-8 ${expandedTables["finalReview"] ? "mb-4" : ""}`}
													aria-expanded={expandedTables["finalReview"]}
													aria-label={`${expandedTables["finalReview"] ? "Hide" : "Show"} final review details`}
												>
													<UsersIcon className="h-4 w-4" />
													<span>{expandedTables["finalReview"] ? "Hide" : "Show"} Sources</span>
													{expandedTables["finalReview"] ? (
														<ChevronUpIcon className="h-4 w-4" />
													) : (
														<ChevronDownIcon className="h-4 w-4" />
													)}
												</Button>
												{expandedTables["finalReview"] && (
													<StudentDetailsTable
														sessions={Object.values(userSessions)}
														type="finalReview"
														onNavigateToReport={navigateToUserReport}
													/>
												)}
											</>
										)}
									</div>
								)}
							</ReportCard>
						)}
					</div>
				);
		}
	}

	return <div className="email-report-post-session"></div>;
};

const AdminAssignmentPage = (): JSX.Element => {
	const { debugMode } = useAppContext();
	const { assignmentId, scheduleId } = useParams<{ assignmentId: string; scheduleId: string }>();
	const [selectedScheduleId, setSelectedScheduleId] = useState<string>(scheduleId || "");
	const [schedules, setSchedules] = useState<ScheduleView[]>([]);
	const [assignedSessions, setAssignedSessions] = useState<AssignedSessionView[]>([]);
	const [anonymousAssignedSessions, setAnonymousAssignedSessions] = useState<AssignedSessionView[]>([]);
	const [isEditingCheckbox, setIsEditingCheckbox] = useState<boolean>(false);
	const [originalFlaggedComments, setOriginalFlaggedComments] = useState<any>(null);
	const { t } = useTranslation("adminAssignmentPage");
	const [isGeneratingAggregate, setIsGeneratingAggregate] = useState<boolean>(false);
	const [assignment, setAssignment] = useState<AdminAssignmentView | null>(null);
	const [isDetailsVisible, setIsDetailsVisible] = useState<boolean>(false);
	const [isDownloading, setIsDownloading] = useState<boolean>(false);
	const [isLoadingUserSessions, setIsLoadingUserSessions] = useState<boolean>(false);
	const [userSessions, setUserSessions] = useState<{ [key: string]: SessionView }>({});
	const [schedule, setSchedule] = useState<ScheduleView | null>(null);
	const [expandedTables, setExpandedTables] = useState<{ [key: string]: boolean }>({});
	const [assignmentSessionStats, setAssignmentSessionStats] = useState<SessionStats>({
		totalDuration: "0h 0m",
		avgDuration: "0h 0m",
		sessionsStarted: 0,
		sessionsSubmitted: 0,
		uniqueStudents: 0
	});

	const [scheduleSessionStats, setScheduleSessionStats] = useState<SessionStats>({
		totalDuration: "0h 0m",
		avgDuration: "0h 0m",
		sessionsStarted: 0,
		sessionsSubmitted: 0,
		uniqueStudents: 0
	});

	const shuffleArray = <T,>(array: T[]): T[] => {
		const newArray = [...array];
		for (let i = newArray.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			[newArray[i], newArray[j]] = [newArray[j], newArray[i]];
		}
		return newArray;
	};

	const tabs: TabData[] = [...schedules]
		.sort((a, b) => {
			// If both have interviewStageOrderNumber, sort by that
			if (a.interviewStageOrderNumber !== null && b.interviewStageOrderNumber !== null) {
				return a.interviewStageOrderNumber - b.interviewStageOrderNumber;
			}
			// If both have frontlineStageOrderNumber, sort by that
			else if (a.frontlineStageOrderNumber !== null && b.frontlineStageOrderNumber !== null) {
				return a.frontlineStageOrderNumber - b.frontlineStageOrderNumber;
			}
			// If both have careerPathStageOrderNumber, sort by that
			else if (a.careerPathStageOrderNumber !== null && b.careerPathStageOrderNumber !== null) {
				return a.careerPathStageOrderNumber - b.careerPathStageOrderNumber;
			}
			return 0; // Keep original order if not comparable
		})
		.map((schedule) => ({
			label: schedule.scheduleDescription,
			status: schedule.status as AssignedSessionStatus,
			date: schedule.scheduleDate,
			scheduleId: schedule.scheduleId,
			activityType: schedule.activityType,
			interviewStageOrderNumber: schedule.interviewStageOrderNumber ?? undefined,
			interviewStageType: schedule.interviewStageType ?? undefined,
			careerPathStageOrderNumber: schedule.careerPathStageOrderNumber ?? undefined,
			careerPathStageType: schedule.careerPathStageType ?? undefined,
			frontlineStageOrderNumber: schedule.frontlineStageOrderNumber ?? undefined,
			frontlineStageType: schedule.frontlineStageType ?? undefined
		}));

	const selectedTab = tabs.findIndex((t) => t.scheduleId === selectedScheduleId);

	useEffect(() => {
		if (assignmentId) {
			// console.log("Getting schedules for assignment:", assignmentId);
			// gets all schedules for the assignment
			ScheduleService.getByAssignmentId(assignmentId)
				.then((schedules) => {
					console.log("schedules", schedules);
					setSchedules(schedules);
					if (debugMode) {
						console.log("schedules", schedules);
					}
				})
				.catch((error) => {
					console.error("Error getting schedules:", error);
				});
			// gets the assignment details
			AssignmentService.getAdminViewById(assignmentId).then((assignment) => {
				if (debugMode) {
					console.log("assignment", assignment);
				}
				setAssignment(assignment);
			});
			// gets the assignment session stats
			SessionService.getSessionStatsByAssignmentId(assignmentId).then((stats) => {
				// console.log(stats);
				if (debugMode) {
					console.log("assignmentSessionStats", stats);
				}
				setAssignmentSessionStats(stats);
			});
		}
	}, [assignmentId, debugMode]);

	useEffect(() => {
		let localScheduleId = scheduleId;
		if (!scheduleId && schedules.length > 0) {
			setSelectedScheduleId(schedules[0].scheduleId);
			localScheduleId = schedules[0].scheduleId;
		}

		if (localScheduleId) {
			const currentSchedule = schedules.find((s) => s.scheduleId === localScheduleId);
			setSchedule(currentSchedule || null);
			setExpandedTables({});
			// gets the assigned sessions for the schedule
			AssignedSessionService.getByscheduleId(localScheduleId)
				.then((sessions) => {
					setAssignedSessions(sessions);
					if (debugMode) {
						console.log("assignedSessions", sessions);
					}
				})
				.catch((error) => {
					console.error("Error getting user assigned sessions:", error);
				});

			// gets the anonymous assigned sessions for the schedule
			if (assignment && !assignment.includeUserIdentification) {
				AssignedSessionService.getByscheduleIdAnonymous(localScheduleId).then((sessions) => {
					const filteredSessions = sessions.filter(
						(s) => s.status === AssignedSessionStatus.SUBMITTED || s.status === AssignedSessionStatus.SUBMITTED_LATE
					);
					setAnonymousAssignedSessions(shuffleArray(filteredSessions));
				});
			}
			// gets the schedule session stats
			SessionService.getSessionStatsByScheduleId(localScheduleId).then((stats) => {
				// console.log(stats);
				setScheduleSessionStats(stats);
			});
		}
	}, [scheduleId, schedules, assignment, debugMode]);

	useEffect(() => {
		if (schedule) {
			fetchUserSessionData(schedule);
		}
	}, [schedule]);

	const { satisfactionData, satisfactionDetails, competencyData, competencyDetails } = useMemo(
		() => generateReportScoreData(schedules, selectedScheduleId),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[schedules, selectedScheduleId]
	);

	const copyToClipboard = useCallback((text: string) => {
		navigator.clipboard.writeText(text);
		// Optionally, show a tooltip or notification that the text was copied
	}, []);

	function generateAggregateData(schedule: ScheduleView) {
		setIsGeneratingAggregate(true);
		switch (schedule.activityType) {
			case ActivityType.FIRST:
				ScheduleService.generateFirstAggregateData(schedule.assignmentId, schedule.scheduleId)
					.then((schedule) => {
						setSchedules((prevSchedules) => {
							const updatedSchedules = prevSchedules.map((s) => (s.scheduleId === schedule.scheduleId ? schedule : s));
							return updatedSchedules;
						});
					})
					.catch((error) => {
						console.error("Error generating aggregate data:", error);
					})
					.finally(() => {
						setIsGeneratingAggregate(false);
					});
				break;
			case ActivityType.MIDDLE:
				ScheduleService.generateMiddleAggregateData(schedule.assignmentId, schedule.scheduleId)
					.then((schedule) => {
						setSchedules((prevSchedules) => {
							const updatedSchedules = prevSchedules.map((s) => (s.scheduleId === schedule.scheduleId ? schedule : s));
							return updatedSchedules;
						});
					})
					.catch((error) => {
						console.error("Error generating aggregate data:", error);
					})
					.finally(() => {
						setIsGeneratingAggregate(false);
					});
				break;
			case ActivityType.LAST:
				ScheduleService.generateLastAggregateData(schedule.assignmentId, schedule.scheduleId)
					.then((schedule) => {
						setSchedules((prevSchedules) => {
							const updatedSchedules = prevSchedules.map((s) => (s.scheduleId === schedule.scheduleId ? schedule : s));
							return updatedSchedules;
						});
					})
					.catch((error) => {
						console.error("Error generating aggregate data:", error);
					})
					.finally(() => {
						setIsGeneratingAggregate(false);
					});
				break;
		}
	}

	const downloadAggregateTemplate = async (scheduleToDownload: ScheduleView) => {
		if (!scheduleToDownload) {
			console.log("No schedule found");
			return;
		}
		setIsDownloading(true);
		await ScheduleService.downloadAggregatePDF(scheduleToDownload.scheduleId);
		setIsDownloading(false);
	};

	const downloadAssignedSessionsCSV = async () => {
		if (!selectedScheduleId) {
			console.log("No schedule found");
			return;
		}
		ScheduleService.downloadAssignedSessionsCSV(selectedScheduleId);
	};

	const refreshAssignedSessions = async () => {
		const sessions = await AssignedSessionService.getByscheduleId(selectedScheduleId);
		setAssignedSessions(sessions);
		console.log(sessions.length);
	};

	const handleEditAssignment = (assignmentId: string, experienceType: ExperienceType) => {
		console.log("Edit assignment", assignmentId, experienceType);
		NavigateService.navToEditAssignmentByExperienceType(assignmentId, experienceType);
	};

	const handleAddUser = async (email: string) => {
		if (!selectedScheduleId) {
			console.log("No schedule ID found");
			return;
		}
		if (!assignmentId) {
			console.log("No assignment ID found");
			return;
		}
		console.log("Add user", email);

		await AssignmentService.createAndAddUserToAssignment(assignmentId, email);

		console.log(assignedSessions.length);
		refreshAssignedSessions();
	};

	const handleEditUser = (userId: string) => {
		console.log("Edit user", userId);
		// Implement edit user logic here
	};

	const handleRemoveUser = async (userId: string) => {
		if (!assignmentId) {
			console.log("No assignmentId ID found");
			return;
		}
		if (!selectedScheduleId) {
			console.log("No schedule ID found");
			return;
		}
		console.log("Remove user", userId);

		try {
			await AssignmentService.removeUserFromAssignment(assignmentId, userId);
			// Refresh the assigned sessions list
			refreshAssignedSessions();
		} catch (error) {
			console.error("Error removing user:", error);
			// Handle error (e.g., show an error message to the user)
		}
	};

	const handleAddExtension = async (assignedSessionId: string, assignmentId: string, scheduleId: string, userId: string, daysToAdd: number) => {
		await AssignedSessionService.addExtension(assignedSessionId, assignmentId, scheduleId, userId, daysToAdd);
		refreshAssignedSessions();
	};

	const generateTimelineData = (type: string, competencyName?: string, questionId?: string): TimelineSegment[] => {
		if (!userSessions || Object.keys(userSessions).length === 0) return [];

		const sessions = Object.values(userSessions);

		// Filter sessions based on type to only include those with relevant data
		const filteredSessions = sessions.filter((session) => {
			switch (type) {
				case "satisfaction":
					return session.satisfaction?.score !== undefined;
				case "expectations":
					return session.expectations?.outlook !== undefined && session.expectations.outlook.trim() !== "";
				case "purpose":
					return session.purpose?.progress !== undefined;
				case "goalProgress":
					return Array.isArray(session.goalProgress) && session.goalProgress.length > 0;
				case "competency":
					if (!competencyName) return false;
					const competency = session.competencyProgress?.find((c) => c.name.toLowerCase() === competencyName.toLowerCase());
					return competency?.assessment !== undefined;
				case "finalReview":
					return session.finalReview?.feedback?.rating !== undefined;
				case "question":
					if (!questionId) return false;
					const question = session.questionListSummary?.find((q) => q.id === questionId);
					return (
						question !== undefined &&
						((question.assessment.score.grade_1 + question.assessment.score.grade_2) / 2 !== 0 ||
							(question.assessment?.feedback && question.assessment.feedback.trim() !== ""))
					);
				default:
					return false;
			}
		});

		// Sort filtered sessions based on type
		const sortedSessions = [...filteredSessions].sort((a, b) => {
			switch (type) {
				case "satisfaction": {
					return (b.satisfaction?.score || 0) - (a.satisfaction?.score || 0);
				}
				case "expectations": {
					const order = { positive: 0, mixed: 1, negative: 2 };
					const aOutlook = (a.expectations?.outlook || "").toLowerCase();
					const bOutlook = (b.expectations?.outlook || "").toLowerCase();
					return order[aOutlook as keyof typeof order] - order[bOutlook as keyof typeof order];
				}
				case "purpose": {
					const order = { Yes: 0, Partial: 1, No: 2, New: 3 };
					const aProgress = a.purpose?.progress || "No";
					const bProgress = b.purpose?.progress || "No";
					return order[aProgress as keyof typeof order] - order[bProgress as keyof typeof order];
				}
				case "goalProgress": {
					const order = { Yes: 0, Partial: 1, No: 2 };

					// Determine the highest priority status for each session
					const getHighestPriorityStatus = (session: SessionView) => {
						if (!Array.isArray(session.goalProgress)) return "No";

						if (session.goalProgress.some((goal) => goal.goal_status === "Yes")) return "Yes";
						if (session.goalProgress.some((goal) => goal.goal_status === "Partial")) return "Partial";
						return "No";
					};

					const aProgress = getHighestPriorityStatus(a);
					const bProgress = getHighestPriorityStatus(b);

					return order[aProgress as keyof typeof order] - order[bProgress as keyof typeof order];
				}
				case "competency": {
					if (!competencyName) return 0;
					const aCompetency = a.competencyProgress?.find((c) => c.name.toLowerCase() === competencyName.toLowerCase());
					const bCompetency = b.competencyProgress?.find((c) => c.name.toLowerCase() === competencyName.toLowerCase());
					return Number(bCompetency?.assessment || 0) - Number(aCompetency?.assessment || 0);
				}
				case "finalReview": {
					return (b.finalReview?.feedback?.rating || 0) - (a.finalReview?.feedback?.rating || 0);
				}
				case "question": {
					if (!questionId) return 0;
					const aQuestion = a.questionListSummary?.find((q) => q.id === questionId);
					const bQuestion = b.questionListSummary?.find((q) => q.id === questionId);
					const aScore = aQuestion ? (aQuestion.assessment.score.grade_1 + aQuestion.assessment.score.grade_2) / 2 : 0;
					const bScore = bQuestion ? (bQuestion.assessment.score.grade_1 + bQuestion.assessment.score.grade_2) / 2 : 0;
					return bScore - aScore;
				}
				default:
					return 0;
			}
		});

		// Map sorted sessions to timeline segments
		return sortedSessions.map((session): TimelineSegment => {
			let value: string | number;
			let variant: "high" | "medium" | "low" | "new";

			switch (type) {
				case "satisfaction": {
					const score = session.satisfaction?.score || 0;
					value = score;
					variant = score >= 8 ? "high" : score >= 6 ? "medium" : "low";
					break;
				}
				case "purpose": {
					const progress = session.purpose?.progress || "No";
					value = progress;
					variant = progress === "Yes" ? "high" : progress === "Partial" ? "medium" : progress === "New" ? "new" : "low";
					break;
				}
				case "goalProgress": {
					if (!Array.isArray(session.goalProgress)) return { value: 0, variant: "low" };
					const progress = session.goalProgress.map((goal) => goal.goal_status).join(", ") || "No";
					value = progress;
					variant = progress.includes("Yes") ? "high" : progress.includes("Partial") ? "medium" : "low";
					break;
				}
				case "expectations": {
					const outlook = (session.expectations?.outlook || "").toLowerCase();
					value = outlook;
					variant = outlook === "positive" ? "high" : outlook === "mixed" ? "medium" : "low";
					break;
				}
				case "competency": {
					if (!competencyName) {
						value = 0;
						variant = "low";
						break;
					}
					const competency = session.competencyProgress?.find((c) => c.name.toLowerCase() === competencyName.toLowerCase());
					const score = Number(competency?.assessment || 0);
					value = score;
					variant = score >= 8 ? "high" : score >= 6 ? "medium" : "low";
					break;
				}
				case "finalReview": {
					const rating = session.finalReview?.feedback?.rating || 0;
					value = rating;
					variant = rating >= 8 ? "high" : rating >= 6 ? "medium" : "low";
					break;
				}
				case "question": {
					if (!questionId) {
						value = 0;
						variant = "low";
						break;
					}
					const question = session.questionListSummary?.find((q) => q.id === questionId);
					const score = question ? (question.assessment.score.grade_1 + question.assessment.score.grade_2) / 2 : 0;
					value = score;
					variant = score >= 80 ? "high" : score >= 60 ? "medium" : "low";
					break;
				}
				default: {
					value = 0;
					variant = "low";
				}
			}

			return { value, variant };
		});
	};

	function generateReportScoreData(schedules: ScheduleView[], currentScheduleId: string) {
		// Sort schedules by scheduleDate ascending (oldest first)
		const sortedSchedules = [...schedules].sort((a, b) => new Date(a.scheduleDate).getTime() - new Date(b.scheduleDate).getTime());

		// Find the index of the current schedule
		const currentScheduleIndex = sortedSchedules.findIndex((schedule) => schedule.scheduleId === currentScheduleId);

		// If the current schedule is not found, return empty data
		if (currentScheduleIndex === -1)
			return {
				satisfactionData: [],
				satisfactionDetails: [],
				competencyData: {},
				competencyDetails: {}
			};

		// Include all schedules up to and including the current one
		const relevantSchedules = sortedSchedules.slice(0, currentScheduleIndex + 1);

		// Satisfaction data with details
		const satisfactionDetails = relevantSchedules
			.filter((schedule) => schedule.studentSatisfaction && typeof schedule.studentSatisfaction.average_score === "number")
			.map((schedule) => ({
				date: schedule.scheduleDate,
				value: schedule.studentSatisfaction!.average_score,
				scheduleDescription: schedule.scheduleDescription
			}));

		const satisfactionData = satisfactionDetails.map((detail) => detail.value);

		// Competency data with details
		// Extract all unique competency names (case-insensitive and ignore hyphens)
		const competencyNames = Array.from(
			new Set(
				relevantSchedules.flatMap((schedule) =>
					schedule.competencyProgress && Array.isArray(schedule.competencyProgress)
						? schedule.competencyProgress.map((competency) => normalizeCompetencyName(competency.name))
						: []
				)
			)
		);

		// For each competency, extract assessment scores
		const competencyData: { [key: string]: number[] } = {};
		const competencyDetails: { [key: string]: ReportScoreDataPoint[] } = {};

		competencyNames.forEach((name) => {
			const details = relevantSchedules
				.map((schedule) => {
					const competency = schedule.competencyProgress?.find((c) => normalizeCompetencyName(c.name) === name);
					if (competency && typeof competency.average_assessment === "number") {
						return {
							date: new Date(schedule.scheduleDate),
							value: competency.average_assessment,
							scheduleDescription: schedule.scheduleDescription
						};
					}
					return null;
				})
				.filter((detail): detail is { date: Date; value: number; scheduleDescription: string } => detail !== null);

			competencyDetails[name] = details;
			competencyData[name] = details.map((detail) => detail.value);
		});

		return { satisfactionData, satisfactionDetails, competencyData, competencyDetails };
	}

	const fetchUserSessionData = async (schedule: ScheduleView) => {
		if (!schedule) {
			return;
		}

		try {
			setIsLoadingUserSessions(true);
			const sessions = await SessionService.getSubmittedSessionsByScheduleId(schedule.scheduleId);

			// Convert array to map with userId as key
			const sessionsMap = sessions.reduce<{ [key: string]: SessionView }>((acc, session) => {
				if (session.userId) {
					acc[session.userId] = session;
				}
				return acc;
			}, {});

			setUserSessions(sessionsMap);
		} catch (error) {
			console.error("Error fetching submitted sessions:", error);
		} finally {
			setIsLoadingUserSessions(false);
		}
	};

	const handleCancelEdit = async () => {
		if (originalFlaggedComments && schedule) {
			// Restore the original state
			setSchedules((prevSchedules) =>
				prevSchedules.map((s) => (s.scheduleId === schedule.scheduleId ? { ...s, flaggedComments: originalFlaggedComments } : s))
			);
		}
		setIsEditingCheckbox(false);
		setOriginalFlaggedComments(null);
	};

	const handleSaveChanges = async () => {
		try {
			if (!schedule) return;
			// Call the API to update the schedule with the new flagged comments
			await ScheduleService.updateFlaggedComments(schedule.scheduleId, schedule.flaggedComments);
			setIsEditingCheckbox(false);
			// Refresh the schedule data
			if (assignmentId) {
				const updatedSchedules = await ScheduleService.getByAssignmentId(assignmentId);
				setSchedules(updatedSchedules);
			}
		} catch (error) {
			console.error("Error saving checked comments:", error);
		}
	};

	const handleToggleExpandedTables = (key: string) => {
		setExpandedTables((prev) => ({ ...prev, [key]: !prev[key] }));
	};

	return (
		<div>
			<BackButton />
			<div className="flex items-center justify-between mb-4">
				<h2 className="text-2xl md:text-3xl font-bold text-[#16013e]">
					{t("topSection.aggregateSummaryTitle")}: {assignment?.assignmentTitle}
				</h2>

				<div className="flex items-center space-x-2">
					{TokenManager.hasAdminClearance() && assignment && (
						<Button
							variant="outline"
							onClick={() => handleEditAssignment(assignment.assignmentId, assignment.experienceType)}
							aria-label={`Edit assignment: ${assignment.assignmentTitle}`}
						>
							<PencilIcon className="h-4 w-4 mr-2" aria-hidden="true" />
							{t("topSection.assignmentDetailsDropdown.editAssignmentButton")}
						</Button>
					)}
					{assignment && (
						<Popover>
							<PopoverTrigger asChild>
								<Button variant="outline" aria-label="Open share assignment dialog">
									<ShareIcon className="h-4 w-4 mr-2" aria-hidden="true" />
									{t("topSection.assignmentDetailsDropdown.shareAssignmentButton")}
								</Button>
							</PopoverTrigger>
							<PopoverContent className="w-80">
								<div className="space-y-4">
									<h4 className="font-medium leading-none">{t("topSection.assignmentDetailsDropdown.shareAssignmentButton")}</h4>
									{assignment.status === "Drafts" ? (
										<p className="text-sm text-muted-foreground">
											{t("topSection.assignmentDetailsDropdown.shareAssignmentButtonDisabled")}
										</p>
									) : (
										<>
											<div className="space-y-2">
												<Label htmlFor="link">{t("topSection.assignmentDetailsDropdown.assignmentLink")}</Label>
												<div className="flex items-center space-x-2">
													<Input type="text" id="link" value={assignment.getSharedLink()} readOnly />
													<Button
														variant="outline"
														size="icon"
														onClick={() => copyToClipboard(assignment.getSharedLink())}
														aria-label="Copy assignment link to clipboard"
													>
														<ClipboardIcon className="h-4 w-4" aria-hidden="true" />
													</Button>
												</div>
											</div>
											<p className="text-sm text-muted-foreground">
												{t("topSection.assignmentDetailsDropdown.assignmentLinkDescription")}
											</p>
										</>
									)}
								</div>
							</PopoverContent>
						</Popover>
					)}
				</div>
			</div>
			<Button
				variant="ghost"
				size="sm"
				className="p-0 mb-4"
				onClick={() => setIsDetailsVisible(!isDetailsVisible)}
				aria-expanded={isDetailsVisible}
				aria-label={`${isDetailsVisible ? "Hide" : "Show"} assignment details`}
			>
				{isDetailsVisible
					? t("topSection.assignmentDetailsDropdown.hideAssignmentDetails")
					: t("topSection.assignmentDetailsDropdown.showAssignmentDetails")}
				{isDetailsVisible ? (
					<ChevronUpIcon className="h-4 w-4 ml-1" aria-hidden="true" />
				) : (
					<ChevronDownIcon className="h-4 w-4 ml-1" aria-hidden="true" />
				)}
			</Button>
			{assignment &&
				isDetailsVisible && ( // Conditional rendering based on toggle state
					<div className="text-gray-600 mb-16">
						<p className="mb-2 text-sm font-semibold">
							<span className="font-semibold">{t("topSection.assignmentDetailsDropdown.expanded.course")}: </span>
							<span className="font-normal">{assignment.courseName}</span>
						</p>
						<p className="mb-2 text-sm font-semibold">
							<span className="font-semibold">{t("topSection.assignmentDetailsDropdown.expanded.experienceType")}: </span>
							<span className="font-normal">{assignment.experienceType}</span>
						</p>
						<p className="mb-2 text-sm font-semibold">
							<span className="font-semibold">{t("topSection.assignmentDetailsDropdown.expanded.assignmentCode")}: </span>
							<span className="font-normal">{assignment.assignmentCode}</span>
						</p>
						<p className="text-sm">{assignment.assignmentDescription}</p>
						<div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-6 my-8">
							<div className="bg-white p-4 border rounded-lg border-gray-200">
								<p className="text-sm font-medium text-gray-500 mb-1">
									{t("topSection.assignmentDetailsDropdown.expanded.totalUsage")}
								</p>
								<p className="text-2xl font-bold text-[#16013e]">{assignmentSessionStats.totalDuration}</p>
							</div>
						</div>
						{assignment.competencies && assignment.competencies.length > 0 && (
							<div className="text-sm mt-4 flex items-center">
								<span className="font-semibold mr-2">{t("topSection.assignmentDetailsDropdown.expanded.competencies")}:</span>
								<div className="font-normal flex flex-wrap">
									{assignment.competencies.map((competency) => (
										<Badge key={competency.id} className="mr-2 border border-[#00a9af]" variant="secondary">
											{competency.name}
										</Badge>
									))}
								</div>
							</div>
						)}
						{debugMode && (
							<>
								<p className="text-sm">
									plGroupIdUrl:{" "}
									<a className="underline text-blue-600" target="_blank" rel="noreferrer" href={assignment.getPlGroupIdUrl()}>
										{assignment.getPlGroupIdUrl()}
									</a>
								</p>
							</>
						)}
					</div>
				)}

			<div>
				{assignmentId && (
					<HorizontalTabs
						tabs={tabs}
						type="admin"
						selectedTab={selectedTab}
						assignmentId={assignmentId}
						userId={null}
						onTabSelect={setSelectedScheduleId}
					/>
				)}
			</div>
			{schedule && (
				<div key={schedule.scheduleId + assignedSessions.length} className="pt-6 mb-4">
					<div className="flex justify-between items-center mb-2">
						{/* <h2 className="text-2xl font-semibold">{schedule.scheduleDescription}</h2> */}
						<div className="flex gap-4 ml-auto">
							{debugMode && (
								<Button
									size="sm"
									onClick={() => generateAggregateData(schedule)}
									disabled={isGeneratingAggregate}
									aria-label="Generate aggregate data for schedule"
								>
									{isGeneratingAggregate ? (
										<>
											<Spinner className="h-4 w-4" />
											{t("report.generating")}...
										</>
									) : (
										t("report.generateAggregateData")
									)}
								</Button>
							)}
							{schedule.aggregateDataId && (
								<Button
									onClick={() => downloadAggregateTemplate(schedule)}
									variant="outline"
									size="sm"
									title="Download Report"
									disabled={isDownloading}
									aria-label="Download aggregate report"
								>
									{isDownloading ? ( // Show spinner when downloading
										<Spinner className="h-4 w-4" />
									) : (
										<ArrowDownTrayIcon className="h-5 w-5 mr-2" />
									)}
									{isDownloading ? (
										t("report.downloading")
									) : (
										<>
											<span className="hidden md:inline">{t("report.downloadReport")}</span>
											<span className="md:hidden">{t("report.download")}</span>
										</>
									)}
								</Button>
							)}
						</div>
					</div>
					{!schedule.aggregateDataId && (
						<div className="mb-4">
							<p className="text-sm text-gray-600">
								{t("report.aggregateReportWillAutoGenerateOn")}{" "}
								{new Date(new Date(schedule.scheduleDate).getTime() + 48 * 60 * 60 * 1000).toLocaleDateString("en-US", {
									weekday: "long",
									year: "numeric",
									month: "long",
									day: "numeric"
								})}
							</p>
						</div>
					)}

					{assignment && (
						<RenderAssignmentSections
							schedule={schedule}
							assignment={assignment}
							assignedSessions={assignedSessions}
							scheduleSessionStats={scheduleSessionStats}
							isEditingCheckbox={isEditingCheckbox}
							handleCancelEdit={handleCancelEdit}
							handleSaveChanges={handleSaveChanges}
							expandedTables={expandedTables}
							handleToggleExpandedTables={handleToggleExpandedTables}
							userSessions={userSessions}
							generateTimelineData={generateTimelineData}
							satisfactionData={satisfactionData}
							satisfactionDetails={satisfactionDetails}
							isLoadingUserSessions={isLoadingUserSessions}
							competencyData={competencyData}
							competencyDetails={competencyDetails}
						/>
					)}

					<div className="mb-6 flex justify-between items-center"></div>
					{assignment && assignedSessions && (
						<AssignedSessionsTable
							key={assignedSessions.length}
							length={assignedSessions.length}
							assignedSessions={assignedSessions}
							onDownloadCSV={downloadAssignedSessionsCSV}
							onAddUser={handleAddUser}
							onEditUser={handleEditUser}
							onRemoveUser={handleRemoveUser}
							onAddExtension={handleAddExtension}
							anonymous={assignment.includeUserIdentification ? null : false}
							assignment={assignment}
						/>
					)}

					{assignment && !assignment.includeUserIdentification && anonymousAssignedSessions && (
						<div className="mt-8 mb-6">
							<AssignedSessionsTable
								key={anonymousAssignedSessions.length}
								length={anonymousAssignedSessions.length}
								assignedSessions={anonymousAssignedSessions}
								onDownloadCSV={downloadAssignedSessionsCSV}
								onAddUser={handleAddUser}
								onEditUser={handleEditUser}
								onRemoveUser={handleRemoveUser}
								onAddExtension={handleAddExtension}
								anonymous={true}
								assignment={assignment}
							/>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default AdminAssignmentPage;
