import axios from "axios";

export interface WebhookConfig {
	id?: string;
	name: string;
	url: string;
	description?: string;
	secret?: string;
	events: string[];
	headers?: Record<string, string>;
	status?: string;
	createdAt?: string;
	updatedAt?: string;
}

// For creating a new webhook
export interface CreateWebhookPayload {
	name: string;
	url: string;
	description?: string;
	events: string[];
	headers?: Record<string, string>;
}

// For updating a webhook
export interface UpdateWebhookPayload {
	name?: string;
	url?: string;
	description?: string;
	events?: string[];
	headers?: Record<string, string>;
	status?: string;
}

// For API responses
export interface WebhookListResponse {
	data: WebhookConfig[];
	total: number;
	limit: number;
	offset: number;
}

// For query parameters
export interface WebhookListParams {
	search?: string;
	events?: string[];
	status?: string;
	limit?: number;
	offset?: number;
}
export interface DeliveryLog {
	id: string;

	payload: Record<string, unknown>;
	response: Record<string, unknown>;
	statusCode: number;
	errorMessage?: string;
	isSuccess: boolean;
	retryCount: number;
	createdAt: string;
}

export class WebhookConfigService {
	private static baseUrl = `${process.env.REACT_APP_BACKEND_URL}/api/webhook-config`;

	public static async createWebhook(payload: CreateWebhookPayload): Promise<WebhookConfig> {
		const response = await axios.post(`${this.baseUrl}/`, payload);
		return response.data;
	}

	public static async updateWebhook(id: string, payload: UpdateWebhookPayload): Promise<WebhookConfig> {
		const response = await axios.put(`${this.baseUrl}/${id}`, payload);
		return response.data;
	}

	public static async deleteWebhook(id: string): Promise<void> {
		await axios.delete(`${this.baseUrl}/${id}`);
	}

	public static async getWebhook(id: string): Promise<WebhookConfig> {
		const response = await axios.get(`${this.baseUrl}/${id}`);
		return response.data;
	}

	public static async getWebhooks(params: WebhookListParams): Promise<WebhookConfig[]> {
		const response = (await axios.get(`${this.baseUrl}/`, { params })) as WebhookListResponse;
		return response.data;
	}

	public static async testWebhook(webhookConfigId: string): Promise<void> {
		await axios.post(`${this.baseUrl}/${webhookConfigId}/test`);
	}

	public static async getWebhookDeliveries(webhookConfigId: string): Promise<DeliveryLog[]> {
		const response = await axios.get(`${this.baseUrl}/${webhookConfigId}/deliveries`);
		return response.data;
	}

	public static async getWebhookDelivery(webhookConfigId: string, deliveryId: string): Promise<DeliveryLog> {
		const response = await axios.get(`${this.baseUrl}/${webhookConfigId}/deliveries/${deliveryId}`);
		return response.data;
	}
}
