import React, { useEffect, useState } from "react";
import { SidebarNavItem } from "../../shadcn-ui/SidebarNavItem";
import { Sidebar } from "../../shadcn-ui/Sidebar";
import logo from "../../assets/images/logo.png";
import logoCollapsed from "../../assets/images/InStageLogo2.png";
import { SidebarSection } from "../../shadcn-ui/SidebarSection";
import {
	BriefcaseBusinessIcon,
	CalendarDaysIcon,
	ChevronLeft,
	ChevronRight,
	EyeIcon,
	Feather,
	PhoneIcon,
	PlusCircleIcon,
	SearchIcon,
	WorkflowIcon
} from "lucide-react";
import { VscSettings } from "react-icons/vsc";
import { GoArrowSwitch } from "react-icons/go";
import { Button } from "../../shadcn-ui/Button";
import { useAppContext } from "../../../contexts/AppContext";
import TokenManager from "../../../services/TokenManager";
import {
	HomeIcon,
	DocumentTextIcon,
	ClipboardDocumentListIcon,
	UserIcon,
	ChatBubbleLeftRightIcon,
	Cog6ToothIcon,
	ChevronDoubleRightIcon,
	EnvelopeIcon,
	ArrowPathIcon,
	CurrencyDollarIcon,
	LanguageIcon
} from "@heroicons/react/24/outline";
import { GrLanguage } from "react-icons/gr";
import { PiStudentFill, PiUser } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import { Switch } from "../../shadcn-ui/Switch";
import FronteggProfile from "../FronteggProfile";

import { GrCode } from "react-icons/gr";
import { toast } from "../../../hooks/useToast";
import { PromptService } from "../../../services/PromptService";
import LanguageModal from "../LanguageModal";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { useTranslation } from "../UseTranslation";
import { SidebarButtons } from "./SideBarComponent";

export const CreateSidebarButtons = ({
	debugMode,
	isStudentMode,
	setDebugMode,
	setIsStudentMode,
	handleStudentModeToggle,
	handleLanguageModalOpen,
	handleRefreshPrompts,
	handleScroll,
	navigate,
	t,
	isLanguageFeatureFlagEnabled,
	hasSelfServeAccessFeatureFlagEnabled,
	hasAssignmentsFeatureFlagEnabled
}: {
	debugMode: boolean;
	isStudentMode: boolean;
	setDebugMode: (value: boolean) => void;
	setIsStudentMode: (value: boolean) => void;
	handleStudentModeToggle: (value: boolean) => void;
	handleLanguageModalOpen: () => void;
	handleRefreshPrompts: () => Promise<void>;
	handleScroll: (id: string) => void;
	navigate: (path: string) => void;
	t: (key: string) => string;
	isLanguageFeatureFlagEnabled: boolean;
	hasSelfServeAccessFeatureFlagEnabled: boolean;
	hasAssignmentsFeatureFlagEnabled: boolean;
}): SidebarButtons => ({
	mainNav: [
		{
			icon: HomeIcon,
			label: "Home",
			href: "/user",
			visible: true
		}
	],
	assignments: [
		{
			icon: DocumentTextIcon,
			label: "Assignments",
			href: "/admin",
			visible: TokenManager.hasFacultyClearance() && hasAssignmentsFeatureFlagEnabled
		}
	],
	assignedCalls: {
		icon: PhoneIcon,
		label: "Assigned Calls",
		visible: true,
		items: [
			{
				icon: ChevronDoubleRightIcon,
				label: "In Progress",
				href: "#",
				visible: true,
				onClick: () => handleScroll("unsubmitted-calls")
			},
			{
				icon: PhoneIcon,
				label: "Available",
				href: "#",
				visible: true,
				onClick: () => handleScroll("available-calls")
			},
			{
				icon: CalendarDaysIcon,
				label: "Upcoming",
				href: "#",
				visible: true,
				onClick: () => handleScroll("upcoming-calls")
			},
			{
				icon: ClipboardDocumentListIcon,
				label: "All Assignments",
				href: "#",
				visible: true,
				onClick: () => handleScroll("all-assignments-id")
			}
		]
	},
	createYourOwn: {
		icon: PiUser,
		label: "Create Your Own",
		items: [
			{ icon: BriefcaseBusinessIcon, label: "Mock Interview", href: "/user/create-interview", visible: true },
			{ icon: DocumentTextIcon, label: "Resume / Cover Letter", href: "user/resume-assist", visible: true },
			{ icon: WorkflowIcon, label: "Career Path", href: "/user/create-career-path", visible: true },
			{ icon: SearchIcon, label: "Job Search", href: "/user/create-job-search", visible: true },
			{ icon: Feather, label: "Reflection", href: "/user/create-reflection", visible: true }
		],
		visible: hasSelfServeAccessFeatureFlagEnabled
	},

	bottomNav: [
		{
			icon: ClipboardDocumentListIcon,
			label: "Session Log",
			href: "/admin/session-log",
			visible: TokenManager.hasFacultyClearance()
		},
		{
			icon: ChatBubbleLeftRightIcon,
			label: "Live Chat Support",
			href: "/support",
			visible: true
		},
		{
			icon: VscSettings,
			label: "Admin Options",
			href: "/admin/settings",
			visible: TokenManager.hasFacultyClearance()
		},
		{
			icon: PiStudentFill,
			label: "Student View",
			customRender: (isMobile?: boolean) => (
				<div className="flex items-center justify-end max-w-auto">
					<Switch checked={isStudentMode} onCheckedChange={handleStudentModeToggle} aria-label={t("Student View")} />
				</div>
			),
			visible: TokenManager.hasFacultyClearance() || sessionStorage.getItem("isStudentMode") === "true",
			onClick: () => {
				handleStudentModeToggle(!isStudentMode);
			}
		},
		{
			icon: EyeIcon,
			label: "InStage Admin",
			customRender: (isMobile?: boolean) => (
				<div className="flex items-center justify-end max-w-auto">
					<Switch checked={debugMode} onCheckedChange={setDebugMode} aria-label={t("InStage Admin")} />
				</div>
			),
			visible: TokenManager.hasInstageAdminClearance(),
			onClick: () => {
				setDebugMode(!debugMode);
				sessionStorage.setItem("isAdminAccess", debugMode.toString());
			}
		},
		{
			icon: GrLanguage,
			label: "Language",
			onClick: handleLanguageModalOpen,
			visible: isLanguageFeatureFlagEnabled
		}
	],
	devOptions: {
		icon: GrCode,
		label: "Dev Tools",
		items: [
			{
				icon: EnvelopeIcon,
				label: "Sent Emails",
				href: "/admin/sent-emails",
				visible: TokenManager.hasInstageAdminClearance() && debugMode
			},
			{
				icon: CurrencyDollarIcon,
				label: "Costs",
				href: "/admin/costs",
				visible: TokenManager.hasInstageAdminClearance() && debugMode
			},
			{
				icon: ArrowPathIcon,
				label: "Refresh Prompts",
				href: "/user/create-career-path",
				visible: TokenManager.hasInstageAdminClearance() && debugMode,
				onClick: handleRefreshPrompts
			}
		],
		visible: TokenManager.hasInstageAdminClearance() && debugMode
	}
});
