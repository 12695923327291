import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { SessionView } from "../../../types/SessionView";
import { SessionService, VapiCallType } from "../../../services/SessionService";
import { AssignedSessionService } from "../../../services/AssignedSessionService";
import { AssignedSessionView } from "../../../types/AssignedSessionView";

import { useAppContext } from "../../../contexts/AppContext";
import { useAuth } from "../../../contexts/AuthContext";

import { NavigateService } from "../../../services/NavigateService";
import { UserAssignmentView } from "../../../types/UserAssignmentView";
import { AssignmentService, JobPosting } from "../../../services/AssignmentService";

import posthog from "posthog-js";

import { isValidJobDetails } from "../../../types/InterviewPayload";

import { ResumeView } from "../../../types/ResumeView";
import { ResumeService } from "../../../services/ResumeService";
import { ExperienceType } from "../../../schemas/ExperienceType";

export const useUserSessionPage = ({
	assignmentId,
	userId,
	scheduleId
}: {
	assignmentId: string | null;
	userId: string | null;
	scheduleId: string | null;
}) => {
	const navigate = useNavigate();
	const { debugMode, checkIfAvailable, activeAssistant, setActiveAssistant, language } = useAppContext();

	const { userId: loggedInUserId, instageUser } = useAuth();
	const resumeAssistFeatureFlag = useFeatureFlagEnabled("resume-assist");
	const resumeAssistTenantFeatureFlag = useFeatureFlagEnabled("resume-assist-tenants");

	const [sessions, setSessions] = useState<SessionView[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isStartingSession, setIsStartingSession] = useState(false);
	const [assignedSessions, setAssignedSessions] = useState<AssignedSessionView[]>([]);
	const [selectedScheduleId, setSelectedScheduleId] = useState<string>(scheduleId || "");
	const [assignment, setAssignment] = useState<UserAssignmentView | null>(null);
	const [isDetailsVisible, setIsDetailsVisible] = useState<boolean>(false);
	const [showResumeOptions, setShowResumeOptions] = useState<boolean>(false);
	const [startNewSession, setStartNewSession] = useState<boolean>(true);
	const [isRegenerating, setIsRegenerating] = useState<boolean>(false);
	const [pendingAction, setPendingAction] = useState<"web" | "phone" | "schedule" | null>(null);
	const [isPlaying, setIsPlaying] = useState(false);
	const [showJobPost, setShowJobPost] = useState(false);
	const [jobTitle, setJobTitle] = useState<string>("");
	const [jobDescription, setJobDescription] = useState<string>("");
	const [isGeneratingJob, setIsGeneratingJob] = useState<boolean>(false);
	const [jobPostings, setJobPostings] = useState<JobPosting[]>([]);
	const [isLoadingJobPosting, setIsLoadingJobPosting] = useState(false);
	const [resumes, setResumes] = useState<ResumeView[]>([]);
	const [selectedResumeId, setSelectedResumeId] = useState<string>("");
	const [selectedResume, setSelectedResume] = useState<ResumeView | null>(null);
	const [showConfidentialityDialog, setShowConfidentialityDialog] = useState(false);

	const selectedAssignedSession = assignedSessions.find((session) => session.scheduleId === selectedScheduleId);

	// Find the index of the selected scheduleId in the assignedSessions array
	const selectedTab = assignedSessions.findIndex((session) => session.scheduleId === selectedScheduleId) || 0;
	// Assigned session data

	// Attempted session data
	const selectedUserSession = sessions.find((session) => session.scheduleId === selectedScheduleId) || null;

	const isJobGenerated = selectedAssignedSession ? isValidJobDetails(selectedAssignedSession) : false;

	useEffect(() => {
		if (assignmentId && userId) {
			SessionService.getUserSessionsViewByUserIdAndAssignmentId(userId, assignmentId)
				.then((sessions) => {
					setSessions(sessions);

					setIsLoading(false);
				})
				.catch((error) => {
					console.error("Error getting user session view:", error);
					setIsLoading(false);
				});
		}
		if (assignmentId && userId) {
			AssignedSessionService.getByAssignmentIdAndUserId(assignmentId, userId)
				.then((assignedSessions) => {
					setAssignedSessions(assignedSessions);
				})
				.catch((error) => {
					console.error("Error getting user assigned sessions:", error);
				});
		}
		if (assignmentId && userId) {
			AssignmentService.getUserViewById(assignmentId)
				.then((assignment) => {
					setAssignment(assignment);
				})
				.catch((error) => {
					console.error("Error getting assignment:", error);
				});
		}
	}, [assignmentId, userId]);

	useEffect(() => {
		if (debugMode) {
			console.log("assignment", assignment);
			console.log("assignedSessions", assignedSessions);
			console.log("sessionsViews", sessions);
		}
	}, [debugMode, assignment, assignedSessions, sessions]);

	useEffect(() => {
		// Set the selectedScheduleId to the first session's scheduleId if not set
		if (!scheduleId && assignedSessions.length > 0) {
			setSelectedScheduleId(assignedSessions[0].scheduleId);
		}
	}, [scheduleId, assignedSessions]);

	useEffect(() => {
		// fetch user resumes for the select drop down if the assignment type is interview
		const fetchUserResumes = async () => {
			if (!userId) return;
			try {
				const response = await ResumeService.fetchUserResumes();
				setResumes(response);
			} catch (error) {
				console.error("Error fetching user resumes:", error);
			}
		};
		if (assignment?.experienceType === ExperienceType.INTERVIEW) {
			fetchUserResumes();
		}
	}, [userId, assignment?.experienceType]);

	useEffect(() => {
		// Reset resume options when selected schedule changes
		setShowResumeOptions(false);
	}, [selectedScheduleId]);

	useEffect(() => {
		// Set initially selected resume from assigned session if it exists
		if (selectedAssignedSession?.resumeId) {
			setSelectedResumeId(selectedAssignedSession.resumeId);
			const resume = resumes.find((r) => r.id === selectedAssignedSession.resumeId);
			setSelectedResume(resume || null);
		}
	}, [selectedAssignedSession?.resumeId, resumes]);

	useEffect(() => {
		if (selectedAssignedSession && debugMode) {
			console.log("selectedAssignedSession", selectedAssignedSession);
		}
	}, [selectedAssignedSession, debugMode]); // Log only when selectedAssignedSession changes

	useEffect(() => {
		if (selectedUserSession && debugMode) {
			console.log("selectedUserSession", selectedUserSession);
		}
	}, [selectedUserSession, debugMode]);

	const handleTabSelect = (scheduleId: string) => {
		setSelectedScheduleId(scheduleId);

		// The useEffect above will handle resetting showResumeOptions
	};

	const handleResumeSelection = async (value: string | null) => {
		if (value === "create-new") {
			navigate("/user/resume-assist");
			return;
		}

		setSelectedResumeId(value || "");
		const resume = value ? resumes.find((r) => r.id === value) : null;
		setSelectedResume(resume || null);

		try {
			// Update the resume for all assigned sessions in this assignment
			await AssignmentService.updateResumeForAssignment(assignmentId!, value);
		} catch (error) {
			console.error("Error updating resume for assignment:", error);
			// Optionally show an error message to the user
		}
	};

	const handleGenerateJobDetails = async () => {
		if (!assignmentId || !jobTitle.trim() || !jobDescription.trim()) {
			return;
		}

		setIsGeneratingJob(true);
		try {
			await AssignmentService.generateJobDetails(assignmentId, {
				jobTitle: jobTitle.trim(),
				jobDescription: jobDescription.trim()
			});
			// Refresh the assignment data
			const updatedAssignment = await AssignmentService.getUserViewById(assignmentId!);
			setAssignment(updatedAssignment);

			// Refresh assigned sessions to get updated job details
			const updatedAssignedSessions = await AssignedSessionService.getByAssignmentIdAndUserId(assignmentId!, userId!);
			setAssignedSessions(updatedAssignedSessions);
		} catch (error) {
			console.error("Error generating job details:", error);
		} finally {
			setIsGeneratingJob(false);
		}
	};

	const searchJobs = async (title: string) => {
		setJobPostings([]);
		try {
			const jobPostingList = await AssignmentService.searchJobPostings(title, language);
			setJobPostings(jobPostingList);
		} catch (error) {
			console.error("Error fetching job postings:", error);
			setJobPostings([]);
		}
	};

	const handleJobTitleChange = (title: string) => {
		setJobTitle(title);
		searchJobs(title);
	};
	const handleJobDescriptionChange = (description: string) => {
		setJobDescription(description);
	};
	const handleRestartClick = () => {
		setShowResumeOptions(true);
		setStartNewSession(true);
		try {
			posthog.capture("MINOR: Restarted Session", {
				userId: instageUser?.fronteggId,
				timestamp: new Date().toISOString()
			});
		} catch (err) {
			console.error("Error capturing event 'MINOR: Restarted Session':", err);
		}
	};

	const handleCancelResumeSession = () => {
		setShowResumeOptions(false);
		setStartNewSession(false);
	};

	const handleConfidentialityDialogOnOpenChange = (open: boolean) => {
		if (!open) {
			setPendingAction(null);
		}

		setShowConfidentialityDialog(open);
	};

	async function handleInitiateSession(mode: "phone" | "web" | "schedule") {
		handleConfidentialityDialogOnOpenChange(true);
		setPendingAction(mode);
		checkMicrophonePermission();
	}

	async function checkMicrophonePermission() {
		const permission = await navigator.permissions.query({ name: "microphone" as PermissionName });

		if (permission.state === "granted") {
			return true;
		} else if (permission.state === "prompt") {
			// Request microphone access
			try {
				await navigator.mediaDevices.getUserMedia({ audio: true });
				// console.log("Microphone access granted");
				posthog.capture("MINOR: Microphone Permission Granted", { userId: instageUser?.fronteggId, timestamp: new Date().toISOString() });
				return true; // Access granted
			} catch (error) {
				// console.error("Microphone access denied:", error);
				posthog.capture("MINOR: Microphone Permission Denied", { userId: instageUser?.fronteggId, timestamp: new Date().toISOString() });
				return false; // Access denied
			}
		}
		console.error("Microphone access denied:");
		return false; // Access denied or not granted
	}

	async function startSession() {
		setShowConfidentialityDialog(false);

		if (pendingAction === "phone") {
			try {
				posthog.capture("MAJOR_EVENT: Starting Phone Call Event", { userId: instageUser?.fronteggId, timestamp: new Date().toISOString() });
			} catch (err) {
				console.error("Error capturing event 'MAJOR_EVENT: Starting Phone Call Event':", err);
			}
			await startPhoneCall();
		} else if (pendingAction === "web") {
			try {
				console.log("Capturing Starting Web Call Event");
				posthog.capture("MAJOR_EVENT: Starting Web Call Event", { userId: instageUser?.fronteggId, timestamp: new Date().toISOString() });
			} catch (err) {
				console.error("Error capturing event 'MAJOR_EVENT: Starting Web Call':", err);
			}
			await startWebCall();
		} else if (pendingAction === "schedule") {
			if (!selectedAssignedSession?.assignedSessionId) return;
			NavigateService.navToScheduleCall(selectedAssignedSession?.assignedSessionId);
		}

		setPendingAction(null);
	}

	async function startWebCall() {
		setIsStartingSession(true);

		setShowConfidentialityDialog(false);

		try {
			if (!loggedInUserId || !assignmentId) return;

			// // Check if the user needs onboarding

			let sessionId = null;
			let session: SessionView | null = null;

			if (!startNewSession && selectedAssignedSession?.sessionId) {
				sessionId = selectedAssignedSession?.sessionId;
			} else {
				const result = await SessionService.startSession(
					loggedInUserId,
					VapiCallType.WebCall,
					assignmentId,
					selectedScheduleId,
					activeAssistant.voice_provider,
					activeAssistant.voice_id,
					activeAssistant.name,
					activeAssistant.model
				);
				sessionId = result.sessionId;
				session = result;
			}

			const isAvailable = await checkIfAvailable(loggedInUserId);
			console.log("isAvailable", isAvailable);
			if (isAvailable) {
				NavigateService.navToStartSession(assignmentId, loggedInUserId, sessionId);
				// try {
				// 	if (!session || !session.clientId) {
				// 		return;
				// 	}
				// 	const client = await UserService.getClientById(session.clientId);
				// } catch (error) {
				// 	console.error("Error fetching client by ID:", error);
				// }
				try {
					// console.log("Capturing Started Web Call Event");
					// console.log("instageUser", instageUser);
					// console.log("instageUser?.fronteggId", instageUser?.fronteggId);
					// console.log("instageUser?.tenantId", instageUser?.tenantId);
					const printObject = {
						fronteggId: instageUser?.fronteggId || "", // need frontegg id
						userId: instageUser?.id || "",
						tenantId: instageUser?.tenantId || "", // need tenant id
						clientId: instageUser?.clientId || "",
						timestamp: new Date().toISOString(),
						sessionId: session?.sessionId || "",
						assignmentId: session?.assignmentId || "",
						activityType: session?.activityType || "",
						plGroupId: session?.plGroupId?.toString() || "",
						scheduleId: session?.scheduleId || "",
						sessionType: session?.sessionType || "",
						experienceType: session?.experienceType || ""
					};
					// console.log("capture data: ", JSON.stringify(printObject, null, 2));
					posthog.capture("MAJOR_EVENT: Started Web Call", printObject);
				} catch (err) {
					console.error("Error capturing event 'M.E. Started Web Call':", err);
				}
			} else {
				try {
					posthog.capture("MAJOR_EVENT: Web Call Full. Added To Queue", {
						userId: instageUser?.fronteggId,
						timestamp: new Date().toISOString(),
						sessionId
					});
				} catch (err) {
					console.error("Error capturing event 'M.E. Web Call Full. Added To Queue':", err);
				}
				NavigateService.navToQueuePage(assignmentId, loggedInUserId, sessionId);
			}
		} catch (error) {
			console.error("Failed to start session:", error);
			// Handle error (e.g., show an error message to the user)
		} finally {
			setIsStartingSession(false);
		}
	}
	async function startPhoneCall() {
		if (!loggedInUserId || !assignmentId) return;
		let sessionId = null;
		let session: SessionView | null = null;
		console.log("startPhoneCall");

		if (!startNewSession && selectedAssignedSession?.sessionId) {
			sessionId = selectedAssignedSession?.sessionId;
		} else {
			const result = await SessionService.startSession(
				loggedInUserId,
				VapiCallType.OutboundPhoneCall,
				assignmentId,
				selectedScheduleId,
				activeAssistant.voice_provider,
				activeAssistant.voice_id,
				activeAssistant.name,
				activeAssistant.model
			);
			sessionId = result.sessionId;
			session = result;
		}

		try {
			// console.log("Capturing Started Web Call Event");
			// console.log("instageUser", instageUser);
			// console.log("instageUser?.fronteggId", instageUser?.fronteggId);
			// console.log("instageUser?.tenantId", instageUser?.tenantId);
			const printObject = {
				fronteggId: instageUser?.fronteggId || "", // need frontegg id
				userId: instageUser?.id || "",
				tenantId: instageUser?.tenantId || "", // need tenant id
				clientId: instageUser?.clientId || "",
				timestamp: new Date().toISOString(),
				sessionId: session?.sessionId || "",
				assignmentId: session?.assignmentId || "",
				activityType: session?.activityType || "",
				plGroupId: session?.plGroupId?.toString() || "",
				scheduleId: session?.scheduleId || "",
				sessionType: session?.sessionType || "",
				experienceType: session?.experienceType || ""
			};
			// console.log("capture data: ", JSON.stringify(printObject, null, 2));
			posthog.capture("MAJOR_EVENT: Started Phone Call", printObject);
		} catch (err) {
			console.error("Error capturing event 'M.E. Started Phone Call':", err);
		}

		NavigateService.navToPhoneCallSession(sessionId);
		// }
	}

	async function regenerateSummary() {
		const currentSelectedSession = sessions.find((session) => session.scheduleId === selectedScheduleId);
		if (!currentSelectedSession) return;

		setIsRegenerating(true);
		try {
			console.log("regenerating summary");
			const updatedSession = await SessionService.regenerateSummary(currentSelectedSession.sessionId);
			console.log("updatedSession", updatedSession);
			// Update the sessions array with the new session data
			setSessions((prevSessions) => prevSessions.map((session) => (session.sessionId === updatedSession.sessionId ? updatedSession : session)));
		} catch (error) {
			console.error("Error regenerating summary:", error);
			// Handle error (e.g., show an error message to the user)
		} finally {
			console.log("regenerating summary finished");
			setIsRegenerating(false);
		}
	}

	const handleToggleJobPost = () => {
		setShowJobPost(!showJobPost);
	};

	const handleJobSelection = (option: JobPosting) => {
		setJobTitle(option.title);
		setJobDescription(option.summary || "");
	};

	return {
		debugMode,
		checkIfAvailable,
		activeAssistant,
		setActiveAssistant,
		loggedInUserId,
		instageUser,
		resumeAssistFeatureFlag,
		resumeAssistTenantFeatureFlag,
		sessions,
		isLoading,
		isStartingSession,
		assignedSessions,
		selectedScheduleId,
		assignment,
		isDetailsVisible,
		showResumeOptions,
		startNewSession,
		isRegenerating,
		showConfidentialityDialog,

		pendingAction,
		isPlaying,
		setIsPlaying,
		showJobPost,
		jobTitle,
		jobDescription,
		isGeneratingJob,
		jobPostings,
		isLoadingJobPosting,
		resumes,
		selectedResumeId,
		selectedResume,
		selectedTab,
		selectedAssignedSession,
		selectedUserSession,
		isJobGenerated,

		handleTabSelect,
		handleResumeSelection,
		handleGenerateJobDetails,
		handleJobTitleChange,
		handleConfidentialityDialogOnOpenChange,
		handleRestartClick,
		handleCancelResumeSession,
		handleInitiateSession,
		handleJobDescriptionChange,
		handleToggleJobPost,
		handleJobSelection,
		startSession,
		regenerateSummary
	};
};
