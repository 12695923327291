import React, { useState } from "react";
import ReportCard from "../Utility/ReportCard";
import { BookOpenIcon, SparklesIcon, UsersIcon } from "@heroicons/react/24/solid";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import { Badge } from "../shadcn-ui/Badge";
import { Button } from "../shadcn-ui/Button";
import StudentDetailsTable from "../AdminPages/StudentDetailsTable";
import { SessionView } from "../../types/SessionView";
import { useTranslation } from "../Utility/UseTranslation";

interface CareerPathSummaryProps {
	overview: string;
	successfulChoice: {
		score: number;
		summary: string;
	};
	userSessions?: { [key: string]: SessionView };
	onNavigateToReport: (assignmentId: string, userId: string, scheduleId: string) => void;
}

const CareerPathSummary: React.FC<CareerPathSummaryProps> = ({ overview, successfulChoice, userSessions, onNavigateToReport }) => {
	const { t } = useTranslation("adminAssignmentPage");
	const [expandedTables, setExpandedTables] = useState<{ [key: string]: boolean }>({});

	const getBadgeVariant = (score: number) => {
		if (score >= 80) return "high";
		if (score >= 60) return "medium";
		return "low";
	};

	return (
		<div className="space-y-6">
			{/* Successful Choice Section */}
			<ReportCard
				title={t("cards.finalizedCareerPath")}
				icon={<SparklesIcon className="h-5 w-5 text-[#16013e]" />}
				averageScore={successfulChoice.score}
				isPercentage={true}
			>
				<div className="space-y-4">
					<p className="text-gray-600">{successfulChoice.summary}</p>
					{userSessions && (
						<>
							<Button
								variant="outline"
								size="sm"
								onClick={() => setExpandedTables((prev) => ({ ...prev, successfulChoice: !prev.successfulChoice }))}
								className={`flex items-center space-x-2 mt-8 ${expandedTables["successfulChoice"] ? "mb-4" : ""}`}
								aria-expanded={expandedTables["successfulChoice"]}
								aria-label={`${
									expandedTables["successfulChoice"] ? t("cards.hideSources") : t("cards.showSources")
								} career path selection details`}
							>
								<UsersIcon className="h-4 w-4" />
								<span>{expandedTables["successfulChoice"] ? t("cards.hideSources") : t("cards.showSources")}</span>
								{expandedTables["successfulChoice"] ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />}
							</Button>
							{expandedTables["successfulChoice"] && userSessions && (
								<StudentDetailsTable
									sessions={Object.values(userSessions)}
									type="finalizedCareerPath"
									onNavigateToReport={onNavigateToReport}
								/>
							)}
						</>
					)}
				</div>
			</ReportCard>
		</div>
	);
};

export default CareerPathSummary;
