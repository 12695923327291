export const ExtraUtilityLocale = {
	en: {
		noResults: "No results.",
		backButton: {
			text: "Back"
		},
		activity: {
			WaitingForGuide: "Waiting for your guide...",
			ConnectingToAssistant: "Connecting to your AI Assistant...",
			ReadyToConnect: "Ready to connect...",
			connect: "Connect",
			leaveQueue: "Leave Queue",
			TimeLeft: "Time left before auto-cancel",
			seconds: "seconds",
			GeneratingSummary: "Generating summary...",
			showTranscript: "Show Transcript",
			transcript: "Transcript",
			endCall: "End Call",
			resumeCall: "Resume Call",
			areYouSure: "Are you sure?",
			enableMicrophone: "Enable Microphone",
			clickTryAgainButtonBelow: "Click the button below to try again.",
			tryAgain: "Try Again",
			toEnableYourMicrophone: "To enable your Microphone:",
			checkYourBrowserSettingsPermissions: "Check your browser settings/permissions",
			selectAllowForMicrophoneAccess: "Select 'Allow' for microphone access"
		},
		defaultDialog: {
			title: "Are you sure you want to leave?",
			description: "Your progress will be lost if you exit this page.",
			leave: "Leave",
			cancel: "Cancel"
		},
		confidentialityDialog: {
			title: "Confidentiality and Data Sharing",
			reminderTitle: "Confidentiality Reminder",
			reminderText: "It is your responsibility to protect the privacy and confidentiality of your employer, colleagues, and your own personal",
			sensitiveInfo: "Do not disclose any sensitive details",
			reminderContinued: "related to the company, its operations, employees, or your own personal information without explicit permission.",
			dataTitle: "Data Sharing",
			settingsIntro: "For this assignment, your administrators have set the following data sharing settings:",
			transcriptShared: "Full Transcript will be shared",
			transcriptNotShared: "Full Transcript will not be shared",
			audioShared: "Audio Recording will be shared",
			audioNotShared: "Audio Recording will not be shared",
			nameShared: "First and last name will be shared",
			nameNotShared: "First and last name will not be shared",
			disagree: "Disagree",
			agreeLabel: "Agree to Confidentiality",
			agreeContinue: "Agree and Continue"
		},
		phoneVerification: {
			title: "Verification Required",
			description: "Please enter your phone number",
			verificationCode: "Verification Code",
			disclaimer: {
				title: "Text Message Disclaimer",
				text: "I agree to receive a single one-time verification code via SMS",
				terms: "Terms of Service",
				privacy: "Privacy Policy",
				standardMessage: "Standard message and data rates may apply.",
				verifyNumber: "Verify Number",
				cancel: "Cancel",
				verify: "Verify"
			},
			cancel: "Cancel",
			verifyNumber: "Verify Number"
		},
		scheduleCall: {
			loading: "Loading...",
			bookingSuccess: "Booking Success",
			bookingSuccessMessage: "Your booking has been confirmed. Please check your email for the details.",
			selectAvailableDate: "Select available date",
			name: "Name",
			email: "Email",
			phone: "Phone",
			selectTimeSlot: "Select a time slot",
			bookCall: "Book Call"
		},
		frontEggBottomButton: {
			logout: "Logout",
			account: "Account"
		}
	},
	fr: {
		noResults: "Aucun résultat.",
		backButton: {
			text: "Retour"
		},
		activity: {
			WaitingForGuide: "En attente de votre guide...",
			ConnectingToAssistant: "Connexion à votre assistant IA...",
			ReadyToConnect: "Prêt à connecter...",
			connect: "Connecter",
			leaveQueue: "Quitter la file d'attente",
			TimeLeft: "Temps restant avant auto-annulation",
			seconds: "secondes",
			GeneratingSummary: "Génération de résumé...",
			showTranscript: "Afficher le Transcript",
			transcript: "Transcript",
			endCall: "Terminer",
			resumeCall: "Reprendre",
			areYouSure: "Êtes-vous sûr?",
			enableMicrophone: "Activer le Microphone",
			clickTryAgainButtonBelow: "Cliquez sur le bouton ci-dessous pour réessayer.",
			tryAgain: "Réessayer",
			checkYourBrowserSettingsPermissions: "Vérifiez vos paramètres/autorisations dans le navigateur",
			selectAllowForMicrophoneAccess: "Sélectionnez Allow pour l'accès au microphone",
			toEnableYourMicrophone: "Pour activer votre Microphone:"
		},
		defaultDialog: {
			title: "Êtes-vous sûr de vouloir quitter?",
			description: "Votre progression sera perdue si vous quittez cette page.",
			leave: "Quitter",
			cancel: "Annuler"
		},
		confidentialityDialog: {
			title: "Confidentialité et Partage des Données",
			reminderTitle: "Rappel de Confidentialité",
			reminderText:
				"Il est de votre responsabilité de protéger la confidentialité de votre employeur, de vos collègues et de vos informations personnelles",
			sensitiveInfo: "Ne divulguez aucune information sensible",
			reminderContinued: "concernant l'entreprise, ses opérations, ses employés ou vos informations personnelles sans autorisation explicite.",
			dataTitle: "Partage des Données",
			settingsIntro: "Pour cette affectation, vos administrateurs ont défini les paramètres de partage suivants :",
			transcriptShared: "La transcription complète sera partagée",
			transcriptNotShared: "La transcription complète ne sera pas partagée",
			audioShared: "L'enregistrement audio sera partagé",
			audioNotShared: "L'enregistrement audio ne sera pas partagé",
			nameShared: "Le prénom et le nom seront partagés",
			nameNotShared: "Le prénom et le nom ne seront pas partagés",
			disagree: "Refuser",
			agreeLabel: "Accepter la Confidentialité",
			agreeContinue: "Accepter et Continuer"
		},
		phoneVerification: {
			title: "Verification Requise",
			description: "Veuillez entrer votre numéro de téléphone",
			verificationCode: "Code de Vérification",
			disclaimer: {
				title: "Avis de Text Message",
				text: "Je suis d'accord pour recevoir un seul code de vérification unique via SMS",
				terms: "Conditions d'Utilisation",
				privacy: "Politique de Confidentialité",
				standardMessage: "Des frais de message et de données peuvent s'appliquer.",
				verifyNumber: "Vérifier le Numéro",
				cancel: "Annuler",
				verify: "Vérifier"
			},
			cancel: "Annuler",
			verifyNumber: "Vérifier le Numéro"
		},
		scheduleCall: {
			loading: "Chargement...",
			bookingSuccess: "Réservation Réussie",
			bookingSuccessMessage: "Votre réservation a été confirmée. Veuillez vérifier votre email pour les détails.",
			selectAvailableDate: "Sélectionner une date disponible",
			name: "Nom",
			email: "Email",
			phone: "Téléphone",
			selectTimeSlot: "Sélectionner un créneau horaire",
			bookCall: "Réserver l'appel"
		},
		frontEggBottomButton: {
			logout: "Déconnexion",
			account: "Compte"
		}
	}
};
