import React from "react";
import { useTranslation } from "../../Utility/UseTranslation";

interface SessionMetricsProps {
	sessionStats: {
		totalDuration?: string;
		avgDuration?: string;
		sessionsStarted?: number;
		sessionsSubmitted?: number;
		uniqueStudents?: number;
	};
}

const SessionMetrics: React.FC<SessionMetricsProps> = ({ sessionStats }) => {
	const { t } = useTranslation("sessionLog");

	return (
		<div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-6 mb-8">
			{[
				{ label: t("metrics.usage"), value: sessionStats?.totalDuration || "0h 0m" },
				{ label: t("metrics.avgDuration"), value: sessionStats?.avgDuration || "0h 0m" },
				{ label: t("metrics.sessionsStarted"), value: sessionStats?.sessionsStarted || "0" },
				{ label: t("metrics.sessionsSubmitted"), value: sessionStats?.sessionsSubmitted || "0" },
				{ label: t("metrics.uniqueStudents"), value: sessionStats?.uniqueStudents || "0" }
			].map((metric, index) => (
				<div key={index} className="bg-white p-4 border rounded-lg border-gray-200">
					<p className="text-sm font-medium text-gray-500 mb-1">{metric.label}</p>
					<p className="text-2xl font-bold text-[#16013e]">{metric.value}</p>
				</div>
			))}
		</div>
	);
};

export default SessionMetrics;
