export const SideBarLocale = {
	en: {
		Home: "Home",
		Assignments: "Assignments",
		"Assigned Calls": "Assigned Calls",
		"In Progress": "In Progress",
		Available: "Available",
		Upcoming: "Upcoming",
		"All Assignments": "All Assignments",
		"Create Assignment": "Create Assignment",
		"Create Your Own": "Self-Serve",
		Assignment: "Assignment",
		"Mock Interview": "Mock Interview",
		"Resume / Cover Letter": "Resume / Cover Letter",
		"Career Path": "Career Path",
		"Job Search": "Job Search",
		Reflection: "Reflection",
		"Dev Tools": "Dev Tools",
		"Sent Emails": "Sent Emails",
		Costs: "Costs",
		"Refresh Prompts": "Refresh Prompts",
		"Session Log": "Session Log",
		"Live Chat Support": "Live Chat Support",
		"Admin Options": "Admin Options",
		"Student View": "Student View",
		"InStage Admin": "InStage Admin",
		Language: "Language",
		studentModeEnabled: "Student Mode Enabled"
	},
	fr: {
		Home: "Accueil",
		Assignments: "Assignations",
		"Assigned Calls": "Appels assignés",
		"In Progress": "En cours",
		Available: "Disponible",
		Upcoming: "À venir",
		"All Assignments": "Tous les assignations",
		"Create Assignment": "Créer une assignation",
		"Create Your Own": "Créez le vôtre",
		"Mock Interview": "Entretien simulé",
		"Resume / Cover Letter": "CV / Lettre de motivation",
		"Career Path": "Parcours professionnel",
		"Job Search": "Recherche d'emploi",
		Reflection: "Réflexion",
		"Dev Tools": "Outils de développement",
		"Sent Emails": "Emails envoyés",
		Costs: "Coûts",
		"Refresh Prompts": "Actualiser les invites",
		"Session Log": "Journal des sessions",
		"Live Chat Support": "Support chat en direct",
		"Admin Options": "Options d'administration",
		"Student View": "Vue étudiant",
		"InStage Admin": "Admin InStage",
		Language: "Langue",
		studentModeEnabled: "Mode étudiant activé"
	}
};
