export const CreateAssignmentLocale = {
	en: {
		createAssignment: "Create Assignment",
		stepLabels: {
			Dates: "Dates",
			Setup: "Setup",
			Details: "Details",
			Review: "Review"
		},
		titles: {
			editAssignment: "Edit Assignment",
			createReflection: "Create Reflection",
			createInterview: "Create Interview",
			createFrontline: "Create Frontline",
			createJobSearch: "Create Job Search",
			createCheckIn: "Create Check In",
			createCareerPath: "Create Career Path"
		}
	},
	fr: {
		createAssignment: "Créer une tâche",
		stepLabels: {
			Dates: "Dates",
			Setup: "Configuration",
			Details: "Détails",
			Review: "Revue"
		},
		titles: {
			editAssignment: "Modifier la tâche",
			createReflection: "Créer un réflexion",
			createInterview: "Créer un entretien",
			createFrontline: "Créer un entretien",
			createJobSearch: "Créer un entretien",
			createCheckIn: "Créer un entretien",
			createCareerPath: "Créer un entretien"
		}
	}
};
