export const UserHomePageLocale = {
	en: {
		showMore: "Show More",
		showLess: "Show Less",
		buttons: {
			viewDetails: "View Details"
		},
		titles: {
			dashboard: "Dashboard",
			unsubmittedCalls: "Unsubmitted Calls",
			inProgressCalls: "In Progress Calls",
			pendingCalls: "Pending Calls",
			completedCalls: "Completed Calls",
			noActiveCalls: "No active calls",
			availableCalls: "Available Calls",
			upcomingCalls: "Upcoming Calls",
			noUpcomingCalls: "No upcoming calls",
			createYourOwn: "Self-Serve",
			selfPractice: "Self Practice",
			selfServe: "Self Serve",
			createAssignment: "Create Assignment",
			Assignment: "Create Assignment",
			recentCallSummaries: "Recent Call Summaries",
			noRecentCalls: "No recent calls",
			allAssignments: "All Assignments",
			noAssignments: "No assignments available",
			otherInStageSimulations: "Other InStage Simulations",
			createNew: "Create New"
		},
		CreateYourOwnButtons: {
			CreateReflection: "Reflection",
			CreateMockInterview: "Mock Interview",
			CreateResume: "Resume Assist",
			CreateJobSearch: "Job Search",
			CreateCareerPath: "Career Path",
			selfPractice: "Self Practice",
			createAssignment: "Create Assignment",
			getStarted: "Get Started"
		},
		noAccess: {
			assignment: "No Assignment Access",
			selfServe: "No Self-Serve Access"
		},
		OtherSims: {
			VideoInterviewSimulation: "Video Interview Simulation (Desktop only)",
			SalesSpin: "Sales Spin",
			SalesDiscoverySpin: "Sales Discovery Spin",
			SalesPresentationSpin: "Sales Presentation Spin",
			SalesProposalSpin: "Sales Proposal Spin",
			SalesCloseSpin: "Sales Close Spin",
			VideoInterviewSimulationDescription: "Practice your interviewing skills in a realistic setting",
			SalesSpinDescription: "Learn to conduct effective discovery meetings with clients",
			SalesPresentationSpinDescription: "Enhance your presentation and public speaking abilities",
			TryNow: "Try Now"
		}
	},
	fr: {
		showMore: "Afficher plus",
		showLess: "Afficher moins",
		buttons: {
			viewDetails: "Voir les détails"
		},
		titles: {
			dashboard: "Tableau de bord",
			unsubmittedCalls: "Appels non soumis",
			inProgressCalls: "Appels en cours",
			pendingCalls: "Appels en attente",
			completedCalls: "Appels terminés",
			noActiveCalls: "Aucun appel actif",
			availableCalls: "Appels disponibles",
			upcomingCalls: "Appels à venir",
			noUpcomingCalls: "Aucun appel à venir",
			createYourOwn: "Créer votre propre",
			selfPractice: "Pratique personnelle",
			selfServe: "Libre-service",
			createAssignment: "Créer un devoir",
			Assignment: "Devoir",
			recentCallSummaries: "Résumés d'appels récents",
			noRecentCalls: "Aucun résumé d'appel récent",
			allAssignments: "Tous les devoirs",
			noAssignments: "Aucun devoir disponible",
			otherInStageSimulations: "Autres simulations InStage",
			createNew: "Créer nouveau"
		},
		CreateYourOwnButtons: {
			CreateReflection: "Créer une réflexion",
			CreateMockInterview: "Créer un entretien simulé",
			CreateResume: "Créer un CV",
			CreateJobSearch: "Créer une recherche de job",
			CreateCareerPath: "Créer une carrière",
			selfPractice: "Pratique personnelle",
			createAssignment: "Créer un devoir",
			getStarted: "Commencer"
		},
		noAccess: {
			assignment: "Accès aux devoirs",
			selfServe: "Accès au libre-service"
		},
		OtherSims: {
			VideoInterviewSimulation: "Simulation d'entretien vidéo (Desktop uniquement)",
			SalesSpin: "Spin de ventes",
			SalesDiscoverySpin: "Spin de découverte de ventes",
			SalesPresentationSpin: "Spin de présentation de ventes",
			SalesProposalSpin: "Spin de proposition de ventes",
			SalesCloseSpin: "Spin de clôture de ventes",
			VideoInterviewSimulationDescription: "Pratiquez vos compétences d'entretien dans un environnement réaliste",
			SalesSpinDescription: "Apprenez à mener des réunions de découverte efficaces avec des clients",
			SalesPresentationSpinDescription: "Améliorez vos compétences en présentation et en paroles publiques",
			TryNow: "Essayer maintenant"
		}
	}
};
