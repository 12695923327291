import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { SessionService, SessionStats } from "../services/SessionService";
import { SessionView } from "../types/SessionView";
import { SortingState, ColumnFiltersState, PaginationState } from "@tanstack/react-table";
import { useAppContext } from "../contexts/AppContext";
import { DateRange } from "react-day-picker";

export const useSessionLog = () => {
	const { assignmentId } = useParams<{ assignmentId: string }>();
	const [sessions, setSessions] = useState<SessionView[]>([]);
	const [loading, setLoading] = useState(true);
	const [statsLoading, setStatsLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const [sorting, setSorting] = useState<SortingState>([]);
	const { debugMode } = useAppContext();
	const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
	const [dateRange, setDateRange] = useState<DateRange>({ from: undefined, to: undefined });
	const [selectedClient, setSelectedClient] = useState<string>("");
	const [sessionStats, setSessionStats] = useState<SessionStats>({
		totalDuration: "0h 0m",
		avgDuration: "0h 0m",
		sessionsStarted: 0,
		sessionsSubmitted: 0,
		uniqueStudents: 0
	});
	const [paginationState, setPaginationState] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 10
	});
	const [pageCount, setPageCount] = useState<number>(-1);
	const [clientFilters, setClientFilters] = useState<{ clientId: string; clientName: string }[]>([]);

	const handleClientChange = (value: string) => {
		setSelectedClient(value);
	};

	const handleDateRangeChange = (newDateRange: DateRange) => {
		console.log("newDateRange", newDateRange);
		setDateRange(newDateRange);
	};

	const handlePaginationChange = (newPagination: PaginationState) => {
		setPaginationState(newPagination);
	};
	useEffect(() => {
		const fetchClientFilters = async () => {
			if (debugMode && clientFilters.length === 0) {
				try {
					const updatedClientFilters = await SessionService.getAvailableClients();
					console.log(`clientFilters`, updatedClientFilters);
					setClientFilters(updatedClientFilters);
				} catch (error) {
					console.error("Error fetching client filters:", error);
				}
			}
		};

		fetchClientFilters();
	}, [debugMode, clientFilters.length, dateRange]);

	useEffect(() => {
		const fetchSessions = async () => {
			try {
				setLoading(true);
				setError(null);
				let fetchedSessions: SessionView[];

				if (debugMode) {
					console.log(`fetching sessions in debug mode, selectedClient: ${selectedClient}`);
					const clientIdForRequest = selectedClient === "all" ? undefined : selectedClient;
					const { sessions, totalPages } = await SessionService.getAllSessionsDebug(
						paginationState.pageIndex,
						paginationState.pageSize,
						clientIdForRequest,
						dateRange?.from?.toISOString(),
						dateRange?.to?.toISOString()
					);
					setPageCount(totalPages);
					fetchedSessions = sessions;
					setSessions(fetchedSessions);
				} else {
					console.log(`fetching sessions for current client`);
					const { sessions, totalPages } = await SessionService.getClientSessions(
						paginationState.pageIndex,
						paginationState.pageSize,
						dateRange?.from?.toISOString(),
						dateRange?.to?.toISOString()
					);
					fetchedSessions = sessions;
					setPageCount(totalPages);
					setSessions(fetchedSessions);
				}
			} catch (error) {
				console.error("Error fetching sessions:", error);
				setError(error instanceof Error ? error.message : "Failed to fetch sessions");
			} finally {
				setLoading(false);
			}
		};
		// dont fetch sessions if dateRange is not set
		if (dateRange?.from && dateRange?.to) {
			fetchSessions();
		}
	}, [assignmentId, debugMode, paginationState, selectedClient, dateRange]);

	useEffect(() => {
		const fetchStats = async () => {
			try {
				setError(null);
				setStatsLoading(true);
				if (!assignmentId) {
					if (debugMode) {
						console.log(`fetching stats in debug mode, selectedClient: ${selectedClient}`);
						const clientIdForRequest = selectedClient === "all" ? undefined : selectedClient;
						const stats = await SessionService.getAllSessionsDebugStats(
							clientIdForRequest,
							dateRange?.from?.toISOString(),
							dateRange?.to?.toISOString()
						);
						console.log(`stats`, stats);
						setSessionStats(stats);
					} else {
						const stats = await SessionService.getClientSessionStats(dateRange?.from?.toISOString(), dateRange?.to?.toISOString());
						console.log(`stats`, stats);
						setSessionStats(stats);
					}
				}
			} catch (error) {
				console.error("Error fetching stats:", error);
				setError(error instanceof Error ? error.message : "Failed to fetch session statistics");
			} finally {
				setStatsLoading(false);
			}
		};
		// dont fetch sessions if dateRange is not set
		if (dateRange?.from && dateRange?.to) {
			fetchStats();
		}
	}, [assignmentId, debugMode, selectedClient, dateRange]);
	return {
		sessions,
		loading,
		statsLoading,
		error,
		sorting,
		setSorting,
		columnFilters,
		setColumnFilters,
		dateRange,
		setDateRange: handleDateRangeChange,
		selectedClient,
		handleClientChange,
		sessionStats,
		paginationState,
		handlePaginationChange,
		pageCount,
		clientFilters,
		debugMode,
		assignmentId
	};
};
