import React, { useState } from "react";
import { useAppContext } from "../../contexts/AppContext";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import {
	HomeIcon,
	ClipboardDocumentListIcon,
	UserIcon,
	Bars3Icon,
	ArrowLeftEndOnRectangleIcon,
	ArrowTopRightOnSquareIcon,
	DocumentTextIcon,
	UsersIcon,
	EnvelopeIcon,
	RocketLaunchIcon,
	BeakerIcon,
	SignalIcon,
	ChatBubbleLeftRightIcon,
	SparklesIcon,
	CurrencyDollarIcon,
	Cog6ToothIcon,
	PlusCircleIcon
} from "@heroicons/react/24/solid";
import { PiStudentFill } from "react-icons/pi";
import { AdminPortal } from "@frontegg/react";
import logo from "../../assets/images/logo.png";
import { Switch } from "../../components/shadcn-ui/Switch";
import {
	DropdownMenu,
	DropdownMenuTrigger,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuSeparator
} from "../../components/shadcn-ui/DropdownMenu";
import { Sheet, SheetContent, SheetTrigger } from "../../components/shadcn-ui/Sheet";
import { Badge } from "../../components/shadcn-ui/Badge"; // Added Badge import
import TokenManager from "../../services/TokenManager";
import { PhoneIcon } from "lucide-react";
import { NavigateService } from "../../services/NavigateService";
import posthog from "posthog-js";
import { PromptService } from "../../services/PromptService";
import { useToast } from "../../hooks/useToast";
import { GrLanguage, GrCode } from "react-icons/gr";
import { useSidebarComponent } from "./SideBar/SideBarComponent";
import LanguageModal from "./LanguageModal";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { useTranslation } from "./UseTranslation";
import { CreateSidebarButtons } from "./SideBar/CreateSidebarButtons";
import FronteggProfile from "./FronteggProfile";

interface HeaderProps {
	maxWidth?: string;
	isStudentMode: boolean;
	setIsStudentMode: (isStudentMode: boolean) => void;
	debugMode: boolean;
	setDebugMode: (debugMode: boolean) => void;
}

interface ExtendedNavItem {
	icon: React.ElementType;
	label: string;
	href?: string;
	visible: boolean;
	items?: ExtendedNavItem[];
	onClick?: () => void;
	customRender?: (isMobile?: boolean) => React.ReactNode;
	isSection?: boolean;
	isNested?: boolean;
}

const Header: React.FC<HeaderProps> = ({ maxWidth = "max-w-7xl", isStudentMode, setIsStudentMode, debugMode, setDebugMode }) => {
	const { isAuthenticated } = useAuth();
	const { isWideScreen } = useAppContext();
	const navigate = useNavigate();
	const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
	const { toast } = useToast();
	const { handleLanguageModalOpen, showLanguageModal, setShowLanguageModal, handleLanguageModalClose } = useSidebarComponent();
	const isLanguageFeatureFlagEnabled = useFeatureFlagEnabled("translation") || false;
	const hasSelfServeAccessFeatureFlagEnabled = useFeatureFlagEnabled("selfserve-access") || false;
	const hasAssignmentsFeatureFlagEnabled = useFeatureFlagEnabled("assignment-access") || false;
	const { t } = useTranslation("sideBar");

	// const [isStudentMode, setIsStudentMode] = useState(() => {
	// 	const stored = sessionStorage.getItem("isStudentMode");
	// 	return stored === "true";
	// });

	const handleStudentModeToggle = (newValue: boolean) => {
		setIsStudentMode(newValue);
		sessionStorage.setItem("isStudentMode", newValue.toString());

		if (window.location.pathname !== "/user" && sessionStorage.getItem("isStudentMode") === "true") {
			navigate("/user");
		}
	};

	const handleRefreshPrompts = async () => {
		try {
			const success = await PromptService.refreshPrompts();
			if (success) {
				toast({
					title: "Success",
					description: "Prompts refreshed successfully",
					variant: "default"
				});
			} else {
				toast({
					title: "Error",
					description: "Failed to refresh prompts",
					variant: "destructive"
				});
			}
		} catch (error) {
			console.log("error refreshing prompts", error);
			toast({
				title: "Error",
				description: error instanceof Error ? error.message : "An unknown error occurred",
				variant: "destructive"
			});
		}
	};

	const handleScroll = (id: string) => {
		const element = document.getElementById(id);
		if (element) {
			element.scrollIntoView({ behavior: "smooth" });
		}
	};

	const buttons = CreateSidebarButtons({
		debugMode,
		isStudentMode,
		setDebugMode,
		setIsStudentMode,
		handleStudentModeToggle,
		handleLanguageModalOpen,
		handleRefreshPrompts,
		handleScroll,
		navigate,
		t,
		isLanguageFeatureFlagEnabled,
		hasSelfServeAccessFeatureFlagEnabled,
		hasAssignmentsFeatureFlagEnabled
	});

	// Filter out items that should be visible in the menu
	const menuItems: ExtendedNavItem[] = [
		...buttons.mainNav,
		...buttons.assignments,
		// Add section labels before their items
		{ icon: PhoneIcon, label: "Assigned Calls", visible: true, isSection: true },
		...buttons.assignedCalls.items.filter((item) => item.visible).map((item) => ({ ...item, isNested: true })),
		{ icon: PlusCircleIcon, label: "Create Your Own", visible: true, isSection: true },
		...buttons.createYourOwn.items.filter((item) => item.visible).map((item) => ({ ...item, isNested: true })),
		...buttons.bottomNav.map((item) => {
			if (item.label === "Student View" || item.label === "InStage Admin") {
				return {
					...item,
					customRender: (isMobile?: boolean) => (
						<Switch
							checked={item.label === "Student View" ? isStudentMode : debugMode}
							onCheckedChange={item.label === "Student View" ? handleStudentModeToggle : setDebugMode}
							aria-label={t(item.label)}
						/>
					)
				};
			}
			return item;
		}),
		// Add Dev Tools section if debug mode is enabled
		...(TokenManager.hasInstageAdminClearance() && debugMode
			? [
					{ icon: GrCode, label: "Dev Tools", visible: true, isSection: true },
					...buttons.devOptions.items.filter((item) => item.visible).map((item) => ({ ...item, isNested: true }))
			  ]
			: [])
	].filter((item) => item.visible);

	const renderMenuItems = (isMobile = false) => (
		<>
			{menuItems.map((item, index) => {
				// Add separator before first debug mode item or before logout
				const showSeparator =
					index === menuItems.length - 2 ||
					// Add separator after main nav
					index === buttons.mainNav.length - 1 ||
					// Add separator after assignments
					index === buttons.mainNav.length + buttons.assignments.length - 1 ||
					// Add separator after assigned calls
					index === buttons.mainNav.length + buttons.assignments.length + buttons.assignedCalls.items.filter((i) => i.visible).length + 1 ||
					// Add separator after create your own
					index ===
						buttons.mainNav.length +
							buttons.assignments.length +
							buttons.assignedCalls.items.filter((i) => i.visible).length +
							buttons.createYourOwn.items.filter((i) => i.visible).length +
							2;

				const itemClasses = item.isNested ? "pl-8" : ""; // Add padding for nested items

				return (
					<React.Fragment key={item.label}>
						{/* {showSeparator && (isMobile ? <div className="h-px bg-gray-200 my-2" /> : <DropdownMenuSeparator />)} */}
						{isMobile ? (
							<button
								onClick={() => {
									if (item.isSection) return; // Don't do anything for section headers
									if (item.onClick) {
										item.onClick();
									} else if (item.href) {
										navigate(item.href);
									}
									setMobileMenuOpen(false);
								}}
								className={`flex items-center justify-between text-left py-2 w-full ${itemClasses} ${
									item.isSection ? "font-semibold text-gray-500 cursor-default" : "cursor-pointer"
								}`}
							>
								<div className="flex items-center space-x-2">
									<item.icon className="h-5 w-5" />
									<span>{t(item.label)}</span>
								</div>
								{item.customRender && item.customRender(true)}
							</button>
						) : (
							<DropdownMenuItem
								onSelect={(e) => {
									if (item.isSection) e.preventDefault(); // Don't do anything for section headers
									if (item.customRender) e.preventDefault();
									if (item.onClick) {
										item.onClick();
									} else if (item.href) {
										navigate(item.href);
									}
								}}
								className={`flex items-center justify-between w-full ${itemClasses} ${
									item.isSection ? "font-semibold text-gray-500" : ""
								}`}
								disabled={item.isSection}
							>
								<div className="flex items-center space-x-2">
									<item.icon className="h-5 w-5" />
									<span>{t(item.label)}</span>
								</div>
								{item.customRender && item.customRender(false)}
							</DropdownMenuItem>
						)}
					</React.Fragment>
				);
			})}
		</>
	);

	return (
		<>
			<LanguageModal
				showLanguageModal={showLanguageModal}
				setShowLanguageModal={setShowLanguageModal}
				handleLanguageModalClose={handleLanguageModalClose}
			/>
			<header className={isWideScreen ? "bg-transparent h-0" : "bg-white h-16"}>
				<div className={`container mx-auto px-4 sm:px-6 lg:px-8 ${maxWidth} h-full`}>
					<div className="flex justify-between items-center h-full">
						<div className="flex items-center space-x-4">
							{!isWideScreen && (
								<a href="/user" className="text-[#00a9af] hover:text-gray-200 transition-colors duration-200">
									<img src={logo} alt="InStage Logo" className="h-8 w-auto" />
								</a>
							)}
							{/* <Badge variant="secondary" className="text-xs bg-[#ffe8c4] text-[#945e0c] rounded-md px-2 py-1 flex items-center">
							<BeakerIcon className="h-3 w-3 mr-1" />
							Beta
						</Badge> */}
						</div>
						{isAuthenticated && (
							<div className="flex items-center space-x-4">
								{TokenManager.hasInstageAdminClearance() && !isWideScreen && (
									<Switch
										id="debug-mode"
										checked={debugMode}
										onCheckedChange={(checked) => {
											setDebugMode(checked);
											sessionStorage.setItem("isAdminAccess", checked.toString());
										}}
										aria-label="Debug Mode"
										className="mr-2"
									/>
								)}
								<div className="h-8">
									<Sheet open={isMobileMenuOpen} onOpenChange={setMobileMenuOpen}>
										<SheetTrigger asChild>
											<button className="text-[#00a9af] hover:text-[#008c91] transition-colors duration-200">
												<Bars3Icon className="h-8 w-8" />
											</button>
										</SheetTrigger>
										<SheetContent side="right" className="w-[400px] pt-12 bg-white">
											<nav className="flex flex-col h-[calc(100vh-6rem)] overflow-y-auto pr-4 bg-white">
												<FronteggProfile hasPadding={false} />
												<div className="flex-grow space-y-4">{renderMenuItems(true)}</div>
											</nav>
										</SheetContent>
									</Sheet>
								</div>
							</div>
						)}
					</div>
				</div>
				{sessionStorage.getItem("isStudentMode") === "true" && (
					<div className="flex justify-center">
						<div className="text-sm text-gray-500 text-center rounded-b-md bg-[rgb(234,236,240)] px-2 py-1 inline-block z-10">
							{t("studentModeEnabled")}
						</div>
					</div>
				)}
			</header>
		</>
	);
};

export default Header;
