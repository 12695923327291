import React, { useEffect, useState } from "react";
import Header from "./Header";
import { cn } from "../../lib/utils";
import SideBarComponent, { useSidebarComponent } from "./SideBar/SideBarComponent";
import { useAppContext } from "../../contexts/AppContext";
import { useAuth } from "../../contexts/AuthContext";

interface LayoutProps {
	children: React.ReactNode;
	maxWidth?: string;
}

const Layout: React.FC<LayoutProps> = ({ children, maxWidth = "max-w-7xl" }) => {
	const { isWideScreen, debugMode, setDebugMode, isCollapsed } = useSidebarComponent();
	const { isStudentMode, setIsStudentMode } = useAppContext();

	const { isAuthenticated } = useAuth();

	// const [isCollapsed, setIsCollapsed] = useState(() => {
	// 	const stored = localStorage.getItem("isCollapsed");
	// 	return stored === "true";
	// });

	useEffect(() => {
		localStorage.setItem("isCollapsed", isCollapsed.toString());
	}, [isCollapsed]);

	if (!isAuthenticated) {
		return (
			<>
				<div className="min-h-screen  bg-background">{children}</div>
			</>
		);
	}

	return (
		<>
			{isWideScreen && (
				<div className="min-h-screen flex bg-background">
					<SideBarComponent />

					<div className={cn("flex-1 flex flex-col", isCollapsed ? "ml-16" : "ml-64")}>
						<Header
							maxWidth={maxWidth}
							isStudentMode={isStudentMode}
							setIsStudentMode={setIsStudentMode}
							debugMode={debugMode}
							setDebugMode={setDebugMode}
						/>
						<main className="flex-grow py-8 overflow-auto [&::-webkit-scrollbar]:hidden [-ms-overflow-style:'none'] [scrollbar-width:none] bg-background">
							<div className={`container mx-auto px-4 sm:px-6 lg:px-8 ${maxWidth} min-h-full`}>{children}</div>
						</main>
					</div>
				</div>
			)}
			{!isWideScreen && (
				<div className="min-h-screen flex flex-col bg-background">
					<Header
						maxWidth={maxWidth}
						isStudentMode={isStudentMode}
						setIsStudentMode={setIsStudentMode}
						debugMode={debugMode}
						setDebugMode={setDebugMode}
					/>
					<main className="flex-grow py-8 overflow-auto [&::-webkit-scrollbar]:hidden [-ms-overflow-style:'none'] [scrollbar-width:none]">
						<div className={`container mx-auto px-4 sm:px-6 lg:px-8 ${maxWidth} min-h-full`}>{children}</div>
					</main>
				</div>
			)}
		</>
	);
};

export default Layout;
