export const WebhookManagementLocale = {
	en: {
		title: "Webhook Managements",
		connect: "Connect Webhook",
		edit: "Edit Webhook",
		connectNew: "Connect New Webhook",
		form: {
			name: "Webhook Name",
			namePlaceholder: "Enter webhook name",
			url: "Webhook URL",
			urlPlaceholder: "Enter webhook URL",
			events: "Webhook Events",
			secret: "Secret",
			status: "Status",
			actions: {
				cancel: "Cancel",
				connect: "Connect",
				update: "Update",
				edit: "Edit",
				delete: "Delete",
				test: "Test",
				back: "Back",
				show: "Show",
				hide: "Hide"
			}
		},
		table: {
			name: "Name",
			url: "URL",
			events: "Events",
			status: "Status",
			actions: "Actions",
			deliveryLogs: {
				title: "Delivery Logs",
				createdAt: "Created At",
				payload: "Payload",
				response: "Response",
				statusCode: "Status Code",
				errorMessage: "Error Message"
			}
		}
	},
	fr: {
		title: "Gestion des Webhooks",
		connect: "Connecter le Webhook",
		edit: "Modifier le Webhook",
		connectNew: "Connecter un Nouveau Webhook",
		form: {
			name: "Nom du Webhook",
			namePlaceholder: "Entrez le nom du webhook",
			url: "URL du Webhook",
			urlPlaceholder: "Entrez l'URL du webhook",
			events: "Événements du Webhook",
			secret: "Secret",
			status: "Statut",
			actions: {
				cancel: "Annuler",
				connect: "Connecter",
				update: "Mettre à jour",
				edit: "Modifier",
				delete: "Supprimer",
				test: "Tester",
				back: "Retour",
				show: "Afficher",
				hide: "Masquer"
			}
		},
		table: {
			name: "Nom",
			url: "URL",
			events: "Événements",
			status: "Statut",
			actions: "Actions",
			deliveryLogs: {
				title: "Journaux de Livraison",
				createdAt: "Créé le",
				payload: "Charge utile",
				response: "Réponse",
				statusCode: "Code de statut",
				errorMessage: "Message d'erreur"
			}
		}
	}
};
