import React, { useState, useEffect } from "react";
import { Command, CommandGroup, CommandInput, CommandItem } from "../../../shadcn-ui/Command";
import { Popover, PopoverContent, PopoverTrigger } from "../../../shadcn-ui/Popover";
import { Button } from "../../../shadcn-ui/Button";
import { Check, ChevronsUpDown } from "lucide-react";
import { cn } from "../../../../lib/utils";
import jobTitlesData from "../../../../constants/JobTitles.json";
import { fr, enCA } from "date-fns/locale";
import { useAppContext } from "../../../../contexts/AppContext";
import { useTranslation } from "../../../Utility/UseTranslation";

interface JobTitleSelectProps {
	value: string;
	onChange: (value: string) => void;
	onJobSearch?: (title: string) => void;
	disabled?: boolean;
}

const JobTitleSelect: React.FC<JobTitleSelectProps> = ({ value, onChange, onJobSearch, disabled }) => {
	const [open, setOpen] = useState(false);
	const [searchValue, setSearchValue] = useState("");
	const [filteredTitles, setFilteredTitles] = useState(jobTitlesData);
	const { language } = useAppContext();
	const locale = language === "fr" ? fr : enCA;
	const { t } = useTranslation("interviewCreatorDates");

	// Check if current value is a custom title
	const isCustomTitle = !jobTitlesData[language].some((job) => job.name === value) && value !== "";

	useEffect(() => {
		const searchLower = searchValue.toLowerCase();
		const filtered =
			searchValue === ""
				? jobTitlesData[language]
				: jobTitlesData[language].filter(
						(job) => job.name.toLowerCase().includes(searchLower) || job.title.toLowerCase().includes(searchLower)
				  );
		setFilteredTitles({ ...filteredTitles, [language]: filtered });
	}, [searchValue, language]);

	const handleSelect = (currentValue: string) => {
		onChange(currentValue);
		setOpen(false);
		if (onJobSearch) {
			onJobSearch(currentValue);
		}
	};

	return (
		<Popover open={open} onOpenChange={setOpen}>
			<PopoverTrigger asChild>
				<Button
					variant="outline"
					role="combobox"
					aria-expanded={open}
					aria-label={t("jobTitleSelect.selectJobTitle")}
					aria-controls="job-title-options"
					aria-haspopup="listbox"
					className={cn("w-full justify-between h-11 px-3 py-2 text-sm bg-white", disabled && "opacity-50 cursor-not-allowed")}
					disabled={disabled}
				>
					{value ? `${value}${isCustomTitle ? " (custom)" : ""}` : t("jobTitleSelect.selectJobTitle")}
					<ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" aria-hidden="true" />
				</Button>
			</PopoverTrigger>
			<PopoverContent className="w-[--radix-popover-trigger-width] p-0">
				<Command>
					<CommandInput
						placeholder={t("jobTitleSelect.searchJobTitles")}
						value={searchValue}
						onValueChange={setSearchValue}
						aria-label="Search job titles"
					/>
					<CommandGroup className="max-h-[300px] overflow-y-auto" role="listbox" id="job-title-options">
						{filteredTitles[language].length > 0 ? (
							filteredTitles[language].map((job) => (
								<CommandItem
									key={job.key}
									value={job.name}
									onSelect={handleSelect}
									role="option"
									aria-selected={value === job.name}
									aria-label={job.name}
								>
									<Check className={cn("mr-2 h-4 w-4", value === job.name ? "opacity-100" : "opacity-0")} aria-hidden="true" />
									{job.name}
								</CommandItem>
							))
						) : (
							<CommandItem
								value={searchValue}
								onSelect={handleSelect}
								role="option"
								aria-selected={value === searchValue}
								aria-label={`${searchValue} (custom job title)`}
							>
								<Check className={cn("mr-2 h-4 w-4", value === searchValue ? "opacity-100" : "opacity-0")} aria-hidden="true" />
								{searchValue}
								<span className="ml-2 text-muted-foreground">(custom)</span>
							</CommandItem>
						)}
					</CommandGroup>
				</Command>
			</PopoverContent>
		</Popover>
	);
};

export default JobTitleSelect;
