import React from "react";
import { AdminPortal, useAuth } from "@frontegg/react";
import { cn } from "../../lib/utils";
import { posthog } from "posthog-js";
import { useNavigate } from "react-router-dom";
import { LuLogOut } from "react-icons/lu";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../shadcn-ui/Dropdown";
import { ArrowLeftEndOnRectangleIcon, UserIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "./UseTranslation";

interface FronteggProfileProps {
	isCollapsed?: boolean;
	hasPadding?: boolean;
}

const FronteggProfile: React.FC<FronteggProfileProps> = ({ isCollapsed, hasPadding = true }) => {
	const { user } = useAuth();
	const navigate = useNavigate();
	const { t } = useTranslation("extraUtility");

	if (!user) return null;

	const handleLogout = () => {
		sessionStorage.clear();
		console.log("MAJOR_EVENT: User Logged Out");
		posthog.capture("MAJOR_EVENT: User Logged Out", {
			timestamp: new Date().toISOString()
		});
		navigate("/account/logout");
	};

	const handleAdminPortal = () => {
		navigate("#/admin-box/users");
		AdminPortal.show();
	};

	return (
		<div
			className={cn(
				"flex items-center gap-3 px-3 py-4 border-t bg-gray-50/50",
				isCollapsed ? "justify-center" : "w-full",
				hasPadding ? "px-3" : "px-0"
			)}
		>
			{user.profilePictureUrl ? (
				<div className="relative">
					{isCollapsed ? (
						<DropdownMenu>
							<DropdownMenuTrigger asChild>
								<img
									src={user.profilePictureUrl}
									alt={user.name}
									className="h-8 w-8 rounded-full object-cover cursor-pointer hover:brightness-90 transition-all duration-300"
								/>
							</DropdownMenuTrigger>
							<DropdownMenuContent className="ml-3 mb-3" align="end">
								<DropdownMenuItem className="flex items-center gap-2" onClick={handleAdminPortal}>
									<UserIcon className="w-5 h-5 mr-2" />
									{t("frontEggBottomButton.account")}
								</DropdownMenuItem>
								<DropdownMenuItem className="flex items-center gap-2" onClick={handleLogout}>
									<LuLogOut className="w-5 h-5 mr-2 p-0.5" />
									{t("frontEggBottomButton.logout")}
								</DropdownMenuItem>
							</DropdownMenuContent>
						</DropdownMenu>
					) : (
						<img
							onClick={handleAdminPortal}
							src={user.profilePictureUrl}
							alt={user.name}
							className="h-8 w-8 rounded-full object-cover cursor-pointer hover:brightness-90 transition-all duration-300"
						/>
					)}
				</div>
			) : (
				<div className="h-8 w-8 rounded-full bg-gray-200 flex items-center justify-center">
					<span className="text-gray-500 text-sm">{user.name?.charAt(0)}</span>
				</div>
			)}
			{!isCollapsed && (
				<>
					<div className="flex flex-col min-w-0">
						<span className="text-sm font-medium truncate">{user.name}</span>
						<span className="text-xs text-gray-500 truncate">{user.email}</span>
					</div>
					<div
						className="cursor-pointer flex items-center justify-center h-8 w-8 rounded-full ml-auto bg-gray-200 hover:text-[#ef5292]"
						onClick={handleLogout}
					>
						<LuLogOut />
					</div>
				</>
			)}
		</div>
	);
};

export default FronteggProfile;
