import React, { useState } from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../shadcn-ui/Dialog";
import { Button } from "../shadcn-ui/Button";
import { GrLanguage } from "react-icons/gr";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../shadcn-ui/Select";
import { useAppContext } from "../../contexts/AppContext";

const LanguageModal: React.FC<{
	showLanguageModal: boolean;
	setShowLanguageModal: (showLanguageModal: boolean) => void;
	handleLanguageModalClose: (selectedLanguage: "en" | "fr") => void;
}> = ({ showLanguageModal, setShowLanguageModal, handleLanguageModalClose }) => {
	const { language } = useAppContext();
	const [selectedLanguage, setSelectedLanguage] = useState<"en" | "fr">(language !== null ? language : "en");

	return (
		<>
			{showLanguageModal && (
				<Dialog open={showLanguageModal} onOpenChange={setShowLanguageModal}>
					<DialogContent className="sm:max-w-[425px]">
						<DialogHeader>
							<DialogTitle className="flex items-center gap-2 text-xl">
								<GrLanguage className="h-6 w-6 text-gray-500" />
								Select Language
							</DialogTitle>
						</DialogHeader>
						<div className="py-4">
							<p className="text-sm text-gray-600 mb-4">Please select your preferred language.</p>
							<Select value={selectedLanguage} onValueChange={(value) => setSelectedLanguage(value as "en" | "fr")}>
								<SelectTrigger className="w-full">
									<SelectValue placeholder="Select a language" />
								</SelectTrigger>
								<SelectContent>
									<SelectItem value="en">English</SelectItem>
									<SelectItem value="fr">French</SelectItem>
								</SelectContent>
							</Select>
							<div className="flex gap-3 justify-start pt-6">
								<Button
									variant="outline"
									onClick={() => setShowLanguageModal(false)}
									className="border-[#00a9af] text-[#00a9af] hover:bg-transparent hover:text-[#00a9af] hover:border-[#00a9af]"
								>
									Cancel
								</Button>
								<Button
									onClick={() => handleLanguageModalClose(selectedLanguage)}
									className="bg-[#00a9af] hover:bg-[#00a9af]/90 text-white border-none"
								>
									Confirm
								</Button>
							</div>
						</div>
					</DialogContent>
				</Dialog>
			)}
		</>
	);
};

export default LanguageModal;
