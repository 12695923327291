import React, { useEffect, useState } from "react";
import $ from "jquery";
import axios from "axios";
import { useParams, useLocation } from "react-router-dom";
import { format } from "date-fns";
import { PencilIcon, ClipboardIcon, ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import { AssignmentPayload } from "../../../../types/AssignmentPayload";
import { AssignmentService } from "../../../../services/AssignmentService";

import { Button } from "../../../shadcn-ui/Button";
import { AdminAssignmentView } from "@/src/types/AdminAssignmentView";
import { StudentNotications } from "../../../../types/AssignmentPayload";
import { Loader2 } from "lucide-react";

import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle
} from "../../../shadcn-ui/AlertDialog";
import { useAuth } from "../../../../contexts/AuthContext";
import { SchedulePayload } from "../../../../types/SchedulePayload";
import { InterviewStage } from "../../../../types/InterviewPayload";
import { FrontlineStage } from "../../../../types/FrontlinePayload";

import { CareerPathStage } from "@/src/types/CareerPathPayload";
import FronteggService from "../../../../services/FronteggService";
import { useTranslation } from "../../../Utility/UseTranslation";

// Define props for the ReviewAssignment component
export interface ReviewAssignmentProps {
	values: any;
	onNext: () => void;
	onPrevious: () => void;
	onEdit: (step: number) => void;
	isEditMode: boolean;
	onSubmit: (assignmentPayload: AdminAssignmentView) => void;
	assignmentType?: "reflection" | "interview" | "frontline" | "jobSearch" | "checkInSim" | "careerPath";
	clientSettings: any;
}

// ReviewAssignment component for reviewing and submitting assignment details
const ReviewAssignment: React.FC<ReviewAssignmentProps> = ({
	values,
	onSubmit,
	onNext,
	onPrevious,
	onEdit,
	isEditMode,
	assignmentType = "reflection",
	clientSettings
}) => {
	const { assignmentId } = useParams<{ step?: string; assignmentId?: string }>();
	const [isDeployDialogOpen, setIsDeployDialogOpen] = useState(false);
	const [isDeploying, setIsDeploying] = useState(false);
	const { instageUser, ltiToken } = useAuth();

	const location = useLocation();
	const isAdminPath = location.pathname.startsWith("/admin");
	const { t } = useTranslation("assignmentReview");
	const { user } = useAuth();

	useEffect(() => {
		if (assignmentType === "careerPath") {
			console.log("values", values);
		}
	}, [values, assignmentType]);

	// Helper function to get the correct step index based on section and assignment type
	const getStepIndex = (section: "dates" | "setup" | "details") => {
		const stepOrder = {
			dates: 0,
			setup: assignmentType === "jobSearch" ? -1 : 1,
			details: assignmentType === "jobSearch" ? 1 : 2
		};
		return stepOrder[section];
	};

	const [expandedSessions, setExpandedSessions] = useState<number[]>([]);

	const toggleSessionExpand = (sessionNumber: number) => {
		setExpandedSessions((prev) => (prev.includes(sessionNumber) ? prev.filter((n) => n !== sessionNumber) : [...prev, sessionNumber]));
	};

	// Format date to a readable string
	const formatDate = (date: Date | null | undefined) => {
		return date ? format(date, "MMMM d, yyyy") : "Not set";
	};

	const stripTrailingNumber = (title: string): string => {
		return title.replace(
			/\s(?:\d+|Two|Three|Four|Five|Six|Seven|Eight|Nine|Ten|Eleven|Twelve|Thirteen|Fourteen|Fifteen|Sixteen|Seventeen|Eighteen|Nineteen|Twenty)$/,
			""
		);
	};

	// Get availability date text for a session
	const getAvailabilityDateText = (session: any, index: number) => {
		if (session.startDate) {
			return formatDate(session.startDate);
		}
		if (index === 0) {
			return "Anytime";
		}
		return "As soon as previous session is completed";
	};

	// Copy text to clipboard
	const copyToClipboard = (text: string) => {
		navigator.clipboard.writeText(text);
		// Optionally, show a tooltip or notification that the text was copied
	};

	// Get the title for a session
	const getSessionTitle = (session: any, index: number) => {
		if (assignmentType === "checkInSim") {
			if (session.title === "First Check-In") return "First Check-In";
			if (session.title === "Final Check-In") return "Final Check-In";
			return `Check-In ${index}`;
		} else {
			if (session.title === "Pre-Reflection") return "Pre-Reflection";
			if (session.title === "Final Reflection") return "Final Reflection";
			return `Reflection ${index}`; // Changed from index + 1 to index
		}
	};

	// Update the sortedSessions memo with proper typing
	const sortedSessions = React.useMemo(() => {
		if (!values.sessions) return [];
		return [...values.sessions].sort((a, b) => {
			if (a.title === "Pre-Reflection") return -1;
			if (b.title === "Pre-Reflection") return 1;
			if (a.title === "Final Reflection") return 1;
			if (b.title === "Final Reflection") return -1;
			return values.sessions.indexOf(a) - values.sessions.indexOf(b); // Maintain original order for other reflections
		});
	}, [values.sessions, values.stages, assignmentType]);

	// Handle assignment creation or update
	const handleCreateAssignment = async (status: "deployed" | "draft"): Promise<AdminAssignmentView | null> => {
		if (status === "deployed") {
			if (isAdminPath) {
				// For admins, open the dialog before creating the assignment
				setIsDeployDialogOpen(true);
			} else {
				// For users, create the assignment right away
				return await createAssignment(status);
			}
		} else {
			// Proceed with creating draft
			return await createAssignment(status);
		}
		return null;
	};

	// Create or update assignment
	const createAssignment = async (status: "deployed" | "draft"): Promise<AdminAssignmentView | null> => {
		setIsDeploying(true);
		try {
			// Ensure instageUser email is included in recipientList when not in admin path
			let updatedRecipientList = values.recipientList;
			if (!isAdminPath && instageUser?.email) {
				const emailList = values.recipientList ? values.recipientList.split(",").map((e: string) => e.trim()) : [];
				if (!emailList.includes(instageUser.email)) {
					emailList.push(instageUser.email);
					updatedRecipientList = emailList.join(",");
				}
			}

			const assignmentPayload = new AssignmentPayload(
				{
					title: !isAdminPath && assignmentType === "interview" ? `Self-Practice Interview for ${values.staticJobTitle}` : values.title,
					adminEmail: instageUser?.email || "admin@instage.io",
					adminFirstName: instageUser?.firstName || "InStage",
					adminLastName: instageUser?.lastName || "",
					additionalReportRecipients: values.additionalReportRecipients,
					emailDeliveryOptions: values.emailDeliveryOptions,
					description: values.description,
					courseName: values.courseName,
					courseCode: values.courseCode,
					courseSection: values.courseSection,
					includeCSV: values.includeCSV,
					includePDF: values.includePDF,
					includeUserIdentification: values.includeUserIdentification,
					recipientList: updatedRecipientList,
					reportFrequency: values.reportFrequency,
					sendReminders: values.notify24HoursBefore,
					experienceType: values.experienceType,
					sessions: values.sessions.map((session: any, index: number) => {
						let date = null;
						let startDate = null;
						if (session.date) {
							const localDate = new Date(session.date);
							localDate.setHours(23, 59, 0, 0);
							date = localDate.toISOString();
						}
						if (session.startDate) {
							const localDate = new Date(session.startDate);
							localDate.setHours(0, 1, 0, 0);
							startDate = localDate.toISOString();
						}

						// For interview assignments, include interview-specific data
						if (assignmentType === "interview" && values.stages) {
							// Get enabled stages and sort them by their type in the desired order (Resume Review is included in initial stages but defaulted to null)
							const stageTypeOrder = ["resume", "screening", "middle", "final"];
							const enabledStages = values.stages
								.filter((stage: InterviewStage) => stage.enabled)
								.sort(
									(a: InterviewStage, b: InterviewStage) =>
										stageTypeOrder.indexOf(a.interviewStageType) - stageTypeOrder.indexOf(b.interviewStageType)
								);
							const currentStage = enabledStages[index];
							const stagePlan = values.stagePlan[currentStage.interviewStageOrderNumber];

							return new SchedulePayload({
								...session,
								date,
								startDate,
								interview: {
									interviewStageOrderNumber: currentStage?.interviewStageOrderNumber,
									interviewStageType: currentStage?.interviewStageType,
									jobTitle: values.staticJobTitle,
									jobDescription: values.jobDescription,
									interviewQuestionList: stagePlan?.interviewQuestionList || [],
									jobSummary: values.jobSummary || "",
									jobFunction: values.jobFunction || "",
									jobExperienceLevel: values.jobExperienceLevel || "",
									jobResponsibilities: values.jobResponsibilities || [],
									jobSkillsRequired: values.jobSkillsRequired || [],
									interviewerAttitude: stagePlan?.interviewerAttitude || ""
								}
							});
						}

						// For career path assignments, include career path-specific data
						if (assignmentType === "careerPath" && values.stages) {
							// Get enabled stages and sort them by their type in the desired order (Resume Review is included in initial stages but defaulted to null)
							const stageTypeOrder = ["discovery", "progression"];
							const enabledStages = values.stages
								.filter((stage: CareerPathStage) => stage.enabled)
								.sort(
									(a: CareerPathStage, b: CareerPathStage) =>
										stageTypeOrder.indexOf(a.careerPathStageType) - stageTypeOrder.indexOf(b.careerPathStageType)
								);
							const currentStage = enabledStages[index];
							const stagePlan = values.stagePlan[currentStage.careerPathStageOrderNumber];

							return new SchedulePayload({
								...session,
								date,
								startDate,
								careerPath: {
									careerPathStageOrderNumber: currentStage?.careerPathStageOrderNumber,
									careerPathStageType: currentStage?.careerPathStageType,
									careerPathQuestionList: stagePlan?.careerPathQuestionList || [],
									careerPathAggregateQuestionList: stagePlan?.careerPathAggregateQuestionList || []
								}
							});
						}

						// For frontline assignments, include frontline-specific data
						if (assignmentType === "frontline" && values.stages) {
							// Get enabled stages
							const enabledStages = values.stages.filter((stage: FrontlineStage) => stage.enabled);
							const currentStage = enabledStages[index];

							return new SchedulePayload({
								...session,
								date,
								startDate,
								frontlinePayload: {
									frontlineProgramName: values.frontlineProgramName,
									frontlineStageOrderNumber: currentStage?.frontlineStageOrderNumber,
									frontlineStageType: currentStage?.frontlineStageType,
									config: currentStage?.config
								}
							});
						}

						// For reflection assignments or if no stage data found
						return new SchedulePayload({
							...session,
							date,
							startDate
						});
					}),
					competencies: values.competencies,
					assignmentCode: values.assignmentCode,
					includeTranscript: values.includeTranscript,
					includeAudio: values.includeAudio,
					studentNotifications: values.studentNotifications || [],
					isPublished: status === "deployed" ? true : false,
					soloMode: !isAdminPath ? true : false,
					gradingType: values.gradingType,
					gradingWeights: values.gradingWeights,
					showGradesToStudents: values.showGradesToStudents
				},
				clientSettings
			);

			// console.log("assignmentPayload", assignmentPayload);

			if (isEditMode && assignmentId) {
				const data = await AssignmentService.updateAssignment(assignmentId, assignmentPayload);
				console.log("Assignment updated successfully:", data);
				onSubmit(data);
				return data;
			} else {
				const data = await AssignmentService.createAssignment(assignmentPayload);
				console.log("Assignment created successfully:", data);

				const deeplink = sessionStorage.getItem("deeplink");
				if (deeplink) {
					const backendUrl = process.env.REACT_APP_BACKEND_URL;

					const response = await axios.post(`${backendUrl}/deep_link`, { assingment: data, ltikToken: ltiToken });
					console.log("data", response.data);
					console.log("form", response.data.form);
					document.body.insertAdjacentHTML("beforeend", response.data.form);

					$("body").append(response.data.form);
				}

				onSubmit(data);
				return data;
			}
		} catch (error) {
			console.error("Error creating assignment:", error);
		} finally {
			setIsDeploying(false);
			setIsDeployDialogOpen(false);
			if (instageUser) {
				console.log("Creating audit log");
				console.log("userId", instageUser.id);
				await FronteggService.getInstance().createAuditLog({
					userId: instageUser.id,
					fronteggId: instageUser?.fronteggId || "",
					tenantId: instageUser.clientId,
					action: `Created or Updated Assignment: ${values.title} `,
					severity: "Info"
				});
			}
		}
		return null;
	};

	// Extract common button content into a component
	const DeployButton: React.FC<{ className?: string }> = ({ className }) => (
		<Button
			onClick={() => handleCreateAssignment("deployed")}
			disabled={isDeploying}
			className={className}
			aria-label={
				isDeploying
					? t("deployment.deploying")
					: values.isPublished
					? isAdminPath
						? t("deployment.updateAssignment")
						: t("deployment.update")
					: isAdminPath
					? t("deployment.deployAssignment")
					: t("deployment.create")
			}
		>
			{isDeploying ? (
				<>
					<Loader2 className="mr-2 h-4 w-4 animate-spin" />
					{t("deployment.deploying")}
				</>
			) : values.isPublished ? (
				isAdminPath ? (
					t("deployment.updateAssignment")
				) : (
					t("deployment.update")
				)
			) : isAdminPath ? (
				t("deployment.deployAssignment")
			) : (
				t("deployment.create")
			)}
		</Button>
	);

	const PreviousButton: React.FC<{ className?: string }> = ({ className }) => (
		<Button
			variant="secondary"
			onClick={() => {
				onPrevious();
			}}
			className={className}
			aria-label="Go to previous step"
		>
			{t("deployment.previous")}
		</Button>
	);

	// Helper function to get stage plan
	const getStagePlan = (stage: InterviewStage | CareerPathStage) => {
		const orderNumber = "interviewStageOrderNumber" in stage ? stage.interviewStageOrderNumber : stage.careerPathStageOrderNumber;
		return values.stagePlan?.[orderNumber - 1] || null;
	};

	// Helper function to get stage session
	const getStageSession = (stage: InterviewStage | FrontlineStage | CareerPathStage) => {
		const orderNumber =
			"interviewStageOrderNumber" in stage
				? stage.interviewStageOrderNumber
				: "careerPathStageOrderNumber" in stage
				? stage.careerPathStageOrderNumber
				: stage.frontlineStageOrderNumber;
		return values.sessions?.[orderNumber - 1] || null;
	};

	// Sort stages by order number for both interview and frontline assignments
	const sortedStages = React.useMemo(() => {
		if (!values.stages) return [];
		if (assignmentType === "interview") {
			return values.stages.slice().sort((a: InterviewStage, b: InterviewStage) => a.interviewStageOrderNumber - b.interviewStageOrderNumber);
		}
		if (assignmentType === "frontline") {
			return values.stages.slice().sort((a: FrontlineStage, b: FrontlineStage) => a.frontlineStageOrderNumber - b.frontlineStageOrderNumber);
		}
		if (assignmentType === "careerPath") {
			return values.stages
				.slice()
				.sort((a: CareerPathStage, b: CareerPathStage) => a.careerPathStageOrderNumber - b.careerPathStageOrderNumber);
		}
		return [];
	}, [values.stages, assignmentType]);

	// Get enabled stages for both interview and frontline
	const enabledStages = React.useMemo(() => {
		return sortedStages.filter((stage: InterviewStage | FrontlineStage | CareerPathStage) => stage.enabled);
	}, [sortedStages]);

	return (
		<div className="space-y-8">
			<Panel
				title={
					isAdminPath
						? t("reviewAssignment.title")
						: ` ${t("reviewAssignment.reviewSelfPractice")} ${
								assignmentType === "interview" ? t("reviewAssignment.interview") : t("reviewAssignment.reflection")
						  }`
				}
			>
				<p className="text-base text-gray-600 mb-6">
					{isAdminPath ? t("reviewAssignment.adminReview") : t("reviewAssignment.studentReview")}
				</p>
			</Panel>

			{/* Sessions/Stages Panel */}
			<Panel title={t("dates.title")} onEdit={() => onEdit(getStepIndex("dates"))}>
				{assignmentType === "reflection"
					? // Reflection sessions
					  sortedSessions.map((session, index) => {
							const sessionTitle = getSessionTitle(session, index);
							const reflectionNumber = sessionTitle.startsWith(t("dates.reflection")) ? index : null;
							return (
								<div key={index} className="mb-6">
									<h4 className="font-semibold text-gray-800 mb-2">
										{reflectionNumber ? `${t("dates.reflection")} ${reflectionNumber}` : sessionTitle}
									</h4>
									<Detail label={t("dates.dueDate")} value={formatDate(session.date)} />
									<Detail label={t("dates.availableFrom")} value={getAvailabilityDateText(session, index)} />
								</div>
							);
					  })
					: assignmentType === "jobSearch"
					? // Job Search sessions
					  sortedSessions.map((session, index) => {
							return (
								<div key={index} className="mb-6">
									<h4 className="font-semibold text-gray-800 mb-2">{`${t("dates.call")} ${index + 1}`}</h4>
									<Detail label={t("dates.dueDate")} value={formatDate(session.date)} />
									<Detail label={t("dates.availableFrom")} value={getAvailabilityDateText(session, index)} />
								</div>
							);
					  })
					: assignmentType === "interview" || assignmentType === "careerPath"
					? // Interview stages
					  enabledStages.map((stage: InterviewStage | CareerPathStage) => {
							const stagePlan = getStagePlan(stage);
							const stageSession = getStageSession(stage);

							let stageDueDate = null;
							let stageStartDate = null;

							// Get dates from the session first, then fall back to stage dates
							if (stageSession) {
								stageDueDate = stageSession.date;
								stageStartDate = stageSession.startDate;
							} else if ("date" in stage && "startDate" in stage) {
								stageDueDate = stage.date;
								stageStartDate = stage.startDate;
							}

							return (
								<div
									key={"interviewStageOrderNumber" in stage ? stage.interviewStageOrderNumber : stage.careerPathStageOrderNumber}
									className="mb-6 border rounded-lg p-4"
								>
									<div
										className="flex justify-between items-center cursor-pointer"
										onClick={() =>
											toggleSessionExpand(
												"interviewStageOrderNumber" in stage
													? stage.interviewStageOrderNumber
													: stage.careerPathStageOrderNumber
											)
										}
										role="button"
										tabIndex={0}
										aria-expanded={expandedSessions.includes(
											"interviewStageOrderNumber" in stage ? stage.interviewStageOrderNumber : stage.careerPathStageOrderNumber
										)}
										aria-label={`${stage.title} session details`}
										onKeyPress={(e) => {
											if (e.key === "Enter" || e.key === " ") {
												toggleSessionExpand(
													"interviewStageOrderNumber" in stage
														? stage.interviewStageOrderNumber
														: stage.careerPathStageOrderNumber
												);
											}
										}}
									>
										<h4 className="font-semibold text-gray-800">{t(`dates.stageTitles.${stripTrailingNumber(stage.title)}`)}</h4>
										<Button
											variant="ghost"
											size="sm"
											className="h-8 px-2 hover:bg-gray-100"
											aria-label={`${
												expandedSessions.includes(
													"interviewStageOrderNumber" in stage
														? stage.interviewStageOrderNumber
														: stage.careerPathStageOrderNumber
												)
													? "Collapse"
													: "Expand"
											} ${stage.title} details`}
										>
											{expandedSessions.includes(
												"interviewStageOrderNumber" in stage
													? stage.interviewStageOrderNumber
													: stage.careerPathStageOrderNumber
											) ? (
												<ChevronUpIcon className="h-5 w-5 text-gray-500" />
											) : (
												<ChevronDownIcon className="h-5 w-5 text-gray-500" />
											)}
										</Button>
									</div>

									<div className="space-y-2 mt-4">
										<div className="grid grid-cols-2 gap-x-4">
											<Detail label={t("dates.dueDate")} value={formatDate(stageDueDate)} />
											<Detail label={t("dates.availableFrom")} value={formatDate(stageStartDate)} />
										</div>
									</div>

									{expandedSessions.includes(
										"interviewStageOrderNumber" in stage ? stage.interviewStageOrderNumber : stage.careerPathStageOrderNumber
									) &&
										stagePlan && (
											<div className="mt-6 space-y-4">
												<Detail
													label={t("dates.questions")}
													value={`${stagePlan.interviewQuestionList?.length || 0} ${t("dates.questions").toLowerCase()}`}
												/>
												{stagePlan.interviewQuestionList && stagePlan.interviewQuestionList.length > 0 && (
													<div>
														<h5 className="text-gray-600 mb-2">{t("dates.interviewQuestions")}:</h5>
														<ul className="list-disc pl-5 space-y-1">
															{stagePlan.interviewQuestionList.map((question: { text: string }, index: number) => (
																<li key={index} className="text-gray-800">
																	{question.text}
																</li>
															))}
														</ul>
													</div>
												)}
												<Detail
													label={t("dates.interviewerAttitude")}
													value={
														(stagePlan.interviewerAttitude || "Neutral").charAt(0).toUpperCase() +
														(stagePlan.interviewerAttitude || "Neutral").slice(1)
													}
												/>
											</div>
										)}
								</div>
							);
					  })
					: assignmentType === "checkInSim"
					? // Check-In sessions
					  sortedSessions.map((session, index) => {
							const sessionTitle = getSessionTitle(session, index);
							const checkInNumber = sessionTitle.startsWith(t("dates.checkIn")) ? index : null;
							return (
								<div key={index} className="mb-6">
									<h4 className="font-semibold text-gray-800 mb-2">
										{checkInNumber ? `${t("dates.checkIn")} ${checkInNumber}` : sessionTitle}
									</h4>
									<Detail label={t("dates.dueDate")} value={formatDate(session.date)} />
									<Detail label={t("dates.availableFrom")} value={getAvailabilityDateText(session, index)} />
								</div>
							);
					  })
					: // Frontline stages
					  enabledStages.map((stage: FrontlineStage) => {
							const stageSession = getStageSession(stage);

							return (
								<div key={stage.frontlineStageOrderNumber} className="mb-6 border rounded-lg p-4">
									<div
										className="flex justify-between items-center cursor-pointer"
										onClick={() => toggleSessionExpand(stage.frontlineStageOrderNumber)}
										role="button"
										tabIndex={0}
										aria-expanded={expandedSessions.includes(stage.frontlineStageOrderNumber)}
										aria-label={`${t("dates.session")} ${stage.frontlineStageOrderNumber} details`}
										onKeyPress={(e) => {
											if (e.key === "Enter" || e.key === " ") {
												toggleSessionExpand(stage.frontlineStageOrderNumber);
											}
										}}
									>
										<h4 className="font-semibold text-gray-800">
											{t("dates.session")} {stage.frontlineStageOrderNumber}
										</h4>
										<Button
											variant="ghost"
											size="sm"
											className="h-8 px-2 hover:bg-gray-100"
											aria-label={`${expandedSessions.includes(stage.frontlineStageOrderNumber) ? "Collapse" : "Expand"} ${t(
												"dates.session"
											)} ${stage.frontlineStageOrderNumber} details`}
										>
											{expandedSessions.includes(stage.frontlineStageOrderNumber) ? (
												<ChevronUpIcon className="h-5 w-5 text-gray-500" />
											) : (
												<ChevronDownIcon className="h-5 w-5 text-gray-500" />
											)}
										</Button>
									</div>

									<div className="space-y-2 mt-4">
										<div className="grid grid-cols-2 gap-x-4">
											<Detail label={t("dates.dueDate")} value={formatDate(stageSession?.date)} />
											<Detail label={t("dates.availableFrom")} value={formatDate(stageSession?.startDate)} />
										</div>
										<Detail
											label={t("dates.framework")}
											value={
												stage.frontlineStageType === "deny"
													? t("dates.frameworkTypes.deny")
													: stage.frontlineStageType === "comply"
													? t("dates.frameworkTypes.comply")
													: t("dates.frameworkTypes.gather")
											}
										/>
									</div>

									{expandedSessions.includes(stage.frontlineStageOrderNumber) && stage.config && (
										<div className="mt-6 space-y-4">
											<div className="grid grid-cols-2 gap-x-4">
												<Detail label={t("dates.studentRole")} value={stage.config.frontlineStudentRole} />
												<Detail label={t("dates.aiRole")} value={stage.config.frontlineAssistantRole} />
											</div>

											<Detail label={t("dates.aiProblem")} value={stage.config.frontlineAssistantProblem} />
											<Detail label={t("dates.aiRequest")} value={stage.config.frontlineAssistantRequest} />

											{stage.frontlineStageType === "deny" && stage.config.frontlineIssueWithRequest && (
												<Detail label={t("dates.issueWithRequest")} value={stage.config.frontlineIssueWithRequest} />
											)}

											{(stage.frontlineStageType === "comply" || stage.frontlineStageType === "gather") &&
												stage.config.frontlineInfoToCollect &&
												stage.config.frontlineInfoToCollect.length > 0 && (
													<div>
														<h5 className="text-gray-600 mb-2">{t("dates.infoToGather")}:</h5>
														<ul className="list-disc pl-5 space-y-1">
															{stage.config.frontlineInfoToCollect.map((info, index) => (
																<li key={index} className="text-gray-800">
																	{info}
																</li>
															))}
														</ul>
													</div>
												)}

											{stage.config.frontlineKeyPoints && stage.config.frontlineKeyPoints.length > 0 && (
												<div>
													<h5 className="text-gray-600 mb-2">{t("dates.keyPoints")}:</h5>
													<ul className="list-disc pl-5 space-y-1">
														{stage.config.frontlineKeyPoints.map((point, index) => (
															<li key={index} className="text-gray-800">
																{point}
															</li>
														))}
													</ul>
												</div>
											)}
										</div>
									)}
								</div>
							);
					  })}
			</Panel>

			{(assignmentType === "reflection" || assignmentType === "interview") && (
				<Panel
					title={
						assignmentType === "reflection" ? t("setup.reflectionSetup") : assignmentType === "interview" ? t("setup.interviewSetup") : ""
					}
					onEdit={() => {
						const setupStep = getStepIndex("setup");
						if (setupStep >= 0) onEdit(setupStep);
					}}
				>
					{assignmentType === "reflection" && values.competencies && values.competencies.length > 0 && (
						// Reflection-specific details
						<div className="flex">
							<span className="font-normal text-gray-600 w-1/3">{t("setup.selectedCompetencies")}:</span>
							<span className="text-gray-800">{values.competencies.join(", ")}</span>
						</div>
					)}
					{assignmentType === "interview" && (
						// Interview-specific details
						<Detail
							label={t("setup.jobTitle")}
							value={values.jobTitleChoice === "Static" ? values.staticJobTitle : t("setup.studentsWillSelect")}
						/>
					)}
				</Panel>
			)}

			{assignmentType === "careerPath" && (
				<Panel title="Aptitude Discovery Session Questions" onEdit={() => onEdit(getStepIndex("details"))}>
					<div className="space-y-6">
						{" "}
						{/* Add consistent vertical spacing */}
						{values.stagePlan?.[1].careerPathQuestionList.map((question: { text: string }, index: number) => (
							<Detail key={index} label={`Question ${index + 1}`} value={question.text} width="150" />
						))}
					</div>
				</Panel>
			)}

			{isAdminPath && (
				<>
					<Panel title={t("details.title")} onEdit={() => onEdit(getStepIndex("details"))}>
						<Detail label={t("details.assignmentTitle")} value={values.title} />
						<Detail label={t("details.description")} value={values.description} />
						{isAdminPath && assignmentType !== "checkInSim" && (
							<>
								<Detail label={t("details.courseName")} value={values.courseName} />
								<Detail label={t("details.courseCode")} value={values.courseCode} />
								<Detail label={t("details.courseSection")} value={values.courseSection} />
							</>
						)}
					</Panel>

					<Panel title={t("grading.title")} onEdit={() => onEdit(getStepIndex("details"))}>
						<Detail
							label={t("grading.gradingType")}
							value={
								values.gradingType === "none"
									? t("grading.none")
									: values.gradingType === "passOrFail"
									? t("grading.passOrFail")
									: values.gradingType === "numeric"
									? t("grading.numeric")
									: values.gradingType
							}
						/>

						{values.gradingType === "numeric" && values.gradingWeights && (
							<div className="mt-4 pt-4">
								<Detail label={t("grading.professionalism")} value={`${values.gradingWeights.professionalism}%`} />
								<Detail label={t("grading.checklistItems")} value={`${values.gradingWeights.checklist}%`} />
							</div>
						)}
					</Panel>

					<Panel title={t("distribution.title")} onEdit={() => onEdit(getStepIndex("details"))}>
						{values.recipientList && (
							<div className="mt-4">
								<h4 className="text-md font-normal text-gray-700 mb-2">{t("distribution.addedEmails")}:</h4>
								<ul className="list-disc pl-5 space-y-1">
									{values.recipientList
										.split(",")
										.slice(0, 10)
										.map((email: string, index: number) => (
											<li key={index} className="text-sm text-gray-800">
												{email.trim()}
											</li>
										))}
									{values.recipientList.split(",").length > 10 && (
										<li className="text-sm text-gray-800">... {values.recipientList.split(",").length - 10} more</li>
									)}
								</ul>
							</div>
						)}
						<div className="mt-2">
							<h4 className="text-md font-normal text-gray-700 mb-2">{t("distribution.signupLink")}:</h4>
							<div className="flex items-center space-x-2">
								<span className="text-sm text-gray-800">{`https://www.reflection.instage.io/assignment/${
									values.assignmentCode || "ABC123"
								}`}</span>
								<Button
									variant="outline"
									size="icon"
									onClick={() =>
										copyToClipboard(`https://www.reflection.instage.io/assignment/${values.assignmentCode || "ABC123"}`)
									}
									aria-label={t("copyToClipboard")}
								>
									<ClipboardIcon className="h-5 w-5" />
								</Button>
							</div>
						</div>
					</Panel>

					<Panel title={t("notifications.title")} onEdit={() => onEdit(getStepIndex("details"))}>
						<Detail label={t("notifications.enrollment")} value={t("notifications.yes")} />
						<Detail label={t("notifications.report")} value={t("notifications.yes")} />
						{clientSettings?.allowNotifyAvailabilityWindow && (
							<Detail
								label={t("notifications.availability")}
								value={
									values.studentNotifications?.includes(StudentNotications.notifyAvailabilityWindow)
										? t("notifications.yes")
										: t("notifications.no")
								}
							/>
						)}
						{clientSettings?.allowNotify24HoursBefore && (
							<Detail
								label={t("notifications.reminder24h")}
								value={
									values.studentNotifications?.includes(StudentNotications.notify24HoursBefore)
										? t("notifications.yes")
										: t("notifications.no")
								}
							/>
						)}
						{clientSettings?.allowNotifyDueDate && (
							<Detail
								label={t("notifications.dueDate")}
								value={
									values.studentNotifications?.includes(StudentNotications.notifyDueDate)
										? t("notifications.yes")
										: t("notifications.no")
								}
							/>
						)}
					</Panel>

					<Panel title={t("summary.title")} onEdit={() => onEdit(getStepIndex("details"))}>
						{clientSettings?.allowUserIdentification && (
							<Detail
								label={t("summary.includeStudentInfo")}
								value={values.includeUserIdentification ? t("summary.yes") : t("summary.no")}
							/>
						)}
						{assignmentType !== "interview" && clientSettings?.allowTranscript && (
							<Detail label={t("summary.includeTranscript")} value={values.includeTranscript ? t("summary.yes") : t("summary.no")} />
						)}
						{clientSettings?.allowAudio && (
							<Detail label={t("summary.includeAudio")} value={values.includeAudio ? t("summary.yes") : t("summary.no")} />
						)}
						<Detail
							label={t("summary.reportFrequency")}
							value={
								values.reportFrequency === "afterEachDueDate"
									? t("summary.afterEachDueDate")
									: values.reportFrequency === "afterFinalDueDate"
									? t("summary.afterFinalDueDate")
									: t("summary.noReports")
							}
						/>
						{values.reportFrequency !== "noReports" && (
							<>
								<Detail label={t("summary.includeCsv")} value={values.includeCSV ? t("summary.yes") : t("summary.no")} />
								<Detail label={t("summary.includePdf")} value={values.includePDF ? t("summary.yes") : t("summary.no")} />
								{values.additionalReportRecipients && (
									<div className="mt-4">
										<h4 className="text-md font-normal text-gray-700 mb-2">{t("summary.additionalRecipients")}:</h4>
										<ul className="list-disc pl-5 space-y-1">
											{values.additionalReportRecipients.split(",").map((email: string, index: number) => (
												<li key={index} className="text-sm text-gray-800">
													{email.trim()}
												</li>
											))}
										</ul>
									</div>
								)}
							</>
						)}
						<p className="mt-2 text-sm text-gray-600 italic">{t("summary.dashboardNote")}</p>
					</Panel>
				</>
			)}

			{/* Navigation and action buttons - and mobile desktop */}
			<div className="block md:flex justify-end mt-8 gap-2">
				<PreviousButton />
				<div className="space-x-4">
					{!values.isPublished && false && (
						<Button variant="outline" onClick={() => handleCreateAssignment("draft")} aria-label="Save assignment as draft">
							{t("saveDraft")}
						</Button>
					)}
					<DeployButton />
				</div>
			</div>

			{/* Navigation and action buttons - mobile */}
			{/* <div className="flex gap-2 mt-8 md:hidden">
				<PreviousButton />
				<DeployButton />
			</div> */}

			{/* Deployment confirmation dialog */}
			<AlertDialog open={isDeployDialogOpen} onOpenChange={setIsDeployDialogOpen}>
				<AlertDialogContent>
					<AlertDialogHeader>
						<AlertDialogTitle>{t("deployment.confirmTitle")}</AlertDialogTitle>
						<AlertDialogDescription>{t("deployment.importantNotes")}:</AlertDialogDescription>
						<ul className="list-disc pl-5 space-y-2 text-sm text-muted-foreground">
							<li>{t("deployment.emailNotification")}</li>
							<li>{t("deployment.dueDatesNote")}</li>
							<li>{t("deployment.addStudentsNote")}</li>
						</ul>
					</AlertDialogHeader>
					<AlertDialogFooter>
						<AlertDialogCancel onClick={() => setIsDeployDialogOpen(false)} disabled={isDeploying} aria-label={t("deployment.cancel")}>
							{t("deployment.cancel")}
						</AlertDialogCancel>
						{/* Deploy Assignment: Where actual deployment happens */}
						<AlertDialogAction
							onClick={() => createAssignment("deployed")}
							disabled={isDeploying}
							aria-label={t("deployment.confirmButton")}
						>
							{isDeploying ? (
								<>
									<Loader2 className="mr-2 h-4 w-4 animate-spin" />
									{t("deployment.deploying")}
								</>
							) : (
								t("deployment.confirmButton")
							)}
						</AlertDialogAction>
						{!values.isPublished && false && (
							<Button
								variant="outline"
								onClick={() => createAssignment("draft")}
								disabled={isDeploying}
								aria-label={t("deployment.saveAsDraft")}
							>
								{t("deployment.saveAsDraft")}
							</Button>
						)}
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialog>
		</div>
	);
};

// Panel component for grouping related details
const Panel: React.FC<{ title: string; children: React.ReactNode; onEdit?: () => void }> = ({ title, children, onEdit }) => {
	const { t } = useTranslation("assignmentReview");

	return (
		<div className="bg-white border border-[#00a9af] rounded-lg p-6">
			<div className="flex justify-between items-center mb-4">
				<h3 className="text-lg font-medium text-gray-800">{title}</h3>
				{onEdit && (
					<Button variant="outline" onClick={onEdit} aria-label={`Edit ${title.toLowerCase()} section`}>
						<PencilIcon className="h-5 w-5 mr-1" />
						{t("edit")}
					</Button>
				)}
			</div>
			<div className="space-y-2">{children}</div>
		</div>
	);
};

// Detail component for displaying individual details
const Detail: React.FC<{ label: string; value: string | number; width?: string }> = ({ label, value, width = "150" }) => (
	<div className={`grid grid-cols-[${width}px,1fr] gap-4 mb-4`}>
		<div className="text-gray-600 font-semibold">{label}:</div>
		<div className="text-gray-800">{value}</div>
	</div>
);

export default ReviewAssignment;
