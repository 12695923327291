import axios from "axios";
import { SessionService, VapiCallType } from "./SessionService";
interface CreatePhoneCallV3Response {
	id: string;
	createdAt: string;
	updatedAt: string;
	customer?: {
		number?: string;
	};
}
export class VapiService {
	private static baseUrl = `${process.env.REACT_APP_BACKEND_URL}/api/vapi`;

	public static async createPhoneCallV3(userId: string, sessionId: string, postHogSessionId?: string): Promise<string> {
		console.log("session started", sessionId);
		const response = await axios.post<CreatePhoneCallV3Response>(`${this.baseUrl}/create-phone-callV3`, {
			userId,
			sessionId: sessionId,
			postHogSessionId: postHogSessionId
		});
		console.log("response", response);
		return response.data.id;
	}
}
