import axios from "axios";

type Severity = "Info" | "Warning" | "Error" | "Critical";

interface CreateAuditLogParams {
	action: string;
	severity: Severity;
	userId: string;
	tenantId: string;
	fronteggId: string;
}

class FronteggService {
	private static instance: FronteggService;
	private baseUrl: string;

	private constructor() {
		this.baseUrl = `${process.env.REACT_APP_BACKEND_URL}/api/customer-portal`;
	}

	public static getInstance(): FronteggService {
		if (!FronteggService.instance) {
			FronteggService.instance = new FronteggService();
		}
		return FronteggService.instance;
	}

	public async createAuditLog({ action, severity, userId, tenantId, fronteggId }: CreateAuditLogParams): Promise<void> {
		if (!userId || !tenantId || !fronteggId) {
			console.error("User ID or Tenant ID or Frontegg ID not available");
			return;
		}

		try {
			// console.log("base URL", this.baseUrl);
			await axios.post(`${this.baseUrl}/createAuditLog`, {
				userId,
				tenantId,
				action,
				severity,
				fronteggId
			});
			// console.log("finished creating audit log 2");
		} catch (error) {
			console.error("Failed to create audit log:", error);
			throw error;
		}
	}
}

export default FronteggService;
