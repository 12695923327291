export const InterviewCreatorDatesLocale = {
	en: {
		jobTitleSelect: {
			selectJobTitle: "Select job title...",
			searchJobTitles: "Search job titles or create your own"
		},
		jobPostings: {
			customJobTitle: "Custom Job Title",
			postedJobs: "Posted Jobs",
			positionsFound: "positions found"
		},
		interviewCreatorDates: "Interview Creator Dates",
		configureYourInterviewAssignmentPannel: {
			title: "Configure Your Interview Assignment",
			description:
				"Welcome to the Interview Assignment setup! First, configure the job details and then select which interview stages you want students to complete. Students will progress through all chosen stages in order, simulating a real-world hiring process.",
			nextSteps: "Once you finish here, you'll move on to customizing questions and assigning the activity to your students."
		},
		configureYourInterviewPannel: {
			description:
				"Welcome to your practice interview setup! First, specify the job you're targeting and then select which interview stages you want to practice. You'll progress through all chosen stages in order, simulating a real-world hiring process.",
			nextSteps: "Once you finish here, you'll move on to customizing the questions for your practice interviews."
		},
		dateRangePicker: {
			dueDate: "Due Date",
			dueDateTooltip: "The due date by which students must complete this specific interview.",
			startOfAvailability: "Start of Availability Window",
			startOfAvailabilityTooltip: "The start date of the availability window for this specific interview.",
			selectDueDate: "Select due date",
			selectStartDate: "Select start date"
		},
		interviewSetup: {
			step1: {
				title: "Step 1: Configure Job Details",
				jobTitleChoice: {
					label: "Allow Students to Input a Desired Job Title?",
					tooltip: "If 'Yes', students will be able to input their desired job title",
					options: {
						yes: "Yes",
						no: "No"
					},
					placeholder: "Students Choose Job Title"
				},
				jobTitle: {
					label: "Job Title *",
					placeholder: "Enter the job title..."
				},
				jobDescription: {
					label: "Job Description *",
					placeholder: "Enter the job description..."
				}
			},
			step2: {
				title: "Step 2: Select Interview Stages",
				tooltip: "All selected stages are mandatory and completed in sequence, providing a continuous, context-aware interview experience.",
				description: {
					admin: "Choose which stages to include in this assignment. Every student will complete all selected stages, regardless of performance, to mirror a full hiring process. Information from earlier interviews will carry over to later ones, creating a rich, context-aware experience.",
					student:
						"Choose which interview stages you want to practice. You'll complete all selected stages in order, just like in a real hiring process. Information from your earlier interviews will carry over to later ones, creating a realistic interview experience."
				},
				addButton: {
					label: "Add Interview",
					ariaLabel: "Add additional interview stage"
				},
				stageTooltips: {
					screening: "This initial stage simulates a company's first screening round, setting the context for subsequent interviews.",
					middle: "Use one or more middle stages to simulate follow-up rounds. Later, you'll customize these questions to be more technical, behavioral, or a blend of both.",
					final: "The concluding stage simulates a final-round interview, informed by prior stages."
				},
				stages: {
					"Screening Interview": {
						title: "Screening Interview",
						description: "Initial screening stage for candidate evaluation"
					},
					"Middle Interview": {
						title: "Middle Interview",
						description: "Additional interview stage for candidate evaluation"
					},
					"Final Interview": {
						title: "Final Interview",
						description: "Concluding interview stage for candidate evaluation"
					}
				}
			},
			step3: {
				title: "Step 3: Set Interview Schedule",
				description: {
					admin: "Decide how to schedule these interviews. If you choose a 'Shared Schedule,' all stages share the same start and due dates. Otherwise, set different availability windows for each stage. Students must complete each stage by its due date before proceeding.",
					student:
						"Plan when you'll take your practice interviews. You can either set one schedule for all stages or different times for each stage. Make sure to give yourself enough time to prepare for each interview stage."
				},
				sharedSchedule: {
					title: "Shared Schedule",
					description: "Use one schedule for all interview stages. Toggle this off if you need to set different dates for each stage.",
					tooltip:
						"If enabled, all stages share the same start and due dates. If disabled, you can assign different timelines to each stage.",
					editModeMessage: "Schedule settings are locked in edit mode"
				},
				individualDatesMessage: "Individual date selection is now enabled. Please set specific dates for each active interview stage above."
			},
			navigation: {
				next: {
					label: "Next",
					ariaLabel: "Proceed to next step"
				},
				previous: {
					label: "Previous",
					ariaLabel: "Return to previous step"
				}
			}
		}
	},
	fr: {
		jobTitleSelect: {
			selectJobTitle: "Sélectionner le titre du poste...",
			searchJobTitles: "Rechercher des titres de poste ou créer votre propre titre"
		},
		jobPostings: {
			customJobTitle: "Titre du poste personnalisé",
			postedJobs: "Postes publiés",
			positionsFound: "positions trouvées"
		},
		interviewCreatorDates: "Dates des créateurs d'entretiens",
		configureYourInterviewAssignmentPannel: {
			title: "Configurez votre tâche d'entretien",
			description:
				"Bienvenue dans la configuration de votre tâche d'entretien ! Premièrement, configurez les détails du poste et sélectionnez les étapes d'entretien que vous souhaitez que les étudiants effectuent. Les étudiants progresseront par ordre de toutes les étapes choisies, simulant un processus de recrutement réel.",
			nextSteps:
				"Une fois que vous avez terminé ici, vous passerez à la personnalisation des questions et à l'attribution de l'activité à vos étudiants."
		},
		configureYourInterviewPannel: {
			description:
				"Bienvenue dans la configuration de votre entretien pratique ! Premièrement, spécifiez le poste que vous souhaitez cibler et sélectionnez les étapes d'entretien que vous souhaitez pratiquer. Vous progresserez par ordre de toutes les étapes choisies, simulant un processus de recrutement réel.",
			nextSteps: "Une fois que vous avez terminé ici, vous passerez à la personnalisation des questions pour vos entretiens pratiques."
		},
		dateRangePicker: {
			dueDate: "Date limite",
			dueDateTooltip: "La date limite par laquelle les étudiants doivent compléter cette entretien spécifique.",
			startOfAvailability: "Date de début de la fenêtre de disponibilité",
			startOfAvailabilityTooltip: "La date de début de la fenêtre de disponibilité pour cette entretien spécifique.",
			selectDueDate: "Sélectionner la date limite",
			selectStartDate: "Sélectionner la date de début"
		},
		interviewSetup: {
			step1: {
				title: "Étape 1 : Configurer les détails du poste",
				jobTitleChoice: {
					label: "Autoriser les étudiants à saisir le titre du poste souhaité ?",
					tooltip: "Si 'Oui', les étudiants pourront saisir le titre du poste qu'ils souhaitent",
					options: {
						yes: "Oui",
						no: "Non"
					},
					placeholder: "Les étudiants choisissent le titre du poste"
				},
				jobTitle: {
					label: "Titre du poste *",
					placeholder: "Entrez le titre du poste..."
				},
				jobDescription: {
					label: "Description du poste *",
					placeholder: "Entrez la description du poste..."
				}
			},
			step2: {
				title: "Étape 2 : Sélectionner les étapes d'entretien",
				tooltip:
					"Toutes les étapes sélectionnées sont obligatoires et complétées dans l'ordre, offrant une expérience d'entretien continue et contextuelle.",
				description: {
					admin: "Choisissez les étapes à inclure dans cette tâche. Chaque étudiant complètera toutes les étapes sélectionnées, indépendamment de sa performance, pour refléter un processus d'embauche complet. Les informations des entretiens précédents seront transmises aux suivants, créant une expérience riche et contextuelle.",
					student:
						"Choisissez les étapes d'entretien que vous souhaitez pratiquer. Vous compléterez toutes les étapes sélectionnées dans l'ordre, comme dans un véritable processus d'embauche. Les informations de vos entretiens précédents seront transmises aux suivants, créant une expérience d'entretien réaliste."
				},
				addButton: {
					label: "Ajouter un entretien",
					ariaLabel: "Ajouter une étape d'entretien supplémentaire"
				},
				stageTooltips: {
					screening:
						"Cette étape initiale simule le premier tour de sélection d'une entreprise, établissant le contexte pour les entretiens suivants.",
					middle: "Utilisez une ou plusieurs étapes intermédiaires pour simuler les tours suivants. Plus tard, vous pourrez personnaliser ces questions pour qu'elles soient plus techniques, comportementales ou un mélange des deux.",
					final: "L'étape finale simule un entretien de dernier tour, enrichi par les étapes précédentes."
				},
				stages: {
					"Screening Interview": {
						title: "Entretien de sélection",
						description: "Première étape de sélection pour l'évaluation des candidats"
					},
					"Middle Interview": {
						title: "Entretien intermédiaire",
						description: "Étape intermédiaire pour l'évaluation des candidats"
					},
					"Final Interview": {
						title: "Entretien final",
						description: "Dernière étape pour l'évaluation des candidats"
					}
				}
			},
			step3: {
				title: "Étape 3 : Définir le calendrier des entretiens",
				description: {
					admin: "Décidez comment planifier ces entretiens. Si vous choisissez un 'Calendrier partagé', toutes les étapes partagent les mêmes dates de début et de fin. Sinon, définissez différentes fenêtres de disponibilité pour chaque étape. Les étudiants doivent compléter chaque étape avant sa date limite avant de passer à la suivante.",
					student:
						"Planifiez quand vous passerez vos entretiens pratiques. Vous pouvez soit définir un calendrier pour toutes les étapes, soit des horaires différents pour chaque étape. Assurez-vous de vous donner suffisamment de temps pour préparer chaque étape d'entretien."
				},
				sharedSchedule: {
					title: "Calendrier partagé",
					description:
						"Utilisez un calendrier pour toutes les étapes d'entretien. Désactivez cette option si vous devez définir des dates différentes pour chaque étape.",
					tooltip:
						"Si activé, toutes les étapes partagent les mêmes dates de début et de fin. Si désactivé, vous pouvez attribuer des délais différents à chaque étape.",
					editModeMessage: "Les paramètres du calendrier sont verrouillés en mode édition"
				},
				individualDatesMessage:
					"La sélection de dates individuelles est maintenant activée. Veuillez définir des dates spécifiques pour chaque étape active ci-dessus."
			},
			navigation: {
				next: {
					label: "Suivant",
					ariaLabel: "Passer à l'étape suivante"
				},
				previous: {
					label: "Précédent",
					ariaLabel: "Retourner à l'étape précédente"
				}
			}
		}
	}
};
