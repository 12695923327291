import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import ReflectionSetup from "./Steps_Reflection/ReflectionSetup";
import ReflectionDates from "./Steps_Reflection/ReflectionDates";
import AssignmentDetails from "./Shared_Steps/AssignmentDetails";
import ReviewAssignment from "./Shared_Steps/ReviewAssignment";
import StepNavigation from "./StepNavigation";
import { AssignmentPayload } from "../../../types/AssignmentPayload";
import { AssignmentService } from "../../../services/AssignmentService";
import { AdminAssignmentView } from "../../../types/AdminAssignmentView";
import { NavigateService } from "../../../services/NavigateService";
import { CalendarIcon, ClipboardDocumentListIcon, WrenchScrewdriverIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import BackButton from "../../Utility/BackButton";
import { Button } from "../../shadcn-ui/Button";
import InterviewDates from "./Steps_Interview/InterviewDates";
import InterviewSetup from "./Steps_Interview/InterviewSetup";
import FrontlineDates from "./Steps_Frontline/FrontlineDates";
// import FrontlineSetup from "./Steps_Frontline/FrontlineSetup";
import JobSearchDates from "./Steps_JobSearch/JobSearchDates";
import { useClientSettings } from "../../../contexts/ClientSettingsContext";
import { ExperienceType } from "../../../schemas/ExperienceType";
import { InterviewStagePlan } from "../../../types/InterviewPayload";
import { useAuth } from "../../../contexts/AuthContext";
import { useAppContext } from "../../../contexts/AppContext";
import CheckInSimDates from "./Steps_CheckInSim/CheckInSimDates";
import CheckInSimSetup from "./Steps_CheckInSim/CheckInSimSetup";
import FrontlineSetup from "./Steps_Frontline/FrontlineSetup";
import CareerPathDates from "./Steps_CareerPath/CareerPathDates";
import CareerPathSetup from "./Steps_CareerPath/CareerPathSetup";
import { useTranslation } from "../../Utility/UseTranslation";

// Define the props for the CreateAssignment component
interface CreateAssignmentProps {
	mode: "create" | "edit";
	assignmentType?: "reflection" | "interview" | "frontline" | "jobSearch" | "checkInSim" | "careerPath";
	existingAssignment?: AssignmentPayload;
}

// Define the props for each step component
type StepProps = {
	values: any;
	errors: any;
	touched: any;
	setFieldValue: (field: string, value: any) => void;
	setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
	onNext: () => void;
	onPrevious: () => void;
	onEdit: (stepIndex: number) => void;
	isValid: boolean;
	dirty: boolean;
	isEditMode: boolean;
	onSubmit: (assignmentPayload: AdminAssignmentView) => void;
	assignmentType?: "reflection" | "interview" | "frontline" | "jobSearch" | "checkInSim" | "careerPath";
	clientSettings: any;
};

// Define the structure for each step
interface Step {
	component: React.ComponentType<StepProps>;
	label: string;
	icon: React.ComponentType<any>;
}

// Main CreateAssignment component
const CreateAssignment: React.FC<CreateAssignmentProps> = ({ mode, existingAssignment, assignmentType }) => {
	const { instageUser } = useAuth();
	const { clientSettings, isLoading: isLoadingSettings } = useClientSettings();
	const { step, assignmentId } = useParams<{ step?: string; assignmentId?: string }>();
	const [currentStep, setCurrentStep] = useState(0);
	const [formInitialValues, setFormInitialValues] = useState<AssignmentPayload | null>(null);

	const isEditMode = mode === "edit";
	const location = useLocation();
	const isAdminPath = location.pathname.startsWith("/admin");
	const { t } = useTranslation("createAssignment");

	useEffect(() => {
		console.log("formInitialValues");
	}, [formInitialValues]);

	// Initialize form values once when client settings are loaded
	useEffect(() => {
		const initializeFormValues = async () => {
			if (!isLoadingSettings && clientSettings && !formInitialValues) {
				// If in edit mode, fetch the assignment payload and create a new AssignmentPayload instance
				if (isEditMode && assignmentId) {
					try {
						const payload = await AssignmentService.getAssignmentPayloadById(assignmentId);

						// Create new AssignmentPayload instance with the payload data
						const newPayload = new AssignmentPayload(payload, clientSettings);

						// Initialize stagePlan from sessions
						if (newPayload.sessions?.length > 0) {
							const stagePlans: { [key: string]: InterviewStagePlan } = {};

							newPayload.sessions.forEach((session) => {
								if (session?.interview) {
									stagePlans[session.interview.interviewStageOrderNumber] = {
										technicalQuestions: session.interview.interviewQuestionList.filter((q) => q.type === "Technical").length,
										behavioralQuestions: session.interview.interviewQuestionList.filter((q) => q.type === "Behavioral").length,
										situationalQuestions: session.interview.interviewQuestionList.filter((q) => q.type === "Situational").length,
										culturalFitQuestions: session.interview.interviewQuestionList.filter((q) => q.type === "Cultural Fit").length,
										backgroundQuestions: session.interview.interviewQuestionList.filter((q) => q.type === "Background").length,
										motivationQuestions: session.interview.interviewQuestionList.filter((q) => q.type === "Motivation").length,
										recallQuestions: session.interview.interviewQuestionList.filter((q) => q.type === "Recall").length,
										generalQuestions: session.interview.interviewQuestionList.filter((q) => q.type === "General").length,
										resumeQuestions: session.interview.interviewQuestionList.filter((q) => q.type === "Resume").length,
										interviewerAttitude:
											(session.interview.interviewerAttitude as "direct" | "friendly" | "neutral") || "neutral",
										interviewStageOrderNumber: session.interview.interviewStageOrderNumber,
										interviewQuestionList: session.interview.interviewQuestionList,
										questionDetails: session.interview.interviewQuestionList.reduce(
											(acc: { [key: string]: { text: string; context: string } }, q) => ({
												...acc,
												[q.id]: {
													text: q.text,
													context: q.context || ""
												}
											}),
											{}
										)
									};
								}
							});

							// Add stagePlan and job details to the payload
							const firstSession = newPayload.sessions[0];
							if (firstSession?.interview) {
								Object.assign(newPayload, {
									stagePlan: stagePlans,
									staticJobTitle: firstSession.interview.jobTitle,
									jobDescription: firstSession.interview.jobDescription,
									jobTitleChoice: !firstSession.interview.jobTitle?.trim() ? "Dynamic" : "Static"
								});
							}
						}

						setFormInitialValues(newPayload);
					} catch (error) {
						console.error("Error fetching assignment:", error);
					}
				} else {
					// If not in edit mode, create a new AssignmentPayload instance with default values
					const initialValues = new AssignmentPayload(
						{
							experienceType:
								assignmentType === "interview"
									? ExperienceType.INTERVIEW
									: assignmentType === "frontline"
									? ExperienceType.FRONTLINE
									: assignmentType === "jobSearch"
									? ExperienceType.JOB_SEARCH_EMPLOYMENT
									: assignmentType === "checkInSim"
									? ExperienceType.CHECK_IN
									: assignmentType === "careerPath"
									? ExperienceType.CAREER_PATH
									: ExperienceType.CO_OP_EXPERIENCE,
							includeTranscript: assignmentType === "interview" || assignmentType === "frontline" ? true : false
						},
						clientSettings
					);
					setFormInitialValues(initialValues);
				}
			}
		};

		initializeFormValues();
	}, [isLoadingSettings, clientSettings, formInitialValues, isEditMode, assignmentId, assignmentType]);
	// Define the steps for creating/editing an assignment
	const steps: Step[] = [
		{
			component:
				assignmentType === "interview"
					? InterviewDates
					: assignmentType === "frontline"
					? FrontlineDates
					: assignmentType === "jobSearch"
					? JobSearchDates
					: assignmentType === "checkInSim"
					? CheckInSimDates
					: assignmentType === "careerPath"
					? CareerPathDates
					: ReflectionDates,
			label: t("stepLabels.Dates"),
			icon: CalendarIcon
		},
		...(assignmentType === "interview" || assignmentType === "reflection" || assignmentType === "checkInSim" || assignmentType === "careerPath"
			? [
					{
						component:
							assignmentType === "interview"
								? InterviewSetup
								: assignmentType === "checkInSim"
								? CheckInSimSetup
								: assignmentType === "careerPath"
								? CareerPathSetup
								: ReflectionSetup,
						// component: assignmentType === "interview" ? InterviewSetup : ReflectionSetup,
						label: t("stepLabels.Setup"),
						icon: WrenchScrewdriverIcon
					}
			  ]
			: []),
		{ component: AssignmentDetails, label: t("stepLabels.Details"), icon: ClipboardDocumentListIcon },
		{ component: ReviewAssignment, label: t("stepLabels.Review"), icon: MagnifyingGlassIcon }
	].filter((step, index) => {
		if (index === 2 && !isAdminPath && assignmentType === "interview") return false;
		return true;
	});

	// Set the current step based on the URL parameter
	useEffect(() => {
		if (step) {
			const stepIndex = parseInt(step, 10);
			if (!isNaN(stepIndex) && stepIndex >= 0 && stepIndex < steps.length) {
				setCurrentStep(stepIndex);
			}
		}
	}, [step, steps.length]);

	// Handle form submission
	const handleSubmit = (assignmentPayload: AdminAssignmentView) => {
		// console.log("Form submitted with values:", assignmentPayload);
		if (isAdminPath) {
			NavigateService.navToAdminAssignment(assignmentPayload.assignmentId);
		} else if (instageUser) {
			NavigateService.navToUserAssignment(assignmentPayload.assignmentId, instageUser?.id || "");
		} else {
			NavigateService.navToUser();
		}
	};

	// Handle navigation to the next step
	const handleNext = (values: any) => {
		const nextStep = Math.min(currentStep + 1, steps.length - 1);
		setCurrentStep(nextStep);
		if (!assignmentId) {
			return;
		}
		if (isEditMode) {
			NavigateService.navToEditAssignmentStep(assignmentType || "reflection", assignmentId, nextStep);
		} else {
			NavigateService.navToCreateAssignmentStep(assignmentType || "reflection", nextStep, isAdminPath);
		}
	};

	// Handle navigation to the previous step
	const handlePrevious = (values: any) => {
		const prevStep = Math.max(currentStep - 1, 0);
		setCurrentStep(prevStep);
		if (!assignmentId) {
			return;
		}
		if (isEditMode) {
			NavigateService.navToEditAssignmentStep(assignmentType || "reflection", assignmentId, prevStep);
		} else {
			NavigateService.navToCreateAssignmentStep(assignmentType || "reflection", prevStep, isAdminPath);
		}
	};

	// Handle editing a specific step
	const handleEdit = (stepIndex: number) => {
		setCurrentStep(stepIndex);
		if (isEditMode && assignmentId) {
			NavigateService.navToEditAssignmentStep(assignmentType || "reflection", assignmentId, stepIndex);
		} else {
			NavigateService.navToCreateAssignmentStep(assignmentType || "reflection", stepIndex, isAdminPath);
		}
	};

	// Define the validation schema for the form
	const validationSchema = Yup.object().shape({
		title: Yup.string().required("Title is required"),
		description: Yup.string().required("Description is required"),
		courseName: Yup.string().required("Course/Project Name is required"),
		courseCode: Yup.string().optional(),
		courseSection: Yup.string().optional(),
		sessions: Yup.array().of(
			Yup.object().shape({
				date: Yup.date().nullable().required("Session date is required")
			})
		),
		reportFrequency: Yup.string().required("Report frequency is required")
	});

	// Don't render form until we have initial values
	if (isLoadingSettings || !formInitialValues) {
		return null;
	}

	return (
		<div>
			<BackButton onPrevious={() => handlePrevious(formInitialValues)} currentStep={currentStep} />
			<Formik initialValues={formInitialValues} onSubmit={() => {}} validationSchema={validationSchema} enableReinitialize>
				{({ values, errors, touched, setFieldValue, setFieldTouched, isValid, dirty }) => (
					<Form className="space-y-8" role="form" aria-label="Assignment creation form">
						<div className="flex flex-col md:flex-row">
							{/* Sidebar with step navigation */}
							<div className="w-full md:w-1/4 md:pr-8 mb-8 md:mb-0">
								<div className="bg-white rounded-lg p-6 border border-[#eaecf0]">
									<h1 className="text-2xl font-bold text-gray-900 mb-6" id="page-title">
										{isEditMode
											? t("titles.editAssignment")
											: assignmentType === "interview"
											? t("titles.createInterview")
											: assignmentType === "frontline"
											? t("titles.createFrontline")
											: assignmentType === "jobSearch"
											? t("titles.createJobSearch")
											: assignmentType === "checkInSim"
											? t("titles.createCheckIn")
											: assignmentType === "careerPath"
											? t("titles.createCareerPath")
											: t("titles.createReflection")}
									</h1>
									{/* <p className="text-gray-500 mb-6">{steps[currentStep].label}</p> */}
									{/* Progress bar */}
									<div
										className="mb-6"
										role="progressbar"
										aria-valuemin={0}
										aria-valuemax={100}
										aria-valuenow={((currentStep + 1) / steps.length) * 100}
									>
										<div className="h-1 w-full bg-gray-200 rounded-full">
											<div
												className="h-1 bg-[#00a9af] rounded-full"
												style={{ width: `${((currentStep + 1) / steps.length) * 100}%` }}
											></div>
										</div>
									</div>
									<StepNavigation steps={steps} currentStep={currentStep} />
								</div>
							</div>
							{/* Main content area */}
							<div className="w-full md:w-3/4" role="main" aria-labelledby="page-title">
								<div>
									{React.createElement(steps[currentStep].component, {
										values,
										errors,
										touched,
										setFieldValue,
										setFieldTouched,
										onNext: () => handleNext(values),
										onPrevious: () => handlePrevious(values),
										onEdit: handleEdit,
										onSubmit: handleSubmit,
										isValid,
										dirty,
										isEditMode,
										assignmentType,
										clientSettings
									} as StepProps)}
								</div>

								{/* Hide navigation buttons on the final review step */}
								{/* {currentStep !== steps.length - 1 && (
									<div className="mt-8 flex gap-2 md:hidden">
										{currentStep > 0 && (
											<Button variant="secondary" onClick={() => handlePrevious(values)}>
												Previous
											</Button>
										)}
										<div className="mt-8 flex gap-2 md:hidden">isValid:{isValid.toString()}</div>

										<Button onClick={() => handleNext(values)} disabled={currentStep === steps.length - 1 || !isValid}>
											{currentStep === steps.length - 1 ? "Submit" : "Next (outer)"}
										</Button>
									</div>
								)} */}
							</div>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	);
};

// Wrap the CreateAssignment component with FormProvider
const WrappedCreateAssignment: React.FC<CreateAssignmentProps> = (props) => <CreateAssignment {...props} />;

export default WrappedCreateAssignment;
