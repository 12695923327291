export const ResumeAssistLocale = {
	en: {
		options: {
			size: "Size",
			template: "Template",
			color: "Color",
			templates: {
				Classic: "Classic",
				"Modern Professional": "Modern Professional",
				"Modern Cover Letter": "Modern Cover Letter",
				"Classic Cover Letter": "Classic Cover Letter"
			}
		},
		templateViewer: {
			resumePreview: "Resume Preview",
			downloadAsDocx: "Download as DOCX"
		},
		resumeAssist: {
			resumePreview: "Resume Preview",
			coverLetterPreview: "Cover Letter Preview",
			hoverOverAndClickOnASectionBelowToBeginEditing: "Hover over and click on a section below to begin editing."
		},
		feedback: {
			generatingFeedback: "Generating...",
			generateFeedback: "Generate Feedback",
			overall: {
				overallPositiveFeedback: "Overall Positive Feedback",
				overallAreasToImprove: "Overall Areas to Improve"
			},
			viewFeedback: "View Feedback",
			matchScore: "Match Score",
			feedback: "Feedback",
			scoreBreakdown: "Score Breakdown",
			scoreBreakdownDescription: "The score above is a culmination of each section score below. You can click on one to expand it.",
			scoreBreakdownDescriptionCoverLetter: "The score is a culmination of each section score below. You can click on one to expand it.",
			sections: {
				Summary: {
					label: "Summary",
					description:
						"Score Weight - 20% - Evaluates the clarity, conciseness, and impact of your professional summary or objective statement."
				},
				Experience: {
					label: "Experience",
					description:
						"Score Weight - 35% - Assesses your work history, including the use of action verbs, quantifiable achievements, and relevant responsibilities."
				},
				Education: {
					label: "Education",
					description:
						"Score Weight - 10% - Reviews your academic credentials, including degrees, certifications, relevant coursework, and academic achievements."
				},
				Skills: {
					label: "Skills",
					description:
						"Score Weight - 20% - Evaluates your technical, soft, and industry-specific skills, including their relevance and organization."
				},
				Projects: {
					label: "Projects",
					description:
						"Score Weight - 7.5% - Analyzes your project descriptions, including technical details, methodologies used, and demonstrated outcomes."
				},
				"Extra Curricular": {
					label: "Extra-Curricular",
					description:
						"Score Weight - 7.5% - Reviews volunteer work, leadership roles, and other activities that demonstrate additional capabilities and character."
				},
				Introduction: {
					label: "Introduction",
					description: "Score Weight - 25% - Evaluates the clarity, conciseness, and impact of your introduction."
				},
				Body: {
					label: "Body",
					description: "Score Weight - 50% - Evaluates how well you have presented yourself in alignment with the job description."
				},
				Closing: {
					label: "Closing",
					description: "Score Weight - 25% - Evaluates the closing of your cover letter and your ability to leave a lasting impression."
				}
			}
		},
		uploadJobForm: {
			step1: "Step 1: Target Job",
			step2: "Step 2: Resume Editor",
			step3: "Step 3: Cover Letter",
			title: "Target Job",
			description: "Enter the job title and desription of a job you wish to apply for to create a resume tailored to it based on your profile.",
			jobTitle: {
				label: "Job Title",
				placeholder: "Enter job title"
			},
			jobDescription: {
				label: "Job Description",
				placeholder: "Enter job description"
			},
			generateButton: {
				default: "Generate Resume & Cover Letter",
				loading: "Generating From Profile..."
			}
		}
	},
	fr: {
		options: {
			size: "Taille",
			template: "Modèle",
			color: "Couleur",
			templates: {
				Classic: "Classique",
				"Modern Professional": "Moderne Professionnel",
				"Modern Cover Letter": "Moderne Lettre de Motivation",
				"Classic Cover Letter": "Classique Lettre de Motivation"
			}
		},
		templateViewer: {
			resumePreview: "Aperçu du CV",
			downloadAsDocx: "Télécharger en DOCX"
		},
		resumeAssist: {
			resumePreview: "Aperçu du CV",
			coverLetterPreview: "Aperçu de la lettre de motivation",
			hoverOverAndClickOnASectionBelowToBeginEditing: "Survoler et cliquer sur une section ci-dessous pour commencer à modifier."
		},
		feedback: {
			generatingFeedback: "Génération...",
			generateFeedback: "Générer le Feedback",
			overall: {
				overallPositiveFeedback: "Retour Global Positif",
				overallAreasToImprove: "Retour Global à Améliorer"
			},
			viewFeedback: "Voir le Feedback",
			matchScore: "Score de Match",
			feedback: "Retour",
			scoreBreakdown: "Décomposition du Score",
			scoreBreakdownDescription:
				"Le score ci-dessus est une somme de chaque score de section ci-dessous. Vous pouvez cliquer dessus pour l'étendre.",
			scoreBreakdownDescriptionCoverLetter:
				"Le score est une somme de chaque score de section ci-dessous. Vous pouvez cliquer dessus pour l'étendre.",
			sections: {
				Summary: {
					label: "Résumé",
					description:
						"Pondération - 20% - Évalue la clarté, la concision et l'impact de votre résumé professionnel ou de votre objectif professionnel."
				},
				Experience: {
					label: "Expérience",
					description:
						"Pondération - 35% - Évalue votre parcours professionnel, y compris l'utilisation de verbes action, des accomplissements quantifiables et des responsabilités pertinentes."
				},
				Education: {
					label: "Formation",
					description:
						"Pondération - 10% - Évalue vos qualifications académiques, y compris les diplômes, les certifications, le cours pertinent et les accomplissements académiques."
				},
				Skills: {
					label: "Compétences",
					description:
						"Pondération - 20% - Évalue vos compétences techniques, douces et spécifiques à l'industrie, y compris leur pertinence et leur organisation."
				},
				Projects: {
					label: "Projects",
					description:
						"Pondération - 7.5% - Analyse les descriptions de vos projets, y compris les détails techniques, les méthodologies utilisées et les résultats démontrés."
				},
				"Extra Curricular": {
					label: "Extra-scolaire",
					description:
						"Pondération - 7.5% - Évalue le travail bénévole, les rôles de leadership et les autres activités qui démontrent des capacités supplémentaires et un caractère."
				},
				Introduction: {
					label: "Introduction",
					description: "Pondération - 25% - Évalue la clarté, la concision et l'impact de votre introduction."
				},
				Body: {
					label: "Corps",
					description: "Pondération - 50% - Évalue la manière dont vous avez présenté vous-même en alignement avec la description du poste."
				},
				Closing: {
					label: "Fermeture",
					description:
						"Pondération - 25% - Évalue la fermeture de votre lettre de motivation et votre capacité à laisser une impression durable."
				}
			}
		},
		uploadJobForm: {
			step1: "Étape 1: Emploi Ciblé",
			step2: "Étape 2: Editeur de CV",
			step3: "Étape 3: Lettre de Motivation",
			title: "Emploi Ciblé",
			description:
				"Entrez le titre et la description du poste pour lequel vous souhaitez postuler afin de créer un CV adapté à partir de votre profil.",
			jobTitle: {
				label: "Titre du Poste",
				placeholder: "Entrez le titre du poste"
			},
			jobDescription: {
				label: "Description du Poste",
				placeholder: "Entrez la description du poste"
			},
			generateButton: {
				default: "Générer CV et Lettre de Motivation",
				loading: "Génération à Partir du Profil..."
			}
		}
	}
};
