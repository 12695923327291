export const ProfileLocale = {
	en: {
		backButtonLabel: "Back to Resume Assist",
		backButtonConfirmationDialog: {
			title: "Unsaved Changes",
			description: "You have unsaved changes. Would you like to continue editing?",
			leavePage: "Leave Page",
			continueEditing: "Continue Editing"
		},
		profileDisclaimer: {
			title: "Profile Information",
			dataStorage: "Data Storage",
			dataStorageDescription: "Your privacy matters. Any information you provide is encrypted and securely stored in Canada.",
			dataAccess: "Data Access",
			dataAccessDescription: "Your data will only be visible to you and authorized InStage support staff who may need access to assist you.",
			dataSharing: "Data Sharing",
			dataSharingDescription: "We never sell or share your personal information with non-critical or threatening third parties.",
			close: "Close",
			agree: "Agree"
		},
		sectionNav: {
			sections: "Sections",
			missing: "Missing"
		},
		months: {
			January: "January",
			February: "February",
			March: "March",
			April: "April",
			May: "May",
			June: "June",
			July: "July",
			August: "August",
			September: "September",
			October: "October",
			November: "November",
			December: "December"
		},
		title: "Profile Creator",
		description: "Your profile holds all of your resume related information which will be used to create/ tailor your resumes easily.",
		delete: {
			title: "Delete Profile",
			description:
				"Are you sure you want to delete this profile? This action cannot be undone. If any resumes and cover letters are linked to this profile, they will be deleted as well.",
			cancel: "Cancel",
			delete: "Delete"
		},
		profileButton: {
			title: "Profile",
			lastModified: "Last modified",
			public: "Public",
			createPrompt: "Create your profile to get started",
			deleteAriaLabel: "Delete profile",
			deleteIconAriaLabel: "Delete profile icon",
			profilePictureAriaLabel: "Profile picture icon",
			editAriaLabel: "Edit profile"
		},
		section: {
			contact: "Contact",
			summary: "Summary",
			experience: "Experience",
			education: "Education",
			skills: "Skills",
			projects: "Projects",
			certifications: "Certifications",
			volunteer: "Volunteer"
		},
		error: {
			summaryRequired: "Summary is required",
			firstNameRequired: "First Name is required",
			lastNameRequired: "Last Name is required",
			emailRequired: "Email is required",
			phoneRequired: "Phone is required",
			jobTitleRequired: "Job Title is required",
			companyNameRequired: "Company Name is required",
			cityRequired: "City is required",
			stateOrProvinceRequired: "State/Province is required",
			startMonthRequired: "Start Month is required",
			startYearRequired: "Start Year is required",
			endMonthRequired: "End Month is required",
			endYearRequired: "End Year is required",
			rolesRequired: "At least one role is required",
			schoolRequired: "School is required",
			degreeRequired: "Degree is required",
			programRequired: "Program is required",
			graduatingYearRequired: "Graduating Year is required",
			projectNameRequired: "Project Name is required",
			descriptionRequired: "Description is required",
			notesRequired: "At least one note is required",
			certificationNameRequired: "Certification Name is required",
			issuingOrganizationRequired: "Issuing Organization is required",
			issueMonthRequired: "Issue Month is required",
			issueYearRequired: "Issue Year is required",
			activityRequired: "Activity is required",
			orgNameRequired: "Organization Name is required",
			tasksRequired: "At least one task is required"
		},
		buttons: {
			uploadResume: "Upload Resume",
			cancel: "Cancel",
			save: "Save",
			edit: "Edit",
			saveChanges: "Save Changes",
			saving: "Saving",
			resumeUpload: {
				button: "Upload your resume",
				uploading: "Uploading...",
				fileTypes: "PDF, DOC, DOCX (Max 5MB)",
				description: "Upload an existing resume to easily populate your profile.",
				reupload: "Re-Upload"
			}
		},
		contact: {
			contactInformationTitle: "Contact Information",
			name: "Name",
			email: "Email",
			phone: "Phone",
			address: "Address",
			linkedin: "LinkedIn",
			website: "Website",
			profile: "Profile",
			placeholders: {
				firstName: "First Name",
				lastName: "Last Name",
				email: "Email Address",
				phone: "Phone Number",
				address: "Address"
			}
		},
		summary: {
			title: "Summary",
			placeholder: "Add a professional summary highlighting your key qualifications and career objectives"
		},
		experience: {
			title: "Experience",
			jobTitle: "Job Title",
			companyName: "Company Name",
			city: "City",
			stateProvince: "State/Province",
			startMonth: "Start Month",
			startYear: "Start Year",
			endMonth: "End Month",
			endYear: "End Year",
			addRoles: "Add role descriptions"
		},
		education: {
			title: "Education",
			schoolName: "School Name",
			degree: "Degree",
			program: "Program",
			graduatingYear: "Graduating Year",
			year: "Year",
			addAchievements: "Add educational achievements or highlights"
		},
		skills: {
			title: "Skills",
			technical: {
				title: "Technical",
				placeholder: "Add technical skills"
			},
			competencies: {
				title: "Competencies",
				placeholder: "Add competencies"
			},
			languages: {
				title: "Languages",
				placeholder: "Add languages"
			},
			toolsAndFrameworks: {
				title: "Tools & Frameworks",
				placeholder: "Add tools and frameworks"
			}
		},
		projects: {
			title: "Projects",
			projectName: "Project Name",
			startMonth: "Start Month",
			startYear: "Start Year",
			endMonth: "End Month",
			endYear: "End Year",
			description: "Project Description",
			projectLink: "Project Link",
			addUrl: "Add project URL",
			addHighlights: "Add project highlights or achievements"
		},
		certifications: {
			title: "Certifications",
			certificationName: "Certification Name",
			issuingOrg: "Issuing Organization",
			issued: "Issued",
			expires: "Expires",
			month: "Month",
			year: "Year"
		},
		extraCurricular: {
			title: "Extra-Curricular Activities",
			activityName: "Activity Name",
			orgName: "Organization Name",
			startMonth: "Start Month",
			startYear: "Start Year",
			endMonth: "End Month",
			endYear: "End Year",
			addTasks: "Add activity responsibilities or achievements"
		},
		interests: {
			title: "Interests",
			placeholder: "Add your interests"
		},
		hobbies: {
			title: "Hobbies",
			placeholder: "Add your hobbies"
		},
		editForm: {
			remove: "Remove",
			add: "Add",
			contact: {
				title: "Edit Contact Information",
				firstName: "First Name",
				lastName: "Last Name",
				email: "Email",
				phone: "Phone",
				address: "Address",
				linkedin: "LinkedIn URL",
				website: "Website",
				placeholders: {
					firstName: "John",
					lastName: "Doe",
					email: "john.doe@gmail.com",
					phone: "(123) 456-7890",
					address: "123 Main St, City, State 12345",
					linkedin: "linkedin.com/in/johndoe",
					website: "www.johndoe.com"
				},
				validation: {
					invalidEmail: "Please enter a valid email address",
					invalidPhone: "Please enter a valid phone number (e.g., (123) 456-7890)",
					invalidUrl: "Please enter a valid URL"
				}
			},
			summary: {
				title: "Edit Summary",
				label: "Summary",
				placeholder:
					"Results-driven Sales Professional with 5+ years of experience in B2B software sales. Proven track record of exceeding quarterly targets and building long-term client relationships. Skilled in consultative selling, pipeline management, and contract negotiations."
			},
			experience: {
				title: "Edit Experience",
				experienceNumber: "Experience",
				removeExperience: "Remove Experience",
				addExperience: "Add Experience",
				jobTitle: "Job Title",
				jobTitlePlaceholder: "Senior Sales Representative",
				companyName: "Company Name",
				companyNamePlaceholder: "Enterprise Solutions Group",
				city: "City",
				cityPlaceholder: "San Francisco",
				stateProvince: "State/Province",
				stateProvincePlaceholder: "California",
				startMonth: "Start Month",
				startYear: "Start Year",
				endMonth: "End Month",
				endYear: "End Year",
				selectMonth: "Select month",
				selectYear: "Select year",
				present: "Present",
				accomplishments: "Accomplishments",
				accomplishmentPlaceholder: "Exceeded quarterly sales targets by 30% through strategic account management and consultative selling",
				removeAccomplishment: "Remove",
				addAccomplishment: "Add Accomplishment"
			},
			education: {
				title: "Edit Education",
				educationNumber: "Education",
				removeEducation: "Remove Education",
				addEducation: "Add Education",
				school: "School",
				schoolPlaceholder: "University of California, Berkeley",
				degree: "Degree",
				degreePlaceholder: "Bachelor of Science",
				program: "Program",
				programPlaceholder: "Computer Science",
				graduatingYear: "Graduating Year",
				selectYear: "Select year",
				notes: "Notes",
				removeNote: "Remove",
				addNote: "Add Note"
			},
			skills: {
				technical: {
					title: "Technical Skills",
					placeholder: "Add technical skills"
				},
				coreCompetencies: {
					title: "Core Competencies",
					placeholder: "Add core competencies"
				},
				languages: {
					title: "Languages",
					placeholder: "Add languages"
				},
				tools: {
					title: "Tools",
					placeholder: "Add tools"
				},
				frameworks: {
					title: "Frameworks",
					placeholder: "Add frameworks"
				}
			},
			projects: {
				title: "Edit Projects",
				projectNumber: "Project",
				removeProject: "Remove Project",
				addProject: "Add Project",
				projectName: "Project Name",
				projectNamePlaceholder: "Regional Sales Strategy Overhaul",
				description: "Description",
				descriptionPlaceholder:
					"Led a team initiative to restructure our regional sales approach, resulting in a 45% increase in quarterly revenue and improved client retention rates",
				startMonth: "Start Month",
				startYear: "Start Year",
				endMonth: "End Month",
				endYear: "End Year",
				selectMonth: "Select month",
				selectYear: "Select year",
				present: "Present",
				projectURL: "Project URL",
				projectURLPlaceholder: "github.com/johndoe/project",
				notes: "Notes",
				removeNote: "Remove",
				addNote: "Add Note"
			},
			certifications: {
				title: "Edit Certifications",
				certificationNumber: "Certification",
				removeCertification: "Remove Certification",
				addCertification: "Add Certification",
				certificationName: "Certification Name",
				certificationNamePlaceholder: "Certified Sales Professional (CSP)",
				issuingOrganization: "Issuing Organization",
				issuingOrganizationPlaceholder: "National Association of Sales Professionals (NASP)",
				issueMonth: "Issue Month",
				issueYear: "Issue Year",
				expirationMonth: "Expiration Month",
				expirationYear: "Expiration Year",
				selectMonth: "Select month",
				selectYear: "Select year",
				present: "Present"
			},
			extraCurricular: {
				title: "Edit Extra-Curricular Activities",
				activityNumber: "Activity",
				removeActivity: "Remove Activity",
				addActivity: "Add Activity",
				activityName: "Activity Name",
				activityNamePlaceholder: "Sales Mentor",
				organizationName: "Organization Name",
				organizationNamePlaceholder: "Young Professionals Sales Network",
				startMonth: "Start Month",
				startYear: "Start Year",
				endMonth: "End Month",
				endYear: "End Year",
				selectMonth: "Select month",
				selectYear: "Select year",
				present: "Present",
				tasks: "Tasks",
				taskPlaceholder: "Mentored 10+ junior sales representatives, improving their quarterly performance by 25%",
				removeTask: "Remove",
				addTask: "Add Task"
			},
			interests: {
				title: "Edit Interests",
				placeholder: "Add an interest",
				removeInterest: "Remove Interest",
				addInterest: "Add Interest"
			},
			hobbies: {
				title: "Edit Hobbies",
				placeholder: "Add a hobby",
				removeHobby: "Remove Hobby",
				addHobby: "Add Hobby"
			}
		}
	},
	fr: {
		backButtonLabel: "Retour à l'Assistant de CV",
		backButtonConfirmationDialog: {
			title: "Changements non enregistrés",
			description: "Vous avez des changements non enregistrés. Voulez-vous continuer à modifier?",
			leavePage: "Quitter la page",
			continueEditing: "Continuer à modifier"
		},
		profileDisclaimer: {
			title: "Informations du profil",
			dataStorage: "Stockage des données",
			dataStorageDescription:
				"Votre vie privée compte. Toutes les informations que vous fournissez sont cryptées et stockées en toute sécurité au Canada.",
			dataAccess: "Accès aux données",
			dataAccessDescription:
				"Vos données ne seront visibles que pour vous et les équipes de support autorisées d'InStage qui peuvent avoir besoin d'accéder à vos données pour vous aider.",
			dataSharing: "Partage des données",
			dataSharingDescription:
				"Nous ne vendons pas ou ne partageons pas vos informations personnelles avec des tiers non critiques ou menaçants.",
			close: "Fermer",
			agree: "Accepter"
		},
		sectionNav: {
			sections: "Sections",
			missing: "Manquant"
		},
		months: {
			January: "Janvier",
			February: "Février",
			March: "Mars",
			April: "Avril",
			May: "Mai",
			June: "Juin",
			July: "Juillet",
			August: "Août",
			September: "Septembre",
			October: "Octobre",
			November: "Novembre",
			December: "Décembre"
		},
		title: "Créateur de profil",
		description: "Votre profil contient toutes les informations liées à votre CV qui seront utilisées pour créer/adapter vos CV facilement.",
		delete: {
			title: "Supprimer le profil",
			description:
				"Êtes-vous sûr de vouloir supprimer ce profil ? Cette action ne peut pas être annulée. Si des CV et lettres de motivation sont liées à ce profil, elles seront également supprimées.",
			cancel: "Annuler",
			delete: "Supprimer"
		},
		profileButton: {
			title: "Profil",
			lastModified: "Dernière modification",
			public: "Public",
			createPrompt: "Créez votre profil pour commencer",
			deleteAriaLabel: "Supprimer le profil",
			deleteIconAriaLabel: "Icône de suppression du profil",
			profilePictureAriaLabel: "Icône de photo de profil",
			editAriaLabel: "Modifier le profil"
		},
		section: {
			contact: "Contact",
			summary: "Résumé",
			experience: "Expérience",
			education: "Formation",
			skills: "Compétences",
			projects: "Projets",
			certifications: "Certifications",
			volunteer: "Volontariat"
		},
		error: {
			summaryRequired: "Le résumé est requis",
			firstNameRequired: "Le prénom est requis",
			lastNameRequired: "Le nom est requis",
			emailRequired: "L'email est requis",
			phoneRequired: "Le téléphone est requis",
			jobTitleRequired: "Le titre du poste est requis",
			companyNameRequired: "Le nom de l'entreprise est requis",
			cityRequired: "La ville est requise",
			stateOrProvinceRequired: "Le statut/province est requis",
			startMonthRequired: "Le mois de début est requis",
			startYearRequired: "Le mois de début est requis",
			endMonthRequired: "Le mois de fin est requis",
			endYearRequired: "Le mois de fin est requis",
			rolesRequired: "Au moins un rôle est requis",
			schoolRequired: "L'école est requise",
			degreeRequired: "Le diplôme est requis",
			programRequired: "Le programme est requis",
			graduatingYearRequired: "Le mois de fin est requis",
			projectNameRequired: "Le nom du projet est requis",
			descriptionRequired: "La description est requise",
			notesRequired: "Au moins une note est requise",
			certificationNameRequired: "Le nom de la certification est requis",
			issuingOrganizationRequired: "L'organisation émettrice est requise",
			issueMonthRequired: "Le mois d'émission est requis",
			issueYearRequired: "Le mois d'émission est requis",
			activityRequired: "L'activité est requise",
			orgNameRequired: "Le nom de l'organisation est requis",
			tasksRequired: "Au moins une tâche est requise"
		},
		buttons: {
			uploadResume: "Télécharger le CV",
			cancel: "Annuler",
			edit: "Modifier",
			save: "Enregistrer",
			saveChanges: "Enregistrer les modifications",
			saving: "Enregistrement",
			resumeUpload: {
				button: "Téléchargez votre CV",
				uploading: "Téléchargement...",
				fileTypes: "PDF, DOC, DOCX (Max 5Mo)",
				description: "Téléchargez un CV existant pour remplir facilement votre profil.",
				reupload: "Télécharger à nouveau"
			}
		},
		contact: {
			contactInformationTitle: "Informations de contact",
			name: "Nom",
			email: "E-mail",
			phone: "Téléphone",
			address: "Adresse",
			linkedin: "LinkedIn",
			website: "Site web",
			profile: "Profil",
			placeholders: {
				firstName: "Prénom",
				lastName: "Nom",
				email: "Adresse e-mail",
				phone: "Numéro de téléphone",
				address: "Adresse"
			}
		},
		summary: {
			title: "Résumé",
			placeholder: "Ajoutez un résumé professionnel mettant en valeur vos principales qualifications et objectifs de carrière"
		},
		experience: {
			title: "Expérience",
			jobTitle: "Titre du poste",
			companyName: "Nom de l'entreprise",
			city: "Ville",
			stateProvince: "État/Province",
			startMonth: "Mois de début",
			startYear: "Année de début",
			endMonth: "Mois de fin",
			endYear: "Année de fin",
			addRoles: "Ajouter des descriptions de rôle"
		},
		education: {
			title: "Formation",
			schoolName: "Nom de l'école",
			degree: "Diplôme",
			program: "Programme",
			graduatingYear: "Année d'obtention",
			year: "Année",
			addAchievements: "Ajouter des réalisations ou points forts académiques"
		},
		skills: {
			title: "Compétences",
			technical: {
				title: "Techniques",
				placeholder: "Ajouter des compétences techniques"
			},
			competencies: {
				title: "Compétences clés",
				placeholder: "Ajouter des compétences"
			},
			languages: {
				title: "Langues",
				placeholder: "Ajouter des langues"
			},
			toolsAndFrameworks: {
				title: "Outils et Frameworks",
				placeholder: "Ajouter des outils et frameworks"
			}
		},
		projects: {
			title: "Modifier les projets",
			projectNumber: "Projet",
			removeProject: "Supprimer le projet",
			addProject: "Ajouter un projet",
			projectName: "Nom du projet",
			projectNamePlaceholder: "Refonte de la stratégie de vente régionale",
			description: "Description",
			descriptionPlaceholder:
				"Direction d'une initiative d'équipe pour restructurer notre approche de vente régionale, résultant en une augmentation de 45% du chiffre d'affaires trimestriel et une amélioration de la fidélisation des clients",
			startMonth: "Mois de début",
			startYear: "Année de début",
			endMonth: "Mois de fin",
			endYear: "Année de fin",
			selectMonth: "Sélectionner le mois",
			selectYear: "Sélectionner l'année",
			present: "Présent",
			projectURL: "URL du projet",
			projectURLPlaceholder: "github.com/jeandupont/projet",
			notes: "Notes",
			removeNote: "Supprimer",
			addNote: "Ajouter une note"
		},
		certifications: {
			title: "Modifier les certifications",
			certificationNumber: "Certification",
			removeCertification: "Supprimer la certification",
			addCertification: "Ajouter une certification",
			certificationName: "Nom de la certification",
			certificationNamePlaceholder: "Professionnel de vente certifié (CSP)",
			issuingOrganization: "Organisation émettrice",
			issuingOrganizationPlaceholder: "Association Nationale des Professionnels de la Vente (NASP)",
			issueMonth: "Mois d'émission",
			issueYear: "Année d'émission",
			expirationMonth: "Mois d'expiration",
			expirationYear: "Année d'expiration",
			selectMonth: "Sélectionner le mois",
			selectYear: "Sélectionner l'année",
			present: "Présent"
		},
		extraCurricular: {
			title: "Modifier les activités extra-scolaires",
			activityNumber: "Activité",
			removeActivity: "Supprimer l'activité",
			addActivity: "Ajouter une activité",
			activityName: "Nom de l'activité",
			activityNamePlaceholder: "Sales Mentor",
			organizationName: "Nom de l'organisation",
			organizationNamePlaceholder: "Réseau des Jeunes Professionnels de la Vente",
			startMonth: "Mois de début",
			startYear: "Année de début",
			endMonth: "Mois de fin",
			endYear: "Année de fin",
			selectMonth: "Sélectionner le mois",
			selectYear: "Sélectionner l'année",
			present: "Présent",
			tasks: "Tâches",
			taskPlaceholder: "Mentored 10+ junior sales representatives, improving their quarterly performance by 25%",
			removeTask: "Supprimer",
			addTask: "Ajouter une tâche"
		},
		interests: {
			title: "Centres d'intérêt",
			placeholder: "Ajoutez vos centres d'intérêt"
		},
		hobbies: {
			title: "Loisirs",
			placeholder: "Ajoutez vos loisirs"
		},
		editForm: {
			remove: "Supprimer",
			add: "Ajouter",
			contact: {
				title: "Modifier les informations de contact",
				firstName: "Prénom",
				lastName: "Nom",
				email: "E-mail",
				phone: "Téléphone",
				address: "Adresse",
				linkedin: "URL LinkedIn",
				website: "Site web",
				placeholders: {
					firstName: "Jean",
					lastName: "Dupont",
					email: "jean.dupont@gmail.com",
					phone: "(123) 456-7890",
					address: "123 Rue Principale, Ville, État 12345",
					linkedin: "linkedin.com/in/jeandupont",
					website: "www.jeandupont.com"
				},
				validation: {
					invalidEmail: "Veuillez saisir une adresse e-mail valide",
					invalidPhone: "Veuillez saisir un numéro de téléphone valide (ex: (123) 456-7890)",
					invalidUrl: "Veuillez saisir une URL valide"
				}
			},
			summary: {
				title: "Modifier le résumé",
				label: "Résumé",
				placeholder:
					"Professionnel de la vente axé sur les résultats avec plus de 5 ans d'expérience dans la vente de logiciels B2B. Historique prouvé de dépassement des objectifs trimestriels et de développement de relations clients à long terme. Compétent en vente consultative, gestion de pipeline et négociation de contrats."
			},
			experience: {
				title: "Modifier l'expérience",
				experienceNumber: "Expérience",
				removeExperience: "Supprimer l'expérience",
				addExperience: "Ajouter une expérience",
				jobTitle: "Titre du poste",
				jobTitlePlaceholder: "Représentant commercial senior",
				companyName: "Nom de l'entreprise",
				companyNamePlaceholder: "Groupe Solutions Entreprise",
				city: "Ville",
				cityPlaceholder: "San Francisco",
				stateProvince: "État/Province",
				stateProvincePlaceholder: "Californie",
				startMonth: "Mois de début",
				startYear: "Année de début",
				endMonth: "Mois de fin",
				endYear: "Année de fin",
				selectMonth: "Sélectionner le mois",
				selectYear: "Sélectionner l'année",
				present: "Présent",
				accomplishments: "Réalisations",
				accomplishmentPlaceholder:
					"Dépassement des objectifs trimestriels de 30% grâce à la gestion stratégique des comptes et à la vente consultative",
				removeAccomplishment: "Supprimer",
				addAccomplishment: "Ajouter une réalisation"
			},
			education: {
				title: "Modifier la formation",
				educationNumber: "Formation",
				removeEducation: "Supprimer la formation",
				addEducation: "Ajouter une formation",
				school: "École",
				schoolPlaceholder: "Université de Californie, Berkeley",
				degree: "Diplôme",
				degreePlaceholder: "Licence en Sciences",
				program: "Programme",
				programPlaceholder: "Informatique",
				graduatingYear: "Année d'obtention",
				selectYear: "Sélectionner l'année",
				notes: "Notes",
				removeNote: "Supprimer",
				addNote: "Ajouter une note"
			},
			skills: {
				technical: {
					title: "Compétences techniques",
					placeholder: "Ajouter des compétences techniques"
				},
				coreCompetencies: {
					title: "Compétences clés",
					placeholder: "Ajouter des compétences clés"
				},
				languages: {
					title: "Langues",
					placeholder: "Ajouter des langues"
				},
				tools: {
					title: "Outils",
					placeholder: "Ajouter des outils"
				},
				frameworks: {
					title: "Frameworks",
					placeholder: "Ajouter des frameworks"
				}
			},
			projects: {
				title: "Modifier les projets",
				projectNumber: "Projet",
				removeProject: "Supprimer le projet",
				addProject: "Ajouter un projet",
				projectName: "Nom du projet",
				projectNamePlaceholder: "Refonte de la stratégie de vente régionale",
				description: "Description",
				descriptionPlaceholder:
					"Direction d'une initiative d'équipe pour restructurer notre approche de vente régionale, résultant en une augmentation de 45% du chiffre d'affaires trimestriel et une amélioration de la fidélisation des clients",
				startMonth: "Mois de début",
				startYear: "Année de début",
				endMonth: "Mois de fin",
				endYear: "Année de fin",
				selectMonth: "Sélectionner le mois",
				selectYear: "Sélectionner l'année",
				present: "Présent",
				projectURL: "URL du projet",
				projectURLPlaceholder: "github.com/jeandupont/projet",
				notes: "Notes",
				removeNote: "Supprimer",
				addNote: "Ajouter une note"
			},
			certifications: {
				title: "Modifier les certifications",
				certificationNumber: "Certification",
				removeCertification: "Supprimer la certification",
				addCertification: "Ajouter une certification",
				certificationName: "Nom de la certification",
				certificationNamePlaceholder: "Professionnel de vente certifié (CSP)",
				issuingOrganization: "Organisation émettrice",
				issuingOrganizationPlaceholder: "Association Nationale des Professionnels de la Vente (NASP)",
				issueMonth: "Mois d'émission",
				issueYear: "Année d'émission",
				expirationMonth: "Mois d'expiration",
				expirationYear: "Année d'expiration",
				selectMonth: "Sélectionner le mois",
				selectYear: "Sélectionner l'année",
				present: "Présent"
			},
			extraCurricular: {
				title: "Modifier les activités extra-scolaires",
				activityNumber: "Activité",
				removeActivity: "Supprimer l'activité",
				addActivity: "Ajouter une activité",
				activityName: "Nom de l'activité",
				activityNamePlaceholder: "Sales Mentor",
				organizationName: "Nom de l'organisation",
				organizationNamePlaceholder: "Réseau des Jeunes Professionnels de la Vente",
				startMonth: "Mois de début",
				startYear: "Année de début",
				endMonth: "Mois de fin",
				endYear: "Année de fin",
				selectMonth: "Sélectionner le mois",
				selectYear: "Sélectionner l'année",
				present: "Présent",
				tasks: "Tâches",
				taskPlaceholder: "Mentored 10+ junior sales representatives, improving their quarterly performance by 25%",
				removeTask: "Supprimer",
				addTask: "Ajouter une tâche"
			},
			interests: {
				title: "Modifier les centres d'intérêt",
				placeholder: "Ajouter un centre d'intérêt",
				removeInterest: "Supprimer le centre d'intérêt",
				addInterest: "Ajouter un centre d'intérêt"
			},
			hobbies: {
				title: "Modifier les loisirs",
				placeholder: "Ajouter un loisir",
				removeHobby: "Supprimer le loisir",
				addHobby: "Ajouter un loisir"
			}
		}
	}
};
