import { ViewColumn } from "./typeorm-mocks";
import { IsUUID, IsString, IsBoolean, IsInt, IsArray } from "class-validator";
import { Expose, Transform } from "class-transformer";
import { dateTransformer } from "./dateTransformer";

export class ClientView {
	@ViewColumn()
	@Expose()
	@IsUUID()
	clientId!: string;

	@ViewColumn()
	@Expose()
	@IsString()
	clientName!: string;

	@ViewColumn()
	@Expose()
	@IsString()
	description!: string;

	@ViewColumn()
	@Expose()
	@IsString()
	tenantId!: string;

	@ViewColumn()
	@Expose()
	@IsBoolean()
	allowEmailReports!: boolean;

	@ViewColumn()
	@Expose()
	@IsBoolean()
	allowUserIdentification!: boolean;

	@ViewColumn()
	@Expose()
	@IsBoolean()
	allowTranscript!: boolean;

	@ViewColumn()
	@Expose()
	@IsBoolean()
	allowAudio!: boolean;

	@ViewColumn()
	@Expose()
	@IsBoolean()
	allowNotifyAvailabilityWindow!: boolean;

	@ViewColumn()
	@Expose()
	@IsBoolean()
	allowNotify24HoursBefore!: boolean;

	@ViewColumn()
	@Expose()
	@IsBoolean()
	allowNotifyDueDate!: boolean;

	@ViewColumn()
	@Expose()
	@IsInt()
	audioStorageLength!: number;

	@ViewColumn()
	@Expose()
	@IsInt()
	assignmentCallTimeLimit!: number;

	@ViewColumn()
	@Expose()
	@IsInt()
	selfServeCallTimeLimit!: number;

	@ViewColumn()
	@Expose()
	@IsString()
	highPriorityDef!: string;

	@ViewColumn()
	@Expose()
	@IsString()
	mediumPriorityDef!: string;

	@ViewColumn()
	@Expose()
	@IsString()
	lowPriorityDef!: string;

	@ViewColumn()
	@Expose()
	@IsString()
	highPriorityContactInfo!: string;

	@ViewColumn()
	@Expose()
	@IsString()
	jobSearchResources!: string;

	@ViewColumn()
	@Expose()
	@Transform(({ value }) => dateTransformer(value))
	minutesResetDate!: Date | null;

	@ViewColumn()
	@Expose()
	@IsArray()
	@IsString({ each: true })
	allowedEmailDomains!: string[];

	@ViewColumn()
	@Expose()
	@IsBoolean()
	allowGenericSignup!: boolean;

	@ViewColumn()
	@Expose()
	@IsString()
	signupCode!: string | null;
}
