import { Assistant } from "../contexts/AppContext";

export const assistants: Assistant[] = [
	{
		id: "1",
		name: "Nina",
		audio: `${process.env.PUBLIC_URL}/audio/nina.mp3`,
		description: "Kind and empathetic",
		voice_provider: "11labs",
		voice_id: "P7x743VjyZEOihNNygQ9",
		language: "en",
		model: "eleven_turbo_v2_5"
	},
	{
		id: "2",
		name: "David",
		audio: `${process.env.PUBLIC_URL}/audio/david.mp3`,
		description: "Reserved and serious",
		voice_provider: "11labs",
		voice_id: "iP95p4xoKVk53GoZ742B",
		language: "en",
		model: "eleven_turbo_v2_5"
	},
	{
		id: "3",
		name: "Myra",
		audio: `${process.env.PUBLIC_URL}/audio/myra.mp3`,
		description: "Calm and professional",
		voice_provider: "11labs",
		voice_id: "2zRM7PkgwBPiau2jvVXc",
		language: "en",
		model: "eleven_turbo_v2_5"
	},
	{
		id: "4",
		name: "John",
		audio: `${process.env.PUBLIC_URL}/audio/john.mp3`,
		description: "Relaxed and friendly",
		voice_provider: "11labs",
		voice_id: "cjVigY5qzO86Huf0OWal",
		language: "en",
		model: "eleven_turbo_v2_5"
	},
	{
		id: "5",
		name: "Ava",
		audio: `${process.env.PUBLIC_URL}/audio/ava.mp3`,
		description: "Calm and professional",
		voice_provider: "11labs",
		voice_id: "cgSgspJ2msm6clMCkdW9",
		language: "en",
		model: "eleven_turbo_v2_5"
	},
	{
		id: "6",
		name: "Jessica",
		audio: `${process.env.PUBLIC_URL}/audio/ava.mp3`,
		description: "Relaxed and friendly",
		voice_provider: "11labs",
		voice_id: "Xb7hH8MSUJpSbSDYk0k2",
		language: "fr",
		model: "eleven_multilingual_v2"
	},
	{
		id: "7",
		name: "Thomas",
		audio: `${process.env.PUBLIC_URL}/audio/thomas.mp3`,
		description: "Relaxed and friendly",
		voice_provider: "11labs",
		voice_id: "RBhYSNMNu6b2CGZ9Fn1M", // Felix Tabarnak

		language: "fr",
		model: "eleven_multilingual_v2"
	},
	{
		id: "8",
		name: "Louis",
		audio: `${process.env.PUBLIC_URL}/audio/louis.mp3`,
		description: "Relaxed and friendly",
		voice_provider: "11labs",
		voice_id: "j9RedbMRSNQ74PyikQwD", // Louis Boutin

		language: "fr",
		model: "eleven_multilingual_v2"
	},
	{
		id: "9",
		name: "Jeanne",
		audio: `${process.env.PUBLIC_URL}/audio/kevin.mp3`,
		description: "Relaxed and friendly",
		voice_provider: "11labs",
		voice_id: "K7gx0ylJdff0yjM2uVQS", // Jeanne Mance -

		language: "fr",
		model: "eleven_multilingual_v2"
	}
];
