import React, { useState, useEffect } from "react";
// import { Slider } from "../../../shadcn-ui/Slider";
import { ArrowUpIcon, ArrowDownIcon, TrashIcon, PencilIcon } from "@heroicons/react/20/solid";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "../../../shadcn-ui/Select";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from "../../../shadcn-ui/Dialog";
import { Button } from "../../../shadcn-ui/Button";
import { Textarea } from "../../../shadcn-ui/Textarea";
import {
	ScreeningQuestion,
	CareerPathStage,
	CareerPathStageType,
	CareerPathStagePlan,
	CoreQuestionType,
	QuestionType,
	CareerPathPayload
} from "../../../../types/CareerPathPayload";
import CustomTooltip from "../../../Utility/CustomTooltip";

interface FormValues {
	stages?: CareerPathStage[];
	stagePlan: {
		[key: string]: CareerPathStagePlan;
	};
	careerPath?: CareerPathPayload;
}

interface CareerPathPlanProps {
	careerPathStageOrderNumber: number;
	values: FormValues;
	setFieldValue: (field: string, value: any) => void;
	isAdminPath?: boolean;
}

const CareerPathPlan: React.FC<CareerPathPlanProps> = ({ careerPathStageOrderNumber, values, setFieldValue, isAdminPath = true }) => {
	const [questionSlots, setQuestionSlots] = useState<ScreeningQuestion[]>([]);
	const [aggregateQuestionSlots, setAggregateQuestionSlots] = useState<ScreeningQuestion[]>([]);
	const [isAddQuestionOpen, setIsAddQuestionOpen] = useState(false);
	const [isEditQuestionOpen, setIsEditQuestionOpen] = useState(false);
	const [newQuestion, setNewQuestion] = useState<{ type: QuestionType; text: string; context?: string }>({
		type: "Survey",
		text: ""
	});
	const [editingQuestion, setEditingQuestion] = useState<ScreeningQuestion | null>(null);

	// Set default questions
	useEffect(() => {
		setAggregateQuestionSlots([
			{
				id: "1",
				type: "Opening",
				text: "On a scale from 1 to 10, 1 being you don't know what you want to do and 10 being the most prepared, how prepared do you feel to achieving your career aspirations? Please explain.",
				order: 1
			},
			{
				id: "2",
				type: "Opening",
				text: "On a scale from 1 to 10, how supportive is your network (mentors, peers, family, etc.) in helping you progress toward your career goals?",
				order: 2
			},
			{
				id: "3",
				type: "Opening",
				text: "On a scale from 1 to 10, how happy are you with your choices related to your career and education?",
				order: 3
			}
		]);

		// if (values.stages?.[0]?.careerPathStageType === "discovery" && questionSlots.length === 0) {
		setQuestionSlots([
			{
				id: "1",
				type: "Survey",
				text: "What are your career interests or passions? Do you like working with people, or with data, or with technology?",
				order: 1
			},
			{
				id: "2",
				type: "Survey",
				text: "What type of work environment do you thrive in? Do you like working in a team, or working independently, or working in a fast-paced environment?",
				order: 2
			},
			{
				id: "3",
				type: "Survey",
				text: "How important is creative freedom and innovation in your ideal career? Would you rather come up with creative solutions to problems, or follow a set of instructions to complete a task?",
				order: 3
			},
			{
				id: "4",
				type: "Survey",
				text: "Are there any new skills you are interested in learning or any skills that you currently have that you would like to use in your career?",
				order: 4
			},
			{ id: "5", type: "Survey", text: "Are there specific sectors or industries you are particularly drawn to?", order: 5 },
			{
				id: "6",
				type: "Survey",
				text: "What motivates you the most: achieving measurable goals, receiving recognition, helping others, or financial rewards?",
				order: 6
			},
			{
				id: "7",
				type: "Survey",
				text: "What is the highest level of education you have completed? If you have a specific degree, please tell me about it and if it still interests you. Why or Why not?",
				order: 7
			}
		]);

		// }
	}, [values.stages]);

	// Initialize question slots from stage plan or with defaults
	useEffect(() => {
		console.log("careerPathStageOrderNumber", careerPathStageOrderNumber);
		const stagePlan = values.stagePlan?.[careerPathStageOrderNumber];
		// set default values for aggregate questions
		setFieldValue(`stagePlan.${careerPathStageOrderNumber}.careerPathAggregateQuestionList`, aggregateQuestionSlots);

		// set default values for screening questions
		if (stagePlan?.careerPathQuestionList.length > 0) {
			console.log("stagePlan.careerPathQuestionList", stagePlan.careerPathQuestionList);
			setQuestionSlots(stagePlan.careerPathQuestionList);
		} else {
			// Initialize with default opening and closing questions

			console.log("questionSlots", questionSlots);
			setFieldValue(`stagePlan.${careerPathStageOrderNumber}.careerPathQuestionList`, questionSlots);
		}
	}, [careerPathStageOrderNumber, values.stagePlan, questionSlots]);

	const moveQuestion = (questionId: string, direction: "up" | "down") => {
		const currentIndex = questionSlots.findIndex((q) => q.id === questionId);
		if (currentIndex === -1) return;

		const newSlots = [...questionSlots];
		const currentQuestion = newSlots[currentIndex];
		const adjacentIndex = direction === "up" ? currentIndex - 1 : currentIndex + 1;

		// Don't move if trying to move past opening/closing questions
		if ((direction === "up" && currentIndex === 1) || (direction === "down" && currentIndex === questionSlots.length - 2)) {
			return;
		}

		// Swap orders
		const adjacentQuestion = newSlots[adjacentIndex];
		const tempOrder = currentQuestion.order;
		currentQuestion.order = adjacentQuestion.order;
		adjacentQuestion.order = tempOrder;

		// Sort the array by order
		newSlots.sort((a, b) => a.order - b.order);
		setQuestionSlots(newSlots);

		// Update form values
		setFieldValue(`stagePlan.${careerPathStageOrderNumber}.careerPathQuestionList`, newSlots);
	};

	const deleteQuestion = (questionId: string) => {
		const newSlots = questionSlots.filter((q) => q.id !== questionId);
		// Reorder remaining questions
		newSlots
			.filter((q) => q.type !== "Opening" && q.type !== "Closing")
			.forEach((q, index) => {
				q.order = index + 1;
			});
		setQuestionSlots(newSlots);
		setFieldValue(`stagePlan.${careerPathStageOrderNumber}.careerPathQuestionList`, newSlots);
	};

	const handleAddQuestion = () => {
		const questionId = `survey-${Date.now()}-${careerPathStageOrderNumber}`;

		// Get the closing question
		// const closingQuestion = questionSlots.find((slot) => slot.type === "Closing");
		// if (!closingQuestion) return;

		// Get all non-closing questions
		// const nonClosingQuestions = questionSlots.filter((slot) => slot.type !== "Closing");
		// Get the last order number of non-closing questions
		const lastOrder = Math.max(...questionSlots.map((slot) => slot.order));

		// Create new question slots array with the new question inserted before closing
		const updatedSlots = [
			...questionSlots,
			{
				id: questionId,
				type: newQuestion.type,
				order: lastOrder + 1,
				text: newQuestion.text,
				context: newQuestion.context
			}
		];

		setQuestionSlots(updatedSlots);
		setFieldValue(`stagePlan.${careerPathStageOrderNumber}.careerPathQuestionList`, updatedSlots);
		setIsAddQuestionOpen(false);
		setNewQuestion({ type: "Survey", text: "" });
	};

	const handleEditQuestion = () => {
		if (!editingQuestion) return;

		const updatedSlots = questionSlots.map((slot) => (slot.id === editingQuestion.id ? { ...slot, ...editingQuestion } : slot));

		setQuestionSlots(updatedSlots);
		setFieldValue(`stagePlan.${careerPathStageOrderNumber}.careerPathQuestionList`, updatedSlots);
		setIsEditQuestionOpen(false);
		setEditingQuestion(null);
	};

	const totalQuestions = questionSlots.filter((q) => q.type === "Survey").length;

	return (
		<div className="space-y-4">
			<div className="flex flex-col space-y-2">
				<p className="text-left text-sm text-gray-500">Total Questions: {totalQuestions}</p>
			</div>

			{/* Question List Section */}
			<div className="border rounded-lg">
				<div className="w-full px-4 py-3 flex justify-between items-center bg-gray-50 rounded-t-lg">
					<div className="flex items-center space-x-3">
						<h4 className="text-sm font-medium text-gray-700">Question List</h4>
					</div>
				</div>

				<div className="p-4">
					<div className="space-y-2">
						{questionSlots
							.sort((a, b) => a.order - b.order)
							.map((slot, index) => (
								<div key={slot.id} className="flex items-center justify-between p-4 bg-white border border-gray-200 rounded-lg">
									<div className="flex-1">
										<span className="text-sm text-gray-600">{slot.text}</span>
										<span className="ml-2 text-xs text-gray-400">{slot.type}</span>
									</div>
									<div className="flex items-center space-x-2">
										{slot.type !== "Opening" && slot.type !== "Closing" && (
											<>
												<button
													type="button"
													onClick={() => moveQuestion(slot.id, "up")}
													disabled={index === 1}
													className="p-1 text-gray-400 hover:text-gray-600 disabled:opacity-50"
													aria-label={`Move question "${slot.text.substring(0, 30)}..." up`}
												>
													<ArrowUpIcon className="h-4 w-4" />
												</button>
												<button
													type="button"
													onClick={() => moveQuestion(slot.id, "down")}
													disabled={index === questionSlots.length - 2}
													className="p-1 text-gray-400 hover:text-gray-600 disabled:opacity-50"
													aria-label={`Move question "${slot.text.substring(0, 30)}..." down`}
												>
													<ArrowDownIcon className="h-4 w-4" />
												</button>
												<button
													type="button"
													onClick={() => {
														setEditingQuestion(slot);
														setIsEditQuestionOpen(true);
													}}
													className="p-1 text-gray-400 hover:text-gray-600"
													aria-label={`Edit question "${slot.text.substring(0, 30)}..."`}
												>
													<PencilIcon className="h-4 w-4" />
												</button>
												<button
													type="button"
													onClick={() => deleteQuestion(slot.id)}
													className="p-1 text-gray-400 hover:text-gray-600"
													aria-label={`Delete question "${slot.text.substring(0, 30)}..."`}
												>
													<TrashIcon className="h-4 w-4" />
												</button>
											</>
										)}
									</div>
								</div>
							))}
					</div>

					{totalQuestions < 10 && (
						<Button
							type="button"
							variant="outline"
							className="w-full mt-4"
							onClick={() => setIsAddQuestionOpen(true)}
							aria-label="Add new screening question"
						>
							+ Add New Question
						</Button>
					)}
				</div>
			</div>

			{/* Add Question Dialog */}
			<Dialog open={isAddQuestionOpen} onOpenChange={setIsAddQuestionOpen}>
				<DialogContent>
					<DialogHeader>
						<DialogTitle>Add New Question</DialogTitle>
						<DialogDescription>Configure a new screening question.</DialogDescription>
					</DialogHeader>

					<div className="space-y-4 py-4">
						{isAdminPath && (
							<div className="space-y-2">
								<label className="text-sm font-medium flex items-center">
									Question Text
									<CustomTooltip content="These questions are used to help the AI understand the job seeker to find suitable career paths." />
								</label>
								<Select
									value={"custom"}
									onValueChange={(value) =>
										setNewQuestion((prev) => ({
											...prev,
											text: value === "auto" ? "auto" : ""
										}))
									}
									aria-label="Select question text type"
								>
									{/* <SelectTrigger>
										<SelectValue placeholder="Select text type" />
									</SelectTrigger>
									<SelectContent>
										<SelectItem value="auto">Auto-generated</SelectItem>
										<SelectItem value="custom">Custom</SelectItem>
									</SelectContent> */}
								</Select>

								{newQuestion.text !== "auto" && (
									<Textarea
										value={newQuestion.text === "auto" ? "" : newQuestion.text}
										onChange={(e) =>
											setNewQuestion((prev) => ({
												...prev,
												text: e.target.value
											}))
										}
										placeholder="Enter your custom question..."
										className="mt-2"
										aria-label="Custom question text input"
									/>
								)}
							</div>
						)}

						{/* {newQuestion.text !== "auto" && isAdminPath && (
							<div className="space-y-2">
								<label className="text-sm font-medium flex items-center">
									Model Response (Optional)
									<CustomTooltip content="If you provide a model (ideal) response, it will influence the AI's evaluation of the student's answer." />
								</label>
								<Textarea
									value={newQuestion.context}
									onChange={(e) =>
										setNewQuestion((prev) => ({
											...prev,
											context: e.target.value
										}))
									}
									placeholder="Enter a model response to the question..."
								/>
							</div>
						)} */}
					</div>

					<DialogFooter>
						<div className="flex justify-between w-full">
							<Button
								type="button"
								variant="outline"
								onClick={() => setIsAddQuestionOpen(false)}
								aria-label="Cancel adding new question"
							>
								Cancel
							</Button>
							<Button
								type="button"
								onClick={handleAddQuestion}
								disabled={newQuestion.text !== "auto" && !newQuestion.text.trim()}
								aria-label="Confirm adding new question"
							>
								Add Question
							</Button>
						</div>
					</DialogFooter>
				</DialogContent>
			</Dialog>

			{/* Edit Question Dialog */}
			<Dialog open={isEditQuestionOpen} onOpenChange={setIsEditQuestionOpen}>
				<DialogContent>
					<DialogHeader>
						<DialogTitle>Edit Question</DialogTitle>
						<DialogDescription>Modify this screening question.</DialogDescription>
					</DialogHeader>

					<div className="space-y-4 py-4">
						{isAdminPath && (
							<>
								<div className="space-y-2">
									<label className="text-sm font-medium flex items-center">
										Question Text
										<CustomTooltip content="If you choose 'Auto-generated', the system provides a context-based screening question. If you pick 'Custom', you can write your own question." />
									</label>
									<Select
										value={"custom"}
										onValueChange={(value) =>
											setEditingQuestion((prev) => (prev ? { ...prev, text: value === "auto" ? "auto" : "" } : null))
										}
										aria-label="Select question text type for editing"
									></Select>

									{editingQuestion?.text !== "auto" && (
										<Textarea
											value={editingQuestion?.text === "auto" ? "" : editingQuestion?.text}
											onChange={(e) => setEditingQuestion((prev) => (prev ? { ...prev, text: e.target.value } : null))}
											placeholder="Enter your custom question..."
											className="mt-2"
											aria-label="Edit question text input"
										/>
									)}
								</div>
							</>
						)}
					</div>

					<DialogFooter>
						<div className="flex justify-between w-full">
							<Button type="button" variant="outline" onClick={() => setIsEditQuestionOpen(false)} aria-label="Cancel editing question">
								Cancel
							</Button>
							<Button
								type="button"
								onClick={handleEditQuestion}
								disabled={editingQuestion?.text !== "auto" && !editingQuestion?.text.trim()}
								aria-label="Save question changes"
							>
								Save Changes
							</Button>
						</div>
					</DialogFooter>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default CareerPathPlan;
