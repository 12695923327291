import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import ChatSection from "../ResumeAssist/ChatSection";
import ResumeSelector from "../ResumeAssist/ResumeSelector";
import { ChatMessage, ResumeService } from "../../services/ResumeService";
import { plainToInstance } from "class-transformer";
import { ResumeView } from "../../types/ResumeView";
import TemplateViewer from "../ResumeAssist/TemplateViewer";
import { useNavigate } from "react-router-dom";
import { ProfileView } from "../../types/ProfileView";
import { ProfileService } from "../../services/ProfileService";
import ProfileInfoBox from "../ResumeAssist/ProfileInfoBox";
import ProfileProgressBar from "../ResumeAssist/ProfileProgressBar";
import { ArrowUturnLeftIcon } from "@heroicons/react/24/outline";
import BackButton from "../Utility/BackButton";
import { Button } from "../shadcn-ui/Button";
import ProfileSectionNav from "../ResumeAssist/ProfileSectionNav";
import { ResumeUpload } from "../ResumeAssist/Resumeupload";
import { TooltipContent, TooltipProvider, TooltipTrigger } from "../shadcn-ui/Tooltip";
import { Tooltip } from "../shadcn-ui/Tooltip";
import { InfoIcon, PencilIcon } from "lucide-react";
import { Dialog, DialogHeader, DialogTitle, DialogContent } from "../shadcn-ui/Dialog";
import { LockIcon, ShieldCheckIcon, UserIcon } from "lucide-react";
import ResumeUploadPopup from "../ResumeAssist/ResumeUploadPopup";
import FronteggService from "../../services/FronteggService";
import { useAuth } from "../../contexts/AuthContext";
import { useTranslation } from "../Utility/UseTranslation";

const ProfileCreator: React.FC = () => {
	const navigate = useNavigate();
	const [message, setMessage] = useState("");
	const [chatHistory, setChatHistory] = useState<ChatMessage[]>([]);
	const [isWaitingForAI, setIsWaitingForAI] = useState(false);
	const { profileId } = useParams<{ profileId?: string }>();
	const { userId } = useParams<{ userId?: string }>();
	const [previousProfile, setPreviousProfile] = useState<ProfileView | null>(null);
	const [profile, setProfile] = useState<ProfileView | null>(null);
	const [documentUrl, setDocumentUrl] = useState<string | null>(null);
	const [initialized, setInitialized] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [selectedSection, setSelectedSection] = useState<string | null>("contact");
	const [unconfirmedSections, setUnconfirmedSections] = useState<string[]>([]);
	const [isEditing, setIsEditing] = useState(false);
	const [editedData, setEditedData] = useState<Partial<ProfileView>>({});
	const [isSaving, setIsSaving] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [validationErrors, setValidationErrors] = useState<string[]>([]);
	const [showBackButtonConfirmationDialog, setShowBackButtonConfirmationDialog] = useState(false);
	const [resumeUploadModalOpen, setResumeUploadModalOpen] = useState(false);
	const { instageUser } = useAuth();
	const { t } = useTranslation("profile");
	useEffect(() => {
		if (profile) {
			setIsOpen(!profile.hasAgreedToPrivacy);
		}
	}, [profile]);

	const validateRequiredFields = () => {
		const errors: string[] = [];

		if (editedData.contact) {
			const { firstName, lastName, email, phone } = editedData.contact;
			if (!firstName) errors.push(t("error.firstNameRequired"));
			if (!lastName) errors.push(t("error.lastNameRequired"));
			if (!email) errors.push(t("error.emailRequired"));
			if (!phone) errors.push(t("error.phoneRequired"));
		}

		if (editedData.summary === "") {
			errors.push(t("error.summaryRequired"));
		}

		if (editedData.experience) {
			editedData.experience.forEach((exp, index) => {
				if (!exp.jobTitle) errors.push(`${t("section.experience")} ${index + 1}: ${t("error.jobTitleRequired")}`);
				if (!exp.companyName) errors.push(`${t("section.experience")} ${index + 1}: ${t("error.companyNameRequired")}`);
				if (!exp.city) errors.push(`${t("section.experience")} ${index + 1}: ${t("error.cityRequired")}`);
				if (!exp.stateOrProv) errors.push(`${t("section.experience")} ${index + 1}: ${t("error.stateOrProvinceRequired")}`);
				if (!exp.startMonth) errors.push(`${t("section.experience")} ${index + 1}: ${t("error.startMonthRequired")}`);
				if (!exp.startYear) errors.push(`${t("section.experience")} ${index + 1}: ${t("error.startYearRequired")}`);
				if (!exp.endMonth) errors.push(`${t("section.experience")} ${index + 1}: ${t("error.endMonthRequired")}`);
				if (exp.endMonth !== "Present" && !exp.endYear) errors.push(`${t("section.experience")} ${index + 1}: ${t("error.endYearRequired")}`);
				if (!exp.roles?.length) errors.push(`${t("section.experience")} ${index + 1}: ${t("error.rolesRequired")}`);
			});
		}

		if (editedData.education) {
			editedData.education.forEach((edu, index) => {
				if (!edu.school) errors.push(`${t("section.education")} ${index + 1}: ${t("error.schoolRequired")}`);
				if (!edu.degree) errors.push(`${t("section.education")} ${index + 1}: ${t("error.degreeRequired")}`);
				if (!edu.program) errors.push(`${t("section.education")} ${index + 1}: ${t("error.programRequired")}`);
				if (!edu.graduatingYear) errors.push(`${t("section.education")} ${index + 1}: ${t("error.graduatingYearRequired")}`);
			});
		}

		if (editedData.projects) {
			editedData.projects.forEach((proj, index) => {
				if (!proj.projectName) errors.push(`${t("section.projects")} ${index + 1}: ${t("error.projectNameRequired")}`);
				if (!proj.description) errors.push(`${t("section.projects")} ${index + 1}: ${t("error.descriptionRequired")}`);
				if (!proj.startMonth) errors.push(`${t("section.projects")} ${index + 1}: ${t("error.startMonthRequired")}`);
				if (!proj.startYear) errors.push(`${t("section.projects")} ${index + 1}: ${t("error.startYearRequired")}`);
				if (!proj.endMonth) errors.push(`${t("section.projects")} ${index + 1}: ${t("error.endMonthRequired")}`);
				if (proj.endMonth !== "Present" && !proj.endYear) errors.push(`${t("section.projects")} ${index + 1}: ${t("error.endYearRequired")}`);
				if (!proj.notes?.length) errors.push(`${t("section.projects")} ${index + 1}: ${t("error.notesRequired")}`);
			});
		}

		if (editedData.certifications) {
			editedData.certifications.forEach((cert, index) => {
				if (!cert.certificationName) errors.push(`${t("section.certifications")} ${index + 1}: ${t("error.certificationNameRequired")}`);
				if (!cert.issuingOrganization) errors.push(`${t("section.certifications")} ${index + 1}: ${t("error.issuingOrganizationRequired")}`);
				if (!cert.issueMonth) errors.push(`${t("section.certifications")} ${index + 1}: ${t("error.issueMonthRequired")}`);
				if (!cert.issueYear) errors.push(`${t("section.certifications")} ${index + 1}: ${t("error.issueYearRequired")}`);
			});
		}

		if (editedData.extraCurricular) {
			editedData.extraCurricular.forEach((activity, index) => {
				if (!activity.activity) errors.push(`${t("section.extraCurricular")} ${index + 1}: ${t("error.activityRequired")}`);
				if (!activity.orgName) errors.push(`${t("section.extraCurricular")} ${index + 1}: ${t("error.orgNameRequired")}`);
				if (!activity.startMonth) errors.push(`${t("section.extraCurricular")} ${index + 1}: ${t("error.startMonthRequired")}`);
				if (!activity.startYear) errors.push(`${t("section.extraCurricular")} ${index + 1}: ${t("error.startYearRequired")}`);
				if (!activity.endMonth) errors.push(`${t("section.extraCurricular")} ${index + 1}: ${t("error.endMonthRequired")}`);
				if (activity.endMonth !== "Present" && !activity.endYear)
					errors.push(`${t("section.extraCurricular")} ${index + 1}: ${t("error.endYearRequired")}`);
				if (!activity.tasks?.length) errors.push(`${t("section.extraCurricular")} ${index + 1}: ${t("error.tasksRequired")}`);
			});
		}

		setValidationErrors(errors);
		return errors.length === 0;
	};

	const handleConfirm = async () => {
		if (!profile || !editedData) return;

		if (!validateRequiredFields()) {
			// Show validation errors in a dialog
			setIsOpen(true);
			return;
		}

		setIsSaving(true);

		try {
			// Save each modified section
			const sections = Object.keys(editedData) as Array<
				keyof Omit<ProfileView, "id" | "userId" | "userEmail" | "chatHistory" | "isPublic" | "createdAt" | "updatedAt">
			>;
			for (const section of sections) {
				await ProfileService.updateProfileSection(profile.id, section, editedData[section]);
			}

			// Update the profile state with the new data
			setProfile({ ...profile, ...editedData });
			setEditedData({});
			setIsEditing(false);
		} catch (error) {
			console.error("Error saving profile:", error);
		} finally {
			setIsSaving(false);
		}
	};

	const updateProfileSection = (sectionName: string, sectionData: any) => {
		console.log("updateProfileSection: ", sectionName, sectionData);
		if (profile) {
			setPreviousProfile(profile);
			const updatedProfile = { ...profile, [sectionName]: sectionData };
			setProfile(updatedProfile);
		}
	};

	useEffect(() => {
		if (!profile || !previousProfile) {
			return;
		}
		const profileSections = [
			"contact",
			"summary",
			"experience",
			"education",
			"extraCurricular",
			"skills",
			"projects",
			"certifications",
			"interests",
			"hobbies"
		];
		if (previousProfile && profile) {
			profileSections.forEach((section) => {
				if (profile[section as keyof ProfileView] !== previousProfile[section as keyof ProfileView]) {
					setUnconfirmedSections((prev) => [...prev, section]);
				}
			});
		}
	}, [previousProfile, profile]);

	const getAISelectedSectionResponse = async (message: string, chatHistory: string[]) => {
		// Artificial delay
		try {
			await new Promise((resolve) => setTimeout(resolve, 1000));
			const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/profile/get-ai-selected-section`, {
				message,
				chatHistory,
				sectionName: selectedSection
			});
			console.log("response.data: ", response.data.AiSelectedSection);
			if (response.data.AiSelectedSection) {
				setSelectedSection(response.data.AiSelectedSection);
			}
			return response.data.AiSelectedSection;
		} catch (error) {
			console.log(error);
			throw error;
		}
	};

	const getAIResponse = async (message: string, section: string) => {
		try {
			await new Promise((resolve) => setTimeout(resolve, 1000));
			const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/profile/send-profile-chat`, {
				profileId: profileId,
				message,
				sectionName: section // Use the passed section instead of selectedSection state
			});
			console.log("response.data: ", response.data[`${section}`]);
			if (section) {
				updateProfileSection(section, response.data[`${section}`]);
			}
			return response.data;
		} catch (error) {
			console.log(error);
			throw error;
		}
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		if (message.trim()) {
			// Add user message to chat
			const userMessage: ChatMessage = {
				role: "User",
				content: message,
				timestamp: new Date()
			};
			const updatedHistory = [...chatHistory, userMessage];
			setChatHistory(updatedHistory);
			setIsWaitingForAI(true);

			if (profileId) {
				try {
					console.log("updatingChatHistory ProfileId: ", profileId, userMessage);
					await ProfileService.updateChatHistory(profileId, userMessage);
				} catch (error) {
					console.log(error);
					throw error;
				}
			}
			// try {
			// 	await getAISelectedSectionResponse(
			// 		message,
			// 		updatedHistory.map((msg) => `${msg.role}: ${msg.content}`)
			// 	);
			// 	// setSelectedSection(aiDeterminedSection.AiSelectedSection);
			// } catch (error) {
			// 	console.log(error);
			// }

			try {
				const aiSelectedSummary = await getAISelectedSectionResponse(
					message,
					updatedHistory.map((msg) => `${msg.role}: ${msg.content}`)
				);
				console.log("aiSelectedSummary", aiSelectedSummary);

				// Get AI response using the newly selected section directly from the response
				const aiResponse = await getAIResponse(
					message,
					aiSelectedSummary // Pass the selected section directly
				);
				const assistantMessage: ChatMessage = {
					role: "Assistant",
					content: aiResponse.message,
					timestamp: new Date()
				};
				setChatHistory([...updatedHistory, assistantMessage]);

				if (profileId) {
					try {
						await ProfileService.updateChatHistory(profileId, assistantMessage);
					} catch (error) {
						console.log(error);
						throw error;
					}
				}
			} catch (error) {
				console.error("Error getting AI response:", error);
			} finally {
				setIsWaitingForAI(false);
			}

			setMessage("");
		}
	};

	const handleFileSelect = (file: File) => {
		if (file.size > 5 * 1024 * 1024) {
			alert("File size should be less than 5MB");
			return;
		}

		const userMessage: ChatMessage = {
			role: "User",
			content: `Selected resume: ${file.name}`,
			timestamp: new Date()
		};
		const assistantMessage: ChatMessage = {
			role: "Assistant",
			content: `I see you've selected ${file.name}. Would you like me to analyze this resume for you?`,
			timestamp: new Date()
		};
		setChatHistory([...chatHistory, userMessage, assistantMessage]);
	};

	useEffect(() => {
		const fetchProfile = async () => {
			if (profileId) {
				const fetchedProfile = await ProfileService.fetchProfile(profileId);
				setProfile(fetchedProfile);
				setChatHistory(fetchedProfile?.chatHistory || []);
				setInitialized(true);
			}
		};
		if (profileId) {
			fetchProfile();
		}
	}, [profileId]);

	useEffect(() => {
		const initializeChat = async () => {
			const initialMessage: ChatMessage = {
				role: "Assistant",
				content:
					"Hi there, I see that you need some help creating or refining your resume. First, Can you please either upload your existing resume or start by providing some basic information about yourself such as your name?",
				timestamp: new Date()
			};
			setChatHistory([initialMessage]);
			setIsLoading(false);

			if (profileId) {
				try {
					await ProfileService.updateChatHistory(profileId, initialMessage);
				} catch (error) {
					console.log(error);
					throw error;
				}
			}
		};

		if (chatHistory.length === 0 && profileId && initialized) {
			initializeChat();
		}
	}, [chatHistory, profileId, initialized]);

	const AgreeToPrivacy = async () => {
		console.log("AgreeToPrivacy");
		if (!profileId) {
			console.log("No profileId");
			return;
		}
		await ProfileService.agreeToPrivacy(profileId);
		setIsOpen(false);
		if (profile) {
			setProfile({ ...profile, hasAgreedToPrivacy: true });
		}

		if (instageUser) {
			await FronteggService.getInstance().createAuditLog({
				userId: instageUser.id,
				fronteggId: instageUser?.fronteggId || "",
				tenantId: instageUser.clientId,
				action: `Agreed to Profile Creator Privacy Policy`,
				severity: "Info"
			});
		}
	};

	const backButtonClicked = () => {
		console.log("backButtonClicked");
		if (isEditing) {
			setShowBackButtonConfirmationDialog(true);
		} else {
			navigate("/user/resume-assist");
		}
	};

	const BackButtonConfirmationDialog: React.FC<{
		isOpen: boolean;
		setIsOpen: (open: boolean) => void;
		onConfirm: () => void;
	}> = ({ isOpen, setIsOpen, onConfirm }) => {
		const navigate = useNavigate();
		const { t } = useTranslation("profile");

		return (
			<Dialog open={isOpen} onOpenChange={setIsOpen}>
				<DialogContent className="sm:max-w-[425px]">
					<DialogHeader>
						<DialogTitle className="flex items-center gap-2 text-xl">
							<ArrowUturnLeftIcon className="h-6 w-6 text-gray-500" />
							{t("backButtonConfirmationDialog.title")}
						</DialogTitle>
					</DialogHeader>
					<div className="py-4">
						<p className="text-sm text-gray-600">{t("backButtonConfirmationDialog.description")}</p>
						<div className="flex gap-3 justify-start pt-6">
							<Button
								variant="outline"
								onClick={onConfirm}
								className="border-[#00a9af] text-[#00a9af] hover:bg-transparent hover:text-[#00a9af]/90 hover:border-[#00a9af]/90"
							>
								{t("backButtonConfirmationDialog.leavePage")}
							</Button>
							<Button onClick={() => setIsOpen(false)} className="bg-[#00a9af] hover:bg-[#00a9af]/90 text-white border-none">
								{t("backButtonConfirmationDialog.continueEditing")}
							</Button>
						</div>
					</div>
				</DialogContent>
			</Dialog>
		);
	};

	const ProfileDisclaimer: React.FC = () => {
		const navigate = useNavigate();

		return (
			<>
				<Dialog open={isOpen} onOpenChange={setIsOpen}>
					<DialogContent className="sm:max-w-[425px]">
						<DialogHeader>
							<DialogTitle className="flex items-center gap-2 text-xl">
								<LockIcon className="h-6 w-6 text-gray-500" />
								{t("profileDisclaimer.title")}
							</DialogTitle>
						</DialogHeader>
						<div className="py-4 space-y-6">
							<div>
								<h4 className="text-sm font-medium mb-2 flex items-center gap-2">
									<InfoIcon className="h-4 w-4 text-gray-500" />
									{t("profileDisclaimer.dataStorage")}
								</h4>
								<p className="text-sm text-gray-600">{t("profileDisclaimer.dataStorageDescription")}</p>
							</div>
							<div>
								<h4 className="text-sm font-medium mb-2 flex items-center gap-2">
									<ShieldCheckIcon className="h-4 w-4 text-gray-500" />
									{t("profileDisclaimer.dataAccess")}
								</h4>
								<p className="text-sm text-gray-600">{t("profileDisclaimer.dataAccessDescription")}</p>
							</div>
							<div>
								<h4 className="text-sm font-medium mb-2 flex items-center gap-2">
									<UserIcon className="h-4 w-4 text-gray-500" />
									{t("profileDisclaimer.dataSharing")}
								</h4>
								<p className="text-sm text-gray-600">{t("profileDisclaimer.dataSharingDescription")}</p>
							</div>
							<div className="flex gap-3 justify-start pt-2">
								<Button
									variant="outline"
									onClick={() => navigate("/user/resume-assist")}
									className="border-[#00a9af] text-[#00a9af] hover:bg-transparent hover:text-[#00a9af]/90 hover:border-[#00a9af]/90"
									aria-label="Cancel and return to Resume Assist page"
								>
									{t("profileDisclaimer.close")}
								</Button>
								<Button
									onClick={() => AgreeToPrivacy()}
									className="bg-[#00a9af] hover:bg-[#00a9af]/90 text-white border-none"
									aria-label="Agree to privacy policy and continue"
								>
									{t("profileDisclaimer.agree")}
								</Button>
							</div>
						</div>
					</DialogContent>
				</Dialog>
			</>
		);
	};

	return (
		<>
			{profileId && (
				<>
					{resumeUploadModalOpen && !isEditing && (
						<ResumeUploadPopup
							isOpen={resumeUploadModalOpen}
							onClose={() => setResumeUploadModalOpen(false)}
							profileId={profileId}
							setProfile={setProfile}
							profile={profile}
						/>
					)}

					<div className="h-[calc(75vh)] mt-0">
						{!profile?.hasAgreedToPrivacy && <ProfileDisclaimer />}
						{showBackButtonConfirmationDialog && (
							<BackButtonConfirmationDialog
								isOpen={showBackButtonConfirmationDialog}
								setIsOpen={setShowBackButtonConfirmationDialog}
								onConfirm={() => navigate("/user/resume-assist")}
							/>
						)}
						{validationErrors.length > 0 && (
							<Dialog open={isOpen} onOpenChange={setIsOpen}>
								<DialogContent className="sm:max-w-[425px]">
									<DialogHeader>
										<DialogTitle className="text-xl text-red-600">Required Fields Missing</DialogTitle>
									</DialogHeader>
									<div className="py-4">
										<div className="max-h-[300px] overflow-y-auto">
											<ul className="list-disc pl-5 space-y-2">
												{validationErrors.map((error, index) => (
													<li key={index} className="text-sm text-gray-600">
														{error}
													</li>
												))}
											</ul>
										</div>
										<div className="flex justify-end mt-4">
											<Button onClick={() => setIsOpen(false)} aria-label="Close validation errors dialog">
												Close
											</Button>
										</div>
									</div>
								</DialogContent>
							</Dialog>
						)}
						<div className="container mx-auto max-w-5xl px-6 py-2 flex flex-col h-full">
							<div className="flex items-center gap-2 mb-4">
								<h1 className="text-2xl font-bold text-[#16013e]">{t("title")}</h1>
								<TooltipProvider delayDuration={0}>
									<Tooltip>
										<TooltipTrigger asChild onClick={(e) => e.preventDefault()} aria-label="View profile information help">
											<button>
												<InfoIcon
													className="w-4 h-4 text-[#16013e] hover:text-[#00a9af] transition-colors cursor-pointer"
													aria-hidden="true"
												/>
											</button>
										</TooltipTrigger>
										<TooltipContent side="right" align="center" role="tooltip">
											<p className="whitespace-pre-wrap max-w-[300px]">{t("description")}</p>
										</TooltipContent>
									</Tooltip>
								</TooltipProvider>
							</div>
							<div className="flex justify-between items-start w-full">
								<BackButton
									label={t("backButtonLabel")}
									onClick={() => backButtonClicked()}
									hasCustomModal={true}
									aria-label="Return to Resume Assist page"
								/>
							</div>
							<div className="flex items-center gap-4">
								<div className="flex-1 flex flex-row">
									<ProfileProgressBar profile={profile} setSelectedSection={setSelectedSection} />
									<div className="flex gap-2 items-start">
										{!isEditing && (
											<Button
												variant="outline"
												onClick={() => setResumeUploadModalOpen(true)}
												aria-label="Open resume upload dialog"
											>
												{t("buttons.uploadResume")}
											</Button>
										)}

										{isEditing ? (
											<>
												<Button
													variant="outline"
													onClick={() => {
														setEditedData({});
														setIsEditing(false);
													}}
													disabled={isSaving}
													aria-label="Cancel profile editing"
												>
													{t("buttons.cancel")}
												</Button>
												<Button
													onClick={handleConfirm}
													disabled={isSaving}
													aria-label={isSaving ? "Saving profile changes" : "Save profile changes"}
												>
													{isSaving ? (
														<div className="flex items-center gap-2">
															<div
																className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin"
																aria-hidden="true"
															/>
															{t("buttons.saving")}...
														</div>
													) : (
														t("buttons.save")
													)}
												</Button>
											</>
										) : (
											<Button
												onClick={() => {
													if (profile) {
														const defaultContact = {
															firstName: "",
															lastName: "",
															email: "",
															phone: "",
															address: "",
															linkedinURL: "",
															website: ""
														};

														setEditedData({
															contact: profile.contact ? { ...defaultContact, ...profile.contact } : defaultContact,
															summary: profile.summary || "",
															experience: profile.experience || [],
															education: profile.education || [],
															skills: profile.skills || {
																technical: [],
																coreCompetencies: [],
																languages: [],
																tools: [],
																frameworks: []
															},
															projects: profile.projects || [],
															certifications: profile.certifications || [],
															extraCurricular: profile.extraCurricular || [],
															interests: profile.interests || [],
															hobbies: profile.hobbies || []
														});
													}
													setIsEditing(true);
												}}
												aria-label="Edit profile information"
											>
												<PencilIcon className="w-4 h-4 mr-2" aria-hidden="true" />
												{t("buttons.edit")}
											</Button>
										)}
									</div>
								</div>
							</div>
							<div className="flex gap-8 h-full">
								{/* <div className="hidden lg:block flex-col w-64">
								<ResumeUpload profileId={profileId} setProfile={setProfile} profile={profile} />
								<ProfileSectionNav
									selectedSection={selectedSection || "contact"}
									setSelectedSection={setSelectedSection}
									profile={profile}
								/>
							</div> */}
								<div className="flex-1 h-full overflow-y-auto">
									{profile && (
										<ProfileInfoBox
											profile={profile}
											setSelectedSection={setSelectedSection}
											selectedSection={selectedSection || "contact"}
											unconfirmedSections={unconfirmedSections}
											setUnconfirmedSections={setUnconfirmedSections}
											setProfile={setProfile}
											isEditing={isEditing}
											editedData={editedData}
											setEditedData={setEditedData}
											isOpen={isOpen}
											setIsOpen={setIsOpen}
											resumeId={profileId}
										/>
									)}
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
};

export default ProfileCreator;
