import axios from "axios";
import { ProfileView } from "../types/ProfileView";

import { plainToInstance } from "class-transformer";
import { ChatMessage } from "./ResumeService";
import { LogAxiosError } from "./AxiosUtility";
import posthog from "posthog-js";

export class ProfileService {
	private static baseUrl = `${process.env.REACT_APP_BACKEND_URL}/api/profile`;

	public static async fetchOrCreateProfile(userId: string): Promise<ProfileView | null> {
		try {
			const response = await axios.post(`${this.baseUrl}/fetch-or-create-profile`, { userId });
			console.log("Profile Fetched Or Created", { userId });
			posthog.capture("Profile Fetched Or Created", { userId });
			return plainToInstance(ProfileView, response.data);
		} catch (err) {
			console.log(err);
			return null;
		}
	}

	public static async fetchProfile(profileId: string): Promise<ProfileView | null> {
		try {
			const response = await axios.get(`${this.baseUrl}/fetch-profile/${profileId}`);
			return plainToInstance(ProfileView, response.data);
		} catch (err) {
			console.log(err);
			return null;
		}
	}

	public static async fetchProfileByUserId(userId: string): Promise<ProfileView | null> {
		try {
			const response = await axios.get(`${this.baseUrl}/fetch-profile-by-user-id/${userId}`);
			return plainToInstance(ProfileView, response.data);
		} catch (err) {
			console.log(err);
			return null;
		}
	}

	public static async updateChatHistory(profileId: string, newChat: ChatMessage): Promise<ProfileView[]> {
		try {
			const response = await axios.post(`${this.baseUrl}/update-chat-history`, {
				profileId,
				newChat: newChat.content,
				role: newChat.role
			});
			return plainToInstance(ProfileView, response.data as ProfileView[]);
		} catch (error) {
			const errorMessage = LogAxiosError(error, `update chat history ${profileId}`);
			console.log(errorMessage);
			throw new Error(errorMessage);
		}
	}

	static async updateProfileSection(profileId: string, sectionName: string, sectionData: any): Promise<void> {
		console.log("updateProfileSection", sectionName, sectionData, profileId);
		if (!profileId) {
			// Extract profileId from the URL
			console.error("No profile id provided. Trying to get from url");
			const url = window.location.href;
			const match = url.match(/profile\/([a-f0-9-]+)/);
			if (match) {
				profileId = match[1];
				console.log("Extracted profileId from URL:", profileId);
			} else {
				console.error("No profile id and unable to extract from URL");
				return;
			}
		}
		try {
			await axios.patch(`${this.baseUrl}/update-section`, {
				sectionName,
				sectionData,
				profileId
			});
		} catch (error) {
			console.error("Error updating profile section:", error);
			throw error;
		}
	}

	public static async agreeToPrivacy(profileId: string): Promise<void> {
		try {
			await axios.post(`${this.baseUrl}/agree-to-privacy`, { profileId });
		} catch (error) {
			console.error("Error agreeing to privacy:", error);
			throw error;
		}
	}

	public static async deleteProfileResumeCoverLetter(userId: string): Promise<void> {
		console.log("userId", userId);
		try {
			const response = await axios.delete(`${this.baseUrl}/delete-profile/${userId}`);
			console.log("response", response);
		} catch (error) {
			console.error("Error deleting profile:", error);
			throw error;
		}
	}
}
