import React, { useEffect, useMemo, useRef } from "react";

import { XCircleIcon } from "@heroicons/react/24/solid";

import { Button } from "../../shadcn-ui/Button";

import { UserAssignmentView } from "../../../types/UserAssignmentView";

import { InfoIcon, LockIcon, FileTextIcon, MicIcon, CheckCircleIcon, UserIcon } from "lucide-react";

import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "../../shadcn-ui/Dialog";
import { useTranslation } from "../../Utility/UseTranslation";

export const ConfidentialityDialog = ({
	assignment,
	showConfidentialityDialog,
	onOpenChange,
	onStartSession
}: {
	assignment: UserAssignmentView | null;
	showConfidentialityDialog: boolean;
	onOpenChange: (show: boolean) => void;
	onStartSession: () => void;
}) => {
	const { t } = useTranslation("extraUtility");

	return (
		<Dialog open={showConfidentialityDialog} onOpenChange={onOpenChange}>
			<DialogContent className="sm:max-w-[425px]">
				<DialogHeader>
					<DialogTitle className="flex items-center gap-2 text-xl">
						<LockIcon className="h-6 w-6 text-gray-500" />
						{t("confidentialityDialog.title")}
					</DialogTitle>
				</DialogHeader>
				<div className="py-4">
					<h3 className="text-lg font-semibold mb-2 flex items-center gap-2">
						<InfoIcon className="h-5 w-5 text-gray-500" />
						{t("confidentialityDialog.reminderTitle")}
					</h3>
					<p className="mb-4 text-sm text-gray-600">
						{t("confidentialityDialog.reminderText")} <span className="font-semibold">{t("confidentialityDialog.sensitiveInfo")}</span>{" "}
						{t("confidentialityDialog.reminderContinued")}
					</p>
					<h3 className="text-lg font-semibold mb-2 flex items-center gap-2">
						<InfoIcon className="h-5 w-5 text-gray-500" />
						{t("confidentialityDialog.dataTitle")}
					</h3>
					<p className="mb-2 text-sm text-gray-600">{t("confidentialityDialog.settingsIntro")}</p>
					<div className="space-y-3 mb-4">
						{assignment?.includeTranscript && (
							<div className="flex items-center gap-2 text-sm">
								<CheckCircleIcon className="h-5 w-5 text-green-500" />
								<FileTextIcon className="h-4 w-4 text-green-500" />
								<span className="font-medium">{t("confidentialityDialog.transcriptShared")}</span>
							</div>
						)}

						{!assignment?.includeTranscript && (
							<div className="flex items-center gap-2 text-sm">
								<XCircleIcon className="h-5 w-5 text-red-500" />
								<FileTextIcon className="h-4 w-4 text-gray-400" />
								<span className="font-medium text-gray-500">{t("confidentialityDialog.transcriptNotShared")}</span>
							</div>
						)}

						{assignment?.includeAudio && (
							<div className="flex items-center gap-2 text-sm">
								<CheckCircleIcon className="h-5 w-5 text-green-500" />
								<MicIcon className="h-4 w-4 text-green-500" />
								<span className="font-medium">{t("confidentialityDialog.audioShared")}</span>
							</div>
						)}

						{!assignment?.includeAudio && (
							<div className="flex items-center gap-2 text-sm">
								<XCircleIcon className="h-5 w-5 text-red-500" />
								<MicIcon className="h-4 w-4 text-gray-400" />
								<span className="font-medium text-gray-500">{t("confidentialityDialog.audioNotShared")}</span>
							</div>
						)}

						{assignment?.includeUserIdentification && (
							<div className="flex items-center gap-2 text-sm">
								<CheckCircleIcon className="h-5 w-5 text-green-500" />
								<UserIcon className="h-4 w-4 text-green-500" />
								<span className="font-medium">{t("confidentialityDialog.nameShared")}</span>
							</div>
						)}

						{!assignment?.includeUserIdentification && (
							<div className="flex items-center gap-2 text-sm">
								<XCircleIcon className="h-5 w-5 text-red-500" />
								<UserIcon className="h-4 w-4 text-gray-400" />
								<span className="font-medium text-gray-500">{t("confidentialityDialog.nameNotShared")}</span>
							</div>
						)}
					</div>
				</div>
				<DialogFooter className="sm:justify-start gap-2">
					<Button
						variant="outline"
						onClick={() => {
							onOpenChange(false);
						}}
					>
						{t("confidentialityDialog.disagree")}
					</Button>
					<Button name={"agreeConfidentiality"} aria-label={t("confidentialityDialog.agreeLabel")} onClick={onStartSession}>
						{t("confidentialityDialog.agreeContinue")}
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};
