// src/contexts/AuthContext.tsx
import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";

import { useAuth as useFronteggAuth, useLoginWithRedirect } from "@frontegg/react";
import { useLocation, useNavigate } from "react-router-dom";
import TokenManager from "../services/TokenManager";
import { UserView } from "../types/UserView";
import { UserService } from "../services/UserService";
import useSocket from "../hooks/useSocket";
import { usePostHog, useFeatureFlagEnabled } from "posthog-js/react";
import { jwtDecode } from "jwt-decode";
import { IgnoreIfEmptyXmlComponent } from "docx";

interface User {
	frontEggId: string;
	id: string;
	tenantId: string;
	clientId: string;
	email: string;
	name: string;
	roles: string[];
	mainRole: string;
}
type UserType = "frontegg" | "lti" | "backendAdmin";

interface AuthContextType {
	isAuthenticated: boolean;
	user: User | null;
	instageUser: UserView | null;
	userId: string | null;
	login: (redirectUrl: string, searchParams: string) => void;
	ltiToken: string | null;
	backendLoginToken: string | null;
	logout: () => void;
	isLoading: boolean;
	// Any other methods you need
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const TOKEN_STORAGE_KEY = "instageLtiToken";
	const BACKEND_LOGIN_TOKEN_STORAGE_KEY = "instageBackendLoginToken";
	const { isAuthenticated: isFronteggAuthenticated, user: fronteggUser } = useFronteggAuth();
	const loginWithRedirect = (searchParams: string = "") => {
		navigate(`/account/login${searchParams}`);
	};

	const [isAuthenticated, setIsAuthenticated] = useState<boolean>(isFronteggAuthenticated || false);
	const { status, emit } = useSocket({ url: process.env.REACT_APP_BACKEND_URL || "http://localhost:5002" });

	const [ltiToken, setLtiToken] = useState<string | null>(null);
	const [backendLoginToken, setBackendLoginToken] = useState<string | null>(null);
	const [user, setUser] = useState<User | null>(null);
	const [instageUser, setInstageUser] = useState<UserView | null>(null);
	const [userId, setUserId] = useState<string | null>(null);
	const location = useLocation();
	const navigate = useNavigate();
	const posthog = usePostHog();
	const backendUrl = process.env.REACT_APP_BACKEND_URL;
	const [isLoading, setIsLoading] = useState<boolean>(true);

	useEffect(() => {
		const OnUserLogin = async (userType: UserType, user: User) => {
			console.log("OnUserLogin", user);
			setUser(user);

			let foundUser = null;
			if (userType === "frontegg") {
				setIsAuthenticated(true);
				console.log("fronteggUser", user.email, user.id, user.name);
				foundUser = await UserService.getUserByFronteggId(user.id);

				if (!foundUser) {
					const createdUser = await UserService.getOrCreateUser(
						user.email,
						user.name.split(" ")[0],
						user.name.split(" ")[1],
						user.tenantId
					);
					if (!createdUser) {
						console.error("Error creating new user");
						return null;
					}
					await UserService.updateUserFronteggIdByEmail(user.id, user.email);
					foundUser = createdUser;
				}
			} else if (userType === "lti") {
				console.log("ltiUser", user.email, user.id, user.name);
				const tokenPayload = TokenManager.getTokenPayload();
				console.log("tokenPayload", tokenPayload);
				foundUser = await UserService.getUserById();
				if (!foundUser) {
					console.error("Error Fetching lti user");
					throw new Error("Error user not created by lti");
				}
				setIsAuthenticated(true);
			} else if (userType === "backendAdmin") {
				console.log("backendAdminUser", user.email, user.id, user.name);
				const tokenPayload = TokenManager.getTokenPayload();
				console.log("tokenPayload", tokenPayload);
				// foundUser = await UserService.getUserByIdV2(tokenPayload.id);
				foundUser = await UserService.getUserById();
				// console.log("foundUser", foundUser);
				if (!foundUser) {
					console.error("Error Fetching backend admin user");
					throw new Error("Error user not created by backend admin");
				}
				setIsAuthenticated(true);
			}

			if (foundUser && foundUser.id) {
				setUserId(foundUser.id);
				setInstageUser(foundUser);
				// console.log("instageUser", foundUser);
				await posthog?.identify(foundUser.id, {
					email: foundUser.email,
					role: user.mainRole,
					isReflectionApp: true
				});

				posthog.group("tenant", foundUser.clientName, {
					clientId: foundUser.clientId,
					tenantName: foundUser.clientName
				});
				// return foundUser;
			}
		};

		const loginWithLTI = async (ltikToken: string, assignmentId: string | null) => {
			try {
				const response = await axios.get(`${backendUrl}/issue-token`, { params: { ltikToken, assignmentId } });
				// const response = await axios.post("http://localhost:5002/issue-token", { litkToken });
				setLtiToken(ltikToken);
				console.log("response", response);
				const instageToken = response.data.token;
				TokenManager.setToken(instageToken);

				// console.log("TokenManager()", TokenManager.getToken());
				const tokenPayload = TokenManager.getTokenPayload();
				console.log("tokenPayload", tokenPayload);
				if (tokenPayload) {
					const ltiUser: User = {
						id: tokenPayload.id,
						frontEggId: tokenPayload.frontEggId,
						email: tokenPayload.email,
						name: tokenPayload.name,
						roles: tokenPayload.roles, // Adjust based on your token's payload
						mainRole: tokenPayload.mainRole,
						tenantId: tokenPayload.tenantId,
						clientId: tokenPayload.clientId
					};

					await OnUserLogin("lti", ltiUser);
				} else {
					console.error("Invalid token payload");
					throw new Error("Invalid token payload");
				}
			} catch (error) {
				console.error("LTI login failed", error);
				setIsAuthenticated(false);
				TokenManager.clearToken();
				throw error;
			}
		};

		const backendAdminLogin = async (backendLoginToken: string) => {
			try {
				// console.log("backendAdminLogin", backendLoginToken);
				const response = await axios.get(`${backendUrl}/backendlogin-token`, { params: { backendLoginToken } });

				const tokenData = jwtDecode(backendLoginToken) as { language: string };

				if (tokenData && tokenData.language) {
					TokenManager.setLanguage(tokenData.language as "en" | "fr");
					localStorage.setItem("language", tokenData.language);
				}

				// console.log("response", response);

				// const response = await axios.post("http://localhost:5002/issue-token", { litkToken });
				setBackendLoginToken(backendLoginToken);
				// console.log("response", response);
				const instageToken = response.data.token;
				TokenManager.setToken(instageToken);
				// console.log("TokenManager()", TokenManager.hasFacultyClearance());
				// console.log("TokenManager()", TokenManager.getToken());
				const tokenPayload = TokenManager.getTokenPayload();
				// console.log("tokenPayload", tokenPayload);
				if (tokenPayload) {
					const backendAdminUser: User = {
						id: tokenPayload.id,
						frontEggId: tokenPayload.frontEggId,
						email: tokenPayload.email,
						name: tokenPayload.name,
						roles: tokenPayload.roles, // Adjust based on your token's payload
						mainRole: tokenPayload.mainRole,
						tenantId: tokenPayload.tenantId,
						clientId: tokenPayload.clientId
					};

					// console.log("backendAdminUser", backendAdminUser);

					await OnUserLogin("backendAdmin", backendAdminUser);
				} else {
					console.error("Invalid token payload");
					throw new Error("Invalid token payload");
				}
			} catch (error) {
				console.error("backend admin login failed", error);
				setIsAuthenticated(false);
				TokenManager.clearToken();
				throw error;
			}
		};

		const authenticateUser = async () => {
			if (isFronteggAuthenticated && fronteggUser) {
				// Handle Frontegg user
				TokenManager.setToken(fronteggUser.accessToken);
				const tokenPayload = TokenManager.getTokenPayload();
				if (tokenPayload) {
					const userInfo: User = {
						id: fronteggUser.id,
						frontEggId: fronteggUser.id,
						email: fronteggUser.email,
						name: fronteggUser.name,
						roles: fronteggUser.roles.map((role) => role.key),
						mainRole: fronteggUser.roles[0].key,
						tenantId: tokenPayload.tenantId,
						clientId: tokenPayload.clientId
						// ...any other properties from payload
					};

					await OnUserLogin("frontegg", userInfo);
				}
			} else {
				// localStorage.removeItem(TOKEN_STORAGE_KEY);
				// Check for LTI token in URL
				const queryParams = new URLSearchParams(location.search);
				const ltiToken = queryParams.get("ltik") || sessionStorage.getItem(TOKEN_STORAGE_KEY);
				const backendLoginToken = queryParams.get("backendLoginToken") || sessionStorage.getItem(BACKEND_LOGIN_TOKEN_STORAGE_KEY);
				// console.log("backendLoginToken", backendLoginToken);
				const assignmentId = queryParams.get("resource");
				if (ltiToken) {
					try {
						sessionStorage.setItem(TOKEN_STORAGE_KEY, ltiToken);

						await loginWithLTI(ltiToken, assignmentId);

						console.log("lti token found");
						// Remove the token from the URL after processing
						queryParams.delete("ltik");
						// queryParams.delete("resource");

						navigate({ search: queryParams.toString() });
					} catch (error) {
						console.error("Error logging in with LTI", error);
						setIsAuthenticated(false);
					}
				} else if (backendLoginToken) {
					try {
						// console.log("backendLoginToken", backendLoginToken);
						await backendAdminLogin(backendLoginToken);
						console.log("backend admin token found");

						queryParams.delete("backendLoginToken");
						navigate({ search: queryParams.toString() });
					} catch (error) {
						console.error("Error logging in with backend admin", error);
						setIsAuthenticated(false);
					}
					sessionStorage.setItem(BACKEND_LOGIN_TOKEN_STORAGE_KEY, backendLoginToken);
				} else {
					console.log("not authenticated");
					setIsAuthenticated(false);
				}
			}

			setIsLoading(false);
		};

		authenticateUser();
	}, [isFronteggAuthenticated, fronteggUser, location.search, navigate, posthog, backendUrl]);

	useEffect(() => {
		if (status === "connected" && userId) {
			console.log("status", status);

			emit("setUserId", { userId, userToken: TokenManager.getToken() }).catch((error) => {
				console.error("Error setting user ID:", error);
			});
		}
	}, [status, userId, emit]);

	const logout = () => {
		TokenManager.clearToken();
		sessionStorage.removeItem(TOKEN_STORAGE_KEY);
		sessionStorage.removeItem(BACKEND_LOGIN_TOKEN_STORAGE_KEY);
		setUser(null);
		setIsAuthenticated(false);
		// Add any other logout logic here
	};

	const login = (redirectUrl: string, searchParams: string) => {
		window.localStorage.setItem("FRONTEGG_AFTER_AUTH_REDIRECT_URL", `${location.pathname + location.search}`);
		console.log("instageUser", instageUser);

		loginWithRedirect(searchParams);

		console.log("on log in", redirectUrl, searchParams);
	};

	return (
		<AuthContext.Provider value={{ isAuthenticated, user, instageUser, userId, logout, login, ltiToken, backendLoginToken, isLoading }}>
			{children}
		</AuthContext.Provider>
	);
};

export const useAuth = () => {
	const context = useContext(AuthContext);
	if (!context) {
		throw new Error("useAuth must be used within an AuthProvider");
	}
	return context;
};
