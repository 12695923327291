import React from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../shadcn-ui/Dialog";
import { ResumeUpload } from "./Resumeupload";
import { useTranslation } from "../Utility/UseTranslation";
interface ResumeUploadPopupProps {
	isOpen: boolean;
	onClose: () => void;
	profileId: string;
	setProfile: (profile: any) => void;
	profile: any;
}

const ResumeUploadPopup: React.FC<ResumeUploadPopupProps> = ({ isOpen, onClose, profileId, setProfile, profile }) => {
	const { t } = useTranslation("profile");
	return (
		<Dialog open={isOpen} onOpenChange={onClose}>
			<DialogContent>
				<DialogHeader>
					<DialogTitle>{t("buttons.uploadResume")}</DialogTitle>
				</DialogHeader>
				<ResumeUpload profileId={profileId} setProfile={setProfile} profile={profile} onClose={onClose} />
			</DialogContent>
		</Dialog>
	);
};

export default ResumeUploadPopup;
