"use client";

import * as React from "react";
import { CalendarIcon } from "@radix-ui/react-icons";
import { format } from "date-fns";
import { DateRange } from "react-day-picker";

import { cn } from "../../lib/utils";
import { Button } from "./Button";
import { Calendar } from "./Calendar";
import { Popover, PopoverContent, PopoverTrigger } from "./Popover";

interface DatePickerWithRangeProps extends React.HTMLAttributes<HTMLDivElement> {
	dateRange: DateRange | undefined;
	onDateRangeChange: (dateRange: DateRange | undefined) => void;
}

export function DatePickerWithRange({ className, dateRange, onDateRangeChange }: DatePickerWithRangeProps) {
	const minDate = new Date(new Date().getFullYear(), 0, 1); // First day of current year
	const maxDate = new Date();

	return (
		<div className={cn("grid gap-2", className)}>
			<Popover>
				<PopoverTrigger asChild>
					<Button
						id="date"
						variant={"outline"}
						className={cn("w-[300px] justify-start text-left font-normal", !dateRange && "text-muted-foreground")}
					>
						<CalendarIcon className="mr-2 h-4 w-4" />
						{dateRange?.from ? (
							dateRange.to ? (
								<>
									{format(dateRange.from, "LLL dd, y")} - {format(dateRange.to, "LLL dd, y")}
								</>
							) : (
								format(dateRange.from, "LLL dd, y")
							)
						) : (
							<span>Pick a date range</span>
						)}
					</Button>
				</PopoverTrigger>
				<PopoverContent className="  p-4" align="start">
					<Calendar
						mode="range"
						defaultMonth={dateRange?.from}
						selected={dateRange}
						onSelect={onDateRangeChange}
						className="p-3"
						minDate={minDate}
						maxDate={maxDate}
					/>
				</PopoverContent>
			</Popover>
		</div>
	);
}
