export const TemplatesLocale = {
	en: {
		resumeTemplates: {
			CONTACT: "CONTACT",
			SUMMARY: "SUMMARY",
			EXPERIENCE: "EXPERIENCE",
			EDUCATION: "EDUCATION",
			SKILLS: "SKILLS",
			PROJECTS: "PROJECTS",
			CERTIFICATIONS: "CERTIFICATIONS",
			"EXTRA CURRICULAR": "EXTRA-CURRICULAR",
			graduatingYear: "Graduating Year",
			technical: "Technical",
			coreCompetencies: "Core Competencies",
			tools: "Tools",
			languages: "Languages",
			PROFILE: "PROFILE",
			TECHNICAL: "Technical",
			"WORK EXPERIENCE": "WORK EXPERIENCE"
		},
		coverLetterTemplates: {
			sincerely: "Sincerely",
			contactInformation: "Contact Information",
			companyInformation: "Company Information",
			salutation: "Dear",
			sincerelySignature: "Sincerely"
		}
	},
	fr: {
		resumeTemplates: {
			CONTACT: "CONTACT",
			SUMMARY: "RÉSUMÉ",
			EXPERIENCE: "EXPÉRIENCE",
			EDUCATION: "ÉDUCATION",
			SKILLS: "COMPÉTENCES",
			PROJECTS: "PROJETS",
			CERTIFICATIONS: "CERTIFICATIONS",
			"EXTRA CURRICULAR": "ACTIVITÉS EXTRA-SCOLAIRES",
			graduatingYear: "Année de diplôme",
			technical: "Technique",
			coreCompetencies: "Compétences clés",
			tools: "Outils",
			languages: "Langues",
			PROFILE: "PROFIL",
			TECHNICAL: "Technique",
			"WORK EXPERIENCE": "EXPÉRIENCE PROFESSIONNELLE"
		},
		coverLetterTemplates: {
			sincerely: "Cordialement",
			contactInformation: "Informations de contact",
			companyInformation: "Informations de l'entreprise",
			salutation: "Cher",
			sincerelySignature: "Cordialement"
		}
	}
};
