export const AssignmentReviewLocale = {
	en: {
		edit: "Edit",
		saveDraft: "Save Draft",
		copyToClipboard: "Copy to clipboard",
		reviewAssignment: {
			title: "Review Your Assignment",
			reviewSelfPractice: "Review Your Self-Practice",
			interview: "Interview",
			reflection: "Reflection",
			adminReview:
				"Review all the settings you've selected. If everything looks good, click 'Deploy Assignment' to finalize. You can always come back later to adjust settings or view reports from the dashboard.",
			studentReview: "Review all the settings you've selected. If everything looks good, click 'Create' to finalize."
		},
		dates: {
			title: "Dates",
			dueDate: "Due Date",
			availableFrom: "Available From",
			reflection: "Reflection",
			call: "Call",
			checkIn: "Check-In",
			session: "Session",
			questions: "Questions",
			interviewQuestions: "Interview Questions",
			interviewerAttitude: "Interviewer Attitude",
			framework: "Framework",
			frameworkTypes: {
				deny: "Denying a Request",
				comply: "Complying with a Request",
				gather: "Information Gathering"
			},
			studentRole: "Student Role",
			aiRole: "AI Role",
			aiProblem: "AI's Problem",
			aiRequest: "AI's Request",
			issueWithRequest: "Issue with Request",
			infoToGather: "Information to Gather",
			keyPoints: "Key Points",
			questionsCount: "{count} questions",
			stageTitles: {
				"Screening Interview": "Screening Interview",
				"Middle Interview": "Middle Interview",
				"Final Interview": "Final Interview",
				"Career Path Discovery": "Career Path Discovery",
				"Career Path Progression": "Career Path Progression"
			}
		},
		setup: {
			reflectionSetup: "Reflection Setup",
			interviewSetup: "Interview Setup",
			selectedCompetencies: "Selected Competencies",
			jobTitle: "Job Title",
			studentsWillSelect: "Students will select"
		},
		details: {
			title: "Details",
			assignmentTitle: "Assignment Title",
			description: "Description",
			courseName: "Course Name",
			courseCode: "Course Code",
			courseSection: "Course Section"
		},
		distribution: {
			title: "Distribution",
			addedEmails: "Added Emails",
			signupLink: "Sign-up Link",
			moreEmails: "... {count} more"
		},
		notifications: {
			title: "Student Notifications",
			enrollment: "Send Enrollment Notification",
			report: "Send Report",
			availability: "Send Availability Reminders",
			reminder24h: "Send 24 Hour Notice Reminders",
			dueDate: "Send Reminder On Due Date",
			yes: "Yes",
			no: "No"
		},
		summary: {
			title: "Aggregated Summary Options",
			includeStudentInfo: "Include Student Name and Email",
			includeTranscript: "Include Transcript",
			includeAudio: "Include Audio",
			reportFrequency: "Report Frequency",
			afterEachDueDate: "After each due date",
			afterFinalDueDate: "After final due date",
			noReports: "No email reports",
			includeCsv: "Include CSV",
			includePdf: "Include PDF",
			additionalRecipients: "Additional Report Recipients",
			dashboardNote: "Note: You can always log in to the dashboard to view assignment activity at any time.",
			yes: "Yes",
			no: "No"
		},
		grading: {
			title: "Grading",
			gradingType: "Grading Type",
			none: "None",
			passOrFail: "Pass or Fail",
			numeric: "Numeric",
			professionalism: "Professionalism",
			checklistItems: "Checklist Items"
		},
		deployment: {
			confirmTitle: "Confirm Assignment Deployment",
			importantNotes: "Important notes",
			emailNotification: "Students will receive an email about the upcoming assignment.",
			dueDatesNote: "Due dates and competencies cannot be changed after deployment.",
			addStudentsNote: "You can add students up until the first session due date.",
			cancel: "Cancel",
			confirmButton: "Confirm Deployment",
			deploying: "Deploying...",
			saveAsDraft: "Save as Draft",
			update: "Update",
			updateAssignment: "Update Assignment",
			createAssignment: "Create Assignment",
			create: "Create",
			deployAssignment: "Deploy Assignment",
			previous: "Previous"
		}
	},
	fr: {
		edit: "Modifier",
		saveDraft: "Enregistrer comme brouillon",
		copyToClipboard: "Copier dans le presse-papiers",
		reviewAssignment: {
			title: "Revue de votre affectation",
			reviewSelfPractice: "Revue de votre pratique",
			interview: "Entretien",
			reflection: "Réflexion",
			adminReview:
				"Revue de toutes les options que vous avez sélectionnées. Si tout semble correct, cliquez sur 'Déployer l'affectation' pour finaliser. Vous pouvez toujours revenir plus tard pour ajuster les paramètres ou afficher les rapports depuis le tableau de bord.",
			studentReview: "Revue de toutes les options que vous avez sélectionnées. Si tout semble correct, cliquez sur 'Créer' pour finaliser."
		},
		dates: {
			title: "Dates",
			dueDate: "Date d'échéance",
			availableFrom: "Disponible à partir de",
			reflection: "Réflexion",
			call: "Appel",
			checkIn: "Point de contrôle",
			session: "Session",
			questions: "Questions",
			interviewQuestions: "Questions d'entretien",
			interviewerAttitude: "Attitude de l'intervieweur",
			framework: "Cadre",
			frameworkTypes: {
				deny: "Refus d'une demande",
				comply: "Acceptation d'une demande",
				gather: "Collecte d'informations"
			},
			studentRole: "Rôle de l'étudiant",
			aiRole: "Rôle de l'IA",
			aiProblem: "Problème de l'IA",
			aiRequest: "Demande de l'IA",
			issueWithRequest: "Problème avec la demande",
			infoToGather: "Informations à recueillir",
			keyPoints: "Points clés",
			questionsCount: "{count} questions",
			stageTitles: {
				"Screening Interview": "Entretien de sélection",
				"Middle Interview": "Entretien intermédiaire",
				"Final Interview": "Entretien final",
				"Career Path Discovery": "Découverte de carrière",
				"Career Path Progression": "Progression de carrière"
			}
		},
		setup: {
			reflectionSetup: "Configuration de la réflexion",
			interviewSetup: "Configuration de l'entretien",
			selectedCompetencies: "Compétences sélectionnées",
			jobTitle: "Titre du poste",
			studentsWillSelect: "Les étudiants sélectionneront"
		},
		details: {
			title: "Détails",
			assignmentTitle: "Titre de l'affectation",
			description: "Description",
			courseName: "Nom du cours",
			courseCode: "Code du cours",
			courseSection: "Section du cours"
		},
		distribution: {
			title: "Distribution",
			addedEmails: "Courriels ajoutés",
			signupLink: "Lien d'inscription",
			moreEmails: "... {count} de plus"
		},
		notifications: {
			title: "Notifications aux étudiants",
			enrollment: "Envoyer une notification d'inscription",
			report: "Envoyer le rapport",
			availability: "Envoyer des rappels de disponibilité",
			reminder24h: "Envoyer des rappels 24 heures avant",
			dueDate: "Envoyer un rappel à la date d'échéance",
			yes: "Oui",
			no: "Non"
		},
		summary: {
			title: "Options de résumé agrégé",
			includeStudentInfo: "Inclure le nom et l'email de l'étudiant",
			includeTranscript: "Inclure la transcription",
			includeAudio: "Inclure l'audio",
			reportFrequency: "Fréquence des rapports",
			afterEachDueDate: "Après chaque date d'échéance",
			afterFinalDueDate: "Après la date d'échéance finale",
			noReports: "Pas de rapports par email",
			includeCsv: "Inclure CSV",
			includePdf: "Inclure PDF",
			additionalRecipients: "Destinataires supplémentaires du rapport",
			dashboardNote: "Note : Vous pouvez toujours vous connecter au tableau de bord pour voir l'activité des affectations à tout moment.",
			yes: "Oui",
			no: "Non"
		},
		grading: {
			title: "Notation",
			gradingType: "Type de notation",
			none: "Aucune",
			passOrFail: "Réussite ou échec",
			numeric: "Numérique",
			professionalism: "Professionnalisme",
			checklistItems: "Éléments de la liste de contrôle"
		},
		deployment: {
			confirmTitle: "Confirmer le déploiement de l'affectation",
			importantNotes: "Notes importantes",
			emailNotification: "Les étudiants recevront un courriel concernant l'affectation à venir.",
			dueDatesNote: "Les dates d'échéance et les compétences ne peuvent pas être modifiées après le déploiement.",
			addStudentsNote: "Vous pouvez ajouter des étudiants jusqu'à la première date d'échéance de la session.",
			cancel: "Annuler",
			confirmButton: "Confirmer le déploiement",
			deploying: "Déploiement en cours...",
			saveAsDraft: "Enregistrer comme brouillon",
			update: "Mettre à jour",
			updateAssignment: "Mettre à jour l'affectation",
			createAssignment: "Créer une affectation",
			create: "Créer",
			deployAssignment: "Déployer l'affectation",
			previous: "Précédent"
		}
	}
};
