import React, { useEffect, useState, useRef } from "react";
import { ProfileView, Experience, Education, ExtraCurricular, Project, Certification, Skills } from "../../types/ProfileView";
import { Input } from "../shadcn-ui/Input";
import { Textarea } from "../shadcn-ui/Textarea";
import { Button } from "../shadcn-ui/Button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../shadcn-ui/Select";
import { useTranslation } from "../Utility/UseTranslation";

interface ProfileEditFormProps {
	editingSection: string;
	editedData: any;
	setEditedData: (data: any) => void;
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
	resumeId: string;
	resume: ProfileView;
}

const RequiredLabel: React.FC<{ text: string }> = ({ text }) => (
	<label className="text-sm font-medium flex items-center gap-1">
		{text}
		<span className="text-red-500">*</span>
	</label>
);

const currentYear = new Date().getFullYear();
const years = Array.from({ length: currentYear - 1950 + 1 }, (_, i) => (currentYear - i + 10).toString());

const ProfileEditForm: React.FC<ProfileEditFormProps> = ({ editingSection, editedData, setEditedData, isOpen, setIsOpen, resumeId, resume }) => {
	const [activeTab, setActiveTab] = useState("edit");
	const [validationErrors, setValidationErrors] = useState<string[]>([]);
	const [showValidationDialog, setShowValidationDialog] = useState(false);
	const firstNameInputRef = useRef<HTMLInputElement>(null);
	const { t } = useTranslation("profile");
	const months = [
		t("months.January"),
		t("months.February"),
		t("months.March"),
		t("months.April"),
		t("months.May"),
		t("months.June"),
		t("months.July"),
		t("months.August"),
		t("months.September"),
		t("months.October"),
		t("months.November"),
		t("months.December")
	];

	useEffect(() => {
		if (isOpen && editingSection === "contact" && firstNameInputRef.current) {
			// Add a small delay to ensure the input is mounted and visible
			setTimeout(() => {
				firstNameInputRef.current?.focus();
			}, 0);
		}
	}, [isOpen, editingSection]);

	// Add validation patterns
	const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
	const PHONE_REGEX = /^\(?\s*([0-9]{3})\s*\)?[-.\s]?([0-9]{3})[-.\s]?([0-9]{4})$/;
	const URL_REGEX = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;

	const handleArrayChange = (index: number, value: string, field: string, parentField?: string) => {
		if (parentField) {
			setEditedData({
				...editedData,
				[parentField]: {
					...editedData[parentField],
					[field]: editedData[parentField]?.[field]?.map((item: string, i: number) => (i === index ? value : item)) || []
				}
			});
		} else {
			setEditedData({
				...editedData,
				[field]: editedData[field]?.map((item: string, i: number) => (i === index ? value : item)) || []
			});
		}
	};

	const handleObjectArrayChange = (index: number, field: string, value: any) => {
		setEditedData(editedData?.map((item: any, i: number) => (i === index ? { ...item, [field]: value } : item)) || []);
	};

	const addObjectArrayItem = () => {
		const newItem = { id: Date.now().toString() }; // Temporary ID for new items
		setEditedData([...(editedData || []), newItem]);
	};

	const removeObjectArrayItem = (index: number) => {
		// Create a new array without the removed item
		const updatedData = editedData?.filter((_: any, i: number) => i !== index) || [];
		// Update the edited data immediately
		setEditedData(updatedData);
	};

	const addArrayItem = (field: string, parentField?: string) => {
		if (parentField) {
			setEditedData({
				...editedData,
				[parentField]: {
					...editedData[parentField],
					[field]: [...(editedData[parentField]?.[field] || []), ""]
				}
			});
		} else {
			setEditedData({
				...editedData,
				[field]: [...(editedData[field] || []), ""]
			});
		}
	};

	const removeArrayItem = (index: number, field: string, parentField?: string) => {
		if (parentField) {
			setEditedData({
				...editedData,
				[parentField]: {
					...editedData[parentField],
					[field]: editedData[parentField]?.[field]?.filter((_: string, i: number) => i !== index) || []
				}
			});
		} else {
			setEditedData({
				...editedData,
				[field]: editedData[field]?.filter((_: string, i: number) => i !== index) || []
			});
		}
	};

	const renderArrayInput = (field: string, label: string, parentField?: string) => {
		let items: string[] = [];
		let placeholder = "";

		// Set appropriate placeholder based on the field
		switch (field) {
			case "technical":
				placeholder = t("editForm.skills.technical.placeholder");
				break;
			case "coreCompetencies":
				placeholder = t("editForm.skills.coreCompetencies.placeholder");
				break;
			case "languages":
				placeholder = t("editForm.skills.languages.placeholder");
				break;
			case "tools":
				placeholder = t("editForm.skills.tools.placeholder");
				break;
			case "frameworks":
				placeholder = t("editForm.skills.frameworks.placeholder");
				break;
			case "interests":
				placeholder = "Sales Psychology";
				break;
			case "hobbies":
				placeholder = "Public Speaking";
				break;
			case "tasks":
				placeholder = "Mentored 10+ junior sales representatives, improving their quarterly performance by 25%";
				break;
			default:
				placeholder = `Enter ${label}`;
		}

		if (parentField) {
			// For skills section, editedData is already the parent object
			if (editingSection === "skills") {
				items = editedData?.[field] || [];
				if (!editedData?.[field]) {
					setEditedData({
						...editedData,
						[field]: []
					});
				}
			} else {
				// Initialize the parent field if it doesn't exist
				if (!editedData?.[parentField]) {
					setEditedData({
						...editedData,
						[parentField]: {}
					});
				}
				// Initialize the array if it doesn't exist
				items = editedData?.[parentField]?.[field] || [];
				if (!editedData?.[parentField]?.[field]) {
					setEditedData({
						...editedData,
						[parentField]: {
							...editedData[parentField],
							[field]: []
						}
					});
				}
			}
		} else {
			// For hobbies and interests, initialize as array if needed
			if (editingSection === "hobbies" || editingSection === "interests") {
				items = editedData || [];
				if (!editedData) {
					setEditedData([]);
				}
			} else {
				// Initialize the array if it doesn't exist
				items = editedData?.[field] || [];
				if (!editedData?.[field]) {
					setEditedData({
						...editedData,
						[field]: []
					});
				}
			}
		}

		return (
			<div className="space-y-4">
				<div className="block">
					<div className="mb-2">
						{editingSection === "interests" || editingSection === "hobbies" ? (
							<label className="text-sm font-medium">{label}</label>
						) : (
							<RequiredLabel text={label} />
						)}
					</div>
					<div className="space-y-2">
						{items.map((item: string, index: number) => (
							<div key={index} className="flex gap-2">
								<Input
									value={item}
									onChange={(e) => {
										if (editingSection === "skills") {
											const newItems = [...items];
											newItems[index] = e.target.value;
											setEditedData({
												...editedData,
												[field]: newItems
											});
										} else if (editingSection === "hobbies" || editingSection === "interests") {
											const newItems = [...items];
											newItems[index] = e.target.value;
											setEditedData(newItems);
										} else {
											handleArrayChange(index, e.target.value, field, parentField);
										}
									}}
									className="flex-1"
									placeholder={placeholder}
									required
									aria-label={`${label} Item ${index + 1}`}
								/>
								<Button
									variant="outline"
									size="sm"
									onClick={() => {
										if (editingSection === "skills") {
											const newItems = items.filter((_, i) => i !== index);
											setEditedData({
												...editedData,
												[field]: newItems
											});
										} else if (editingSection === "hobbies" || editingSection === "interests") {
											const newItems = items.filter((_, i) => i !== index);
											setEditedData(newItems);
										} else {
											removeArrayItem(index, field, parentField);
										}
									}}
									aria-label={`Remove ${label} Item ${index + 1}`}
								>
									{t("editForm.remove")}
								</Button>
							</div>
						))}
					</div>
					<div className="mt-2">
						<Button
							variant="outline"
							size="sm"
							onClick={() => {
								if (editingSection === "skills") {
									setEditedData({
										...editedData,
										[field]: [...items, ""]
									});
								} else if (editingSection === "hobbies" || editingSection === "interests") {
									setEditedData([...items, ""]);
								} else {
									addArrayItem(field, parentField);
								}
							}}
							aria-label={`Add New ${label}`}
						>
							{t("editForm.add")} {label}
						</Button>
					</div>
				</div>
			</div>
		);
	};

	const validateRequiredFields = () => {
		const errors: string[] = [];

		if (editingSection === "contact" && typeof editedData === "object") {
			const { firstName, lastName, email, phone, linkedinURL, website } = editedData;
			if (!firstName) errors.push("First Name is required");
			if (!lastName) errors.push("Last Name is required");
			if (!email) {
				errors.push("Email is required");
			} else if (!EMAIL_REGEX.test(email)) {
				errors.push("Email format is invalid");
			}
			if (!phone) {
				errors.push("Phone is required");
			} else if (!PHONE_REGEX.test(phone)) {
				errors.push("Phone format is invalid (e.g., (123) 456-7890)");
			}
			if (linkedinURL && !URL_REGEX.test(linkedinURL)) {
				errors.push("LinkedIn URL format is invalid");
			}
			if (website && !URL_REGEX.test(website)) {
				errors.push("Website URL format is invalid");
			}
		}

		if (editingSection === "projects" && Array.isArray(editedData)) {
			editedData.forEach((proj, index) => {
				if (!proj.projectName) errors.push(`Project ${index + 1}: Project Name is required`);
				if (!proj.description) errors.push(`Project ${index + 1}: Description is required`);
				if (!proj.startMonth) errors.push(`Project ${index + 1}: Start Month is required`);
				if (!proj.startYear) errors.push(`Project ${index + 1}: Start Year is required`);
				if (!proj.endMonth) errors.push(`Project ${index + 1}: End Month is required`);
				if (proj.endMonth !== "Present" && !proj.endYear) errors.push(`Project ${index + 1}: End Year is required`);
				if (proj.projectURL && !URL_REGEX.test(proj.projectURL)) {
					errors.push(`Project ${index + 1}: Project URL format is invalid`);
				}
				// Notes are no longer required
			});
		}

		if (editingSection === "extraCurricular" && Array.isArray(editedData)) {
			editedData.forEach((activity, index) => {
				if (!activity.activity) errors.push(`Activity ${index + 1}: Activity is required`);
				if (!activity.orgName) errors.push(`Activity ${index + 1}: Organization Name is required`);
				if (!activity.startMonth) errors.push(`Activity ${index + 1}: Start Month is required`);
				if (!activity.startYear) errors.push(`Activity ${index + 1}: Start Year is required`);
				if (!activity.endMonth) errors.push(`Activity ${index + 1}: End Month is required`);
				if (activity.endMonth !== "Present" && !activity.endYear) errors.push(`Activity ${index + 1}: End Year is required`);
				// Tasks are no longer required
			});
		}

		if (editingSection === "certifications" && Array.isArray(editedData)) {
			editedData.forEach((cert, index) => {
				if (!cert.certificationName) errors.push(`Certification ${index + 1}: Name is required`);
				if (!cert.issuingOrganization) errors.push(`Certification ${index + 1}: Issuing Organization is required`);
				if (!cert.issueMonth) errors.push(`Certification ${index + 1}: Issue Month is required`);
				if (!cert.issueYear) errors.push(`Certification ${index + 1}: Issue Year is required`);
				// Notes are no longer required
			});
		}

		setValidationErrors(errors);
		setShowValidationDialog(errors.length > 0);
		return errors.length === 0;
	};

	switch (editingSection) {
		case "summary":
			return (
				<div className="space-y-4">
					<h2 className="text-xl font-semibold mb-4">{t("editForm.summary.title")}</h2>
					<div>
						<RequiredLabel text={t("editForm.summary.label")} />
						<Textarea
							value={editedData || ""}
							onChange={(e) => setEditedData(e.target.value)}
							placeholder={t("editForm.summary.placeholder")}
							className="mt-1 p-2"
							rows={10}
							required
							aria-label={t("editForm.summary.label")}
						/>
					</div>
				</div>
			);

		case "contact":
			// Ensure editedData is initialized as an object with default values
			if (!editedData || typeof editedData !== "object") {
				setEditedData({
					firstName: "",
					lastName: "",
					email: "",
					phone: "",
					address: "",
					linkedinURL: "",
					website: ""
				});
				return null; // Return null for initial render until state is updated
			}

			return (
				<div className="space-y-4">
					<h2 className="text-xl font-semibold mb-4">{t("editForm.contact.title")}</h2>
					<div className="grid grid-cols-2 gap-4">
						<div>
							<RequiredLabel text={t("editForm.contact.firstName")} />
							<Input
								tabIndex={0}
								ref={firstNameInputRef}
								value={editedData?.firstName || ""}
								onChange={(e) => setEditedData({ ...editedData, firstName: e.target.value })}
								placeholder={t("editForm.contact.placeholders.firstName")}
								className="mt-1 focus:ring-0 focus:ring-offset-0"
								required
								aria-label={t("editForm.contact.firstName")}
							/>
						</div>
						<div>
							<RequiredLabel text={t("editForm.contact.lastName")} />
							<Input
								value={editedData?.lastName || ""}
								onChange={(e) => setEditedData({ ...editedData, lastName: e.target.value })}
								placeholder={t("editForm.contact.placeholders.lastName")}
								className="mt-1"
								required
								aria-label={t("editForm.contact.lastName")}
							/>
						</div>
					</div>
					<div>
						<RequiredLabel text={t("editForm.contact.email")} />
						<Input
							value={editedData?.email || ""}
							onChange={(e) => setEditedData({ ...editedData, email: e.target.value })}
							placeholder={t("editForm.contact.placeholders.email")}
							type="email"
							className="mt-1"
							required
							aria-label={t("editForm.contact.email")}
							aria-invalid={editedData?.email && !EMAIL_REGEX.test(editedData.email)}
							aria-errormessage={editedData?.email && !EMAIL_REGEX.test(editedData.email) ? "email-error" : undefined}
						/>
						{editedData?.email && !EMAIL_REGEX.test(editedData.email) && (
							<p className="text-red-500 text-sm mt-1" id="email-error">
								{t("editForm.contact.validation.invalidEmail")}
							</p>
						)}
					</div>
					<div>
						<RequiredLabel text={t("editForm.contact.phone")} />
						<Input
							value={editedData?.phone || ""}
							onChange={(e) => setEditedData({ ...editedData, phone: e.target.value })}
							placeholder={t("editForm.contact.placeholders.phone")}
							className="mt-1"
							required
							aria-label={t("editForm.contact.phone")}
							aria-invalid={editedData?.phone && !PHONE_REGEX.test(editedData.phone)}
							aria-errormessage={editedData?.phone && !PHONE_REGEX.test(editedData.phone) ? "phone-error" : undefined}
						/>
						{editedData?.phone && !PHONE_REGEX.test(editedData.phone) && (
							<p className="text-red-500 text-sm mt-1" id="phone-error">
								{t("editForm.contact.validation.invalidPhone")}
							</p>
						)}
					</div>
					<div>
						<label className="text-sm font-medium">{t("editForm.contact.address")}</label>
						<Input
							value={editedData?.address || ""}
							onChange={(e) => setEditedData({ ...editedData, address: e.target.value })}
							placeholder={t("editForm.contact.placeholders.address")}
							className="mt-1"
							aria-label={t("editForm.contact.address")}
						/>
					</div>
					<div>
						<label className="text-sm font-medium">{t("editForm.contact.linkedin")}</label>
						<Input
							value={editedData?.linkedinURL || ""}
							onChange={(e) => setEditedData({ ...editedData, linkedinURL: e.target.value })}
							placeholder={t("editForm.contact.placeholders.linkedin")}
							className="mt-1"
							aria-label={t("editForm.contact.linkedin")}
							aria-invalid={editedData?.linkedinURL && !URL_REGEX.test(editedData.linkedinURL)}
							aria-errormessage={editedData?.linkedinURL && !URL_REGEX.test(editedData.linkedinURL) ? "linkedin-error" : undefined}
						/>
						{editedData?.linkedinURL && !URL_REGEX.test(editedData.linkedinURL) && (
							<p className="text-red-500 text-sm mt-1" id="linkedin-error">
								{t("editForm.contact.validation.invalidUrl")}
							</p>
						)}
					</div>
					<div>
						<label className="text-sm font-medium">{t("editForm.contact.website")}</label>
						<Input
							value={editedData?.website || ""}
							onChange={(e) => setEditedData({ ...editedData, website: e.target.value })}
							placeholder={t("editForm.contact.placeholders.website")}
							className="mt-1"
							aria-label={t("editForm.contact.website")}
							aria-invalid={editedData?.website && !URL_REGEX.test(editedData.website)}
							aria-errormessage={editedData?.website && !URL_REGEX.test(editedData.website) ? "website-error" : undefined}
						/>
						{editedData?.website && !URL_REGEX.test(editedData.website) && (
							<p className="text-red-500 text-sm mt-1" id="website-error">
								{t("editForm.contact.validation.invalidUrl")}
							</p>
						)}
					</div>
				</div>
			);

		case "experience":
			return (
				<div className="space-y-8">
					{(editedData || []).map((exp: Experience, index: number) => (
						<div key={exp.id || index} className="space-y-4 p-4 border rounded-lg">
							<div className="flex justify-between items-center">
								<h3 className="text-lg font-medium">
									{t("editForm.experience.experienceNumber")} {index + 1}
								</h3>
								<Button
									variant="outline"
									size="sm"
									onClick={() => removeObjectArrayItem(index)}
									aria-label={`${t("editForm.experience.removeExperience")} ${index + 1}`}
								>
									{t("editForm.experience.removeExperience")}
								</Button>
							</div>
							<div>
								<RequiredLabel text={t("editForm.experience.jobTitle")} />
								<Input
									value={exp.jobTitle || ""}
									onChange={(e) => handleObjectArrayChange(index, "jobTitle", e.target.value)}
									placeholder={t("editForm.experience.jobTitlePlaceholder")}
									className="mt-1"
									required
									aria-label={`${t("editForm.experience.jobTitle")} ${t("editForm.experience.experienceNumber")} ${index + 1}`}
								/>
							</div>
							<div>
								<RequiredLabel text={t("editForm.experience.companyName")} />
								<Input
									value={exp.companyName || ""}
									onChange={(e) => handleObjectArrayChange(index, "companyName", e.target.value)}
									placeholder={t("editForm.experience.companyNamePlaceholder")}
									className="mt-1"
									required
									aria-label={`${t("editForm.experience.companyName")} ${t("editForm.experience.experienceNumber")} ${index + 1}`}
								/>
							</div>
							<div className="grid grid-cols-2 gap-4">
								<div>
									<RequiredLabel text={t("editForm.experience.city")} />
									<Input
										value={exp.city || ""}
										onChange={(e) => handleObjectArrayChange(index, "city", e.target.value)}
										placeholder={t("editForm.experience.cityPlaceholder")}
										className="mt-1"
										required
										aria-label={`${t("editForm.experience.city")} ${t("editForm.experience.experienceNumber")} ${index + 1}`}
									/>
								</div>
								<div>
									<RequiredLabel text={t("editForm.experience.stateProvince")} />
									<Input
										value={exp.stateOrProv || ""}
										onChange={(e) => handleObjectArrayChange(index, "stateOrProv", e.target.value)}
										placeholder={t("editForm.experience.stateProvincePlaceholder")}
										className="mt-1"
										required
										aria-label={`${t("editForm.experience.stateProvince")} ${t("editForm.experience.experienceNumber")} ${
											index + 1
										}`}
									/>
								</div>
							</div>
							<div className="grid grid-cols-2 gap-4">
								<div>
									<RequiredLabel text={t("editForm.experience.startMonth")} />
									<Select
										value={exp.startMonth || ""}
										onValueChange={(value) => handleObjectArrayChange(index, "startMonth", value)}
										required
										aria-label={`${t("editForm.experience.startMonth")} ${t("editForm.experience.experienceNumber")} ${
											index + 1
										}`}
									>
										<SelectTrigger className="w-[180px] bg-white" tabIndex={0}>
											<SelectValue placeholder={t("editForm.experience.selectMonth")} />
										</SelectTrigger>
										<SelectContent>
											<SelectItem value="Present">{t("editForm.experience.present")}</SelectItem>
											{months.map((month) => (
												<SelectItem key={month} value={month} tabIndex={-1}>
													{month}
												</SelectItem>
											))}
										</SelectContent>
									</Select>
								</div>
								<div>
									<RequiredLabel text={t("editForm.experience.startYear")} />
									<Select
										value={exp.startYear || ""}
										onValueChange={(value) => handleObjectArrayChange(index, "startYear", value)}
										required
										aria-label={`${t("editForm.experience.startYear")} ${t("editForm.experience.experienceNumber")} ${index + 1}`}
									>
										<SelectTrigger className="w-[180px] bg-white" tabIndex={0}>
											<SelectValue placeholder={t("editForm.experience.selectYear")} />
										</SelectTrigger>
										<SelectContent>
											{years.map((year) => (
												<SelectItem key={year} value={year} tabIndex={-1}>
													{year}
												</SelectItem>
											))}
										</SelectContent>
									</Select>
								</div>
							</div>
							<div className="grid grid-cols-2 gap-4">
								<div>
									<RequiredLabel text={t("editForm.experience.endMonth")} />
									<Select
										value={exp.endMonth || ""}
										onValueChange={(value) => {
											if (value === "Present") {
												handleObjectArrayChange(index, "endYear", "");
											}
											handleObjectArrayChange(index, "endMonth", value);
										}}
										required
										aria-label={`${t("editForm.experience.endMonth")} ${t("editForm.experience.experienceNumber")} ${index + 1}`}
									>
										<SelectTrigger className="w-[180px] bg-white" tabIndex={0}>
											<SelectValue placeholder={t("editForm.experience.selectMonth")} />
										</SelectTrigger>
										<SelectContent>
											<SelectItem value="Present">{t("editForm.experience.present")}</SelectItem>
											{months.map((month) => (
												<SelectItem key={month} value={month} tabIndex={-1}>
													{month}
												</SelectItem>
											))}
										</SelectContent>
									</Select>
								</div>
								{exp.endMonth && exp.endMonth !== "Present" && (
									<div>
										<RequiredLabel text={t("editForm.experience.endYear")} />
										<Select
											value={exp.endYear || ""}
											onValueChange={(value) => handleObjectArrayChange(index, "endYear", value)}
											required
											aria-label={`${t("editForm.experience.endYear")} ${t("editForm.experience.experienceNumber")} ${
												index + 1
											}`}
										>
											<SelectTrigger className="w-[180px] bg-white" tabIndex={0}>
												<SelectValue placeholder={t("editForm.experience.selectYear")} />
											</SelectTrigger>
											<SelectContent>
												{years.map((year) => (
													<SelectItem key={year} value={year} tabIndex={-1}>
														{year}
													</SelectItem>
												))}
											</SelectContent>
										</Select>
									</div>
								)}
							</div>
							<div className="space-y-2">
								<RequiredLabel text={t("editForm.experience.accomplishments")} />
								{(exp.roles || []).map((role: string, roleIndex: number) => (
									<div key={roleIndex} className="flex gap-2">
										<Input
											value={role}
											onChange={(e) => {
												const newRoles = [...(exp.roles || [])];
												newRoles[roleIndex] = e.target.value;
												handleObjectArrayChange(index, "roles", newRoles);
											}}
											className="flex-1"
											placeholder={t("editForm.experience.accomplishmentPlaceholder")}
											required
											aria-label={`${t("editForm.experience.accomplishments")} ${roleIndex + 1} ${t(
												"editForm.experience.experienceNumber"
											)} ${index + 1}`}
										/>
										<Button
											variant="outline"
											size="sm"
											onClick={() => {
												const newRoles = exp.roles.filter((_, i) => i !== roleIndex);
												handleObjectArrayChange(index, "roles", newRoles);
											}}
											aria-label={`${t("editForm.experience.removeAccomplishment")} ${roleIndex + 1} ${t(
												"editForm.experience.experienceNumber"
											)} ${index + 1}`}
										>
											{t("editForm.experience.removeAccomplishment")}
										</Button>
									</div>
								))}
								<Button
									className="ml-2"
									variant="outline"
									size="sm"
									onClick={() => {
										const newRoles = [...(exp.roles || []), ""];
										handleObjectArrayChange(index, "roles", newRoles);
									}}
									aria-label={`${t("editForm.experience.addAccomplishment")} ${t("editForm.experience.experienceNumber")} ${
										index + 1
									}`}
								>
									{t("editForm.experience.addAccomplishment")}
								</Button>
							</div>
						</div>
					))}
					<Button variant="outline" onClick={addObjectArrayItem} aria-label={t("editForm.experience.addExperience")}>
						{t("editForm.experience.addExperience")}
					</Button>
				</div>
			);

		case "education":
			return (
				<div className="space-y-8">
					{(editedData || []).map((edu: Education, index: number) => (
						<div key={edu.id || index} className="space-y-4 p-4 border rounded-lg">
							<div className="flex justify-between items-center">
								<h3 className="text-lg font-medium">
									{t("editForm.education.educationNumber")} {index + 1}
								</h3>
								<Button
									variant="outline"
									size="sm"
									onClick={() => removeObjectArrayItem(index)}
									aria-label={`${t("editForm.education.removeEducation")} ${index + 1}`}
								>
									{t("editForm.education.removeEducation")}
								</Button>
							</div>
							<div>
								<RequiredLabel text={t("editForm.education.school")} />
								<Input
									value={edu.school || ""}
									onChange={(e) => handleObjectArrayChange(index, "school", e.target.value)}
									placeholder={t("editForm.education.schoolPlaceholder")}
									className="mt-1"
									required
									aria-label={`${t("editForm.education.school")} ${t("editForm.education.educationNumber")} ${index + 1}`}
								/>
							</div>
							<div>
								<RequiredLabel text={t("editForm.education.degree")} />
								<Input
									value={edu.degree || ""}
									onChange={(e) => handleObjectArrayChange(index, "degree", e.target.value)}
									placeholder={t("editForm.education.degreePlaceholder")}
									className="mt-1"
									required
									aria-label={`${t("editForm.education.degree")} ${t("editForm.education.educationNumber")} ${index + 1}`}
								/>
							</div>
							<div>
								<RequiredLabel text={t("editForm.education.program")} />
								<Input
									value={edu.program || ""}
									onChange={(e) => handleObjectArrayChange(index, "program", e.target.value)}
									placeholder={t("editForm.education.programPlaceholder")}
									className="mt-1"
									required
									aria-label={`${t("editForm.education.program")} ${t("editForm.education.educationNumber")} ${index + 1}`}
								/>
							</div>
							<div>
								<RequiredLabel text={t("editForm.education.graduatingYear")} />
								<Select
									value={edu.graduatingYear || ""}
									onValueChange={(value) => handleObjectArrayChange(index, "graduatingYear", value)}
									required
									aria-label={`${t("editForm.education.graduatingYear")} ${t("editForm.education.educationNumber")} ${index + 1}`}
								>
									<SelectTrigger className="w-[180px] bg-white" tabIndex={0}>
										<SelectValue placeholder={t("editForm.education.selectYear")} />
									</SelectTrigger>
									<SelectContent>
										{years.map((year) => (
											<SelectItem key={year} value={year} tabIndex={-1}>
												{year}
											</SelectItem>
										))}
									</SelectContent>
								</Select>
							</div>
							<div className="space-y-2">
								<RequiredLabel text={t("editForm.education.notes")} />
								{(edu.notes || []).map((note: string, noteIndex: number) => (
									<div key={noteIndex} className="flex gap-2">
										<Input
											value={note}
											onChange={(e) => {
												const newNotes = [...(edu.notes || [])];
												newNotes[noteIndex] = e.target.value;
												handleObjectArrayChange(index, "notes", newNotes);
											}}
											className="flex-1"
											required
											aria-label={`${t("editForm.education.notes")} ${noteIndex + 1} ${t(
												"editForm.education.educationNumber"
											)} ${index + 1}`}
										/>
										<Button
											variant="outline"
											size="sm"
											onClick={() => {
												const newNotes = edu.notes.filter((_, i) => i !== noteIndex);
												handleObjectArrayChange(index, "notes", newNotes);
											}}
											aria-label={`${t("editForm.education.removeNote")} ${noteIndex + 1} ${t(
												"editForm.education.educationNumber"
											)} ${index + 1}`}
										>
											{t("editForm.education.removeNote")}
										</Button>
									</div>
								))}
								<Button
									className="ml-2"
									variant="outline"
									size="sm"
									onClick={() => {
										const newNotes = [...(edu.notes || []), ""];
										handleObjectArrayChange(index, "notes", newNotes);
									}}
									aria-label={`${t("editForm.education.addNote")} ${t("editForm.education.educationNumber")} ${index + 1}`}
								>
									{t("editForm.education.addNote")}
								</Button>
							</div>
						</div>
					))}
					<Button variant="outline" onClick={addObjectArrayItem} aria-label={t("editForm.education.addEducation")}>
						{t("editForm.education.addEducation")}
					</Button>
				</div>
			);

		case "projects":
			return (
				<div className="space-y-8">
					{/* <h2 className="text-xl font-semibold mb-4">{t("editForm.projects.title")}</h2> */}
					{(editedData || []).map((project: Project, index: number) => (
						<div key={project.id || index} className="space-y-4 p-4 border rounded-lg">
							<div className="flex justify-between items-center">
								<h3 className="text-lg font-medium">
									{t("editForm.projects.projectNumber")} {index + 1}
								</h3>
								<Button
									variant="outline"
									size="sm"
									onClick={() => removeObjectArrayItem(index)}
									aria-label={`${t("editForm.projects.removeProject")} ${index + 1}`}
								>
									{t("editForm.projects.removeProject")}
								</Button>
							</div>
							<div>
								<RequiredLabel text={t("editForm.projects.projectName")} />
								<Input
									value={project.projectName || ""}
									onChange={(e) => handleObjectArrayChange(index, "projectName", e.target.value)}
									placeholder={t("editForm.projects.projectNamePlaceholder")}
									className="mt-1"
									required
									aria-label={`${t("editForm.projects.projectName")} ${t("editForm.projects.projectNumber")} ${index + 1}`}
								/>
							</div>
							<div>
								<RequiredLabel text={t("editForm.projects.description")} />
								<Textarea
									value={project.description || ""}
									onChange={(e) => handleObjectArrayChange(index, "description", e.target.value)}
									placeholder={t("editForm.projects.descriptionPlaceholder")}
									className="mt-1"
									rows={4}
									required
									aria-label={`${t("editForm.projects.description")} ${t("editForm.projects.projectNumber")} ${index + 1}`}
								/>
							</div>
							<div className="grid grid-cols-2 gap-4">
								<div>
									<RequiredLabel text={t("editForm.projects.startMonth")} />
									<Select
										value={project.startMonth || ""}
										onValueChange={(value) => handleObjectArrayChange(index, "startMonth", value)}
										required
										aria-label={`${t("editForm.projects.startMonth")} ${t("editForm.projects.projectNumber")} ${index + 1}`}
									>
										<SelectTrigger className="w-[180px] bg-white" tabIndex={0}>
											<SelectValue placeholder={t("editForm.projects.selectMonth")} />
										</SelectTrigger>
										<SelectContent>
											<SelectItem value="Present">{t("editForm.projects.present")}</SelectItem>
											{months.map((month) => (
												<SelectItem key={month} value={month} tabIndex={-1}>
													{month}
												</SelectItem>
											))}
										</SelectContent>
									</Select>
								</div>
								<div>
									<RequiredLabel text={t("editForm.projects.startYear")} />
									<Select
										value={project.startYear || ""}
										onValueChange={(value) => handleObjectArrayChange(index, "startYear", value)}
										required
										aria-label={`${t("editForm.projects.startYear")} ${t("editForm.projects.projectNumber")} ${index + 1}`}
									>
										<SelectTrigger className="w-[180px] bg-white" tabIndex={0}>
											<SelectValue placeholder={t("editForm.projects.selectYear")} />
										</SelectTrigger>
										<SelectContent>
											{years.map((year) => (
												<SelectItem key={year} value={year} tabIndex={-1}>
													{year}
												</SelectItem>
											))}
										</SelectContent>
									</Select>
								</div>
							</div>
							<div className="grid grid-cols-2 gap-4">
								<div>
									<RequiredLabel text={t("editForm.projects.endMonth")} />
									<Select
										value={project.endMonth || ""}
										onValueChange={(value) => {
											if (value === "Present") {
												handleObjectArrayChange(index, "endYear", "");
											}
											handleObjectArrayChange(index, "endMonth", value);
										}}
										required
										aria-label={`${t("editForm.projects.endMonth")} ${t("editForm.projects.projectNumber")} ${index + 1}`}
									>
										<SelectTrigger className="w-[180px] bg-white" tabIndex={0}>
											<SelectValue placeholder={t("editForm.projects.selectMonth")} />
										</SelectTrigger>
										<SelectContent>
											<SelectItem value="Present">{t("editForm.projects.present")}</SelectItem>
											{months.map((month) => (
												<SelectItem key={month} value={month} tabIndex={-1}>
													{month}
												</SelectItem>
											))}
										</SelectContent>
									</Select>
								</div>
								{project.endMonth && project.endMonth !== "Present" && (
									<div>
										<RequiredLabel text={t("editForm.projects.endYear")} />
										<Select
											value={project.endYear || ""}
											onValueChange={(value) => handleObjectArrayChange(index, "endYear", value)}
											required
											aria-label={`${t("editForm.projects.endYear")} ${t("editForm.projects.projectNumber")} ${index + 1}`}
										>
											<SelectTrigger className="w-[180px] bg-white" tabIndex={0}>
												<SelectValue placeholder={t("editForm.projects.selectYear")} />
											</SelectTrigger>
											<SelectContent>
												{years.map((year) => (
													<SelectItem key={year} value={year} tabIndex={-1}>
														{year}
													</SelectItem>
												))}
											</SelectContent>
										</Select>
									</div>
								)}
							</div>
							<div>
								<label className="text-sm font-medium">{t("editForm.projects.projectURL")}</label>
								<Input
									value={project.projectURL || ""}
									onChange={(e) => handleObjectArrayChange(index, "projectURL", e.target.value)}
									placeholder={t("editForm.projects.projectURLPlaceholder")}
									className="mt-1"
									aria-label={`${t("editForm.projects.projectURL")} ${t("editForm.projects.projectNumber")} ${index + 1}`}
								/>
							</div>
							<div className="space-y-2">
								<RequiredLabel text={t("editForm.projects.notes")} />
								{(project.notes || []).map((note: string, noteIndex: number) => (
									<div key={noteIndex} className="flex gap-2">
										<Input
											value={note}
											onChange={(e) => {
												const newNotes = [...(project.notes || [])];
												newNotes[noteIndex] = e.target.value;
												handleObjectArrayChange(index, "notes", newNotes);
											}}
											className="flex-1"
											aria-label={`${t("editForm.projects.notes")} ${noteIndex + 1} ${t("editForm.projects.projectNumber")} ${
												index + 1
											}`}
											required
										/>
										<Button
											variant="outline"
											size="sm"
											onClick={() => {
												const newNotes = project.notes.filter((_, i) => i !== noteIndex);
												handleObjectArrayChange(index, "notes", newNotes);
											}}
											aria-label={`${t("editForm.projects.removeNote")} ${noteIndex + 1} ${t(
												"editForm.projects.projectNumber"
											)} ${index + 1}`}
										>
											{t("editForm.projects.removeNote")}
										</Button>
									</div>
								))}
								<Button
									className="ml-2"
									variant="outline"
									size="sm"
									onClick={() => {
										const newNotes = [...(project.notes || []), ""];
										handleObjectArrayChange(index, "notes", newNotes);
									}}
									aria-label={`${t("editForm.projects.addNote")} ${t("editForm.projects.projectNumber")} ${index + 1}`}
								>
									{t("editForm.projects.addNote")}
								</Button>
							</div>
						</div>
					))}
					<Button variant="outline" onClick={addObjectArrayItem} aria-label={t("editForm.projects.addProject")}>
						{t("editForm.projects.addProject")}
					</Button>
				</div>
			);

		case "certifications":
			return (
				<div className="space-y-8">
					{/* <h2 className="text-xl font-semibold mb-4">{t("editForm.certifications.title")}</h2> */}
					{(editedData || []).map((cert: Certification, index: number) => (
						<div key={cert.id || index} className="space-y-4 p-4 border rounded-lg">
							<div className="flex justify-between items-center">
								<h3 className="text-lg font-medium">
									{t("editForm.certifications.certificationNumber")} {index + 1}
								</h3>
								<Button
									variant="outline"
									size="sm"
									onClick={() => removeObjectArrayItem(index)}
									aria-label={`${t("editForm.certifications.removeCertification")} ${index + 1}`}
								>
									{t("editForm.certifications.removeCertification")}
								</Button>
							</div>
							<div>
								<RequiredLabel text={t("editForm.certifications.certificationName")} />
								<Input
									value={cert.certificationName || ""}
									onChange={(e) => handleObjectArrayChange(index, "certificationName", e.target.value)}
									placeholder={t("editForm.certifications.certificationNamePlaceholder")}
									className="mt-1"
									required
									aria-label={`${t("editForm.certifications.certificationName")} ${t(
										"editForm.certifications.certificationNumber"
									)} ${index + 1}`}
								/>
							</div>
							<div>
								<RequiredLabel text={t("editForm.certifications.issuingOrganization")} />
								<Input
									value={cert.issuingOrganization || ""}
									onChange={(e) => handleObjectArrayChange(index, "issuingOrganization", e.target.value)}
									placeholder={t("editForm.certifications.issuingOrganizationPlaceholder")}
									className="mt-1"
									required
									aria-label={`${t("editForm.certifications.issuingOrganization")} ${t(
										"editForm.certifications.certificationNumber"
									)} ${index + 1}`}
								/>
							</div>
							<div className="grid grid-cols-2 gap-4">
								<div>
									<RequiredLabel text={t("editForm.certifications.issueMonth")} />
									<Select
										value={cert.issueMonth || ""}
										onValueChange={(value) => handleObjectArrayChange(index, "issueMonth", value)}
										required
										aria-label={`${t("editForm.certifications.issueMonth")} ${t("editForm.certifications.certificationNumber")} ${
											index + 1
										}`}
									>
										<SelectTrigger className="w-[180px] bg-white" tabIndex={0}>
											<SelectValue placeholder={t("editForm.certifications.selectMonth")} />
										</SelectTrigger>
										<SelectContent>
											<SelectItem value="Present">{t("editForm.certifications.present")}</SelectItem>
											{months.map((month) => (
												<SelectItem key={month} value={month} tabIndex={-1}>
													{month}
												</SelectItem>
											))}
										</SelectContent>
									</Select>
								</div>
								<div>
									<RequiredLabel text={t("editForm.certifications.issueYear")} />
									<Select
										value={cert.issueYear || ""}
										onValueChange={(value) => handleObjectArrayChange(index, "issueYear", value)}
										required
										aria-label={`${t("editForm.certifications.issueYear")} ${t("editForm.certifications.certificationNumber")} ${
											index + 1
										}`}
									>
										<SelectTrigger className="w-[180px] bg-white" tabIndex={0}>
											<SelectValue placeholder={t("editForm.certifications.selectYear")} />
										</SelectTrigger>
										<SelectContent>
											{years.map((year) => (
												<SelectItem key={year} value={year} tabIndex={-1}>
													{year}
												</SelectItem>
											))}
										</SelectContent>
									</Select>
								</div>
							</div>
							<div className="grid grid-cols-2 gap-4">
								<div>
									<label className="text-sm font-medium">{t("editForm.certifications.expirationMonth")}</label>
									<Select
										value={cert.expirationMonth || ""}
										onValueChange={(value) => handleObjectArrayChange(index, "expirationMonth", value)}
										aria-label={`${t("editForm.certifications.expirationMonth")} ${t(
											"editForm.certifications.certificationNumber"
										)} ${index + 1}`}
									>
										<SelectTrigger className="w-[180px] bg-white" tabIndex={0}>
											<SelectValue placeholder={t("editForm.certifications.selectMonth")} />
										</SelectTrigger>
										<SelectContent>
											<SelectItem value="Present">{t("editForm.certifications.present")}</SelectItem>
											{months.map((month) => (
												<SelectItem key={month} value={month} tabIndex={-1}>
													{month}
												</SelectItem>
											))}
										</SelectContent>
									</Select>
								</div>
								<div>
									<label className="text-sm font-medium">{t("editForm.certifications.expirationYear")}</label>
									<Select
										value={cert.expirationYear || ""}
										onValueChange={(value) => handleObjectArrayChange(index, "expirationYear", value)}
										aria-label={`${t("editForm.certifications.expirationYear")} ${t(
											"editForm.certifications.certificationNumber"
										)} ${index + 1}`}
									>
										<SelectTrigger className="w-[180px] bg-white" tabIndex={0}>
											<SelectValue placeholder={t("editForm.certifications.selectYear")} />
										</SelectTrigger>
										<SelectContent>
											{years.map((year) => (
												<SelectItem key={year} value={year} tabIndex={-1}>
													{year}
												</SelectItem>
											))}
										</SelectContent>
									</Select>
								</div>
							</div>
						</div>
					))}
					<Button variant="outline" onClick={addObjectArrayItem} aria-label={t("editForm.certifications.addCertification")}>
						{t("editForm.certifications.addCertification")}
					</Button>
				</div>
			);

		case "extraCurricular":
			return (
				<div className="space-y-8">
					{(editedData || []).map((activity: ExtraCurricular, index: number) => (
						<div key={activity.id || index} className="space-y-4 p-4 border rounded-lg">
							<div className="flex justify-between items-center">
								<h3 className="text-lg font-medium">
									{t("editForm.extraCurricular.activityNumber")} {index + 1}
								</h3>
								<Button
									variant="outline"
									size="sm"
									onClick={() => removeObjectArrayItem(index)}
									aria-label={`${t("editForm.extraCurricular.removeActivity")} ${index + 1}`}
								>
									{t("editForm.extraCurricular.removeActivity")}
								</Button>
							</div>
							<div>
								<RequiredLabel text={t("editForm.extraCurricular.activityName")} />
								<Input
									value={activity.activity || ""}
									onChange={(e) => handleObjectArrayChange(index, "activity", e.target.value)}
									placeholder={t("editForm.extraCurricular.activityNamePlaceholder")}
									className="mt-1"
									required
									aria-label={`${t("editForm.extraCurricular.activityName")} ${t("editForm.extraCurricular.activityNumber")} ${
										index + 1
									}`}
								/>
							</div>
							<div>
								<RequiredLabel text={t("editForm.extraCurricular.organizationName")} />
								<Input
									value={activity.orgName || ""}
									onChange={(e) => handleObjectArrayChange(index, "orgName", e.target.value)}
									placeholder={t("editForm.extraCurricular.organizationNamePlaceholder")}
									className="mt-1"
									required
									aria-label={`${t("editForm.extraCurricular.organizationName")} ${t("editForm.extraCurricular.activityNumber")} ${
										index + 1
									}`}
								/>
							</div>
							<div className="grid grid-cols-2 gap-4">
								<div>
									<RequiredLabel text={t("editForm.extraCurricular.startMonth")} />
									<Select
										value={activity.startMonth || ""}
										onValueChange={(value) => handleObjectArrayChange(index, "startMonth", value)}
										required
										aria-label={`${t("editForm.extraCurricular.startMonth")} ${t("editForm.extraCurricular.activityNumber")} ${
											index + 1
										}`}
									>
										<SelectTrigger className="w-[180px] bg-white" tabIndex={0}>
											<SelectValue placeholder={t("editForm.extraCurricular.selectMonth")} />
										</SelectTrigger>
										<SelectContent>
											<SelectItem value="Present">{t("editForm.extraCurricular.present")}</SelectItem>
											{months.map((month) => (
												<SelectItem key={month} value={month} tabIndex={-1}>
													{month}
												</SelectItem>
											))}
										</SelectContent>
									</Select>
								</div>
								<div>
									<RequiredLabel text={t("editForm.extraCurricular.startYear")} />
									<Select
										value={activity.startYear || ""}
										onValueChange={(value) => handleObjectArrayChange(index, "startYear", value)}
										required
										aria-label={`${t("editForm.extraCurricular.startYear")} ${t("editForm.extraCurricular.activityNumber")} ${
											index + 1
										}`}
									>
										<SelectTrigger className="w-[180px] bg-white" tabIndex={0}>
											<SelectValue placeholder={t("editForm.extraCurricular.selectYear")} />
										</SelectTrigger>
										<SelectContent>
											{years.map((year) => (
												<SelectItem key={year} value={year} tabIndex={-1}>
													{year}
												</SelectItem>
											))}
										</SelectContent>
									</Select>
								</div>
							</div>
							<div className="grid grid-cols-2 gap-4">
								<div>
									<RequiredLabel text={t("editForm.extraCurricular.endMonth")} />
									<Select
										value={activity.endMonth || ""}
										onValueChange={(value) => {
											if (value === "Present") {
												handleObjectArrayChange(index, "endYear", "");
											}
											handleObjectArrayChange(index, "endMonth", value);
										}}
										required
										aria-label={`${t("editForm.extraCurricular.endMonth")} ${t("editForm.extraCurricular.activityNumber")} ${
											index + 1
										}`}
									>
										<SelectTrigger className="w-[180px] bg-white" tabIndex={0}>
											<SelectValue placeholder={t("editForm.extraCurricular.selectMonth")} />
										</SelectTrigger>
										<SelectContent>
											<SelectItem value="Present">{t("editForm.extraCurricular.present")}</SelectItem>
											{months.map((month) => (
												<SelectItem key={month} value={month} tabIndex={-1}>
													{month}
												</SelectItem>
											))}
										</SelectContent>
									</Select>
								</div>
								{activity.endMonth && activity.endMonth !== "Present" && (
									<div>
										<RequiredLabel text={t("editForm.extraCurricular.endYear")} />
										<Select
											value={activity.endYear || ""}
											onValueChange={(value) => handleObjectArrayChange(index, "endYear", value)}
											required
											aria-label={`${t("editForm.extraCurricular.endYear")} ${t("editForm.extraCurricular.activityNumber")} ${
												index + 1
											}`}
										>
											<SelectTrigger className="w-[180px] bg-white" tabIndex={0}>
												<SelectValue placeholder={t("editForm.extraCurricular.selectYear")} />
											</SelectTrigger>
											<SelectContent>
												{years.map((year) => (
													<SelectItem key={year} value={year} tabIndex={-1}>
														{year}
													</SelectItem>
												))}
											</SelectContent>
										</Select>
									</div>
								)}
							</div>
							<div className="space-y-2">
								<label className="text-sm font-medium">{t("editForm.extraCurricular.tasks")}</label>
								{(activity.tasks || []).map((task: string, taskIndex: number) => (
									<div key={taskIndex} className="flex gap-2">
										<Input
											value={task}
											onChange={(e) => {
												const newTasks = [...(activity.tasks || [])];
												newTasks[taskIndex] = e.target.value;
												handleObjectArrayChange(index, "tasks", newTasks);
											}}
											className="flex-1"
											placeholder={t("editForm.extraCurricular.taskPlaceholder")}
											aria-label={`${t("editForm.extraCurricular.tasks")} ${taskIndex + 1} ${t(
												"editForm.extraCurricular.activityNumber"
											)} ${index + 1}`}
										/>
										<Button
											variant="outline"
											size="sm"
											onClick={() => {
												const newTasks = activity.tasks.filter((_, i) => i !== taskIndex);
												handleObjectArrayChange(index, "tasks", newTasks);
											}}
											aria-label={`${t("editForm.extraCurricular.removeTask")} ${taskIndex + 1} ${t(
												"editForm.extraCurricular.activityNumber"
											)} ${index + 1}`}
										>
											{t("editForm.extraCurricular.removeTask")}
										</Button>
									</div>
								))}
								<Button
									variant="outline"
									size="sm"
									onClick={() => {
										const newTasks = [...(activity.tasks || []), ""];
										handleObjectArrayChange(index, "tasks", newTasks);
									}}
									aria-label={`${t("editForm.extraCurricular.addTask")} ${t("editForm.extraCurricular.activityNumber")} ${
										index + 1
									}`}
								>
									{t("editForm.extraCurricular.addTask")}
								</Button>
							</div>
						</div>
					))}
					<Button variant="outline" onClick={addObjectArrayItem} aria-label={t("editForm.extraCurricular.addActivity")}>
						{t("editForm.extraCurricular.addActivity")}
					</Button>
				</div>
			);

		case "skills":
			return (
				<div className="space-y-4">
					{renderArrayInput("technical", t("editForm.skills.technical.title"), "skills")}
					{renderArrayInput("coreCompetencies", t("editForm.skills.coreCompetencies.title"), "skills")}
					{renderArrayInput("languages", t("editForm.skills.languages.title"), "skills")}
					{renderArrayInput("tools", t("editForm.skills.tools.title"), "skills")}
					{renderArrayInput("frameworks", t("editForm.skills.frameworks.title"), "skills")}
				</div>
			);

		case "interests":
			return (
				<div className="space-y-4">
					{/* <h2 className="text-xl font-semibold mb-4">{t("editForm.interests.title")}</h2> */}
					<div className="space-y-2">
						{(editedData || []).map((interest: string, index: number) => (
							<div key={index} className="flex gap-2">
								<Input
									value={interest}
									onChange={(e) => {
										const newInterests = [...(editedData || [])];
										newInterests[index] = e.target.value;
										setEditedData(newInterests);
									}}
									className="flex-1"
									placeholder={t("editForm.interests.placeholder")}
									aria-label={`Interest ${index + 1}`}
								/>
								<Button
									variant="outline"
									size="sm"
									onClick={() => {
										const newInterests = editedData.filter((_: string, i: number) => i !== index);
										setEditedData(newInterests);
									}}
									aria-label={t("editForm.interests.removeInterest")}
								>
									{t("editForm.interests.removeInterest")}
								</Button>
							</div>
						))}
						<Button
							variant="outline"
							size="sm"
							onClick={() => setEditedData([...(editedData || []), ""])}
							aria-label={t("editForm.interests.addInterest")}
						>
							{t("editForm.interests.addInterest")}
						</Button>
					</div>
				</div>
			);

		case "hobbies":
			return (
				<div className="space-y-4">
					{/* <h2 className="text-xl font-semibold mb-4">{t("editForm.hobbies.title")}</h2> */}
					<div className="space-y-2">
						{(editedData || []).map((hobby: string, index: number) => (
							<div key={index} className="flex gap-2">
								<Input
									value={hobby}
									onChange={(e) => {
										const newHobbies = [...(editedData || [])];
										newHobbies[index] = e.target.value;
										setEditedData(newHobbies);
									}}
									className="flex-1"
									placeholder={t("editForm.hobbies.placeholder")}
									aria-label={`Hobby ${index + 1}`}
								/>
								<Button
									variant="outline"
									size="sm"
									onClick={() => {
										const newHobbies = editedData.filter((_: string, i: number) => i !== index);
										setEditedData(newHobbies);
									}}
									aria-label={t("editForm.hobbies.removeHobby")}
								>
									{t("editForm.hobbies.removeHobby")}
								</Button>
							</div>
						))}
						<Button
							variant="outline"
							size="sm"
							onClick={() => setEditedData([...(editedData || []), ""])}
							aria-label={t("editForm.hobbies.addHobby")}
						>
							{t("editForm.hobbies.addHobby")}
						</Button>
					</div>
				</div>
			);

		default:
			return null;
	}
};

export default ProfileEditForm;
