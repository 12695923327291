import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useAppContext } from "../../contexts/AppContext";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { ChevronDownIcon, ChevronUpIcon, ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { AssignedSessionView } from "../../types/AssignedSessionView";
import { AssignedSessionService } from "../../services/AssignedSessionService";
import UserAssignedSessionCard from "../Utility/UserAssignedSessionCard";
import { AssignmentService } from "../../services/AssignmentService";
import { UserAssignmentView } from "../../types/UserAssignmentView";
import CompletedSessionCard from "../Utility/CompletedSessionCard";
import Spinner from "../Utility/Spinner";
import { Button } from "../../components/shadcn-ui/Button";
import { Card } from "../shadcn-ui/Card";
import UserAssignmentCard from "../Utility/UserAssignmentCard";
import { BriefcaseBusiness, Feather, UserCircle, ExternalLink, FileText, Search, WorkflowIcon, LockIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { AllAssignmentsCarousel } from "../Utility/AllAssignmentsCarousel";
import { useTranslation } from "../Utility/UseTranslation";
import { is } from "date-fns/locale";
import TokenManager from "../../../src/services/TokenManager";
import { PiUsersThree, PiUser } from "react-icons/pi";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "../shadcn-ui/Tabs";

const UseUserSessions = () => {
	const { userId } = useAuth();

	// State variables for different types of sessions and assignments
	const [availableDueDateStats, setAvailableDueDateStats] = useState<AssignedSessionView[]>([]);
	const [pendingDueDateStats, setPendingDueDateStats] = useState<AssignedSessionView[]>([]);
	const [completedDueDateStats, setCompletedDueDateStats] = useState<AssignedSessionView[]>([]);
	const [inProgressDueDateStats, setInProgressDueDateStats] = useState<AssignedSessionView[]>([]);
	const [userAssignments, setUserAssignments] = useState<UserAssignmentView[]>([]);

	// Loading states for different data fetching operations
	const [loadingAvailable, setLoadingAvailable] = useState(true);
	const [loadingInProgress, setLoadingInProgress] = useState(true);
	const [loadingPending, setLoadingPending] = useState(true);
	const [loadingCompleted, setLoadingCompleted] = useState(true);
	const [loadingAssignments, setLoadingAssignments] = useState(true);

	useEffect(() => {
		// Fetch data when the component mounts or userId changes
		const fetchEarliestPendingDueDateStats = async () => {
			if (!userId) return;
			try {
				const data = await AssignedSessionService.getAvailableByUserId(userId);
				setAvailableDueDateStats(data);
			} catch (error) {
				console.error("Error fetching earliest pending due date stats:", error);
			} finally {
				setLoadingAvailable(false);
			}
		};

		const fetchInProgressDueDateStats = async () => {
			if (!userId) return;
			try {
				const data = await AssignedSessionService.getInProgressByUserId(userId);
				setInProgressDueDateStats(data);
			} catch (error) {
				console.error("Error fetching in progress due date stats:", error);
			} finally {
				setLoadingInProgress(false);
			}
		};

		const fetchPendingDueDateStats = async () => {
			if (!userId) return;
			try {
				const data = await AssignedSessionService.getPendingByUserId(userId);
				setPendingDueDateStats(data);
			} catch (error) {
				console.error("Error fetching pending due date stats:", error);
			} finally {
				setLoadingPending(false);
			}
		};

		const fetchCompletedDueDateStats = async () => {
			if (!userId) return;
			try {
				const data = await AssignedSessionService.getCompletedByUserId(userId);
				setCompletedDueDateStats(data);
			} catch (error) {
				console.error("Error fetching completed due date stats:", error);
			} finally {
				setLoadingCompleted(false);
			}
		};

		const fetchUserAssignments = async () => {
			if (!userId) return;
			try {
				const data = await AssignmentService.getByUserId(userId);
				setUserAssignments(data);
			} catch (error) {
				console.error("Error fetching user assignments:", error);
			} finally {
				setLoadingAssignments(false);
			}
		};

		// Call all fetch functions
		fetchUserAssignments();
		fetchEarliestPendingDueDateStats();
		fetchInProgressDueDateStats();
		fetchPendingDueDateStats();
		fetchCompletedDueDateStats();
	}, [userId]);

	// Create elements for different types of sessions and assignments
	const availableDueDateUserStatsElements = availableDueDateStats
		.sort((a, b) => a.scheduleNumber - b.scheduleNumber)
		.map((stat) => <UserAssignedSessionCard key={stat.scheduleId} stat={stat} userId={userId} />);

	const inProgressDueDateUserStatsElements = inProgressDueDateStats
		.sort((a, b) => a.scheduleNumber - b.scheduleNumber)
		.map((stat) => <UserAssignedSessionCard key={stat.scheduleId} stat={stat} userId={userId} />);

	const pendingDueDateUserStatsElements = pendingDueDateStats
		.sort((a, b) => a.scheduleNumber - b.scheduleNumber)
		.map((stat) => <UserAssignedSessionCard key={stat.scheduleId} stat={stat} userId={userId} isPending={true} />);

	const completedDueDateUserStatsElements = completedDueDateStats
		.sort((a, b) => a.scheduleNumber - b.scheduleNumber)
		.map((stat) => <CompletedSessionCard key={stat.scheduleId} stat={stat} userId={userId} />);

	const userAssignmentElements = userAssignments.map((assignment) => <UserAssignmentCard key={assignment.assignmentId} assignment={assignment} />);

	return {
		loadingInProgress,
		loadingAvailable,
		loadingPending,
		loadingCompleted,
		loadingAssignments,
		availableDueDateUserStatsElements,
		inProgressDueDateUserStatsElements,
		pendingDueDateUserStatsElements,
		completedDueDateUserStatsElements,
		userAssignmentElements
	};
};
const NoItemsCard: React.FC<{ message: string }> = ({ message }) => (
	<Card className="w-full min-h-[250px] bg-transparent flex items-center justify-center p-2">
		<ExclamationCircleIcon className="h-5 w-5 text-gray-400 mr-2" />
		<p className="text-gray-500">{message}</p>
	</Card>
);

const VerticalCardStack: React.FC<{ elements: React.ReactNode[]; emptyMessage: string }> = ({ elements, emptyMessage }) => {
	const [showAll, setShowAll] = useState(false);
	const hasMoreThanOne = elements.length > 1;
	const { t } = useTranslation("userHomePage");
	return (
		<div className="mb-6">
			<div className="space-y-4">
				{elements.length > 0 ? (
					<>
						{elements[0]}
						{hasMoreThanOne && (
							<div className="mt-4">
								<Button
									variant="ghost"
									size="sm"
									className="p-0"
									onClick={() => setShowAll(!showAll)}
									aria-label={showAll ? "Show fewer items" : "Show more items"}
									aria-expanded={showAll}
								>
									{showAll ? (
										<>
											{t("showLess")} <ChevronUpIcon className="ml-2 h-4 w-4" />
										</>
									) : (
										<>
											{t("showMore")} <ChevronDownIcon className="ml-2 h-4 w-4" />
										</>
									)}
								</Button>
								{showAll && (
									<div className="space-y-4 mt-4">
										{elements.slice(1).map((element, index) => (
											<React.Fragment key={index}>{element}</React.Fragment>
										))}
									</div>
								)}
							</div>
						)}
					</>
				) : (
					<NoItemsCard message={emptyMessage} />
				)}
			</div>
		</div>
	);
};

const CreateSection = ({ loadingCompleted }: { loadingCompleted: boolean }) => {
	const { t } = useTranslation("userHomePage");
	const navigate = useNavigate();
	const interviewTenantFeatureFlag = useFeatureFlagEnabled("interview-access-tenants");
	const interviewUserFeatureFlag = useFeatureFlagEnabled("interview-access-users");
	const jobSearchTenantFeatureFlag = useFeatureFlagEnabled("job-search-access-tenants");
	const jobSearchUserFeatureFlag = useFeatureFlagEnabled("job-search-access-users");
	const careerPathSimFeatureFlag = useFeatureFlagEnabled("CareerPath-Flag");
	const careerPathSimTenantFeatureFlag = useFeatureFlagEnabled("CareerPathTenants");
	const resumeAssistFeatureFlag = useFeatureFlagEnabled("resume-assist");
	const resumeAssistTenantFeatureFlag = useFeatureFlagEnabled("resume-assist-tenants");
	const hasSelfServeAccessFeatureFlagEnabled = useFeatureFlagEnabled("selfserve-access") || false;
	const hasAssignmentsFeatureFlagEnabled = useFeatureFlagEnabled("assignment-access") || false;
	const hasFacultyClearance = TokenManager.hasFacultyClearance();

	type SimulationType = {
		id: string;
		icon: JSX.Element;
		title: string;
		userPath: string;
		adminPath: string;
		selfPracticeOnly?: boolean;
		enabled?: boolean;
		alwaysShow?: boolean;
	};

	const simulationTypes: SimulationType[] = [
		{
			id: "reflection",
			icon: <Feather className="h-4 w-4" />,
			title: t("CreateYourOwnButtons.CreateReflection"),
			userPath: "/user/create-reflection",
			adminPath: "/admin/create-reflection",
			alwaysShow: true
		},
		{
			id: "interview",
			icon: <BriefcaseBusiness className="h-4 w-4" />,
			title: t("CreateYourOwnButtons.CreateMockInterview"),
			userPath: "/user/create-interview",
			adminPath: "/admin/create-interview",
			enabled: interviewTenantFeatureFlag || interviewUserFeatureFlag
		},
		{
			id: "jobSearch",
			icon: <Search className="h-4 w-4" />,
			title: t("CreateYourOwnButtons.CreateJobSearch"),
			userPath: "/user/create-job-search",
			adminPath: "/admin/create-job-search",
			enabled: jobSearchTenantFeatureFlag || jobSearchUserFeatureFlag
		},
		{
			id: "careerPath",
			icon: <WorkflowIcon className="h-4 w-4" />,
			title: t("CreateYourOwnButtons.CreateCareerPath"),
			userPath: "/user/create-career-path",
			adminPath: "/admin/create-career-path",
			enabled: careerPathSimFeatureFlag || careerPathSimTenantFeatureFlag
		},
		{
			id: "resume",
			icon: <FileText className="h-4 w-4" />,
			title: t("CreateYourOwnButtons.CreateResume"),
			userPath: "/user/resume-assist",
			adminPath: "/user/resume-assist",
			selfPracticeOnly: true,
			enabled: resumeAssistFeatureFlag || resumeAssistTenantFeatureFlag
		}
	];

	const SimulationGrid = ({ isAssignmentMode }: { isAssignmentMode: boolean }) => (
		<div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
			{simulationTypes.map((sim) => {
				if ((!sim.enabled && !sim.alwaysShow) || (isAssignmentMode && sim.selfPracticeOnly)) {
					return null;
				}

				return (
					<Button
						key={sim.id}
						variant="outlineTransparent"
						className="h-[56px] flex items-center justify-center gap-2"
						onClick={() => navigate(isAssignmentMode ? sim.adminPath : sim.userPath)}
						aria-label={`Create new ${sim.title.toLowerCase()}`}
					>
						{sim.icon}
						{sim.title}
					</Button>
				);
			})}
		</div>
	);

	return (
		<div className="mb-8" role="region" aria-label="Create session section">
			{hasFacultyClearance ? (
				<Tabs defaultValue="assignment" className="w-full">
					<div className="flex items-center gap-4 mb-4">
						<h2 className="text-lg font-bold text-[#16013e]">{t("titles.createNew")}</h2>
					</div>
					<TabsList className="w-full h-11 grid w-full grid-cols-2 bg-gray-200 bg-opacity-70 p-1 rounded-lg">
						<TabsTrigger
							value="assignment"
							className="data-[state=active]:bg-white data-[state=active]:text-gray-900 data-[state=active]:shadow-sm text-gray-600 rounded-md h-9"
						>
							<PiUsersThree className="h-4 w-4 mr-2" />
							{t("titles.Assignment")}
						</TabsTrigger>
						<TabsTrigger
							value="self-practice"
							className="data-[state=active]:bg-white data-[state=active]:text-gray-900 data-[state=active]:shadow-sm text-gray-600 rounded-md h-9"
						>
							<PiUser className="h-4 w-4 mr-2" />
							{t("titles.selfServe")}
						</TabsTrigger>
					</TabsList>

					{loadingCompleted ? (
						<Spinner />
					) : (
						<>
							<TabsContent value="assignment">
								{hasAssignmentsFeatureFlagEnabled && <SimulationGrid isAssignmentMode={true} />}
								{!hasAssignmentsFeatureFlagEnabled && (
									<div className="flex items-center justify-center border border-gray-300 rounded-md p-4 bg-gray-100">
										<LockIcon className="h-5 w-5 text-gray-500 mr-2" />
										<h1 className="text-gray-700 text-center font-semibold">{t("noAccess.assignment")}</h1>
									</div>
								)}
							</TabsContent>
							<TabsContent value="self-practice">
								{hasSelfServeAccessFeatureFlagEnabled && <SimulationGrid isAssignmentMode={false} />}
								{!hasSelfServeAccessFeatureFlagEnabled && (
									<div className="flex items-center justify-center border border-gray-300 rounded-md p-4 bg-gray-100">
										<LockIcon className="h-5 w-5 text-gray-500 mr-2" />
										<h1 className="text-gray-700 text-center font-semibold">{t("noAccess.selfServe")}</h1>
									</div>
								)}
							</TabsContent>
						</>
					)}
				</Tabs>
			) : (
				<>
					{hasSelfServeAccessFeatureFlagEnabled && (
						<>
							<React.Fragment>
								<div className="flex items-center gap-2 mb-4">
									<h2 className="text-lg font-bold text-[#16013e]">{t("titles.createYourOwn")}</h2>
								</div>
							</React.Fragment>
							{loadingCompleted ? <Spinner /> : <SimulationGrid isAssignmentMode={false} />}
						</>
					)}
				</>
			)}
		</div>
	);
};

const RecentSessionSummariesSection = ({
	loadingCompleted,
	completedDueDateUserStatsElements
}: {
	loadingCompleted: boolean;
	completedDueDateUserStatsElements: React.ReactNode[];
}) => {
	const { t } = useTranslation("userHomePage");
	return (
		<div role="region" aria-label="Recent call summaries section">
			<h2 className="text-lg font-bold text-[#16013e] mb-4">{t("titles.recentCallSummaries")}</h2>
			{loadingCompleted ? (
				<Spinner aria-label="Loading recent call summaries" />
			) : completedDueDateUserStatsElements.length > 0 ? (
				<VerticalCardStack elements={completedDueDateUserStatsElements} emptyMessage={t("titles.noRecentCalls")} />
			) : (
				<div
					className="w-full h-[56px] rounded-[12px] border border-dashed border-[#171717] border-opacity-20 flex items-center justify-center"
					role="status"
					aria-label="No recent calls available"
				>
					<p className="text-[#3C3C3F] opacity-70">{t("titles.noRecentCalls")}</p>
				</div>
			)}
		</div>
	);
};

const MyAssignmentsSection = ({
	loadingAssignments,
	userAssignmentElements
}: {
	loadingAssignments: boolean;
	userAssignmentElements: React.ReactNode[];
}) => {
	const { t } = useTranslation("userHomePage");
	return (
		<div role="region" aria-label="All assignments section">
			<h2 id="all-assignments-id" className="text-lg font-bold text-[#16013e] mb-4">
				{t("titles.allAssignments")}
			</h2>
			{loadingAssignments ? (
				<Spinner aria-label="Loading assignments" />
			) : userAssignmentElements.length > 0 ? (
				<div className="space-y-4" role="list" aria-label="List of assignments">
					{userAssignmentElements}
				</div>
			) : (
				<div
					className="w-full h-[56px] rounded-[12px] border border-dashed border-[#171717] border-opacity-20 flex items-center justify-center"
					role="status"
					aria-label="No assignments available"
				>
					<p className="text-[#3C3C3F] opacity-70">{t("titles.noAssignments")}</p>
				</div>
			)}
		</div>
	);
};

const OtherInStageSimulationsSection: React.FC = () => {
	const { t } = useTranslation("userHomePage");
	const simulations = [
		{
			title: t("OtherSims.VideoInterviewSimulation"),
			description: t("OtherSims.VideoInterviewSimulationDescription"),
			link: "https://www.app.instage.io/setup/interview"
		},
		{
			title: t("OtherSims.SalesSpin"),
			description: t("OtherSims.SalesSpinDescription"),
			link: "https://www.app.instage.io/setup/discovery_spin"
		},
		{
			title: t("OtherSims.SalesPresentationSpin"),
			description: t("OtherSims.SalesPresentationSpinDescription"),
			link: "https://www.app.instage.io/setup/presentation"
		}
	];

	return (
		<div role="region" aria-label="Other InStage simulations section">
			<h2 className="text-lg font-bold text-[#16013e] mb-4">{t("titles.otherInStageSimulations")}</h2>
			<Card className="w-full bg-white p-6">
				<div className="grid gap-4">
					{simulations.map((sim, index) => (
						<div key={index}>
							<div
								className={`flex items-center justify-between p-0 transition-colors ${index < simulations.length - 1 ? "pb-0" : ""}`}
							>
								<div className="text-left">
									<h3 className="text-xl font-semibold text-[#00a9af] mb-2 transition-colors">{sim.title}</h3>
									<p className="text-gray-600 transition-colors group-hover:text-opacity-30">{sim.description}</p>
								</div>
								<Button
									variant="outline"
									size="sm"
									onClick={() => window.open(sim.link, "_blank")}
									aria-label={`Try ${sim.title} simulation in new tab`}
								>
									{t("OtherSims.TryNow")}
									<ExternalLink className="ml-2 h-4 w-4" />
								</Button>
							</div>
							{index < simulations.length - 1 && <hr className="border-gray-200 my-3" />}
						</div>
					))}
				</div>
			</Card>
		</div>
	);
};

const InProgressCallsSection = ({
	loadingInProgress,
	inProgressDueDateUserStatsElements
}: {
	loadingInProgress: boolean;
	inProgressDueDateUserStatsElements: React.ReactNode[];
}) => {
	const { t } = useTranslation("userHomePage");

	return (
		<div id="unsubmitted-calls">
			{inProgressDueDateUserStatsElements.length > 0 && loadingInProgress ? (
				<div className="mb-8" role="region" aria-label="Unsubmitted calls section">
					<h2 className="text-lg font-bold text-[#16013e] mb-4">{t("titles.unsubmittedCalls")}</h2>
					<Spinner aria-label="Loading unsubmitted calls" />
				</div>
			) : inProgressDueDateUserStatsElements.length > 0 ? (
				<div className="mb-8" role="region" aria-label="Unsubmitted calls section">
					<h2 className="text-lg font-bold text-[#16013e] mb-4">{t("titles.unsubmittedCalls")}</h2>
					<VerticalCardStack elements={inProgressDueDateUserStatsElements} emptyMessage="No unsubmitted calls" />
				</div>
			) : null}
		</div>
	);
};

const AvailableCallsSection = ({
	loadingAvailable,
	availableDueDateUserStatsElements
}: {
	loadingAvailable: boolean;
	availableDueDateUserStatsElements: React.ReactNode[];
}) => {
	const { t } = useTranslation("userHomePage");

	return (
		<div id="available-calls" className="mb-8" role="region" aria-label="Available calls section">
			<h2 className="text-lg font-bold text-[#16013e] mb-4">{t("titles.availableCalls")}</h2>
			{loadingAvailable ? (
				<Spinner aria-label="Loading available calls" />
			) : availableDueDateUserStatsElements.length > 0 ? (
				<div className="space-y-4" role="list" aria-label="List of available calls">
					{availableDueDateUserStatsElements}
				</div>
			) : (
				<div
					className="w-full h-[56px] rounded-[12px] border border-dashed border-[#171717] border-opacity-20 flex items-center justify-center"
					role="status"
					aria-label="No active calls available"
				>
					<p className="text-[#3C3C3F] opacity-70">{t("titles.noActiveCalls")}</p>
				</div>
			)}
		</div>
	);
};

const UpcomingCallsSection = ({
	loadingPending,
	pendingDueDateUserStatsElements
}: {
	loadingPending: boolean;
	pendingDueDateUserStatsElements: React.ReactNode[];
}) => {
	const { t } = useTranslation("userHomePage");

	return (
		<div id="upcoming-calls" role="region" aria-label="Upcoming calls section">
			<h2 className="text-lg font-bold text-[#16013e] mb-4">{t("titles.upcomingCalls")}</h2>
			{loadingPending ? (
				<Spinner aria-label="Loading upcoming calls" />
			) : pendingDueDateUserStatsElements.length > 0 ? (
				<div className="relative">
					<div className="overflow-x-auto pb-4 hide-scrollbar" role="list" aria-label="List of upcoming calls">
						<div className="flex space-x-4">
							{pendingDueDateUserStatsElements.map((element, index) => (
								<div key={index} className="w-full sm:w-[calc(50%-8px)] md:w-[calc(33.333%-11px)] flex-shrink-0" role="listitem">
									{element}
								</div>
							))}
						</div>
					</div>
					{pendingDueDateUserStatsElements.length > 3 && (
						<div
							className="absolute top-0 right-0 bottom-0 w-16 bg-gradient-to-l from-white to-transparent pointer-events-none"
							aria-hidden="true"
						></div>
					)}
				</div>
			) : (
				<div
					className="w-full h-[56px] rounded-[12px] border border-dashed border-[#171717] border-opacity-20 flex items-center justify-center"
					role="status"
					aria-label="No upcoming calls"
				>
					<p className="text-[#3C3C3F] opacity-70">{t("titles.noUpcomingCalls")}</p>
				</div>
			)}
		</div>
	);
};
// Main component for the user's homepage
const UserHomepage: React.FC = () => {
	const {
		loadingInProgress,
		loadingAvailable,
		loadingPending,
		loadingCompleted,
		loadingAssignments,
		availableDueDateUserStatsElements,
		inProgressDueDateUserStatsElements,
		pendingDueDateUserStatsElements,
		completedDueDateUserStatsElements,
		userAssignmentElements
	} = UseUserSessions();

	const { t } = useTranslation("userHomePage");

	// Render the main component
	return (
		<div className="h-full max-w-3xl mx-auto px-4">
			<h1 className="text-3xl font-bold text-[#16013e] mb-10" aria-label="Welcome message">
				{t("titles.dashboard")}
			</h1>

			{/* In Progress Calls Section */}
			<InProgressCallsSection loadingInProgress={loadingInProgress} inProgressDueDateUserStatsElements={inProgressDueDateUserStatsElements} />

			{/* Available Calls Section */}
			<AvailableCallsSection loadingAvailable={loadingAvailable} availableDueDateUserStatsElements={availableDueDateUserStatsElements} />

			{/* Create Your Own Section */}
			<CreateSection loadingCompleted={loadingCompleted} />

			{/* Stack all sections vertically */}
			<div className="space-y-8">
				{/* Upcoming Section */}
				<UpcomingCallsSection loadingPending={loadingPending} pendingDueDateUserStatsElements={pendingDueDateUserStatsElements} />

				{/* My Assignments Section */}
				<MyAssignmentsSection loadingAssignments={loadingAssignments} userAssignmentElements={userAssignmentElements} />

				{/* Recent Session Summaries Section */}
				<RecentSessionSummariesSection
					loadingCompleted={loadingCompleted}
					completedDueDateUserStatsElements={completedDueDateUserStatsElements}
				/>

				{/* Other InStage Simulations Section */}
				<OtherInStageSimulationsSection />
			</div>
		</div>
	);
};

export default UserHomepage;
