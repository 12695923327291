import React, { useEffect, useState } from "react";
import { SidebarNavItem } from "../../shadcn-ui/SidebarNavItem";
import { Sidebar } from "../../shadcn-ui/Sidebar";
import logo from "../../../assets/images/logo.png";
import logoCollapsed from "../../../assets/images/InStageLogo2.png";
import { SidebarSection } from "../../shadcn-ui/SidebarSection";
import {
	BriefcaseBusinessIcon,
	CalendarDaysIcon,
	ChevronLeft,
	ChevronRight,
	EyeIcon,
	Feather,
	PhoneIcon,
	PlusCircleIcon,
	SearchIcon,
	WorkflowIcon
} from "lucide-react";
import { VscSettings } from "react-icons/vsc";
import { GoArrowSwitch } from "react-icons/go";
import { Button } from "../../shadcn-ui/Button";
import { useAppContext } from "../../../contexts/AppContext";
import TokenManager from "../../../services/TokenManager";
import {
	HomeIcon,
	DocumentTextIcon,
	ClipboardDocumentListIcon,
	UserIcon,
	ChatBubbleLeftRightIcon,
	Cog6ToothIcon,
	ChevronDoubleRightIcon,
	EnvelopeIcon,
	ArrowPathIcon,
	CurrencyDollarIcon,
	LanguageIcon
} from "@heroicons/react/24/outline";
import { GrLanguage } from "react-icons/gr";
import { PiStudentFill } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import { Switch } from "../../shadcn-ui/Switch";
import FronteggProfile from "../FronteggProfile";

import { GrCode } from "react-icons/gr";
import { toast } from "../../../hooks/useToast";
import { PromptService } from "../../../services/PromptService";
import LanguageModal from "../LanguageModal";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { useTranslation } from "../UseTranslation";
import { CreateSidebarButtons } from "./CreateSidebarButtons";

interface NavItem {
	icon: React.ElementType;
	label: string;
	href?: string;
	visible: boolean;
	items?: NavItem[];
	onClick?: () => void;
	customRender?: (isMobile?: boolean) => React.ReactNode;
}

interface SidebarSection {
	icon: React.ElementType;
	label: string;
	visible: boolean;
	items: NavItem[];
}

export interface SidebarButtons {
	mainNav: NavItem[];
	assignments: NavItem[];
	assignedCalls: SidebarSection;
	createYourOwn: SidebarSection;
	bottomNav: NavItem[];
	devOptions: SidebarSection;
}

interface SidebarContentProps {
	isCollapsed: boolean;
	buttons: SidebarButtons;
	t: (key: string) => string;
}

export const useSidebarComponent = () => {
	const {
		setLanguage,
		language,
		debugMode,
		setDebugMode,
		isStudentMode,
		setIsStudentMode,
		isWideScreen,
		setIsWideScreen,
		isCollapsed,
		setIsCollapsed
	} = useAppContext();
	const navigate = useNavigate();
	const [showLanguageModal, setShowLanguageModal] = useState(false);
	const isLanguageFeatureFlagEnabled = useFeatureFlagEnabled("translation") || false;
	const hasSelfServeAccessFeatureFlagEnabled = useFeatureFlagEnabled("selfserve-access") || false;
	const hasAssignmentsFeatureFlagEnabled = useFeatureFlagEnabled("assignment-access") || false;
	const { t } = useTranslation("sideBar");

	const handleStudentModeToggle = (newValue: boolean) => {
		setIsStudentMode(newValue);
		sessionStorage.setItem("isStudentMode", newValue.toString());

		if (window.location.pathname !== "/user" && sessionStorage.getItem("isStudentMode") === "true") {
			navigate("/user");
		}
	};

	const handleLanguageModalClose = (selectedLanguage: "en" | "fr") => {
		setLanguage(selectedLanguage);
		setShowLanguageModal(false);
	};

	const handleRefreshPrompts = async () => {
		try {
			const success = await PromptService.refreshPrompts();
			if (success) {
				toast({
					title: "Success",
					description: "Prompts refreshed successfully",
					variant: "default"
				});
			} else {
				toast({
					title: "Error",
					description: "Failed to refresh prompts",
					variant: "destructive"
				});
			}
		} catch (error) {
			console.log("error refreshing prompts", error);
			toast({
				title: "Error",
				description: error instanceof Error ? error.message : "An unknown error occurred",
				variant: "destructive"
			});
		}
	};

	const handleScroll = (id: string) => {
		console.log("window.location.pathname", window.location.pathname);
		// Wait for the navigation to complete and network to be idle before attempting to scroll
		const scrollToElement = () => {
			const element = document.getElementById(id);
			if (element) {
				element.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
			}
		};

		const onIdle = () => {
			if ("requestIdleCallback" in window) {
				console.log("requestIdleCallback");
				window.requestIdleCallback(scrollToElement);
			} else {
				// Fallback for browsers that do not support requestIdleCallback
				console.log("setTimeout");
				setTimeout(scrollToElement, 1000);
			}
		};

		if (window.location.pathname === "/user") {
			// if already on user page
			scrollToElement();
		} else if (window.location.pathname !== "/user") {
			// if not already on user page then navigate, wait for it to load, then scroll
			navigate("/user");
			setTimeout(onIdle, 1000);
		}
	};

	const handleLanguageModalOpen = () => {
		setShowLanguageModal(true);
	};

	const buttons = CreateSidebarButtons({
		debugMode,
		isStudentMode,
		setDebugMode,
		setIsStudentMode,
		handleStudentModeToggle,
		handleLanguageModalOpen,
		handleRefreshPrompts,
		handleScroll,
		navigate,
		t,
		isLanguageFeatureFlagEnabled,
		hasSelfServeAccessFeatureFlagEnabled,
		hasAssignmentsFeatureFlagEnabled
	});

	return {
		setLanguage,
		language,
		showLanguageModal,
		setShowLanguageModal,
		buttons,
		handleStudentModeToggle,
		handleLanguageModalClose,
		handleRefreshPrompts,
		handleScroll,
		isCollapsed,
		setIsCollapsed,
		isStudentMode,
		setIsStudentMode,
		debugMode,
		setDebugMode,
		navigate,
		LogoAndCollapseButton,
		isWideScreen,
		setIsWideScreen,
		handleLanguageModalOpen,
		t
	};
};

export const LogoAndCollapseButton = ({
	isCollapsed,
	setIsCollapsed,
	navigate
}: {
	isCollapsed: boolean;
	setIsCollapsed: (isCollapsed: boolean) => void;
	navigate: (path: string) => void;
}) => {
	useEffect(() => {
		console.log("isCollapsed", isCollapsed);
	}, [isCollapsed]);

	return (
		<div className="flex-shrink-0 flex items-center justify-between p-4">
			{!isCollapsed && <img src={logo} alt="InStage Logo" className="h-8 w-auto ml-2 cursor-pointer" onClick={() => navigate("/user")} />}
			{isCollapsed && (
				<img src={logoCollapsed} alt="InStage Logo" className="h-8 w-auto ml-2 cursor-pointer" onClick={() => navigate("/user")} />
			)}
			<Button
				variant="ghost"
				size="icon"
				// className="absolute -right-8 top-3.5 rounded-full bg-background border"
				className={
					isCollapsed ? "absolute -right-8 top-3.5 rounded-full bg-white rounded-l-[10px]" : "absolute -right-0 top-3.5 rounded-full"
				}
				onClick={() => setIsCollapsed(!isCollapsed)}
			>
				{isCollapsed ? <GoArrowSwitch className="h-4.5 w-4.5" /> : <GoArrowSwitch className="h-4 w-4" />}
			</Button>
		</div>
	);
};

const SidebarContent: React.FC<SidebarContentProps> = ({ isCollapsed, buttons, t }) => {
	return (
		<nav className={`flex-1 space-y-4 p-4 ${isCollapsed ? "mt-4" : "overflow-y-auto"}`}>
			<div>
				{buttons.mainNav.map((item) => (
					<SidebarNavItem
						key={item.href}
						icon={item.icon}
						label={t(item.label)}
						href={item.href}
						isCollapsed={isCollapsed}
						visible={item?.visible}
					/>
				))}
				<div className="my-4 border-b border-border" />
			</div>

			<div>
				{buttons.assignments.map((item) => (
					<SidebarNavItem
						key={item.href}
						icon={item.icon}
						label={t(item.label)}
						href={item.href}
						isCollapsed={isCollapsed}
						visible={item?.visible}
					/>
				))}
			</div>

			<SidebarSection
				icon={buttons.assignedCalls.icon}
				label={t(buttons.assignedCalls.label)}
				isCollapsed={isCollapsed}
				visible={buttons.assignedCalls.visible}
			>
				{buttons.assignedCalls.items.map((item: NavItem, index: number) => (
					<SidebarNavItem
						key={`${item.label}-${index}`}
						icon={item.icon}
						label={t(item.label)}
						href={item.href}
						isCollapsed={isCollapsed}
						visible={item.visible}
						onClick={item.onClick}
					/>
				))}
			</SidebarSection>

			<SidebarSection
				icon={buttons.createYourOwn.icon}
				label={t(buttons.createYourOwn.label)}
				isCollapsed={isCollapsed}
				visible={buttons.createYourOwn.visible}
			>
				{buttons.createYourOwn.items.map((item, index) => (
					<SidebarNavItem
						key={`${item.label}-${index}`}
						icon={item.icon}
						label={t(item.label)}
						href={item.href}
						isCollapsed={isCollapsed}
						visible={item.visible}
					/>
				))}
			</SidebarSection>

			{buttons.bottomNav.map((item) => (
				<SidebarNavItem
					key={item.href || item.label}
					icon={item.icon}
					label={t(item.label)}
					href={item?.href}
					isCollapsed={isCollapsed}
					customRender={item?.customRender}
					visible={item.visible}
					onClick={item?.onClick}
				/>
			))}

			<SidebarSection
				icon={buttons.devOptions.icon}
				label={t(buttons.devOptions.label)}
				isCollapsed={isCollapsed}
				visible={buttons.devOptions.visible}
			>
				{buttons.devOptions.items.map((item, index) => (
					<SidebarNavItem
						key={`${item.label}-${index}`}
						icon={item.icon}
						label={t(item.label)}
						href={item.href}
						isCollapsed={isCollapsed}
						visible={item.visible}
						onClick={item.onClick}
					/>
				))}
			</SidebarSection>
		</nav>
	);
};

const SideBarComponent = () => {
	const { isCollapsed, showLanguageModal, buttons, setIsCollapsed, navigate, setShowLanguageModal, handleLanguageModalClose, t } =
		useSidebarComponent();

	return (
		<div>
			<LanguageModal
				showLanguageModal={showLanguageModal}
				setShowLanguageModal={setShowLanguageModal}
				handleLanguageModalClose={handleLanguageModalClose}
			/>

			<Sidebar variant="default" size={isCollapsed ? "collapsed" : "default"} className="fixed top-0 left-0 h-screen flex flex-col z-30">
				<LogoAndCollapseButton isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} navigate={navigate} />
				<SidebarContent isCollapsed={isCollapsed} buttons={buttons} t={t} />
				<FronteggProfile isCollapsed={isCollapsed} />
			</Sidebar>
		</div>
	);
};

export default SideBarComponent;
