import { z } from "zod";

export type CoreQuestionType = "Survey";
export type QuestionType = "Opening" | "Closing" | CoreQuestionType;

export const ScreeningQuestionSchema = z.object({
	id: z.string(),
	order: z.number(),
	type: z.enum(["Opening", "Survey", "Closing"]),
	text: z.string(),
	context: z.string().optional()
});

export type ScreeningQuestion = z.infer<typeof ScreeningQuestionSchema>;

export const CareerPathAggregateQuestionSummarySchema = z.object({
	summary: z.string(),
	score: z.number()
});

export type CareerPathAggregateQuestionSummary = z.infer<typeof CareerPathAggregateQuestionSummarySchema>;

export const careerPathMiddleSessionAggregateSchema = z.object({
	overview: z.string().describe("A detail rich 3-4 sentence summary synthesizing students' overall feelings about the career path process so far."),
	successfulChoice: z.object({
		summary: z.string().describe("A detail rich 3-4 sentence summary synthesizing the most successful career path choice so far."),
		score: z
			.number()
			.describe("The average score of the successful career path choice questions. percentage of people who have chosen a specific career path")
	})
});
export type CareerPathMiddleSessionAggregate = z.infer<typeof careerPathMiddleSessionAggregateSchema>;

export type CareerPathStageType = "discovery" | "progression";

export interface CareerPathStagePlan {
	screeningQuestions: number;
	careerPathStageOrderNumber: number;
	careerPathQuestionList: ScreeningQuestion[];
	questionDetails?: {
		[questionId: string]: {
			text: string;
			context: string;
		};
	};
}

export type CareerPathStage = {
	careerPathStageOrderNumber: number;
	careerPathStageType: CareerPathStageType;
	enabled: boolean;
	title: string;
	description: string;
	date: Date | null;
	startDate: Date | null;
};

export class CareerPathPayload {
	careerPathStageOrderNumber: number;

	careerPathStageType: CareerPathStageType;

	careerPathQuestionList: ScreeningQuestion[];

	careerPathAggregateQuestionList: ScreeningQuestion[];

	constructor(data: Partial<CareerPathPayload>) {
		this.careerPathStageOrderNumber = data.careerPathStageOrderNumber ?? 0;
		this.careerPathStageType = data.careerPathStageType ?? "discovery";
		this.careerPathQuestionList = data.careerPathQuestionList ?? [];
		this.careerPathAggregateQuestionList = data.careerPathAggregateQuestionList ?? [];
	}
}
