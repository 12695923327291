import React from "react";
import { useLocation } from "react-router-dom";
import { Button } from "../../../shadcn-ui/Button";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { CheckCircleIcon as CheckCircleOutlineIcon } from "@heroicons/react/24/outline";
import { CareerPathStage, CareerPathStagePlan, CareerPathStageType } from "../../../../types/CareerPathPayload";
import CareerPathPlan from "./CareerPathPlan";

// Define props for the CareerPathSetup component
export interface CareerPathSetupProps {
	values: {
		stages?: CareerPathStage[];
		stagePlan: {
			[key: string]: CareerPathStagePlan;
		};
		[key: string]: any;
	};
	errors: any;
	touched: any;
	setFieldValue: (field: string, value: any) => void;
	onPrevious: () => void;
	onNext: () => void;
	isValid: boolean;
	dirty: boolean;
	isEditMode: boolean;
}

// Helper function to determine stage type based on order
export const determineStageType = (orderNumber: number): CareerPathStageType => {
	switch (orderNumber) {
		case 0:
			return "discovery";
		case 1:
			return "progression";

		default:
			return orderNumber > 1 ? "progression" : "discovery";
	}
};

// Helper function to get stage title based on type and order
export const getStageTitle = (type: CareerPathStageType, orderNumber: number): string => {
	switch (type) {
		case "discovery":
			return "Discovery";
		case "progression":
			return "Progression";
		default:
			return "Discovery";
	}
};

//  Setup component for capturing basic assignment details
const CareerPathSetup: React.FC<CareerPathSetupProps> = ({
	values,
	errors,
	touched,
	setFieldValue,
	onPrevious,
	onNext,
	isValid,
	dirty,
	isEditMode
}) => {
	const location = useLocation();
	const isAdminPath = location.pathname.startsWith("/admin");

	// Check if there is at least one question
	const isFormValid = () => {
		if (isEditMode) return true;

		return (
			values.stages?.[0]?.careerPathStageType === "discovery" &&
			values.stages?.[0]?.enabled &&
			values.stagePlan?.[values.stages?.[0]?.careerPathStageOrderNumber]?.careerPathQuestionList?.length > 0
		);
	};

	return (
		<div className="space-y-8">
			<Panel title="Customize Your Career Path Discovery Questions">
				{isAdminPath ? (
					<p className="text-base text-gray-600 mb-6">
						Configure the details of your discovery questions or leave as the default. These questions will be asked to the students / job
						seekers to help the AI discover their potential career path options.
					</p>
				) : (
					<p className="text-base text-gray-600 mb-6">
						Design your career path discovery questions. These questions will be asked to help the AI discover potential career path
						options.
					</p>
				)}
			</Panel>

			{/* Interview Plans for each enabled stage, excluding resume review */}
			{values.stages
				?.filter((stage: CareerPathStage) => stage.enabled && stage.careerPathStageType === "discovery")
				.map((stage: CareerPathStage, index: number) => {
					// Calculate total questions for this stage
					const stagePlan = values.stagePlan?.[stage.careerPathStageOrderNumber];
					const totalQuestions = stagePlan ? stagePlan.screeningQuestions || 0 : 0;

					return (
						<Panel
							key={stage.careerPathStageOrderNumber}
							title={`Career Path Discovery Questions - ${stage.title}`}
							totalQuestions={totalQuestions}
						>
							<div className="mb-4">
								<p className="text-base text-gray-600 mb-6">
									You may edit the the question list to use different career aptitude questions. You can add, remove, or reorder
									questions to match your needs.
								</p>
							</div>
							<CareerPathPlan
								careerPathStageOrderNumber={stage.careerPathStageOrderNumber}
								values={values}
								setFieldValue={setFieldValue}
								isAdminPath={isAdminPath}
							/>
						</Panel>
					);
				})}

			{/* Navigation buttons - visible only on larger screens */}
			<div className="hidden md:flex justify-end mt-8 gap-2">
				<Button
					type="button"
					variant="secondary"
					onClick={() => {
						onPrevious();
					}}
					aria-label="Go to previous step in career path setup"
				>
					Previous
				</Button>
				<Button
					name="next"
					aria-label="Go to next step in career path setup"
					value="Next"
					onClick={() => {
						onNext();
					}}
					disabled={!isFormValid()}
				>
					Next
				</Button>
			</div>
		</div>
	);
};

// Panel component for grouping related form fields
const Panel: React.FC<{
	title: string;
	children: React.ReactNode;
	totalQuestions?: number;
	validationStatus?: boolean;
}> = ({ title, children, totalQuestions, validationStatus }) => {
	const getBorderAndIcon = () => {
		if (validationStatus !== undefined) {
			return validationStatus
				? {
						border: "border-[#00a9af]",
						icon: <CheckCircleIcon className="w-6 h-6 text-[#00a9af]" />
				  }
				: {
						border: "border-incomplete",
						icon: <CheckCircleOutlineIcon className="w-6 h-6 text-incomplete" />
				  };
		}
		if (totalQuestions === undefined) {
			return { border: "border-[#eaecf0]", icon: null };
		}
		return totalQuestions > 0
			? {
					border: "border-[#00a9af]",
					icon: <CheckCircleIcon className="w-6 h-6 text-[#00a9af]" />
			  }
			: {
					border: "border-incomplete",
					icon: <CheckCircleOutlineIcon className="w-6 h-6 text-incomplete" />
			  };
	};

	const { border, icon } = getBorderAndIcon();

	return (
		<div className={`bg-white border rounded-lg p-6 ${border}`}>
			<div className="flex justify-between items-center mb-4">
				<h3 className="text-lg font-semibold text-gray-800">{title}</h3>
				{icon}
			</div>
			<div className="space-y-4">{children}</div>
		</div>
	);
};

export default CareerPathSetup;
