export const UserSessionPageLocale = {
	en: {
		"Select a Resume": "Select a Resume",
		"Create New Resume": "Create New Resume",
		"Your Resumes": "Your Resumes",
		"Job Details": "Job Details",
		"Show Job Post": "Show Job Post",
		"Hide Job Post": "Hide Job Post",
		"Experience Level": "Experience Level",
		"Required Skills": "Required Skills",
		"Key Responsibilities": "Key Responsibilities",
		"Job Function": "Job Function",
		"Interview Plan": "Interview Plan",
		"Auto-generated question": "Auto-generated question",
		"Generate Job Details": "Generate Job Details",
		"Generating Job": "Generating Job",
		"Job Description": "Job Description",
		"Job Title": "Job Title",
		"Select Job": "Select Job",

		ResumeForInterview: "Resume for Interview",
		questionType: {
			Opening: "Opening",
			Closing: "Closing",
			Technical: "Technical",
			Behavioral: "Behavioral",
			Situational: "Situational",
			"Cultural Fit": "Cultural Fit",
			Background: "Background",
			Motivation: "Motivation",
			General: "General",
			Recall: "Recall",
			Resume: "Resume"
		},
		Questions: "Questions",
		Clear: "Clear",
		general: {
			loading: "Loading",
			course: "Course",
			description: "Description",
			for: "For",
			selfPractice: "Self Practice",
			success: "Success",
			error: "Error",
			deleteSession: "Delete Session",
			chatHistory: "Chat History",
			confirmAndSubmit: "Confirm & Submit",
			unsubmit: "Unsubmit",
			restartCall: "Restart Call",
			restartCallWith: "Restart Call With",
			deleteSessionDescription: "This action cannot be undone",
			deleteSessionTitle: "Are you sure?",
			yesDelete: "Yes, delete",
			noCancel: "No, cancel"
		},
		CallOptions: {
			RestartWebCallNow: "Restart Web Call Now",
			CallMeNow: "Call Me Now",
			StartWebCallNow: "Start Web Call Now",
			RestartPhoneCall: "Restart Phone Call",
			Scheduled: "Scheduled",
			ScheduledCall: "Scheduled Call",
			ScheduleACall: "Schedule a Call",
			RestartBySchedulingACall: "Restart by Scheduling a Call",
			Cancel: "Cancel",
			Starting: "Starting...",
			StartWebCall: "Start Web Call",
			StartPhoneCall: "Start Phone Call",
			StartCallWith: "Start Call With",
			ManageBooking: "Manage Booking",
			Note: "Note: This will delete your current summary.",
			and: "and",
			isA: "is a",
			PlayingRoleOf: "You're playing the role of",
			YouArePlayingTheRoleOf: "You are playing the role of",
			ResumeRequiredLong: "Please select a resume before restarting the interview.",
			ResumeRequiredShort: "Resume Required"
		},
		assignment: {
			details: {
				hide: "Hide Assignment Details",
				show: "Show Assignment Details"
			},
			session: {
				deleted: "Session Deleted",
				summary: "Individual Session Summary",
				regenerating: "Regenerating...",
				regenerateSummary: "Regenerate Summary"
			},
			status: {
				stages: "Stages",
				calls: "Calls",
				pastDue: "Sorry, this call is past due. Please contact your instructor to see if you can get an extension.",
				lateSubmission: "This call is past due. You can still complete it, but it will be marked as late.",
				noData: "No data available for this call"
			}
		},
		overview: {
			title: "Overview",
			expectations: "Expectations for this Experience",
			outlook: "Outlook"
		},
		purpose: {
			title: "Purpose",
			description: "Description",
			summary: "Summary",
			progress: "Progress",
			keyTakeaways: "Key Takeaways",
			growth: "Growth",
			valuableAspects: "Valuable Aspects",
			contribution: "Contribution",
			suggestions: "Suggestions"
		},
		goals: {
			title: "Goal",
			progress: "Goal Progress",
			status: "Goal Status",
			nextCall: "Goals / Intentions Before Next Call",
			nextSession: "Goals Before Next Session"
		},
		assessment: {
			feedback: {
				wentWell: "What Went Well",
				improve: "Areas to Improve",
				reflectionProcess: "Review of Reflection Process",
				point: "Point",
				keyTakeaways: "Key Takeaways",
				growth: "Growth",
				feedback: "Feedback",
				valuableAspects: "Valuable Aspects",
				contribution: "Contribution",
				suggestions: "Suggestions",
				preferredMethod: "Preferred Method",
				rating: "Rating",
				satisfaction: "Student Satisfaction"
			},
			course: {
				mostValuable: "Most Valuable Aspects of Course",
				leastValuable: "Least Valuable Aspects of Course",
				ratingReason: "Reason for Rating"
			}
		},
		frontline: {
			professionalism: "Professionalism",
			status: "Status",
			reason: "Reason",
			checklists: {
				communication: "Communication Points Checklist",
				information: "Information Collection Checklist",
				point: "Point"
			},
			scoring: {
				overall: "Overall Score",
				weight: "weight",
				saving: "Saving...",
				saveChanges: "Save Changes",
				updateSuccess: "Frontline assessment scores updated successfully",
				updateError: "Failed to update frontline assessment. Please try again.",
				completionBased: "Score based on successful completion of Communication Points",
				infoCollection: "and Information Collection",
				items: "checklist items",
				levelGuide: "Score based on professionalism level: Poor (25%), Fair (50%), Good (75%), Excellent (100%)"
			}
		},
		career: {
			matches: {
				title: "Top 5 Career Path Matches",
				whatTheyDo: "What do they do?",
				whyMatch: "Why This is a Good Match"
			},
			discussion: {
				title: "Career Path Discussion",
				chosenPath: "Chosen Career Path"
			},
			roles: {
				coach: "Career Coach",
				user: "User",
				interviewer: "Interviewer",
				interviewee: "Interviewee"
			},
			questionSummary: "Question List Summary"
		},
		communication: {
			chat: {
				history: "Chat History",
				noTranscript: "Transcript is not included for this session."
			},
			comments: "Noteworthy Comments"
		},
		confidentiality: {
			notice: "Confidentiality Notice",
			title: "Confidentiality and Data Sharing",
			reminder: "Confidentiality Reminder",
			responsibility:
				"It is your responsibility to protect the privacy and confidentiality of your employer, colleagues, and your own personal information.",
			sensitive: "Do not disclose any sensitive details",
			permission: "related to the company, its operations, employees, or your own personal information without explicit permission.",
			dataSharing: {
				title: "Data Sharing",
				settings: "For this assignment, your administrators have set the following data sharing settings:",
				transcript: {
					shared: "Full Transcript will be shared",
					notShared: "Full Transcript will not be shared"
				},
				audio: {
					shared: "Audio Recording will be shared",
					notShared: "Audio Recording will not be shared"
				},
				name: {
					shared: "First and last name will be shared",
					notShared: "First and last name will not be shared"
				}
			},
			agreement: {
				agree: "Agree to Confidentiality",
				continue: "Agree and Continue",
				disagree: "Disagree"
			}
		},
		submitDialog: {
			title: "Confirm & Submit",
			description:
				"Are you sure you want to confirm and submit this call summary? Once you submit, you will not be able to unsubmit or retry unless you contact our support team.",
			note: "This will only submit the currently selected session.",
			confirm: "Submit",
			cancel: "Cancel"
		}
	},
	fr: {
		"Select a Resume": "Sélectionner un CV",
		"Create New Resume": "Créer un nouveau CV",
		"Your Resumes": "Vos CV",
		"Job Details": "Détails du travail",
		"Show Job Post": "Afficher le poste de travail",
		"Hide Job Post": "Masquer le poste de travail",
		"Experience Level": "Niveau d'expérience",
		"Required Skills": "Compétences requises",
		"Key Responsibilities": "Responsabilités clés",
		"Job Function": "Fonction du travail",
		"Interview Plan": "Plan d'entretien",
		"Auto-generated question": "Question auto-générée",
		"Generate Job Details": "Generate Job Details",
		"Generating Job": "Génération du travail",
		"Job Description": "Description du travail",
		"Job Title": "Titre du travail",
		"Select Job": "Sélectionner le travail",
		ResumeForInterview: "CV pour l'entretien",
		questionType: {
			Opening: "Ouverture",
			Closing: "Fermeture",
			Technical: "Technique",
			Behavioral: "Comportementale",
			Situational: "Situationnelle",
			"Cultural Fit": "Fit culturel",
			Background: "Arrière-plan",
			Motivation: "Motivation",
			General: "Général",
			Recall: "Rappel",
			Resume: "CV"
		},
		Questions: "Questions",
		Clear: "Effacer",
		general: {
			loading: "Chargement",
			course: "Cours",
			description: "Description",
			for: "Pour",
			selfPractice: "Pratique Individuelle",
			success: "Succès",
			error: "Erreur",
			deleteSession: "Supprimer la session",
			chatHistory: "Historique des messages",
			confirmAndSubmit: "Confirmer et soumettre",
			unsubmit: "Annuler la soumission",
			restartCall: "Redémarrer l'appel",
			restartCallWith: "Redémarrer l'appel avec",
			deleteSessionDescription: "Cette action ne peut pas être annulée",
			deleteSessionTitle: "Êtes-vous sûr?",
			yesDelete: "Oui, supprimer",
			noCancel: "Non, annuler"
		},
		CallOptions: {
			RestartWebCallNow: "Redémarrer l'appel web maintenant",
			CallMeNow: "Appeler maintenant",
			StartWebCallNow: "Démarrer l'appel web",
			RestartPhoneCall: "Redémarrer l'appel téléphonique",
			Scheduled: "Programmé",
			ScheduledCall: "Appel programmé",
			ScheduleACall: "Planifier un appel",
			RestartBySchedulingACall: "Redémarrer en planifiant un appel",
			Cancel: "Annuler",
			Starting: "Démarrage...",
			StartWebCall: "Commencer l'appel web",
			StartPhoneCall: "Commencer l'appel téléphonique",
			StartCallWith: "Commencer l'appel avec",
			ManageBooking: "Gérer la réservation",
			Note: "Note: Cela supprimera votre résumé actuel.",
			and: "et",
			isA: "est un",
			PlayingRoleOf: "Vous jouez le rôle de",
			YouArePlayingTheRoleOf: "Vous êtes le rôle de",
			ResumeRequiredLong: "Veuillez sélectionner un CV avant de redémarrer l'entretien.",
			ResumeRequiredShort: "CV requis"
		},
		assignment: {
			details: {
				hide: "Masquer les détails du devoir",
				show: "Afficher les détails du devoir"
			},
			session: {
				deleted: "Session supprimée",
				summary: "Résumé de la session individuelle",
				regenerating: "Régénération...",
				regenerateSummary: "Régénérer le résumé"
			},
			status: {
				stages: "Étapes",
				calls: "Appels",
				pastDue: "Désolé, cet appel est en retard. Veuillez contacter votre instructeur pour voir si vous pouvez obtenir une prolongation.",
				lateSubmission: "Cet appel est en retard. Vous pouvez toujours le compléter, mais il sera marqué comme en retard.",
				noData: "Aucune donnée disponible pour cet appel"
			}
		},
		overview: {
			title: "Aperçu",
			expectations: "Attentes pour cette expérience",
			outlook: "Perspective"
		},
		purpose: {
			title: "Objectif",
			description: "Description",
			summary: "Résumé",
			progress: "Progression",
			keyTakeaways: "Points clés",
			growth: "Développement",
			valuableAspects: "Aspects précieux",
			contribution: "Contribution",
			suggestions: "Suggestions"
		},
		goals: {
			title: "Objectif",
			progress: "Progression de l'objectif",
			status: "Statut de l'objectif",
			nextCall: "Objectifs / Intentions avant le prochain appel",
			nextSession: "Objectifs avant la prochaine session"
		},
		assessment: {
			feedback: {
				wentWell: "Ce qui s'est bien passé",
				improve: "Domaines à améliorer",
				reflectionProcess: "Revue du processus de réflexion",
				keyTakeaways: "Points clés à retenir",
				growth: "Développement",
				feedback: "Retour",
				valuableAspects: "Aspects précieux",
				contribution: "Contribution",
				suggestions: "Suggestions",
				preferredMethod: "Méthode préférée",
				rating: "Évaluation",
				satisfaction: "Satisfaction des étudiants"
			},
			course: {
				mostValuable: "Aspects les plus précieux du cours",
				leastValuable: "Aspects les moins précieux du cours",
				ratingReason: "Raison de l'évaluation"
			}
		},
		frontline: {
			professionalism: "Professionnalisme",
			status: "Statut",
			reason: "Raison",
			checklists: {
				communication: "Liste de contrôle des points de communication",
				information: "Liste de contrôle de la collecte d'informations",
				point: "Point"
			},
			scoring: {
				overall: "Score Global",
				weight: "pondération",
				saving: "Enregistrement...",
				saveChanges: "Enregistrer les modifications",
				updateSuccess: "Scores de l'évaluation Frontline mis à jour avec succès",
				updateError: "Failed to update frontline assessment. Please try again.",
				completionBased: "Score basé sur la réussite de la communication",
				infoCollection: "et la collecte d'informations",
				items: "éléments de la liste de contrôle",
				levelGuide: "Score basé sur le niveau de professionnalisme : Faible (25%), Moyen (50%), Bon (75%), Excellent (100%)"
			}
		},
		career: {
			matches: {
				title: "Top 5 des correspondances de carrière",
				whatTheyDo: "Que font-ils?",
				whyMatch: "Pourquoi c'est une bonne correspondance"
			},
			discussion: {
				title: "Discussion sur le parcours professionnel",
				chosenPath: "Parcours professionnel choisi"
			},
			roles: {
				coach: "Conseiller en carrière",
				user: "Utilisateur",
				interviewer: "Intervieweur",
				interviewee: "Candidat"
			},
			questionSummary: "Résumé de la liste des questions"
		},
		communication: {
			chat: {
				history: "Historique des messages",
				noTranscript: "La transcription n'est pas incluse pour cette session."
			},
			comments: "Commentaires notables"
		},
		confidentiality: {
			notice: "Avis de confidentialité",
			title: "Confidentialité et partage des données",
			reminder: "Rappel de confidentialité",
			responsibility:
				"Il est de votre responsabilité de protéger la confidentialité de votre employeur, de vos collègues et de vos informations personnelles.",
			sensitive: "Ne divulguez aucune information sensible",
			permission: "concernant l'entreprise, ses opérations, ses employés ou vos informations personnelles sans autorisation explicite.",
			dataSharing: {
				title: "Partage des données",
				settings: "Pour ce devoir, vos administrateurs ont défini les paramètres de partage suivants :",
				transcript: {
					shared: "La transcription complète sera partagée",
					notShared: "La transcription complète ne sera pas partagée"
				},
				audio: {
					shared: "L'enregistrement audio sera partagé",
					notShared: "L'enregistrement audio ne sera pas partagé"
				},
				name: {
					shared: "Le nom et le prénom seront partagés",
					notShared: "Le nom et le prénom ne seront pas partagés"
				}
			},
			agreement: {
				agree: "Accepter la confidentialité",
				continue: "Accepter et continuer",
				disagree: "Refuser"
			}
		},
		submitDialog: {
			title: "Confirmer et soumettre",
			description:
				"Êtes-vous sûr de vouloir confirmer et soumettre ce résumé d'appel? Une fois soumis, vous ne pourrez pas annuler ou réessayer sauf si vous contactez notre équipe de support.",
			note: "Cela ne soumettra que la session actuellement sélectionnée.",
			confirm: "Soumettre",
			cancel: "Annuler"
		}
	}
};
