import React, { useState, useEffect } from "react";

const StatusBanner: React.FC<{ message: string }> = ({ message }) => {
	const [isVisible, setIsVisible] = useState(false);

	useEffect(() => {
		// Show the banner after a short delay
		const timer = setTimeout(() => setIsVisible(true), 500);

		// Clean up the timer
		return () => clearTimeout(timer);
	}, []);

	if (!isVisible || !message) return null;

	return (
		<div className="w-full bg-red-500" role="alert" aria-live="polite">
			<div className="container mx-auto px-4 py-2">
				<p className="text-white text-center">{message}</p>
			</div>
		</div>
	);
};

export default StatusBanner;
