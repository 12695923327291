import React, { useEffect, useState, useRef } from "react";

import { ChevronDownIcon } from "@heroicons/react/24/solid";

import { Assistant } from "../../../contexts/AppContext";

import { cn } from "../../../lib/utils";

import { useAppContext } from "../../../contexts/AppContext";

import { assistants } from "../../../constants/Assistants";

export const AssistantDropdown = ({
	activeAssistant,
	setActiveAssistant,

	handleAssistantChange
}: {
	activeAssistant: Assistant;
	setActiveAssistant: (assistant: Assistant) => void;
	handleAssistantChange: (assistantId: string) => void;
}) => {
	const [isAssistantDropdownOpen, setIsAssistantDropdownOpen] = useState<boolean>(false);
	const dropdownRef = useRef<HTMLDivElement>(null);
	const { language } = useAppContext();

	const filteredAssistants = assistants.filter((assistant) => assistant.language === language);

	useEffect(() => {
		function handleClickOutside(event: MouseEvent) {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
				setIsAssistantDropdownOpen(false);
			}
		}

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	return (
		<div className="relative inline-block">
			<button
				onClick={() => setIsAssistantDropdownOpen(!isAssistantDropdownOpen)}
				className="ml-2 text-[#00a9af] underline inline-flex items-center"
			>
				{activeAssistant.name}
				<ChevronDownIcon className="h-4 w-4 ml-1" />
			</button>
			{isAssistantDropdownOpen && (
				<div ref={dropdownRef} className="absolute left-0 mt-2 py-0 w-48 bg-white rounded-md shadow-xl z-20 max-w-24">
					{filteredAssistants.map((assistant) => (
						<button
							key={assistant.id}
							className={cn(
								"block w-full text-left px-4 py-2 text-base",
								activeAssistant.id === assistant.id
									? "bg-[#00a9af] text-white font-semibold hover:bg-[#00a9af] hover:text-white"
									: "text-gray-700 hover:bg-gray-100"
							)}
							onClick={() => {
								handleAssistantChange(assistant.id);
								setIsAssistantDropdownOpen(false);
							}}
						>
							{assistant.name}
						</button>
					))}
				</div>
			)}
		</div>
	);
};
