import React, { useEffect, useMemo, useState } from "react";
import { MagnifyingGlassIcon, DocumentIcon } from "@heroicons/react/24/solid";
import TokenManager from "../../services/TokenManager";
import { useTranslation } from "../../components/Utility/UseTranslation";
type FAQType = "security" | "general";
type FAQAudience = "students" | "admins" | "both";

interface FAQ {
	question: string;
	answer: string;
	type: FAQType;
	who: FAQAudience;
	helpDocLink?: string;
}

const SupportPage: React.FC<{}> = () => {
	const { t } = useTranslation("supportPage");
	const faqs = useMemo<FAQ[]>(
		() => [
			{
				question: t("faqs.q1"),
				answer: t("faqs.a1"),
				type: "general",
				who: "admins"
			},
			{
				question: t("faqs.q2"),
				answer: t("faqs.a2"),
				type: "general",
				who: "admins",
				helpDocLink: "https://help.instagepractice.com/en/articles/9833546-how-to-create-a-reflection-assignment"
			},
			{
				question: t("faqs.q3"),
				answer: t("faqs.a3"),
				type: "general",
				who: "admins"
			},
			{
				question: t("faqs.q4"),
				answer: t("faqs.a4"),
				type: "security",
				who: "admins"
			},
			{
				question: t("faqs.q5"),
				answer: t("faqs.a5"),
				type: "security",
				who: "admins"
			},
			{
				question: t("faqs.q6"),
				answer: t("faqs.a6"),
				type: "general",
				who: "both"
			},
			{
				question: t("faqs.q7"),
				answer: t("faqs.a7"),
				type: "security",
				who: "both"
			},
			{
				question: t("faqs.q8"),
				answer: t("faqs.a8"),
				type: "security",
				who: "admins"
			},
			{
				question: t("faqs.q9"),
				answer: t("faqs.a9"),
				type: "security",
				who: "admins"
			},
			{
				question: t("faqs.q10"),
				answer: t("faqs.a10"),
				type: "general",
				who: "students"
			},
			{
				question: t("faqs.q11"),
				answer: t("faqs.a11"),
				type: "security",
				who: "both"
			},
			{
				question: t("faqs.q12"),
				answer: t("faqs.a12"),
				type: "general",
				who: "students",
				helpDocLink: "https://help.instagepractice.com/en/articles/9833736-how-to-run-a-reflection-session"
			}
		],
		[t]
	);

	const [expandedIndex, setExpandedIndex] = useState<number | null>(null);
	const [showAll, setShowAll] = useState<boolean>(false);
	const [searchQuery, setSearchQuery] = useState<string>("");
	const [selectedType, setSelectedType] = useState<FAQType | "all">("all");

	const toggleFAQ = (index: number) => {
		setExpandedIndex(expandedIndex === index ? null : index);
	};

	const toggleShowAll = () => {
		setShowAll(!showAll);
	};

	const filteredFaqs = faqs.filter(
		(faq) =>
			(selectedType === "all" || faq.type === selectedType) &&
			(faq.question.toLowerCase().includes(searchQuery.toLowerCase()) || faq.answer.toLowerCase().includes(searchQuery.toLowerCase())) &&
			(faq.who === "both" || (TokenManager.hasFacultyClearance() ? faq.who === "admins" : faq.who === "students"))
	);

	return (
		<>
			<p className="text-3xl font-bold text-[#16013e] mb-4">{t("header.pageTitle")}</p>
			<div className="rounded-lg border bg-card text-card-foreground mb-4 shadow-md">
				<div className="container mx-auto px-4 py-2">
					<h1 className="text-xl font-semibold text-primary-text font-plus-jakarta mb-1 mt-1">{t("helpSection.title")}</h1>
					<p className="text-black mb-4">{t("helpSection.description")}</p>
					<div className="flex flex-row gap-2 items-center">
						<button
							className="inline-flex items-center justify-center whitespace-nowrap rounded-md font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none border border-input bg-background hover:bg-background hover:text-primary hover:border-primary h-10 px-4 py-2 text-base"
							onClick={() => window.Intercom("showMessages")}
							aria-label="Open live chat support"
						>
							{t("helpSection.liveChatButton")}
						</button>
						<p className="text-black align-middle">{t("helpSection.orText")}</p>
						<button
							className="inline-flex items-center justify-center whitespace-nowrap rounded-md font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none border border-input bg-background hover:bg-background hover:text-primary hover:border-primary h-10 px-4 py-2 text-base"
							onClick={() => (window.location.href = "mailto:team@instage.io")}
							aria-label="Send email to support team"
						>
							{t("helpSection.emailButton")}
						</button>
					</div>
					<p className="text-black align-middle mb-4 mt-4">{t("helpSection.supportInfo")}</p>
				</div>
			</div>

			<hr className="mb-4" />
			<div className="rounded-lg border bg-card text-card-foreground mb-4 shadow-md px-4 py-4">
				<h1 className="text-xl font-semibold text-primary-text font-plus-jakarta mb-2">{t("faqSection.title")}</h1>
				<div className="flex items-center mb-4">
					<div className="relative">
						<span className="absolute left-3 top-1/2 transform -translate-y-1/2">
							<MagnifyingGlassIcon className="h-5 w-5 text-gray-500" />
						</span>
						<input
							type="text"
							placeholder={t("faqSection.search.placeholder")}
							value={searchQuery}
							onChange={(e) => setSearchQuery(e.target.value)}
							className="pl-10 pr-4 py-2 border border-gray-300 rounded-md"
							aria-label="Search frequently asked questions"
						/>
					</div>
					<div className="ml-4">
						<div className="flex items-center">
							<label className="mr-2 w-auto">{t("faqSection.search.filterLabel")}</label>
							<select
								value={selectedType}
								onChange={(e) => setSelectedType(e.target.value as FAQType | "all")}
								className="border border-gray-300 rounded-md p-2"
								aria-label="Filter FAQ by type"
							>
								<option value="all">{t("faqSection.search.filterOptions.all")}</option>
								<option value="general">{t("faqSection.search.filterOptions.general")}</option>
								<option value="security">{t("faqSection.search.filterOptions.security")}</option>
							</select>
						</div>
					</div>
				</div>
				<div className="flex flex-col gap-4" style={{ maxHeight: showAll ? "400px" : "auto", overflowY: showAll ? "auto" : "visible" }}>
					{(showAll ? filteredFaqs : filteredFaqs.slice(0, 3)).map((faq, index) => (
						<div key={index} className="border border-gray-300 rounded-lg p-4 bg-white transition duration-300 hover:bg-gray-50">
							<button
								className={`w-full text-left font-semibold text-gray-800 flex justify-between items-center ${
									expandedIndex === index ? "text-primary" : ""
								}`}
								onClick={() => toggleFAQ(index)}
								aria-expanded={expandedIndex === index}
								aria-controls={`faq-answer-${index}`}
								aria-label={`FAQ question: ${faq.question}`}
							>
								{faq.question}
								<div className="flex items-center">
									{faq.helpDocLink && (
										<a
											href={faq.helpDocLink}
											target="_blank"
											rel="noopener noreferrer"
											className="ml-2"
											aria-label={`View help documentation for ${faq.question}`}
										>
											<div className="flex items-center text-primary hover:text-teal-600">
												<p className="mr-1 font-normal w-50 text-sm">{t("faqSection.helpDocLink")}</p>
												<DocumentIcon className="h-5 w-5 " />
											</div>
										</a>
									)}
									<span className={`ml-2 transition-transform duration-300 ${expandedIndex === index ? "rotate-0" : "-rotate-90"}`}>
										<svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
											<path
												fillRule="evenodd"
												d="M5.23 7.21a.75.75 0 011.06 0L10 10.44l3.71-3.23a.75.75 0 111.06 1.06l-4.25 3.5a.75.75 0 01-1.06 0l-4.25-3.5a.75.75 0 010-1.06z"
												clipRule="evenodd"
											/>
										</svg>
									</span>
								</div>
							</button>
							{expandedIndex === index && <p className="mt-2 text-gray-700">{faq.answer}</p>}
						</div>
					))}
				</div>
				{filteredFaqs.length > 3 && (
					<button
						className="mt-4 text-primary font-medium"
						onClick={toggleShowAll}
						aria-label={showAll ? "Show fewer FAQ items" : "Show all FAQ items"}
					>
						{showAll ? t("faqSection.viewControls.viewLess") : t("faqSection.viewControls.viewMore")}
					</button>
				)}
			</div>
		</>
	);
};

export default SupportPage;
