import React, { useEffect, useMemo, useRef } from "react";

import { AssignedSessionView } from "../../../types/AssignedSessionView";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";

import { Button } from "../../shadcn-ui/Button";
import { Input } from "../../shadcn-ui/Input";
import { Textarea } from "../../shadcn-ui/Textarea";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../shadcn-ui/Select";
import { useTranslation } from "../../Utility/UseTranslation";

import { JobPosting } from "../../../services/AssignmentService";
import JobTitleSelect from "../../AdminPages/CreateAssignment/Steps_Interview/JobTitleSelect";
import JobPostings from "../../AdminPages/CreateAssignment/Steps_Interview/JobPostings";

import { Loader2 } from "lucide-react";

import { Card, CardContent, CardHeader, CardTitle } from "../../shadcn-ui/Card";

import Spinner from "../../Utility/Spinner";

export const JobDetailsSection = ({
	selectedAssignedSession,
	isJobGenerated,
	jobTitle,
	jobDescription,
	handleJobTitleChange,
	handleJobDescriptionChange,
	handleGenerateJobDetails,
	handleJobSelection,
	jobPostings,
	showJobPost,
	handleToggleJobPost,
	isLoadingJobPosting,
	isGeneratingJob
}: {
	selectedAssignedSession: AssignedSessionView;
	isJobGenerated: boolean;
	jobTitle: string;
	jobDescription: string;
	handleJobTitleChange: (value: string) => void;
	handleJobDescriptionChange: (value: string) => void;
	handleGenerateJobDetails: () => void;
	handleJobSelection: (option: JobPosting) => void;
	jobPostings: JobPosting[];
	showJobPost: boolean;
	handleToggleJobPost: () => void;
	isLoadingJobPosting: boolean;
	isGeneratingJob: boolean;
}) => {
	const { t } = useTranslation("userSessionPage");

	// If it's not an interview, don't show anything
	if (selectedAssignedSession.interviewStageType === null) return null;

	// If job details haven't been generated yet, show the generate button
	if (!isJobGenerated) {
		return (
			<div className="mt-12">
				<div className="flex items-center justify-between mb-4">
					<h2 className="text-xl md:text-2xl font-semibold">{t("Job Details")}</h2>
					<Button variant="outline" size="sm" onClick={handleToggleJobPost}>
						{showJobPost ? t("Hide Job Post") : t("Show Job Post")}
					</Button>
				</div>
				<Card className="mb-6">
					<CardContent className="space-y-4">
						<div>
							<label htmlFor="jobTitle" className="block text-sm font-medium text-gray-700 mb-1">
								{t("Job Title")}
							</label>
							<JobTitleSelect value={jobTitle} onChange={handleJobTitleChange} />
							<JobPostings jobTitle={jobTitle} options={jobPostings} onSelect={handleJobSelection} />
						</div>
						<div>
							<label htmlFor="jobDescription" className="block text-sm font-medium text-gray-700 mb-1">
								{t("Job Description")}
							</label>
							<Textarea
								id="jobDescription"
								value={jobDescription}
								onChange={(e) => handleJobDescriptionChange(e.target.value)}
								className="min-h-[200px]"
							/>
						</div>
						<div className="flex justify-between items-center">
							<Button
								onClick={handleGenerateJobDetails}
								disabled={!jobTitle.trim() || !jobDescription.trim() || isGeneratingJob}
								className="w-full"
							>
								{isGeneratingJob ? (
									<>
										<Spinner />
										{t("Generating Job")}
									</>
								) : (
									t("Generate Job Details")
								)}
							</Button>

							{jobPostings && jobPostings.length > 0 && (
								<Select onValueChange={(index) => handleJobSelection(jobPostings[parseInt(index)])}>
									<SelectTrigger className="w-[200px]">
										<SelectValue placeholder={t("Select Job")} />
									</SelectTrigger>
									<SelectContent>
										{jobPostings.map((posting, index) => (
											<SelectItem key={index} value={index.toString()}>
												{posting.title}
											</SelectItem>
										))}
									</SelectContent>
								</Select>
							)}
						</div>

						{isLoadingJobPosting && (
							<div className="flex items-center gap-2">
								<Spinner />
								<span>{t("Loading Job Posting")}</span>
							</div>
						)}
					</CardContent>
				</Card>
			</div>
		);
	}

	const jobSections = [
		{
			title: t("Job Function"),
			content: selectedAssignedSession?.jobFunction,
			type: "text"
		},
		{
			title: t("Experience Level"),
			content: selectedAssignedSession?.jobExperienceLevel,
			type: "text"
		},
		{
			title: t("Required Skills"),
			content: selectedAssignedSession?.jobSkillsRequired,
			type: "list"
		},
		{
			title: t("Key Responsibilities"),
			content: selectedAssignedSession?.jobResponsibilities,
			type: "list"
		}
	];

	return (
		<>
			<div className="flex items-center justify-between mb-4">
				<h2 className="text-xl md:text-2xl font-semibold">{t("Job Details")}</h2>
				<Button variant="outline" size="sm" onClick={handleToggleJobPost}>
					{showJobPost ? t("Hide Job Post") : t("Show Job Post")}
				</Button>
			</div>
			{showJobPost && (
				<div className="space-y-6">
					{jobSections.map((section, index) => {
						// Skip rendering if content is empty or undefined
						if (!section.content || (Array.isArray(section.content) && section.content.length === 0)) {
							return null;
						}

						return (
							<Card key={index}>
								<CardHeader>
									<CardTitle className="text-lg font-semibold text-[#16013e]">{section.title}</CardTitle>
								</CardHeader>
								<CardContent className="pt-0">
									{section.type === "text" ? (
										<p className="text-gray-600">{section.content as string}</p>
									) : (
										<ul className="list-disc pl-5 space-y-1">
											{(section.content as string[]).map((item, idx) => (
												<li key={idx} className="text-gray-600">
													{item}
												</li>
											))}
										</ul>
									)}
								</CardContent>
							</Card>
						);
					})}
				</div>
			)}
		</>
	);
};
