"use client";

import * as React from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { DayPicker } from "react-day-picker";
import { isBefore, isAfter, startOfToday, max, min } from "date-fns";
import { fr, enCA } from "date-fns/locale";
import { cn } from "../../lib/utils";
import { buttonVariants } from "./Button";
import { useAppContext } from "../../contexts/AppContext";
export type CalendarProps = React.ComponentProps<typeof DayPicker> & {
	minDate?: Date;
	maxDate?: Date;
};

function Calendar({ className, classNames, showOutsideDays = true, minDate, maxDate, ...props }: CalendarProps) {
	// const today = startOfToday();
	const { language } = useAppContext();
	const locale = language === "fr" ? fr : enCA;
	// const disabledDays = React.useCallback(
	// 	(day: Date) => {
	// 		if (isBefore(day, max([today, minDate || today]))) return true; // Disable all days before max of today and minDate
	// 		if (maxDate && isAfter(day, maxDate)) return true;
	// 		return false;
	// 	},
	// 	[minDate, maxDate]
	// );

	const disabledDays = React.useCallback(
		(day: Date) => {
			const oldestDate = new Date(0); // First day of current year
			if (isBefore(day, max([oldestDate, minDate || oldestDate]))) return true;
			if (maxDate && isAfter(day, maxDate)) return true;
			return false;
		},
		[minDate, maxDate]
	);

	return (
		<DayPicker
			locale={locale}
			showOutsideDays={showOutsideDays}
			className={cn("p-3 w-full", className)}
			classNames={{
				months: "flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0 w-full",
				month: "space-y-4 w-full",
				caption: "flex justify-center pt-1 relative items-center w-full h-10",
				caption_label: "text-md font-medium absolute",
				nav: "space-x-1 flex items-center w-full justify-between",
				nav_button: cn(buttonVariants({ variant: "outline" }), "h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100"),
				nav_button_previous: "absolute left-1",
				nav_button_next: "absolute right-1",
				table: "w-full border-collapse space-y-1",
				head_row: "flex w-full",
				head_cell: "text-muted-foreground rounded-md w-full font-normal text-[0.8rem]",
				row: "flex w-full mt-2",
				cell: "text-center text-sm p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20 w-full",
				day: cn(buttonVariants({ variant: "ghost" }), "w-full p-0 font-normal aria-selected:opacity-100 hover:bg-[#e6f7f8]"),
				day_range_end: "day-range-end",
				day_selected:
					"bg-primary text-primary-foreground hover:bg-[primary] hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground",
				day_today: "bg-accent text-accent-foreground",
				day_outside:
					"day-outside text-muted-foreground opacity-50 aria-selected:bg-accent/50 aria-selected:text-muted-foreground aria-selected:opacity-30",
				day_disabled: "text-muted-foreground opacity-25",
				day_range_middle: "aria-selected:bg-accent aria-selected:text-accent-foreground",
				day_hidden: "invisible",
				...classNames
			}}
			components={{
				IconLeft: ({ ...props }) => <ChevronLeft className="h-4 w-4" />,
				IconRight: ({ ...props }) => <ChevronRight className="h-4 w-4" />
			}}
			disabled={disabledDays}
			{...props}
		/>
	);
}

Calendar.displayName = "Calendar";

export { Calendar };
