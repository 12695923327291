export const SessionLogLocale = {
	en: {
		title: "Session Log",
		forAssignment: "for Assignment",
		tableHeaders: {
			sessionId: "Session ID",
			clientName: "Client",
			userId: "User ID",
			assignmentId: "Assignment ID",
			startTime: "Start Time",
			endTime: "End Time",
			scheduleDescription: "Schedule Description",
			studentName: "Student Name",
			email: "Student Email",
			date: "Date",
			duration: "Duration",
			report: "Report",
			viewReport: "View Report"
		},
		metrics: {
			usage: "Usage",
			avgDuration: "Avg Duration",
			sessionsStarted: "Sessions Started",
			sessionsSubmitted: "Sessions Submitted",
			uniqueStudents: "Unique Students"
		}
	},
	fr: {
		title: "Journal des sessions",
		forAssignment: "pour l'affectation",
		tableHeaders: {
			sessionId: "ID de session",
			clientName: "Client",
			userId: "ID de l'utilisateur",
			assignmentId: "ID de l'assignation",
			startTime: "Heure de début",
			endTime: "Heure de fin",
			scheduleDescription: "Description du planning",
			studentName: "Nom de l'étudiant",
			email: "Email de l'étudiant",
			date: "Date",
			duration: "Durée",
			report: "Rapport",
			viewReport: "Voir le rapport"
		},
		metrics: {
			usage: "Usage",
			avgDuration: "Durée moyenne",
			sessionsStarted: "Sessions commencées",
			sessionsSubmitted: "Sessions soumises",
			uniqueStudents: "Étudiants uniques"
		}
	}
};
