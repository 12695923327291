import React, { useState, useEffect } from "react";
import {
	RenderOverview,
	RenderExpectations,
	RenderCompetencyProgress,
	RenderSatisfaction,
	RenderGoalProgress,
	RenderGoals,
	RenderFinalReview,
	RenderNoteworthyComments,
	RenderNoChatHistory,
	RenderPurpose,
	RenderInterviewAssessment,
	RenderFrontlineAssessment,
	RenderQuestionListSummary,
	RenderFrontlineScore,
	RenderCareerPathAssessment,
	RenderCareerPathQuestionList,
	RenderMostInterestedIn
} from "./reportUtility";
import { SessionView } from "../../types/SessionView";
import { ActivityType } from "../../schemas/ActivityType";
import ChatHistory from "./ChatHistory";
import { AssignedSessionService } from "../../services/AssignedSessionService";
import { Button } from "../shadcn-ui/Button";
import { useAppContext } from "../../contexts/AppContext";
import { useAuth } from "../../contexts/AuthContext";
import { isDateNull } from "./isDateNull";
import { CommentService } from "../../services/CommentService";
import { ArrowPathIcon } from "@heroicons/react/24/solid";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { getSessionType } from "../../utils/getSessionType";
import posthog from "posthog-js";
import { SessionType } from "../../schemas/SessionType";
import ConfirmationModal from "./ConfirmationModal";
import { useTranslation } from "./UseTranslation";

interface ReportProps {
	selectedUserSession: SessionView;
	userName: string;
	satisfactionData: number[];
	competencyData: { [key: string]: number[] };
	showRestartButton?: boolean;
	onRestartClick?: () => void;
}

const ReportSections = ({
	selectedUserSession,
	userId,
	handleAddComment,
	submitted,
	satisfactionData,
	competencyData
}: {
	selectedUserSession: SessionView;
	userId: string;
	handleAddComment: (content: string, title: string) => void;
	submitted: boolean;
	satisfactionData: number[];
	competencyData: { [key: string]: number[] };
}) => {
	const sections = [];
	const sessionType = getSessionType(selectedUserSession.experienceType);
	const { t } = useTranslation("userSessionPage");

	if (sessionType === SessionType.INTERVIEW) {
		// Interview-specific sections
		sections.push(...RenderInterviewAssessment(selectedUserSession, userId || "", handleAddComment, submitted));
		sections.push(...RenderQuestionListSummary(selectedUserSession, userId || "", handleAddComment, submitted));
		sections.push(<div key="noteworthyComments">{RenderNoteworthyComments(selectedUserSession, userId || "", handleAddComment)}</div>);
	} else if (sessionType === SessionType.FRONTLINE) {
		// Add frontline score section first
		if (selectedUserSession.showGradesToStudents) {
			sections.push(<div key="frontlineScore">{RenderFrontlineScore(selectedUserSession, userId || "", handleAddComment)}</div>);
		}
		sections.push(...RenderFrontlineAssessment(selectedUserSession, handleAddComment, submitted));
		sections.push(<div key="noteworthyComments">{RenderNoteworthyComments(selectedUserSession, userId || "", handleAddComment)}</div>);
	} else if (sessionType === SessionType.CAREER_PATH) {
		// sections.push(...renderQuestionListSummary(selectedUserSession, userId || "", handleAddComment, submitted));
		if (selectedUserSession.activityType === ActivityType.FIRST) {
			sections.push(<div key="overview">{RenderOverview(selectedUserSession, userId || "", handleAddComment)}</div>);
			sections.push(RenderCareerPathAssessment(selectedUserSession, handleAddComment, submitted));
			sections.push(RenderCareerPathQuestionList(selectedUserSession, handleAddComment, submitted));
			sections.push(RenderGoals(selectedUserSession, userId || "", handleAddComment));
			sections.push(<div key="noteworthyComments">{RenderNoteworthyComments(selectedUserSession, userId || "", handleAddComment)}</div>);
		} else {
			sections.push(<div key="overview">{RenderOverview(selectedUserSession, userId || "", handleAddComment)}</div>);
			sections.push(<div key="MostInterestedIn">{RenderMostInterestedIn(selectedUserSession, userId || "", handleAddComment)}</div>);
			sections.push(<div key="noteworthyComments">{RenderNoteworthyComments(selectedUserSession, userId || "", handleAddComment)}</div>);
		}
	} else {
		// Existing reflection-type sections
		const isCourse = sessionType === SessionType.REFLECTION_COURSE;
		const isJobSearch = sessionType === SessionType.JOB_SEARCH;

		switch (selectedUserSession.activityType) {
			case ActivityType.FIRST:
				sections.push(
					<div key="overview">{RenderOverview(selectedUserSession, userId || "", handleAddComment)}</div>,
					!isJobSearch && <div key="expectations">{RenderExpectations(selectedUserSession, userId || "", handleAddComment)}</div>,
					!isCourse && !isJobSearch && <div key="purpose">{RenderPurpose(selectedUserSession, userId || "", handleAddComment)}</div>,
					!isJobSearch && (
						<div key="competencyProgress">
							{RenderCompetencyProgress(selectedUserSession, userId || "", competencyData, handleAddComment)}
						</div>
					),
					!isCourse && <div key="goals">{RenderGoals(selectedUserSession, userId || "", handleAddComment)}</div>,
					<div key="noteworthyComments">{RenderNoteworthyComments(selectedUserSession, userId || "", handleAddComment)}</div>
				);
				break;

			case ActivityType.MIDDLE:
				sections.push(
					isJobSearch && <div key="overview">{RenderOverview(selectedUserSession, userId || "", handleAddComment)}</div>,
					!isJobSearch && (
						<div key="satisfaction">{RenderSatisfaction(selectedUserSession, userId || "", satisfactionData, handleAddComment)}</div>
					),
					!isCourse && <div key="goalProgress">{RenderGoalProgress(selectedUserSession, userId || "", handleAddComment)}</div>,
					!isJobSearch && (
						<div key="competencyProgress">
							{RenderCompetencyProgress(selectedUserSession, userId || "", competencyData, handleAddComment)}
						</div>
					),
					!isCourse && !isJobSearch && <div key="purpose">{RenderPurpose(selectedUserSession, userId || "", handleAddComment)}</div>,
					!isCourse && <div key="goals">{RenderGoals(selectedUserSession, userId || "", handleAddComment)}</div>,
					<div key="noteworthyComments">{RenderNoteworthyComments(selectedUserSession, userId || "", handleAddComment)}</div>
				);
				break;

			case ActivityType.LAST:
				sections.push(
					<div key="satisfaction">{RenderSatisfaction(selectedUserSession, userId || "", satisfactionData, handleAddComment)}</div>,
					!isCourse && <div key="goalProgress">{RenderGoalProgress(selectedUserSession, userId || "", handleAddComment)}</div>,
					<div key="competencyProgress">
						{RenderCompetencyProgress(selectedUserSession, userId || "", competencyData, handleAddComment)}
					</div>,
					!isCourse && <div key="purpose">{RenderPurpose(selectedUserSession, userId || "", handleAddComment)}</div>,
					<div key="finalReview">{RenderFinalReview(selectedUserSession, userId || "", handleAddComment)}</div>,
					<div key="noteworthyComments">{RenderNoteworthyComments(selectedUserSession, userId || "", handleAddComment)}</div>
				);
				break;
		}
	}

	return <>{sections.filter(Boolean)}</>;
};

const StickyFooter = ({
	submitted,
	userId,
	selectedUserSession,
	loading,
	setIsConfirmationModalVisible,
	debugMode,
	allConfirmed,
	handleUnsubmit,
	showRestartButton,
	onRestartClick
}: {
	submitted: boolean;
	userId: string;
	selectedUserSession: SessionView;
	loading: boolean;
	setIsConfirmationModalVisible: (visible: boolean) => void;
	debugMode: boolean;
	allConfirmed: boolean;
	handleUnsubmit: () => void;
	showRestartButton?: boolean;
	onRestartClick?: () => void;
}) => {
	const { t } = useTranslation("userSessionPage");
	return (
		<div className="fixed bottom-0 left-0 right-0 py-6 bg-white border-t border-gray-200">
			<div className="container flex gap-2 items-center mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl">
				<div className="flex gap-2">
					{!submitted && userId === selectedUserSession.userId && (
						<>
							<Button
								name="Confirm & Submit"
								title="Confirm & Submit"
								aria-label="Confirm & Submit"
								variant="default"
								onClick={() => setIsConfirmationModalVisible(true)}
								disabled={loading || submitted}
							>
								<CheckCircleIcon className="h-5 w-5 mr-2" />
								{t("general.confirmAndSubmit")}
							</Button>
						</>
					)}
					{debugMode && (
						<>
							{allConfirmed && submitted && <p className="self-center">User submitted</p>}
							{!allConfirmed && submitted && <p className="self-center">Auto submitted</p>}
							<Button
								name="Unsubmit"
								title="Unsubmit"
								aria-label="Unsubmit"
								variant="outline"
								onClick={handleUnsubmit}
								disabled={loading || !submitted}
							>
								{t("general.unsubmit")}
							</Button>
						</>
					)}
				</div>
				{showRestartButton && (
					<Button
						variant="outlineWarning"
						name="Footer Restart Call"
						aria-label="Footer Restart Call"
						onClick={onRestartClick}
						disabled={loading}
					>
						<ArrowPathIcon className="h-5 w-5 md:mr-2" />
						<span className="hidden md:inline">{t("general.restartCall")}</span>
					</Button>
				)}
			</div>
		</div>
	);
};

export const Report: React.FC<ReportProps> = ({
	selectedUserSession,
	userName,
	satisfactionData,
	competencyData,
	showRestartButton,
	onRestartClick
}) => {
	const { debugMode } = useAppContext();
	const { userId, instageUser } = useAuth();
	const [loading, setLoading] = useState(false);
	const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(false);

	const submitted = !isDateNull(selectedUserSession.submittedAt);
	const allConfirmed = selectedUserSession.userConfirmed;

	const frontEggId = instageUser?.fronteggId;
	const { t } = useTranslation("userSessionPage");

	useEffect(() => {
		if (!submitted) {
			try {
				posthog.capture("MINOR: User Opened Unsubmitted Report", { userId: frontEggId, timestamp: new Date().toISOString() });
			} catch (err) {
				console.error("Error capturing event 'MINOR: User Opened Unsubmitted Report':", err);
			}
		}
	}, [submitted, frontEggId]);

	const handleSubmit = async () => {
		setLoading(true);
		await AssignedSessionService.submitSession(selectedUserSession.sessionId, selectedUserSession.userId, 100, true);
		setLoading(false);
		try {
			posthog.capture("MAJOR_EVENT: Confirmed & Submitted Clicked Successfully", {
				userId: instageUser?.fronteggId,
				timestamp: new Date().toISOString()
			});
		} catch (err) {
			console.error("Error capturing event 'MAJOR_EVENT: Confirmed & Submitted Clicked Successfully':", err);
		}
		window.location.reload();
	};

	const handleUnsubmit = async () => {
		setLoading(true);
		await AssignedSessionService.unSubmitSession(selectedUserSession.sessionId, selectedUserSession.userId);
		setLoading(false);
		try {
			posthog.capture("MAJOR_EVENT: Unsubmitted Report", { userId: instageUser?.fronteggId, timestamp: new Date().toISOString() });
		} catch (err) {
			console.error("Error capturing event 'MAJOR_EVENT: Unsubmitted Report':", err);
		}
		window.location.reload();
	};

	const handleAddComment = async (content: string, title: string) => {
		// console.log("Adding comment:", content, title);
		const data = await CommentService.createComment(title, content, selectedUserSession.assignedSessionId);
		console.log("Comment created:", data);
	};

	return (
		<>
			{loading && <div>Loading...</div>}

			<ReportSections
				selectedUserSession={selectedUserSession}
				userId={userId || ""}
				handleAddComment={handleAddComment}
				submitted={submitted}
				satisfactionData={satisfactionData}
				competencyData={competencyData}
			/>

			{selectedUserSession.includeTranscript && (
				<ChatHistory messages={selectedUserSession.chatHistory || []} userName={userName} recordingUrls={selectedUserSession.recordingUrls} />
			)}
			{!selectedUserSession.includeTranscript && RenderNoChatHistory()}
			{isConfirmationModalVisible && (
				<ConfirmationModal
					title={t("submitDialog.title")}
					description={t("submitDialog.description")}
					note={t("submitDialog.note")}
					onConfirm={handleSubmit}
					isOpen={isConfirmationModalVisible}
					onClose={() => setIsConfirmationModalVisible(false)}
				/>
			)}
			{/* Sticky footer */}
			{(!submitted || debugMode) && (
				<StickyFooter
					submitted={submitted}
					userId={userId || ""}
					selectedUserSession={selectedUserSession}
					loading={loading}
					setIsConfirmationModalVisible={setIsConfirmationModalVisible}
					debugMode={debugMode}
					allConfirmed={allConfirmed}
					handleUnsubmit={handleUnsubmit}
					showRestartButton={showRestartButton}
					onRestartClick={onRestartClick}
				/>
			)}
		</>
	);
};
