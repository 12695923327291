import React, { useEffect, useMemo } from "react";
import { SessionView } from "../../../types/SessionView";
import { ExtendedColumnDef } from "../../shadcn-ui/DataTable";
import "react-datepicker/dist/react-datepicker.css";
import BackButton from "../../Utility/BackButton";
import { Button } from "../../shadcn-ui/Button";
import { DataTable } from "../../shadcn-ui/DataTable";
import { NavigateService } from "../../../services/NavigateService";
import { useTranslation } from "../../Utility/UseTranslation";
import { useSessionLog } from "../../../hooks/useSessionLog";
import SessionMetrics from "./SessionMetrics";
import ClientSelector from "./ClientSelector";
import DateRangeFilter from "./DateRangeFilter";
import { Alert, AlertDescription } from "../../shadcn-ui/Alert";
import Spinner from "../../Utility/Spinner";
import { DateRange } from "react-day-picker";

const SessionLog: React.FC = () => {
	const {
		sessions,
		loading,
		error,
		sorting,
		setSorting,
		columnFilters,
		setColumnFilters,
		dateRange,
		setDateRange,
		selectedClient,
		handleClientChange,
		sessionStats,
		paginationState,
		handlePaginationChange,
		pageCount,
		clientFilters,
		debugMode,
		assignmentId,
		statsLoading
	} = useSessionLog();

	const { t } = useTranslation("sessionLog");

	const columns = useMemo<ExtendedColumnDef<SessionView>[]>(
		() => [
			{
				header: t("tableHeaders.sessionId"),
				accessorKey: "sessionId",
				initiallyHidden: true
			},
			{
				header: "Client",
				accessorKey: "clientName",
				initiallyHidden: true
			},
			{
				header: t("tableHeaders.userId"),
				accessorKey: "userId",
				initiallyHidden: true
			},
			{
				header: t("tableHeaders.assignmentId"),
				accessorKey: "assignmentId",
				initiallyHidden: true
			},
			{
				header: t("tableHeaders.startTime"),
				accessorKey: "createdAt",
				initiallyHidden: true,
				cell: ({ getValue }) => {
					const date = new Date(getValue() as string);
					return date
						? date.toLocaleString("en-US", {
								weekday: "short",
								year: "numeric",
								month: "short",
								day: "numeric",
								hour: "2-digit",
								minute: "2-digit"
						  })
						: "n/a";
				}
			},
			{
				header: t("tableHeaders.endTime"),
				accessorKey: "submittedAt",
				initiallyHidden: true,
				cell: ({ getValue }) => {
					const date = new Date(getValue() as string);
					return date.getTime() === 0 ? "n/a" : date.toLocaleString();
				}
			},
			{
				header: t("tableHeaders.scheduleDescription"),
				accessorKey: "scheduleDescription"
			},
			{
				header: t("tableHeaders.studentName"),
				accessorKey: "firstName"
			},
			{
				header: t("tableHeaders.email"),
				accessorKey: "email"
			},
			{
				header: t("tableHeaders.date"),
				accessorKey: "scheduleDate",
				cell: ({ getValue }) => (getValue() as Date)?.toLocaleDateString()
			},
			{
				header: t("tableHeaders.duration"),
				accessorKey: "minutesUsed",
				cell: ({ getValue }) => {
					const totalMinutes = getValue() as number;
					if (!totalMinutes) return "n/a";
					const minutes = Math.floor(totalMinutes);
					const seconds = (totalMinutes - minutes) * 60;
					return seconds > 0 ? `${minutes}m ${seconds.toFixed(0)}s` : `${minutes}m`;
				}
			},
			{
				header: t("tableHeaders.report"),
				cell: ({ row }) => (
					<div className="flex gap-2">
						{new Date(row.original.submittedAt).getTime() !== 0 && (
							<Button variant="link" onClick={() => NavigateService.navToUserReport(row.original.assignmentId, row.original.userId)}>
								{t("tableHeaders.viewReport")}
							</Button>
						)}
					</div>
				)
			}
		],
		[t]
	);

	return (
		<div>
			<BackButton />

			<h1 className="text-3xl font-bold text-[#16013e] mb-6">
				{t("title")} {assignmentId ? `${t("forAssignment")} ${assignmentId}` : ""}
			</h1>

			{statsLoading ? (
				<div className="flex justify-center items-center h-full">
					<Spinner />
				</div>
			) : (
				<SessionMetrics sessionStats={sessionStats} />
			)}

			<div className="flex gap-4 mb-4">
				<DateRangeFilter dateRange={dateRange} onDateRangeChange={setDateRange} />
				{debugMode && <ClientSelector onClientChange={handleClientChange} clientFilters={clientFilters} />}
			</div>

			{error && (
				<Alert variant="destructive" className="mb-4">
					<AlertDescription>{error}</AlertDescription>
				</Alert>
			)}

			{loading ? (
				<div className="flex justify-center items-center h-full">
					<Spinner />
				</div>
			) : (
				<DataTable
					pageCount={pageCount}
					paginationState={paginationState}
					onPaginationChange={handlePaginationChange}
					columns={columns}
					data={sessions}
				/>
			)}
		</div>
	);
};

export default SessionLog;
