import React from "react";
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from "../../shadcn-ui/Select";

interface Client {
	clientId: string;
	clientName: string;
}

interface ClientSelectorProps {
	onClientChange: (value: string) => void;
	clientFilters?: Client[];
}

const ClientSelector: React.FC<ClientSelectorProps> = ({ onClientChange, clientFilters }) => {
	return (
		<div className="flex justify-between items-center mb-4">
			<Select onValueChange={onClientChange}>
				<SelectTrigger className="w-[200px]">
					<SelectValue placeholder="Select Client" />
				</SelectTrigger>
				<SelectContent>
					<SelectGroup>
						<SelectLabel>Clients</SelectLabel>
						<SelectItem value="all">All Clients</SelectItem>
						{clientFilters?.map((client) => (
							<SelectItem key={client.clientId} value={client.clientId}>
								{client.clientName}
							</SelectItem>
						))}
					</SelectGroup>
				</SelectContent>
			</Select>
		</div>
	);
};

export default ClientSelector;
