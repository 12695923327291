import { useEffect, useState, useCallback } from "react";

import { Input } from "../shadcn-ui/Input";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter, DialogTrigger } from "../shadcn-ui/Dialog";
import { Button } from "../shadcn-ui/Button";
import { Label } from "../shadcn-ui/Label";
import { WEBHOOK_EVENTS, WEBHOOK_STATUSES, WebhookEvent } from "../../types/webhookTypes";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../shadcn-ui/Table";
import { Switch } from "../shadcn-ui/Switch";
import { WebhookConfigService, WebhookConfig, DeliveryLog, CreateWebhookPayload } from "../../services/WebhookConfigService";
import { Badge } from "../shadcn-ui/Badge";
import { Card, CardHeader, CardTitle } from "../shadcn-ui/Card";
import { useTranslation } from "../Utility/UseTranslation";

const SecretDisplay = ({ secret }: { secret: string }) => {
	const [revealed, setRevealed] = useState(false);
	const { t } = useTranslation("webhookManagement");

	const stars = "*".repeat(secret.length);
	const display = revealed ? secret : stars;

	console.log("Name", t("form.actions.hide"));
	return (
		<div className="flex items-center gap-2">
			<span>{display}</span>
			<Button variant="ghost" size="sm" onClick={() => setRevealed((prev) => !prev)}>
				{revealed ? t("form.actions.hide") : t("form.actions.show")}
			</Button>
		</div>
	);
};

function DeliveryLogTable({ deliveryLogs }: { deliveryLogs: DeliveryLog[] }) {
	const { t } = useTranslation("webhookManagement");
	return (
		<Card className="p=2 text-black">
			<Table>
				<TableHeader>
					<TableRow>
						<TableHead>{t("table.deliveryLogs.createdAt")}</TableHead>
						<TableHead>{t("table.deliveryLogs.payload")}</TableHead>
						<TableHead>{t("table.deliveryLogs.response")}</TableHead>
						<TableHead>{t("table.deliveryLogs.statusCode")}</TableHead>
						<TableHead>{t("table.deliveryLogs.errorMessage")}</TableHead>
					</TableRow>
				</TableHeader>
				<TableBody>
					{deliveryLogs.map((log) => (
						<TableRow key={log.id}>
							<TableCell>{log.createdAt}</TableCell>
							<TableCell className="text-gray-500 max-h-40 overflow-y-auto">
								<pre className="max-h-40 overflow-y-auto">{JSON.stringify(log.payload, null, 2)}</pre>
							</TableCell>
							<TableCell className="text-gray-500 max-h-40 overflow-y-auto">
								<pre className="max-h-40 overflow-y-auto">{JSON.stringify(log.response, null, 2)}</pre>
							</TableCell>
							<TableCell>
								<Badge
									variant={
										log.statusCode >= 200 && log.statusCode < 300
											? "high"
											: log.statusCode >= 300 && log.statusCode < 400
											? "new"
											: log.statusCode >= 400 && log.statusCode < 500
											? "medium"
											: "low"
									}
								>
									{log.statusCode}
								</Badge>
							</TableCell>
							<TableCell>{log.errorMessage}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</Card>
	);
}

function WebhookTable({
	webhooks,
	onEdit,
	onDelete,
	onSelect
}: {
	webhooks: WebhookConfig[];
	onEdit: (webhook: WebhookConfig) => void;
	onDelete: (webhook: WebhookConfig) => void;
	onSelect: (webhook: WebhookConfig) => void;
}) {
	const { t } = useTranslation("webhookManagement");
	return (
		<Card className="p-2 text-black">
			<Table>
				<TableHeader>
					<TableRow>
						<TableHead>{t("table.name")}</TableHead>
						<TableHead>{t("table.url")}</TableHead>
						<TableHead>{t("table.events")}</TableHead>
						<TableHead>{t("table.status")}</TableHead>
						<TableHead>{t("table.actions")}</TableHead>
					</TableRow>
				</TableHeader>
				<TableBody>
					{webhooks.map((webhook) => (
						<TableRow key={webhook.id}>
							<TableCell onClick={() => onSelect(webhook)}>
								<Button variant="link" onClick={() => onSelect(webhook)}>
									{webhook.name}
								</Button>
							</TableCell>
							<TableCell>{webhook.url}</TableCell>
							<TableCell>{webhook.events.join(", ")}</TableCell>
							<TableCell>
								<Badge variant={webhook.status === "active" ? "high" : "low"}>{webhook.status}</Badge>
							</TableCell>
							<TableCell>
								<Button variant="outline" onClick={() => onEdit(webhook)}>
									{t("form.actions.edit")}
								</Button>
								<Button variant="destructive" onClick={() => onDelete(webhook)}>
									{t("form.actions.delete")}
								</Button>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</Card>
	);
}

function WebhookCard({ webhook, onTest }: { webhook: WebhookConfig; onTest: (webhook: WebhookConfig) => void }) {
	const { t } = useTranslation("webhookManagement");
	return (
		<Card className="p-2 text-black">
			<CardHeader>
				<CardTitle>{webhook.name}</CardTitle>
			</CardHeader>
			<hr></hr>
			<div className="flex items-center gap-2 p-4">
				<span>POST</span>
				<Input value={webhook.url} disabled />
				<Button variant="outline" size="sm" onClick={() => onTest(webhook)}>
					{t("form.actions.test")}
				</Button>
			</div>
			<div className="px-4 pb-4">
				<p>
					{t("table.events")}:{" "}
					{webhook.events.map((event, index) => (
						<Badge key={event} variant="outline">
							{event}
							{index < webhook.events.length - 1 ? ", " : ""}
						</Badge>
					))}
					<br></br>
					{t("table.status")}: <Badge variant={webhook.status === "active" ? "high" : "low"}>{webhook.status}</Badge>
				</p>
			</div>
		</Card>
	);
}

function WebhookForm({
	webhook,
	onSubmit,
	open,
	setOpen,
	onOpen,
	isEdit
}: {
	webhook: WebhookConfig | null;
	onSubmit: (webhook: WebhookConfig) => void;
	open: boolean;
	setOpen: (open: boolean) => void;
	onOpen?: () => void;
	isEdit: boolean;
}) {
	const { t } = useTranslation("webhookManagement");
	const [webhookData, setWebhookData] = useState<WebhookConfig>(
		webhook ?? {
			name: "",
			url: "",
			events: [],
			headers: {}
		}
	);

	const toggleEvent = (event: WebhookEvent) => {
		setWebhookData((prev) => ({
			...prev,
			events: prev.events.includes(event) ? prev.events.filter((e) => e !== event) : [...prev.events, event]
		}));
	};

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			{!isEdit && (
				<DialogTrigger asChild>
					<Button onClick={onOpen}>{t("connect")}</Button>
				</DialogTrigger>
			)}
			<DialogContent>
				<DialogHeader>
					<DialogTitle>{isEdit ? t("edit") : t("connectNew")}</DialogTitle>
				</DialogHeader>
				<div className="py-4 space-y-4">
					<div>
						<Label htmlFor="webhookName">{t("form.name")}</Label>
						<Input
							id="webhookName"
							placeholder={t("form.namePlaceholder")}
							value={webhookData.name}
							onChange={(e) => setWebhookData({ ...webhookData, name: e.target.value })}
						/>
					</div>
					<div>
						<Label htmlFor="webhookUrl">{t("form.url")}</Label>
						<Input
							id="webhookUrl"
							placeholder={t("form.urlPlaceholder")}
							value={webhookData.url}
							onChange={(e) => setWebhookData({ ...webhookData, url: e.target.value })}
						/>
					</div>
					<div>
						<Label>{t("form.events")}</Label>
						<div className="grid grid-cols-2 gap-2 mt-2">
							{Object.values(WEBHOOK_EVENTS).map((event) => (
								<Button
									key={event}
									variant={webhookData.events.includes(event) ? "default" : "outline"}
									onClick={() => toggleEvent(event)}
									className="justify-start"
								>
									{event}
								</Button>
							))}
						</div>
					</div>

					{isEdit && (
						<div>
							<Label>{t("form.secret")}</Label>
							<SecretDisplay secret={webhookData.secret ?? ""} />
						</div>
					)}
					{isEdit && (
						<div>
							<Label>{t("form.status")}</Label>
							<br></br>
							<Switch
								checked={webhookData.status === "active"}
								onCheckedChange={() =>
									setWebhookData({ ...webhookData, status: webhookData.status === "active" ? "inactive" : "active" })
								}
							/>
							{webhookData.status}
						</div>
					)}
				</div>
				<DialogFooter>
					<Button variant="outline" onClick={() => setOpen(false)}>
						{t("form.actions.cancel")}
					</Button>
					<Button onClick={() => onSubmit(webhookData)}>{isEdit ? t("form.actions.update") : t("form.actions.connect")}</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
}

export function useWebhookManagement() {
	const [webhooks, setWebhooks] = useState<WebhookConfig[]>([]);
	const [selectedWebhook, setSelectedWebhook] = useState<WebhookConfig | null>(null);
	const [open, setOpen] = useState(false);
	const [editOpen, setEditOpen] = useState(false);

	const [deliveryLogs, setDeliveryLogs] = useState<DeliveryLog[]>([]);

	const getWebhooks = async () => {
		const webhooks = await WebhookConfigService.getWebhooks({});
		setWebhooks(webhooks);
	};

	const handleEdit = (webhook: WebhookConfig) => {
		if (!webhook.id) {
			console.error("Webhook ID is undefined");
			return;
		}
		setSelectedWebhook(webhook);
		setEditOpen(true);
	};

	const handleDelete = async (webhook: WebhookConfig) => {
		if (!webhook.id) {
			console.error("Webhook ID is undefined");
			return;
		}
		await WebhookConfigService.deleteWebhook(webhook.id);
		getWebhooks();
	};

	const handleSubmit = async (webhook: CreateWebhookPayload) => {
		setOpen(false);
		await WebhookConfigService.createWebhook({
			name: webhook.name,
			url: webhook.url,
			events: webhook.events,
			headers: {}
		});
		getWebhooks();
	};

	const handleUpdate = async (webhook: WebhookConfig) => {
		if (!webhook.id) {
			console.error("Webhook ID is undefined");
			return;
		}
		setEditOpen(false);
		await WebhookConfigService.updateWebhook(webhook.id, {
			name: webhook.name,
			url: webhook.url,
			events: webhook.events,
			status: webhook.status,
			headers: {}
		});
		setSelectedWebhook(null);
		getWebhooks();
	};

	const handleCreateNewWebhook = () => {
		setSelectedWebhook(null);
		setOpen(true);
	};

	const handleTest = async (webhook: WebhookConfig) => {
		if (!webhook.id) {
			console.error("Webhook ID is undefined");
			return;
		}
		await WebhookConfigService.testWebhook(webhook.id);
		getDeliveryLogs();
	};

	const handleSelect = (webhook: WebhookConfig) => {
		console.log("Selected webhook:", webhook);
		setSelectedWebhook(webhook);
	};

	const clearSelectedWebhook = () => {
		setSelectedWebhook(null);
		setDeliveryLogs([]);
	};

	const handleSetEditOpen = (open: boolean) => {
		setEditOpen(open);
		if (!open) {
			setSelectedWebhook(null);
		}
	};

	const getDeliveryLogs = useCallback(async () => {
		if (!selectedWebhook) {
			return;
		}
		if (!selectedWebhook.id) {
			console.error("Webhook ID is undefined");
			return;
		}
		const deliveryLogs = await WebhookConfigService.getWebhookDeliveries(selectedWebhook.id);
		console.log("Delivery logs:", deliveryLogs);
		setDeliveryLogs(deliveryLogs);
	}, [selectedWebhook]);

	useEffect(() => {
		getWebhooks();
	}, []);

	useEffect(() => {
		if (selectedWebhook) {
			getDeliveryLogs();
		}
	}, [selectedWebhook, getDeliveryLogs]);

	return {
		webhooks,
		selectedWebhook,
		open,
		editOpen,
		setOpen,
		handleSetEditOpen,
		handleEdit,
		handleDelete,
		handleSubmit,
		handleUpdate,
		handleCreateNewWebhook,
		handleTest,
		handleSelect,
		clearSelectedWebhook,
		deliveryLogs
	};
}

export default function WebhookManagement() {
	const { t } = useTranslation("webhookManagement");
	const {
		webhooks,
		selectedWebhook,
		open,
		editOpen,
		setOpen,
		handleSetEditOpen,
		handleEdit,
		handleDelete,
		handleSubmit,
		handleUpdate,
		handleCreateNewWebhook,
		handleTest,
		handleSelect,
		clearSelectedWebhook,
		deliveryLogs
	} = useWebhookManagement();

	return (
		<div className="p-6">
			<div className="flex justify-between items-center mb-6">
				<h1 className="text-2xl font-bold">{t("title")}</h1>
				<WebhookForm webhook={null} onSubmit={handleSubmit} open={open} setOpen={setOpen} onOpen={handleCreateNewWebhook} isEdit={false} />
			</div>
			{selectedWebhook && !editOpen && (
				<div className="mb-6">
					<Button variant="link" onClick={clearSelectedWebhook}>
						{t("form.actions.back")}
					</Button>
					<WebhookCard webhook={selectedWebhook} onTest={handleTest} />
				</div>
			)}

			{(!selectedWebhook || (selectedWebhook && editOpen)) && (
				<>
					<WebhookTable webhooks={webhooks} onEdit={handleEdit} onDelete={handleDelete} onSelect={handleSelect} />
				</>
			)}

			{selectedWebhook && (
				<WebhookForm webhook={selectedWebhook} onSubmit={handleUpdate} open={editOpen} setOpen={handleSetEditOpen} isEdit={true} />
			)}

			{selectedWebhook && !editOpen && (
				<>
					<h2 className="text-lg font-bold">{t("table.deliveryLogs.title")}</h2>
					{deliveryLogs.length > 0 && <DeliveryLogTable deliveryLogs={deliveryLogs} />}
				</>
			)}
		</div>
	);
}
