import React from "react";
import { MoreHorizontal } from "lucide-react";
import { Button } from "./Button";
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuLabel, DropdownMenuItem, DropdownMenuSeparator } from "./Dropdown";

interface ActionMenuItem {
	label: string;
	onClick: () => void;
	hidden?: boolean;
	"aria-label"?: string;
}

interface DataTableActionMenuProps {
	label: string;
	copyId?: string;
	menuItems: ActionMenuItem[];
	"aria-label"?: string;
}

export function DataTableActionMenu({ label, copyId, menuItems, "aria-label": ariaLabel }: DataTableActionMenuProps) {
	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button variant="ghost" className="h-8 w-8 p-0" aria-label={ariaLabel || `Actions menu for ${label}`}>
					<span className="sr-only">Open menu</span>
					<MoreHorizontal className="h-4 w-4" />
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent align="end">
				<DropdownMenuLabel>{label}</DropdownMenuLabel>
				{copyId && (
					<DropdownMenuItem onClick={() => navigator.clipboard.writeText(copyId)} aria-label={`Copy ${label} ID`}>
						Copy ID
					</DropdownMenuItem>
				)}
				{copyId && <DropdownMenuSeparator />}
				{menuItems.map((item, index) =>
					item.hidden ? null : (
						<DropdownMenuItem key={index} onClick={item.onClick} aria-label={item["aria-label"] || item.label}>
							{item.label}
						</DropdownMenuItem>
					)
				)}
			</DropdownMenuContent>
		</DropdownMenu>
	);
}
