export const WEBHOOK_EVENTS = {
	CALL_STARTED: "call.started"
	// REPORT_SUBMITTED: "report.submitted",
	// REPORT_DELETED: "report.deleted",
	// ASSIGNMENT_CREATED: "assingment.created",
	// ASSIGNMENT_UPDATED: "assingment.updated",
	// ASSIGNMENT_DELETED: "assingment.deleted"
} as const;

export const WEBHOOK_STATUSES = {
	ACTIVE: "active",
	INACTIVE: "inactive",
	FAILED: "failed"
} as const;

// Type helpers
export type WebhookEvent = (typeof WEBHOOK_EVENTS)[keyof typeof WEBHOOK_EVENTS];
export type WebhookStatus = (typeof WEBHOOK_STATUSES)[keyof typeof WEBHOOK_STATUSES];
