import React from "react";
import { UserAssignmentView } from "../../types/UserAssignmentView";
import { Button } from "../shadcn-ui/Button";
import { Card, CardContent } from "../shadcn-ui/Card";
import { NavigateService } from "../../services/NavigateService";
import { useTranslation } from "./UseTranslation";
// Define the props interface for the UserAssignmentCard component
interface UserAssignmentCardProps {
	assignment: UserAssignmentView;
}

// UserAssignmentCard component: Displays a card with assignment details and a button to view more information
const UserAssignmentCard: React.FC<UserAssignmentCardProps> = ({ assignment }) => {
	const { t } = useTranslation("userHomePage");
	// Function to handle the "View Details" button click
	// It navigates to the user assignment page using the NavigateService
	const handleViewDetails = () => {
		NavigateService.navToUserAssignment(assignment.assignmentId, assignment.userId);
	};

	return (
		// Card component to wrap the assignment information
		<Card className="mb-4 shadow-sm">
			<CardContent className="p-6">
				{/* Flex container for vertical layout */}
				<div className="flex flex-col h-full">
					{/* Assignment title */}
					<h2 className="text-xl font-semibold text-primary-text font-plus-jakarta mb-1">{assignment.assignmentTitle}</h2>
					{/* Course name */}
					<p className="text-gray-600 font-dm-sans mb-4">{assignment.courseName}</p>
					{/* Container for the button, pushed to the bottom of the card */}
					<div className="mt-auto">
						{/* Button to view assignment details */}
						<Button onClick={handleViewDetails} variant="outline" size="sm">
							{t("buttons.viewDetails")}
						</Button>
					</div>
				</div>
			</CardContent>
		</Card>
	);
};

export default UserAssignmentCard;
