import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AssignmentService } from "../../services/AssignmentService";
import { UserService } from "../../services/UserService";
import Spinner from "../Utility/Spinner";
import { Button } from "../shadcn-ui/Button";
import { Input } from "../shadcn-ui/Input";
import { Label } from "../shadcn-ui/Label";
import posthog from "posthog-js";
import { ClientService } from "../../services/ClientService";
const SignUpV2: React.FC<{ isEmailConfirm?: boolean }> = ({ isEmailConfirm }) => {
	const searchParams = new URLSearchParams(window.location.search);

	const navigate = useNavigate();
	const preFilledEmail = searchParams.get("email");

	const redirectUrl = searchParams.get("redirectUrl");

	const { clientCode } = useParams();
	const [email, setEmail] = useState(preFilledEmail || "");
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [tenantId, setTenantId] = useState("");
	const [assignmentId, setAssignmentId] = useState("");
	const [fullName, setFullName] = useState("");

	const [clientName, setClientName] = useState("");
	const [loading, setLoading] = useState(false);

	const [signUpDone, setSignUpDone] = useState(false);

	const [isEmailError, setIsEmailError] = useState(false);

	useEffect(() => {
		if (clientCode) {
			setLoading(true);
			ClientService.getClientNamebyClientCode(clientCode)
				.then(({ name }) => {
					setClientName(name);
				})
				.finally(() => {
					setLoading(false);
				});
		}
	}, [clientCode]);

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		if (!clientCode) {
			throw new Error("Client code is required");
		}
		try {
			setLoading(true);
			console.log("Submitting form", tenantId);
			// if (firstName) {
			// 	console.log("Splitting first name", firstName);
			// 	const nameParts = firstName.split(" ");
			// 	await new Promise<void>((resolve) => {
			// 		setFirstName(nameParts[0]);
			// 		setLastName(nameParts.slice(1).join(" "));
			// 		resolve(); // Resolve the promise after setting the states
			// 	});
			// }
			await ClientService.signUpByClientCode({ email, name: fullName, clientCode });

			try {
				// console.log("MAJOR_EVENT: User Signed Up from Custom Signup Page");
				posthog.capture("MAJOR_EVENT: User Signed Up from Generic Signup Page", {
					email: email,
					timestamp: new Date().toISOString()
				});
			} catch (err) {
				console.error("Error capturing event 'M.E. User Signed Up from Custom Signup Page':", err);
			}

			const searchParams = new URLSearchParams();
			if (clientCode) {
				// searchParams.append("redirect_url", redirect_url);
				searchParams.append("login_hint", email);
				searchParams.append("clientCode", clientCode);
			}
			//
			navigate(`/user${searchParams.toString() ? `?${searchParams.toString()}` : ""}`);
		} catch (error) {
			console.error("Sign up failed:", error);
			// Handle error (e.g., show error message to user)
			setIsEmailError(true);
		} finally {
			setLoading(false);
		}
	};

	const handleLoginClick = (e: React.MouseEvent) => {
		console.log("handleLoginClick", clientCode);
		e.preventDefault();
		// const redirect_url = `/signup/${clientCode}`;
		const searchParams = new URLSearchParams();
		if (clientCode) {
			// searchParams.append("redirect_url", redirect_url);
			searchParams.append("clientCode", clientCode);
		}
		//
		navigate(`/user${searchParams.toString() ? `?${searchParams.toString()}` : ""}`);
	};

	if (signUpDone) {
		return (
			<div className="flex items-center justify-center min-h-screen">
				<div className="text-center">
					<p>Check your email for a link to activate your account</p>
				</div>
			</div>
		);
	}

	if (loading) {
		return <Spinner />;
	}

	return (
		<>
			<div className="flex items-center justify-center  h-[calc(80vh-4rem)]  ">
				<div className="px-8 py-6 text-left bg-white shadow-lg">
					{isEmailConfirm ? (
						<h3 className="text-2xl font-bold text-center"> Confirm your email</h3>
					) : (
						<h3 className="text-2xl font-bold text-center"> Join the {clientName} Account</h3>
					)}
					{/* <h2 className="text-xl font-bold text-center">{redirectUrl}</h2> */}
					<form onSubmit={handleSubmit}>
						<div>
							<div>
								<Label htmlFor="name">Name</Label>
								<Input
									type="text"
									placeholder="Name"
									id="name"
									value={fullName} // Use fullName for display
									onChange={(e) => {
										const input = e.target.value;
										setFullName(input); // Update fullName directly
										const nameParts = input.trim().split(" ");
										setFirstName(nameParts[0] || ""); // Set first name to the first part or empty if not present
										setLastName(nameParts.slice(1).join(" ") || ""); // Set last name to everything after the first part or empty if not present
									}}
									required
								/>
							</div>

							<div>
								<Label htmlFor="email">Email</Label>
								<Input
									disabled={isEmailConfirm}
									type="email"
									placeholder="Email"
									id="email"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									required
								/>
							</div>

							<div className="mt-4 flex items-baseline justify-between">
								{isEmailConfirm ? <Button type="submit">Confirm</Button> : <Button type="submit">Sign Up</Button>}

								{!isEmailConfirm && (
									<Button variant="link" onClick={handleLoginClick}>
										Already have an account? Log in
									</Button>
								)}
							</div>
							<br></br>
							<p className="text-sm text-muted-foreground">
								I agree with the{" "}
								<a href="https://www.instage.io/terms" className="text-[#00a9af] underline" target="_blank" rel="noopener noreferrer">
									Terms
								</a>{" "}
								and{" "}
								<a
									href="https://www.instage.io/privacy"
									className="text-[#00a9af] underline"
									target="_blank"
									rel="noopener noreferrer"
								>
									Privacy Policy
								</a>
							</p>
							{isEmailError && <p className="text-red-500">Email already in use</p>}
						</div>
					</form>
				</div>
			</div>
		</>
	);
};

export default SignUpV2;
