import * as React from "react";
import { Search } from "lucide-react"; // Import the Search icon
import {
	ColumnDef,
	SortingState,
	VisibilityState,
	ColumnFiltersState,
	flexRender,
	getCoreRowModel,
	useReactTable,
	getPaginationRowModel,
	getSortedRowModel,
	getFilteredRowModel,
	PaginationState,
	Row
} from "@tanstack/react-table";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "./Table";
import { DataTableColumnHeader } from "./DataTableColumnHeader";
import { Checkbox } from "./Checkbox";
import { DataTablePagination } from "./DataTablePagination";
import { DataTableViewOptions } from "./DataTableViewOptions";
import { DataTableActionMenu } from "./DataTableActionMenu";
import { DataTableInputFilter } from "./DataTableInputFilter";
import { DataTableSelectFilter } from "./DataTableSelectFilter";
import { DataTableDateRangeFilter } from "./DataTableDateRangeFilter";
import { useEffect } from "react";
import { useTranslation } from "../Utility/UseTranslation";

export type Payment = {
	id: string;
	amount: number;
	status: "pending" | "processing" | "success" | "failed";
	email: string;
	createdAt: Date;
};

export const TestData: Payment[] = [
	{
		id: "a1b2c3d4",
		amount: 75,
		status: "success",
		email: "john@example.com",
		createdAt: new Date("2023-01-15")
	},
	{
		id: "e5f6g7h8",
		amount: 200,
		status: "failed",
		email: "sarah@gmail.com",
		createdAt: new Date("2023-02-20")
	},
	{
		id: "i9j0k1l2",
		amount: 150,
		status: "processing",
		email: "mike@yahoo.com",
		createdAt: new Date("2023-03-10")
	},
	{
		id: "m3n4o5p6",
		amount: 50,
		status: "pending",
		email: "emma@hotmail.com",
		createdAt: new Date("2023-04-05")
	},
	{
		id: "q7r8s9t0",
		amount: 300,
		status: "success",
		email: "alex@example.org",
		createdAt: new Date("2023-05-18")
	},
	{
		id: "u1v2w3x4",
		amount: 175,
		status: "processing",
		email: "lisa@gmail.com",
		createdAt: new Date("2023-06-02")
	},
	{
		id: "y5z6a7b8",
		amount: 90,
		status: "failed",
		email: "david@yahoo.com",
		createdAt: new Date("2023-07-12")
	},
	{
		id: "c9d0e1f2",
		amount: 225,
		status: "pending",
		email: "olivia@hotmail.com",
		createdAt: new Date("2023-08-25")
	},
	{
		id: "g3h4i5j6",
		amount: 125,
		status: "success",
		email: "chris@example.net",
		createdAt: new Date("2023-09-10")
	},
	{
		id: "k7l8m9n0",
		amount: 80,
		status: "processing",
		email: "sophia@gmail.com",
		createdAt: new Date("2023-10-03")
	},
	{
		id: "o1p2q3r4",
		amount: 250,
		status: "failed",
		email: "daniel@yahoo.com",
		createdAt: new Date("2023-11-15")
	},
	{
		id: "s5t6u7v8",
		amount: 110,
		status: "pending",
		email: "emily@hotmail.com",
		createdAt: new Date("2023-12-08")
	},
	{
		id: "w9x0y1z2",
		amount: 180,
		status: "success",
		email: "ryan@example.com",
		createdAt: new Date("2024-01-20")
	},
	{
		id: "a3b4c5d6",
		amount: 95,
		status: "processing",
		email: "ava@gmail.com",
		createdAt: new Date("2024-02-12")
	},
	{
		id: "e7f8g9h0",
		amount: 275,
		status: "failed",
		email: "jacob@yahoo.com",
		createdAt: new Date("2024-03-05")
	},
	{
		id: "i1j2k3l4",
		amount: 60,
		status: "pending",
		email: "mia@hotmail.com",
		createdAt: new Date("2024-04-18")
	},
	{
		id: "m5n6o7p8",
		amount: 140,
		status: "success",
		email: "ethan@example.org",
		createdAt: new Date("2024-05-10")
	},
	{
		id: "q9r0s1t2",
		amount: 210,
		status: "processing",
		email: "zoe@gmail.com",
		createdAt: new Date("2024-06-02")
	}
];

// Modify the ColumnDef type to include an initiallyHidden property
export type ExtendedColumnDef<TData, TValue = unknown> = ColumnDef<TData, TValue> & {
	initiallyHidden?: boolean;
	accessorKey?: string;
};

export const testColumns: ExtendedColumnDef<Payment, any>[] = [
	{
		id: "select",
		header: ({ table }) => (
			<Checkbox
				checked={table.getIsAllPageRowsSelected() || (table.getIsSomePageRowsSelected() && "indeterminate")}
				onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
				aria-label="Select all"
			/>
		),
		cell: ({ row }) => (
			<Checkbox checked={row.getIsSelected()} onCheckedChange={(value) => row.toggleSelected(!!value)} aria-label="Select row" />
		),
		enableSorting: false,
		initiallyHidden: true
	},
	{
		accessorKey: "status",
		header: ({ column }) => {
			return <DataTableColumnHeader column={column} title="Status" />;
		}
	},
	{
		accessorKey: "email",
		header: ({ column }) => {
			return <DataTableColumnHeader column={column} title="Email" />;
		}
	},
	{
		accessorKey: "amount",
		header: () => <div className="text-right">Amount</div>,
		cell: ({ row }) => {
			const amount = parseFloat(row.getValue("amount"));
			const formatted = new Intl.NumberFormat("en-US", {
				style: "currency",
				currency: "USD"
			}).format(amount);

			return <div className="text-right font-medium">{formatted}</div>;
		}
	},
	{
		accessorKey: "createdAt",
		header: ({ column }) => {
			return <DataTableColumnHeader column={column} title="Created At" />;
		},
		cell: ({ row }) => {
			const date = row.getValue("createdAt") as Date;
			return <div>{date.toLocaleDateString()}</div>;
		}
	},
	{
		id: "actions",
		cell: ({ row }) => {
			const payment = row.original;

			return (
				<DataTableActionMenu
					label="Actions"
					copyId={payment.id}
					menuItems={[
						{ label: "View customer", onClick: () => console.log("View customer") },
						{ label: "View payment details", onClick: () => console.log("View payment details") }
					]}
				/>
			);
		}
	}
];

// Add this type for the filter function
export type FilterFn = (row: Row<any>, columnId: string, filterValue: string) => boolean;

export interface Filter {
	columnId: string;
	type: "input" | "select" | "dateRange";
	placeholder?: string;
	options?: { label: string; value: string }[];
	filterFn?: FilterFn; // Add this optional property
}

interface DataTableProps<TData, TValue = unknown> {
	columns: ExtendedColumnDef<TData, TValue>[];
	data: TData[];
	filters?: Filter[];
	enableSorting?: boolean;
	viewOptions?: boolean;
	pagination?: boolean;
	paginationState?: PaginationState;
	onPaginationChange?: (paginationState: PaginationState) => void;
	pageCount?: number;
	sorting?: SortingState;
	onSortingChange?: (sorting: SortingState) => void;
}

export function DataTable<TData, TValue = unknown>({
	columns,
	data,
	filters,
	enableSorting = false,
	viewOptions = true,
	pagination = true,
	paginationState: controlledPaginationState,
	onPaginationChange,
	pageCount,
	sorting: controlledSorting,
	onSortingChange: controlledOnSortingChange
}: DataTableProps<TData, TValue>) {
	const [internalSorting, setInternalSorting] = React.useState<SortingState>([]);
	const { t } = useTranslation("extraUtility");
	const sorting = controlledSorting || internalSorting;
	const handleSortingChange = React.useCallback(
		(updater: SortingState | ((prev: SortingState) => SortingState)) => {
			const newState = typeof updater === "function" ? updater(sorting) : updater;
			if (controlledOnSortingChange) {
				controlledOnSortingChange(newState);
			} else {
				setInternalSorting(newState);
			}
		},
		[sorting, controlledOnSortingChange]
	);

	const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
	const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>(() => {
		const initialState: VisibilityState = {};
		// console.log(`columns:  `, columns);
		columns.forEach((column) => {
			// console.log(`column:  `, column);
			// console.log(column.initiallyHidden);
			if (column.initiallyHidden) {
				// console.log(`hiding column ${column.accessorKey}`);
				initialState[column.accessorKey as string] = false;
			}
		});
		return initialState;
	});
	const [rowSelection, setRowSelection] = React.useState({});

	const [internalPaginationState, setInternalPaginationState] = React.useState<PaginationState>({
		pageIndex: 0,
		pageSize: 10
	});
	const isControlled = controlledPaginationState !== undefined && onPaginationChange !== undefined;
	const paginationState = isControlled ? controlledPaginationState! : internalPaginationState;
	// console.log("paginationState", paginationState);

	useEffect(() => {
		setColumnVisibility((prev) => {
			const newState = { ...prev };
			columns.forEach((column) => {
				if (column.initiallyHidden) {
					newState[column.accessorKey as string] = false;
				}
			});
			return newState;
		});
	}, [columns]);

	// Add this function to handle custom filtering
	const customFilterFns = React.useMemo(() => {
		const fns: { [key: string]: FilterFn } = {};
		filters?.forEach((filter) => {
			if (filter.filterFn) {
				fns[filter.columnId] = filter.filterFn;
			}
		});
		return fns;
	}, [filters]);

	const table = useReactTable({
		data,
		columns,
		getCoreRowModel: getCoreRowModel(),
		...(isControlled
			? {
					pageCount: pageCount || -1, // Indicates server-side pagination
					manualPagination: true
			  }
			: {
					getPaginationRowModel: getPaginationRowModel()
			  }),
		enableSorting: enableSorting,
		enableMultiSort: true,
		onSortingChange: handleSortingChange,

		getSortedRowModel: getSortedRowModel(),
		onColumnFiltersChange: setColumnFilters,
		getFilteredRowModel: getFilteredRowModel(),
		onColumnVisibilityChange: setColumnVisibility,
		onRowSelectionChange: setRowSelection,
		onPaginationChange: (updater) => {
			//console.log("onPaginationChange", updater);
			const newState = typeof updater === "function" ? updater(paginationState) : updater;
			if (isControlled) {
				onPaginationChange!(newState);
			} else {
				setInternalPaginationState(newState);
			}
		},
		filterFns: customFilterFns,
		state: {
			sorting,
			columnFilters,
			columnVisibility,
			rowSelection,
			pagination: paginationState
		}
	});

	return (
		<div className="space-y-4">
			<div className="rounded-md border bg-[#FFFFFF]">
				<div className="flex items-center justify-between p-2 border-b">
					<div className="flex items-center space-x-2">
						{filters &&
							filters.map((filter, index) => {
								if (filter.type === "input") {
									return (
										<div key={index} className="flex items-center">
											<Search className="h-4 w-4 text-gray-500 mr-2" />
											<DataTableInputFilter table={table} columnId={filter.columnId} placeholder={filter.placeholder} />
										</div>
									);
								}
								return null;
							})}
					</div>
					<div className="flex items-center space-x-2">
						{filters &&
							filters.map((filter, index) => {
								if (filter.type === "select" && filter.options) {
									return (
										<DataTableSelectFilter
											key={index}
											table={table}
											columnId={filter.columnId}
											title={filter.placeholder || filter.columnId}
											options={filter.options}
										/>
									);
								} else if (filter.type === "dateRange") {
									return (
										<DataTableDateRangeFilter
											key={index}
											table={table}
											columnId={filter.columnId}
											title={filter.placeholder || filter.columnId}
										/>
									);
								}
								return null;
							})}
						{viewOptions && <DataTableViewOptions table={table} />}
					</div>
				</div>
				<Table>
					<TableHeader>
						{table.getHeaderGroups().map((headerGroup) => (
							<TableRow key={headerGroup.id}>
								{headerGroup.headers.map((header) => (
									<TableHead key={header.id} className="hover:bg-muted/30 transition-colors whitespace-normal max-w-[110px]">
										{header.isPlaceholder ? null : header.column.getCanSort() ? (
											<DataTableColumnHeader column={header.column} title={header.column.columnDef.header as string} />
										) : (
											flexRender(header.column.columnDef.header, header.getContext())
										)}
									</TableHead>
								))}
							</TableRow>
						))}
					</TableHeader>
					<TableBody>
						{table.getRowModel().rows?.length ? (
							table.getRowModel().rows.map((row) => (
								<TableRow key={row.id} data-state={row.getIsSelected() && "selected"} className="bg-[#FFFFFF]">
									{row.getVisibleCells().map((cell) => (
										<TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
									))}
								</TableRow>
							))
						) : (
							<TableRow>
								<TableCell colSpan={columns?.length} className="h-24 text-center bg-[#FFFFFF]">
									{t("noResults")}
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</div>
			{pagination && <DataTablePagination table={table} />}
		</div>
	);
}

// Example usage:
export const testFilters: Filter[] = [
	{
		columnId: "email",
		type: "input",
		placeholder: "Search"
	},
	{
		columnId: "status",
		type: "select",
		placeholder: "Status",
		options: [
			{ label: "Pending", value: "pending" },
			{ label: "Processing", value: "processing" },
			{ label: "Success", value: "success" },
			{ label: "Failed", value: "failed" }
		]
	},
	{
		columnId: "createdAt",
		type: "dateRange",
		placeholder: "Created At"
	}
];

// In the parent component where DataTable is used:
<DataTable columns={testColumns} data={TestData} filters={testFilters} />;
