import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { SessionView } from "../../types/SessionView";
import { SessionService } from "../../services/SessionService";
import {
	RenderOverview,
	RenderExpectations,
	RenderGoals,
	RenderNoteworthyComments,
	RenderSatisfaction,
	RenderGoalProgress,
	RenderFinalReview
} from "../Utility/reportUtility";
import UserScheduleCall from "./UserScheduleCall";
import { Button } from "../shadcn-ui/Button";
import { Dialog, DialogContent, DialogTitle, DialogTrigger, DialogDescription } from "../shadcn-ui/Dialog";
import { ActivityType } from "../../schemas/ActivityType";
import { ExperienceType } from "../../schemas/ExperienceType";

const extractPhoneFromSession = (session: SessionView): string => {
	return session.lastName || ""; // lastName contains the phone number
};

const UserPublicSessionPage: React.FC = () => {
	const { sessionId } = useParams<{ sessionId: string }>();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [selectedUserSession, setSelectedUserSession] = useState<SessionView | null>(null);
	const [dialogOpen, setDialogOpen] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const audioRef = useRef<HTMLAudioElement | null>(null);

	useEffect(() => {
		if (!sessionId) {
			setIsLoading(false);
			setError("Session ID is missing");
			return;
		}

		SessionService.getPublicSession(sessionId)
			.then((session) => {
				setSelectedUserSession(session);
				setIsLoading(false);
			})
			.catch((err) => {
				setIsLoading(false);
				console.error(err);
				if (err.message === "Access denied for non-inbound sessions") {
					setError("Access denied. This session is not publicly available.");
				} else if (err.message === "Session not found") {
					setError("Invalid session ID. Please check the session ID and try again.");
				} else {
					setError("An error occurred while fetching the session. Please try again later.");
				}
			});
	}, [sessionId]);

	const renderButton = () => {
		if (selectedUserSession?.activityType?.includes("first")) {
			return (
				<Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
					<DialogTrigger asChild>
						<Button className="w-full md:w-auto">Schedule Follow-Up</Button>
					</DialogTrigger>
					<DialogContent className="sm:max-w-[425px]">
						<DialogTitle className="sr-only">Schedule Follow-Up</DialogTitle>
						<DialogDescription className="sr-only">Schedule your follow-up call with us</DialogDescription>
						<UserScheduleCall
							preVerifiedPhone={selectedUserSession ? extractPhoneFromSession(selectedUserSession) : undefined}
							userEmail={selectedUserSession?.email}
							onClose={() => setDialogOpen(false)}
						/>
					</DialogContent>
				</Dialog>
			);
		} else if (selectedUserSession?.activityType?.includes("last")) {
			return (
				<Button className="w-full md:w-auto" onClick={() => window.open("https://instage.io/reflection", "_blank")}>
					Learn More
				</Button>
			);
		}
		return null;
	};

	if (isLoading) {
		return <div>Loading...</div>;
	}

	if (error) {
		return <div className="text-red-600 font-semibold">{error}</div>;
	}

	return (
		<>
			<div className="h-full md:pb-0 pb-12">
				{" "}
				{/* Add bottom padding on mobile */}
				<div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-4">
					<h2 className="text-2xl md:text-3xl font-bold text-[#16013e] mb-4 md:mb-0">Call Summary</h2>
					{selectedUserSession?.experienceType === ExperienceType.DEMO && <div className="hidden md:block">{renderButton()}</div>}
				</div>
				<div>
					{selectedUserSession && (
						<div key={selectedUserSession.sessionId}>
							<h2 className="text-xl md:text-2xl font-semibold mt-8 mb-4">Individual Session Summary</h2>

							{selectedUserSession.activityType === ActivityType.FIRST && (
								<>
									<div className="mb-6">
										{RenderOverview(selectedUserSession, selectedUserSession.userId || "", undefined, true)}
									</div>
									{selectedUserSession.experienceType === ExperienceType.CONFERENCE && (
										<div className="mb-6">
											{RenderExpectations(selectedUserSession, selectedUserSession.userId || "", undefined, true)}
										</div>
									)}
									{selectedUserSession?.experienceType === ExperienceType.DEMO && (
										<div className="mb-6">
											{RenderGoals(selectedUserSession, selectedUserSession.userId || "", undefined, true)}
										</div>
									)}
								</>
							)}
							{selectedUserSession.activityType === ActivityType.LAST && (
								<>
									<div className="mb-6">
										{RenderSatisfaction(selectedUserSession, selectedUserSession.userId || "", undefined, undefined, true)}
									</div>
									<div className="mb-6">
										{RenderGoalProgress(selectedUserSession, selectedUserSession.userId || "", undefined, true)}
									</div>
									<div className="mb-6">
										{RenderFinalReview(selectedUserSession, selectedUserSession.userId || "", undefined, true)}
									</div>
								</>
							)}
							<div className="mb-6">
								{RenderNoteworthyComments(selectedUserSession, selectedUserSession.userId || "", undefined, true)}
							</div>
						</div>
					)}
				</div>
				<audio ref={audioRef} style={{ display: "none" }} />
			</div>

			{/* Sticky footer for mobile */}
			{selectedUserSession?.experienceType === ExperienceType.DEMO && (
				<div className="md:hidden fixed bottom-0 left-0 right-0 px-4 py-6 pr-24 bg-white border-t border-gray-200">{renderButton()}</div>
			)}
		</>
	);
};

export default UserPublicSessionPage;
