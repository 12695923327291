import * as React from "react";
import { cn } from "../../lib/utils";
import { Link, useLocation } from "react-router-dom";

interface SidebarNavItemProps {
	icon: React.ElementType;
	label: string;
	href?: string;
	onClick?: () => void; // Add this line
	isCollapsed: boolean;
	customRender?: () => React.ReactNode;
	style?: React.CSSProperties;
	visible?: boolean;
}

export function SidebarNavItem({ icon: Icon, label, href, isCollapsed, onClick, customRender, style, visible = true }: SidebarNavItemProps) {
	const location = useLocation();
	const isActive = location.pathname === href;

	if (!visible) return null;
	return (
		<div onClick={onClick} className="sidebar-nav-item group relative hover:cursor-pointer">
			{customRender ? (
				<div
					className={cn(
						"flex items-center gap-3 rounded-lg px-3 py-2 text-[14px] transition-all hover:bg-accent",
						isActive ? "bg-accent" : "transparent",
						isCollapsed ? "justify-center w-full" : ""
					)}
				>
					<Icon style={style} className="text-muted-foreground h-5 w-5 flex-shrink-0" />
					{!isCollapsed && (
						<div className="flex items-center justify-between min-w-0 flex-1">
							<span className="break-words">{label}</span>
							{customRender()}
						</div>
					)}
				</div>
			) : href ? (
				<Link
					to={href}
					className={cn(
						"flex items-center gap-3 rounded-lg px-3 py-2 text-[14px] transition-all hover:bg-accent",
						isActive ? "bg-accent" : "transparent",
						isCollapsed ? "justify-center w-full" : ""
					)}
				>
					<Icon style={style} className="text-muted-foreground h-5 w-5 flex-shrink-0" />
					{!isCollapsed && (
						<div className="flex items-center justify-between min-w-0 flex-1">
							<span className="break-words">{label}</span>
						</div>
					)}
				</Link>
			) : (
				<div
					className={cn(
						"flex items-center gap-3 rounded-lg px-3 py-2 text-[14px] transition-all hover:bg-accent",
						isActive ? "bg-accent" : "transparent",
						isCollapsed ? "justify-center w-full" : ""
					)}
				>
					<Icon style={style} className="text-muted-foreground h-5 w-5 flex-shrink-0" />
					{!isCollapsed && (
						<div className="flex items-center justify-between min-w-0 flex-1">
							<span className="break-words">{label}</span>
						</div>
					)}
				</div>
			)}
		</div>
	);
}
