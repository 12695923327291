import React, { useEffect, useState } from "react";
import { FaTrash, FaTrashAlt, FaUserCircle } from "react-icons/fa";
import { ProfileService } from "../../services/ProfileService";
import { useNavigate } from "react-router-dom";
import { ProfileView } from "../../types/ProfileView";
import ProfileProgressBar from "./ProfileProgressBar";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { Card, CardContent } from "../shadcn-ui/Card";
import { Edit2Icon, Trash2Icon, TrashIcon } from "lucide-react";
import { LuTrash } from "react-icons/lu";
import { GrTrash } from "react-icons/gr";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../shadcn-ui/Dialog";
import { Button } from "../shadcn-ui/Button";
import Spinner from "../Utility/Spinner";
import { useTranslation } from "../Utility/UseTranslation";
const DeleteConfirmationDialog: React.FC<{
	isOpen: boolean;
	setIsOpen: (open: boolean) => void;
	onConfirm: () => void;
}> = ({ isOpen, setIsOpen, onConfirm }) => {
	const { t } = useTranslation("profile");
	return (
		<Dialog open={isOpen} onOpenChange={setIsOpen}>
			<DialogContent className="sm:max-w-[425px]">
				<DialogHeader>
					<DialogTitle className="flex items-center gap-2 text-xl">
						<FaTrashAlt className="h-6 w-6 text-gray-500" />
						{t("delete.title")}
					</DialogTitle>
				</DialogHeader>
				<div className="py-4">
					<p className="text-sm text-gray-600">{t("delete.description")}</p>
					<div className="flex gap-3 justify-start pt-6">
						<Button onClick={() => setIsOpen(false)} className="bg-[#00a9af] hover:bg-[#00a9af]/90 text-white border-none">
							{t("delete.cancel")}
						</Button>
						<Button
							variant="outline"
							onClick={onConfirm}
							className="border-red-500 text-red-500 hover:bg-transparent hover:text-red-600 hover:border-red-400"
						>
							{t("delete.delete")}
						</Button>
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
};

interface ProfileButtonProps {
	userId: string;
	profile: ProfileView | null;
}

const ProfileButton: React.FC<ProfileButtonProps> = ({ userId, profile }) => {
	const navigate = useNavigate();
	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	const { t } = useTranslation("profile");

	const fetchOrCreateProfile = async () => {
		const profile = await ProfileService.fetchOrCreateProfile(userId);

		if (!profile) {
			return;
		}
		console.log(profile);

		navigate(`/user/resume-assist/profile/${profile.id}/user/${profile.userId}`);
	};

	const handleDeleteClick = (e: React.MouseEvent) => {
		e.stopPropagation(); // Prevent the card click event from firing
		setIsDeleteDialogOpen(true);
	};

	const handleDeleteConfirm = async () => {
		if (profile) {
			try {
				setIsDeleting(true);
				if (profile) {
					await ProfileService.deleteProfileResumeCoverLetter(userId);
				}
				setIsDeleteDialogOpen(false);
				window.location.reload();
			} catch (error) {
				console.error("Failed to delete profile:", error);
			} finally {
				setIsDeleting(false);
			}
		}
	};

	// useEffect(() => {
	// 	if (profile) {
	// 		console.log("ProfileButton", "Profile fetched", profile);
	// 	}
	// }, [profile]);

	const formatDate = (dateString: string) => {
		const date = new Date(dateString);
		return date.toLocaleDateString("en-US", {
			month: "short",
			day: "numeric",
			year: "numeric",
			hour: "numeric",
			minute: "2-digit"
		});
	};

	if (profile) {
		return (
			<>
				<Card className="mb-4 shadow-sm hover:shadow-lg transition-shadow duration-200">
					<CardContent onClick={fetchOrCreateProfile} className="p-4 cursor-pointer">
						<div className="flex flex-col h-full">
							<div className="flex items-start justify-between mb-3">
								<div className="flex items-center gap-3 relative w-full">
									<div className="flex items-center gap-2">
										<FaUserCircle className="w-8 h-8 text-[#303d7c]" aria-label={t("profileButton.profilePictureAriaLabel")} />
										<div>
											<h2 className="text-xl font-semibold text-primary-text font-plus-jakarta mb-1">
												{t("profileButton.title")}
											</h2>
											<p className="text-sm text-gray-600">
												{t("profileButton.lastModified")}: {new Date(profile.updatedAt).toISOString().split("T")[0]}
											</p>
										</div>
									</div>
									<div className="ml-auto mb-auto">
										<button
											onClick={handleDeleteClick}
											className="p-0 rounded-full hover:bg-gray-100 transition-colors"
											aria-label={t("profileButton.deleteAriaLabel")}
											disabled={isDeleting}
										>
											{isDeleting ? (
												<Spinner className="w-5 h-5" />
											) : (
												<FaTrashAlt
													className="w-5 h-5 text-gray-600 hover:text-[#ef4444] transition-colors"
													aria-label={t("profileButton.deleteIconAriaLabel")}
												/>
											)}
										</button>
									</div>
								</div>
								{profile.isPublic && (
									<span className="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
										{t("profileButton.public")}
									</span>
								)}
							</div>
							<div className="mt-4">
								<ProfileProgressBar profile={profile} />
							</div>
						</div>
					</CardContent>
				</Card>
				<DeleteConfirmationDialog isOpen={isDeleteDialogOpen} setIsOpen={setIsDeleteDialogOpen} onConfirm={handleDeleteConfirm} />
			</>
		);
	}

	return (
		<Card className="mb-4 shadow-sm">
			<CardContent className="p-6">
				<div className="flex flex-col h-full">
					<div className="flex items-start justify-between mb-3">
						<div className="flex items-center gap-3 relative w-full">
							<div className="flex items-center gap-2">
								<FaUserCircle className="w-8 h-8 text-[#303d7c]" aria-label={t("profileButton.profilePictureAriaLabel")} />
								<div>
									<h2 className="text-xl font-semibold text-primary-text font-plus-jakarta mb-1">{t("profileButton.title")}</h2>
									<p className="text-sm text-gray-600">{t("profileButton.createPrompt")}</p>
								</div>
							</div>
							<div className="ml-auto">
								<button
									onClick={fetchOrCreateProfile}
									className="p-2 rounded-full hover:bg-gray-100 transition-colors"
									aria-label={t("profileButton.editAriaLabel")}
								>
									<PencilSquareIcon className="w-5 h-5 text-gray-600" />
								</button>
							</div>
						</div>
					</div>
					<div className="mt-4">
						<ProfileProgressBar profile={profile} />
					</div>
				</div>
			</CardContent>
		</Card>
	);

	// return (
	// 	<button
	// 		onClick={fetchOrCreateProfile}
	// 		className="w-full h-[150px] rounded-[12px] border-2 border-dashed border-[#00a9af]
	//   flex flex-col gap-3 items-center justify-center transition-all duration-200
	//   hover:border-[#00a9af] hover:bg-teal-50 mb-10 group"
	// 	>
	// 		<FaUserCircle className="w-8 h-8 text-[#00a9af] group-hover:text-[#008c91] transition-colors" />
	// 		<span className="text-gray-700 font-medium group-hover:text-[#008c91] transition-colors">Resume Profile</span>
	// 	</button>
	// );
};

export default ProfileButton;
