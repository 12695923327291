export const CoverLetterEditFormLocale = {
	en: {
		common: {
			edit: "Edit",
			original: "Original",
			editRewrite: "Edit / AI Rewrite",
			feedback: "Feedback",
			rewriting: "Rewriting...",
			formalize: "Formalize",
			shorten: "Shorten",
			elaborate: "Elaborate",
			requiredFieldsMissing: "Required Fields Missing",
			close: "Close",
			rephraseTooltip: "Use these buttons to rephrase the content of the section. Confirm or Cancel after each change.",
			confirm: "Confirm",
			cancel: "Cancel"
		},
		sections: {
			contact: {
				label: "Contact Information",
				firstName: "First Name",
				lastName: "Last Name",
				email: "Email",
				phone: "Phone",
				address: "Address",
				linkedinURL: "LinkedIn URL",
				website: "Website",
				placeholders: {
					firstName: "John",
					lastName: "Doe",
					email: "john.doe@gmail.com",
					phone: "(123) 456-7890",
					address: "123 Main St, City, State 12345",
					linkedinURL: "linkedin.com/in/johndoe",
					website: "www.johndoe.com"
				}
			},
			companyInfo: {
				label: "Company Information",
				companyName: "Company Name",
				companyAddress: "Company Address",
				companyHiringManagerName: "Hiring Manager Name"
			},
			introduction: {
				label: "Introduction",
				placeholders: {
					introduction: "Write your introduction paragraph here..."
				}
			},
			body: {
				label: "Body",
				placeholders: {
					body: "Write your body paragraph here..."
				}
			},
			closing: {
				label: "Closing",
				placeholders: {
					closing: "Write your closing paragraph here..."
				}
			}
		}
	},
	fr: {
		common: {
			edit: "Modifier",
			original: "Original",
			editRewrite: "Modifier / Réécrire avec IA",
			feedback: "Retour",
			rewriting: "Réécriture...",
			formalize: "Formaliser",
			shorten: "Raccourcir",
			elaborate: "Elaborer",
			requiredFieldsMissing: "Champs requis manquants",
			close: "Fermer",
			rephraseTooltip: "Utilisez ces boutons pour réécrire le contenu de la section. Confirmez ou Annulez après chaque modification.",
			confirm: "Confirmer",
			cancel: "Annuler"
		},
		sections: {
			contact: {
				label: "Informations de contact",
				firstName: "Prénom",
				lastName: "Nom",
				email: "Email",
				phone: "Téléphone",
				address: "Adresse",
				linkedinURL: "URL LinkedIn",
				website: "Site web"
			},
			introduction: {
				label: "Introduction",
				placeholders: {
					introduction: "Écrivez votre paragraphe d'introduction ici..."
				}
			},
			body: {
				label: "Corps",
				placeholders: {
					body: "Écrivez votre paragraphe de corps ici..."
				}
			},
			closing: {
				label: "Conclusion",
				placeholders: {
					closing: "Écrivez votre paragraphe de conclusion ici..."
				}
			}
		}
	}
};
