import React, { useState, useEffect, useCallback } from "react";
import { useAppContext } from "../../contexts/AppContext";
import { useAuth } from "../../contexts/AuthContext";

import { useParams, useNavigate } from "react-router-dom";
import { StopIcon } from "@heroicons/react/24/solid";
import { AlertCircle, Mic, RefreshCw } from "lucide-react";
import AudioVisualizer from "../Utility/AudioVisualizer";
import VapiMicrophoneVisualizer from "../Utility/VapiMicrophoneVisualizer";
import { SessionService } from "../../services/SessionService";
import { SessionView } from "../../types/SessionView";
import { Button } from "../../components/shadcn-ui/Button";
import { NavigateService } from "../../services/NavigateService";
import Spinner from "../Utility/Spinner";
import posthog from "posthog-js";
import { Switch } from "../../components/shadcn-ui/Switch";
import { useQueueSystem } from "../../hooks/useQueueSystem";
import { useTranslation } from "../Utility/UseTranslation";

const Activity: React.FC = () => {
	const {
		vapi,
		on,
		off,
		startCall,
		stopCall,
		isCallActive,
		toggleMute,
		isInitialSpeech,
		callStatus,
		status,

		activityMessages
	} = useAppContext();
	const { userId, instageUser } = useAuth();

	const { sessionId } = useParams();
	const navigate = useNavigate();
	const [currentSession, setCurrentSession] = useState<SessionView | null>(null);
	const [isEndCallModalVisible, setIsEndCallModalVisible] = useState(false);
	const [isGeneratingSummary, setIsGeneratingSummary] = useState(false);
	const [micPermission, setMicPermission] = useState<"granted" | "denied" | "prompt">("prompt");
	const [showTranscript, setShowTranscript] = useState(false);
	const [isWaiting, setIsWaiting] = useState(false);
	const [isPending, setIsPending] = useState(false);
	const { t } = useTranslation("extraUtility");
	const {
		timeLeft,
		isLoading: queueIsLoading,
		joinCallQueue,
		leaveCallQueue,
		startCall: queueStartCall
	} = useQueueSystem({
		userId: instageUser?.id || "",
		onReadyToStart: handleReadyToStart,
		onCancelPendingCall: handleCancelPendingCall
	});

	const checkMicrophonePermission = useCallback(async () => {
		try {
			const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
			// If we get here, permission was granted
			stream.getTracks().forEach((track) => track.stop()); // Clean up
			setMicPermission("granted");
			return true;
		} catch (err) {
			console.error("Microphone permission error:", err);
			// Check if permission was denied
			const permission = await navigator.permissions.query({ name: "microphone" as PermissionName });
			setMicPermission(permission.state as "granted" | "denied" | "prompt");

			// Set up permission change listener
			permission.addEventListener("change", (e) => {
				setMicPermission((e.target as PermissionStatus).state as "granted" | "denied" | "prompt");

				try {
					posthog.capture(`MINOR: Microphone Permission Changed to ${permission.state}`, {
						userId: instageUser?.fronteggId,
						timestamp: new Date().toISOString(),
						permission: permission.state
					});
				} catch (err) {
					console.error("Error capturing event 'MINOR: Microphone Permission Changed':", err);
				}
			});

			return false;
		}
	}, []);

	const handleStartCall = useCallback(
		async (session: SessionView) => {
			if (!sessionId || !vapi || !userId || status !== "connected") return;

			const hasMicAccess = await checkMicrophonePermission();
			if (!hasMicAccess) {
				return;
			}

			startCall({
				sessionId,
				session
			}).then((vapiCall) => {
				if (vapiCall) {
					queueStartCall(sessionId, vapiCall.id);
				}
				// Additional logic if needed
			});
		},
		[sessionId, startCall, vapi, status, userId, checkMicrophonePermission, queueStartCall]
	);

	useEffect(() => {
		checkMicrophonePermission();
	}, [checkMicrophonePermission]);

	useEffect(() => {
		if (!sessionId || micPermission !== "granted" || !instageUser) return;

		SessionService.getSession(sessionId).then(async (session) => {
			if (session) {
				setCurrentSession(session);
				console.log("Joining call queue");
				const data = await joinCallQueue(sessionId);
				console.log("Joined call queue", data);
				if (data.hasToWait) {
					setIsWaiting(true);
					console.log("Waiting for queue spot");
					// do nothing, we are waiting for our spot in the queue
				} else {
					console.log("Not waiting, starting call");
					handleStartCall(session);
				}
			}
		});
	}, [handleStartCall, sessionId, micPermission, joinCallQueue, instageUser]);

	useEffect(() => {
		if (callStatus === "ended") {
			setIsGeneratingSummary(true);
		}
	}, [callStatus]);

	useEffect(() => {
		const handleSummaryGenerated = (data: { assignmentId: string; scheduleId: string }) => {
			if (currentSession?.activityType === "onboarding") {
				NavigateService.navToUser();
			} else if (data.assignmentId && userId && data.scheduleId) {
				NavigateService.navToUserAssignment(data.assignmentId, userId, data.scheduleId);
			}
		};

		on("summaryGenerated", handleSummaryGenerated);

		return () => {
			off("summaryGenerated", handleSummaryGenerated);
		};
	}, [on, off, currentSession, userId]);

	const handleLeaveQueue = () => {
		if (sessionId) {
			console.log("Leaving queue");
			leaveCallQueue(sessionId);
			setIsWaiting(false);
			navigate(-1);
		}
	};

	function handleReadyToStart() {
		// called when the queue is ready to start aka leaving the waiting room
		// setIsWaiting(false);

		setIsPending(true);
	}

	function handleCancelPendingCall() {
		navigate(-1);
	}

	const handleStopClick = () => {
		toggleMute();
		setIsEndCallModalVisible(true);
	};

	const handleResume = () => {
		toggleMute();
		setIsEndCallModalVisible(false);
	};

	const handleEndCall = () => {
		setIsEndCallModalVisible(false);
		stopCall();
	};

	const handleStartPendingCall = () => {
		setIsPending(false);
		setIsWaiting(false);
		if (currentSession) {
			handleStartCall(currentSession);
		}
	};

	const MicrophonePermission = ({
		micPermission,
		onCheckPermission,
		className = ""
	}: {
		micPermission: "granted" | "denied" | "prompt";
		onCheckPermission: () => void;
		className?: string;
	}) => {
		const isDenied = micPermission === "denied";
		const { t } = useTranslation("extraUtility");
		return (
			<div className="flex flex-col items-center justify-center w-full max-w-md mx-auto">
				<div className="w-full rounded-lg border bg-card text-card-foreground p-4">
					<div className="flex items-center gap-3 mb-3">
						<AlertCircle className="h-5 w-5 text-gray-700 flex-shrink-0" />
						<h2 className="text-lg font-semibold text-gray-900">{isDenied ? "Microphone Access Needed" : "Enable Your Microphone"}</h2>
					</div>
					<p className="text-gray-600">
						{isDenied
							? "This app needs microphone access to work properly. Please enable it in your browser settings."
							: "Please allow microphone access when your browser shows the permission prompt."}
					</p>
					{isDenied ? (
						<div className="space-y-6 w-full">
							<div className="mt-6">
								<h3 className="font-medium mb-3">{t("activity.toEnableYourMicrophone")}</h3>
								<ol className="space-y-2 text-sm text-gray-600">
									<li className="flex items-start">
										<span className="font-medium mr-2">1.</span>
										{t("activity.checkYourBrowserSettingsPermissions")}
									</li>
									<li className="flex items-start">
										<span className="font-medium mr-2">2.</span>
										{t("activity.selectAllowForMicrophoneAccess")}
									</li>
									<li className="flex items-start">
										<span className="font-medium mr-2">3.</span>
										{t("activity.clickTryAgainButtonBelow")}
									</li>
								</ol>
							</div>

							<Button
								onClick={() => window.location.reload()}
								className="w-full"
								size="lg"
								aria-label="Reload page to retry microphone access"
							>
								<RefreshCw className="h-4 w-4 mr-2" />
								{t("activity.tryAgain")}
							</Button>
						</div>
					) : (
						<Button
							onClick={onCheckPermission}
							className="w-full mt-6"
							size="lg"
							aria-label="Enable microphone access for the application"
						>
							<Mic className="h-4 w-4 mr-2" />
							{t("activity.enableMicrophone")}
						</Button>
					)}
				</div>
			</div>
		);
	};

	if (micPermission !== "granted") {
		return <MicrophonePermission micPermission={micPermission} onCheckPermission={checkMicrophonePermission} />;
	}

	if (isInitialSpeech) {
		return (
			<div className="flex items-center justify-center h-full min-h-[calc(100vh-4rem-4rem)]">
				<div className="relative w-80 h-80">
					{/* Expanding rings */}
					{[...Array(3)].map((_, index) => (
						<div
							key={index}
							className="absolute inset-0 rounded-full border border-[#00a9af] animate-ripple-fade"
							style={{
								animationDelay: `${index * 1333}ms`
							}}
						/>
					))}

					{/* Center text */}
					<div className="absolute inset-0 flex items-center justify-center flex-col">
						<span className="text-xl font-bold text-[#00a9af] whitespace-nowrap">
							{isWaiting && !isPending && t("activity.WaitingForGuide")}
							{!isWaiting && !isPending && t("activity.ConnectingToAssistant")}
							{isWaiting && isPending && t("activity.ReadyToConnect")}
						</span>

						<div className="flex items-center justify-center flex-col">
							{(isWaiting || isPending) && (
								<Button
									onClick={handleLeaveQueue}
									variant="outline"
									aria-label="Leave the current waiting queue and return to previous page"
								>
									{t("activity.leaveQueue")}
								</Button>
							)}
							{isPending && (
								<Button onClick={handleStartPendingCall} aria-label="Start the pending call with your AI Assistant">
									{t("activity.connect")}
								</Button>
							)}
						</div>

						{isPending && (
							<>
								{timeLeft !== -1 && (
									<p>
										{t("TimeLeft")}: {timeLeft} {t("seconds")}
									</p>
								)}
							</>
						)}
					</div>
				</div>
			</div>
		);
	}

	if (isGeneratingSummary) {
		return (
			<div className="flex flex-col items-center justify-center h-full min-h-[calc(100vh-4rem-4rem)]">
				<Spinner />
				<span title="Generating summary" className="mt-4 text-xl font-bold text-[#00a9af]">
					{t("activity.GeneratingSummary")}
				</span>
			</div>
		);
	}

	return (
		<>
			<div className="h-full">
				{/* Main content */}
				<div className="flex-grow pb-24 overflow-y-auto">
					{!showTranscript ? (
						<>
							<h2 className="text-2xl font-semibold text-gray-800 text-center">
								{`Live: ${
									currentSession?.scheduleDescription
										? `"${currentSession.scheduleDescription}"`
										: currentSession?.activityType === "onboarding"
										? "Onboarding Session"
										: "Reflection Session"
								}`}
							</h2>
							<div className="w-full max-w-3xl mx-auto flex flex-col gap-20 pt-12">
								{isCallActive && (
									<div className="h-48">
										<VapiMicrophoneVisualizer />
									</div>
								)}
								<AudioVisualizer isVapiActive={isCallActive} />
							</div>
						</>
					) : (
						// Transcript Panel
						<div className="w-full max-w-3xl mx-auto overflow-y-auto">
							<div className="flex flex-col gap-4">
								{activityMessages
									.filter((msg) => msg.role !== "system")
									.map((msg, index) => (
										<div key={index} className={`flex ${msg.role === "assistant" ? "justify-start" : "justify-end"}`}>
											<div
												className={`max-w-[80%] rounded-lg p-3 ${
													msg.role === "assistant" ? "bg-gray-100 text-gray-800" : "bg-[#00a9af] text-white"
												}`}
											>
												{msg.content}
											</div>
										</div>
									))}
							</div>
						</div>
					)}
				</div>

				{/* End Call Modal */}
				{isEndCallModalVisible && (
					<div className="fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center">
						<div className="bg-white rounded-lg p-6 w-11/12 max-w-md">
							<h2 title="End Call Modal Title" className="text-2xl font-semibold text-center mb-6">
								{t("activity.areYouSure")}
							</h2>
							<div className="flex gap-4 justify-between">
								<Button
									name="Resume Call"
									title="Resume Call"
									aria-label="Resume Call"
									className="flex-1"
									size="lg"
									onClick={handleResume}
								>
									{t("activity.resumeCall")}
								</Button>
								<Button
									name="End Call"
									title="End Call"
									aria-label="End Call"
									className="flex-1"
									size="lg"
									variant="warning"
									onClick={handleEndCall}
								>
									{t("activity.endCall")}
								</Button>
							</div>
						</div>
					</div>
				)}
			</div>

			{/* Footer */}
			<div className="fixed bottom-0 left-0 right-0 border-t border-gray-200 bg-[#f6f3ed]">
				<div className="container mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl py-6">
					<div className="relative flex items-center">
						{/* Transcript Toggle - positioned absolutely on the left */}
						{isCallActive && (
							<div className="absolute left-0 flex items-center gap-2">
								<Switch
									checked={showTranscript}
									onCheckedChange={setShowTranscript}
									className="data-[state=checked]:bg-[#00a9af]"
									aria-label={`${showTranscript ? "Hide" : "Show"} conversation transcript`}
								/>
								<span className="text-sm text-gray-600 hidden sm:inline">{t("activity.showTranscript")}</span>
								<span className="text-sm text-gray-600 sm:hidden">{t("activity.transcript")}</span>
							</div>
						)}

						<div className="flex-1 flex justify-center">
							{isCallActive && (
								<Button
									name="Show End Call Modal"
									title="Show End Call Modal"
									aria-label="Show End Call Modal"
									onClick={handleStopClick}
									className="h-12 w-12 rounded-full bg-[#262626] flex items-center justify-center"
								>
									<StopIcon className="h-6 w-6 text-white" />
								</Button>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Activity;
