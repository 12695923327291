import React, { useState, useRef, useEffect } from "react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { Tooltip, TooltipContent, TooltipTrigger, TooltipProvider } from "../shadcn-ui/Tooltip";

interface CustomTooltipProps {
	content: string;
	image?: string;
}
export const CustomTooltip: React.FC<CustomTooltipProps> = ({ content, image }) => {
	return (
		<TooltipProvider>
			<Tooltip>
				<TooltipTrigger asChild>
					<div
						tabIndex={0}
						role="button"
						className="text-gray-500 hover:text-[#00a9af] focus:outline-none cursor-pointer"
						aria-label="More information"
					>
						<InformationCircleIcon className="h-4 w-4" />
					</div>
				</TooltipTrigger>
				<TooltipContent className="w-64 sm:w-72 md:w-80 max-w-[256px] bg-white text-gray-700 border border-gray-200" sideOffset={5}>
					{image && <img src={image} alt="Example of app screen" className="w-full h-auto" />}
					<p className="text-sm text-left">{content}</p>
				</TooltipContent>
			</Tooltip>
		</TooltipProvider>
	);
};

export const CustomTooltipOld: React.FC<CustomTooltipProps> = ({ content, image }) => {
	const [isVisible, setIsVisible] = useState(false);
	const tooltipRef = useRef<HTMLDivElement>(null);
	const containerRef = useRef<HTMLSpanElement>(null);

	const toggleTooltip = () => {
		setIsVisible(!isVisible);
	};

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
				setIsVisible(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	return (
		<span ref={containerRef} className="inline-flex items-center ml-1 relative">
			<div
				role="button"
				tabIndex={0}
				onClick={toggleTooltip}
				onKeyDown={(e) => {
					if (e.key === "Enter" || e.key === " ") {
						toggleTooltip();
					}
				}}
				className="text-gray-500 hover:text-[#00a9af] focus:outline-none cursor-pointer"
				aria-label="More information"
			>
				<InformationCircleIcon className="h-4 w-4" />
			</div>
			{isVisible && (
				<div
					ref={tooltipRef}
					className="absolute z-10 w-64 p-2 text-sm text-left text-gray-700 bg-white rounded-lg shadow-lg border border-gray-200 sm:w-72 md:w-80"
					// style={{
					// 	bottom: "calc(100% + 5px)",
					// 	left: "50%",
					// 	transform: "translateX(-50%)"
					// }}
					style={{
						bottom: "calc(100% + 10px)",
						left: "50%",
						transform: "translateX(-50%)",
						maxWidth: "256px",
						whiteSpace: "normal"
					}}
				>
					{image && <img src={image} alt="Example of app screen" className="w-full h-auto" />}
					{content}
				</div>
			)}
		</span>
	);
};

export default CustomTooltip;
