import * as React from "react";
import { cn } from "../../lib/utils";
import { ChevronDown, ChevronRight } from "lucide-react";
import { useState } from "react";

interface SidebarSectionProps {
	icon?: React.ElementType;
	label: string;
	children?: React.ReactNode;
	isCollapsed?: boolean;
	defaultExpanded?: boolean;
	visible?: boolean;
}

export function SidebarSection({ icon: Icon, label, children, isCollapsed, defaultExpanded = false, visible = true }: SidebarSectionProps) {
	const [isExpanded, setIsExpanded] = useState(defaultExpanded);

	if (!visible) return null;
	if (isCollapsed) {
		return (
			<div className="relative group py-2">
				{Icon && (
					<button className="w-full flex items-center justify-center px-3 py-2">
						<Icon className="h-5 w-5 text-muted-foreground" />
					</button>
				)}
				<div className="absolute left-full top-0 ml-2 hidden group-hover:block bg-popover rounded-md shadow-md p-2 z-50 min-w-[200px]">
					<div className="absolute right-full top-0 h-full w-2" />
					<div className="flex flex-col gap-1">
						{React.Children.toArray(children).map((child) => {
							if (React.isValidElement(child)) {
								return React.cloneElement(child, {
									...child.props,
									isCollapsed: false
								});
							}
							return child;
						})}
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className="space-y-1">
			<button
				onClick={() => setIsExpanded(!isExpanded)}
				className={cn(
					"flex items-center w-full px-3 py-2 text-[14px] rounded-md hover:bg-accent",
					children ? "justify-between" : "text-muted-foreground"
				)}
			>
				<div className="flex items-center gap-3">
					{Icon && <Icon className="h-5 w-5 text-muted-foreground" />}
					<span>{label}</span>
				</div>
				{children && (
					<div className="text-muted-foreground">
						{isExpanded ? <ChevronDown className="h-4 w-4" /> : <ChevronRight className="h-4 w-4" />}
					</div>
				)}
			</button>
			{isExpanded && children && (
				<div className="pl-4 ml-2 border-l space-y-1">
					{React.Children.toArray(children).map((child) => {
						if (React.isValidElement(child)) {
							return React.cloneElement(child, {
								...child.props,
								isCollapsed: isCollapsed
							});
						}
						return child;
					})}
				</div>
			)}
		</div>
	);
}
