import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "../../lib/utils";

const sidebarVariants = cva("flex flex-col transition-all duration-300 ease-in-out", {
	variants: {
		variant: {
			default: "bg-white",
			floating: "bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60 shadow-lg",
			minimal: "bg-transparent",
			colored: "bg-primary text-primary-foreground"
		},
		size: {
			default: "w-64",
			sm: "w-48",
			lg: "w-80",
			collapsed: "w-17"
		},
		position: {
			left: "left-0",
			right: "right-0"
		}
	},
	defaultVariants: {
		variant: "default",
		size: "default",
		position: "left"
	}
});

export interface SidebarProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof sidebarVariants> {
	children?: React.ReactNode;
}

const Sidebar = React.forwardRef<HTMLDivElement, SidebarProps>(({ className, variant, size, position, children, ...props }, ref) => {
	return (
		<div ref={ref} className={cn(sidebarVariants({ variant, size, position, className }))} {...props}>
			{children}
		</div>
	);
});
Sidebar.displayName = "Sidebar";

export { Sidebar, sidebarVariants };
