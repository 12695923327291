import React, { useState } from "react";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "../../components/shadcn-ui/Dialog";
import { Button } from "../../components/shadcn-ui/Button";
import { useTranslation } from "./UseTranslation";

interface ConfirmationModalProps {
	title: string;
	description: string;
	onConfirm: () => void;
	isOpen: boolean;
	onClose: () => void;
	note?: string;
}

const ConfirmationModal = ({ title, description, note, onConfirm, isOpen, onClose }: ConfirmationModalProps) => {
	const { t } = useTranslation("userSessionPage");
	return (
		<Dialog open={isOpen} onOpenChange={onClose}>
			<DialogContent style={{ minHeight: "200px" }}>
				{" "}
				{/* Adjust the height as needed */}
				<DialogHeader>
					<DialogTitle>{title}</DialogTitle>
					<DialogDescription style={{ fontSize: "16px", marginTop: "20px" }}>{description}</DialogDescription>
					<DialogDescription style={{ fontSize: "14px", marginTop: "20px" }}>{note}</DialogDescription>
				</DialogHeader>
				<DialogFooter style={{ marginTop: "auto" }}>
					<Button variant="outline" onClick={onClose} style={{ marginRight: "10px" }}>
						{t("submitDialog.cancel")}
					</Button>
					<Button onClick={onConfirm}>{t("submitDialog.confirm")}</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};

export default ConfirmationModal;
