export const SupportPageLocale = {
	en: {
		title: "Support",
		header: {
			pageTitle: "Support"
		},
		helpSection: {
			title: "Need Help?",
			description:
				"We're here to assist you. Please send us a message through Intercom. 9am-6pm EST. Any messages sent outside of these hours will be responded to the next business day.",
			liveChatButton: "Live Chat Support",
			emailButton: "Email Us",
			orText: "Or",
			supportInfo: "Please provide the following to your support agent: email, institution/company, and a detailed description of your issue."
		},
		faqSection: {
			title: "FAQ",
			search: {
				placeholder: "Search FAQs...",
				filterLabel: "Filter by type:",
				filterOptions: {
					all: "All",
					general: "General",
					security: "Security"
				}
			},
			helpDocLink: "View Help Doc",
			viewControls: {
				viewMore: "View All",
				viewLess: "View Less"
			}
		},
		faqs: {
			q1: "What model of Reflection are we using?",
			a1: "We are using COBS but are looking at ways to be able to select which model you are hoping for.",
			q2: "How do I create a reflection assignment?",
			a2: "You can create a reflection assignment by going to the Assignments page and clicking the 'Create Assignment' button. You can then select the model you want to use and create your assignment.",
			q3: "Can administrators create their own questions?",
			a3: "Not at this time, if it's something you think would add value please let us know.",
			q4: "Where is the data (including audio) stored and processed?",
			a4: "All data, including audio files, is processed and stored in Canada using AWS servers, with encryption applied in both transit and at rest.",
			q5: "Is it an open system AI tool or a closed system AI tool?",
			a5: "We use OpenAI. OpenAI does not train on any data submitted through the API (And neither do we).",
			q6: "What is the purpose of recording audio in the Instage tool?",
			a6: "The audio is recorded to create a speech-to-text transcription, which is used for reflection and practice sessions. The transcription helps capture the spoken words for later review.",
			q7: "Who has access to the recorded audio?",
			a7: "The recorded audio is available to the student for personal review. If the instructor opts for it, the instructor can also access the file.",
			q8: "How long is the audio data stored, and can it be deleted sooner?",
			a8: "Audio data is stored for 12 months by default. However, it can be deleted sooner by user request or based on school admin preferences. Users will soon have the ability to delete their files at any time, once the feature is live.",
			q9: "Is the audio data shared with third parties?",
			a9: "No, audio data is not shared with OpenAI or any other third-party services beyond the transcription service. Instage does not use the data for AI audio technology or data selling purposes.",
			q10: "Can I participate without having my voice recorded?",
			a10: "If you have privacy concerns regarding the use of the audio recording feature, you may inquire with your school admin or Instage for alternative methods of participation without engaging with this feature.",
			q11: "Does Instage use the audio data for AI development?",
			a11: "No, Instage is not involved in AI audio technology development or adjacent fields. The audio recordings are solely used to support educational activities, and there is no intent to commercialize or collect this data for other purposes.",
			q12: "How do I complete my reflection assignment?",
			a12: "You can complete your reflection assignment by navigating to the home page and selecting Start Session. If the call is ready to be completed, you will be able to do it via a phone call or on the web."
		}
	},
	fr: {
		title: "Support",
		header: {
			pageTitle: "Support"
		},
		helpSection: {
			title: "Besoin d'aide ?",
			description:
				"Nous sommes là pour vous aider. Veuillez nous envoyer un message via Intercom. 9h-18h EST. Tout message envoyé en dehors de ces heures recevra une réponse le jour ouvrable suivant.",
			liveChatButton: "Support Chat en Direct",
			emailButton: "Envoyez-nous un Email",
			orText: "Ou",
			supportInfo:
				"Veuillez fournir les informations suivantes à votre agent de support : email, institution/entreprise, et une description détaillée de votre problème."
		},
		faqSection: {
			title: "FAQ",
			search: {
				placeholder: "Rechercher dans les FAQ...",
				filterLabel: "Filtrer par type :",
				filterOptions: {
					all: "Tous",
					general: "Général",
					security: "Sécurité"
				}
			},
			helpDocLink: "Voir le Document d'Aide",
			viewControls: {
				viewMore: "Voir Tout",
				viewLess: "Voir Moins"
			}
		},
		faqs: {
			q1: "Quel modèle de Réflexion utilisons-nous ?",
			a1: "Nous utilisons COBS mais nous cherchons des moyens de pouvoir sélectionner le modèle que vous espérez.",
			q2: "Comment créer une tâche de réflexion ?",
			a2: "Vous pouvez créer une tâche de réflexion en allant sur la page des Tâches et en cliquant sur le bouton 'Créer une Tâche'. Vous pouvez ensuite sélectionner le modèle que vous souhaitez utiliser et créer votre tâche.",
			q3: "Les administrateurs peuvent-ils créer leurs propres questions ?",
			a3: "Pas pour le moment, si vous pensez que cela ajouterait de la valeur, veuillez nous le faire savoir.",
			q4: "Où les données (y compris l'audio) sont-elles stockées et traitées ?",
			a4: "Toutes les données, y compris les fichiers audio, sont traitées et stockées au Canada en utilisant les serveurs AWS, avec un chiffrement appliqué à la fois en transit et au repos.",
			q5: "Est-ce un outil d'IA à système ouvert ou à système fermé ?",
			a5: "Nous utilisons OpenAI. OpenAI ne s'entraîne pas sur les données soumises via l'API (et nous non plus).",
			q6: "Quel est le but de l'enregistrement audio dans l'outil Instage ?",
			a6: "L'audio est enregistré pour créer une transcription de la parole en texte, qui est utilisée pour les sessions de réflexion et de pratique. La transcription aide à capturer les mots prononcés pour une révision ultérieure.",
			q7: "Qui a accès à l'audio enregistré ?",
			a7: "L'audio enregistré est disponible pour l'étudiant pour une révision personnelle. Si l'instructeur le souhaite, il peut également accéder au fichier.",
			q8: "Combien de temps les données audio sont-elles stockées, et peuvent-elles être supprimées plus tôt ?",
			a8: "Les données audio sont stockées pendant 12 mois par défaut. Cependant, elles peuvent être supprimées plus tôt sur demande de l'utilisateur ou en fonction des préférences de l'administrateur de l'école. Les utilisateurs auront bientôt la possibilité de supprimer leurs fichiers à tout moment, une fois la fonctionnalité en ligne.",
			q9: "Les données audio sont-elles partagées avec des tiers ?",
			a9: "Non, les données audio ne sont pas partagées avec OpenAI ou tout autre service tiers au-delà du service de transcription. Instage n'utilise pas les données pour la technologie audio IA ou à des fins de vente de données.",
			q10: "Puis-je participer sans que ma voix soit enregistrée ?",
			a10: "Si vous avez des préoccupations concernant l'utilisation de la fonctionnalité d'enregistrement audio, vous pouvez vous renseigner auprès de votre administrateur scolaire ou d'Instage pour des méthodes alternatives de participation sans utiliser cette fonctionnalité.",
			q11: "Instage utilise-t-il les données audio pour le développement de l'IA ?",
			a11: "Non, Instage n'est pas impliqué dans le développement de la technologie audio IA ou des domaines adjacents. Les enregistrements audio sont uniquement utilisés pour soutenir les activités éducatives, et il n'y a aucune intention de commercialiser ou de collecter ces données à d'autres fins.",
			q12: "Comment puis-je compléter ma tâche de réflexion ?",
			a12: "Vous pouvez compléter votre tâche de réflexion en naviguant vers la page d'accueil et en sélectionnant Démarrer la Session. Si l'appel est prêt à être complété, vous pourrez le faire via un appel téléphonique ou sur le web."
		}
	}
};
