import React, { useState, useEffect, useCallback } from "react";
import BackButton from "../Utility/BackButton";
import { Button } from "../shadcn-ui/Button";
import { Checkbox } from "../shadcn-ui/Checkbox";
import CustomTooltip from "../Utility/CustomTooltip";
import { Textarea } from "../shadcn-ui/Textarea";
import { Input } from "../shadcn-ui/Input";
import { ClientService } from "../../services/ClientService";
import Spinner from "../Utility/Spinner";
import { useToast } from "../../hooks/useToast";
import { useTranslation } from "../Utility/UseTranslation";
import CopyUrl from "../Utility/CopyUrl";

const clampValue = (value: string, min: number, max: number): string => {
	const numValue = parseInt(value) || 0;
	return Math.min(Math.max(numValue, min), max).toString();
};

interface ClientSettings {
	allowEmailReports: boolean;
	allowUserIdentification: boolean;
	allowTranscript: boolean;
	allowAudio: boolean;
	allowNotifyAvailabilityWindow: boolean;
	allowNotify24HoursBefore: boolean;
	allowNotifyDueDate: boolean;
	audioStorageLength: string;
	assignmentCallTimeLimit: string;
	selfServeCallTimeLimit: string;
	highPriorityDef: string;
	mediumPriorityDef: string;
	lowPriorityDef: string;
	highPriorityContactInfo: string;
	jobSearchResources: string;
	allowGenericSignup: boolean;
}

const AdminSettings: React.FC = () => {
	const { toast } = useToast();
	const [isLoading, setIsLoading] = useState(true);
	const [initialSettings, setInitialSettings] = useState<ClientSettings | null>(null);
	const [allowEmailReports, setAllowEmailReports] = useState(true);
	const [allowUserIdentification, setAllowUserIdentification] = useState(true);
	const [allowTranscript, setAllowTranscript] = useState(true);
	const [allowAudio, setAllowAudio] = useState(true);
	const [allowNotifyAvailabilityWindow, setAllowNotifyAvailabilityWindow] = useState(true);
	const [allowNotify24HoursBefore, setAllowNotify24HoursBefore] = useState(true);
	const [allowNotifyDueDate, setAllowNotifyDueDate] = useState(true);
	const [audioStorageLength, setAudioStorageLength] = useState("365");
	const [assignmentCallTimeLimit, setAssignmentCallTimeLimit] = useState("60");
	const [selfServeCallTimeLimit, setSelfServeCallTimeLimit] = useState("60");
	const [highPriorityDef, setHighPriorityDef] = useState("");
	const [mediumPriorityDef, setMediumPriorityDef] = useState("");
	const [lowPriorityDef, setLowPriorityDef] = useState("");
	const [highPriorityContactInfo, setHighPriorityContactInfo] = useState("");
	const [jobSearchResources, setJobSearchResources] = useState("");
	const [allowGenericSignup, setAllowGenericSignup] = useState(false);
	const [signupUrl, setSignupUrl] = useState<string | null>(null);
	const { t } = useTranslation("adminSettings");

	// Helper function to get current settings
	const getCurrentSettings = (): ClientSettings => ({
		allowEmailReports,
		allowUserIdentification,
		allowTranscript,
		allowAudio,
		allowNotifyAvailabilityWindow,
		allowNotify24HoursBefore,
		allowNotifyDueDate,
		audioStorageLength,
		assignmentCallTimeLimit,
		selfServeCallTimeLimit,
		highPriorityDef,
		mediumPriorityDef,
		lowPriorityDef,
		highPriorityContactInfo,
		jobSearchResources,
		allowGenericSignup
	});

	// Helper function to check if settings have changed
	const hasSettingsChanged = (): boolean => {
		if (!initialSettings) return false;
		const currentSettings = getCurrentSettings();
		return Object.keys(initialSettings).some(
			(key) => initialSettings[key as keyof ClientSettings] !== currentSettings[key as keyof ClientSettings]
		);
	};

	// Function to update all state values from settings
	const updateStateFromSettings = (settings: ClientSettings) => {
		setAllowEmailReports(settings.allowEmailReports);
		setAllowUserIdentification(settings.allowUserIdentification);
		setAllowTranscript(settings.allowTranscript);
		setAllowAudio(settings.allowAudio);
		setAllowNotifyAvailabilityWindow(settings.allowNotifyAvailabilityWindow);
		setAllowNotify24HoursBefore(settings.allowNotify24HoursBefore);
		setAllowNotifyDueDate(settings.allowNotifyDueDate);
		setAudioStorageLength(settings.audioStorageLength);
		setAssignmentCallTimeLimit(settings.assignmentCallTimeLimit);
		setSelfServeCallTimeLimit(settings.selfServeCallTimeLimit);
		setHighPriorityDef(settings.highPriorityDef);
		setMediumPriorityDef(settings.mediumPriorityDef);
		setLowPriorityDef(settings.lowPriorityDef);
		setHighPriorityContactInfo(settings.highPriorityContactInfo);
		setJobSearchResources(settings.jobSearchResources);
		setAllowGenericSignup(settings.allowGenericSignup);
	};

	// Function to fetch client settings
	const fetchClientSettings = useCallback(async () => {
		try {
			setIsLoading(true);
			const client = await ClientService.getClient();
			console.log(`client`, client);
			const settings = {
				allowEmailReports: client.allowEmailReports,
				allowUserIdentification: client.allowUserIdentification,
				allowTranscript: client.allowTranscript,
				allowAudio: client.allowAudio,
				allowNotifyAvailabilityWindow: client.allowNotifyAvailabilityWindow,
				allowNotify24HoursBefore: client.allowNotify24HoursBefore,
				allowNotifyDueDate: client.allowNotifyDueDate,
				audioStorageLength: client.audioStorageLength.toString(),
				assignmentCallTimeLimit: client.assignmentCallTimeLimit.toString(),
				selfServeCallTimeLimit: client.selfServeCallTimeLimit.toString(),
				highPriorityDef: client.highPriorityDef,
				mediumPriorityDef: client.mediumPriorityDef,
				lowPriorityDef: client.lowPriorityDef,
				highPriorityContactInfo: client.highPriorityContactInfo,
				jobSearchResources: client.jobSearchResources,
				allowGenericSignup: client.allowGenericSignup
			};
			console.log(`settings`, client.allowGenericSignup);
			if (client.signupCode) {
				setSignupUrl(`${window.location.origin}/signup/${client.signupCode}`);
			}
			setInitialSettings(settings);
			updateStateFromSettings(settings);
		} catch (error) {
			console.error("Failed to load client settings:", error);
		} finally {
			setIsLoading(false);
		}
	}, []);

	useEffect(() => {
		fetchClientSettings();
	}, [fetchClientSettings]);

	const handleSave = async () => {
		try {
			const settings = {
				allowEmailReports,
				allowUserIdentification,
				allowTranscript,
				allowAudio,
				allowNotifyAvailabilityWindow,
				allowNotify24HoursBefore,
				allowNotifyDueDate,
				audioStorageLength: parseInt(audioStorageLength),
				assignmentCallTimeLimit: parseInt(assignmentCallTimeLimit),
				selfServeCallTimeLimit: parseInt(selfServeCallTimeLimit),
				highPriorityDef,
				mediumPriorityDef,
				lowPriorityDef,
				highPriorityContactInfo,
				jobSearchResources,
				allowGenericSignup
			};

			await ClientService.updateClient(settings);
			await fetchClientSettings(); // Refetch data after saving

			toast({
				title: t("saveButton.toast.success.title"),
				description: t("saveButton.toast.success.description"),
				variant: "default"
			});
		} catch (error) {
			console.error("Failed to save settings:", error);
			toast({
				title: t("saveButton.toast.error.title"),
				description: t("saveButton.toast.error.description"),
				variant: "destructive"
			});
		}
	};

	const handleStorageChange = (value: string, setter: (value: string) => void) => {
		const sanitizedValue = value.replace(/\D/g, ""); // Remove non-digits
		setter(sanitizedValue);
	};

	const handleStorageBlur = (value: string, setter: (value: string) => void) => {
		const clampedValue = clampValue(value, 1, 365);
		setter(clampedValue);
	};

	const handleCallTimeLimitChange = (value: string, setter: (value: string) => void) => {
		const sanitizedValue = value.replace(/\D/g, ""); // Remove non-digits
		setter(sanitizedValue);
	};

	const handleCallTimeLimitBlur = (value: string, setter: (value: string) => void) => {
		const clampedValue = clampValue(value, 30, 1000);
		setter(clampedValue);
		if (parseInt(value) < 30) {
			toast({
				title: "Invalid Duration",
				description: "Call time limit cannot be less than 30 minutes",
				variant: "destructive"
			});
		}
		if (parseInt(value) > 1000) {
			toast({
				title: "Invalid Duration",
				description: "Call time limit cannot be greater than 1000 minutes",
				variant: "destructive"
			});
		}
	};

	const SubTitle = ({ text }: { text: string }) => {
		return <p className="text-sm text-gray-600 mb-6">{text}</p>;
	};
	const SubTitleWithBold = ({ prefix, bold, suffix }: { prefix: string; bold: string; suffix: string }) => {
		return (
			<p className="text-sm text-gray-600 mb-6">
				<span className="font-medium">{prefix}</span> <span className="font-bold">{bold}</span> <span className="font-medium">{suffix}</span>
			</p>
		);
	};
	const PageTitle = ({ text }: { text: string }) => {
		return (
			<div className="flex justify-between items-center mb-6">
				<div className="flex items-center">
					<h2 className="text-2xl md:text-3xl font-bold text-[#16013e] mr-3">{t("title")}</h2>
				</div>
			</div>
		);
	};

	return (
		<div className="h-full pb-16">
			<BackButton />
			<PageTitle text={t("title")} />

			{isLoading ? (
				<div className="h-full flex items-center justify-center">
					<Spinner />
				</div>
			) : (
				<div className="space-y-8">
					<Panel title={t("emailReportSettings.title")}>
						<SubTitle text={t("emailReportSettings.description")} />
						<label className="flex items-center">
							<input
								type="radio"
								name="allowEmailReports"
								checked={allowEmailReports}
								onChange={() => setAllowEmailReports(true)}
								className="form-radio h-4 w-4 text-[#00a9af] focus:ring-[#00a9af]"
								aria-label="Enable email reports"
							/>
							<span className="ml-2 text-sm text-gray-700">{t("emailReportSettings.allowEmailReports")}</span>
						</label>
						<label className="flex items-center">
							<input
								type="radio"
								name="allowEmailReports"
								checked={!allowEmailReports}
								onChange={() => setAllowEmailReports(false)}
								className="form-radio h-4 w-4 text-[#00a9af] focus:ring-[#00a9af]"
								aria-label="Disable email reports"
							/>
							<span className="ml-2 text-sm text-gray-700">{t("emailReportSettings.doNotAllowEmailReports")}</span>
						</label>
					</Panel>

					<Panel title={t("studentSignupOptions.title")}>
						<SubTitle text={t("studentSignupOptions.description")} />
						<label className="flex items-center">
							<Checkbox
								id="allowTranscript"
								checked={allowGenericSignup}
								onCheckedChange={(checked: boolean) => setAllowGenericSignup(checked)}
								aria-label={t("studentSignupOptions.allowGenericSignup")}
							/>
							<span className="ml-2 text-sm text-gray-700">{t("studentSignupOptions.allowGenericSignup")}</span>
						</label>
						{allowGenericSignup && signupUrl && (
							<>
								<SubTitle text={t("studentSignupOptions.signupUrl")} />
								<CopyUrl url={signupUrl} label={t("studentSignupOptions.copyAssignmentLink")} />
							</>
						)}
					</Panel>

					<Panel title={t("summaryContentPermissions.title")}>
						<SubTitle text={t("summaryContentPermissions.description")} />
						<div className="space-y-4">
							<label className="flex items-center">
								<Checkbox
									id="allowUserIdentification"
									checked={allowUserIdentification}
									onCheckedChange={(checked: boolean) => setAllowUserIdentification(checked)}
									aria-label={t("summaryContentPermissions.allowUserIdentification.ariaLabel")}
								/>
								<span className="ml-2 text-sm text-gray-700">{t("summaryContentPermissions.allowUserIdentification.label")}</span>
							</label>
							<label className="flex items-center">
								<Checkbox
									id="allowTranscript"
									checked={allowTranscript}
									onCheckedChange={(checked: boolean) => setAllowTranscript(checked)}
									aria-label={t("summaryContentPermissions.allowTranscript.ariaLabel")}
								/>
								<span className="ml-2 text-sm text-gray-700">{t("summaryContentPermissions.allowTranscript.label")}</span>
							</label>
							<label className="flex items-center">
								<Checkbox
									id="allowAudio"
									checked={allowAudio}
									onCheckedChange={(checked: boolean) => setAllowAudio(checked)}
									aria-label={t("summaryContentPermissions.allowAudio.ariaLabel")}
								/>
								<span className="ml-2 text-sm text-gray-700">{t("summaryContentPermissions.allowAudio.label")}</span>
							</label>
						</div>
						<div className="mt-4 p-3 bg-gray-50 rounded-md">
							<p className="text-sm text-gray-600">
								<span className="font-medium">{t("summaryContentPermissions.note.prefix")}</span>{" "}
								{t("summaryContentPermissions.note.message")}
							</p>
						</div>
					</Panel>

					<Panel title={t("studentNotificationSettings.title")}>
						<SubTitleWithBold
							prefix={t("studentNotificationSettings.description.prefix")}
							bold={t("studentNotificationSettings.description.bold")}
							suffix={t("studentNotificationSettings.description.suffix")}
						/>

						<div className="space-y-6">
							<div className="flex items-center">
								<label className="flex items-center">
									<Checkbox
										id="allowNotifyAvailabilityWindow"
										checked={allowNotifyAvailabilityWindow}
										onCheckedChange={(checked: boolean) => setAllowNotifyAvailabilityWindow(checked)}
										aria-label={t("studentNotificationSettings.allowNotifyAvailabilityWindow.ariaLabel")}
									/>
									<span className="ml-2 text-sm text-gray-700">
										{t("studentNotificationSettings.allowNotifyAvailabilityWindow.label")}
									</span>
									<CustomTooltip
										content={t("studentNotificationSettings.allowNotifyAvailabilityWindow.tooltip")}
										image="https://resumedocumentsbucket.s3.ca-central-1.amazonaws.com/ReflectionCallNowAvailablePNG.JPG"
									/>
								</label>
							</div>

							<div className="flex items-center">
								<label className="flex items-center">
									<Checkbox
										id="allowNotify24HoursBefore"
										checked={allowNotify24HoursBefore}
										onCheckedChange={(checked: boolean) => setAllowNotify24HoursBefore(checked)}
										aria-label={t("studentNotificationSettings.allowNotify24HoursBefore.ariaLabel")}
									/>
									<span className="ml-2 text-sm text-gray-700">
										{t("studentNotificationSettings.allowNotify24HoursBefore.label")}
									</span>
									<CustomTooltip
										content={t("studentNotificationSettings.allowNotify24HoursBefore.tooltip")}
										image="https://resumedocumentsbucket.s3.ca-central-1.amazonaws.com/ReflectionDueTomorrowPNG.JPG"
									/>
								</label>
							</div>

							<div className="flex items-center">
								<label className="flex items-center">
									<Checkbox
										id="allowNotifyDueDate"
										checked={allowNotifyDueDate}
										onCheckedChange={(checked: boolean) => setAllowNotifyDueDate(checked)}
										aria-label={t("studentNotificationSettings.allowNotifyDueDate.ariaLabel")}
									/>
									<span className="ml-2 text-sm text-gray-700">{t("studentNotificationSettings.allowNotifyDueDate.label")}</span>
									<CustomTooltip
										content={t("studentNotificationSettings.allowNotifyDueDate.tooltip")}
										image="https://resumedocumentsbucket.s3.ca-central-1.amazonaws.com/ReflectionDueTodayPNG.JPG"
									/>
								</label>
							</div>
						</div>
						<div className="mt-4 p-3 bg-gray-50 rounded-md">
							<p className="text-sm text-gray-600">
								<span className="font-medium">{t("studentNotificationSettings.note.prefix")}</span>{" "}
								{t("studentNotificationSettings.note.message")}
							</p>
						</div>
					</Panel>

					<Panel title={t("dataStorageLimits.title")}>
						<div className="space-y-4">
							<div>
								<label className="block text-sm font-medium text-gray-700 mb-1">{t("dataStorageLimits.audioStorage.label")}</label>
								<Input
									type="number"
									value={audioStorageLength}
									onChange={(e) => handleStorageChange(e.target.value, setAudioStorageLength)}
									className="w-32"
									min="14"
									onBlur={() => handleStorageBlur(audioStorageLength, setAudioStorageLength)}
									aria-label={t("dataStorageLimits.audioStorage.ariaLabel")}
								/>
							</div>
						</div>
					</Panel>

					<Panel title={t("callTimeLimits.title")}>
						<div className="space-y-4">
							<div>
								<label className="block text-sm font-medium text-gray-700 mb-1">{t("callTimeLimits.assignmentCall.label")}</label>
								<Input
									type="number"
									value={assignmentCallTimeLimit}
									onChange={(e) => handleCallTimeLimitChange(e.target.value, setAssignmentCallTimeLimit)}
									className="w-32"
									min="30"
									onBlur={() => handleCallTimeLimitBlur(assignmentCallTimeLimit, setAssignmentCallTimeLimit)}
									aria-label={t("callTimeLimits.assignmentCall.ariaLabel")}
								/>
							</div>
							<div>
								<label className="block text-sm font-medium text-gray-700 mb-1">{t("callTimeLimits.selfServeCall.label")}</label>
								<Input
									type="number"
									value={selfServeCallTimeLimit}
									onChange={(e) => handleCallTimeLimitChange(e.target.value, setSelfServeCallTimeLimit)}
									className="w-32"
									min="30"
									onBlur={() => handleCallTimeLimitBlur(selfServeCallTimeLimit, setSelfServeCallTimeLimit)}
									aria-label={t("callTimeLimits.selfServeCall.ariaLabel")}
								/>
							</div>
						</div>
					</Panel>

					<Panel title={t("priorityComments.title")}>
						<SubTitle text={t("priorityComments.description")} />
						<div className="space-y-4">
							<div>
								<label className="block text-sm font-medium text-gray-700 mb-1">
									{t("priorityComments.highPriority.definition.label")}
								</label>
								<Textarea
									value={highPriorityDef}
									onChange={(e) => setHighPriorityDef(e.target.value)}
									placeholder={t("priorityComments.highPriority.definition.placeholder")}
									aria-label={t("priorityComments.highPriority.definition.ariaLabel")}
								/>
							</div>
							<div>
								<label className="block text-sm font-medium text-gray-700 mb-1">
									{t("priorityComments.highPriority.contactInstructions.label")}
								</label>
								<Textarea
									value={highPriorityContactInfo}
									onChange={(e) => setHighPriorityContactInfo(e.target.value)}
									placeholder={t("priorityComments.highPriority.contactInstructions.placeholder")}
									aria-label={t("priorityComments.highPriority.contactInstructions.ariaLabel")}
								/>
							</div>
							<div>
								<label className="block text-sm font-medium text-gray-700 mb-1">{t("priorityComments.mediumPriority.label")}</label>
								<Textarea
									value={mediumPriorityDef}
									onChange={(e) => setMediumPriorityDef(e.target.value)}
									placeholder={t("priorityComments.mediumPriority.placeholder")}
									aria-label={t("priorityComments.mediumPriority.ariaLabel")}
								/>
							</div>
							<div>
								<label className="block text-sm font-medium text-gray-700 mb-1">{t("priorityComments.lowPriority.label")}</label>
								<Textarea
									value={lowPriorityDef}
									onChange={(e) => setLowPriorityDef(e.target.value)}
									placeholder={t("priorityComments.lowPriority.placeholder")}
									aria-label={t("priorityComments.lowPriority.ariaLabel")}
								/>
							</div>
						</div>
					</Panel>

					<Panel title={t("jobSearchResources.title")}>
						<div>
							<SubTitle text={t("jobSearchResources.description.part1")} />

							<SubTitle text={t("jobSearchResources.description.part2")} />

							<Textarea
								value={jobSearchResources}
								onChange={(e) => setJobSearchResources(e.target.value)}
								placeholder={t("jobSearchResources.textarea.placeholder")}
								className="min-h-[150px]"
								aria-label={t("jobSearchResources.textarea.ariaLabel")}
							/>
						</div>
					</Panel>

					{/* Sticky footer */}
					<div className="fixed bottom-0 left-0 right-0 py-6 bg-white border-t border-gray-200">
						<div className="flex items-center mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl">
							<Button
								onClick={handleSave}
								disabled={!hasSettingsChanged()}
								className={!hasSettingsChanged() ? "opacity-50 cursor-not-allowed" : ""}
								aria-label={t("saveButton.ariaLabel")}
							>
								{t("saveButton.label")}
							</Button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

const Panel: React.FC<{ title: string; children: React.ReactNode }> = ({ title, children }) => (
	<div className="bg-white border border-[#eaecf0] rounded-lg p-6">
		<h3 className="text-lg font-semibold text-gray-800 mb-6">{title}</h3>
		<div className="space-y-4">{children}</div>
	</div>
);

export default AdminSettings;
