import React, { useMemo, useState } from "react";
import { format } from "date-fns";
import { AssignedSessionView } from "../../types/AssignedSessionView";
import { DataTable, ExtendedColumnDef, Filter } from "../shadcn-ui/DataTable";
import { DataTableActionMenu } from "../shadcn-ui/DataTableActionMenu";
import { Button } from "../shadcn-ui/Button";
import { ArrowDownTrayIcon, UserPlusIcon } from "@heroicons/react/24/outline";
import { Input } from "../shadcn-ui/Input";
import { Popover, PopoverContent, PopoverTrigger } from "../shadcn-ui/Popover";
import { NavigateService } from "../../services/NavigateService";
import { DocumentIcon } from "@heroicons/react/24/solid";
import { AssignedSessionStatus } from "../../types/AssignedSessionStatus";
import { useAppContext } from "../../contexts/AppContext";
import { ExperienceType } from "../../schemas/ExperienceType";
import { AdminAssignmentView } from "../../types/AdminAssignmentView";
import FronteggService from "../../services/FronteggService";
import { useAuth } from "../../contexts/AuthContext";
import { useTranslation } from "../Utility/UseTranslation";

interface AssignedSessionsTableProps {
	assignedSessions: AssignedSessionView[];
	onDownloadCSV: () => void;
	onAddUser: (email: string) => void;
	onEditUser: (userId: string) => void;
	onRemoveUser: (userId: string) => void;
	onAddExtension: (assignedSessionId: string, assignmentId: string, scheduleId: string, userId: string, daysToAdd: number) => void;
	length: number;
	anonymous?: boolean | null;
	assignment?: AdminAssignmentView;
}

const AssignedSessionsTable: React.FC<AssignedSessionsTableProps> = ({
	length,
	assignedSessions,
	onDownloadCSV,
	onAddUser,
	onEditUser,
	onRemoveUser,
	onAddExtension,
	anonymous = false,
	assignment
}) => {
	const [newUserEmail, setNewUserEmail] = useState("");
	const [emailError, setEmailError] = useState<string>("");
	const [isAddingUser, setIsAddingUser] = useState(false);
	const { instageUser } = useAuth();
	const { t } = useTranslation("adminAssignmentPage");
	// Email validation regex
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

	const validateEmail = (email: string): boolean => {
		// Reset error message
		setEmailError("");

		// Check if email is empty
		if (!email.trim()) {
			setEmailError("Email is required");
			return false;
		}

		// Check email format
		if (!emailRegex.test(email)) {
			setEmailError("Please enter a valid email address");
			return false;
		}

		// Check if email already exists in the list
		const emailExists = assignedSessions.some((session) => session.email.toLowerCase() === email.toLowerCase());
		if (emailExists) {
			setEmailError(t("assignedSessionsTable.emailAlreadyExists"));
			return false;
		}

		return true;
	};

	const navigateToUserReport = async (
		assignmentId: string,
		userId: string,
		scheduleId: string,
		assignmentTitle: string,
		firstName: string,
		lastName: string
	) => {
		// if (instageUser) {
		// 	await FronteggService.getInstance().createAuditLog({
		// 		userId: instageUser.id,
		// 		fronteggId: instageUser?.fronteggId || "",
		// 		tenantId: instageUser.clientId,
		// 		action: `Viewed ${firstName} ${lastName} Call Summary: ${assignmentTitle} `,
		// 		severity: "Info"
		// 	});
		// }

		NavigateService.navToSessionSummary(assignmentId, userId, scheduleId);
	};
	const hasExtension = assignedSessions.some((session) => session.daysToAdd !== 0);
	const { debugMode } = useAppContext();

	const columns: ExtendedColumnDef<AssignedSessionView, any>[] = [
		{
			accessorKey: "fullName",
			header: t("assignedSessionsTable.student"),
			initiallyHidden: anonymous === true,
			cell: ({ row }) => (
				<div className="w-[125px]">
					<div className="truncate">
						{row.original.firstName} {row.original.lastName}
					</div>
				</div>
			)
		},
		{
			accessorKey: "email",
			header: t("assignedSessionsTable.email"),
			initiallyHidden: anonymous === true,
			cell: ({ row }) => <span className="text-muted-foreground">{row.original.email}</span>
		},
		{
			accessorKey: "report",
			header: t("assignedSessionsTable.report"),
			initiallyHidden: anonymous === false,
			cell: ({ row }) =>
				(row.original.userId !== "" && row.original.status === AssignedSessionStatus.SUBMITTED) ||
				(row.original.userId !== "" && row.original.status === AssignedSessionStatus.SUBMITTED_LATE) ? (
					<Button
						variant="link"
						onClick={() =>
							navigateToUserReport(
								row.original.assignmentId,
								row.original.userId,
								row.original.scheduleId,
								row.original.assignmentTitle,
								row.original?.firstName,
								row.original?.lastName
							)
						}
						className="flex space-x-1 p-0"
						aria-label={`View report for ${row.original.firstName} ${row.original.lastName}`}
					>
						<DocumentIcon className="h-4 w-4" />
						<span>{t("assignedSessionsTable.viewReport")}</span>
					</Button>
				) : null
		},
		{
			accessorKey: "grade",
			header: t("assignedSessionsTable.grade"),
			initiallyHidden: anonymous === false || assignment?.gradingType === "none",
			cell: ({ row }) => {
				if (row.original.status !== AssignedSessionStatus.SUBMITTED && row.original.status !== AssignedSessionStatus.SUBMITTED_LATE) {
					return t("assignedSessionsTable.notSubmitted");
				}

				// Numeric grading for Frontline assignments
				// TODO: Make this dynamic to support other experience types
				if (
					assignment?.gradingType === "numeric" &&
					assignment?.experienceType === ExperienceType.FRONTLINE &&
					row.original.frontlineAssessment &&
					row.original.gradingWeights
				) {
					const scores = row.original.frontlineAssessment.score;
					const weights = row.original.gradingWeights;
					const totalScore = scores.professionalism * (weights.professionalism / 100) + scores.checklist * (weights.checklist / 100);
					return `${totalScore.toFixed(1)}%`;
				}

				// For pass/fail assignments
				return row.original.reviewPercentage >= 50 ? t("assignedSessionsTable.pass") : t("assignedSessionsTable.fail");
			}
		},
		{
			accessorKey: "status",
			header: t("assignedSessionsTable.status"),
			cell: ({ row }) => (
				<span
					className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
						row.original.status === AssignedSessionStatus.SUBMITTED
							? "bg-[#00a9af] text-[#fff]"
							: row.original.status === AssignedSessionStatus.NOT_ATTEMPTED
							? "bg-red-100 text-red-800"
							: row.original.status === AssignedSessionStatus.PENDING ||
							  row.original.status === AssignedSessionStatus.IN_PROGRESS ||
							  row.original.status === AssignedSessionStatus.UNSUBMITTED
							? "bg-yellow-100 text-yellow-600"
							: row.original.status === AssignedSessionStatus.AVAILABLE
							? "bg-green-100 text-green-800"
							: "bg-[#e6e5ff] text-[#16013e]"
					}`}
				>
					{row.original.status === AssignedSessionStatus.PENDING
						? t("assignedSessionsTable.statuses.upcoming")
						: t(`assignedSessionsTable.statuses.${row.original.status}`)}
				</span>
			)
		},
		{
			accessorKey: "daysToAdd",
			header: t("assignedSessionsTable.extension"),

			initiallyHidden: true
		},
		{
			accessorKey: "date",
			header: t("assignedSessionsTable.dueDate"),

			cell: ({ row }) => {
				const date = new Date(row.original.date + "Z"); // Append 'Z' to indicate UTC time
				date.setDate(date.getDate() + (row.original.daysToAdd || 0)); // Manually add days to the date
				if (date && date.getTime() !== 0) {
					if (debugMode) {
						return date.toLocaleString(); // Format to local time
					} else {
						return format(date, "yyyy-MM-dd");
					}
				}
				return "No due date";
			}
		},
		{
			accessorKey: "submittedAt",
			header: t("assignedSessionsTable.submittedAt"),
			initiallyHidden: anonymous === false,
			cell: ({ row }) => {
				const value = row.original.submittedAt;
				if (value && value.getTime() !== 0) {
					return format(value, "yyyy-MM-dd HH:mm:ss");
				}
				return t("assignedSessionsTable.notSubmitted");
			}
		}
	];
	if (anonymous !== true) {
		columns.push({
			id: "actions",
			initiallyHidden: true,
			cell: ({ row }) => (
				<DataTableActionMenu
					label={t("assignedSessionsTable.actions")}
					menuItems={[
						{
							label: t("assignedSessionsTable.add1DayExtension"),
							onClick: () => {
								if (row.original.assignedSessionId) {
									onAddExtension(
										row.original.assignedSessionId,
										row.original.assignmentId,
										row.original.scheduleId,
										row.original.userId,
										1
									);
								} else {
									console.error("Assigned session ID is null");
								}
							},
							"aria-label": `Add 1 day extension for ${row.original.firstName} ${row.original.lastName}`
						},
						{
							label: t("assignedSessionsTable.add3DayExtension"),
							onClick: () => {
								if (row.original.assignedSessionId) {
									onAddExtension(
										row.original.assignedSessionId,
										row.original.assignmentId,
										row.original.scheduleId,
										row.original.userId,
										3
									);
								} else {
									console.error("Assigned session ID is null");
								}
							},
							"aria-label": `Add 3 day extension for ${row.original.firstName} ${row.original.lastName}`
						},
						{
							label: t("assignedSessionsTable.add7DayExtension"),
							onClick: () => {
								if (row.original.assignedSessionId) {
									onAddExtension(
										row.original.assignedSessionId,
										row.original.assignmentId,
										row.original.scheduleId,
										row.original.userId,
										7
									);
								} else {
									console.error("Assigned session ID is null");
								}
							},
							"aria-label": `Add 7 day extension for ${row.original.firstName} ${row.original.lastName}`
						},
						{
							label: t("assignedSessionsTable.removeUser"),
							onClick: () => onRemoveUser(row.original.userId),
							"aria-label": `Remove ${row.original.firstName} ${row.original.lastName}`
						}
					]}
					aria-label={`Actions for ${row.original.firstName} ${row.original.lastName}`}
				/>
			)
		});
	}
	const dataTableKey = useMemo(
		() => assignedSessions.map((session) => session.assignmentId.split("-")[0]).join("-") + hasExtension,
		[assignedSessions, hasExtension]
	);

	const filters: Filter[] = [
		{
			columnId: "email",
			type: "input",
			placeholder: t("assignedSessionsTable.search")
		},
		{
			columnId: "status",
			type: "select",
			placeholder: t("assignedSessionsTable.status"),
			options: [
				{ label: t("assignedSessionsTable.submitted"), value: AssignedSessionStatus.SUBMITTED },
				{ label: t("assignedSessionsTable.pastDue"), value: AssignedSessionStatus.NOT_ATTEMPTED },
				{ label: t("assignedSessionsTable.pending"), value: AssignedSessionStatus.PENDING },
				{ label: t("assignedSessionsTable.available"), value: AssignedSessionStatus.AVAILABLE },
				{ label: t("assignedSessionsTable.inProgress"), value: AssignedSessionStatus.IN_PROGRESS },
				{ label: t("assignedSessionsTable.notOnboarded"), value: AssignedSessionStatus.NOT_CONFIRMED },
				{ label: t("assignedSessionsTable.bounced"), value: AssignedSessionStatus.BOUNCED }
			]
		},
		{
			columnId: "submittedAt",
			type: "dateRange",
			placeholder: t("assignedSessionsTable.submittedAt")
		}
	];

	const handleAddUser = async () => {
		const email = newUserEmail.trim();
		if (validateEmail(email)) {
			setIsAddingUser(true);
			try {
				await onAddUser(email);
				setNewUserEmail("");
				setEmailError("");
			} finally {
				setIsAddingUser(false);

				if (instageUser) {
					await FronteggService.getInstance().createAuditLog({
						userId: instageUser.id,
						fronteggId: instageUser?.fronteggId || "",
						tenantId: instageUser.clientId,
						action: `Added Student: ${email} to ${assignment?.assignmentTitle}`,
						severity: "Info"
					});
				}
			}
		}
	};

	return (
		<div key={length}>
			<div className="flex justify-between items-center mb-4">
				<h2 className="text-xl font-semibold">
					{anonymous ? t("assignedSessionsTable.userReports") : t("assignedSessionsTable.assignedSessions")}
				</h2>
				<div className="flex space-x-2">
					<Button
						onClick={onDownloadCSV}
						variant="outline"
						size="icon"
						title={t("assignedSessionsTable.downloadCSV")}
						aria-label="Download CSV of assigned sessions"
					>
						<ArrowDownTrayIcon className="h-4 w-4" />
					</Button>
					{(anonymous === false || anonymous === null) && (
						<Popover>
							<PopoverTrigger asChild>
								<Button variant="outline" className="flex items-center space-x-2" title="Add User" aria-label="Open add student form">
									<UserPlusIcon className="h-4 w-4" />
									<span>{t("assignedSessionsTable.addStudent")}</span>
								</Button>
							</PopoverTrigger>
							<PopoverContent className="w-80">
								<div className="space-y-4">
									<h4 className="font-medium leading-none">{t("assignedSessionsTable.addUser")}</h4>
									<div className="space-y-2">
										<Input
											type="email"
											placeholder={t("assignedSessionsTable.enterEmail")}
											value={newUserEmail}
											onChange={(e) => setNewUserEmail(e.target.value)}
											className={emailError ? "border-red-500" : ""}
											disabled={isAddingUser}
											aria-label="Student email address"
											aria-invalid={!!emailError}
											aria-describedby={emailError ? "email-error" : undefined}
										/>
										{emailError && (
											<p id="email-error" className="text-sm text-red-500">
												{emailError}
											</p>
										)}
									</div>
									<Button
										onClick={handleAddUser}
										className="w-full"
										disabled={isAddingUser}
										aria-label={isAddingUser ? "Adding student..." : "Add student"}
									>
										{isAddingUser ? (
											<>
												<svg
													className="animate-spin -ml-1 mr-3 h-4 w-4 text-white"
													xmlns="http://www.w3.org/2000/svg"
													fill="none"
													viewBox="0 0 24 24"
												>
													<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
													<path
														className="opacity-75"
														fill="currentColor"
														d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
													/>
												</svg>
												{t("assignedSessionsTable.adding")}...
											</>
										) : (
											t("assignedSessionsTable.addUser")
										)}
									</Button>
								</div>
							</PopoverContent>
						</Popover>
					)}
				</div>
			</div>

			<DataTable enableSorting={true} columns={columns} data={assignedSessions} filters={filters} key={dataTableKey} />
		</div>
	);
};

export default AssignedSessionsTable;
