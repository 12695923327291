import { useEffect, useState } from "react";
import { WebhookManagementLocale } from "../../PageTranslations/WebhookManagementLocale";
import { SideBarLocale } from "../../PageTranslations/SidebarLocale";
import { UserSessionPageLocale } from "../../PageTranslations/UserSessionPageLocale";
import { AdminAssignmentPageLocale } from "../../PageTranslations/AdminAssignmentPageLocale";
import { UserHomePageLocale } from "../../PageTranslations/UserHomePageLocale";
import { useAppContext } from "../../contexts/AppContext";
import { ExtraUtilityLocale } from "../../PageTranslations/ExtraUtilityLocale";
import { AssignmentListLocale } from "../../PageTranslations/AssignmentListLocale";
import { SessionLogLocale } from "../../PageTranslations/SessionLogLocale";
import { SupportPageLocale } from "../../PageTranslations/SupportPageLocale";
import { AdminSettingsLocale } from "../../PageTranslations/AdminSettingsLocale";
import { InterviewCreatorDatesLocale } from "../../PageTranslations/InterviewCreatorDatesLocale";
import { CreateAssignmentLocale } from "../../PageTranslations/CreateAssignmentLocale";
import { InterviewSetupLocale } from "../../PageTranslations/InterviewSetupLocale";
import { AssignmentDetailsLocale } from "../../PageTranslations/AssignmentDetailsLocale";
import { AssignmentReviewLocale } from "../../PageTranslations/AssignmentReviewLocale";
import { ResumeSelectorLocale } from "../../PageTranslations/ResumeSelectorLocale";
import { ProfileLocale } from "../../PageTranslations/ProfileLocale";
import { ResumeAssistLocale } from "../../PageTranslations/ResumeAssistLocale";
import { ResumeEditFormLocale } from "../../PageTranslations/ResumeEditFormLocale";
import { CoverLetterEditFormLocale } from "../../PageTranslations/CoverLetterEditFormLocale";
import { TemplatesLocale } from "../../PageTranslations/TemplatesLocale";

enum Language {
	EN = "en",
	FR = "fr"
}

// Define valid namespace types
type NamespaceType =
	| "webhookManagement"
	| "sideBar"
	| "userSessionPage"
	| "adminAssignmentPage"
	| "userHomePage"
	| "extraUtility"
	| "assignmentList"
	| "sessionLog"
	| "supportPage"
	| "adminSettings"
	| "interviewCreatorDates"
	| "createAssignment"
	| "interviewSetup"
	| "assignmentDetails"
	| "assignmentReview"
	| "resumeSelector"
	| "profile"
	| "resumeAssist"
	| "resumeEditForm"
	| "coverLetterEditForm"
	| "templates";

export const NoTranslationChar = "¶";
export const useTranslation = (namespace: NamespaceType) => {
	const { language: appLanguage } = useAppContext();
	const [language, setLanguage] = useState<Language>(Language.EN);

	useEffect(() => {
		switch (appLanguage) {
			case "en":
				setLanguage(Language.EN);
				break;
			case "fr":
				setLanguage(Language.FR);
				break;
		}
	}, [appLanguage]);

	const resources: Record<Language, Record<string, any>> = {
		[Language.EN]: {
			webhookManagement: WebhookManagementLocale.en,
			sideBar: SideBarLocale.en,
			userSessionPage: UserSessionPageLocale.en,
			adminAssignmentPage: AdminAssignmentPageLocale.en,
			userHomePage: UserHomePageLocale.en,
			extraUtility: ExtraUtilityLocale.en,
			assignmentList: AssignmentListLocale.en,
			sessionLog: SessionLogLocale.en,
			supportPage: SupportPageLocale.en,
			adminSettings: AdminSettingsLocale.en,
			interviewCreatorDates: InterviewCreatorDatesLocale.en,
			createAssignment: CreateAssignmentLocale.en,
			interviewSetup: InterviewSetupLocale.en,
			assignmentDetails: AssignmentDetailsLocale.en,
			assignmentReview: AssignmentReviewLocale.en,
			resumeSelector: ResumeSelectorLocale.en,
			profile: ProfileLocale.en,
			resumeAssist: ResumeAssistLocale.en,
			resumeEditForm: ResumeEditFormLocale.en,
			coverLetterEditForm: CoverLetterEditFormLocale.en,
			templates: TemplatesLocale.en
		},
		[Language.FR]: {
			webhookManagement: WebhookManagementLocale.fr,
			sideBar: SideBarLocale.fr,
			userSessionPage: UserSessionPageLocale.fr,
			adminAssignmentPage: AdminAssignmentPageLocale.fr,
			userHomePage: UserHomePageLocale.fr,
			extraUtility: ExtraUtilityLocale.fr,
			assignmentList: AssignmentListLocale.fr,
			sessionLog: SessionLogLocale.fr,
			supportPage: SupportPageLocale.fr,
			adminSettings: AdminSettingsLocale.fr,
			interviewCreatorDates: InterviewCreatorDatesLocale.fr,
			createAssignment: CreateAssignmentLocale.fr,
			interviewSetup: InterviewSetupLocale.fr,
			assignmentDetails: AssignmentDetailsLocale.fr,
			assignmentReview: AssignmentReviewLocale.fr,
			resumeSelector: ResumeSelectorLocale.fr,
			profile: ProfileLocale.fr,
			resumeAssist: ResumeAssistLocale.fr,
			resumeEditForm: ResumeEditFormLocale.fr,
			coverLetterEditForm: CoverLetterEditFormLocale.fr,
			templates: TemplatesLocale.fr
		}
	};

	function t(key: string) {
		const fallbackTranslation = resources[Language.EN][namespace];
		const translation = resources[language][namespace];

		let text = key.split(".").reduce((obj, k) => (obj as any)?.[k], translation);
		// console.log(key, text);

		if (!text) {
			text = key.split(".").reduce((obj, k) => (obj as any)?.[k], fallbackTranslation);
		}

		if (!text) {
			text = `${NoTranslationChar}${key}${NoTranslationChar}`;
		}
		// Use type assertion with unknown first to avoid direct incorrect casting
		return text;
	}

	function changeLanguage(language: string) {
		return language;
	}

	return { t, changeLanguage };
};
