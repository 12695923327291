import React from "react";
import { Document, Packer, Paragraph, TextRun, AlignmentType, BorderStyle, Table, TableRow, TableCell, WidthType } from "docx";
import { CoverLetterView } from "../../../../../types/CoverLetterView";
import { ResumeStyleProps } from "../../../../UserPages/ResumeAssist";
import { useTranslation } from "../../../../Utility/UseTranslation";

export const ModernCLTemplateHTML = (
	coverLetter: CoverLetterView,
	style: ResumeStyleProps,
	handleSectionClick: (section: string) => void,
	{
		editingSection,
		editedData,
		setEditedData,
		isEditFormOpen,
		setIsEditFormOpen,
		setCoverLetter
	}: {
		editingSection: string;
		editedData: any;
		setEditedData: (data: any) => void;
		isEditFormOpen: boolean;
		setIsEditFormOpen: (open: boolean) => void;
		setCoverLetter: (coverLetter: CoverLetterView) => void;
	}
) => {
	const { t } = useTranslation("templates");
	const modernStyles = (
		<style>{`
            .cover-letter-section {
                border: 1px solid transparent;
                border-radius: 8px;
                padding: 8px;
                position: relative;
                transition: all 0.2s ease;
            }
            .cover-letter-section:hover,
            .cover-letter-section:focus {
                background-color: rgba(0, 169, 175, 0.1);
                border: 1px solid #00a9af;
                cursor: pointer;
            }
            .cover-letter-content {
                max-width: 100%;
                margin: 0 auto;
                padding: 40px;
                background: white;
                display: grid;
                grid-template-columns: 250px 1fr;
                gap: 40px;
            }
            .left-column {
                padding-right: 40px;
                border-right: 1px solid #E2E8F0;
            }
            .right-column {
                padding-left: 0;
            }
            .name {
				padding: 40px;
				padding-bottom: 0;
                font-size: 42px;
                font-weight: 600;
                color: #1a202c;
                margin-bottom: 8px;
                letter-spacing: -0.5px;
            }
            .job-title {
                font-size: 18px;
                color: #4A5568;
                margin-bottom: 24px;
                font-weight: 500;
            }
            .contact-section {
                margin-bottom: 10px;
                border: 1px solid transparent;
                border-radius: 8px;
                transition: all 0.2s ease;
                padding: 8px;
            }
            .contact-section:hover,
            .contact-section:focus {
                background-color: rgba(0, 169, 175, 0.1);
                border: 1px solid #00a9af;
                cursor: pointer;
            }
            .contact-info {
                color: #4A5568;
                font-size: 14px;
                display: flex;
                flex-direction: column;
                gap: 8px;
            }
            .company-section {
                margin-top: 0px;
                padding-top: 32px;
                border-radius: 8px;
                border: 1px solid transparent;
                padding: 8px;
                position: relative;
                transition: all 0.2s ease;
            }
            .company-section:hover,
            .company-section:focus {
                background-color: rgba(0, 169, 175, 0.1);
                border: 1px solid #00a9af;
                cursor: pointer;
            }
            .company-info {
                color: #4A5568;
                font-size: 14px;
                display: flex;
                flex-direction: column;
                gap: 8px;
            }
            .company-title {
                font-size: 16px;
                font-weight: 600;
                color: #1a202c;
                margin-bottom: 16px;
            }
            .letter-body-section {
                margin-bottom: 16px;
                padding: 8px;
                border: 1px solid transparent;
                border-radius: 8px;
                transition: all 0.2s ease;
            }
            .letter-body-section:hover,
            .letter-body-section:focus {
                background-color: rgba(0, 169, 175, 0.1);
                border: 1px solid #00a9af;
                cursor: pointer;
            }
            .main-content {
                max-width: 1000px;
                margin: 0 auto;
            }
            .date-section {
                margin-bottom: 32px;
                color: #4A5568;
            }
        `}</style>
	);

	const handleKeyDown = (e: React.KeyboardEvent, section: string) => {
		if (e.key === "Enter" || e.key === " ") {
			e.preventDefault();
			handleSectionClick(section);
		}
	};

	return (
		<>
			{modernStyles}
			<div
				style={{
					fontFamily: "'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif",
					margin: "0 auto",
					fontSize: `${14 * style.fontSize}px`,
					lineHeight: 1.6,
					color: "#2D3748"
				}}
				role="document"
				aria-label="Modern Cover Letter Document"
			>
				<h1
					className="name"
					onClick={() => handleSectionClick("contact")}
					onKeyDown={(e) => handleKeyDown(e, "contact")}
					tabIndex={0}
					role="button"
					aria-label="Edit contact information"
				>
					{coverLetter.contact.firstName} {coverLetter.contact.lastName}
				</h1>
				<div className="cover-letter-content" role="main">
					{/* Left Column */}
					<div className="left-column" role="complementary" aria-label="Contact and company information">
						<div
							className="contact-section"
							onClick={() => handleSectionClick("contact")}
							onKeyDown={(e) => handleKeyDown(e, "contact")}
							role="button"
							tabIndex={0}
							aria-label="Edit contact information"
						>
							<h2 className="company-title" id="contact-heading">
								{t("coverLetterTemplates.contactInformation")}
							</h2>
							<div className="contact-info" role="list" aria-labelledby="contact-heading">
								<span role="listitem">{coverLetter.contact.email}</span>
								<span role="listitem">{coverLetter.contact.phone}</span>
								<span role="listitem">{coverLetter.contact.address}</span>
							</div>
						</div>
						<div
							className="company-section"
							onClick={() => handleSectionClick("companyInfo")}
							onKeyDown={(e) => handleKeyDown(e, "companyInfo")}
							role="button"
							tabIndex={0}
							aria-label="Edit company information"
						>
							<h2 className="company-title" id="company-heading">
								{t("coverLetterTemplates.companyInformation")}
							</h2>
							<div className="company-info" role="list" aria-labelledby="company-heading">
								<span role="listitem">{coverLetter.companyInfo.companyHiringManagerName}</span>
								<span role="listitem">{coverLetter.companyInfo.companyName}</span>
								<span role="listitem">{coverLetter.companyInfo.companyAddress}</span>
							</div>
						</div>
					</div>

					{/* Right Column */}
					<div className="right-column" role="article" aria-label="Cover letter main content">
						{/* Date */}
						{/* <div className="date-section">
							<p>{new Date().toLocaleDateString("en-US", { month: "long", day: "numeric", year: "numeric" })}</p>
						</div> */}

						{/* Recipient's Information */}
						{/* <div className="cover-letter-section" onClick={() => handleSectionClick("companyInfo")} style={{ marginBottom: "32px" }}>
							<p style={{ marginBottom: "4px", color: "#4A5568" }}>{coverLetter.companyInfo.companyHiringManagerName}</p>
							<p style={{ marginBottom: "4px", color: "#4A5568" }}>{coverLetter.companyInfo.companyName}</p>
							<p style={{ color: "#4A5568" }}>{coverLetter.companyInfo.companyAddress}</p>
						</div> */}

						{/* Letter Body */}
						<div style={{ marginBottom: "32px" }}>
							{/* Salutation */}
							<div style={{ marginBottom: "24px", padding: "8px" }} role="text">
								<p style={{ color: "#2D3748" }}>
									{t("coverLetterTemplates.salutation")} {coverLetter.companyInfo.companyHiringManagerName},
								</p>
							</div>

							{/* Introduction */}
							<div
								className="letter-body-section"
								onClick={() => handleSectionClick("introduction")}
								onKeyDown={(e) => handleKeyDown(e, "introduction")}
								role="button"
								tabIndex={0}
								aria-label="Edit introduction paragraph"
							>
								<p style={{ color: "#2D3748", lineHeight: "1.8", textAlign: "justify" }}>
									{coverLetter.coverLetterData.introduction}
								</p>
							</div>

							{/* Main Body */}
							<div
								className="letter-body-section"
								onClick={() => handleSectionClick("body")}
								onKeyDown={(e) => handleKeyDown(e, "body")}
								role="button"
								tabIndex={0}
								aria-label="Edit main body paragraph"
							>
								<p style={{ color: "#2D3748", lineHeight: "1.8", textAlign: "justify" }}>{coverLetter.coverLetterData.body}</p>
							</div>

							{/* Closing */}
							<div
								className="letter-body-section"
								onClick={() => handleSectionClick("closing")}
								onKeyDown={(e) => handleKeyDown(e, "closing")}
								role="button"
								tabIndex={0}
								aria-label="Edit closing paragraph"
							>
								<p style={{ color: "#2D3748", lineHeight: "1.8", textAlign: "justify" }}>{coverLetter.coverLetterData.closing}</p>
							</div>
						</div>

						{/* Signature */}
						<div style={{ marginTop: "32px", marginBottom: "60px" }} role="contentinfo" aria-label="Signature">
							<p style={{ marginBottom: "24px", color: "#2D3748" }}>{t("coverLetterTemplates.sincerelySignature")},</p>
							<p style={{ color: "#2D3748", fontWeight: "500" }}>
								{coverLetter.contact.firstName} {coverLetter.contact.lastName}
							</p>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export const ModernCLTemplateDocX = (coverLetter: CoverLetterView, style: ResumeStyleProps, translations: any) => {
	return new Document({
		styles: {
			paragraphStyles: [
				{
					id: "Normal",
					name: "Normal",
					run: {
						font: "Calibri",
						size: 24,
						color: "2D3748"
					},
					paragraph: {
						spacing: {
							after: 120,
							line: 360
						}
					}
				}
			]
		},
		sections: [
			{
				properties: {
					page: {
						margin: {
							top: 720,
							right: 720,
							bottom: 720,
							left: 720
						}
					}
				},
				children: [
					// Two-column layout using a table
					new Paragraph({
						children: [
							new TextRun({
								text: `${coverLetter.contact.firstName} ${coverLetter.contact.lastName}`,
								size: 64,
								font: "Calibri",
								color: "1A202C",
								bold: true
							})
						],
						spacing: { after: 120 }
					}),
					new Table({
						width: {
							size: 100,
							type: WidthType.PERCENTAGE
						},
						borders: {
							top: { style: BorderStyle.NONE },
							bottom: { style: BorderStyle.NONE },
							left: { style: BorderStyle.NONE },
							right: { style: BorderStyle.NONE },
							insideHorizontal: { style: BorderStyle.NONE },
							insideVertical: { style: BorderStyle.NONE }
						},
						rows: [
							new TableRow({
								children: [
									// Left column
									new TableCell({
										borders: {
											top: { style: BorderStyle.NONE },
											bottom: { style: BorderStyle.NONE },
											left: { style: BorderStyle.NONE },
											right: { style: BorderStyle.SINGLE, size: 10, color: "E2E8F0" }
										},
										width: {
											size: 25,
											type: WidthType.PERCENTAGE
										},
										children: [
											new Paragraph({
												children: [
													new TextRun({
														text: translations.contactInformation,
														size: 24,
														font: "Calibri",
														color: "1A202C",
														bold: true
													})
												],
												spacing: { after: 240 }
											}),
											new Paragraph({
												children: [
													new TextRun({
														text: coverLetter.contact.email,
														size: 22,
														font: "Calibri",
														color: "4A5568"
													})
												],
												spacing: { after: 120 }
											}),
											new Paragraph({
												children: [
													new TextRun({
														text: coverLetter.contact.phone,
														size: 22,
														font: "Calibri",
														color: "4A5568"
													})
												],
												spacing: { after: 120 }
											}),
											new Paragraph({
												children: [
													new TextRun({
														text: coverLetter.contact.address,
														size: 22,
														font: "Calibri",
														color: "4A5568"
													})
												],
												spacing: { after: 360 }
											}),

											// Company Information
											new Paragraph({
												children: [
													new TextRun({
														text: translations.companyInformation,
														size: 24,
														font: "Calibri",
														color: "1A202C",
														bold: true
													})
												],
												spacing: { after: 240 }
											}),
											new Paragraph({
												children: [
													new TextRun({
														text: coverLetter.companyInfo.companyHiringManagerName,
														size: 22,
														font: "Calibri",
														color: "4A5568"
													})
												],
												spacing: { after: 120 }
											}),
											new Paragraph({
												children: [
													new TextRun({
														text: coverLetter.companyInfo.companyName,
														size: 22,
														font: "Calibri",
														color: "4A5568"
													})
												],
												spacing: { after: 120 }
											}),
											new Paragraph({
												children: [
													new TextRun({
														text: coverLetter.companyInfo.companyAddress,
														size: 22,
														font: "Calibri",
														color: "4A5568"
													})
												]
											})
										],
										margins: {
											right: 360
										}
									}),
									// Right column
									new TableCell({
										borders: {
											top: { style: BorderStyle.NONE },
											bottom: { style: BorderStyle.NONE },
											left: { style: BorderStyle.NONE },
											right: { style: BorderStyle.NONE }
										},
										width: {
											size: 75,
											type: WidthType.PERCENTAGE
										},
										children: [
											// Letter content
											new Paragraph({
												children: [
													new TextRun({
														text: `${translations.salutation} ${coverLetter.companyInfo.companyHiringManagerName},`,
														size: 24,
														font: "Calibri",
														color: "2D3748"
													})
												],
												spacing: { after: 360 }
											}),
											new Paragraph({
												children: [
													new TextRun({
														text: coverLetter.coverLetterData.introduction,
														size: 24,
														font: "Calibri",
														color: "2D3748"
													})
												],
												spacing: { after: 240, line: 360 },
												alignment: AlignmentType.JUSTIFIED
											}),
											new Paragraph({
												children: [
													new TextRun({
														text: coverLetter.coverLetterData.body,
														size: 24,
														font: "Calibri",
														color: "2D3748"
													})
												],
												spacing: { after: 240, line: 360 },
												alignment: AlignmentType.JUSTIFIED
											}),
											new Paragraph({
												children: [
													new TextRun({
														text: coverLetter.coverLetterData.closing,
														size: 24,
														font: "Calibri",
														color: "2D3748"
													})
												],
												spacing: { after: 360, line: 360 },
												alignment: AlignmentType.JUSTIFIED
											}),
											new Paragraph({
												children: [
													new TextRun({
														text: translations.sincerelySignature + ",",
														size: 24,
														font: "Calibri",
														color: "2D3748"
													})
												],
												spacing: { after: 360 }
											}),
											new Paragraph({
												children: [
													new TextRun({
														text: `${coverLetter.contact.firstName} ${coverLetter.contact.lastName}`,
														size: 24,
														font: "Calibri",
														color: "2D3748",
														bold: false
													})
												]
											})
										],
										margins: {
											left: 360
										}
									})
								]
							})
						]
					})
				]
			}
		]
	});
};
