export const ResumeEditFormLocale = {
	en: {
		months: {
			January: "January",
			February: "February",
			March: "March",
			April: "April",
			May: "May",
			June: "June",
			July: "July",
			August: "August",
			September: "September",
			October: "October",
			November: "November",
			December: "December"
		},
		common: {
			edit: "Edit",
			original: "Original",
			editRewrite: "Edit / AI Rewrite",
			feedback: "Feedback",
			rewriting: "Rewriting...",
			formalize: "Formalize",
			shorten: "Shorten",
			elaborate: "Elaborate",
			rephraseTooltip: "Use these buttons to rephrase the content of the section. Confirm or Cancel after each change.",
			cancel: "Cancel",
			confirm: "Confirm",
			remove: "Remove",
			add: "Add"
		},
		summary: {
			label: "Summary",
			placeholder:
				"Results-driven Sales Professional with 5+ years of experience in B2B software sales. Proven track record of exceeding quarterly targets and building long-term client relationships. Skilled in consultative selling, pipeline management, and contract negotiations."
		},
		contact: {
			label: "Contact Information",
			firstName: "First Name",
			lastName: "Last Name",
			email: "Email",
			phone: "Phone",
			address: "Address",
			linkedinURL: "LinkedIn URL",
			website: "Website",
			placeholders: {
				firstName: "John",
				lastName: "Doe",
				email: "john.doe@gmail.com",
				phone: "(123) 456-7890",
				address: "123 Main St, City, State 12345",
				linkedinURL: "linkedin.com/in/johndoe",
				website: "www.johndoe.com"
			}
		},
		experience: {
			title: "Experience",
			jobTitle: "Job Title",
			companyName: "Company Name",
			city: "City",
			stateOrProv: "State/Province",
			startMonth: "Start Month",
			startYear: "Start Year",
			endMonth: "End Month",
			endYear: "End Year",
			roles: "Accomplishments",
			placeholders: {
				jobTitle: "Senior Sales Representative",
				companyName: "Enterprise Solutions Group",
				city: "City",
				stateOrProv: "State/Province"
			},
			selectMonth: "Select month",
			selectYear: "Select year",
			present: "Present",
			addExperience: "Add Experience",
			removeExperience: "Remove Experience",
			addAccomplishment: "Add Accomplishment",
			removeAccomplishment: "Remove Accomplishment"
		},
		education: {
			title: "Education",
			school: "School",
			degree: "Degree",
			program: "Program",
			graduatingYear: "Graduating Year",
			notes: "Notes",
			placeholders: {
				school: "University of Toronto",
				degree: "Bachelor of Science",
				program: "Computer Science"
			},
			selectYear: "Select year",
			addEducation: "Add Education",
			removeEducation: "Remove Education",
			addNote: "Add Note",
			removeNote: "Remove Note"
		},
		projects: {
			title: "Project",
			projectName: "Project Name",
			description: "Description",
			startMonth: "Start Month",
			startYear: "Start Year",
			endMonth: "End Month",
			endYear: "End Year",
			projectURL: "Project URL",
			notes: "Notes",
			placeholders: {
				projectName: "Regional Sales Strategy Overhaul",
				description:
					"Led a team initiative to restructure our regional sales approach, resulting in a 45% increase in quarterly revenue and improved client retention rates",
				projectURL: "https://example.com/project"
			},
			selectMonth: "Select month",
			selectYear: "Select year",
			present: "Present",
			addProject: "Add Project",
			removeProject: "Remove Project",
			addNote: "Add Note",
			removeNote: "Remove Note"
		},
		extraCurricular: {
			title: "Activity",
			activity: "Activity",
			orgName: "Organization Name",
			startMonth: "Start Month",
			startYear: "Start Year",
			endMonth: "End Month",
			endYear: "End Year",
			tasks: "Tasks",
			placeholders: {
				activity: "Sales Mentor",
				orgName: "Young Professionals Sales Network"
			},
			selectMonth: "Select month",
			selectYear: "Select year",
			present: "Present",
			addActivity: "Add Activity",
			removeActivity: "Remove Activity",
			addTask: "Add Task",
			removeTask: "Remove Task"
		},
		skills: {
			technical: "Technical Skills",
			coreCompetencies: "Core Competencies",
			languages: "Languages",
			tools: "Tools",
			frameworks: "Frameworks",
			placeholders: {
				technical: "Salesforce CRM",
				coreCompetencies: "Contract Negotiation",
				languages: "English (Native)",
				tools: "LinkedIn Sales Navigator",
				frameworks: "SPIN Selling"
			},
			addTechnical: "Add Technical Skill",
			removeTechnical: "Remove Technical Skill",
			addCoreCompetencies: "Add Core Competency",
			removeCoreCompetencies: "Remove Core Competency",
			addLanguages: "Add Language",
			removeLanguages: "Remove Language",
			addTools: "Add Tool",
			removeTools: "Remove Tool",
			addFrameworks: "Add Framework",
			removeFrameworks: "Remove Framework"
		}
	},
	fr: {
		months: {
			January: "Janvier",
			February: "Février",
			March: "Mars",
			April: "Avril",
			May: "Mai",
			June: "Juin",
			July: "Juillet",
			August: "Août",
			September: "Septembre",
			October: "Octobre",
			November: "Novembre",
			December: "Décembre"
		},
		common: {
			edit: "Modifier",
			original: "Original",
			editRewrite: "Modifier / Réécrire avec AI",
			feedback: "Feedback",
			rewriting: "Réécriture...",
			formalize: "Formaliser",
			shorten: "Raccourcir",
			elaborate: "Elaborer",
			rephraseTooltip: "Utilisez ces boutons pour réécrire le contenu de la section. Confirmez ou Annulez après chaque modification.",
			cancel: "Annuler",
			confirm: "Confirmer",
			remove: "Supprimer",
			add: "Ajouter"
		},
		summary: {
			label: "Résumé",
			placeholder:
				"Vendeur de logiciels B2B avec 5+ années d'expérience dans les ventes de logiciels B2B. Proven track record of exceeding quarterly targets and building long-term client relationships. Skilled in consultative selling, pipeline management, and contract negotiations."
		},
		contact: {
			label: "Informations de Contact",
			firstName: "Prénom",
			lastName: "Nom",
			email: "Email",
			phone: "Téléphone",
			address: "Adresse",
			linkedinURL: "URL LinkedIn",
			website: "Site Web",
			placeholders: {
				firstName: "Jean",
				lastName: "Dupont",
				email: "jean.dupont@gmail.com",
				phone: "(123) 456-7890",
				address: "123 Rue Principale, Ville, État 12345",
				linkedinURL: "linkedin.com/in/jeandupont",
				website: "www.jeandupont.com"
			}
		},
		experience: {
			title: "Expérience",
			jobTitle: "Titre du Poste",
			companyName: "Nom de l'Entreprise",
			city: "Ville",
			stateOrProv: "État/Province",
			startMonth: "Mois de Début",
			startYear: "Année de Début",
			endMonth: "Mois de Fin",
			endYear: "Année de Fin",
			roles: "Réalisations",
			placeholders: {
				jobTitle: "Représentant Commercial Senior",
				companyName: "Groupe Solutions Entreprise",
				city: "Ville",
				stateOrProv: "État/Province"
			},
			selectMonth: "Sélectionner le mois",
			selectYear: "Sélectionner l'année",
			present: "Présent",
			addExperience: "Ajouter une Expérience",
			removeExperience: "Supprimer l'Expérience",
			addAccomplishment: "Ajouter une Réalisation",
			removeAccomplishment: "Supprimer la Réalisation"
		},
		education: {
			title: "Formation",
			school: "École",
			degree: "Diplôme",
			program: "Programme",
			graduatingYear: "Année d'Obtention",
			notes: "Notes",
			placeholders: {
				school: "Université de Toronto",
				degree: "Baccalauréat en Sciences",
				program: "Informatique"
			},
			selectYear: "Sélectionner l'année",
			addEducation: "Ajouter une Formation",
			removeEducation: "Supprimer la Formation",
			addNote: "Ajouter une Note",
			removeNote: "Supprimer la Note"
		},
		projects: {
			title: "Projet",
			projectName: "Nom du Projet",
			description: "Description",
			startMonth: "Mois de Début",
			startYear: "Année de Début",
			endMonth: "Mois de Fin",
			endYear: "Année de Fin",
			projectURL: "URL du Projet",
			notes: "Notes",
			placeholders: {
				projectName: "Refonte de la Stratégie de Vente Régionale",
				description:
					"Dirigé une initiative d'équipe pour restructurer notre approche de vente régionale, résultant en une augmentation de 45% du chiffre d'affaires trimestriel et une amélioration des taux de rétention des clients",
				projectURL: "https://exemple.com/projet"
			},
			selectMonth: "Sélectionner le mois",
			selectYear: "Sélectionner l'année",
			present: "Présent",
			addProject: "Ajouter un Projet",
			removeProject: "Supprimer le Projet",
			addNote: "Ajouter une Note",
			removeNote: "Supprimer la Note"
		},
		extraCurricular: {
			title: "Activité",
			activity: "Activité",
			orgName: "Nom de l'Organisation",
			startMonth: "Mois de Début",
			startYear: "Année de Début",
			endMonth: "Mois de Fin",
			endYear: "Année de Fin",
			tasks: "Tâches",
			placeholders: {
				activity: "Mentor Commercial",
				orgName: "Réseau des Jeunes Professionnels de la Vente"
			},
			selectMonth: "Sélectionner le mois",
			selectYear: "Sélectionner l'année",
			present: "Présent",
			addActivity: "Ajouter une Activité",
			removeActivity: "Supprimer l'Activité",
			addTask: "Ajouter une Tâche",
			removeTask: "Supprimer la Tâche"
		},
		skills: {
			technical: "Compétences Techniques",
			coreCompetencies: "Compétences Clés",
			languages: "Langues",
			tools: "Outils",
			frameworks: "Frameworks",
			placeholders: {
				technical: "Salesforce CRM",
				coreCompetencies: "Négociation de Contrats",
				languages: "Français (Natif)",
				tools: "LinkedIn Sales Navigator",
				frameworks: "SPIN Selling"
			},
			addTechnical: "Ajouter une Compétence Technique",
			removeTechnical: "Supprimer la Compétence Technique",
			addCoreCompetencies: "Ajouter une Compétence Clé",
			removeCoreCompetencies: "Supprimer la Compétence Clé",
			addLanguages: "Ajouter une Langue",
			removeLanguages: "Supprimer la Langue",
			addTools: "Ajouter un Outil",
			removeTools: "Supprimer l'Outil",
			addFrameworks: "Ajouter un Framework",
			removeFrameworks: "Supprimer le Framework"
		}
	}
};
