import React from "react";

import { PlusIcon } from "@heroicons/react/24/solid";

import { Button } from "../../shadcn-ui/Button";

import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../shadcn-ui/Select";
import { ResumeView } from "../../../types/ResumeView";
import { useTranslation } from "../../Utility/UseTranslation";

export const ResumeSelectSection = ({
	selectedResumeId,
	handleResumeSelection,
	resumes
}: {
	selectedResumeId: string | null;
	handleResumeSelection: (value: string | null) => void;
	resumes: ResumeView[];
}) => {
	const { t } = useTranslation("userSessionPage");

	return (
		<div className="flex items-center gap-3">
			<div className="text-sm font-medium text-gray-700">{t("ResumeForInterview")}</div>
			<Select value={selectedResumeId || ""} onValueChange={handleResumeSelection}>
				<SelectTrigger className="w-[280px]">
					<SelectValue placeholder={t("Select a Resume")} />
				</SelectTrigger>
				<SelectContent>
					<SelectItem value="create-new" className="text-[#00a9af]">
						<div className="flex items-center">
							<PlusIcon className="h-4 w-4 mr-2" />
							{t("Create New Resume")}
						</div>
					</SelectItem>
					<div className="py-1">
						<div className="px-2 text-xs font-medium text-gray-500 uppercase">{t("Your Resumes")}</div>
					</div>
					{resumes.map((resume) => (
						<SelectItem key={resume.id} value={resume.id}>
							{resume.title || "Untitled Resume"}
						</SelectItem>
					))}
				</SelectContent>
			</Select>
			{selectedResumeId && (
				<Button variant="ghost" size="sm" onClick={() => handleResumeSelection(null)} className="text-gray-500 hover:text-gray-700">
					{t("Clear")}
				</Button>
			)}
		</div>
	);
};
